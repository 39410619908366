import { FC, ReactNode } from 'react'
import LisaLoader from 'common/Loaders/LisaLoader'
import { NoResults, NoResultsProps } from 'common/NoResults/NoResults'
import { GridGrow } from 'common/Grid/GridGrow'

type WithLoaderProps = {
  loading: boolean
  loadingText?: ReactNode
  hasResults: boolean
  noResultsProps?: NoResultsProps
  noWrapper?: boolean
  className?: string
}
export const WithLoader: FC<WithLoaderProps> = ({
  loading,
  hasResults,
  loadingText = '',
  noResultsProps = {},
  children,
  noWrapper = false,
  className
}) => {
  const showContent = !loading && hasResults
  if (noWrapper && showContent) {
    return <>{children ?? null}</>
  }
  return (
    <GridGrow
      className={className}
      container
      direction={'column'}
      justifyContent={showContent ? 'flex-start' : 'center'}>
      { loading && <LisaLoader text={loadingText} normalLoader/>}
      { !loading && !hasResults && <NoResults {...noResultsProps}/>}
      { showContent && (children ?? null) }
    </GridGrow>
  )
}

export const WithDocumentsLoader: FC<WithLoaderProps> = (props) => {
  return (
    <WithLoader
      {...props}
      loadingText={props.loadingText ?? 'Loading Documents...'}
      noResultsProps={{
        type: props.noResultsProps?.type ?? 'documents',
        message: props.noResultsProps?.message ?? 'No documents'
      }}/>
  )
}
export const WithOcrQueueLoader: FC<WithLoaderProps> = (props) => {
  return (
    <WithLoader
      {...props}
      loadingText={props.loadingText ?? 'Loading OCR Queue...'}
      noResultsProps={{
        type: props.noResultsProps?.type ?? 'documents',
        message: props.noResultsProps?.message ?? 'Nothing in OCR Queue'
      }}/>
  )
}

export const WithLabelsLoader: FC<WithLoaderProps> = (props) => {
  return (
    <WithLoader
      {...props}
      loadingText={props.loadingText ?? 'Loading file tree data...'}
      noResultsProps={{
        type: 'labels',
        message: props.noResultsProps?.message ?? 'No labels'
      }}/>
  )
}

export const WithTransitionsLoader: FC<WithLoaderProps> = (props) => {
  return (
    <WithLoader
      {...props}
      loadingText={props.loadingText ?? 'Loading...'}
      noResultsProps={{
        type: 'transitions',
        message: props.noResultsProps?.message ?? 'No Recent Assets'
      }}/>
  )
}

export const WithQueriesLoader: FC<WithLoaderProps> = (props) => {
  return (
    <WithLoader
      {...props}
      loadingText={props.loadingText ?? 'Loading...'}
      noResultsProps={{
        type: 'queries',
        message: props.noResultsProps?.message ?? 'No Recent Queries'
      }}/>
  )
}

export const WithReportLoader: FC<WithLoaderProps> = (props) => {
  return (
    <WithLoader
      {...props}
      loadingText={props.loadingText ?? 'Loading report...'}
      noResultsProps={{
        type: 'labels',
        message: props.noResultsProps?.message ?? 'No report data'
      }}/>
  )
}
