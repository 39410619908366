import { FC, useState } from 'react'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { Button, Grid, makeStyles, TableRow, Typography } from '@material-ui/core'
import InputSearch from 'common/LisaControls/InputSearch'
import { openConfirmDialog, openModal, openToast } from 'redux/slices/appSlice'
import { useDispatch } from 'react-redux'
import { GridGrow } from 'common/Grid/GridGrow'
import { TableData } from 'common/TableData/TableData'
import TableCell from '@material-ui/core/TableCell'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { TenantForm } from 'pages/Administration/components/tenants/TenantForm'
import { useEditTenantMutation, useGetTenantsQuery } from 'services/api/tenantApi'
import { TenantSettingsMenu } from 'pages/Administration/components/tenants/TenantSettingsMenu'
import { TenantId } from 'types'
import { ConfirmDialog } from 'common/Dialogs/ConfirmDialog'
import { formattedDate } from 'utils/date'
import { DotIndicator } from 'common/Indicators/DotIndicator'
import { useAuth } from 'react-oidc-context'

const useStyles = makeStyles((theme) => ({
  search: {},
  inviteButton: {},
  subscribersTable: {
    flexGrow: 1,
    height: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    justifyContent: 'flex-start',
    position: 'relative',
    '& .MuiTableCell-head': {
      fontWeight: 700
    },
    '& td': {
      fontSize: 16
    }
  },
  loaderOverlay: {
    position: 'initial'
  },
  subscriberRow: {
    height: '56px !important',
    '& td': {
      borderBottom: `4px solid ${theme.palette.grey2.main}`,
      borderTop: `4px solid ${theme.palette.grey2.main}`
    }
  },
  deactivateSubscriber: {
    marginBlock: 16
  },
  tenantName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 150,
    lineHeight: '56px'
  }
}))

export const Tenants: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { user, signinSilent } = useAuth()
  const { data: tenants = [], isFetching } = useGetTenantsQuery()
  const [editTenantId, setEditTenantId] = useState<TenantId | undefined>()
  const [deactivateTenantId, setDeactivateTenantId] = useState<TenantId | undefined>()
  const [activateTenantId, setActivateTenantId] = useState<TenantId | undefined>()
  const [editTenant] = useEditTenantMutation()
  const [searchQuery, setSearchQuery] = useState('')

  return (
    <ContentWrapper newLayout>
      <GridGrow container direction={'column'} spacing={2}>
        <Grid item>
          <Grid container justifyContent={'space-between'}>
            <Grid item xs={8} lg={4} className={classes.search}>
              <InputSearch
                name='search'
                label='Find subscribers'
                onChange={(e) => setSearchQuery(e.target.value)}
                onClick={(e) => {
                  e.preventDefault()
                }}
                value={searchQuery}
                onKeyUp={() => {}}
              />
            </Grid>
            <Grid item className={classes.inviteButton}>
              <Button
                variant='contained'
                color='primary'
                size='large'
                onClick={() => dispatch(openModal('SUBSCRIBER_FORM'))}>
                ADD SUBSCRIBER
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <GridGrow item container className={classes.subscribersTable}>
          <Grid container direction={'column'}>
            <TableData
              noShadow
              noGrow
              loading={isFetching}
              loadingText='Loading Subscribers'
              noResultsProps={{
                type: 'users',
                message: 'No subscribers'
              }}
              headCells={[
                <TableCell
                  width={'15%'}
                  variant={'head'}
                  key={'subscriber'}
                  style={{ paddingLeft: 16 }}
                  align={'left'}>
                    Subscriber
                </TableCell>,
                <TableCell
                  width={'15%'}
                  variant={'head'}
                  key={'subscription'}
                  align={'left'}>
                    Subscription
                </TableCell>,
                <TableCell
                  width={'10%'}
                  variant={'head'}
                  key={'Start'}
                  align={'left'}>
                    Start
                </TableCell>,
                <TableCell
                  width={'10%'}
                  variant={'head'}
                  key={'end'}
                  align={'left'}>
                    End
                </TableCell>,
                <TableCell
                  width={'10%'}
                  variant={'head'}
                  key={'subscriberSince'}
                  align={'left'}>
                    Subscriber Since
                </TableCell>,
                <TableCell
                  width={'20%'}
                  variant={'head'}
                  key={'subAdminEmail'}
                  align={'left'}>
                    Subscriber Admin Email
                </TableCell>,
                <TableCell
                  width={'5%'}
                  variant={'head'}
                  key={'assets'}
                  align={'center'}>
                  Assets
                </TableCell>,
                <TableCell
                  width={'5%'}
                  variant={'head'}
                  key={'users'}
                  align={'center'}>
                    Users
                </TableCell>,
                <TableCell
                  width={'5%'}
                  variant={'head'}
                  key={'status'}
                  align={'center'}>
                    Status
                </TableCell>,
                <TableCell
                  width={'5%'}
                  variant={'head'}
                  key={'actions'}
                  align={'left'}/>
              ]}
              tableRows={
                tenants.filter(_ => _.name.toUpperCase().includes(searchQuery.toUpperCase())).map((tenant) => (
                  <TableRow key={tenant.tenantId} className={classes.subscriberRow}>
                    <TableCell
                      style={{ paddingLeft: 16 }}
                      width={'15%'}>
                      <div className={classes.tenantName}>
                        {tenant.name}
                      </div>
                    </TableCell>
                    <TableCell
                      width={'15%'}>
                      {tenant.subscriptionType}
                    </TableCell>
                    <TableCell
                      width={'10%'}>
                      {tenant.validSince ? formattedDate(new Date(tenant.validSince)) : '-'}
                    </TableCell>
                    <TableCell
                      width={'10%'}>
                      {tenant.validThru ? formattedDate(new Date(tenant.validThru)) : '-'}
                    </TableCell>
                    <TableCell
                      width={'10%'}>
                      {tenant.validSince ? formattedDate(new Date(tenant.validSince)) : '-'}
                    </TableCell>
                    <TableCell
                      width={'20%'}>
                      {tenant.email}
                    </TableCell>
                    <TableCell
                      align={'center'}
                      width={'5%'}>
                      {tenant.assetCount}
                    </TableCell>
                    <TableCell
                      align={'center'}
                      width={'5%'}>
                      {tenant.userCount}
                    </TableCell>
                    <TableCell
                      align={'center'}
                      width={'5%'}>
                      <DotIndicator active={tenant.active}/>
                    </TableCell>
                    <TableCell width={'5%'} align={'center'}>
                      {
                        !tenant.isDefault &&
                        <TenantSettingsMenu
                          tenantId={tenant.tenantId}
                          isActive={tenant.active}
                          onEdit={(tenantId) => {
                            setEditTenantId(tenantId)
                            dispatch(openModal('SUBSCRIBER_FORM'))
                          }}
                          onDeactivate={(tenantId) => {
                            setDeactivateTenantId(tenantId)
                            dispatch(openConfirmDialog('DEACTIVATE_TENANT'))
                          }}
                          onActivate={(tenantId) => {
                            setActivateTenantId(tenantId)
                            dispatch(openConfirmDialog('ACTIVATE_TENANT'))
                          }}/>
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            />
          </Grid>
        </GridGrow>
      </GridGrow>
      <LisaModal modalType={'SUBSCRIBER_FORM'} title={'ADD SUBSCRIBER'} scroll={'body'}>
        <TenantForm tenantId={editTenantId} onClose={() => setEditTenantId(undefined)}/>
      </LisaModal>
      <ConfirmDialog
        title={'Deactivate subscriber'}
        type={'DEACTIVATE_TENANT'}
        onConfirm={async () => {
          if (!deactivateTenantId) {
            return
          }
          try {
            await editTenant({ tenantId: deactivateTenantId, active: false })
            dispatch(openToast({ severity: 'info', message: 'Subscriber deactivated successfully' }))
            if (user?.profile.current_tenant === deactivateTenantId) {
              await signinSilent()
            }
          } catch (e) {
          }
          setDeactivateTenantId(undefined)
        }}>
        <Typography className={classes.deactivateSubscriber}>
          Are you sure you want to deactivate Subscriber?
        </Typography>
      </ConfirmDialog>
      <ConfirmDialog
        title={'Activate subscriber'}
        type={'ACTIVATE_TENANT'}
        onConfirm={async () => {
          if (!activateTenantId) {
            return
          }
          try {
            await editTenant({ tenantId: activateTenantId, active: true })
            dispatch(openToast({ severity: 'info', message: 'Subscriber activated successfully' }))
          } catch (e) {
          }
          setActivateTenantId(undefined)
        }}>
        <Typography className={classes.deactivateSubscriber}>
          Are you sure you want to activate Subscriber?
        </Typography>
      </ConfirmDialog>
    </ContentWrapper>
  )
}
