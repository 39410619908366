import { FC, MouseEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { Button } from '@material-ui/core'
import { useTransitionContext } from 'context/TransitionContext'
import { DocumentIcon } from 'common/DocumentIcon/DocumentIcon'
import { closeModal, openConfirmDialog, openToast } from 'redux/slices/appSlice'
import { ConfirmDialog } from 'common/Dialogs/ConfirmDialog'
import { useGetOneDriveStructureQuery, useStartImportFromOneDriveMutation } from 'services/api/importApi'
import { WithDocumentsLoader } from 'common/WithLoader/WithLoader'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    minWidth: '100%',
    minHeight: '60vh',
    marginBottom: theme.spacing(1),
    background: 'transparent'
  },
  table: {
    borderRadius: '4px',
    overflow: 'hidden',
    '& .MuiTableRow-root': {
      cursor: 'pointer',
      background: theme.palette.common.white
    },
    '& .MuiTableCell-root': {
      padding: '8px 12px'
    }
  },
  cellWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  breadcrumbsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    background: 'transparent',
    padding: '16px 0'
  },
  breadcrumbSegment: {
    display: 'flex',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingRight: '5px',
    columnGap: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '30px',
    fontWeight: 600,
    '&:hover': {
      color: theme.palette.black.light
    },
    '&:last-of-type': {
      textDecoration: 'none',
      color: theme.palette.black.light,
      '&:hover': {
        cursor: 'default',
        color: theme.palette.black.light
      }
    }
  },
  fileData: {
    fontSize: '16px',
    fontWeight: 400
  },
  message: {
    padding: '48px 0',
    fontSize: '20px'
  },
  structureImportTable: {
    width: '100%',
    overflowY: 'auto',
    height: '70vh',
    marginBottom: '32px'
  }
}))

export const UpdateStructure: FC = () => {
  const classes = useStyles()
  const [editMode, setEditMode] = useState(false)
  const [rowName, setRowName] = useState('')
  const { transitionId } = useTransitionContext()
  const {
    data: structure = [],
    isFetching
  } = useGetOneDriveStructureQuery()
  const dispatch = useDispatch()
  const [startImportFromOneDrive] = useStartImportFromOneDriveMutation()

  useEffect(() => {
    if (editMode) {
      dispatch(openConfirmDialog('IMPORT_STRUCTURE'))
    }
  }, [editMode])

  const handleImportStructure = async () => {
    const { success, message } = await startImportFromOneDrive({
      transitionId,
      path: rowName
    }).unwrap()
    if (!success && message) {
      dispatch(openToast({ severity: 'error', message: message }))
    } else {
      dispatch(closeModal(['ADD_FROM_ONE_DRIVE']))
    }
  }
  const handleImport = (e: MouseEvent<HTMLElement>, endPatchFolder: string) => {
    e.stopPropagation()
    setRowName(`/${endPatchFolder}`)
    setEditMode(true)
  }

  const handleClose = () => {
    setRowName('')
    setEditMode(false)
  }
  return (
    <div className={classes.root}>
      <WithDocumentsLoader
        loading={isFetching}
        hasResults={structure.length > 0}
        loadingText={'Loading OneDrive...'}>
        <Paper className={classes.paper} elevation={0}>
          <div className={classes.breadcrumbsWrapper}>
            <div className={classes.breadcrumbSegment}>
              Root LabelFolder<span> / </span>
            </div>
          </div>
          <TableContainer className={classes.structureImportTable}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table"
            >
              <TableBody>
                {structure.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.name}>
                      <TableCell
                        component="th"
                        scope="row"
                      >
                        <div className={classes.cellWrapper}>
                          {
                            row.extension && // @todo Probably always null
                            <DocumentIcon extension={row.extension}/>
                          }
                          <Typography className={classes.fileData}>
                            {' '}
                            {row.name}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <div className={classes.cellWrapper}>
                          <Typography className={classes.fileData}>
                            {' '}
                            {(row.size / 1000000).toFixed(2)} Mb
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell component="th" align="right" scope="row">
                        {row.isFolder && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => handleImport(e, row.name)}
                          >
                            Import
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </WithDocumentsLoader>
      {
        editMode &&
        <ConfirmDialog
          title="Import LabelFolder"
          type={'IMPORT_STRUCTURE'}
          onConfirm={handleImportStructure}
          onCancel={handleClose}>
          <div className={classes.message}>
            Are you sure you want to import this folder?
          </div>
        </ConfirmDialog>
      }
    </div>
  )
}
