import { FC, useMemo } from 'react'
import * as Icons from 'common/Icons/SvgIcons'
import { makeStyles } from '@material-ui/core'
import { formatBytes } from 'utils/file'
import { LisaFile, OcrStatus } from 'types'
import { supportedFiles } from 'utils/documents'

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '416px',
    padding: '48px 0 32px 0',
    '& div': {
      fontSize: '16px',
      display: 'flex',
      justifyContent: 'space-between',
      background: 'white',
      padding: '7px 16px',
      borderRadius: '4px',
      marginBottom: '4px',
      '& span:nth-child(2)': {
        fontWeight: 600
      }
    },
    '& .ocrMessage': {
      display: 'block',
      fontWeight: 600,
      marginTop: '16px',
      color: 'white',
      '&.error': {
        background: theme.palette.statusColors.red
      },
      '&.unsupported': {
        background: theme.palette.statusColors.orange
      },
      '&.completed': {
        background: theme.palette.statusColors.green
      },
      '&.progress': {
        backgroundColor: theme.palette.statusColors.blue
      },
      '& .tip': {
        margin: '8px 0',
        borderRadius: '4px',
        display: 'flex',
        padding: '8px',
        background: 'white',
        '& .tip-icon': {
          display: 'flex',
          color: theme.palette.statusColors.blue,
          marginRight: '10px'
        },
        '& .tip-message': {
          display: 'flex',
          flexDirection: 'row',
          color: theme.palette.black.main,
          fontWeight: 400,
          fontSize: '14px'
        }
      }
    }
  }
}))

type OCRDocumentInfoProps = {
  document: LisaFile
  ocrMessages: string[]
}

export const OCRDocumentInfo: FC<OCRDocumentInfoProps> = ({
  document,
  ocrMessages
}) => {
  const classes = useStyles()
  const {
    lowConfident,
    duration,
    totalCharacters,
    uncertainCharacters,
    pages,
    size,
    isProcessed,
    ocrStatus,
    extension
  } = document
  const ocrFailed = ocrStatus === OcrStatus.Failed
  const isExtensionSupported = extension && supportedFiles.includes(extension.toLowerCase())
  const OCRMessages = useMemo(() => {
    if (ocrMessages.length === 0) {
      return null
    }
    return (
      <span className="tip">
        <span className={'tip-icon'}>
          <Icons.Tip/>
        </span>
        <span>
          {ocrMessages && ocrMessages.map((item) =>
            <span key={item} className="tip-message">
              {item}
            </span>
          )}
        </span>
      </span>
    )
  }, [ocrMessages])
  return (
    <div className={classes.dialogWrapper}>
      <div><span>OCR Quality: </span><span>{`${(100 - Number(lowConfident)).toFixed(2)} %`}</span></div>
      <div><span>Duration: </span><span>{`${duration}`}</span></div>
      <div>
        <span>Total Characters: </span><span>{`${totalCharacters && totalCharacters.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span>
      </div>
      <div>
        <span>Uncertain Characters: </span><span>{`${uncertainCharacters && uncertainCharacters.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span>
      </div>
      <div><span>Pages: </span><span>{`${pages}`}</span></div>
      <div><span>FIle Size: </span><span>{`${formatBytes(size)}`}</span></div>
      {
        isProcessed &&
        <div className={ocrFailed ? 'ocrMessage error' : 'ocrMessage completed'}>
          {ocrFailed ? 'OCR error' : 'OCR completed'}
          {OCRMessages}
        </div>
      }
      {
        !isProcessed && !isExtensionSupported &&
        <div className={'ocrMessage unsupported'}>
          OCR does not support this type of file
          <span className="tip">
            <span className={'tip-icon'}>
              <Icons.Tip/>
            </span>
            <span className="tip-message">
                Download the document and try to open it on your computer.
            </span>
          </span>
        </div>
      }
      {
        !isProcessed && isExtensionSupported &&
        <div className={ocrFailed ? 'ocrMessage error' : 'ocrMessage progress'}>
          {ocrFailed ? 'OCR error' : 'OCR in progress'}
          {OCRMessages}
          {
            !ocrFailed &&
            <span className="tip">
              <span className={'tip-icon'}>
                <Icons.Tip/>
                <span className="tip-message">
                File is currently being processed by the OCR system.
                </span>
              </span>
            </span>
          }
        </div>
      }
    </div>
  )
}
