import { FC, useCallback, useEffect, useState } from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import Input from 'common/LisaControls/Input'
import SwitchLabel from 'common/LisaControls/SwitchLabel'
import { Tenant, TenantId } from 'types'
import { useCreateTenantMutation, useEditTenantMutation, useGetTenantQuery } from 'services/api/tenantApi'
import { useDispatch } from 'react-redux'
import { closeModal, openToast } from 'redux/slices/appSlice'
import DatePicker from 'common/LisaControls/DatePicker'
import { toYMDString } from 'utils/date'
import { CountriesDropDown } from 'common/CountriesDropDown/CountriesDropDown'
import { FormField } from 'common/FormField/FormField'
import Dropdown from 'common/LisaControls/Dropdown'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useAuth } from 'react-oidc-context'
import { LisaForm, useLisaForm } from 'common/Form/LisaForm'
import { WithLoader } from 'common/WithLoader/WithLoader'

export const emptyTenant: Tenant = {
  billingAddress: '',
  billingAddressSame: false,
  billingZipCode: '',
  tenantCity: '',
  tenantCountry: '',
  name: '',
  tenantEmail: '',
  tenantTitle: '',
  validSince: toYMDString(new Date()),
  validThru: toYMDString(new Date(), 1),
  active: true,
  avatar: '',
  billingCity: '',
  billingCountry: '',
  email: '',
  emailConfirmed: false,
  logo: '',
  expirationNotify: false,
  subscriptionType: '',
  superAdmin: false,
  tenantAddress: '',
  tenantEmailConfirmed: false,
  tenantFirstName: '',
  tenantId: '',
  tenantJobDescription: '',
  tenantLastName: '',
  tenantTelephone: '',
  tenantTelephoneConfirmed: false,
  tenantZipCode: '',
  assetCount: 0,
  userCount: 0,
  isDefault: false,
  wasLogged: false
}

type FormErrors = {
  name?: string
  email?: string
  subscriptionType?: string
}

const useStyles = makeStyles(() => ({
  root: {
    paddingBlock: 32
  }
}))

export interface TenantFormProps {
  tenantId?: TenantId
  onClose?: () => void
}

export const TenantForm: FC<TenantFormProps> = ({ tenantId, onClose = () => null }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [createTenant] = useCreateTenantMutation()
  const [editTenant] = useEditTenantMutation()
  const isEdit = tenantId !== undefined
  const {
    data: tenantData = [],
    isLoading
  } = useGetTenantQuery(tenantId!, { skip: tenantId === undefined })
  const { signinSilent } = useAuth()
  const { user } = useLisaAuth()
  const [tenant, setTenant] = useState<Tenant>(emptyTenant)

  useEffect(() => {
    if (!isLoading && isEdit && tenantData.length > 0) {
      setTenant(tenantData[0])
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setValues(tenantData[0])
    }
  }, [isLoading, isEdit, tenantData.length])

  const validate = useCallback((values: Tenant) => {
    const validationFields: (keyof FormErrors)[] = ['email', 'name', 'subscriptionType']
    const emailRegex = /^\S+@\S+\.\S+$/
    return validationFields.reduce(
      ({ errors: e, isValid }: {errors: FormErrors, isValid: boolean}, field: keyof FormErrors) => {
        if (!values[field]) {
          e[field] = 'This field is required.'
          isValid = false
        } else {
          switch (values[field]) {
          case values.email: {
            if (!emailRegex.test(values[field])) {
              e.email = 'Email is not valid.'
              isValid = false
            }
            break
          }
          default:
            break
          }
        }
        return { errors: e, isValid }
      }, { errors: {}, isValid: true })
  }, [])

  const {
    values,
    errors,
    handleInputChange,
    setValue,
    setValues,
    checkForErrors
  } = useLisaForm<Tenant, FormErrors>(
    { ...tenant },
    validate,
    false
  )

  const submit = useCallback(async (t: Tenant) => {
    let msg
    if (isEdit) {
      const { message } = await editTenant(t).unwrap()
      msg = message
    } else {
      const { message } = await createTenant(t).unwrap()
      msg = message
    }
    if (msg) {
      dispatch(openToast({ severity: 'info', message: msg }))
    }
    if (user.email === t.email) {
      await signinSilent()
    }
    dispatch(closeModal())
  }, [isEdit, user.email])

  return (
    <Grid container direction={'column'} spacing={2} className={classes.root}>
      <WithLoader loading={isLoading} hasResults={true} loadingText={'Loading subscriber...'}>
        <LisaForm>
          <Grid container spacing={2}>
            <Grid item>
              <Typography>Subscriber data</Typography>
            </Grid>
            <Grid item container spacing={4}>
              <Grid item xs>
                <Input
                  name='name'
                  value={values.name}
                  label={'Subscriber (Company) name*'}
                  errMsg={errors?.name}
                  onChange={handleInputChange}/>
              </Grid>
              <Grid item xs>
                <Input
                  name='email'
                  value={values.email}
                  label={'Subscriber Admin Email*'}
                  errMsg={errors?.email}
                  onChange={handleInputChange}/>
              </Grid>
            </Grid>
            <Grid item>
              <Typography>Contact details</Typography>
            </Grid>
            <Grid item container spacing={4}>
              <Grid item xs>
                <Input
                  name='tenantTitle'
                  label={'Title'}
                  value={values.tenantTitle}
                  onChange={handleInputChange}/>
              </Grid>
              <Grid item xs>
                <Input
                  name='tenantFirstName'
                  label={'First Name'}
                  value={values.tenantFirstName}
                  onChange={handleInputChange}/>
              </Grid>
            </Grid>
            <Grid item container spacing={4}>
              <Grid item xs>
                <Input
                  name='tenantLastName'
                  label={'Last Name'}
                  value={values.tenantLastName}
                  onChange={handleInputChange}/>
              </Grid>
              <Grid item xs>
                <Input
                  name='tenantJobDescription'
                  label={'Job description'}
                  value={values.tenantJobDescription}
                  onChange={handleInputChange}/>
              </Grid>
            </Grid>
            <Grid item container spacing={4}>
              <Grid item xs>
                <Input
                  name='tenantEmail'
                  label={'E-mail address'}
                  value={values.tenantEmail}
                  onChange={handleInputChange}/>
              </Grid>
              <Grid item xs>
                <Input
                  name='tenantTelephone'
                  label={'Tel. no.'}
                  value={values.tenantTelephone}
                  onChange={handleInputChange}/>
              </Grid>
            </Grid>
            <Grid item container spacing={4}>
              <Grid item xs={9}>
                <Input
                  name='tenantAddress'
                  label={'Address'}
                  value={values.tenantAddress}
                  onChange={handleInputChange}/>
              </Grid>
              <Grid item xs>
                <Input
                  name='tenantZipCode'
                  label={'ZIP Code'}
                  value={values.tenantZipCode}
                  onChange={handleInputChange}/>
              </Grid>
            </Grid>
            <Grid item container spacing={4}>
              <Grid item xs>
                <Input
                  name='tenantCity'
                  label={'Town / City'}
                  value={values.tenantCity}
                  onChange={handleInputChange}/>
              </Grid>
              <Grid item xs>
                <FormField label={'Country'}>
                  <CountriesDropDown
                    name='tenantCountry'
                    countryCode={values.tenantCountry}
                    onCountryChange={(countryCode) => setValue('tenantCountry', countryCode)}/>
                </FormField>
              </Grid>
            </Grid>
            <Grid item>
              <SwitchLabel
                name='billingAddressSame'
                label={'Billing Address same as Contact address'}
                value={values.billingAddressSame}
                onChange={() => setValue('billingAddressSame', !values.billingAddressSame)}/>
            </Grid>
            {
              !values.billingAddressSame &&
          <>
            <Grid item container spacing={4}>
              <Grid item xs={9}>
                <Input
                  name='billingAddress'
                  label={'Address'}
                  value={values.billingAddress}
                  onChange={handleInputChange}/>
              </Grid>
              <Grid item xs>
                <Input
                  name='billingZipCode'
                  label={'ZIP Code'}
                  value={values.billingZipCode}
                  onChange={handleInputChange}/>
              </Grid>
            </Grid>
            <Grid item container spacing={4}>
              <Grid item xs>
                <Input
                  name='billingCity'
                  label={'Town / City'}
                  value={values.billingCity}
                  onChange={handleInputChange}/>
              </Grid>
              <Grid item xs>
                <FormField label={'Country'}>
                  <CountriesDropDown
                    name='billingCountry'
                    countryCode={values.billingCountry}
                    onCountryChange={(countryCode) => setValue('billingCountry', countryCode)}/>
                </FormField>
              </Grid>
            </Grid>
          </>
            }
            <Grid container item spacing={2}>
              <Grid item>
                <Typography>Subscription details</Typography>
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name='subscriptionType'
                  label={'Subscription type'}
                  value={values.subscriptionType}
                  showSelectOption={false}
                  error={errors?.subscriptionType}
                  required
                  items={[{
                    value: 'standard',
                    text: 'Standard'
                  }]}
                  onChange={(e) => setValue('subscriptionType', e.target.value as string)}/>
              </Grid>
            </Grid>
            <Grid item container spacing={4}>
              <Grid item xs>
                <DatePicker
                  label={'Valid from *'}
                  onChange={(date) => {
                    if (date) {
                      setValue('validSince', toYMDString(date))
                    }
                  }}
                  value={values.validSince}/>
              </Grid>
              <Grid item xs>
                <DatePicker
                  label={'Valid to *'}
                  minDate={toYMDString(new Date(), 1)}
                  onChange={(date) => {
                    if (date) {
                      setValue('validThru', toYMDString(date))
                    }
                  }}
                  value={values.validThru}/>
              </Grid>
            </Grid>
            <Grid item>
              <SwitchLabel
                name='expirationNotify'
                label={'Notify the subscriber 15 days before licence expiration'}
                value={values.expirationNotify}
                onChange={() => setValue('expirationNotify', !values.expirationNotify)}/>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item>
                <Button
                  onClick={async () => {
                    const isValid = checkForErrors()
                    if (isValid) {
                      await submit(values)
                      onClose()
                    }
                  }}
                  variant='contained'
                  color='primary'>
                  {isEdit ? 'Save' : 'Save and invite subscriber'}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    dispatch(closeModal())
                    onClose()
                  }}
                  variant="contained"
                  color="primary">
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </LisaForm>
      </WithLoader>
    </Grid>
  )
}
