import { FC } from 'react'
import clsx from 'clsx'
import Icon, { IconProps } from '@material-ui/core/Icon'
import { supportedFiles } from 'utils/documents'

type DocumentIconProps = IconProps & {
  extension: string
}
export const DocumentIcon: FC<DocumentIconProps> = ({
  extension,
  fontSize = 'small',
  className,
  ...props
}) => {
  return (
    <Icon
      fontSize={fontSize}
      className={clsx(className, {
        'far fa-file-pdf':
          extension.toLowerCase() === '.pdf',
        'fas fa-file-csv':
          extension.toLowerCase() === '.csv',
        'far fa-file-word': [
          '.doc',
          '.docx'
        ].includes(extension.toLowerCase()),
        'far fa-file-powerpoint': [
          '.ppt',
          '.pptx'
        ].includes(extension.toLowerCase()),
        'far fa-file-excel': [
          '.xls',
          '.xlsx'
        ].includes(extension.toLowerCase()),
        'far fa-file-image': [
          '.jpg',
          '.png',
          '.gif',
          '.tiff',
          '.svg',
          '.bmp',
          '.jpeg'
        ].includes(extension.toLowerCase()),
        'far fa-file-alt': ['.txt', '.rtf'].includes(
          extension.toLowerCase()),
        'far fa-file': !supportedFiles.includes(extension.toLowerCase())
      })}
      {...props}/>
  )
}
