import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Grid,
  makeStyles
} from '@material-ui/core'
import { closeModal } from 'redux/slices/appSlice'
import { AircraftType, Manufacturer, Transition } from 'types'
import { useLisaForm } from 'common/Form/LisaForm'
import { TransitionInfoForm } from 'pages/Administration/TransitionForm/TransitionInfoForm'
import { applyManufacturerParams, wantsTransitionField } from 'utils/transition'
import { hasAllowedCharacters, INVALID_CHARACTERS } from 'utils/validators'
import Prompt from 'common/Dialogs/Prompt'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '12px',
    position: 'relative',
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  actionButtons: {
    padding: '32px 0px',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  }
}))

type FormValues = Partial<Transition>

type FormErrors = {
  name?: string
  transitionType?: string
  transitionComponent?: string
  manufacturer?: string
  aircraftType?: string
  msn?: string
  registration?: string
  apuPn?: string
  landingGears?: string
  manufacturerName?: string
  modelName?: string
}

const initialValues: FormValues = {
  name: '',
  transitionType: 1,
  description: ''
}

type TransitionFormProps = {
  transition?: Transition,
  isLoading: boolean,
  canCancel?: boolean,
  onSave: (values: FormValues) => Promise<{ isError: boolean, message: string }>
}

export const TransitionForm: FC<TransitionFormProps> = ({ isLoading, transition, onSave, canCancel = false }) => {
  const [_initialValues] = useState<FormValues>(transition ?? initialValues)
  const [valuesDifference, setValuesDifference] = useState(false)
  const dispatch = useDispatch()
  const classes = useStyles()
  const isEdit = transition !== undefined
  const [transitionError, setTransitionError] = useState(false)
  const [transitionErrorMessage, setTransitionErrorMessage] = useState('')

  const validate = useCallback((values: FormValues) => {
    const validationFields: (keyof FormErrors)[] = [
      'name', 'transitionType', 'transitionComponent', 'manufacturer', 'aircraftType', 'msn', 'registration'
    ]
    if (values?.transitionComponent && wantsTransitionField(values?.transitionComponent, 'landingGears')) {
      validationFields.push('landingGears')
    }
    if (values?.transitionComponent && wantsTransitionField(values?.transitionComponent, 'apuPn')) {
      validationFields.push('apuPn')
    }
    if (values.manufacturer === Manufacturer.Other) {
      validationFields.push('manufacturerName')
    }
    if (values.aircraftType === AircraftType.OTHER) {
      validationFields.push('modelName')
    }
    return validationFields.reduce(
      ({ errors: e, isValid }: {errors: FormErrors, isValid: boolean}, field: keyof FormErrors) => {
        if (!values[field]) {
          e[field] = 'This field is required.'
          isValid = false
        } else if (field === 'name' && !hasAllowedCharacters(values.name ?? '')) {
          e.name = `Name cannot contain ${INVALID_CHARACTERS}`
          isValid = false
        } else if (field === 'landingGears' && !values.landingGears?.length) {
          e[field] = 'This field is required.'
          isValid = false
        }
        return { errors: e, isValid }
      }, { errors: {}, isValid: true })
  }, [])

  const {
    values,
    setValues,
    errors,
    checkForErrors,
    setValue: setTransitionValue
  } = useLisaForm<FormValues, FormErrors>(
    { ..._initialValues },
    validate,
    false
  )

  useEffect(() => {
    setValues(applyManufacturerParams(values))
  }, [values.aircraftType])

  const handleClose = () => {
    dispatch(closeModal(['TRANSITION_FORM', 'TRANSITION_FORM_PROJECTS', 'TRANSITION_FORM_MENU']))
  }

  useEffect(() => {
    if (isEdit) {
      setValuesDifference(JSON.stringify(transition) !== JSON.stringify(values))
    }
  }, [values])

  return (
    <>
      <Prompt when={valuesDifference} pageName={'asset'} />
      <div className={classes.root}>
        <Grid container spacing={0}>
          <TransitionInfoForm
            transition={values}
            errors={errors ?? {}}
            transitionError={transitionError}
            transitionErrorMessage={transitionErrorMessage}
            onChange={setTransitionValue}/>
        </Grid>
        <div className={classes.actionButtons}>
          <Button
            onClick={async () => {
              const isValid = checkForErrors()
              if (isValid) {
                const { isError, message } = await onSave(values)
                if (!isError) {
                  handleClose()
                }
                setTransitionError(isError)
                setTransitionErrorMessage(message)
              }
            }}
            variant='contained'
            color='primary'
            size='small'
            disabled={isLoading}
          >
            Save
          </Button>
          {
            canCancel &&
            <Button
              onClick={handleClose}
              variant='contained'
              color='primary'
              size='small'
              disabled={isLoading}>
              Cancel
            </Button>
          }
        </div>
      </div>
    </>
  )
}
