import { makeStyles, Tooltip } from '@material-ui/core'
import { KeyboardEvent, useRef, useState } from 'react'
import useOutsideClick from 'hooks/useOutsideClick'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/slices/appSlice'
import { Search } from './components/Search'
import { updateSearchParams } from 'redux/slices/searchDocumentsSlice'
import InputSearch from 'common/LisaControls/InputSearch'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    color: theme.palette.black.main,
    background: theme.palette.statusColors.orange,
    padding: '2px 8px',
    borderRadius: '4px'
  },
  arrow: {
    color: theme.palette.statusColors.orange
  },
  modal: {
    '& .MuiDialogContent-root': {
      display: 'flex',
      paddingBottom: 32
    }
  }
}))

export const DocumentSearch = () => {
  const [searchText, setSearchText] = useState('')
  const dispatch = useDispatch()
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)
  const ref = useRef(null)
  const classes = useStyles()

  useOutsideClick(ref, () => {
    setTooltipIsOpen(false)
  })

  const handleClick = () => {
    dispatch(updateSearchParams({ searchText }))
    dispatch(openModal('DOCUMENT_SEARCH'))
  }

  const enterPressed = (event: KeyboardEvent) => {
    event.preventDefault()
    if (searchText.length === 0 || searchText.length > 2) {
      setTooltipIsOpen(false)
      if (event.key === 'Enter') {
        dispatch(openModal('DOCUMENT_SEARCH'))
        dispatch(updateSearchParams({ searchText }))
      }
    } else {
      if (event.key === 'Enter') {
        setTooltipIsOpen(true)
      }
    }
  }

  return (
    <>
      <Tooltip
        open={tooltipIsOpen}
        title="Min. 3 characters needed to perform search"
        placement="left"
        onOpen={() => setTooltipIsOpen(true)}
        onClose={() => setTooltipIsOpen(false)}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow
        classes={{
          arrow: classes.arrow,
          tooltip: classes.tooltip
        }}>
        <div ref={ref}>
          <InputSearch
            name="search"
            label="Find Documents"
            onChange={(e) => setSearchText(e.target.value)}
            onClick={handleClick}
            value={searchText}
            onKeyUp={enterPressed}/>
        </div>
      </Tooltip>
      <LisaModal
        className={classes.modal}
        fullScreen
        title={'Document Search'}
        noTitle
        modalType={'DOCUMENT_SEARCH'}
        maxWidth={'xl'}>
        <Search/>
      </LisaModal>
    </>
  )
}
