import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useA2KLabels } from 'redux/slices/a2kLabelsSlice'
import { Column, LisaTable, Row } from 'common/LisaTable/LisaTable'
import { setPage, setPreviewType, sortBy, toggleAll, toggleDocument, useA2KRule } from 'redux/slices/a2kRulesSlice'
import { LisaPaper } from 'common/LisaPaper/LisaPaper'
import { makeStyles, MenuItem, Tab, Tabs, Tooltip } from '@material-ui/core'
import { A2KRulePreviewResponse, A2KRulePreviewType } from 'services/api/labelRuleApi'
import { extractDocumentsForPreviewType } from 'utils/labellingRules'
import { AbbreviationTypeIcon } from 'common/AbbreviationTypeIcon/AbbreviationTypeIcon'
import { DocumentLink } from 'common/DocumentLink/DocumentLink'
import { DocumentLabels } from 'common/DocumentLabels/DocumentLabels'
import { openConfirmDialog } from 'redux/slices/appSlice'

const useStyles = makeStyles((theme) => ({
  tabs: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.blue.main,
    '& .MuiTab-textColorInherit': {
      opacity: 1
    },
    '& .MuiTab-root': {
      minWidth: '130px'
    }
  },
  tabsIndicator: {
    backgroundColor: `${theme.palette.blue.main} !important`,
    height: '5px !important'
  },
  toolbar: {
    justifyContent: 'center',
    '& .MuiTabs-root': {
      flex: 1
    }
  },
  tabTooltip: {
    maxWidth: 200
  }
}))

export interface LabellingRulesDocumentsProps {
  data?: A2KRulePreviewResponse
  isLoading: boolean
}
export const LabellingRulesDocuments: FC<LabellingRulesDocumentsProps> = ({ data, isLoading }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { selected: label } = useSelector(useA2KLabels)
  const [[addedDocsCount, excludedDocsCount, matchedDocsCount, skippedDocsCount], setCounts] = useState([0, 0, 0, 0])
  const {
    filters,
    selectedDocuments
  } = useSelector(useA2KRule)

  const {
    pageSize,
    sortOrder,
    sortColumn,
    previewType
  } = filters
  const { rows: documents, total, page, pages } = extractDocumentsForPreviewType(previewType, data)

  useEffect(() => {
    if (!isLoading) {
      setCounts([data?.addedDocsCount ?? 0, data?.excludedDocsCount ?? 0, data?.matchedDocsCount ?? 0, data?.skippedDocsCount ?? 0])
    }
  }, [isLoading, data])

  useEffect(() => {
    dispatch(setPage(1))
  }, [label?.labelId])

  const columns: Column[] = useMemo(() => {
    return [
      {
        id: 'docType',
        label: 'Doc Type',
        width: '60px',
        tableSortLabelProps: {
          active: sortColumn === 'DocType',
          direction: sortColumn === 'DocType' ? sortOrder || 'asc' : 'asc',
          onClick: () => dispatch(sortBy('DocType'))
        }
      },
      {
        id: 'name',
        label: 'File Name',
        className: 'fileName fileNameMD',
        tableSortLabelProps: {
          active: sortColumn === 'Filename',
          direction: sortColumn === 'Filename' ? sortOrder || 'asc' : 'asc',
          onClick: () => dispatch(sortBy('Filename'))
        }
      },
      {
        id: 'labels',
        label: 'Labels'
      }
    ]
  }, [sortColumn, sortOrder])

  const rows: Row[] = useMemo(() => {
    return documents.map((document) => ({
      id: `document-${document.documentId}`,
      selected: selectedDocuments.includes(document.documentId),
      document,
      cells: [
        {
          id: `document-${document.documentId}-docType`,
          value: (
            <AbbreviationTypeIcon
              docTypeRecognitionResultByPages={document.docTypeRecognitionResultByPages}
              docTypeRecognitionResult={document.docTypeRecognitionResult}/>
          )
        },
        {
          id: `document-${document.documentId}-fileName`,
          value: <DocumentLink document={{ ...document, name: document.fileName }} noDocType documentNameClass={'fileNameEllipsis'}/>
        },
        {
          id: `document-${document.documentId}-labels`,
          value: <DocumentLabels
            labels={document.labels.slice().sort((a, z) => {
              if (a.labelId === label?.labelId) {
                return 1
              }
              if (z.labelId === label?.labelId) {
                return -1
              }
              return 0
            })}/>
        }
      ]
    }))
  }, [documents, label?.labelId, previewType, selectedDocuments])

  const tabs = useMemo(() => {
    return (
      <Tabs
        value={previewType}
        centered
        onChange={(e, vt) => {
          dispatch(setPreviewType(vt))
          dispatch(setPage(1))
        }}
        classes={{
          root: classes.tabs,
          indicator: classes.tabsIndicator
        }}>
        <Tab value={A2KRulePreviewType.List} label={`List${matchedDocsCount ? ` [${matchedDocsCount}]` : ''}`}/>
        <Tab value={A2KRulePreviewType.Excluded} label={`Remove${excludedDocsCount ? ` [${excludedDocsCount}]` : ''}`}/>
        <Tab value={A2KRulePreviewType.Added} label={`Add${addedDocsCount ? ` [${addedDocsCount}]` : ''}`}/>
        <Tab value={A2KRulePreviewType.Skipped} label={
          <Tooltip title="This is a list of files which will be skipped by the labeling rule, since they were manually assigned."
            placement="top"
            classes={{ popper: classes.tabTooltip }}>
            <div>{`Skip${skippedDocsCount ? ` [${skippedDocsCount}]` : ''}`}</div>
          </Tooltip>}/>
      </Tabs>
    )
  }, [previewType, matchedDocsCount, excludedDocsCount, addedDocsCount, skippedDocsCount])

  const items = useMemo<ReactNode>(() => {
    return [
      <MenuItem
        key={'exclude-selected'}
        disabled={selectedDocuments.length === 0}
        onClick={() => dispatch(openConfirmDialog('EXCLUDE_A2K_RULES_DOCUMENTS'))}>
        Exclude selected documents
      </MenuItem>
    ]
  }, [selectedDocuments])
  const isExcludedList = previewType === A2KRulePreviewType.Excluded ||
    previewType === A2KRulePreviewType.Added

  return (
    <LisaPaper
      toolbar={{
        option: tabs,
        noTitle: true,
        toolbarClassName: classes.toolbar,
        items
      }}>
      <LisaTable
        disableRowsSelection={!isExcludedList}
        columns={columns}
        rows={rows}
        onSelectAll={() => dispatch(toggleAll(documents))}
        onRowToggle={(document) => dispatch(toggleDocument(document.documentId))}
        selectedRows={selectedDocuments}
        allRows={documents.map(_ => _.documentId)}
        isFetching={isLoading}
        page={page}
        pages={pages}
        pageSize={pageSize}
        total={total}
        onPageChange={(p) => dispatch(setPage(p))}/>
    </LisaPaper>
  )
}
