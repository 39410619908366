import { FC, FormEvent, MouseEvent, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { LisaForm, useLisaForm } from 'common/Form/LisaForm'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { Project } from 'types'
import { useUpdateOrCreateProjectMutation } from 'services/api/projectsApi'
import TextArea from 'common/LisaControls/TextArea'
import Input from 'common/LisaControls/Input'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
    padding: '24px 0 32px 0',
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiDivider-root': {
      width: '100%',
      margin: '10px 0px 0px 1px'
    }
  },
  userRole: {
    marginBottom: '2px'
  },
  actionButtons: {
    paddingTop: '32px',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  }
}))

interface FormValues {
  projectName: string
  description: string
}

const initialFormValues: FormValues = {
  projectName: '',
  description: ''
}

type FormErrors = {
  projectName?: string
}

interface ProjectFormProps {
  project?: Project
}

export const ProjectForm: FC<ProjectFormProps> = ({ project }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { userId } = useLisaAuth()
  const isEdit = project !== undefined
  const [_initialValues] = useState<FormValues>(isEdit
    ? {
      projectName: project.name,
      description: project.description
    }
    : initialFormValues
  )
  const [updateOrCreateProject, { data }] = useUpdateOrCreateProjectMutation()

  const validate = useCallback((values: FormValues) => {
    const validationFields: (keyof FormErrors)[] = ['projectName']

    return validationFields.reduce(
      ({ errors: e, isValid }: {errors: FormErrors, isValid: boolean}, field: keyof FormErrors) => {
        if (!values[field]) {
          e[field] = 'This field is required.'
          isValid = false
        }
        return { errors: e, isValid }
      }, { errors: {}, isValid: true })
  }, [])

  const {
    values,
    errors,
    handleInputChange,
    resetForm,
    checkForErrors
  } = useLisaForm<FormValues, FormErrors>(
    { ..._initialValues },
    validate,
    false
  )

  const handleClose = () => {
    dispatch(closeModal(['PROJECT_FORM', 'PROJECT_FORM_EDIT']))
  }

  const handleProjectActions = async (e: MouseEvent | FormEvent) => {
    e.preventDefault()
    const isValid = checkForErrors()
    if (isValid) {
      const { isError, message } = await updateOrCreateProject({
        userId,
        projectId: project?.projectId ?? '',
        name: values.projectName,
        description: values.description,
        isActive: true
      }).unwrap()
      if (isError && message) {
        dispatch(openToast({ severity: 'error', message }))
      } else {
        dispatch(openToast({
          severity: 'success',
          message: isEdit
            ? 'You have successfully updated a entity.'
            : 'You successfully created a entity'
        }))
        resetForm()
        handleClose()
      }
    }
  }

  return (
    <div className={classes.root}>
      <LisaForm onSubmit={handleProjectActions}>
        <Grid container spacing={2} >
          <Grid item xs={12}>
            <Input
              label='Entity name'
              name='projectName'
              required={true}
              value={values.projectName}
              onChange={handleInputChange}
              errMsg={errors?.projectName}
            />
            {data?.isError && values.projectName.length > 0 && <Typography variant='body2' color='error'>{data?.message ?? ''}</Typography>}
          </Grid>
          <Grid item xs={12}>
            <TextArea
              label='Description'
              name='description'
              value={values.description}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </LisaForm>
      <div className={classes.actionButtons}>
        <Button type='submit' onClick={handleProjectActions} variant='contained' color='primary' size='small'>
          {isEdit ? 'Edit' : 'Add'}
        </Button>
        <Button onClick={handleClose} variant='contained' color='primary' size='small'>
          Cancel
        </Button>
      </div>
    </div>
  )
}
