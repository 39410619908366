import { FC } from 'react'
import * as Icons from 'common/Icons/SvgIcons'
import { Grid, GridProps, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  transitionNameRoot: {
    columnGap: 4,
    paddingTop: 12,
    paddingBottom: 12
  },
  transitionNameTextWrapper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  transitionNameLg: {
    // paddingTop: 16
  },
  transitionNameText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  transitionNameTextLg: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.2px'
  },
  transitionNameTextMd: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '17px',
    letterSpacing: '-0.2px'
  },
  transitionIcon: {
    marginLeft: -2,
    display: 'flex',
    alignItems: 'center'
  }
}))

type TransitionNameProps = GridProps & {
  transitionName: string
  size?: 'md' | 'lg'
}
export const TransitionName: FC<TransitionNameProps> = ({ transitionName, size = 'md', ...gridProps }) => {
  const classes = useStyles()
  return (
    <Grid
      container
      alignItems={'center'}
      className={clsx(classes.transitionNameRoot, {
        [classes.transitionNameLg]: size === 'lg'
      })}
      {...gridProps}>
      <Grid item className={classes.transitionIcon}>
        <Icons.PlaneIco />
      </Grid>
      <Grid item xs className={clsx(classes.transitionNameText, {
        [classes.transitionNameTextMd]: size === 'md',
        [classes.transitionNameTextLg]: size === 'lg'
      })}>
        {transitionName}
      </Grid>
    </Grid>
  )
}
