import { FC, useEffect, useState } from 'react'
import { RoundIconButton } from 'common/RoundIconButton/RoundIconButton'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { LisaModal } from 'common/Dialogs/LisaModal'
import Dropdown from 'common/LisaControls/Dropdown'
import { TableViewRowType } from 'types'
import { useDispatch } from 'react-redux'
import { closeModal, openModal, openToast } from 'redux/slices/appSlice'
import { useDocumentId } from 'context/DocumentContext'
import { useAddTableViewRowMutation } from 'services/api/tableViewApi'

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    bottom: 50,
    right: 50
  },
  form: {
    paddingBlock: 16
  },
  helperTxt: {
    marginTop: 4
  }
}))

export type AddTableRowProps = {
  rowIndex?: number
}
export const AddTableRow: FC<AddTableRowProps> = ({ rowIndex }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const documentId = useDocumentId()
  const isRowSelected = rowIndex !== undefined
  const [rowType, setRowType] = useState(isRowSelected ? TableViewRowType.Context : TableViewRowType.New)
  const [addTableViewRow] = useAddTableViewRowMutation()

  const close = () => dispatch(closeModal())

  useEffect(() => {
    setRowType(isRowSelected ? TableViewRowType.Context : TableViewRowType.New)
  }, [rowIndex])

  return (
    <div className={classes.root}>
      <RoundIconButton
        onClick={() => dispatch(openModal('ADD_TABLE_VIEW_ROW'))}
        icon={<AddIcon fontSize={'large'}/>}/>
      <LisaModal modalType={'ADD_TABLE_VIEW_ROW'} title={'Add table row'} maxWidth={'xs'}>
        <Grid container direction={'column'} spacing={3} className={classes.form}>
          <Grid item>
            <Dropdown
              label={'Row type'}
              showSelectOption={false}
              value={rowType}
              onChange={(e) => setRowType(e.target.value as TableViewRowType) }
              items={[
                { value: TableViewRowType.Context, text: 'Context Row', disabled: !isRowSelected },
                { value: TableViewRowType.New, text: 'New Row' }
              ]}/>
            <Typography variant={'body2'} className={classes.helperTxt}>
              {rowType === TableViewRowType.Context && 'Context Row is always directly related to master row no.'}
              {rowType === TableViewRowType.New && 'New Row is always added as the last row.'}
            </Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Button
                onClick={async () => {
                  const { success, message } = await addTableViewRow({
                    rowIndex: rowType === TableViewRowType.Context ? rowIndex : undefined,
                    documentId
                  }).unwrap()
                  if (success) {
                    dispatch(openToast({ severity: 'success', message }))
                  }
                  close()
                }}
                variant="contained"
                color="primary"
                size="small">
              Add
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={close}
                variant="contained"
                color="primary"
                size="small">
              Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </LisaModal>
    </div>
  )
}
