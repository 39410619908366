import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { TransitionId } from 'types'
import { DashboardSection } from './DashboardSection'
import { TransitionItem } from 'common/Items/TransitionItem'
import { useGetRecentTransitionsQuery } from 'services/api/transitionApi'

export const RecentTransitions: FC = () => {
  const history = useHistory()
  const { hasAccess, userId } = useLisaAuth()
  const { data: recentTransitions = [], isLoading } = useGetRecentTransitionsQuery(userId)
  const hasResults = recentTransitions.length > 0

  const canViewA2k = hasAccess('perm_view_a2kstructure')

  const openTransition = (transitionId: TransitionId) => {
    history.push(`/structures/${canViewA2k ? 'import' : 'client'}/${transitionId}`)
  }

  return (
    <DashboardSection
      title={'Recent assets'}
      loading={isLoading}
      hasResults={hasResults}
      type={'transitions'}>
      <Grid item>
        <Grid container spacing={1}>
          {
            recentTransitions.map((transition) => (
              <Grid item xs={6} key={transition.transitionId}>
                <TransitionItem transition={transition} onClick={openTransition}/>
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    </DashboardSection>
  )
}
