import { FC } from 'react'
import { Grid, GridProps, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { GridGrow } from 'common/Grid/GridGrow'
import {
  WithDocumentsLoader,
  WithOcrQueueLoader,
  WithQueriesLoader,
  WithTransitionsLoader
} from 'common/WithLoader/WithLoader'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  titleWrapper: {
    height: 32
  },
  title: {
    marginTop: 6,
    fontWeight: 700,
    letterSpacing: '1.2px',
    fontSize: 15,
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,.7)'
  },
  loading: {
    backgroundColor: theme.palette.common.white
  },
  noResults: {
    backgroundColor: 'rgba(255,255,255,.4)'
  }
}))

const LoaderWrappers = {
  documents: WithDocumentsLoader,
  transitions: WithTransitionsLoader,
  queries: WithQueriesLoader,
  ocrQueue: WithOcrQueueLoader
}

type DashboardSectionProps = GridProps & {
  title?: string
  loading?: boolean
  hasResults?: boolean
  type?: 'documents' | 'queries' | 'transitions' | 'ocrQueue'
  itemContainerClass?: string
}
export const DashboardSection: FC<DashboardSectionProps> = ({
  title,
  loading = false,
  hasResults = false,
  children,
  type,
  itemContainerClass,
  ...props
}) => {
  const classes = useStyles()
  const WithLoader = type ? LoaderWrappers[type] : null
  return (
    <Grid container direction={'column'} className={classes.root} {...props}>
      <Grid item className={classes.titleWrapper}>
        <Typography variant="body2" className={classes.title}>{title ?? ''}</Typography>
      </Grid>
      <GridGrow
        item
        container
        className={clsx(itemContainerClass, {
          [classes.loading]: loading,
          [classes.noResults]: !loading && !hasResults
        })}>
        {
          WithLoader &&
          <WithLoader
            loading={loading}
            hasResults={hasResults}>
            {children}
          </WithLoader>
        }
        {
          !WithLoader && children
        }
      </GridGrow>
    </Grid>
  )
}
