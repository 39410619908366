import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { LabelsProvider, LabelsType } from 'context/LabelsContext'
import clsx from 'clsx'
import { Button, Chip, Grid, Typography } from '@material-ui/core'
import { LabelSearch } from 'common/LabelSearch/LabelSearch'
import { labelRemoved, reset, useQueryLabels } from 'redux/slices/queryLabelsSlice'
import { Label } from 'common/Label/Label'
import { LabelTree } from 'common/LabelTree/LabelTree'
import * as Icons from 'common/Icons/SvgIcons'
import { DocumentId, Query, QueryStatus, QueryVisibility, RiskLevel } from 'types'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { MaybeForbiddenAction } from 'common/MaybeForbiddenAction/MaybeForbiddenAction'
import { useDispatch, useSelector } from 'react-redux'
import { LisaForm, useLisaForm } from 'common/Form/LisaForm'
import { useTransitionContext } from 'context/TransitionContext'
import { QueryStatusSelect } from 'common/Select/QueryStatusSelect'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { useGetDocumentsByLabelV1Query } from 'services/api/labellingApi'
import { useCreateQueryMutation, useEditQueryMutation, useGetAssigneeUsersQuery } from 'services/api/queryApi'
import { useQueryFormStyles } from 'components/Query/queryStyles'
import { QueryRiskLevelSelect } from 'common/Select/QueryRiskLevelSelect'
import { WithLoader } from 'common/WithLoader/WithLoader'
import { QueryTypeSelect } from 'common/Select/QueryTypeSelect'
import Input from 'common/LisaControls/Input'
import TextArea from 'common/LisaControls/TextArea'
import DatePicker from 'common/LisaControls/DatePicker'
import SwitchLabel from 'common/LisaControls/SwitchLabel'
import Dropdown from 'common/LisaControls/Dropdown'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { toYMDString } from 'utils/date'

interface FormValues extends Partial<Query> {
  queryReferenceOption?: 'document'
}

const initialValues = (fv: Partial<FormValues> = {}): FormValues => ({
  riskLevel: RiskLevel.None,
  title: '',
  labelId: null,
  queryText: '',
  assignTo: '',
  dueDate: toYMDString(new Date()),
  ataChapter: '',
  documentId: '',
  queryStatus: 1,
  critical: false,
  partNo: '',
  serialNo: '',
  ...fv
})

type FormErrors = {
  title?: string
  queryText?: string
  riskLevel?: string
  queryPriority?: string
  assignTo?: string
  queryType?: string
  documentId?: string
  labelId?: string
  dueDate?: string
  wotc?: string
}

type AddNewQueryProps = {
  onComplete: () => void
  query?: Query
  documentId?: DocumentId
  showLabelSelector?: boolean
}
// @todo Lot of warnings and big component. Should refactor soon
export const QueryForm: FC<AddNewQueryProps> = ({
  onComplete,
  query: queryProps,
  documentId,
  showLabelSelector = true
}) => {
  const classes = useQueryFormStyles()
  const dispatch = useDispatch()
  const {
    hasAccess,
    userId
  } = useLisaAuth()
  const { transitionId } = useTransitionContext()
  const { selected: selectedLabel } = useSelector(useQueryLabels)
  const [localVisibility, setLocalVisibility] = useState(
    queryProps ? queryProps.visibility === QueryVisibility.External : !hasAccess('internal_external_flag'))
  const isEdit = queryProps !== undefined
  const isCreatedForDocument = queryProps && queryProps.documentId && !queryProps.labelId
  const canSelectDocument = documentId === undefined && !isCreatedForDocument
  const selectedLabelId = isEdit ? queryProps.labelId : selectedLabel?.labelId
  const [dueDate, setDueDate] = useState(isEdit ? queryProps.dueDate : new Date())
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const hasInternalExternalFlagAccess = hasAccess('internal_external_flag')
  const {
    data: a2kFiles = [],
    isLoading: isLoadingDocuments,
    fulfilledTimeStamp: lastTimeDocumentsLoaded
  } = useGetDocumentsByLabelV1Query({
    transitionId,
    labelId: selectedLabelId ?? 0
  }, {
    skip: !selectedLabelId
  })
  const [createQuery] = useCreateQueryMutation()
  const [editQuery] = useEditQueryMutation()
  const {
    data: assigneeUsers = [],
    isLoading: isLoadingAssignedUsers
  } = useGetAssigneeUsersQuery({
    transitionId,
    isExternal: localVisibility
  })
  const isLoading = isLoadingAssignedUsers

  const validate = useCallback((values: FormValues) => {
    const validationFields: (keyof FormErrors)[] = ['title', 'queryText', 'riskLevel', 'assignTo', 'queryType']
    if (!isEdit && canSelectDocument) {
      validationFields.push('labelId')
    }
    if (values.queryStatus === QueryStatus.WorkPack) {
      validationFields.push('wotc')
    }
    return validationFields.reduce(
      ({
        errors: e,
        isValid
      }: { errors: FormErrors, isValid: boolean }, field: keyof FormErrors) => {
        if (!values[field]) {
          e[field] = 'This field is required.'
          isValid = false
        }
        return {
          errors: e,
          isValid
        }
      }, {
        errors: {},
        isValid: true
      })
  }, [])

  const createOrUpdate = isEdit ? 'updatedBy' : 'createdBy'
  const {
    values: query,
    setValue: setQueryValue,
    setValues: setQueryValues,
    errors,
    handleInputChange,
    resetForm,
    checkForErrors
  } = useLisaForm<FormValues, FormErrors>(
    {
      ...initialValues({ documentId: documentId ?? '' }),
      ...queryProps,
      [createOrUpdate]: userId!,
      transitionId,
      visibility: localVisibility ? 2 : 1,
      ...(isEdit && { queryReferenceOption: 'document' })
    },
    validate,
    false)

  useEffect(() => {
    if (selectedLabelId && canSelectDocument) {
      setQueryValue('labelId', selectedLabelId)
    }
  }, [selectedLabelId])

  const fileReferences: { value: DocumentId, text: string }[] = useMemo(() => {
    return (a2kFiles || []).map((af) => ({
      value: af.documentId,
      text: af.name
    }))
  }, [query?.labelId, lastTimeDocumentsLoaded])

  const finalUserList = useMemo(() => {
    return assigneeUsers
      ? assigneeUsers.map((item) => ({
        value: item.userId,
        text: (<div className={classes.dropdownText}>
          <Typography className={classes.userName}>{`${item.firstName} ${item.lastName}`}</Typography>
          <Typography className={classes.userEmail}>{item.email}</Typography>
        </div>)
      }))
      : []
  }, [assigneeUsers])

  const isWorkPack = query.queryStatus === QueryStatus.WorkPack

  useEffect(() => () => {
    dispatch(reset())
    resetForm()
  }, [])

  const handleClose = () => {
    onComplete()
    dispatch(closeModal())
  }
  const handleSubmit = async (e: MouseEvent) => {
    e.preventDefault()
    setButtonDisabled(true)
    const isValid = checkForErrors()
    if (isValid) {
      const params: FormValues = {
        ...query,
        dueDate,
        visibility: localVisibility ? 2 : 1
      }
      if (params.queryId) {
        const {
          success,
          message
        } = await editQuery(params).unwrap()
        if (success && message) {
          dispatch(openToast({
            severity: 'success',
            message
          }))
        }
      } else {
        await createQuery(params)
        dispatch(openToast({
          severity: 'success',
          message: 'You successfully created new query'
        }))
      }
      handleClose()
    } else {
      setButtonDisabled(false)
    }
  }
  return (
    <LabelsProvider labelsType={LabelsType.Query}>
      <div className={classes.root}>
        <WithLoader loading={isLoading} hasResults={true}>
          <div className={clsx(classes.sideIndicator, `${query.riskLevel}`)}/>
          <div className={classes.priorityLabels}/>
          <LisaForm>
            <Grid container direction="column" spacing={1}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography className={classes.sectionTitle}>CONTENT</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label="Query title"
                    name="title"
                    required={true}
                    value={query.title}
                    onChange={handleInputChange}
                    errMsg={errors?.title}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextArea
                    label="Query body"
                    name="queryText"
                    required={true}
                    value={query.queryText}
                    onChange={handleInputChange}
                    errMsg={errors?.queryText}
                  />
                </Grid>
              </Grid>
              {
                isEdit &&
                <Grid container spacing={1}>
                  <Grid item xs={12} className={classes.groupContainer}>
                    <Typography className={classes.sectionTitle}>STATUS</Typography>
                  </Grid>
                  <Grid item xs={isWorkPack ? 6 : 12}>
                    <QueryStatusSelect
                      showSelectOption={false}
                      value={query.queryStatus ?? ''}
                      isQueryReportedByMe={query.reporter === userId}
                      onChangeEvent={handleInputChange}/>
                  </Grid>
                  {
                    isWorkPack &&
                    <Grid item xs={6}>
                      <Input
                        label="WO/TC reference *"
                        name="wotc"
                        value={query.wotc}
                        onChange={handleInputChange}
                        errMsg={errors?.wotc}
                      />
                    </Grid>
                  }
                </Grid>
              }
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.groupContainer}>
                  <Typography className={classes.sectionTitle}>PRIORITY</Typography>
                </Grid>
                <Grid item xs={6}>
                  <QueryRiskLevelSelect
                    value={query.riskLevel ?? RiskLevel.None}
                    onChange={(riskLevel) => {
                      setQueryValues({
                        riskLevel,
                        critical: riskLevel !== RiskLevel.High && query.critical ? false : query.critical
                      })
                    }}
                    error={errors?.riskLevel}/>
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label="Due Date"
                    name="dueDate"
                    required
                    disablePast={!isEdit}
                    value={dueDate}
                    onChange={(date: MaterialUiPickersDate) => date && setDueDate(date)}/>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '8px' }}>
                  <SwitchLabel
                    label="Critical"
                    name="critical"
                    value={query.critical}
                    onChange={(e, critical) => {
                      setQueryValues({
                        critical,
                        ...(critical && { riskLevel: RiskLevel.High })
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '8px' }}>
                  <MaybeForbiddenAction isForbidden={!hasInternalExternalFlagAccess}>
                    <SwitchLabel
                      label="External Query"
                      name="visibility"
                      value={localVisibility}
                      onChange={(e, checked) => setLocalVisibility(checked)}
                    />
                  </MaybeForbiddenAction>
                </Grid>
              </Grid>
              <Grid container item>

              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography className={classes.sectionTitle}>
                    ASSIGNMENT
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    label="Assign to"
                    name="assignTo"
                    required={true}
                    items={[...finalUserList]}
                    value={query.assignTo!}
                    onChange={handleInputChange}
                    error={errors?.assignTo}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.groupContainer}>
                  <Typography className={classes.sectionTitle}>
                    ADDITIONAL DATA
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <QueryTypeSelect
                    required
                    value={query.queryType ?? ''}
                    onChange={(qt) => setQueryValue('queryType', qt)}
                    error={errors?.queryType}/>
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label="ATA chapter"
                    name="ataChapter"
                    value={query.ataChapter}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label="Part no."
                    name="partNo"
                    value={query.partNo}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label="Serial no."
                    name="serialNo"
                    value={query.serialNo}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              {
                !isEdit && showLabelSelector &&
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.sectionTitle}>LABELS *</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LabelSearch/>
                  </Grid>
                  <Grid item xs={12}>
                    {
                      selectedLabel &&
                      <Label
                        label={selectedLabel}
                        handleDeleteLabel={() => dispatch(labelRemoved(selectedLabel))}/>
                    }
                  </Grid>
                  <Grid className={classes.labelsContainer} item xs={12}>
                    <LabelTree/>
                    {
                      errors?.labelId &&
                      <Typography variant="body2" color="error">
                        {errors?.labelId}
                      </Typography>
                    }
                  </Grid>
                </Grid>
              }

              {
                canSelectDocument && !isLoadingDocuments &&
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.sectionTitle}>
                      REFERENCE FILE
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Dropdown
                      name="documentId"
                      items={fileReferences}
                      value={query.documentId!}
                      onChange={handleInputChange}
                      error={errors?.documentId}
                    />
                  </Grid>
                </Grid>
              }
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.groupContainer}>
                  <Typography className={classes.sectionTitle}>
                    QUERY OVERVIEW
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.dynamicOverview}>
                    {localVisibility && <Icons.ExternalQuery/>}
                    {query.critical && (
                      <Icons.CriticalQuery className={classes.criticalColor}/>
                    )}
                    <Chip
                      className={clsx([
                        'QueryStatus',
                        {
                          info: query.riskLevel === 1,
                          low: query.riskLevel === 2,
                          medium: query.riskLevel === 3,
                          high: query.riskLevel === 4
                          // ["critical"]: query.riskLevel === 5,
                        }
                      ])}
                      label={'Open'}
                      size="small"
                      variant="outlined"
                    />
                    {query.queryStatus === QueryStatus.Sideletter && <Icons.SideletterQuery/>}
                    {
                      isEdit &&
                      <div className={classes.label}>
                        {
                          query.labelId &&
                          <Chip
                            variant="outlined"
                            size="small"
                            label={query.labelName}
                            component="span"
                          />
                        }
                        {query.documentName && query.documentName}
                      </div>
                    }
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.actionButtons}>
                    <Button
                      type='submit'
                      onClick={handleSubmit}
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={buttonDisabled}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      Cancel
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </LisaForm>
        </WithLoader>
      </div>
    </LabelsProvider>
  )
}
