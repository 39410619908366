import { DocumentId, Label, LabelId, LabelResponse, Nullable, WorkorderWorkpackage } from 'types'

/**
 * Maps label response to label structure and adds additional data needed for label rendering
 * @param lr
 * @param path
 * @param parentName
 * @param level
 */
export const createHierarchy = (lr: LabelResponse, path: LabelId[] = [], parentName: string = '', level = 0): Label => {
  const labelPath = [...path]
  if (lr.labelId) {
    labelPath.push(lr.labelId)
  }
  return {
    ...lr,
    parentName,
    path: [...labelPath],
    level,
    children: lr.children.map((lc) => createHierarchy(lc, labelPath, lr.name, level + 1))
  }
}

/**
 * Reduce tree to selected document labels list. We will ignore children in selected labels list
 * @param label
 */
export const selectedLabels = (label: Label | null): Label[] => {
  if (label === null) {
    return []
  }
  const thisLabel: Label[] = label.isSelected
    ? [{
      ...label,
      children: []
    }]
    : []
  return [...thisLabel, ...label.children?.flatMap((lc) => selectedLabels(lc))]
}

export const pickLabels = (labelTree: Label, labelIds: LabelId[]): Label[] => {
  const thisLabel = labelIds.includes(labelTree.labelId) ? [labelTree] : []
  return [...thisLabel, ...labelTree.children.flatMap((lc) => pickLabels(lc, labelIds))]
}

/**
 * Finds label object by label id
 * @param labelTree
 * @param labelId
 */
export const getLabel = (labelTree: Label, labelId: LabelId): Nullable<Label> =>
  pickLabels(labelTree, [labelId])[0] ?? null

/**
 * Checks if label name contains query string
 * @param label
 * @param query
 */
export const labelContains = (label: Label, query: string): boolean => {
  const l = label.name.toLowerCase()
  const q = query.toLowerCase()
  return l.indexOf(q) >= 0
}

/**
 * This helper method will create list from label tree structure.
 * Only labels without children are valid for labels list
 * @param label
 * @param query
 * @param minLevel
 */
export const searchTree = (label: Label | null, query: string, minLevel: number = 2): Label[] => {
  if (label === null || query === '') {
    return []
  }
  const isMatch = label.level! > minLevel && labelContains(label, query)
  const thisLabel = isMatch ? [label] : []
  return [...thisLabel, ...label.children?.flatMap((cl) => searchTree(cl, query, minLevel))]
}

/**
 * Will mark label in tree as (not)selected and modify documentId
 * @param labelTree
 * @param labelId
 * @param path
 * @param documentId
 */
export const toggleDocumentLabelInTree = (labelTree: Label, labelId: LabelId, path: LabelId[], documentId: DocumentId | null = null): Label => {
  const [lId, ...newPath] = path ?? []
  const newLabelTree = { ...labelTree }
  if (!lId) { // No tree or no label id in path then do nothing
    return newLabelTree
  }
  if (labelId === labelTree.labelId) { // If this is that label then modify it and return it
    newLabelTree.documentId = newLabelTree.documentId ?? documentId
    newLabelTree.isSelected = !newLabelTree.isSelected
    return newLabelTree
  }
  if (labelTree.labelId === lId) { // If this label's id is next in path then check its children
    newLabelTree.children = newLabelTree.children.map(
      (lc) => toggleDocumentLabelInTree(lc, labelId, newPath, documentId))
    return newLabelTree
  }
  return newLabelTree // If we are here then we are in wrong branch
}

/**
 * Search label tree by label id and toggles selection
 * @param labelTree
 * @param labelId
 * @param documentId // @todo not sure why we need this
 */
export const toggleDocumentLabelInTreeByLabelId = (labelTree: Label, labelId: LabelId, documentId: Nullable<DocumentId> = null): Label => {
  const newLabelTree = { ...labelTree }
  if (labelTree.labelId === labelId) {
    newLabelTree.isSelected = !newLabelTree.isSelected
    newLabelTree.documentId = documentId
    return newLabelTree
  }
  return {
    ...newLabelTree,
    children: newLabelTree.children.map((lc) => toggleDocumentLabelInTreeByLabelId(lc, labelId, documentId))
  }
}

export const toggleDocumentLabelInTreeByLabelIds = (labelTree: Label, labelIds: LabelId[]) =>
  labelIds.reduce((newHierarchy, labelId) =>
    toggleDocumentLabelInTreeByLabelId(newHierarchy, labelId), labelTree)

/**
 * Will select only that label in tree and will unselect all other
 * @param labelTree
 * @param label If null then it will deselect all labels
 * @param state If provided set label to that selected state; toggle otherwise
 */
export const selectLabelInTree = (labelTree: Label, label: Label | null, state?: boolean): Label => {
  let isSelected = false
  if (label !== null && label.labelId === labelTree.labelId) {
    isSelected = state ?? !labelTree.isSelected
  }
  return {
    ...labelTree,
    isSelected,
    children: labelTree.children.map((lc) => selectLabelInTree(lc, label))
  }
}

/**
 * Adds label in label tree. Skips adding if label is present
 * @param labelTree
 * @param label
 * @param parentName
 */
export const replaceLabelInTree = (labelTree: Label, label: LabelResponse, parentName: string = ''): Label => {
  if (labelTree.labelId === label.labelId) {
    return createHierarchy(label, (labelTree.path ?? []).slice(0, -1), parentName, labelTree.level)
  }
  return {
    ...labelTree,
    children: labelTree.children.map((lc) => replaceLabelInTree(lc, label, labelTree.name))
  }
}

/**
 * Checks if label can be deleted
 *
 * Label CANNOT be deleted if:
 *   - It has queries or documents
 *   - Its children has queries or documents
 * @param label
 */
export const isLabelDeletable = (label: Label | null): boolean => {
  if (label === null) {
    return false
  }
  if (!!label.documentCount || !!label.queryCount) {
    return false
  }
  return label.children.every((lc) => isLabelDeletable(lc))
}

/**
 * Compares recent and selected labels and pre select recent labels
 * @param recentLabels
 * @param selectedLabels
 */
export const preselectRecentLabels = (recentLabels: Label[], selectedLabels: Label[]): Label[] => {
  const selectedLabelIds = selectedLabels.map(_ => _.labelId)
  return recentLabels.map((label) => {
    const newLabel = { ...label }
    newLabel.isSelected = selectedLabelIds.includes(label.labelId)
    return newLabel
  })
}

/**
 * Count total documents and queries for given labels array and all of its sub labels
 * @param subLabels
 */
export const subLabelsDocAndQueryCount = (subLabels: Label[]): number => {
  return subLabels.reduce((total, label) => {
    return subLabelsDocAndQueryCount(label.children) + total
  }, subLabels.reduce((sum: number, sl) => sum + (sl.documentCount ?? 0) + (sl.queryCount ?? 0), 0))
}

/**
 * Converts label hierarchy to label list.
 * Will empty children array to reduce memory used
 * @param label
 * @param predicate
 */
export const toList = (label: Label, predicate: (l: Label) => boolean = () => true): Label[] => {
  const {
    children,
    ...rest
  } = label
  const l: Label[] = predicate(label)
    ? [{
      ...rest,
      children: []
    }]
    : []
  return [...l, ...children.flatMap((c) => toList(c, predicate))]
}

export const getParentLabel = (labelTree: Nullable<Label>, label: Nullable<Label>): Nullable<Label> => {
  if (!labelTree || !label) {
    return null
  }
  if (labelTree.labelId === label.parentId) {
    return labelTree
  }
  return labelTree.children.flatMap((lc) => getParentLabel(lc, label))[0] ?? null
}

/**
 * Returns label ids and all parent label ids and all children ids
 * @param labels
 * @param onlyParents
 */
export const getAllLabelIds = (labels: Label[], onlyParents: boolean = false): LabelId[] => {
  return Array.from(new Set(labels.flatMap((label) => [
    ...(label.path ?? []).filter(_ => _ !== label.labelId), // its parent ids; Filter out this label id because path has it
    ...(onlyParents ? [] : [label.labelId]), // this label id
    ...(onlyParents ? [] : getAllLabelIds(label.children, onlyParents)) // and it's children
  ])))
}

export const hasAdditionalInfo = (label: WorkorderWorkpackage): boolean =>
  !!(label.workorderUuid || label.workpackageUuid || label.eventUuid)

export const hasState = (label: WorkorderWorkpackage): boolean =>
  !!(label.workorderState || label.workpackageStatus || label.eventState)

export const isOpenedState = (label: WorkorderWorkpackage): boolean =>
  label.eventState === 'O' || label.workorderState === 'O' || label.workpackageStatus === 'Open'

export const labelPathName = (labelTree: Nullable<Label>, labelPath: LabelId[]): string => {
  if (labelTree === null || labelPath.length === 0) {
    return ''
  }
  const [labelId, childLabelId, ...restLabelPath] = labelPath
  if (!childLabelId) {
    return `/${labelTree.name}`
  }
  if (labelId === labelTree.labelId) {
    const currentLabelName = labelTree.parentId ? `/${labelTree.name}` : '' // We will skip root label
    return `${currentLabelName}${labelPathName(
      labelTree.children.find((c) => c.labelId === childLabelId) ?? null,
      [childLabelId, ...restLabelPath])}`
  }
  return ''
}
