import { FC, useMemo } from 'react'
import { QueryStatus } from 'types'
import { Chip, ChipProps, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { queryStatusColors, queryStatusOptions } from 'utils/queries'

const useStyles = makeStyles((theme) => ({
  chip: {
    fontSize: 12,
    borderRadius: 4,
    borderWidth: 2,
    textTransform: 'uppercase',
    padding: 4,
    '& .MuiChip-labelSmall': {
      paddingInline: 0
    }
  },
  chipClickable: {
    '&:hover': {
      backgroundColor: `${theme.palette.blue.main} !important`,
      color: `${theme.palette.common.white} !important`
    }
  },
  new: { borderColor: queryStatusColors.get(QueryStatus.New) },
  open: { borderColor: queryStatusColors.get(QueryStatus.Open) },
  pending: { borderColor: queryStatusColors.get(QueryStatus.Pending) },
  delayed: { borderColor: queryStatusColors.get(QueryStatus.Delayed) },
  workpack: { borderColor: queryStatusColors.get(QueryStatus.WorkPack) },
  closed: { borderColor: queryStatusColors.get(QueryStatus.Closed) },
  sideletter: { borderColor: queryStatusColors.get(QueryStatus.Sideletter) },
  noBorders: {
    borderWidth: 0
  }
}))

export interface QueryStatusChipProps extends ChipProps {
  queryStatus: QueryStatus
  active?: boolean
}

export const QueryStatusChip: FC<QueryStatusChipProps> = ({
  queryStatus,
  clickable,
  active,
  ...chipProps
}) => {
  const classes = useStyles()
  const label = useMemo(() => {
    return queryStatusOptions.find(_ => _.value === queryStatus)?.text ?? ''
  }, [queryStatus])
  return (
    <Chip
      size={'small'}
      variant={'outlined'}
      label={label}
      className={clsx(classes.chip, {
        [classes.chipClickable]: clickable && !active,
        [classes.new]: queryStatus === QueryStatus.New,
        [classes.open]: queryStatus === QueryStatus.Open,
        [classes.pending]: queryStatus === QueryStatus.Pending,
        [classes.delayed]: queryStatus === QueryStatus.Delayed,
        [classes.workpack]: queryStatus === QueryStatus.WorkPack,
        [classes.closed]: queryStatus === QueryStatus.Closed,
        [classes.sideletter]: queryStatus === QueryStatus.Sideletter,
        [classes.noBorders]: active === false
      })}
      clickable={clickable}
      {...chipProps}/>
  )
}
