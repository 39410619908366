import { FC } from 'react'
import { Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core'
import { formatBytes } from 'utils/file'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 4,
    backgroundColor: theme.palette.common.black
  },
  colorPrimary: {
    backgroundColor: `${theme.palette.common.black} !important`
  },
  bar: {
    borderRadius: 2,
    backgroundColor: theme.palette.lime.main
  },
  text: {
    color: theme.palette.black.light100,
    fontWeight: 600,
    marginTop: 20
  }
}))

export interface DocumentDownloadProgressProps {
  percentage: number
  size: number
}
export const DocumentDownloadProgress: FC<DocumentDownloadProgressProps> = ({ percentage, size }) => {
  const classes = useStyles()
  return (
    <Grid container direction={'column'} alignItems={'center'}>
      <LinearProgress
        value={percentage}
        classes={{
          root: classes.root,
          colorPrimary: classes.colorPrimary,
          bar: classes.bar
        }}
        variant={'determinate'}
        color={'primary'}/>
      <Typography variant={'body2'} className={classes.text}>
        Downloading {formatBytes(Number(size), 0)}
      </Typography>
    </Grid>
  )
}
