import { lisaApi } from 'services/api/lisaApi'
import { LisaResponse, Notification, LisaResponseExtended } from 'types'

type NotificationsArgs = {
  isEdit: boolean,
  notificationTemplateId: number,
  scheduledTime: Date,
  expirationTime: Date,
  outageFrom: Date,
  outageTo: Date
  createdTime?: Date
}

export interface TransformNotificationResponse {
  notifications: Notification[]
  isError: boolean
  message: string
}

export const notificationsApi = lisaApi.injectEndpoints({
  endpoints: (builder) => ({
    createOrEditSystemNotification: builder.mutation<LisaResponseExtended, NotificationsArgs>({
      query: ({ isEdit, ...rest }) => ({
        url: `/Notification/${isEdit ? 'UpdateSystemStatusNotification' : 'CreateSystemStatusNotification'}`,
        body: rest,
        method: isEdit ? 'PUT' : 'POST'
      }),
      invalidatesTags: ['Notifications', 'ActiveNotifications', 'UnseenNotifications']
    }),
    getSystemNotifications: builder.query<Notification[], void>({
      query: () => '/Notification/GetSystemStatusNotifications',
      transformResponse: (response: TransformNotificationResponse) => response.notifications,
      providesTags: ['Notifications']
    }),
    getActiveNotifications: builder.query<Notification[], void>({
      query: () => '/Notification/GetActiveUserNotifications',
      transformResponse: (response: TransformNotificationResponse) => response.notifications,
      providesTags: ['ActiveNotifications']
    }),
    getUnseenCountNotifications: builder.query<LisaResponse & { count: number}, void>({
      query: () => '/Notification/GetUnseenCountNotifications',
      providesTags: ['UnseenNotifications']
    }),
    updateSeenNotifications: builder.mutation<LisaResponse, void>({
      query: () => ({
        url: '/Notification/UpdateStatusesToSeen',
        method: 'PUT'
      }),
      invalidatesTags: ['UnseenNotifications']
    }),
    deleteNotification: builder.mutation<LisaResponseExtended, {
      createdTime: Date
    }>({
      query: ({
        createdTime = new Date()
      }) => ({
        url: '/Notification/DeleteSystemStatusNotification',
        method: 'GET',
        params: { createdTime }
      }),
      invalidatesTags: ['Notifications', 'UnseenNotifications', 'ActiveNotifications']
    })
  })
})

export const {
  useCreateOrEditSystemNotificationMutation,
  useGetSystemNotificationsQuery,
  useDeleteNotificationMutation,
  useGetActiveNotificationsQuery,
  useGetUnseenCountNotificationsQuery,
  useUpdateSeenNotificationsMutation
} = notificationsApi
