import { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import {
  OCRDocumentType,
  toggleDocumentIdForOCR,
  useOCRStatus
} from 'redux/slices/ocrStatusSlice'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import * as Icons from 'common/Icons/SvgIcons'
import { useTransitionId } from 'context/TransitionContext'
import { TableData } from 'common/TableData/TableData'
import { SelectAllCell } from './SelectAllCell'
import { SortByFileNameCell, SortByOcrStatusCell } from './SortCells'
import { DocumentLink } from 'common/DocumentLink/DocumentLink'
import { useGetIncompletedDocumentsQuery, useResendToOcrMutation } from 'services/api/ocrApi'
import { openToast } from 'redux/slices/appSlice'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { sortOcrDocuments } from 'utils/documents'

const useStyles = makeStyles((theme) => ({
  dataTableCheckbox: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.35rem'
    }
  },
  bodyRows: {
    height: '42px'
  },
  resendIcon: {
    display: 'flex',
    fontSize: '18px',
    fill: theme.palette.black.main,
    cursor: 'pointer',
    selfAlign: 'center',
    justifyContent: 'center'
  },
  statusLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  last: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

export const IncompleteDocuments: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { hasAccess } = useLisaAuth()
  const {
    documentIdsForOCR,
    filters
  } = useSelector(useOCRStatus)
  const {
    data: documents = [],
    isFetching
  } = useGetIncompletedDocumentsQuery(useTransitionId())
  const [resendToOcr] = useResendToOcrMutation()
  const { fileName, ocrStatus } = filters[OCRDocumentType.Incomplete]

  return (
    <TableData
      loading={isFetching}
      headCells={[
        <SelectAllCell
          key={'checkbox'}
          documents={documents}
          documentIds={documents.map(_ => _.documentId)}/>,
        <SortByFileNameCell key={'fileName'} ocrDocumentType={OCRDocumentType.Incomplete}/>,
        <SortByOcrStatusCell key={'ocrStatus'} ocrDocumentType={OCRDocumentType.Incomplete}/>,
        <TableCell style={{ width: '50px' }} key={'empty'}/>
      ]}
      tableRows={ sortOcrDocuments(documents, {
        fileName,
        ocrStatus
      }).map((row) => {
        const isItemSelected = documentIdsForOCR.includes(row.documentId)
        const labelId = `enhanced-table-checkbox-${row.documentId}`
        return (
          <TableRow
            hover={!isItemSelected}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.documentId}
            selected={isItemSelected}
            className={classes.bodyRows}
          >
            <TableCell
              padding="checkbox"
              onClick={() =>
                dispatch(toggleDocumentIdForOCR(row.documentId))
              }
              style={{ width: '40px' }}
            >
              <Checkbox
                className={classes.dataTableCheckbox}
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </TableCell>
            <TableCell
              id={labelId}
              scope="row"
              padding="none"
              style={{ width: 'auto' }}>
              <DocumentLink document={row} title={row.filePath}/>
            </TableCell>
            <TableCell align="right" style={{ width: '100px' }}>
              <div className={classes.statusLabel}>
                <Tooltip title="Incomplete">
                  <div>
                    <Icons.IncompleteIcon />
                  </div>
                </Tooltip>
              </div>
            </TableCell>
            <TableCell align="left" style={{ width: '50px' }}>
              <Tooltip title="Resend to OCR">
                <div className={classes.last}>
                  <Icons.ResendDocument
                    className={classes.resendIcon}
                    onClick={async () => {
                      if (!hasAccess('perm_act_resendtoocr')) {
                        return
                      }
                      const { success } = await resendToOcr(row.documentId).unwrap()
                      if (success) {
                        dispatch(openToast({ severity: 'info', message: `${row.name} resent to OCR` }))
                      }
                    }}
                  />
                </div>
              </Tooltip>
            </TableCell>
          </TableRow>
        )
      })
      }>
    </TableData>
  )
}
