import { FC, KeyboardEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { LisaForm, useLisaForm } from 'common/Form/LisaForm'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { hasAllowedCharacters, INVALID_FILE_NAME } from 'utils/validators'
import Input from 'common/LisaControls/Input'
import { useImportDocuments } from 'redux/slices/importDocumentsSlice'
import { useGetSubfoldersQuery, useRenameFolderMutation } from 'services/api/importApi'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '32px',
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  paper: {
    padding: '24px'
  },
  sectionTitle: {
    fontSize: '14px',
    margin: '32px 0 6px 0',
    fontWeight: 700,
    letterSpacing: '.8px',
    textTransform: 'uppercase'
  },
  actionButtons: {
    padding: '32px 0',
    display: 'flex',
    // columnGap: "10px",
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  }
}))

interface FormValues {
  name: string
}
interface FormErrors {
  name: string
}
const initialFormValues: FormValues = {
  name: ''
}

export const RenameFolderModal: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { selectedFolder, importFolderId } = useSelector(useImportDocuments)
  const { data: subfolders, isLoading: subfoldersLoading } = useGetSubfoldersQuery({ folderId: selectedFolder?.parentId! })

  initialFormValues.name = selectedFolder?.name!
  const {
    values, handleInputChange
  } = useLisaForm<FormValues, FormErrors>(initialFormValues)

  const [renameFolder] = useRenameFolderMutation()

  let nameExistsError = ''
  if (!subfoldersLoading) {
    const nameAlreadyExists = subfolders!.filter(x => x !== initialFormValues.name).some(x => x.toUpperCase() === values.name.toUpperCase())
    nameExistsError = nameAlreadyExists === true ? 'Folder with the same name already exists' : ''
  }

  const isEmpty = values.name.trim().length === 0
  const isNameValid = hasAllowedCharacters(values.name)
  const nameError = isNameValid || isEmpty ? '' : INVALID_FILE_NAME

  const close = () => dispatch(closeModal())

  const handleRenameFolder = async () => {
    if (isNameValid) {
      try {
        const { message, success } = await renameFolder({
          name: values.name.trim(),
          id: importFolderId,
          parentId: selectedFolder?.parentId!
        }).unwrap()
        if (message) {
          dispatch(openToast({
            severity: success ? 'success' : 'error',
            message
          }))
          if (success) {
            dispatch(closeModal())
          }
        }
      } catch (e) {
        const { data: { message } } = e as { data: { message: string } }
        if (message) {
          dispatch(openToast({
            severity: 'error',
            message
          }))
        }
      }
    }
  }

  const handleKeyUp = async (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      await handleRenameFolder()
    }
  }

  return (
    <div className={classes.root}>
      <LisaForm onSubmit={e => { e.preventDefault() }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Input
              label="Folder name"
              name="name"
              required={true}
              value={values.name}
              onChange={handleInputChange}
              onKeyUp={handleKeyUp}
              errMsg={nameError !== '' ? nameError : (nameExistsError ?? '')}
            />
          </Grid>
        </Grid>
      </LisaForm>
      <div className={classes.actionButtons}>
        {
          <Button
            onClick={handleRenameFolder}
            variant="contained"
            color="primary"
            size="small"
            disabled={isEmpty || !isNameValid}>
            Rename
          </Button>
        }
        <Button
          onClick={close}
          variant="contained"
          color="primary"
          size="small"
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}
