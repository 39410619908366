import { lisaApi } from 'services/api/lisaApi'
import {
  BusinessStatus,
  DocumentTypeId,
  DeletedDocumentsSortColumn,
  DeletedResults,
  SortDirection,
  TransitionId
} from 'types'

export const deletedApi = lisaApi.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query<DeletedResults, {
      transitionId: TransitionId
      operatorAnd: boolean
      operatorOr: boolean
      matchCase: boolean
      searchText: string
      page: number
      size: number
      hasFilter: boolean
      noLabel: boolean
      businessStatus: BusinessStatus | ''
      label: string
      hasQueries: string | number
      documentTypes: DocumentTypeId[]
      sortOrder?: SortDirection,
      sortColumn?: DeletedDocumentsSortColumn
    }>({
      query: (params) => ({
        url: 'Search',
        method: 'POST',
        body: {
          ...params,
          wildcard: params && params.searchText !== null && !!params.searchText?.match(/([*?])/),
          sortOrder: params.sortOrder ?? 'asc',
          sortColumn: params.sortColumn ?? 'Name'
        }
      }),
      providesTags: ['Documents'],
      transformResponse: (response: DeletedResults, meta, arg) => {
        if (meta?.response?.status === 204) {
          return {
            rows: [],
            page: 1,
            pages: 0,
            total: 0,
            pageSize: arg.size
          }
        }
        return {
          ...response,
          rows: response.rows.map(r => ({
            ...r,
            name: r.fileName,
            businessStatus: r.status
          }))
        }
      }
    })
  })
})

export const {
  useSearchQuery
} = deletedApi
