import { ReactNode, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, IconButton, makeStyles, MenuItem, Tooltip } from '@material-ui/core'
import { TreeView } from './components/TreeView'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { ClientDocuments } from './components/ClientDocuments'
import { toggleEmptyLabels, useClientLabels, setHierarchy } from 'redux/slices/clientLabelsSlice'
import { useTransitionId } from 'context/TransitionContext'
import { ExportProgress } from './components/ExportProgress'
import { WithLabelsLoader } from 'common/WithLoader/WithLoader'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { MaybeForbiddenAction } from 'common/MaybeForbiddenAction/MaybeForbiddenAction'
import { GridGrow } from 'common/Grid/GridGrow'
import { LisaPaper } from 'common/LisaPaper/LisaPaper'
import { SearchDocuments } from 'components/Transitions/SearchDocuments'
import { ClientModals } from './components/ClientModals'
import { openModal } from 'redux/slices/appSlice'
import { clearDocumentList, setExportPathName, useClientDocuments } from 'redux/slices/clientDocumentsSlice'
import * as Icons from 'common/Icons/SvgIcons'
import { getDeliveryBibleName, unsetDeliveryBibleName } from 'utils/transition'
import { useCheckExportProgressQuery, useGetClientLabelsQuery } from 'services/api/exportApi'

const useStyles = makeStyles(() => ({
  treeContainer: {
    height: 0,
    overflow: 'auto'
  }
}))

const Client = () => {
  const transitionId = useTransitionId()
  const {
    hierarchy,
    emptyLabels
  } = useSelector(useClientLabels)
  const { exportPathName } = useSelector(useClientDocuments)
  const { hasAccess } = useLisaAuth()
  const dispatch = useDispatch()
  const pathNameLocal = getDeliveryBibleName(transitionId)
  const classes = useStyles()
  const {
    data: exportProgress = {
      exportInProgres: false,
      exportedCount: 0,
      totalCount: 0,
      exportedPercent: 0
    },
    refetch: checkExportProgress
  } = useCheckExportProgressQuery({
    transitionId,
    exportName: exportPathName
  }, {
    skip: !exportPathName,
    pollingInterval: 20 * 1000
  })
  const { data: clientLabels, isFetching, fulfilledTimeStamp } = useGetClientLabelsQuery({
    transitionId,
    showAll: emptyLabels
  })

  useEffect(() => {
    if (exportProgress !== undefined && !exportProgress.exportInProgres) {
      unsetDeliveryBibleName(transitionId)
      dispatch(setExportPathName(''))
    }
  }, [exportProgress?.exportInProgres])

  useEffect(() => {
    dispatch(setExportPathName(pathNameLocal ?? ''))
    return () => {
      dispatch(clearDocumentList())
    }
  }, [])

  useEffect(() => {
    if (clientLabels !== undefined) {
      dispatch(setHierarchy(clientLabels))
    }
  }, [fulfilledTimeStamp])

  const items = useMemo<ReactNode>(() => {
    return [
      <MaybeForbiddenAction key="export-bible" isForbidden={!hasAccess('perm_act_exportdeliverybible')}>
        <MenuItem
          onClick={() => dispatch(openModal('EXPORT_DELIVERY_BIBLE'))}
          disabled={exportProgress && exportProgress.exportInProgres}>
          Export Delivery Bible
        </MenuItem>
      </MaybeForbiddenAction>
    ]
  }, [exportProgress, emptyLabels])

  const option = useMemo(() => {
    return <Tooltip title={emptyLabels ? 'Hide empty labels' : 'Show all labels'}>
      <IconButton onClick={() => dispatch(toggleEmptyLabels())}>
        {!emptyLabels ? <Icons.ShowAllLabels/> : <Icons.HideEmptyLabels/>}
      </IconButton>
    </Tooltip>
  }, [emptyLabels])

  return (
    <ContentWrapper newLayout>
      <GridGrow container direction="column">
        <SearchDocuments/>
        <GridGrow container item className={'columnGap1'}>
          <Grid
            // Left Side
            item
            container
            direction="column"
            xs={5}
            lg={4}>
            <LisaPaper toolbar={{
              title: 'Client export structure',
              items,
              option
            }} direction={'column'}>
              <Grid item container>
                {
                  exportPathName &&
                  <ExportProgress {...exportProgress} checkExportProgress={checkExportProgress}/>
                }
              </Grid>
              <GridGrow item container className={classes.treeContainer}>
                <WithLabelsLoader
                  loading={isFetching}
                  hasResults={hierarchy !== null && hierarchy.children.length > 0}>
                  <TreeView/>
                </WithLabelsLoader>
              </GridGrow>
            </LisaPaper>
          </Grid>
          <GridGrow
            // Right side
            item
            container
            xs={7}
            lg={8}>
            <ClientDocuments/>
          </GridGrow>
        </GridGrow>
      </GridGrow>
      <ClientModals/>
    </ContentWrapper>
  )
}

export default Client
