import { FC } from 'react'
import { QueryFlag } from 'types'
import Dropdown, { DropdownProps } from 'common/LisaControls/Dropdown'

export type SelectOptions = {
  value: QueryFlag
  text: string
  textSuffix?: string
}

export const QueryFlagItems: SelectOptions[] = [
  {
    value: QueryFlag.Critical,
    text: 'Critical'
  },
  {
    value: QueryFlag.Sideletter,
    text: 'Sideletter'
  }
]
export const QueryFlagSelect: FC<DropdownProps> = (props) => {
  return (
    <Dropdown
      items={QueryFlagItems}
      multiple
      {...props}
    />
  )
}
