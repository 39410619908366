import { FC } from 'react'
import { makeStyles, TextFieldProps } from '@material-ui/core'
import Input from 'common/LisaControls/Input'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '16px',
    fontWeight: 600,
    height: 'auto !important',
    background: theme.palette.common.white,
    '&.MuiFormControl-root': {
      borderRadius: '4px'
    }
  }
}))

type TextAreaProps = TextFieldProps & {
  errMsg?: string
}

const TextArea: FC<TextAreaProps> = ({ className, minRows = 15, ...props }) => {
  const classes = useStyles()
  return (
    <Input className={clsx(classes.root, className)} {...props} multiline minRows={minRows}/>
  )
}

export default TextArea
