import { lisaApi } from 'services/api/lisaApi'
import {
  DocumentId,
  LisaResponseExtended,
  OCRDocument,
  OCRLowQualityDocuments,
  OCRReport,
  OCRUnprocessedDocuments,
  OCRUnsupportedDocuments,
  ProcessingReport,
  SortDirection,
  TransitionId
} from 'types'

export const ocrApi = lisaApi.injectEndpoints({
  endpoints: (builder) => ({
    getOCRReport: builder.query<OCRReport, TransitionId>({
      query: (transitionId) => ({
        url: 'Document/OCRReport',
        params: { transitionId }
      }),
      providesTags: ['OCRReport']
    }),
    getProcessingReport: builder.query<ProcessingReport, TransitionId>({
      query: (transitionId) => ({
        url: 'Document/ProcessingReport',
        params: { transitionId }
      }),
      providesTags: ['ProcessingReport']
    }),
    getOCRUnprocessedDocuments: builder.query<OCRUnprocessedDocuments, TransitionId>({
      query: (transitionId) => ({
        url: 'Document/Unprocessed',
        params: { transitionId }
      }),
      providesTags: ['OCRReport']
    }),
    getIncompletedDocuments: builder.query<OCRDocument[], TransitionId>({
      query: (transitionId) => ({
        url: 'Document/Incomplete',
        params: { transitionId }
      }),
      providesTags: ['OCRReport']
    }),
    getOCRUnsupportedDocuments: builder.query<OCRUnsupportedDocuments, TransitionId>({
      query: (transitionId) => ({
        url: 'Document/Unsupported',
        params: { transitionId }
      }),
      providesTags: ['OCRReport']
    }),
    getOCRLowQualityDocuments: builder.query<OCRLowQualityDocuments, {
      transitionId: TransitionId,
      threshold?: number,
      page?: number,
      pageSize?: number,
      sortOrder?: SortDirection,
      sortColumn?: string,
    }>({
      query: ({
        transitionId,
        threshold = 95,
        page = 1,
        pageSize = 15,
        sortOrder = 'asc',
        sortColumn = 'Name'
      }) => ({
        url: 'Document/LowQuality',
        params: {
          transitionId,
          threshold,
          page,
          pageSize,
          sortOrder,
          sortColumn
        }
      }),
      providesTags: ['OCRReport']
    }),
    resendToOcr: builder.mutation<LisaResponseExtended, DocumentId>({
      query: (documentId) => ({
        url: `/Document/ResendToOcr?documentId=${documentId}`,
        method: 'PUT'
      }),
      invalidatesTags: ['OCRReport', 'ProcessingReport']
    }),
    bulkResendToOcr: builder.mutation<LisaResponseExtended, DocumentId[]>({
      query: (documentIds) => ({
        url: 'Document/BulkResendToOcr',
        method: 'PUT',
        body: documentIds
      }),
      invalidatesTags: ['OCRReport', 'ProcessingReport']
    })
  })
})

export const {
  useGetProcessingReportQuery,
  useGetOCRReportQuery,
  useGetOCRUnprocessedDocumentsQuery,
  useGetOCRUnsupportedDocumentsQuery,
  useGetOCRLowQualityDocumentsQuery,
  useResendToOcrMutation,
  useBulkResendToOcrMutation,
  useGetIncompletedDocumentsQuery
} = ocrApi
