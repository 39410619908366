import { FC } from 'react'
import * as Icons from 'common/Icons/SvgIcons'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  noDocuments: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.palette.black.light100
  },
  noDocumentIco: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      width: '52px',
      height: '52px',
      marginBottom: '24px',
      fill: theme.palette.black.light100
    }
  },
  PaperMessage: {
    display: 'flex',
    textAlign: 'center',
    fontSize: '20px',
    letterSpacing: '-.3px',
    fontWeight: 600
  }
}))

export const NoDocuments: FC<{ message?: string }> = ({ message = 'No documents' }) => {
  const classes = useStyles()
  return (
    <div className={classes.noDocuments}>
      {' '}
      <div className={classes.noDocumentIco}>
        <Icons.NoDocuments />
      </div>
      <Typography className={classes.PaperMessage}>
        { message }
      </Typography>
    </div>
  )
}
