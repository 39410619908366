import { FC } from 'react'
import clsx from 'clsx'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { QueryStatusIcon } from 'common/Icons/QueryStatusIcon'
import { TransitionName } from 'common/Names/TransitionName'
import { LisaBox } from 'common/LisaBox/LisaBox'
import { Query } from 'types'
import { QueryReferenceLabel } from 'components/Query/QueryReferenceLabel'
import { QueryStatusChip } from 'components/Query/QueryStatusChip'
import { QueryRiskLevelIndicator } from 'components/Query/QueryRiskLevelIndicator'

const useStyles = makeStyles((theme) => ({
  queryReference: {
    height: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 'auto',
    '& .MuiChip-root': {
      color: theme.palette.common.black
    }
  },
  hasWotc: {
    justifyContent: 'space-between'
  },
  wotc: {
    display: 'flex',
    gap: '5px',
    '& .wotcValue': {
      fontWeight: 600
    }
  },
  queryStatusIconContainer: {
    marginLeft: -4
  },
  queryStatusContainer: {
    columnGap: 4
  }
}))

type QueryItemProps = {
  query: Query
  onClick: (query: Query) => void
}

export const QueryItem: FC<QueryItemProps> = ({ query, onClick }) => {
  const classes = useStyles()
  const hasWotc = query.wotc !== ''

  return (
    <LisaBox
      container
      direction={'column'}
      style={{ height: 182 }}
      onClick={() => onClick(query)}>
      <Grid container item justifyContent={'space-between'}>
        <div className={classes.queryStatusIconContainer}>
          <QueryStatusIcon query={query}/>
        </div>
        <Grid item>
          <Grid container className={classes.queryStatusContainer}>
            <QueryStatusChip queryStatus={query.queryStatus}/>
            <QueryRiskLevelIndicator riskLevel={query.riskLevel}/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container>
        <TransitionName transitionName={query.transitionName ?? ''}/>
      </Grid>
      <Grid item container>
        <Typography variant='h6'>{query.title}</Typography>
      </Grid>
      <Grid item container className={clsx(classes.queryReference, {
        [classes.hasWotc]: hasWotc
      })}>
        {hasWotc && <div className={classes.wotc}>
          <span>WO/TC ref:</span>
          <div className='wotcValue'>{query.wotc}</div>
        </div>}
        {query.queryReference !== '' && <QueryReferenceLabel queryReference={query.queryReference}/>}
      </Grid>
    </LisaBox>
  )
}
