import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Grid, makeStyles } from '@material-ui/core'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { openModal } from 'redux/slices/appSlice'
import { NotificationCard } from './components/notifications/NotificationCard'
import NotificationForm from './components/notifications/NotificationForm'
import { useGetSystemNotificationsQuery } from 'services/api/notificationsApi'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: '16px 16px 32px 16px',
    '& .MuiGrid-container': {
      '&:first-child': {}
    },
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiGrid-item': {
    }
  },
  paper: {
    borderRadius: '4px',
    margin: '0px 10px 0px 0px',
    marginBottom: '0px'
  },
  searchWrapper: {
    marginBottom: 8
  },
  search: {
    marginBottom: '10px',
    '& .fas.fa-info-circle': {
      display: 'none'
    }
  },
  notificationButton: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  loader: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 200px)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '2px',
    margin: '4px'
  },
  noDocuments: {
    display: 'flex',
    margin: '5px',
    background: 'rgba(255,255,255,.4)',
    height: 'calc(100vh - 200px)',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.palette.black.light100
  },
  noDocumentIco: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      width: '52px',
      height: '52px',
      marginBottom: '24px',
      fill: theme.palette.black.light100
    }
  },
  notificationList: {
    rowGap: 10
  }
}))

export const Notifications: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data: notifications } = useGetSystemNotificationsQuery()

  return (
    <ContentWrapper newLayout>
      <Grid item xs={4} lg={8} className={classes.notificationButton}>
        <Button
          variant='contained'
          color='primary'
          size='large'
          onClick={() => dispatch(openModal('CREATE_NOTIFICATION'))}
        >
            ADD NOTIFICATION
        </Button>
      </Grid>
      <Grid container item xs={6} className={classes.notificationList}>
        {notifications?.filter(nt => nt.active).map(n => <NotificationCard key={n.createdTime.toLocaleString()} notification={n}/>)}
      </Grid>
      <LisaModal
        modalType={'CREATE_NOTIFICATION'}
        title={'ADD NOTIFICATION'}
        scroll='body'>
        <NotificationForm />
      </LisaModal>
    </ContentWrapper>
  )
}

export default Notifications
