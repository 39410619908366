import { Nullable, TransitionId } from 'types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'

export interface TransitionSlice { // @todo Consider moving to appSlice.ts
  currentTransitionId: Nullable<TransitionId> // To keep transition info in navigation
}

export const initialState: TransitionSlice = {
  currentTransitionId: null
}

const transitionSlice = createSlice({
  name: 'transition',
  initialState,
  reducers: {
    setCurrentTransitionId: (state, { payload }: PayloadAction<Nullable<TransitionId>>) => {
      state.currentTransitionId = payload
    }
  }
})

export const { setCurrentTransitionId } = transitionSlice.actions

export const useTransition = (state: RootState) => state.transition

export default transitionSlice.reducer
