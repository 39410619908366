import { FC, useEffect } from 'react'
import { Label } from 'types'
import { Button, makeStyles, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { clearAssignSelection, reset, setFrom, useReassignLabels } from 'redux/slices/reassignLabelsSlice'
import { isLabelDeletable, subLabelsDocAndQueryCount } from 'utils/labels'
import { LabelSearch } from 'common/LabelSearch/LabelSearch'
import { LabelTree } from 'common/LabelTree/LabelTree'
import { Label as LabelComponent } from 'common/Label/Label'
import { LabelsProvider, LabelsType } from 'context/LabelsContext'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { useTransitionId } from 'context/TransitionContext'
import { useRemoveLabelMutation } from 'services/api/labellingApi'
import { onLabelRemoved } from 'redux/slices/a2kLabelsSlice'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '32px',
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiTypography-caption': {
      textTransform: 'none',
      color: theme.palette.statusColors.red
    }
  },
  paper: {
    padding: '24px'
  },
  sectionTitle: {
    fontSize: '14px',
    margin: '16px 0 6px 0',
    fontWeight: 700,
    letterSpacing: '.8px',
    textTransform: 'uppercase'
  },
  actionButtons: {
    padding: '32px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  },
  selectedLabelContainer: {
    width: '100%',
    paddingBottom: 12
  },
  labelListResults: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '400px',
    overflow: 'auto',
    borderRadius: '4px',
    marginTop: '16px',
    background: theme.palette.common.white
  },
  sectionTitleMove: {
    fontSize: '14px',
    margin: '10px 0 6px 0',
    fontWeight: 700,
    letterSpacing: '.8px',
    textTransform: 'uppercase'
  },
  searchContainer: {
    marginTop: 10
  },
  fullText: {
    overflow: 'auto',
    whiteSpace: 'normal'
  }
}))

type DeleteLabelProp = {
  label: Label
}

export const DeleteEmptyLabel: FC<DeleteLabelProp> = ({ label }) => {
  return (
    <>
      <Typography variant="body1">Are you sure that you want to delete {label.name} label?</Typography>
      <Typography variant="caption" >This action can not be undone!</Typography>
    </>
  )
}

export const DeleteEmptyLabelWithEmptySubLabels: FC<DeleteLabelProp> = ({ label }) => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="body1" className={classes.fullText}>
        Are you sure that you want to delete {label.name} label and its sub-labels?
      </Typography>
      <Typography variant="caption" >This action can not be undone!</Typography>
    </>
  )
}

export const ReassignBeforeDeleting: FC<DeleteLabelProp> = ({ label }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { selected } = useSelector(useReassignLabels)
  return (
    <LabelsProvider labelsType={LabelsType.LabelReassign}>
      <Typography component={'span'} variant={'inherit'}>
        {`Label ${label.name} contains ${label.documentCount ?? 0} documents and
        ${label.queryCount ?? 0} queries. Assign new label to documents and queries to continue.`}
      </Typography>
      <div className={classes.selectedLabelContainer}>
        <Typography className={classes.sectionTitle} variant='h5'>CURRENT LABEL</Typography>
        <LabelComponent label={label}/>
        {
          selected &&
            <>
              <Typography className={classes.sectionTitleMove} variant='h5'>MOVE TO</Typography>
              <LabelComponent label={selected} handleDeleteLabel={() => { dispatch(clearAssignSelection(selected)) }} />
            </>
        }
        <div className={classes.searchContainer}>
          <LabelSearch/>
        </div>
        <div className={classes.labelListResults}>
          <LabelTree/>
        </div>
      </div>
    </LabelsProvider>
  )
}

export const NoDelete: FC<DeleteLabelProp> = () => {
  return (
    <>
      <Typography variant="body1" >Action not possible</Typography>
      <Typography variant="body2">
        To be able to delete label containing sub-labels with files and queries
        you need to assign new labels to those files and queries
      </Typography>
    </>
  )
}

export type ActionsProps = {
  handleClose: () => void
  handleConfirm?: () => void
}

export const DeleteActions: FC<ActionsProps> = ({ handleClose, handleConfirm = () => {} }) => {
  return (
    <>
      <Button
        onClick={handleConfirm}
        variant="contained"
        color="primary"
        size="small">
        Yes
      </Button>
      <Button
        onClick={handleClose}
        variant="contained"
        color="primary"
        size="small">
        Cancel
      </Button>
    </>
  )
}

export const ReassignActions: FC<ActionsProps> = ({ handleClose, handleConfirm = () => {} }) => {
  const { selected } = useSelector(useReassignLabels)
  return (
    <>
      <Button
        onClick={handleConfirm}
        variant="contained"
        color="primary"
        size="small"
        disabled={!selected}>
        Delete
      </Button>
      <Button
        onClick={handleClose}
        variant="contained"
        color="primary"
        size="small">
        Cancel
      </Button>
    </>
  )
}

export const NoDeleteActions: FC<ActionsProps> = ({ handleClose }) => {
  return (
    <Button
      onClick={handleClose}
      variant="contained"
      color="primary"
      size="small">
      Cancel
    </Button>
  )
}

const DeleteLabel: FC<{ label: Label }> = ({ label }) => {
  const classes = useStyles()
  const { selected } = useSelector(useReassignLabels)
  const dispatch = useDispatch()
  const transitionId = useTransitionId()
  const [removeLabel] = useRemoveLabelMutation()

  const handleClose = () => dispatch(closeModal())

  const handleRemoveLabel = async () => {
    const newLabelName = selected?.name
    const { isError, structure } = await removeLabel({ label: label, newLabelName, transitionId }).unwrap()
    if (isError) {
      dispatch(openToast({
        severity: 'error',
        message: `Label ${label.name} could not be removed`
      }))
    } else {
      if (newLabelName) {
        dispatch(openToast({
          severity: 'success',
          message: `Label ${label.name} removed, and all documents transferred to ${newLabelName}`
        }))
      } else {
        dispatch(openToast({
          severity: 'success',
          message: `Label ${label.name} removed`
        }))
      }
      dispatch(onLabelRemoved(structure))
      handleClose()
    }
  }

  useEffect(() => {
    dispatch(reset())
    dispatch(setFrom(label))
  }, [])

  const isDeletable = isLabelDeletable(label)

  const isEmpty = isDeletable && label.children.length === 0
  const isEmptyWithChildren = isDeletable && label.children.length > 0
  const noDelete = subLabelsDocAndQueryCount(label?.children ?? []) > 0
  const shouldReassign = label && !noDelete && (!!label.documentCount || !!label.queryCount)

  return (
    <div className={classes.root}>
      {
        isEmpty &&
          <DeleteEmptyLabel label={label}/>
      }
      {
        isEmptyWithChildren &&
          <DeleteEmptyLabelWithEmptySubLabels label={label}/>
      }
      {
        shouldReassign &&
          <ReassignBeforeDeleting label={label}/>
      }
      {
        noDelete &&
          <NoDelete label={label}/>
      }
      <div className={classes.actionButtons}>
        {
          (isEmpty || isEmptyWithChildren) &&
            <DeleteActions handleClose={handleClose} handleConfirm={handleRemoveLabel}/>
        }
        {
          shouldReassign &&
            <ReassignActions handleClose={handleClose} handleConfirm={handleRemoveLabel}/>
        }
        {
          noDelete &&
            <NoDeleteActions handleClose={handleClose}/>
        }
      </div>
    </div>
  )
}

export default DeleteLabel
