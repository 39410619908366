import { FC } from 'react'
import { Grid, GridProps, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  flexGrow: {
    flexGrow: 1
  }
}))

export const GridGrow: FC<GridProps> = ({
  children,
  className,
  ...props
}) => {
  const classes = useStyles()
  return (
    <Grid {...props} className={clsx(classes.flexGrow, className)}>
      {children}
    </Grid>
  )
}
