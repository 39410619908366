import { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IconButton, LinearProgress, makeStyles, Typography } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { uploadFolder, useImportDocuments, setCurrentImportHeaderInProgress } from 'redux/slices/importDocumentsSlice'
import { useTransitionContext } from 'context/TransitionContext'
import { LoadingState } from 'types'
import { closeModal, openToast } from 'redux/slices/appSlice'
import clsx from 'clsx'
import { importApi, useSetImportHeaderInProgressMutation } from 'services/api/importApi'
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks'

const useStyles = makeStyles((theme) => ({
  uploadFilesForm: {
    margin: '32px 0'
  },
  sectionTitle: {
    fontSize: '14px',
    margin: '32px 0 12px 0',
    fontWeight: 700,
    letterSpacing: '.8px',
    textTransform: 'uppercase'
  },
  filesWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    background: theme.palette.common.white,
    padding: '5px 7px 5px 16px',
    marginBottom: '4px',
    borderRadius: '4px',
    fontSize: '16px',
    lineHeight: '24px',
    '&:hover': {
      background: theme.palette.grey2.light
    }
  },
  selectedFiles: {
    margin: '32px 0',
    maxHeight: '50vh',
    overflow: 'auto'
  },
  filesWrapperDisabled: {
    background: theme.palette.grey2.light
  },
  progressWrapper: {
    height: 42,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.statusColors.blue,
    marginTop: '8px',
    '& .file-progress': {
      fontSize: '13px',
      lineHeight: '1',
      fontWeight: '500',
      color: theme.palette.black.light,
      margin: '4px 0'
    },
    '& .MuiLinearProgress-colorPrimary': {
      background: 'white'
    },
    '& .MuiLinearProgress-bar': {
      background: theme.palette.statusColors.blue
    }
  },
  progressBar: {
    width: '100%',
    height: 20
  }
}))

interface ImportProgress {
  importInProgres: boolean
  importedCount: number
  importedPercent: number
  totalCount: number
}

export const ImportFolderModal: FC = () => {
  const [files, setFiles] = useState<File[]>([])
  const dispatch = useDispatch()
  const classes = useStyles()
  const { fileUpload, selectedFolder } = useSelector(useImportDocuments)
  const { storageId, id: folderId } = selectedFolder!
  const { transitionId } = useTransitionContext()
  const uploadInProgress = fileUpload.loading === LoadingState.Pending // @todo Probably redundant but keeping it for now
  const [setImportHeaderInProgress] = useSetImportHeaderInProgressMutation()

  useEffect(() => {
    if (fileUpload.loading === LoadingState.Pending && !fileUpload.isError) {
      dispatch(closeModal())
    }
  }, [fileUpload.isError, fileUpload.loading])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget
    if (files !== null && files.length > 0) {
      setFiles((fs) => fs.concat(Array.from(files)))
    }
  }

  const removeFile = (index: number) => {
    setFiles((files) => files.filter((_, i) => i !== index))
  }

  const handleSubmit = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    if (storageId !== null && files.length > 0 && !uploadInProgress) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 104857600) {
          dispatch(openToast({
            severity: 'error',
            message: `File size of '${files[i].name}' is bigger than 100 MB.`
          }))
          return
        }
      }

      const result = setImportHeaderInProgress({ transitionId }).unwrap().then((importHeaderId) => {
        dispatch(setCurrentImportHeaderInProgress(importHeaderId))

        dispatch(importApi.util.updateQueryData('getImportHeaderInProgress', { id: importHeaderId }, (importProgress: MaybeDrafted<ImportProgress>) => {
          Object.assign(importProgress, { importInProgress: true })
        }))

        dispatch(uploadFolder({
          folderId,
          files,
          transitionId,
          importHeaderId,
          storageId
        }))
      })
    }
  }

  return (
    <div>
      <div className={classes.uploadFilesForm}>
        <Typography className={classes.sectionTitle}>Select Folder</Typography>
        <form>
          <input
            disabled={uploadInProgress}
            id="contained-button-file"
            multiple
            type="file"
            {...{ webkitdirectory: '', mozdirectory: '', directory: '' }}
            onChange={handleInputChange}
          />
        </form>
        <div className={classes.selectedFiles}>
          {
            files.map((file, index) => (
              <div
                key={file.name + index}
                className={clsx(classes.filesWrapper, { [classes.filesWrapperDisabled]: uploadInProgress })}>
                <div>{file.webkitRelativePath}</div>
                <IconButton
                  disabled={uploadInProgress}
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => removeFile(index)}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </div>
            ))
          }
        </div>
        {
          !uploadInProgress &&
          <Button
            disabled={files.length === 0 || fileUpload.loading === LoadingState.Pending}
            variant="contained"
            color="primary"
            onClick={handleSubmit}>
            UPLOAD FOLDER
          </Button>
        }
        {
          uploadInProgress &&
          <div className={classes.progressWrapper}>
            <LinearProgress className={classes.progressBar} variant="determinate" value={fileUpload.uploadPercent} />
          </div>
        }
      </div>
    </div>
  )
}
