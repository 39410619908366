import { memo } from 'react'
import { makeStyles } from '@material-ui/core'
import SingleListItem from './SingleListItem'
import { useTransitionId } from 'context/TransitionContext'
import { toFormattedString } from 'utils/date'
import { useGetProcessingReportQuery } from 'services/api/ocrApi'
import { ProcessingStatusReport } from 'pages/TransitionReports/components/ProcessingStatusReport'
import { Theme } from '@material-ui/core/styles'
import { ProcessingStatusesNames } from 'types'

const useStyles = makeStyles<Theme>(() => ({
  root: {
    background: 'white',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: '25px',
    height: '100%'
  },
  details: {
    padding: '20px 20px 0px 20px',
    '&:last-child': {
      padding: '20px 20px 20px 20px'
    }
  },
  sectionTitle: {
    fontWeight: 900,
    fontSize: 16,
    textTransform: 'uppercase',
    '& span': {
      fontWeight: 600,
      fontSize: 14,
      textTransform: 'lowercase'
    }
  },
  detailsWrapper: {
    maxHeight: 450,
    overflowY: 'scroll',
    scrollBehavior: 'smooth'
  }
}))

export const ProcessingReport = memo(() => {
  const transitionId = useTransitionId()
  const classes = useStyles()

  const {
    data: report = {
      dataExtractionInQueue: 0,
      dataExtractionSuccess: 0,
      dataExtractionCompleted: 0,
      dataExtractionIncomplete: 0,
      dataExtractionExtractedText: 0,
      dataExtractionError: 0,
      dataExtractionUnsupported: 0,
      dataExtractionFailed: 0,
      dataExtractionCanceled: 0,
      dataExtractionTimeout: 0,
      dtrInQueue: 0,
      dtrSuccess: 0,
      dtrError: 0,
      autoLabellingInQueue: 0,
      autoLabellingSuccess: 0,
      autoLabellingError: 0,
      autoLabellingNoMatch: 0,
      processingStarted: '',
      filesUploaded: 0,
      extractedPages: 0,
      avgExtractedPagesPerFile: 0,
      avgOcrQuality: 0
    }, isLoading
  } = useGetProcessingReportQuery(transitionId)
  const ifDateIsFromBeginning = report.processingStarted === '0001-01-01T00:00:00'

  if (isLoading) return null
  return (
    <div className={classes.root}>
      <ProcessingStatusReport />
      <div className={classes.detailsWrapper}>
        <div className={classes.details}>
          <div className={classes.sectionTitle}>{ProcessingStatusesNames.DATA_EXTRACTION} <span>(no. of files)</span></div>
          <SingleListItem majorRow indicatorColor='inQueue' text="InQueue" value={report.dataExtractionInQueue}/>
          <SingleListItem majorRow indicatorColor='success' text="Success" value={report.dataExtractionSuccess}/>
          <SingleListItem childRow text="Completed" value={report.dataExtractionCompleted}/>
          <SingleListItem childRow text="Incomplete" value={report.dataExtractionIncomplete}/>
          <SingleListItem majorRow indicatorColor='error' text="Error" value={report.dataExtractionError}/>
          <SingleListItem childRow text="Unsupported" value={report.dataExtractionUnsupported}/>
          <SingleListItem childRow text="Failed" value={report.dataExtractionFailed}/>
          <SingleListItem childRow text="Timeout" value={report.dataExtractionTimeout}/>
        </div>
        <div className={classes.details}>
          <div className={classes.sectionTitle}>{ProcessingStatusesNames.DTR} <span>(no. of files)</span></div>
          <SingleListItem majorRow indicatorColor='inQueue' text="InQueue" value={report.dtrInQueue}/>
          <SingleListItem majorRow indicatorColor='success' text="Success" value={report.dtrSuccess}/>
          <SingleListItem majorRow indicatorColor='error' text="Error" value={report.dtrError}/>
        </div>
        <div className={classes.details}>
          <div className={classes.sectionTitle}>{ProcessingStatusesNames.AUTO_LABELING} <span>(no. of files)</span></div>
          <SingleListItem majorRow indicatorColor='inQueue' text="InQueue" value={report.autoLabellingInQueue}/>
          <SingleListItem majorRow indicatorColor='success' text="Success" value={report.autoLabellingSuccess}/>
          <SingleListItem childRow text='of which sucessfully labelled:' value={report.autoLabellingSuccess - report.autoLabellingNoMatch}/>
        </div>
        <div className={classes.details}>
          <div className={classes.sectionTitle}>PROCESSING OVERVIEW</div>
          <SingleListItem text="Processing started" value={!ifDateIsFromBeginning ? toFormattedString(new Date(report.processingStarted + 'Z')) : 'N/A'}/>
          <SingleListItem text="Total no. of files uploaded" value={report.filesUploaded}/>
          <SingleListItem text="Total no. of extracted pages" value={report.extractedPages}/>
          <SingleListItem text="Avg no. of extracted pages per file" value={report.avgExtractedPagesPerFile}/>
          <SingleListItem text="Avg. OCR quality (%)" value={report.avgOcrQuality}/>
        </div>
      </div>
    </div>
  )
})

ProcessingReport.displayName = 'Processing report'
