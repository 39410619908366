import { FC } from 'react'
import { RiskLevel } from 'types'
import Dropdown from 'common/LisaControls/Dropdown'
import { queryRiskLevels } from 'utils/queries'

type QueryPrioritySelectProps = {
  value: RiskLevel
  onChange: (value: RiskLevel) => void
  error?: string
}
export const QueryRiskLevelSelect: FC<QueryPrioritySelectProps> = ({ value, onChange, error }) => {
  return (
    <Dropdown
      label="Priority"
      name="riskLevel"
      required={true}
      items={queryRiskLevels}
      showSelectOption={false}
      value={value}
      onChange={(e) => {
        const riskLevel = e.target.value as RiskLevel
        riskLevel && onChange(riskLevel)
      }}
      error={error}
    />
  )
}
