import { FC, ReactNode } from 'react'
import { Icon, IconButton, IconButtonProps, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 64,
    height: 64,
    background: theme.palette.blue.main,
    color: theme.palette.common.white,
    zIndex: 10,
    boxShadow: '2px 2px 10px 0 rgba(0,0,0,.3)',
    '&:hover': {
      background: theme.palette.lime.main,
      color: theme.palette.black.main
    },
    '& .MuiIcon-fontSizeSmall': {
      fontSize: '20px',
      lineHeight: '22px'
    }
  },
  rootSmall: {
    width: 42,
    height: 42
  },
  disabled: {
    background: theme.palette.grey[400],
    '&:hover': {
      background: theme.palette.grey[400],
      color: theme.palette.common.white
    }
  }
}))

export interface RoundIconButtonProps extends IconButtonProps {
  disabled?: boolean
  iconClass?: string
  icon?: ReactNode
}

export const RoundIconButton: FC<RoundIconButtonProps> = ({
  disabled = false,
  iconClass,
  icon,
  size,
  ...iconButtonProps
}) => {
  const classes = useStyles()
  return (

    <IconButton
      className={clsx(classes.root, {
        [classes.disabled]: disabled,
        [classes.rootSmall]: size === 'small'
      })}
      size={size}
      {...iconButtonProps}>
      { iconClass && <Icon className={iconClass} fontSize="small" /> }
      { icon && icon }
    </IconButton>
  )
}
