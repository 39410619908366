import { ChangeEvent, FC, Fragment } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { TransitionField } from './TransitionField'
import { AircraftType, EngineType, Manufacturer, Transition, TransitionComponent, TransitionFieldType, TransitionType } from 'types'
import {
  EngineNumberSelect,
  EngineTypeSelect,
  FamilyTypeSelect,
  landingGearOptions,
  LandingGearSelect,
  ManufacturerSelect,
  ModelSelect,
  TransitionComponentSelect,
  TypeSelect
} from './TransitionElements'
import Input from 'common/LisaControls/Input'

type EngineNumbers = 1 | 2 | 3 | 4

type TransitionInfoFormProps = {
  transition: Partial<Transition>
  errors: any // @todo
  transitionError: boolean
  transitionErrorMessage: string
  onChange: (field: string, value: any) => void
}
export const TransitionInfoForm: FC<TransitionInfoFormProps> = ({
  transition,
  transitionError,
  transitionErrorMessage,
  onChange,
  errors
}) => {
  const transitionComponent = transition.transitionComponent ?? 0
  const isAircraftTypeDefined = Number(transition.aircraftType) === AircraftType.OTHER || Number(transition.aircraftType) === 0

  const isPropellerEngine = transition.engineType === EngineType.Turboprop
  return (
    <Grid container direction={'column'}>
      <Grid container item spacing={2}>
        <TransitionField item xs={6} fieldType={'transitionName'} transitionComponent={transitionComponent}>
          <Input
            label='Asset Name'
            name='name'
            required={true}
            value={transition.name}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('name', e.target.value)}
            errMsg={errors?.name}
          />
          {transitionError && transition.name && transition.name.length > 0 && <Typography variant='body2' color='error'>{transitionErrorMessage}</Typography>}
        </TransitionField>
        <TransitionField item xs={6} fieldType={'transitionComponent'} transitionComponent={transitionComponent}>
          <TransitionComponentSelect
            error={errors?.transitionComponent}
            value={transition.transitionComponent ?? 0}
            onChange={(tc) => onChange('transitionComponent', tc)}/>
        </TransitionField>
        <TransitionField item xs={6} fieldType={'transitionType'} transitionComponent={transitionComponent}>
          <TypeSelect
            error={errors?.transitionType}
            value={transition.transitionType ?? TransitionType.PhaseIn}
            onChange={(tt) => onChange('transitionType', tt)}/>
        </TransitionField>
        <TransitionField item xs={6} fieldType={'manufacturer'} transitionComponent={transitionComponent}>
          <ManufacturerSelect
            error={errors?.manufacturer}
            value={transition.manufacturer ?? 0}
            onChange={(manufacturer) => {
              onChange('manufacturer', manufacturer)
            }}/>
        </TransitionField>
        {
          Number(transition.manufacturer) === Manufacturer.Other &&
          <TransitionField item xs={12} fieldType={'manufacturerName'} transitionComponent={transitionComponent}>
            <Input
              required
              label={'Manufacturer name'}
              name={'manufacturerName'}
              value={transition.manufacturerName}
              errMsg={errors?.manufacturerName}
              onChange={(e: ChangeEvent<{ value: string }>) => onChange('manufacturerName', e.target.value)}
            />
          </TransitionField>
        }
        <TransitionField item xs={6} fieldType={'aircraftType'} transitionComponent={transitionComponent}>
          <ModelSelect
            manufacturer={Number(transition.manufacturer) ?? Manufacturer.Other}
            error={errors?.aircraftType}
            value={Number(transition.aircraftType) ?? 0}
            onChange={(at) => {
              onChange('aircraftType', at)
            }}/>
        </TransitionField>
        <TransitionField item xs={6} fieldType={'familyType'} transitionComponent={transitionComponent}>
          <FamilyTypeSelect
            disabled={!isAircraftTypeDefined}
            value={transition.familyType || ''}
            onChange={(ft) => onChange('familyType', ft)}/>
        </TransitionField>
        {
          transition.aircraftType === AircraftType.OTHER &&
          <TransitionField item xs={12} fieldType={'modelName'} transitionComponent={transitionComponent}>
            <Input
              required
              label={'Model name'}
              name={'modelName'}
              value={transition.modelName}
              errMsg={errors?.modelName}
              onChange={(e: ChangeEvent<{ value: string }>) => onChange('modelName', e.target.value)}
            />
          </TransitionField>
        }
        <TransitionField item xs={6} fieldType={'msn'} transitionComponent={transitionComponent}>
          <Input
            required
            errMsg={errors?.msn}
            label={'MSN'}
            name={'msn'}
            value={transition.msn}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('msn', e.target.value)}
          />
        </TransitionField>
        <TransitionField item xs={6} fieldType={'variation'} transitionComponent={transitionComponent}>
          <Input
            label={'Variation'}
            name={'variation'}
            value={transition.variation ?? ''}
            type={'number'}
            onChange={(e: ChangeEvent<{ value: number | string }>) => {
              onChange('variation', e.target.value)
            }}
          />
        </TransitionField>
        <TransitionField item xs={6} fieldType={'previousRegistration'} transitionComponent={transitionComponent}>
          <Input
            required={true}
            label={'Previous Registration'}
            name={'registration'}
            errMsg={errors?.registration}
            value={transition.registration}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('registration', e.target.value)}
          />
        </TransitionField>
        <TransitionField item xs={6} fieldType={'newRegistration'} transitionComponent={transitionComponent}>
          <Input
            label={'New Registration'}
            name={'registrationNew'}
            value={transition.registrationNew}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('registrationNew', e.target.value)}
          />
        </TransitionField>
        <TransitionField item xs={12} fieldType={'owner'} transitionComponent={transitionComponent}>
          <Input
            label={'Owner'}
            name={'owner'}
            value={transition.owner}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('owner', e.target.value)}
          />
        </TransitionField>
        <TransitionField item xs={6} fieldType={'previousOperator'} transitionComponent={transitionComponent}>
          <Input
            label={'Previous Operator'}
            name={'previousOperator'}
            value={transition.previousOperator}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('previousOperator', e.target.value)}
          />
        </TransitionField>
        <TransitionField item xs={6} fieldType={'newOperator'} transitionComponent={transitionComponent}>
          <Input
            label={'New Operator'}
            name={'newOperator'}
            value={transition.newOperator}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('newOperator', e.target.value)}
          />
        </TransitionField>
        <TransitionField item xs={6} fieldType={'apuPn'} transitionComponent={transitionComponent}>
          <Input
            required
            errMsg={errors?.apuPn}
            label={'APU PN'}
            name={'apuPn'}
            value={transition.apuPn}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('apuPn', e.target.value)}
          />
        </TransitionField>
        <TransitionField item xs={6} fieldType={'apuSn'} transitionComponent={transitionComponent}>
          <Input
            label={'APU SN'}
            name={'apuSn'}
            value={transition.apuSn}
            onChange={(e: ChangeEvent<{ value: string }>) => onChange('apuSn', e.target.value)}
          />
        </TransitionField>
        <TransitionField item xs={6} fieldType={'engineAmount'} transitionComponent={transitionComponent}>
          <EngineNumberSelect
            max={transition.engineType === EngineType.Turboprop ? 2 : 4}
            disabled={!isAircraftTypeDefined}
            value={transition.engineAmount || 1}
            onChange={(engineAmount) => onChange('engineAmount', engineAmount)}/>
        </TransitionField>
        <TransitionField item xs={6} fieldType={'engineType'} transitionComponent={transitionComponent}>
          <EngineTypeSelect
            value={transition.engineType ?? ''}
            disabled={!isAircraftTypeDefined}
            onChange={(engineType) => onChange('engineType', engineType)}/>
        </TransitionField>
        <TransitionField item xs={6} fieldType={'landingGears'} transitionComponent={transitionComponent}>
          <LandingGearSelect
            disabled={!isAircraftTypeDefined}
            value={transition.landingGears ?? []}
            onChange={(landingGears) => onChange('landingGears', landingGears)}
            error={errors?.landingGears}/>
        </TransitionField>
        <Grid item xs={6}/>
        {
          (Array.from(Array(
            transition.transitionComponent !== TransitionComponent.Engine ? transition.engineAmount ?? 1 : 1
          ).keys()) as EngineNumbers[]).map((engineNumber: EngineNumbers) => (
            <Fragment key={`EngineSection${engineNumber}`}>
              <TransitionField
                item xs={6}
                fieldType={`engineESN${engineNumber + 1}` as TransitionFieldType}
                transitionComponent={transitionComponent}>
                <Input
                  label={`E${engineNumber + 1} SN`}
                  name={`engineESN${engineNumber}`}
                  value={transition[`engineESN${engineNumber + 1}` as keyof Transition]}
                  onChange={(e: ChangeEvent<{ value: string }>) => onChange(`engineESN${engineNumber + 1}`, e.target.value)}
                />
              </TransitionField>
              {
                isPropellerEngine &&
                  <>
                    <Grid item xs={6}/>
                    <TransitionField
                      item xs={6}
                      fieldType={`e${engineNumber + 1}PropellerSN` as TransitionFieldType}
                      transitionComponent={transitionComponent}>
                      <Input
                        label={`E${engineNumber + 1} Propeller SN`}
                        name={`e${engineNumber + 1}PropellerSN`}
                        value={transition[`e${engineNumber + 1}PropellerSN` as keyof Transition]}
                        onChange={(e: ChangeEvent<{ value: string }>) => onChange(`e${engineNumber + 1}PropellerSN`, e.target.value)}
                      />
                    </TransitionField>

                    <TransitionField
                      item xs={6}
                      fieldType={`e${engineNumber + 1}PropellerPN` as TransitionFieldType}
                      transitionComponent={transitionComponent}>
                      <Input
                        label={`E${engineNumber + 1} Propeller PN`}
                        name={`e${engineNumber + 1}PropellerPN`}
                        value={transition[`e${engineNumber + 1}PropellerPN` as keyof Transition]}
                        onChange={(e: ChangeEvent<{ value: string }>) => onChange(`e${engineNumber + 1}PropellerPN`, e.target.value)}
                      />
                    </TransitionField>
                  </>
              }
            </Fragment>
          ))
        }
        {
          transition.landingGears?.map((landingGear) => {
            const lg = landingGearOptions.find(_ => _.value === landingGear)
            if (!lg) {
              return null
            }
            const lgPnProp = `${lg.property}PN` as keyof Transition
            const lgSnProp = `${lg.property}SN` as keyof Transition
            return (
              <Grid item xs={12} key={landingGear}>
                <Grid container spacing={2}>
                  <TransitionField
                    item xs={6}
                    fieldType={'landingGearPNs'}
                    transitionComponent={transitionComponent}>
                    <Input
                      label={`${lg.text} PN`}
                      name={lgPnProp}
                      value={transition[lgPnProp]}
                      onChange={(e: ChangeEvent<{ value: string }>) => onChange(lgPnProp, e.target.value)}
                    />
                  </TransitionField>
                  <TransitionField
                    item xs={6}
                    fieldType={'landingGearSNs'}
                    transitionComponent={transitionComponent}>
                    <Input
                      label={`${lg.text} SN`}
                      name={lgSnProp}
                      value={transition[lgSnProp]}
                      onChange={(e: ChangeEvent<{ value: string }>) => onChange(lgSnProp, e.target.value)}
                    />
                  </TransitionField>
                </Grid>
              </Grid>
            )
          })
        }
      </Grid>
    </Grid>
  )
}
