import { FC, useEffect, useMemo } from 'react'
import { makeStyles, Table, TableBody, TableContainer, TableHead } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { TableViewRow } from './TableViewRow'
import { TableViewHeaderRow } from './TableViewHeaderRow'
import { WithDocumentsLoader } from 'common/WithLoader/WithLoader'
import { ParserStatus, TableViewRow as TableViewRowT } from 'types'
import clsx from 'clsx'
import { useDocumentId } from 'context/DocumentContext'
import {
  useCheckParserStatusQuery
} from 'services/api/documentApi'
import { openToast } from 'redux/slices/appSlice'
import { useDocument } from 'redux/slices/documentSlice'
import { AddTableRow } from 'components/TableView/AddTableRow'
import { useGetTableViewQuery, useUpdateTableViewCellMutation } from 'services/api/tableViewApi'

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    backgroundColor: theme.palette.common.white,
    // border: '2px solid #696969',
    marginInline: 3,
    width: 'calc(100% - 6px)',
    height: '100%',
    '&::-webkit-scrollbar': {
      // display: 'none'
    }
  },
  bodyTableRow: {
    '&:hover td:not(.status):not(.queries)': {
      backgroundColor: theme.palette.blue.main,
      color: theme.palette.common.white
    }
  },
  firstColumn: {
    left: 0,
    minWidth: 30,
    maxWidth: 30,
    textAlign: 'center'
  },
  secondColumn: {
    left: 64,
    minWidth: 130,
    maxWidth: 130
  },
  thirdColumn: {
    left: 228,
    minWidth: 50,
    maxWidth: 50
  },
  mediumCol: {
    minWidth: 150
  },
  clarificationStatus: {
    backgroundColor: theme.palette.statusColors.orange
  },
  openStatus: {
    backgroundColor: theme.palette.statusColors.blue
  },
  reviewedStatus: {
    backgroundColor: theme.palette.statusColors.green
  },
  irrelevantStatus: {
    backgroundColor: theme.palette.statusColors.grey
  },
  deletedStatus: {
    backgroundColor: theme.palette.statusColors.black
  },
  selected: {
    border: '2px solid red'
  }
}))

export const TableView: FC<{className?: string}> = ({ className }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { selectedCell } = useSelector(useDocument)
  const documentId = useDocumentId()
  const { data: parserStatus } = useCheckParserStatusQuery(documentId)
  const { data: tableView, isLoading } = useGetTableViewQuery(documentId, { skip: parserStatus !== ParserStatus.Success })
  const { data } = useUpdateTableViewCellMutation()[1]

  const contextRow: TableViewRowT | undefined = useMemo(() => {
    if (selectedCell === null || !tableView) {
      return undefined
    }
    const selectedRow = tableView.rows.find((row) => row.rowIndex === selectedCell[0])
    if (!selectedRow) {
      return undefined
    }
    if (Number(selectedRow.rowName)) {
      return selectedRow
    }
    const [rowName] = selectedRow.rowName.match(/\d+/)!
    return tableView?.rows.find((row) => row.rowName === rowName)
  }, [selectedCell])

  useEffect(() => {
    // Handle update response
    if (data && !data.success) {
      dispatch(openToast({
        severity: 'error',
        message: data?.message ?? 'Error saving cell content!'
      }))
    }
  }, [data?.message, data?.success])

  if (!tableView) {
    return null
  }
  const { header, rows } = tableView
  if (isLoading || header.columns === null || header.columns.length === 0) {
    // Not the best way. Fine for now
    return (
      <WithDocumentsLoader
        className={className}
        noResultsProps={{
          message: 'No Data'
        }}
        loadingText={'Loading Data...'}
        loading={isLoading}
        hasResults={false}/>
    )
  }
  return (
    <TableContainer className={clsx(classes.tableContainer, className)}>
      <Table stickyHeader>
        <TableHead>
          <TableViewHeaderRow
            rowData={header}/>
        </TableHead>
        <TableBody>
          {
            rows.map((row) => {
              return (
                <TableViewRow
                  key={row.rowIndex}
                  documentId={documentId}
                  rowData={row}
                  selectedColumn={selectedCell && selectedCell[0] === row.rowIndex ? selectedCell[1] : undefined}/>
              )
            })
          }
        </TableBody>
      </Table>
      <AddTableRow rowIndex={contextRow?.rowIndex}/>
    </TableContainer>
  )
}
