import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Report } from 'common/Report/Report'
import { Bar } from 'react-chartjs-2'
import { Chart, ChartData, ChartOptions } from 'chart.js'
import Dropdown from 'common/LisaControls/Dropdown'
import { Grid, makeStyles } from '@material-ui/core'
import { OverallTransitionProgressReportValue, ProgressType } from 'types'
import { ProgressIndicator } from 'common/Indicators/ProgressIndicator'
import { WithReportLoader } from 'common/WithLoader/WithLoader'
import {
  useGetOverallProgressReportQuery,
  useGetTransitionQuery, useUpdateOverallProgressNotesMutation,
  useUpdateReportProgressMutation
} from 'services/api/transitionApi'
import { useTransitionId } from 'context/TransitionContext'

const useStyles = makeStyles(() => ({
  dropdown: {
    height: 30,
    width: 160
  },
  title: {
    marginLeft: 10
  }
}))

const getConfig = (title: string): ChartOptions => ({
  indexAxis: 'y',
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: true,
      text: title,
      align: 'start'
    },
    tooltip: {
      callbacks: {
        label: ({ formattedValue }) => `Overall progress: ${formattedValue}%`
      }
    }
  },
  scales: {
    x: {
      max: 100,
      ticks: {
        callback: (v) => `${v}%`
      }
    }
  }
})
const progressColor = (progressType: ProgressType): string => {
  if (progressType === ProgressType.Low) {
    return '#e20000'
  }
  if (progressType === ProgressType.Medium) {
    return '#ff9e20'
  }
  return '#02c12b'
}
const barThickness = (numberOfRows: number): number => {
  if (numberOfRows < 10) {
    return 40
  }
  if (numberOfRows < 20) {
    return 20
  }
  return 10
}
interface ChartDataType extends ChartData {
  labels: string[]
  datasets: ChartData['datasets'] & {
    backgroundColor: string[]
    data: number[]
  }[]
}
const chartData = (data: OverallTransitionProgressReportValue[]): ChartDataType => {
  return data.reduce<ChartDataType>((cd, { transitionPercents, week, progress }, i) => {
    cd.labels.push(`W${week}`)
    cd.datasets[0].backgroundColor.push(progressColor(progress))
    // cd.datasets[0].data.push(transitionPercents)
    cd.datasets[0].data.push((cd.datasets[0].data[i - 1] ?? 0) + transitionPercents)
    // cd.datasets[0].barThickness = 8
    return cd
  }, {
    labels: [],
    datasets: [{
      backgroundColor: [],
      data: [],
      maxBarThickness: barThickness(data.length)
    }]
  })
  // chartD.datasets[0].data = chartD.datasets[0].data.map((d: number) => Math.round(d))
}

export const OverallTransitionProgressReport: FC = () => {
  const classes = useStyles()
  const chart = useRef<Chart>(null)
  const transitionId = useTransitionId()
  const { data: transitionData } = useGetTransitionQuery(transitionId)
  const transition = transitionData!
  const { data: overallProgressReport, isLoading } = useGetOverallProgressReportQuery(transitionId)
  const data = overallProgressReport?.data ?? []
  const config = useMemo(() => getConfig(transition.name), [transition.name])
  const latestWeek: OverallTransitionProgressReportValue | undefined = data[data.length - 1]
  const [currentProgress, setCurrentProgress] = useState(latestWeek?.progress ?? ProgressType.High)
  const [updateReportProgress] = useUpdateReportProgressMutation()
  const [updateNotes, { originalArgs }] = useUpdateOverallProgressNotesMutation()
  const notes = originalArgs?.notes ?? overallProgressReport?.notes ?? ''

  useEffect(() => {
    if (latestWeek) { // @todo or add loader for entire report in TransitionReports page
      setCurrentProgress(latestWeek.progress ?? ProgressType.High)
    }
  }, [latestWeek])

  const filters = useMemo(() => {
    if (isLoading) {
      return
    }
    return (
      <Dropdown
        className={classes.dropdown}
        disabled={latestWeek === undefined}
        label={''}
        showSelectOption={false}
        name={'progressType'}
        value={currentProgress}
        onChange={(e) => {
          if (latestWeek === undefined) {
            return
          }
          const progress = (e.target.value as ProgressType) * 1
          if (chart && chart.current) {
            // To apply proper color without animation
            chart.current.data = chartData([...data.slice(0, -1), { ...latestWeek, progress }])
            chart.current.update('none')
            setCurrentProgress(progress)
          }
          updateReportProgress({
            transitionId,
            progress,
            year: latestWeek.year,
            week: latestWeek.week
          })
        }}
        items={[
          { value: ProgressType.High, text: 'High Progress' },
          { value: ProgressType.Medium, text: 'Medium Progress' },
          { value: ProgressType.Low, text: 'Low Progress' }
        ]}/>
    )
  }, [data, currentProgress, isLoading])

  const cData = useMemo(() => chartData(data), [data])

  const ChartElement = useMemo(() => {
    return <Bar ref={chart} type={'bar'} data={cData} options={config}/>
  }, [data, config])

  return (
    <Report
      title={
        <Grid container alignItems={'center'}>
          <ProgressIndicator level={!isLoading ? currentProgress : undefined}/>
          <span className={classes.title}>Overall Asset Progress</span>
        </Grid>
      }
      chartHeight={80 + (cData.labels?.length || 3) * barThickness(data.length)}
      filters={filters}
      info={'Relation between text recognised document, labelled and reviewed documents and closed/sideletter queries compared to imported documents, labelled documents and amount of queries.'}
      reportNote={{
        note: notes,
        saveNote: (notes) => updateNotes({ transitionId, notes })
      }}>
      <WithReportLoader
        noWrapper
        loading={isLoading}
        hasResults={true}>
        {ChartElement}
      </WithReportLoader>
    </Report>
  )
}
