import { FC } from 'react'
import { DocumentReference, Label, LisaFile } from 'types'
import { Chip, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  ocrStatusCompleted: {
    background: `${theme.palette.statusColors.green} !important`,
    color: `${theme.palette.common.white} !important`,
    border: 'none !important',
    borderRadius: '4px',
    boxShadow: '0 0 10px 0 rgba(0,0,0,.2)',
    '&:hover': {
      background: `${theme.palette.statusColors.green} !important`
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&:focus': {
      background: `${theme.palette.statusColors.green} !important`,
      color: theme.palette.common.white
    }
  },
  ocrStatus: {
    display: 'inline-block',
    '& .MuiChip-colorPrimary': {
      background: theme.palette.success.dark
    },
    '& .MuiChip-colorSecondary': {
      background: theme.palette.info.dark
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.info.dark,
      cursor: 'pointer'
    }
  },
  ocrStatusProgress: {
    background: `${theme.palette.statusColors.blue} !important`,
    color: theme.palette.common.white,
    border: 'none !important',
    borderRadius: '4px',
    boxShadow: '0 0 10px 0 rgba(0,0,0,.2)',
    '&:hover': {
      background: `${theme.palette.statusColors.blue} !important`
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&:focus': {
      background: `${theme.palette.statusColors.blue} !important`,
      color: theme.palette.common.white
    }
  },
  referencesRow: {
    display: 'inline-block',
    '& .MuiChip-sizeSmall': {
      height: '24px',
      fontSize: '13px',
      fontWeight: 600,
      color: theme.palette.common.white,
      margin: '0 4px 4px 0px',
      border: `2px solid ${theme.palette.black.light}`,
      borderRadius: '4px',
      background: theme.palette.black.main,
      boxShadow: '0 2px 8px 0 rgba(0,0,0,.4)',
      '&:hover': {
        background: theme.palette.black.main
      }
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.common.white,
      '&:hover': {
        color: theme.palette.statusColors.red
      }
    }
  },
  labelsRow: {
    display: 'inline-block',
    '& .MuiChip-sizeSmall': {
      height: '24px',
      fontSize: '13px',
      fontWeight: 600,
      color: theme.palette.black.main,
      margin: '0 4px 4px 0px',
      border: `2px solid ${theme.palette.statusColors.blue}`,
      borderRadius: '4px',
      boxShadow: '0 2px 8px 0 rgba(0,0,0,.4)',
      background: theme.palette.common.white,
      '&:hover': {
        background: theme.palette.common.white
      }
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.black.light,
      '&:hover': {
        color: theme.palette.statusColors.red
      }
    }
  }
}))

type CompactReferenceHeaderProps = {
  document: LisaFile
  labels: Label[]
  documentReferences: DocumentReference[]
  className?: string
}
export const CompactReferenceHeader: FC<CompactReferenceHeaderProps> = ({
  document, labels, documentReferences, className
}) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.referencesRow}>
        <div className={classes.ocrStatus}>
          {document.isProcessed
            ? (
              <>
                <Chip
                  className={classes.ocrStatusCompleted}
                  size="small"
                  label="OCR Completed"
                />
              </>
            )
            : document.extension.toLowerCase() === '.csv'
              ? (
                <Chip
                  className={classes.ocrStatusProgress}
                  size="small"
                  label="Unsupported"
                />
              )
              : (
                <Chip
                  className={classes.ocrStatusProgress}
                  size="small"
                  label="OCR in Progress"
                />
              )}
        </div>
        {documentReferences &&
        documentReferences.map((item) => (
          <Chip
            key={item.referenceId}
            variant="outlined"
            size="small"
            label={`${item.referenceKey} ${item.referenceValue}`}
          />
        ))}
      </div>
      <div className={classes.labelsRow}>
        {labels &&
        labels.map((item) =>
          item.isSelected
            ? (
              <Chip
                key={item.labelId}
                variant="outlined"
                size="small"
                label={item.name}
              />
            )
            : null
        )}
      </div>
    </div>
  )
}
