import { FC } from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  info: {
    width: 20,
    height: 20,
    cursor: 'pointer',
    borderRadius: 50,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: 800,
    fontStyle: 'italic',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

type InfoIndicatorProps = {
  info: string,
  className?: string
}
export const InfoIndicator: FC<InfoIndicatorProps> = ({ info, className }) => {
  const classes = useStyles()
  return (
    <Tooltip title={info} placement={'left-start'} className={className}>
      <div className={classes.info}><i className="fas fa-info"/></div>
    </Tooltip>
  )
}
