import { Label, LabelId, LabelResponse, LoadingState } from 'types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createHierarchy, getLabel, searchTree, selectLabelInTree } from 'utils/labels'
import { initialLabelsState, labelsReducers, LabelsReducers, LabelsState, OpenedLabels } from './labelsSlice'
import { RootState } from 'redux/store'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

export interface MxHistoryLabelsState extends LabelsState {
  selected: Label | null
  emptyLabels: boolean
  wpStatus: string
  wpStartDate: MaterialUiPickersDate
  wpEndDate: MaterialUiPickersDate
  woState: string
}

export interface MxHistoryLabelsReducers extends LabelsReducers {
}

export const initialState: MxHistoryLabelsState = {
  ...initialLabelsState,
  selected: null,
  emptyLabels: true,
  wpStatus: '',
  wpStartDate: null,
  wpEndDate: null,
  woState: ''
}

const mxHistoryLabelsSlice = createSlice({
  name: 'MxHistoryLabels',
  initialState,
  reducers: {
    search: labelsReducers.search<MxHistoryLabelsState>(),
    toggle: labelsReducers.toggle<MxHistoryLabelsState>(),
    labelAdded: (state, { payload }: PayloadAction<Label>) => {
      if (payload.level! > 0) {
        state.hierarchy = selectLabelInTree(state.hierarchy!, payload)
        state.selected = payload
        state.searchResults = searchTree(state.hierarchy, state.searchTerm)
      }
    },
    toggleEmptyLabels: (state) => {
      state.emptyLabels = !state.emptyLabels
    },
    mxHistoryToggleLabelsInTree: (state, { payload }: PayloadAction<{ labelIds: LabelId[], labelsState?: boolean }>) => {
      const { labelsState, labelIds } = payload
      if (state.hierarchy) {
        state.openedLabels = {
          [state.hierarchy.labelId]: true, // Root is always open
          ...labelIds.reduce<OpenedLabels>((opened, labelId) => {
            opened[labelId] = labelsState ?? !opened[labelId]
            return opened
          }, {})
        }
      }
    },
    setHierarchy: (state, { payload }: PayloadAction<LabelResponse>) => {
      state.loadingHierarchy = LoadingState.Completed
      if (payload) { // @todo temporary condition. External users are getting null
        state.hierarchy = createHierarchy(payload)
        state.openedLabels[state.hierarchy.labelId] = true // Root is always open
        if (state.selected) {
          state.hierarchy = selectLabelInTree(state.hierarchy, state.selected)
          // We will need to update selected label to get fresh data
          const selected = getLabel(state.hierarchy, state.selected.labelId)
          if (selected) {
            state.selected = selected
          }
        }
      }
    },
    setWpStatus: (state, { payload }: PayloadAction<string>) => {
      state.wpStatus = payload
    },
    setWpStartDate: (state, { payload }: PayloadAction<MaterialUiPickersDate>) => {
      state.wpStartDate = payload
    },
    setWpEndDate: (state, { payload }: PayloadAction<MaterialUiPickersDate>) => {
      state.wpEndDate = payload
    },
    setWoState: (state, { payload }: PayloadAction<string>) => {
      state.woState = payload
    },
    resetFilters: (state) => {
      state.wpStatus = ''
      state.wpStartDate = null
      state.wpEndDate = null
      state.woState = ''
    }
  }
})

export const {
  search,
  toggle,
  labelAdded,
  toggleEmptyLabels,
  setHierarchy,
  mxHistoryToggleLabelsInTree,
  setWpStatus,
  setWpStartDate,
  setWpEndDate,
  setWoState,
  resetFilters
} = mxHistoryLabelsSlice.actions

export const mxHistoryLabelsReducers: MxHistoryLabelsReducers = {
  search,
  toggle,
  labelAdded
}

export const useMxHistoryLabels = (state: RootState) => state.mxHistoryLabels

export default mxHistoryLabelsSlice.reducer
