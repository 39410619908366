import { useParams } from 'react-router-dom'
import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import { DocumentId, TransitionId } from 'types'
import { DocumentViewer } from 'common/DocumentViewer/DocumentViewer'
import { GridGrow } from 'common/Grid/GridGrow'
import clsx from 'clsx'
import { useGetDocumentMetaDataQuery, useGetDocumentQuery } from 'services/api/documentApi'
import { useGetDocumentLabelsQuery } from 'services/api/labellingApi'

const useStyles = makeStyles(() => ({
  root: {
  },
  referenceHeader: {
    height: '200px',
    zIndex: 4
  },
  leftSide: {
    borderRight: '1px solid #6E6E6E'
  },
  side: {
    backgroundColor: '#353535',
    height: '100vh'
  }
}))

const Compare = () => {
  const classes = useStyles()
  const { compareId, transitionId, id } = useParams<{compareId: DocumentId, transitionId: TransitionId, id: DocumentId}>()
  // left document
  const { data: document, isLoading } = useGetDocumentQuery({ id, transitionId })
  const { data: { selected: selectedLabels } = { selected: [] } } = useGetDocumentLabelsQuery(id)
  const { data: documentMetaData } = useGetDocumentMetaDataQuery(id)
  // right document
  const { data: compare, isLoading: isLoadingCompare } = useGetDocumentQuery({ id: compareId, transitionId })
  const { data: { selected: compareSelectedLabels } = { selected: [] } } = useGetDocumentLabelsQuery(compareId)
  const { data: compareDocumentMetaData } = useGetDocumentMetaDataQuery(compareId)

  return (
    <div className={classes.root}>
      <Grid container>
        <GridGrow container item xs={6} className={clsx(classes.side, classes.leftSide)}>
          {
            isLoading && <CircularProgress/>
          }
          {
            !isLoading &&
                <DocumentViewer
                  key={'1'}
                  options={{ view: 'compact', referenceTitle: 'main document' }}
                  document={document!}
                  loadingDocument={isLoading}
                  labels={selectedLabels}
                  documentReferences={documentMetaData?.references ?? []} />
          }
        </GridGrow>
        <GridGrow container item xs={6} className={classes.side}>

          {
            isLoadingCompare && <CircularProgress/>
          }
          {
            !isLoadingCompare &&
                <DocumentViewer
                  key={'2'}
                  options={{ view: 'compact', referenceTitle: 'comparison document', referenceVariant: 'compare' }}
                  document={compare!}
                  loadingDocument={isLoadingCompare}
                  labels={compareSelectedLabels}
                  documentReferences={compareDocumentMetaData?.references ?? []} />
          }
        </GridGrow>
      </Grid>
    </div>
  )
}

export default Compare
