import { configureStore } from '@reduxjs/toolkit'
import rootReducer from 'redux/slices'
import { lisaApi } from 'services/api/lisaApi'
import { setupListeners } from '@reduxjs/toolkit/query'
import { apiErrorHandler } from 'services/api/apiErrorHandler'

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(lisaApi.middleware).concat(apiErrorHandler)
})

setupListeners(store.dispatch)

export default store

export type RootState = ReturnType<typeof store.getState>
