import { useSelector } from 'react-redux'
import { useGetLisaUsersQuery, useSearchLisaUsersQuery } from 'services/api/usersApi'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useUsers } from 'redux/slices/usersSlice'

export const useLisaUsers = () => {
  const { userId } = useLisaAuth()
  const {
    sortOrder,
    sortColumn,
    searchTerm: search,
    page,
    includeDeactivated
  } = useSelector(useUsers)
  const isSearch = !!search

  const { data, isFetching } = useGetLisaUsersQuery({ userId: userId!, page, sortOrder, sortColumn, includeDeactivated }, {
    skip: isSearch
  })
  const { data: searchData = [], isFetching: isSearching } = useSearchLisaUsersQuery({ search, includeDeactivated }, {
    skip: !isSearch
  })
  const users = !isSearch ? (data?.rows ?? []) : searchData
  const isLoading = isFetching || isSearching

  return {
    users,
    isLoading,
    pageSize: data?.pageSize ?? 200,
    total: data?.total ?? 0,
    pages: isSearch ? 1 : (data?.pages ?? 1)
  }
}
