import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ClientDocument, ClientFolderId, DocumentId, SortDirection, User } from 'types'
import { RootState } from 'redux/store'
import { nextSortDirection } from 'utils/filters'
import { toggleDocumentsOnPage } from 'utils/documents'

export type ClientDocumentsSortColumn = 'Name' | 'Query' | 'BusinessStatus' | 'DocType'

export const SortColumnToConfigMap: Map<ClientDocumentsSortColumn, keyof User['settings']['clientSettings']> = new Map([
  ['Name', 'clientListFilename'],
  ['DocType', 'clientListDocumentType'],
  ['Query', 'clientListQueries'],
  ['BusinessStatus', 'clientListDocumentStatus']
])

export interface ClientDocumentsSlice {
  documentIds: DocumentId[]
  filters: {
    sortOrder: SortDirection
    sortColumn: ClientDocumentsSortColumn
    page: number
    pageSize: number
  },
  clientFolderId: ClientFolderId
  exportPathName: string
}

export const initialState: ClientDocumentsSlice = {
  documentIds: [],
  filters: {
    sortOrder: 'asc',
    sortColumn: 'Name',
    page: 1,
    pageSize: 200
  },
  clientFolderId: 0,
  exportPathName: ''
}

const clientDocumentsSlice = createSlice({
  name: 'clientDocuments',
  initialState,
  reducers: {
    setFolderId: (state, { payload }: PayloadAction<ClientFolderId>) => {
      state.clientFolderId = payload
    },
    sortBy: (state, { payload }: PayloadAction<ClientDocumentsSortColumn>) => {
      const { sortColumn, sortOrder } = state.filters
      state.filters.sortOrder = nextSortDirection(sortColumn === payload ? (sortOrder ?? false) : false)
      state.filters.sortColumn = payload
      state.filters.page = 1
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.filters.page = payload
    },
    toggleAllDocumentIds: (state, { payload }: PayloadAction<ClientDocument[]>) => {
      state.documentIds = toggleDocumentsOnPage(payload.map(_ => _.documentId), state.documentIds)
    },
    toggleDocumentId: (state, { payload }: PayloadAction<DocumentId>) => {
      if (state.documentIds.includes(payload)) {
        state.documentIds = state.documentIds.filter(_ => _ !== payload)
      } else {
        state.documentIds = [...state.documentIds, payload]
      }
    },
    resetSelectedDocuments: (state) => {
      state.documentIds = []
    },
    clearDocumentList: (state) => {
      state.clientFolderId = 0
    },
    emptyDocuments: (state) => {
      state.documentIds = []
    },
    setExportPathName: (state, { payload }: PayloadAction<string>) => {
      state.exportPathName = payload
    }
  }
})

export const {
  setPage,
  toggleAllDocumentIds,
  toggleDocumentId,
  setFolderId,
  resetSelectedDocuments,
  clearDocumentList,
  emptyDocuments,
  setExportPathName,
  sortBy
} = clientDocumentsSlice.actions

export const useClientDocuments = (state: RootState) => state.clientDocuments

export default clientDocumentsSlice.reducer
