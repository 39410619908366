import { FC } from 'react'
import { OCRQueueProgress } from 'components/JobProgress/OCRQueueProgress'
import { Grid, makeStyles } from '@material-ui/core'
import { DashboardSection } from './DashboardSection'
import { useGetOcrQueueReportingQuery } from 'services/api/transitionApi'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  itemContainerClass: {
    height: 0,
    overflow: 'auto',
    '& .ocrProgressItem:last-child': {
      marginBottom: 0
    },
    '&.smallerScreen': {
      flex: 1,
      overflow: 'hidden'
    }
  }
}))

export const OcrQueue: FC = () => {
  const classes = useStyles()
  const { data: ocrQueueJob = [], isLoading } = useGetOcrQueueReportingQuery(undefined, {
    pollingInterval: 2 * 60 * 1000
  })

  return (
    <DashboardSection
      title={'OCR queue'}
      loading={isLoading}
      hasResults={ocrQueueJob.length > 0}
      itemContainerClass={clsx(classes.itemContainerClass, {
        smallerScreen: ocrQueueJob.length === 0
      })}
      type={'ocrQueue'}>
      <Grid container>
        {
          ocrQueueJob.map((job, index) => (
            <OCRQueueProgress
              key={job.transitionName}
              orderNo={index + 1}
              {...job}/>
          ))
        }
      </Grid>
    </DashboardSection>
  )
}
