import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { Label, LoadingState } from 'types'
import { searchTree, selectedLabels, toggleDocumentLabelInTree, toggleDocumentLabelInTreeByLabelId } from 'utils/labels'
import { initialLabelsState, labelsReducers, LabelsReducers, LabelsState } from './labelsSlice'
import { RootState } from 'redux/store'

export interface DocumentLabelsState extends LabelsState {
  selected: Label[] // List of labels assigned to document
  isScrolling: boolean | null
}

export interface DocumentLabelsReducers extends LabelsReducers {
}

export const initialState: DocumentLabelsState = {
  ...initialLabelsState,
  selected: [],
  isScrolling: null
}

const documentLabelsSlice = createSlice({
  name: 'documentLabels',
  initialState,
  reducers: {
    search: labelsReducers.search<DocumentLabelsState>(),
    toggle: labelsReducers.toggle<DocumentLabelsState>(),
    labelAdded: (state, { payload }: PayloadAction<Label>) => {
      if (payload.path && payload.path.length > 0) {
        state.hierarchy = toggleDocumentLabelInTree(state.hierarchy!, payload.labelId, payload.path!, payload.documentId!)
      } else {
        // Recent labels are not part of the tree structure, and they do not have path
        state.hierarchy = toggleDocumentLabelInTreeByLabelId(state.hierarchy!, payload.labelId, payload.documentId!)
      }
      state.selected = selectedLabels(state.hierarchy)
      state.searchResults = searchTree(state.hierarchy, state.searchTerm)
    },
    labelRemoved: (state, { payload }: PayloadAction<Label>) => {
      state.hierarchy = toggleDocumentLabelInTree(state.hierarchy!, payload.labelId, payload.path!, payload.documentId!)
      state.selected = selectedLabels(state.hierarchy)
      state.searchResults = searchTree(state.hierarchy, state.searchTerm)
    },
    setHierarchy: (state, { payload }: PayloadAction<{ selected: Label[], label: Label }>) => {
      state.loadingHierarchy = LoadingState.Completed
      state.hierarchy = payload.label
      state.selected = payload.selected
      state.searchResults = searchTree(state.hierarchy, state.searchTerm)
      state.openedLabels[state.hierarchy.labelId] = true
    },
    setScrollListener: (state, { payload }: PayloadAction<boolean | null>) => {
      state.isScrolling = payload
    }
  }
})

export const {
  search,
  toggle,
  labelAdded,
  labelRemoved,
  setHierarchy,
  setScrollListener
} = documentLabelsSlice.actions

export const documentLabelsReducers: DocumentLabelsReducers = {
  search,
  toggle,
  labelAdded,
  labelRemoved
}

export const useDocumentLabels: Selector<RootState, DocumentLabelsState> = (state: RootState) => state.documentLabels

export default documentLabelsSlice.reducer
