import { FC } from 'react'
import { Button, makeStyles, Theme } from '@material-ui/core'
import { BusinessStatus } from 'types'
import { CheckMark, CancelMark } from 'common/Icons/SvgIcons'
import { useHover } from 'utils/useHover'
import clsx from 'clsx'

const useStyles = makeStyles<Theme, { accepted: boolean, hovered: boolean }>((theme) => ({
  acceptButton: {
    background: `${theme.palette.blue.main} !important`,
    color: `${theme.palette.common.white} !important`,
    height: '24px !important',
    borderRadius: '0px !important',
    minWidth: '92px !important',
    maxWidth: '92px !important',
    '& .MuiButton-startIcon': {
      margin: '0px !important'
    },
    '&.accepted': {
      background: `${theme.palette.statusColors.green} !important`,
      '&:hover': {
        background: `${theme.palette.statusColors.red} !important`
      }
    },
    '&:disabled': {
      background: `${theme.palette.grey[500]} !important`
    }
  },
  acceptButtonCompact: {
    background: `${theme.palette.blue.main} !important`,
    color: `${theme.palette.common.white} !important`,
    height: '24px !important',
    borderRadius: '0px !important',
    minWidth: '92px !important',
    maxWidth: '92px !important',
    '& .MuiButton-startIcon': {
      margin: '0px !important'
    },
    '&.accepted': {
      background: 'transparent !important',
      '& span': {
        color: 'green'
      },
      '&:hover': {
        background: `${theme.palette.statusColors.red} !important`
      }
    },
    '&:disabled': {
      background: `${theme.palette.grey[600]} !important`
    }
  }
}))

type DocumentAcceptanceProps = {
  accepted: number,
  compact?: boolean,
  disabled?: boolean,
  onClick: (ac: boolean) => void
}

export const DocumentAcceptanceButton: FC<DocumentAcceptanceProps> = ({ onClick, accepted, compact = false, disabled }) => {
  const isAccepted = accepted === BusinessStatus.Accepted
  const [hoverRef, isHovered] = useHover<HTMLButtonElement>()
  const classes = useStyles({ accepted: isAccepted, hovered: isHovered })

  return (
    <Button
      disabled={disabled}
      ref={hoverRef}
      fullWidth
      className={clsx({
        [classes.acceptButton]: compact,
        [classes.acceptButtonCompact]: !compact
      },
      `${isAccepted ? 'accepted' : 'notAccepted'}`,
      `${isHovered && 'hovered'}`)}
      variant="contained"
      color="primary"
      size="small"
      style={{ fontWeight: 600 }}
      startIcon={isAccepted ? !isHovered ? <CheckMark /> : <CancelMark onClick={() => onClick(false)}/> : null}
      onClick={() => onClick(!isAccepted)}>
      {!isAccepted && 'Accept'}
    </Button>
  )
}
