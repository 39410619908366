import { MouseEvent, useMemo, useState } from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import MenuIcon from '@material-ui/icons/Menu'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import LisaStructureTabs from './LisaStructureTabs'
import LisaDashboardTabs from './LisaDashboardTabs'
import Tooltip from '@material-ui/core/Tooltip'
import LisaAdministrationTabs from './LisaAdministrationTabs'
import * as Icons from 'common/Icons/SvgIcons'
import { Avatar, Badge, Popover, useMediaQuery, useTheme } from '@material-ui/core'
import { EditProfileModal } from './EditProfileModal'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { UserAvatar } from 'common/UserAvatar/UserAvatar'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { openModal } from 'redux/slices/appSlice'
import { useGetTransitionQuery } from 'services/api/transitionApi'
import { useTransition } from 'redux/slices/transitionSlice'
import { useAuth } from 'react-oidc-context'
import { useUpdateUserSettingsMutation } from 'services/api/usersApi'
import { TenantSwitch } from 'common/TenantSwitch/TenantSwitch'
import { TransparentLogo } from 'common/Logo/TransparentLogo'
import {
  useGetActiveNotificationsQuery,
  useGetUnseenCountNotificationsQuery,
  useUpdateSeenNotificationsMutation
} from 'services/api/notificationsApi'
import { SimpleNotificationCard } from 'pages/Administration/components/notifications/NotificationCard'
import { hasCompleteProfile } from 'utils/user'
import { RoleName } from 'utils/userRoleSecurity'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& .MuiToolbar-root': {
      minHeight: '56px'
    },
    '& .MuiToolbar-gutters': {
      padding: '0px'
    },
    '& .MuiTabs-indicator': {
    },
    '& .MuiList-padding': {
      paddingTop: '56px',
      paddingBottom: '0px'
    }
  },
  appBar: {
    width: `calc(100% - ${theme.spacing(7) + 1}px)`,
    background: theme.palette.blue.main,
    color: theme.palette.primary.dark,
    boxShadow: 'none',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 57px)'
    },
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    alignItems: 'center'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    height: '56px',
    justifyContent: 'flex-start',
    background: theme.palette.blue.main,
    color: 'white',
    borderRight: '1px solid rgba(255,255,255,.2)'
  },
  drawerOpen: {
    [theme.breakpoints.down('lg')]: {
      width: '57px'
    },
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth
    },
    boxShadow: '0 0 4px 0 rgba(0,0,0,.15)',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    [theme.breakpoints.down('lg')]: {
      display: 'flex'
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1
    }
  },
  content: {
    marginLeft: -drawerWidth // @todo better solution is to for main content to take remaining width...
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  primaryAvatar: {
    backgroundColor: theme.palette.lime.dark,
    color: theme.palette.blue.main,
    marginRight: '12px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.lime.main
    }
  },
  notificationIcon: {
    marginRight: 10,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.lime.main
    }
  },
  toolbarLogo: {
    display: 'flex',
    width: '56px !important',
    height: '56px',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    '& .MuiSvgIcon-root': {
      width: '44px',
      height: '44px',
      transition: 'all .1s linear',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.lime.dark
      }
    }
  },
  toolbarWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '56px'
  },
  navIcons: {
    fill: theme.palette.black.main
  },
  navIconPlane: {
    fill: theme.palette.black.main,
    padding: '5px'
  },
  poopoverWrapper: {
    height: 'auto',
    width: '240px',
    display: 'flex',
    flexDirection: 'column'
  },
  userData: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px 16px',
    '& .userNameLastName': {
      paddingTop: '12px',
      fontSize: '16px',
      fontWeight: 600
    },
    '& .userRole': {
      fontSize: '14px',
      fontWeight: 500
    },
    '& .image': {
      '& .large': {
        width: '64px',
        height: '64px'
      }
    }
  },
  buttonContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between'
  },
  actionButtons: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      fill: '#b7c4c4'
    },
    background: theme.palette.grey2.light,
    '&:hover': {
      background: theme.palette.blue.main,
      transition: 'background 1s',
      '& .MuiSvgIcon-root': {
        fill: '#DDFF66',
        transition: 'fill 1s'
      }
    }
  },
  logoContainer: {
    width: 170,
    marginTop: 'auto',
    marginBottom: 50,
    marginInline: 'auto'
  },
  logoContainerSM: {
    marginTop: 'auto',
    height: 170,
    '& svg': {
      transform: 'rotate(-90deg) translate(0, -57px)',
      width: 170,
      marginBottom: -57
    }
  },
  notificationBadge: {
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      backgroundColor: theme.palette.statusColors.red,
      zIndex: 14
    },
    '& .MuiBadge-badge': {
      display: 'flex',
      height: '14px',
      minWidth: '14px',
      marginTop: '4px',
      marginRight: '14px'
    }
  }
}))

const NavigationBar = () => {
  const classes = useStyles()
  const location = useLocation()
  const dispatch = useDispatch()
  const { signoutRedirect, user: userAuth } = useAuth()
  const { hasAccess, hasRole, userId, user: lisaUser } = useLisaAuth()
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  let open = lisaUser.settings.sidebarExpanded ?? true
  if (isMedium) {
    open = false // always closed if screen width is medium or lower
  }
  const [defaultTab, setDefaultTab] = useState(false)
  const history = useHistory()
  const { data: notifications } = useGetActiveNotificationsQuery()
  const { data: unseenCount } = useGetUnseenCountNotificationsQuery()
  const { currentTransitionId } = useSelector(useTransition)
  const [updateSeenNotifications] = useUpdateSeenNotificationsMutation()
  const { data: transition } = useGetTransitionQuery(currentTransitionId ?? '', {
    skip: currentTransitionId === null
  })
  const name = currentTransitionId ? transition?.name : ''
  const transitionId = transition?.transitionId
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [anchorNotification, setAnchorNotification] = useState<HTMLDivElement | null>(null)
  const [updateUserSettings] = useUpdateUserSettingsMutation()

  const user = {
    url: lisaUser?.profilePictureURL ?? '',
    firstName: lisaUser?.firstName ?? 'N',
    lastName: lisaUser?.lastName ?? 'N',
    userId
  }

  const openPopover = Boolean(anchorEl)
  const id = openPopover ? 'simple-popover' : undefined

  const openPopoverNavigation = Boolean(anchorNotification)
  const poopoverNotificationId = openPopoverNavigation ? 'simple-popover' : undefined

  const matchStructure = useRouteMatch('/structures/:param')

  const handleDrawerOpen = async () => {
    await updateUserSettings({ userId, settings: { sidebarExpanded: !open } })
  }

  // @todo Looks like this has very little functionality (or none?)
  const handleDrawerClose = (e: MouseEvent, component: string | null = null) => {
    if (component !== null) {
      if (component === 'document' || component === 'compare') {
        const win = window.open(`/${component}`, '_blank')
        win?.focus()
      } else {
        if (component === 'structures/import') { // @todo Not sure this is needed anymore
          hasAccess('perm_view_a2kstructure')
            ? history.push(`/${component}/${transitionId}`)
            : history.push(`/structures/client/${transitionId}`)
        } else {
          history.push(`/${component}`)
          setDefaultTab(!defaultTab)
        }
      }
    }
  }

  const handleOpenPopover = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenNotificationPopover = async (event: MouseEvent<HTMLDivElement>) => {
    setAnchorNotification(event.currentTarget)
    await updateSeenNotifications().unwrap()
  }

  const handleCloseNotification = () => {
    setAnchorNotification(null)
  }
  const handleOpenEditProfile = () => {
    dispatch(openModal('EDIT_PROFILE'))
    handleClose()
  }
  const handleOpenEditCompanyProfile = () => {
    dispatch(openModal('CLIENT_SETUP_FORM'))
    handleClose()
  }

  async function signOut () {
    await signoutRedirect()
  }

  const menu = useMemo(() => {
    if (location.pathname.includes('structure')) {
      return <LisaStructureTabs />
    }
    if (location.pathname.includes('dashboard')) {
      return <LisaDashboardTabs />
    }
    if (location.pathname.includes('administration')) {
      return <LisaAdministrationTabs />
    }
    return null
  }, [location.pathname])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <div className={classes.toolbarLogo}>
            <Icons.MenuIcon
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton)}
            >
              {open ? <MenuOpenIcon /> : <MenuIcon />}
            </Icons.MenuIcon>
          </div>
          <div className={classes.toolbarWrapper}>
            {menu}
          </div>
          <Badge
            className={classes.notificationBadge}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={unseenCount?.count! > 0 ? ' ' : null}
          >
            <Avatar className={classes.notificationIcon} onClick={(e) => notifications?.length! > 0 && handleOpenNotificationPopover(e)}>
              <Icons.BellIcon />
            </Avatar>
          </Badge>
          <UserAvatar className={classes.primaryAvatar} user={user} onClick={handleOpenPopover} />
          <Popover
            id={id}
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            style={{ marginTop: '4px' }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <div className={classes.poopoverWrapper}>
              <div className={classes.userData}>
                <div className="image">
                  <UserAvatar user={user} />
                </div>
                <div className={'userNameLastName'}>
                  {user.firstName} {user.lastName}
                </div>
                <div className={'userRole'}>{userAuth && userAuth.profile.email}</div>
              </div>
              <div className={classes.buttonContainer}>
                {
                  (hasRole(RoleName.TechAdmin) || hasRole(RoleName.Administrator)) &&
                  <Tooltip title="Company Profile">
                    <div
                      className={classes.actionButtons}
                      onClick={handleOpenEditCompanyProfile}>
                      <Icons.Briefcase fontSize="large" />
                    </div>
                  </Tooltip>
                }
                <Tooltip title="Edit Profile">
                  <div
                    className={classes.actionButtons}
                    onClick={handleOpenEditProfile}>
                    <Icons.User fontSize="large" />
                  </div>
                </Tooltip>
                <Tooltip title="Sign out">
                  <div
                    className={classes.actionButtons}
                    onClick={() => signOut()}
                  >
                    <Icons.SignOut fontSize="large" />
                  </div>
                </Tooltip>
              </div>
            </div>
          </Popover>
          <Popover
            id={poopoverNotificationId}
            open={openPopoverNavigation}
            anchorEl={anchorNotification}
            onClose={handleCloseNotification}
            style={{ marginTop: '4px', maxWidth: 500 }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <div className={classes.poopoverWrapper} style={{ width: 450, padding: 12, rowGap: '10px' }}>
              {notifications?.map(_ => <SimpleNotificationCard key={_.notificationId} notification={_} />)}
            </div>
          </Popover>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        anchor="left"
        open={open}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}>
        <TenantSwitch wide={open}/>
        <List>
          {name && (
            <Tooltip title={open ? '' : `Asset: ${name}`} placement="right">
              <ListItem
                button
                key={name}
                selected={Boolean(matchStructure)}
                onClick={(e) => handleDrawerClose(e, 'structures/import')}
              >
                <ListItemIcon>
                  <Icons.PlaneIco
                    className={classes.navIconPlane}
                    fontSize="large"
                  />
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItem>
            </Tooltip>
          )}
          <Tooltip title={open ? '' : 'Dashboard'} placement="right">
            <ListItem
              button
              key={'Dashboard'}
              selected={Boolean(useRouteMatch('/dashboard'))}
              onClick={(e) => handleDrawerClose(e, 'dashboard')}
            >
              <ListItemIcon>
                <Icons.Dashboard
                  className={classes.navIcons}
                  fontSize="large"
                />
              </ListItemIcon>
              <ListItemText primary={'Dashboard'} />
            </ListItem>
          </Tooltip>
          <Tooltip title={open ? '' : 'Reports'} placement="right">
            <ListItem
              button
              key={'Reports'}
              selected={Boolean(useRouteMatch('/reports'))}
              onClick={(e) => handleDrawerClose(e, 'reports')}
            >
              <ListItemIcon>
                <Icons.Reports className={classes.navIcons} fontSize="large" />
              </ListItemIcon>
              <ListItemText primary={'Reports'} />
            </ListItem>
          </Tooltip>
          <Tooltip title={open ? '' : 'Administration'} placement="right">
            <ListItem
              button
              key={'Administration'}
              style={{ display: hasAccess('perm_view_administration') ? '' : 'none' }}
              disabled={!hasAccess('perm_view_administration')}
              selected={Boolean(useRouteMatch('/administration/'))}
              onClick={hasAccess('perm_view_administration') ? (e) => handleDrawerClose(e, 'administration') : undefined}
            >
              <ListItemIcon>
                <Icons.Administration
                  className={classes.navIcons}
                  fontSize="large"
                />
              </ListItemIcon>
              <ListItemText primary={'Administration'} />
            </ListItem>
          </Tooltip>
        </List>
        <div className={clsx({
          [classes.logoContainer]: open,
          [classes.logoContainerSM]: !open
        })}>
          <TransparentLogo/>
        </div>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
      <LisaModal
        noCloseButton={!hasCompleteProfile(lisaUser)}
        title={'Edit Profile'}
        modalType={'EDIT_PROFILE'}
        scroll={'body'}>
        <EditProfileModal />
      </LisaModal>
    </div>
  )
}

export default NavigationBar
