import { RefObject, useEffect, useState } from 'react'

const useTooltipEllipsis = (ref: RefObject<HTMLElement>) => {
  const [hoverStatus, setHover] = useState(false)

  const compareSize = () => {
    if (ref.current) {
      const compare = ref.current.scrollWidth > ref.current.clientWidth
      compare ? setHover(true) : setHover(false)
    }
  }

  useEffect(() => {
    compareSize()
    window.addEventListener('resize', compareSize)

    return () => {
      window.removeEventListener('resize', compareSize)
    }
  }, [])

  return hoverStatus
}
export default useTooltipEllipsis
