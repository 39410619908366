import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

export function LogoIcon (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 56 56">
      <path fill="#DDFF66" d="M31.5,25.4h-1.4v1.7h1.4c0.6,0,1.1-0.3,1.1-0.9C32.6,25.7,32.2,25.4,31.5,25.4z"/>
      <path fill="#DDFF66" d="M28,7C16.4,7,7,16.4,7,28c0,11.6,9.4,21,21,21s21-9.4,21-21C49,16.4,39.6,7,28,7z M33.7,32.5   l-3.2-3.7h-0.4v3.7H28v-9h3.8c1.8,0,3,1.2,3,2.7c0,1.2-0.7,1.9-1.9,2.3l3.4,4H33.7z M44.8,32.5v-5.6L42,30.6l-2.8-3.6v5.6H37v-9h2   l3,3.9l3-3.9h2v9H44.8z"/>
    </SvgIcon>
  )
}

export function LogoType (props: SvgIconProps) {
  const LogoIconProps = {
    fill: '#DDFF66',
    stroke: '#DDFF66',
    strokeWidth: 0.5,
    strokeMiterlimit: 10
  }
  return (
    <SvgIcon {...props} viewBox="0 0 220 56">
      <g>
        <path fill="#FFFFFF" d="M12,10.3h5.8V40H12V10.3z"/>
        <path fill="#FFFFFF" d="M24.4,18.8h5.8V40h-5.8V18.8z"/>
        <path fill="#FFFFFF" d="M42.9,40.5c-3.7,0-6.9-1.3-8.7-4.1l4-3.4c0.9,1.6,2.7,2.5,4.7,2.5c1.2,0,2.3-0.5,2.3-1.5   c0-0.9-0.5-1.4-2.8-2l-1.6-0.4c-3.9-1-5.9-3.5-5.8-6.8c0.1-3.9,3.5-6.4,8-6.4c3.2,0,5.7,1.2,7.3,3.6L46.4,25   c-1-1.2-2.2-1.9-3.6-1.9c-1,0-2.1,0.5-2.1,1.4c0,0.6,0.3,1.4,2.1,1.8l2,0.6c3.8,1,6.1,2.9,6.1,6.6C50.9,37.7,47.4,40.5,42.9,40.5z"/>
        <path fill="#FFFFFF" d="M62.8,18.4c2.9,0,5.2,1.5,6.1,2.8v-2.4h5.8V40h-5.8v-2.4c-0.9,1.4-3.2,2.8-6.1,2.8   c-5.6,0-9.9-5-9.9-11C52.8,23.4,57.2,18.4,62.8,18.4z M64,23.7c-3.2,0-5.3,2.5-5.3,5.8c0,3.3,2.2,5.8,5.3,5.8   c3.2,0,5.3-2.5,5.3-5.8C69.3,26.1,67.2,23.7,64,23.7z"/>
        <g>
          <path {...LogoIconProps} d="M85.6,18.8h2.9c1.6,0,2.5,0.9,2.5,2.4    c0,1.2-0.8,2.1-2.1,2.3l3.3,3.9h-1.4l-3.3-3.9h-0.8v3.9h-1.1V18.8z M88.4,22.5c1,0,1.6-0.4,1.6-1.3c0-0.9-0.6-1.3-1.6-1.3h-1.7    v2.6H88.4z"/>
          <path {...LogoIconProps} d="M94.9,18.8h5v1.1H96v2.6h3.1v1.1H96v2.8h3.8    v1.1h-5V18.8z"/>
          <path {...LogoIconProps} d="M106.8,18.7c1.6,0,3,0.8,3.8,2.1l-0.9,0.6    c-0.6-0.9-1.6-1.6-2.8-1.6c-1.9,0-3.3,1.5-3.3,3.3c0,1.9,1.4,3.3,3.3,3.3c1.2,0,2.2-0.6,2.8-1.6l0.9,0.6c-0.8,1.3-2.1,2.1-3.8,2.1    c-2.4,0-4.4-1.9-4.4-4.4S104.4,18.7,106.8,18.7z"/>
          <path {...LogoIconProps} d="M117.6,27.5c-2.4,0-4.4-1.9-4.4-4.4    s2-4.4,4.4-4.4c2.5,0,4.4,1.9,4.4,4.4S120,27.5,117.6,27.5z M117.6,26.4c1.9,0,3.3-1.5,3.3-3.3c0-1.9-1.4-3.3-3.3-3.3    c-1.9,0-3.3,1.5-3.3,3.3C114.3,25,115.7,26.4,117.6,26.4z"/>
          <path {...LogoIconProps} d="M125.4,18.8h2.9c1.6,0,2.5,0.9,2.5,2.4    c0,1.2-0.8,2.1-2.1,2.3l3.3,3.9h-1.4l-3.3-3.9h-0.8v3.9h-1.1V18.8z M128.1,22.5c1,0,1.6-0.4,1.6-1.3c0-0.9-0.6-1.3-1.6-1.3h-1.7    v2.6H128.1z"/>
          <path {...LogoIconProps} d="M134.7,18.8h2.4c2.6,0,4.5,1.8,4.5,4.3    c0,2.4-1.9,4.3-4.5,4.3h-2.4V18.8z M137.1,26.3c2.1,0,3.4-1.4,3.4-3.2c0-1.8-1.4-3.2-3.4-3.2h-1.3v6.4H137.1z"/>
          <path {...LogoIconProps} d="M145.2,25.3c0.5,0.7,1.2,1.2,2.1,1.2    c0.9,0,1.6-0.5,1.6-1.3c0-0.7-0.4-1.1-1.2-1.4l-1.1-0.4c-1.3-0.5-1.9-1.2-1.9-2.4c0-1.4,1.1-2.3,2.7-2.3c1.1,0,2,0.5,2.5,1.2    l-0.8,0.7c-0.4-0.5-1-0.8-1.7-0.8c-0.8,0-1.5,0.5-1.5,1.2c0,0.7,0.4,1,1.3,1.4l1,0.4c1.3,0.5,2,1.2,2,2.5c0,1.5-1.1,2.4-2.7,2.4    c-1.4,0-2.5-0.7-3-1.5L145.2,25.3z"/>
          <path {...LogoIconProps} d="M86.7,33.4V40h-1.1v-8.5h1l3.2,4.2l3.2-4.2    h1V40h-1.1v-6.6l-3.1,4.1L86.7,33.4z"/>
          <path {...LogoIconProps} d="M100.6,31.5h0.9l3.8,8.5h-1.2l-1.1-2.5h-4    L98,40h-1.2L100.6,31.5z M102.6,36.5l-1.5-3.5l-1.5,3.5H102.6z"/>
          <path {...LogoIconProps} d="M114.5,40l-5.2-6.6V40h-1.1v-8.5h1l5.2,6.6    v-6.6h1.1V40H114.5z"/>
          <path {...LogoIconProps} d="M122.1,31.5h0.9l3.8,8.5h-1.2l-1.1-2.5h-4    l-1.1,2.5h-1.2L122.1,31.5z M124.1,36.5l-1.5-3.5l-1.5,3.5H124.1z"/>
          <path {...LogoIconProps} d="M133.5,40.1c-2.7,0-4.6-1.9-4.6-4.4    c0-2.5,1.9-4.4,4.4-4.4c1.3,0,2.4,0.4,3.2,1.2l-0.7,0.8c-0.6-0.6-1.4-0.9-2.5-0.9c-1.9,0-3.3,1.5-3.3,3.3c0,1.9,1.4,3.3,3.5,3.3    c0.8,0,1.7-0.2,2.3-0.6v-2.3h-2.4v-1h3.5v3.8C136,39.7,134.7,40.1,133.5,40.1z"/>
          <path {...LogoIconProps} d="M140.3,31.5h5v1.1h-3.8v2.6h3.1v1.1h-3.1    v2.8h3.8V40h-5V31.5z"/>
          <path {...LogoIconProps} d="M149.8,33.4V40h-1.1v-8.5h1l3.2,4.2l3.2-4.2    h1V40H156v-6.6l-3.1,4.1L149.8,33.4z"/>
          <path {...LogoIconProps} d="M161,31.5h5v1.1h-3.8v2.6h3.1v1.1h-3.1v2.8    h3.8V40h-5V31.5z"/>
          <path {...LogoIconProps} d="M175.7,40l-5.2-6.6V40h-1.1v-8.5h1l5.2,6.6    v-6.6h1.1V40H175.7z"/>
          <path {...LogoIconProps} d="M182.3,32.5h-2.7v-1.1h6.4v1.1h-2.7V40h-1.1    V32.5z"/>
        </g>
      </g>
    </SvgIcon>
  )
}

export function MenuIcon (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 56 56">
      <g transform="translate(-6.75 -10.195)">
        <path id="Path_439" d="M21.5,37h26v2.2h-26V37z" />
        <path id="Path_440" d="M21.5,29.1h26v2.2h-26V29.1z" />
        <path id="Path_441" d="M21.5,44.9h26v2.2h-26V44.9z" />
      </g>
    </SvgIcon>
  )
}

export function Dashboard (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 56 56">
      <g>
        <path d="M24.7,11.9h-11c-1.1,0-2,0.9-2,2v11c0,1.1,0.9,2,2,2h11c1.1,0,2-0.9,2-2v-11C26.7,12.8,25.8,11.9,24.7,11.9z" />
        <path d="M41.7,11.9h-11c-1.1,0-2,0.9-2,2v11c0,1.1,0.9,2,2,2h11c1.1,0,2-0.9,2-2v-11C43.7,12.8,42.8,11.9,41.7,11.9z" />
        <path d="M24.7,28.9h-11c-1.1,0-2,0.9-2,2v11c0,1.1,0.9,2,2,2h11c1.1,0,2-0.9,2-2v-11C26.7,29.8,25.8,28.9,24.7,28.9z" />
        <path d="M41.7,28.9h-11c-1.1,0-2,0.9-2,2v11c0,1.1,0.9,2,2,2h11c1.1,0,2-0.9,2-2v-11C43.7,29.8,42.8,28.9,41.7,28.9z" />
      </g>
    </SvgIcon>
  )
}

export function Administration (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 56 56">
      <path d="M43.5,31.3h-4.1c-0.3,1.3-0.8,2.5-1.5,3.6l2.9,2.9l-4,4l-2.9-2.9c-1.1,0.7-2.3,1.2-3.6,1.5
  v4.1h-5.6v-4.1c-1.3-0.3-2.5-0.8-3.6-1.5l-2.9,2.9l-4-4l2.9-2.9c-0.7-1.1-1.2-2.3-1.5-3.6h-4.1v-5.6h4.1c0.3-1.3,0.8-2.5,1.5-3.7
  l-2.9-2.9l4-4l2.9,2.9c1.1-0.7,2.3-1.2,3.6-1.5v-4.1h5.6v4.1c1.3,0.3,2.5,0.8,3.6,1.5l2.9-2.9l4,4L37.9,22c0.7,1.1,1.2,2.4,1.5,3.7
  h4.1V31.3z M27.5,34.1c3.1,0,5.6-2.5,5.6-5.6s-2.5-5.6-5.6-5.6c-3.1,0-5.6,2.5-5.6,5.6C21.9,31.5,24.4,34.1,27.5,34.1
  C27.5,34.1,27.5,34.1,27.5,34.1L27.5,34.1z"/>
    </SvgIcon>
  )
}

export function Reports (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 56 56">
      <g>
        <path d="M34.9,38.5c0,0.2-0.1,0.3-0.2,0.5c-2.5,2.3-5.7,3.5-9.1,3.5c-2.4,0-4.7-0.6-6.8-1.8
      c-2-1.2-3.8-2.9-5-5c-1.2-2-1.9-4.4-1.8-6.7c0-2.3,0.6-4.6,1.7-6.7c2.3-4.1,6.5-6.7,11.1-7c0.2,0,0.4,0.1,0.5,0.2
      c0.1,0.1,0.2,0.3,0.2,0.5v12.9l9.1,9.1C34.9,38.2,34.9,38.3,34.9,38.5z M28.3,27.1h12.9c0.2,0,0.4-0.1,0.5-0.2
      c0.1-0.1,0.2-0.3,0.2-0.5c-0.1-2.2-0.8-4.4-1.9-6.3c-1.1-1.9-2.7-3.5-4.6-4.6c-1.9-1.2-4.1-1.8-6.3-1.9c-0.2,0-0.4,0.1-0.5,0.2
      c-0.1,0.1-0.2,0.3-0.2,0.5L28.3,27.1z M43.8,30c0.1,0.1,0.2,0.3,0.2,0.5c-0.1,1.5-0.4,3-1,4.4c-0.6,1.4-1.4,2.8-2.5,3.9
      c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0-0.4-0.1-0.5-0.2l-9.1-9.1h12.9C43.5,29.8,43.7,29.9,43.8,30L43.8,30z"/>
      </g>
    </SvgIcon>
  )
}

export function CompareIco (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M14,3h-3V2c0-1.1-0.9-2-2-2H2C0.9,0,0,0.9,0,2v9c0,1.1,0.9,2,2,2h3v1c0,1.1,0.9,2,2,2h7c1.1,0,2-0.9,2-2V5
  C16,3.9,15.1,3,14,3z M5,11H2V2h7v9H5z M14,14H7v-1h2c1.1,0,2-0.9,2-2V5h3V14z"/>
    </SvgIcon>
  )
}

export function LinkIco (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M10.1,5.9C9.9,5.7,9.7,5.5,9.5,5.4c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.1,0.1L8.4,6.1C8.2,6.3,8.1,6.6,8.2,6.9
  C8.2,7,8.2,7,8.2,7c0.1,0.1,0.3,0.2,0.4,0.3c1,1,1,2.6,0,3.7l-2.2,2.2c-0.5,0.5-1.1,0.8-1.8,0.8c-1.4,0-2.6-1.2-2.6-2.6c0,0,0,0,0,0
  c0-0.7,0.3-1.3,0.8-1.8l0.4-0.4C3.3,9,3.4,8.9,3.3,8.8C3.1,8.2,3,7.6,3,7c0-0.1-0.1-0.2-0.2-0.2c0,0-0.1,0-0.1,0
  c-0.1,0-0.1,0-0.1,0.1L1.3,8.1c-0.6,0.6-1,1.3-1.2,2.1c-0.2,0.8-0.2,1.6,0,2.4c0.4,1.6,1.7,2.9,3.3,3.3c0.8,0.2,1.6,0.2,2.4,0
  c0.8-0.2,1.5-0.6,2.1-1.2l2.2-2.2c0.6-0.6,1-1.3,1.2-2.1c0.2-0.8,0.2-1.6,0-2.4C11.1,7.2,10.7,6.5,10.1,5.9z"/>
      <path d="M14.7,1.3c-0.6-0.6-1.3-1-2.1-1.2c-0.8-0.2-1.6-0.2-2.4,0C9.4,0.4,8.7,0.8,8.1,1.3L5.9,3.5
  c-0.6,0.6-1,1.3-1.2,2.1C4.5,6.4,4.5,7.2,4.7,8c0.2,0.8,0.6,1.5,1.2,2.1c0.2,0.2,0.4,0.4,0.6,0.5c0,0,0.1,0,0.2,0
  c0.1,0,0.1,0,0.2-0.1l0.7-0.7c0.2-0.2,0.3-0.5,0.3-0.8C7.8,9,7.8,9,7.8,9C7.6,8.9,7.5,8.8,7.4,8.6c-1-1-1-2.6,0-3.7l2.2-2.2
  c0.5-0.5,1.1-0.8,1.8-0.8c1.4,0,2.6,1.2,2.6,2.6c0,0,0,0,0,0c0,0.7-0.3,1.3-0.8,1.8l-0.4,0.4c-0.1,0.1-0.1,0.2-0.1,0.3
  C12.9,7.7,13,8.3,13,9c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.3,0l1.2-1.2c0.6-0.6,1-1.3,1.2-2.1c0.2-0.8,0.2-1.6,0-2.4
  C15.6,2.7,15.2,1.9,14.7,1.3z"/>
    </SvgIcon>
  )
}

export function PlaneIco (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M13.4,16.5c0,0.4,0,0.9,0,1.3c0,0.1,0.1,0.3,0.1,0.4c0.5,0.7,1.1,1.4,1.6,2c0.3,0.4,0.2,0.9,0.2,1.3
  c0,0.2-0.2,0.2-0.3,0.1c-0.4-0.2-0.8-0.4-1.2-0.6c-0.2-0.1-0.4-0.3-0.7-0.4c-0.4-0.2-0.7-0.1-0.7,0.4c0,0.2-0.1,0.4-0.2,0.6
  c0,0.1-0.2,0.2-0.2,0.3c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.3-0.2-0.5-0.5-0.4c-0.1,0-0.3,0.1-0.4,0.2
  c-0.5,0.3-1,0.5-1.5,0.8c-0.4,0.2-0.4,0.1-0.5-0.3c0-0.5,0.1-1,0.5-1.4c0.5-0.6,0.9-1.2,1.4-1.8c0.1-0.1,0.1-0.2,0.1-0.3
  c0-0.9,0-1.8,0-2.6c0-0.5-0.1-0.6-0.6-0.4c-2.4,0.7-4.8,1.4-7.3,2C2.1,17,2,16.9,2,16.3c0-0.4,0.2-0.8,0.6-1
  c1.3-0.9,2.5-1.9,3.8-2.8c1.2-0.9,2.4-1.8,3.6-2.6c0.3-0.2,0.5-0.5,0.5-0.9c0-1.4,0-2.7,0.1-4.1c0-0.6,0-1.1,0.2-1.7
  C10.8,3,11,2.6,11.2,2.4c0.4-0.5,1.1-0.5,1.5,0c0.4,0.4,0.6,1,0.6,1.5c0.1,1.4,0.1,2.9,0.1,4.3c0,0.3,0,0.6,0,0.8
  c0,0.4,0.2,0.6,0.5,0.9c2.5,1.8,4.9,3.6,7.4,5.4c0.4,0.3,0.7,0.7,0.6,1.3c0,0.4-0.2,0.5-0.5,0.4c-2.5-0.7-4.9-1.4-7.4-2.1
  c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.5,0-0.5,0.3C13.4,15.6,13.5,16.1,13.4,16.5C13.4,16.5,13.4,16.5,13.4,16.5z"/>
    </SvgIcon>
  )
}

export function SearchIco (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M22,20.2l-4.2-4.2c1.2-1.5,1.8-3.4,1.8-5.3C19.6,6,15.7,2,10.9,2c0,0-0.1,0-0.1,0
  C6,2,2,5.9,2,10.7c0,0,0,0.1,0,0.1c0,4.8,3.9,8.8,8.7,8.8c0,0,0.1,0,0.1,0c1.9,0,3.8-0.6,5.3-1.8l4.2,4.2L22,20.2z M4.5,10.8
  c0-3.4,2.7-6.3,6.2-6.3c0,0,0.1,0,0.1,0c3.4,0,6.3,2.7,6.3,6.2c0,0,0,0.1,0,0.1c0,3.4-2.7,6.3-6.2,6.3c0,0-0.1,0-0.1,0
  c-3.4,0-6.3-2.7-6.3-6.2C4.5,10.9,4.5,10.8,4.5,10.8z"/>
    </SvgIcon>
  )
}

export function ExternalQuery (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <polygon points="11.1,9 18,9 18,6 9,6 6,6 6,9 6,18 9,18 9,11.1 16.9,19 19,16.9 " />
    </SvgIcon>
  )
}

export function CriticalQuery (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path d="M13.1,16.6V15c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.1h-1.7c-0.1,0-0.1,0-0.2,0.1
    c-0.1,0.1-0.1,0.1-0.1,0.2v1.6c0,0.1,0,0.1,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1h1.7c0.1,0,0.1,0,0.2-0.1
    C13.1,16.7,13.1,16.6,13.1,16.6L13.1,16.6z M13.1,13.4l0.2-3.9c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.1h-2
    c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.2l0.2,3.9c0,0.1,0,0.1,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1h1.7c0.1,0,0.1,0,0.2-0.1
    C13.1,13.5,13.1,13.5,13.1,13.4L13.1,13.4z M13,5.6l6.9,11.8c0.2,0.3,0.2,0.7,0,1.1c-0.2,0.3-0.6,0.5-1,0.5H5.2
    c-0.4,0-0.8-0.2-1-0.5c-0.2-0.3-0.2-0.7,0-1.1L11,5.6c0.1-0.2,0.2-0.3,0.4-0.4c0.4-0.2,0.8-0.2,1.2,0C12.8,5.3,12.9,5.4,13,5.6z"/>
      </g>
    </SvgIcon>
  )
}

export function DocMetaData (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path d="M19.6,14.7c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0,0.4-0.1,0.7l-0.6,1.2c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0-0.6-0.1
    l-4.7-3l0.1,5.7c0,0.3,0,0.5-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-1.3c-0.3,0-0.5-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6l0.1-5.7
    l-4.7,3c-0.2,0.1-0.4,0.2-0.6,0.1c-0.2-0.1-0.4-0.2-0.5-0.4l-0.6-1.2C4,15.7,4,15.5,4,15.3c0.1-0.2,0.2-0.4,0.4-0.5L9.3,12L4.4,9.3
    C4.2,9.1,4.1,9,4,8.7C4,8.5,4,8.3,4.1,8.1l0.6-1.2C4.9,6.7,5,6.6,5.3,6.5c0.2-0.1,0.4,0,0.6,0.1l4.7,3l-0.1-5.7
    c0-0.3,0-0.5,0.2-0.6C10.9,3.1,11.1,3,11.4,3h1.3c0.3,0,0.5,0.1,0.6,0.2c0.2,0.2,0.2,0.4,0.2,0.6l-0.1,5.7l4.7-3
    c0.2-0.1,0.4-0.2,0.6-0.1c0.2,0.1,0.4,0.2,0.5,0.4l0.6,1.2C20,8.3,20,8.5,20,8.7c-0.1,0.2-0.2,0.4-0.4,0.5L14.7,12L19.6,14.7z"/>
      </g>
    </SvgIcon>
  )
}

export function DocSearch (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M21,19.4l-3.7-3.7c1-1.4,1.6-3,1.6-4.8C18.9,6.6,15.4,3,11,3c0,0-0.1,0-0.1,0C6.6,3,3,6.5,3,10.8
  c0,0,0,0.1,0,0.1c0,4.3,3.5,7.9,7.8,7.9c0,0,0.1,0,0.1,0c1.7,0,3.4-0.5,4.8-1.6l3.7,3.7L21,19.4z M5.3,10.9c0-3.1,2.5-5.6,5.6-5.7
  c0,0,0.1,0,0.1,0c3.1,0,5.6,2.5,5.7,5.6c0,0,0,0.1,0,0.1c0,3.1-2.5,5.6-5.6,5.7c0,0-0.1,0-0.1,0c-3.1,0-5.6-2.5-5.7-5.6
  C5.3,11,5.3,11,5.3,10.9z"/>
    </SvgIcon>
  )
}

export function DocLabel (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path d="M19,4H5C3.4,4,2.2,5.1,2,6.6c0,0.1,0,0.2,0,0.3v10.2c0,0.1,0,0.3,0,0.4c0.2,1.3,1.2,2.3,2.5,2.4c0.1,0,0.3,0,0.4,0H19
    c0.1,0,0.2,0,0.4,0c1.5-0.2,2.6-1.4,2.6-2.9V6.9C22,5.3,20.7,4,19,4z M19,17.8H5c-0.4,0-0.7-0.3-0.7-0.7V6.9c0-0.4,0.3-0.7,0.7-0.7
    H19c0.4,0,0.7,0.3,0.7,0.7v10.2C19.8,17.5,19.4,17.8,19,17.8z"/>
        <path d="M15,11.3H9c-0.6,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1H15c0.6,0,1.1-0.5,1.1-1.1S15.6,11.3,15,11.3z" />
      </g>
    </SvgIcon>
  )
}

export function DocQuery (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M2,17.8V20h2.2C3,20,2,19,2,17.8z" />
      <path d="M9.4,4C5.3,4,2,7.3,2,11.3C2,7.3,5.3,4,9.4,4z" />
      <path d="M14.6,20c4.1,0,7.4-3.3,7.4-7.3C22,16.7,18.7,20,14.6,20z" />
      <path d="M22,11.3c0-4-3.3-7.3-7.4-7.3C18.7,4,22,7.3,22,11.3z" />
      <path d="M14.6,4H9.4C5.3,4,2,7.3,2,11.3v6.5C2,19,3,20,4.2,20h10.4c4.1,0,7.4-3.3,7.4-7.3v-1.5C22,7.3,18.7,4,14.6,4z M19.8,12.7
  c0,2.8-2.3,5.1-5.2,5.1H4.2v-6.5c0-2.8,2.3-5.1,5.2-5.1h5.2c2.9,0,5.2,2.3,5.2,5.1V12.7z"/>
    </SvgIcon>
  )
}

export function DocHistory (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <path d="M18.9,16c-0.7,1.2-1.7,2.2-2.9,2.9S13.4,20,12,20c-0.9,0-1.8-0.2-2.7-0.5c-0.9-0.3-1.6-0.7-2.4-1.3
    c-0.2-0.2-0.3-0.3-0.3-0.6c0-0.2,0.1-0.4,0.2-0.6l0.4-0.4c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0,0.5,0.1c1.1,0.9,2.3,1.3,3.7,1.3
    c1.1,0,2.1-0.3,3-0.8c0.9-0.5,1.6-1.2,2.2-2.2c0.5-0.9,0.8-1.9,0.8-3s-0.3-2.1-0.8-3c-0.5-0.9-1.2-1.6-2.2-2.2
    c-0.9-0.5-1.9-0.8-3-0.8c-0.8,0-1.5,0.1-2.2,0.4C9.1,6.8,8.5,7.2,7.9,7.7l1.6,1.6c0.2,0.2,0.2,0.3,0.1,0.5
    c-0.1,0.2-0.2,0.3-0.5,0.3H4.5c-0.2,0-0.3,0-0.4-0.1C4,10,4,9.8,4,9.7V5c0-0.2,0.1-0.4,0.3-0.5s0.4-0.1,0.5,0.1l1.6,1.6
    C7.2,5.5,8.1,5,9,4.6S10.9,4,12,4c1.4,0,2.8,0.4,4,1.1s2.2,1.7,2.9,2.9s1.1,2.6,1.1,4C20,13.4,19.6,14.8,18.9,16z M14.5,14.1
    c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.3-0.5L13,12V8.6c0-0.2-0.1-0.4-0.2-0.5s-0.3-0.2-0.5-0.2h-0.5c-0.2,0-0.4,0.1-0.5,0.2
    S11,8.4,11,8.6V13l2.1,1.6c0.2,0.1,0.4,0.2,0.6,0.1c0.2,0,0.4-0.1,0.5-0.3L14.5,14.1z"/>
      </g>
    </SvgIcon>
  )
}

export function SideletterQuery (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <polygon points="4,10.7 4,18 20,18 20,10.7 12,15.1 " />
      <polygon points="20,8.4 20,6 4,6 4,8.4 12,12.9 " />
    </SvgIcon>
  )
}

export function AddIco (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 42 42">
      <path d="M37,18.9H23v-14c0-1.1-0.9-2-2-2s-2,0.9-2,2v14H5c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h14v14c0,1.1,0.9,2,2,2s2-0.9,2-2v-14h14
  c1.1,0,2-0.9,2-2C39,19.8,38.1,18.9,37,18.9z"/>
    </SvgIcon>
  )
}

export function ChevronIco (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M11.6,8.8l-5.4,5C6,13.9,5.8,14,5.5,14c-0.2,0-0.5-0.1-0.6-0.2l-0.6-0.6c-0.3-0.3-0.3-0.9,0-1.2l4.2-4
  L4.3,4C4.1,3.9,4,3.7,4,3.4C4,3.2,4.1,3,4.3,2.8l0.6-0.6C5.1,2.1,5.3,2,5.5,2C5.8,2,6,2.1,6.2,2.2l5.4,5C11.8,7.4,12,7.7,12,8
  C12,8.3,11.8,8.6,11.6,8.8z"/>
    </SvgIcon>
  )
}

export function ReplaceIco (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 42 42">
      <path d="M29.7,5H12.3c-1.6,0-2.9,1.3-2.9,2.9v26.2c0,1.6,1.3,2.9,2.9,2.9h17.5c1.6,0,2.9-1.3,2.9-2.9
    V7.9C32.6,6.3,31.3,5,29.7,5z M29.7,34.1H12.3V22.5h7.5l-3.5,3.5h4.2l4.9-4.9l-4.9-4.9h-4.2l3.5,3.5h-7.5V7.9h17.5V34.1z"/>
    </SvgIcon>
  )
}

export function DeleteIco (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 42 42">
      <path d="M32.5,8.3h-23c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4v23c0,1.6,1.3,2.9,2.9,2.9h17.2
  c1.6,0,2.9-1.3,2.9-2.9v-23c0.8,0,1.4-0.6,1.4-1.4C33.9,8.9,33.3,8.3,32.5,8.3 M29.6,34.1H12.4v-23h17.2V34.1z"/>
      <path d="M18.1,6.9h5.7c0.8,0,1.4-0.6,1.4-1.4S24.7,4,23.9,4h-5.7c-0.8,0-1.4,0.6-1.4,1.4
  S17.3,6.9,18.1,6.9"/>
      <rect x="16.1" y="14" className="st0" width="2.9" height="17.2" />
      <rect x="23" y="14" className="st0" width="2.9" height="17.2" />
    </SvgIcon>
  )
}

export function NoResults (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96">
      <rect x="-8" y="36.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -17.4583 42.2675)" width="100.5" height="12" />
      <path d="M76.1,67.6c5.5-7.3,8.5-16.2,8.5-25.4c0.1-8.1-2.2-15.6-6.1-22.1l-8.9,8.9c1.8,3.8,2.9,8.1,2.9,12.6
  c0,0.2,0,0.4,0,0.6c0.2,16.5-13.1,30-29.6,30.2c-0.2,0-0.4,0-0.6,0c-4.7,0-9.2-1-13.2-2.9l-8.9,8.9c6.3,3.8,13.6,6,21.5,6.1
  c0.2,0,0.3,0,0.5,0c9.2,0.1,18.1-2.9,25.4-8.5L87.5,96l8.5-8.5L76.1,67.6z"/>
      <path d="M12.1,42.8c0-0.2,0-0.4,0-0.6c-0.2-16.5,13.1-30,29.6-30.2c0.2,0,0.4,0,0.6,0c4.7,0,9.1,1,13.1,2.9
  l8.9-8.9C58,2.3,50.7,0,42.8,0c-0.2,0-0.3,0-0.5,0C19.1-0.1,0.1,18.5,0,41.7c0,0.2,0,0.3,0,0.5c-0.1,8.1,2.2,15.6,6.1,22l8.9-8.9
  C13.2,51.6,12.1,47.3,12.1,42.8z"/>
    </SvgIcon>
  )
}

export function NoDocuments (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96">
      <g>
        <path d="M89.9,6.9l-4.2-4.2C85.2,2.2,84.7,2,84.2,2c-0.5,0-1,0.2-1.4,0.6L3.1,82.3c-0.8,0.8-0.8,2.1,0,2.8
    l4.2,4.2c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6L89.9,9.7C90.6,8.9,90.6,7.6,89.9,6.9z"/>
        <path d="M76,30.6V87H22v-2.4l-8.3,8.3c1,1.9,3,3.1,5.3,3.1h60c3.3,0,6-2.7,6-6V30.1c0-2-0.7-4.5-1.8-6.7
    L76,30.6z"/>
        <path d="M22,9h32.9c1,0,3.2,0.9,3.9,1.6l4.5,4.5l6.4-6.4l-4.5-4.5C62.8,1.9,58.2,0,54.9,0H19
    c-3.3,0-6,2.7-6,6v59.3l9-9V9z"/>
      </g>
    </SvgIcon>
  )
}

export function NoLabel (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96">
      <polygon points="55.4,34.3 24.2,65.5 0,89.8 6.2,96 40.6,61.7 61.5,40.8 96,6.2 89.8,0 " />
      <path d="M87,31.3v42H37.5l-9,9H87c5,0,9-4,9-9v-42c0-4.5-3.3-8.2-7.6-8.9l-8.9,8.9H87z" />
      <path d="M9,19.3h26.3l10.2,10.2c1.1,1.1,2.7,1.8,4.2,1.8h0.2l1.2-1.2l7.8-7.8h-8L40.7,12.1c-1.1-1.1-2.7-1.8-4.2-1.8H9c-5,0-9,4-9,9
  v54c0,2.2,0.8,4.3,2.2,5.9L9,72.3V19.3z"/>
    </SvgIcon>
  )
}

export function NoTransitions (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96">
      <g>
        <path d="M3.4,71.4c3-0.8,6-1.7,9.1-2.5l42.7-42.7C55,20.4,54.9,14.7,54.7,9c-0.1-2.8-1-5.3-3-7.3c-2.2-2.2-5.4-2.3-7.4,0
    c-1.1,1.3-2.1,2.9-2.5,4.5c-0.6,2.6-0.7,5.4-0.8,8c-0.2,6.5-0.3,13.1-0.5,19.6c0,1.8-0.8,3.1-2.2,4.1c-5.8,4.2-11.6,8.4-17.4,12.6
    C14.8,55,8.8,59.4,2.7,63.9C1,65.1,0,66.7,0,68.8C0,71.6,0.7,72.2,3.4,71.4z"/>
        <polygon points="61.5,40.8 96,6.2 89.8,0 55.4,34.3 24.2,65.5 0,89.8 6.2,96 40.6,61.7 " />
        <path d="M93.1,63.7c-8.9-6.5-17.8-12.9-26.7-19.4L41.2,69.5c0,2.3,0.1,4.5,0.1,6.8c0,0.4-0.2,1-0.5,1.3c-2.2,2.8-4.3,5.7-6.7,8.4
    c-1.7,2-2.4,4-2.2,6.6c0.2,1.9,0.6,2.3,2.3,1.4c2.4-1.2,4.8-2.6,7.3-3.9c0.6-0.3,1.3-0.6,2-0.8c1.7-0.3,2.3,0.2,2.6,1.9
    c0.2,1.2,0.5,2.4,0.9,3.5c0.2,0.5,0.7,0.8,1.1,1.2c0.4-0.4,0.9-0.7,1.1-1.2c0.4-1,0.7-2.1,0.8-3.1c0.2-2.4,1.4-2.6,3.5-1.9
    c1.2,0.4,2.2,1.1,3.3,1.7c1.8,1,3.7,1.9,5.5,2.9c0.6,0.3,1.5,0.5,1.6-0.5c0.2-2.1,0.7-4.3-0.8-6.2c-2.6-3.2-5.2-6.5-7.7-9.8
    c-0.4-0.5-0.7-1.3-0.7-1.9c-0.1-2,0-4.1,0-6.1c0.1,0,0.2,0,0.2,0c0-2.2,0-4.4,0-6.6c0-1.6,0.7-2.1,2.3-1.7c0.2,0.1,0.5,0.1,0.7,0.2
    c11.8,3.3,23.6,6.6,35.4,9.9c1.7,0.5,2.4,0,2.6-1.8C96.2,67.2,95.2,65.3,93.1,63.7z"/>
      </g>
    </SvgIcon>
  )
}

export function NoQueries (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96">
      <g>
        <rect x="-15.5" y="43.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -19.8823 48)" width="127" height="8.8" />
        <path d="M89.1,18.4l-6,6l0.5,0.7c0.9,1.3,1.4,2.9,1.4,4.5v36.6c0,4.5-3.7,8.2-8.2,8.2H33.1L24.5,83h52.3c9.2,0,16.7-7.5,16.7-16.7
    V29.7c0-3.8-1.3-7.4-3.7-10.4L89.1,18.4z"/>
        <path d="M11.1,29.7c0-4.5,3.7-8.2,8.2-8.2h43.7l8.5-8.5H19.2C10,13,2.5,20.5,2.5,29.7v52.3l8.5-8.5V29.7z" />
      </g>
    </SvgIcon>
  )
}

export function User (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96">
      <path d="M48.2,57.7c4.3,0,8.5-0.1,12.8,0c7.3,0.1,13.6,4.5,15.6,11.1c0.3,1,0.1,2.6-0.6,3.4c-13.8,17.1-42,17.2-56,0.1
  c-0.7-0.8-1-2.6-0.6-3.6c2.1-6.6,8.3-10.9,15.7-11C39.5,57.6,43.8,57.7,48.2,57.7z"/>
      <path d="M64.2,37.1c0,9-7.2,16.2-16.3,16.2c-8.9,0-16-7.2-16.1-16.1c-0.1-9.1,7.1-16.3,16.1-16.4C56.9,20.7,64.2,28,64.2,37.1z" />
      <path d="M48,7.5c22.3,0,40.5,18.2,40.5,40.5S70.3,88.5,48,88.5S7.5,70.3,7.5,48S25.7,7.5,48,7.5 M48,0C21.5,0,0,21.5,0,48
  s21.5,48,48,48s48-21.5,48-48S74.5,0,48,0L48,0z"/>
    </SvgIcon>
  )
}

export function Tip (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96">
      <path d="M52.8,90c-2.6,0-5.1,0-7.7,0c-2.2,0-3.4,0.7-3.8,2.3C41,93.8,41.7,95,43.5,96c3.5,0,7,0,10.5,0c0,0,0.1-0.1,0.1-0.1
    c1.6-0.8,2.4-2,2.1-3.5C55.9,90.9,54.6,90,52.8,90z"/>
      <path d="M75.1,35.5c-2.6-14.4-16-23.9-30.5-21.6c-17.7,2.8-27.1,21-20.4,36.3c1.5,3.4,3.4,6.6,6.2,9c1.9,1.7,3.2,3.6,4.1,5.9
    c1.2,3.1,1.5,6.3,1.5,9.6c0,2.1,1.3,3.3,3.4,3.3c3.1,0,6.2,0,9.4,0c3.2,0,6.3,0,9.5,0c2.1,0,3.2-1.2,3.3-3.2
    c0.2-2.4,0.3-4.8,0.8-7.1c0.6-2.9,1.8-5.6,4.1-7.7c0.9-0.8,1.9-1.7,2.6-2.6C74.1,50.9,76.5,43.7,75.1,35.5z M62.5,55.4
    c-3.2,2.7-4.9,6.4-5.8,10.3c-0.5,2-0.8,4.1-1.2,6.3c-4.5,0-8.9,0-13.5,0c-0.2-1.2-0.3-2.5-0.5-3.7c-0.9-4.8-2.5-9.2-6.1-12.7
    c-1.2-1.2-2.5-2.3-3.4-3.7c-9.3-13.4-1.1-29.8,13.6-32.2c10.2-1.6,20.2,4.5,23.2,14.3C71,41.6,68.5,50.3,62.5,55.4z"/>
      <path d="M55.9,81c-4.8,0-9.6,0-14.3,0c-2,0-3.3,1.3-3.3,3c0,1.7,1.4,3,3.3,3c2.4,0,4.7,0,7.1,0c2.4,0,4.8,0,7.2,0
    c2,0,3.4-1.2,3.4-3C59.3,82.3,57.9,81,55.9,81z"/>
      <path d="M45,7.5c0,1.7,1.3,3,2.9,3c1.7,0,3-1.3,3-3c0-1.5,0-3,0-4.5c0-1.7-1.4-3-3-3c-1.6,0-2.9,1.3-3,3c0,0.7,0,1.5,0,2.2
    C45,6,45,6.8,45,7.5z"/>
      <path d="M15.8,36.8c-0.7,0-1.5,0-2.2,0c-0.8,0-1.6,0-2.4,0c-1.6,0.1-2.9,1.4-2.9,3c0,1.6,1.3,2.9,2.9,3c1.6,0,3.1,0,4.7,0
    c1.6,0,2.9-1.4,2.9-3C18.8,38.2,17.5,36.8,15.8,36.8z"/>
      <path d="M26.4,59.8c-1.2-0.5-2.3-0.3-3.2,0.5c-1.1,1-2.2,2.1-3.2,3.2c-1.2,1.3-1.2,3,0,4.2c1.2,1.2,3,1.3,4.3,0
    c1.1-1,2.2-2.1,3.2-3.2c0.5-0.6,0.7-1.3,0.9-1.7C28.1,61.3,27.6,60.3,26.4,59.8z"/>
      <path d="M23,19c0.6,0.5,1.4,0.8,1.7,1c1.7,0,2.7-0.6,3.2-1.8c0.5-1.2,0.3-2.3-0.6-3.3c-1-1.1-2.1-2.1-3.2-3.2
    c-1.3-1.2-3.1-1.2-4.3,0c-1.2,1.2-1.2,3,0.1,4.3C21,17,22,18.1,23,19z"/>
      <path d="M84.9,36.8c-1.1-0.1-2.2-0.1-3.4,0c-1.6,0.1-2.8,1.4-2.8,2.9c0,1.6,1.2,2.9,2.8,3c0.6,0,1.1,0,1.7,0c0.5,0,1.1,0,1.6,0
    c1.6-0.1,2.8-1.4,2.9-2.9C87.8,38.3,86.5,36.9,84.9,36.8z"/>
      <path d="M73.6,61.3c-0.9-0.8-2-0.9-3.1-0.3c-1.1,0.6-1.7,1.5-1.7,2.7c0,1.8,3.3,5.1,5.2,5.1c1.1-0.1,2.1-0.6,2.6-1.7
    c0.5-1.1,0.5-2.2-0.3-3.1C75.5,63,74.6,62.1,73.6,61.3z"/>
      <path d="M72.2,11.4c-1,0.8-1.9,1.8-2.8,2.8c-0.8,0.9-0.8,2-0.3,3.1c0.6,1.1,1.5,1.6,2.9,1.7c0.3-0.1,1-0.2,1.4-0.6
    c1.1-0.9,2.1-1.9,3-3c0.9-1.2,0.6-2.8-0.5-3.8C74.9,10.6,73.3,10.5,72.2,11.4z"/>
    </SvgIcon>
  )
}

export function SignOut (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96">
      <path d="M81.6,50.2c0,0,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.3,0.3-0.5c0-0.1,0-0.2,0.1-0.2c0.1-0.2,0.1-0.3,0.2-0.5
  c0.1-0.3,0.1-0.5,0.1-0.8c0-0.3,0-0.5-0.1-0.8c0-0.2-0.1-0.3-0.2-0.5c0-0.1,0-0.2-0.1-0.2c-0.1-0.2-0.2-0.3-0.3-0.5
  c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.3-0.4-0.5-0.6c0,0,0,0,0,0L62.8,26.8c-1.6-1.6-4.1-1.6-5.7,0c-1.6,1.6-1.6,4.1,0,5.7L68.7,44H45
  c-2.2,0-4,1.8-4,4s1.8,4,4,4h23.7L57.1,63.5c-1.6,1.6-1.6,4.1,0,5.7c0.8,0.8,1.8,1.2,2.8,1.2s2-0.4,2.8-1.2l18.4-18.4
  C81.3,50.6,81.5,50.4,81.6,50.2z"/>
      <path d="M48,7.5c22.3,0,40.5,18.2,40.5,40.5S70.3,88.5,48,88.5S7.5,70.3,7.5,48S25.7,7.5,48,7.5 M48,0C21.5,0,0,21.5,0,48
  s21.5,48,48,48s48-21.5,48-48S74.5,0,48,0L48,0z"/>
    </SvgIcon>
  )
}

export function Filter (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M20.1,3.6L14.2,12c-0.6,0.8-0.9,2-0.9,3v3.2l-2.5,1.5V15c0-1-0.4-2.2-0.9-3L3.9,3.6H20.1 M23,1.6H1C0.4,1.6,0.3,2,0.6,2.4
  l7.6,10.7c0.3,0.4,0.6,1.3,0.6,1.8v7.2c0,0.4,0.2,0.6,0.5,0.6c0.1,0,0.2,0,0.4-0.1l4.8-2.8c0.5-0.3,0.9-1,0.9-1.5V15
  c0-0.5,0.3-1.4,0.6-1.8l7.6-10.7C23.7,2,23.5,1.6,23,1.6L23,1.6z"/>
    </SvgIcon>
  )
}

export function ResendDocument (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 96 96">
      <g>
        <path d="M27.4,96H4c-2.2,0-4-1.8-4-4V68.6c0-2.2,1.8-4,4-4s4,1.8,4,4V88h19.4c2.2,0,4,1.8,4,4S29.6,96,27.4,96z"/>
      </g>
      <g>
        <path d="M92,31.4c-2.2,0-4-1.8-4-4V8H68.6c-2.2,0-4-1.8-4-4s1.8-4,4-4H92c2.2,0,4,1.8,4,4v23.4C96,29.6,94.2,31.4,92,31.4z"/>
      </g>
      <g>
        <path d="M92,96H68.6c-2.2,0-4-1.8-4-4s1.8-4,4-4H88V68.6c0-2.2,1.8-4,4-4s4,1.8,4,4V92C96,94.2,94.2,96,92,96z"/>
      </g>
      <g>
        <path d="M4,31.4c-2.2,0-4-1.8-4-4V4c0-2.2,1.8-4,4-4h23.4c2.2,0,4,1.8,4,4s-1.8,4-4,4H8v19.4C8,29.6,6.2,31.4,4,31.4z"/>
      </g>
      <g>
        <path d="M13.1,36.9c0-1.7,0.3-3.2,0.9-4.5c0.6-1.3,1.4-2.4,2.4-3.2c0.9-0.8,2-1.5,3.2-1.9c1.2-0.4,2.4-0.6,3.6-0.6
    c1.2,0,2.4,0.2,3.6,0.6c1.2,0.4,2.3,1.1,3.3,1.9c0.9,0.9,1.7,1.9,2.3,3.2c0.6,1.3,0.9,2.8,0.9,4.5v22.2c0,1.8-0.3,3.3-0.9,4.5
    c-0.6,1.3-1.4,2.3-2.3,3.1c-1,0.9-2.1,1.5-3.3,1.9c-1.2,0.4-2.4,0.6-3.6,0.6c-1.2,0-2.4-0.2-3.6-0.6c-1.2-0.4-2.3-1.1-3.2-1.9
    c-1-0.8-1.8-1.9-2.4-3.1c-0.6-1.3-0.9-2.8-0.9-4.5V36.9z M19.1,59.1c0,1.5,0.4,2.5,1.2,3.2c0.8,0.7,1.8,1,2.9,1
    c1.1,0,2.1-0.3,2.9-1c0.8-0.7,1.2-1.8,1.2-3.2V36.9c0-1.5-0.4-2.5-1.2-3.2c-0.8-0.7-1.8-1-2.9-1c-1.1,0-2.1,0.3-2.9,1
    c-0.8,0.7-1.2,1.8-1.2,3.2V59.1z"/>
        <path d="M57.7,57v2.6c0,1.3-0.2,2.5-0.7,3.7c-0.5,1.2-1.2,2.2-2,3.1c-0.9,0.9-1.9,1.6-3,2.2c-1.2,0.5-2.4,0.8-3.7,0.8
    c-1.1,0-2.3-0.2-3.5-0.5c-1.2-0.3-2.2-0.9-3.2-1.7c-0.9-0.8-1.7-1.8-2.3-3c-0.6-1.2-0.9-2.8-0.9-4.7V36.3c0-1.4,0.2-2.7,0.7-3.8
    c0.5-1.2,1.1-2.2,2-3.1c0.9-0.9,1.9-1.5,3.1-2c1.2-0.5,2.5-0.7,4-0.7c2.8,0,5.1,0.9,6.9,2.8c0.9,0.9,1.5,2,2,3.2
    c0.5,1.2,0.7,2.6,0.7,4V39h-6v-2c0-1.2-0.3-2.2-1-3.1c-0.7-0.9-1.6-1.3-2.7-1.3c-1.5,0-2.5,0.5-3,1.4c-0.5,0.9-0.7,2.1-0.7,3.5V59
    c0,1.2,0.3,2.2,0.8,3.1c0.5,0.8,1.5,1.2,2.9,1.2c0.4,0,0.8-0.1,1.3-0.2c0.5-0.1,0.9-0.4,1.3-0.7c0.4-0.3,0.6-0.7,0.9-1.3
    c0.2-0.6,0.4-1.2,0.4-2.1V57H57.7z"/>
        <path d="M62.6,69V27h9.7c7.1,0,10.6,4.1,10.6,12.3c0,2.5-0.4,4.6-1.2,6.3c-0.8,1.7-2.1,3.1-4,4.2L84.2,69h-6.4l-5.6-17.9h-3.6V69
    H62.6z M68.6,32.7v13.1H72c1.1,0,1.9-0.1,2.5-0.4c0.6-0.3,1.1-0.7,1.5-1.3c0.3-0.6,0.5-1.2,0.6-2c0.1-0.8,0.2-1.7,0.2-2.8
    c0-1.1-0.1-2-0.2-2.8c-0.1-0.8-0.4-1.5-0.7-2.1c-0.7-1.1-2.2-1.7-4.2-1.7H68.6z"/>
      </g>
    </SvgIcon>
  )
}

export const FullScreenIcon = ({ onClick }: {onClick: () => void}) => (

  <svg className="rpv-core-icon" height="16px" viewBox="0 0 24 24" width="16px" onClick={onClick}>
    <path d="M15.5,8.499l8-8
                M0.5,23.499l8-8
                M5.5,23.499h-5v-5
                M23.5,5.499v-5h-5
                M15.5,15.499l8,8
                M0.5,0.499l8,8
                M0.5,5.499v-5h5
                M18.5,23.499h5v-5"/>
  </svg>
)

export function ShowAllLabels (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path fill="#1E1E1E" d="M19.1,4.1L19.1,4.1H5C3.5,4,2.2,5.1,2,6.7v10.9c0.2,1.3,1.2,2.3,2.5,2.4h14.9
        c1.5-0.2,2.6-1.4,2.6-2.9V7C22,5.3,20.7,4,19.1,4.1z M19.7,7v10.2c0.1,0.4-0.3,0.7-0.7,0.7H5c-0.4,0-0.7-0.3-0.7-0.7c0,0,0,0,0,0V7
        c0-0.4,0.3-0.7,0.7-0.7c0,0,0,0,0,0h14C19.4,6.2,19.7,6.5,19.7,7C19.7,6.9,19.7,6.9,19.7,7z"/>
      <path fill="#1E1E1E" d="M15,11.4C15,11.4,15,11.4,15,11.4H9c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1c0,0,0,0,0,0h6
        c0.6,0,1.1-0.5,1.1-1.1C16.1,11.8,15.6,11.4,15,11.4z"/>
    </SvgIcon>
  )
}

export function HideEmptyLabels (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path fill="#1E1E1E" d="M23.7,0.3C23.5,0.1,23.3,0,23,0c-0.3,0-0.5,0.1-0.7,0.3l-22,22c-0.4,0.4-0.4,1,0,1.4   C0.5,23.9,0.7,24,1,24s0.5-0.1,0.7-0.3l22-22C24.1,1.3,24.1,0.7,23.7,0.3z"/>
      <path fill="#1E1E1E" d="M4.3,16.9V7c0-0.4,0.3-0.7,0.7-0.7c0,0,0,0,0,0h9.9l2.2-2.2H5C3.5,4,2.2,5.1,2,6.7v10.9   c0.1,0.4,0.2,0.8,0.4,1.2L4.3,16.9z"/>
      <path fill="#1E1E1E" d="M19.7,7.1v10c0.1,0.4-0.3,0.7-0.7,0.7H9L6.9,20h12.5c1.5-0.2,2.6-1.4,2.6-2.9V7   c0-0.6-0.2-1.2-0.5-1.6L19.7,7.1z"/>
      <path fill="#1E1E1E" d="M16.1,12.5c0-0.5-0.3-0.9-0.7-1l-2.1,2.1H15C15.6,13.6,16.1,13.1,16.1,12.5z"/>
      <path fill="#1E1E1E" d="M7.9,12.4c0,0.2,0.1,0.4,0.2,0.6l1.7-1.7H9C8.4,11.4,7.9,11.8,7.9,12.4z"/>
    </SvgIcon>
  )
}

export function CheckMark (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 14" style={{ pointerEvents: 'none' }}>
      <g>
        <polygon points="15.9,0 6.3,9.7 2.1,5.4 0,7.6 6.3,14 8.5,11.9 18,2.1  "/>
      </g>
    </SvgIcon>
  )
}

export function CancelMark (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 14" style={{ pointerEvents: 'none' }}>
      <g>
        <polygon points="16,2 14,0 9,5 4,0 2,2 7,7 2,12 4,14 9,9 14,14 16,12 11,7  "/>
      </g>
    </SvgIcon>
  )
}

export const InfoIcon = (props: SvgIconProps) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 4 18"
      width={16}
      height={16}
      xmlSpace="preserve"
      {...props}>
      <path
        fill={'#323436'}
        className="st1"
        d="M.2 18V5.6h3.6V18H.2z"
      />
      <circle className="st1" cx={2} cy={2} r={2} />
    </svg>
  )
}

export const CIcon = (props: SvgIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M16 14c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h12c1.1 0 2 .9 2 2v12z"
        fill={'#00c128'}
      />
      <path
        d="M8.8 11.2C7 11.2 5.6 9.8 5.6 8c0-1.8 1.4-3.2 3.2-3.2.9 0 1.7.3 2.3.9l1.5-1.5c-2.1-2.1-5.5-2.1-7.5 0s-2.1 5.5 0 7.5 5.5 2.1 7.5 0L11 10.3c-.6.6-1.4.9-2.2.9"
        fill={'#fff'}
      />
    </svg>
  )
}

export const OIcon = (props: SvgIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M16 14c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h12c1.1 0 2 .9 2 2v12z"
        fill={'#e20001'}
      />
      <path
        d="M8 13c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5zm0-8C6.3 5 5 6.3 5 8s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z"
        fill={'#fff'}
      />
    </svg>
  )
}

export const BellIcon = (props: SvgIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      width={24}
      height={24}
      xmlSpace="preserve"
      {...props}
    >
      <path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"/>
    </svg>
  )
}

export const IncompleteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <polygon fill='none' points="9,18 10.2,18 10.2,10.4 18,10.4 18,9 9,9 "/>
      <path fill='#E2E9E9' d="M9,0C4,0,0,4,0,9c0,5,4,9,9,9h0V9h9C18,4,14,0,9,0z"/>
      <path fill='#E21B19' d="M10.2,18L10.2,18c4.3,0,7.8-3.3,7.8-7.6h-7.8V18z"/>
    </SvgIcon>
  )
}

export const ClockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" xmlSpace="preserve">
      <g xmlns="http://www.w3.org/2000/svg">
        <path fill='rgba(0, 0, 0, 0.54)' d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z M12,22C6.5,22,2,17.5,2,12C2,6.5,6.5,2,12,2   s10,4.5,10,10C22,17.5,17.5,22,12,22z"/>
        <path fill='rgba(0, 0, 0, 0.54)' d="M18,11.8h-5V6c0-0.6-0.4-1-1-1s-1,0.4-1,1v6.8c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1S18.5,11.8,18,11.8z"/>
      </g>
    </SvgIcon>
  )
}

export const CalendarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" xmlSpace="preserve">
      <g xmlns="http://www.w3.org/2000/svg">
        <path fill='rgba(0, 0, 0, 0.54)' id="calendar" d="M18,13.2v6h-6v-6H18z M16.8,0h2.4v2.4h1.2c1.3,0,2.4,1.1,2.4,2.4c0,0,0,0,0,0v16.7c0,1.3-1.1,2.4-2.4,2.4   c0,0,0,0,0,0H3.6c-0.6,0-1.3-0.2-1.7-0.7c-0.5-0.4-0.7-1.1-0.7-1.7V4.8c0-0.6,0.2-1.3,0.7-1.7C2.4,2.7,3,2.4,3.6,2.4h1.2V0h2.4v2.4   h9.6V0z M20.4,21.6V8.4H3.6v13.2H20.4z"/>
      </g>
    </SvgIcon>
  )
}

export const Briefcase = (props: SvgIconProps) => {
  return (
    <SvgIcon version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 96 96" {...props}>
      <path d="M48,0C21.5,0,0,21.5,0,48c0,26.5,21.5,48,48,48s48-21.5,48-48C96,21.5,74.5,0,48,0z M48,90.4c-23.4,0-42.4-19-42.4-42.4S24.6,5.6,48,5.6s42.4,19,42.4,42.4S71.4,90.4,48,90.4z"/>
      <path d="M67.4,33.4h-6.5v-4c0-3.1-2.5-5.7-5.7-5.7H40.7c-3.1,0-5.7,2.5-5.7,5.7v4h-6.5c-3.6,0-6.5,2.9-6.5,6.5v9.7h51.8v-9.7C73.9,36.3,71,33.4,67.4,33.4z M56.1,33.4H39.9v-4c0-0.4,0.4-0.8,0.8-0.8h14.6c0.4,0,0.8,0.4,0.8,0.8V33.4z"/>
      <path d="M54.5,56.1c0,1.8-1.4,3.2-3.2,3.2c0,0,0,0,0,0h-6.5c-1.8,0-3.2-1.4-3.2-3.2c0,0,0,0,0,0v-3.2H22.1v12.9c0,3.6,2.9,6.5,6.5,6.5h38.8c3.6,0,6.5-2.9,6.5-6.5V52.9H54.5V56.1z"/>
    </SvgIcon>

  )
}

export const RecycleBin = (props: SvgIconProps) => {
  return (
    <SvgIcon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 22"
      {...props}>
      <path fill='#000' xmlns="http://www.w3.org/2000/svg" id="trash" d="M5.6,0v1.5H0v2.8h16V1.5h-5.6V0H5.6z M0.9,6.5V20h14.3V6.5H0.9z"/>
    </SvgIcon>

  )
}
