export const isErrorCode = (message: string | number): boolean => {
  const code = +message
  return code >= 400
}

export const uriParts = (uri: string): { pathname: string, search?: string, hash?: string } => {
  const [pathname = '', searchPart = ''] = uri.split('?')
  const [search, hash] = searchPart.split('#')
  return { pathname, hash, search }
}
