import { FC } from 'react'
import { NotificationType } from 'types'
import Dropdown, { DropdownProps } from 'common/LisaControls/Dropdown'

export type SelectOptions = {
  value: NotificationType
  text: string
}

export const NotificationItems: SelectOptions[] = [
  {
    value: NotificationType.DOWNTIME,
    text: 'Planned downtime'
  },
  {
    value: NotificationType.RELEASE,
    text: 'Version Release'
  },
  {
    value: NotificationType.UNPLANNED,
    text: 'Unplanned downtime'
  }
]
export const NotificationSelect: FC<DropdownProps> = (props) => {
  return (
    <Dropdown
      items={NotificationItems}
      {...props}
    />
  )
}
