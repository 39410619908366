import { FC } from 'react'
import { CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.common.white
  }
}))

type WithCircularLoaderProps = {
  loading: boolean
  fullPage?: boolean
  text?: string
}
export const WithCircularLoader: FC<WithCircularLoaderProps> = ({
  loading, children, fullPage = false, text
}) => {
  const classes = useStyles()
  if (loading) {
    return (
      <Grid
        container
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        style={{ height: fullPage ? '100%' : 'auto' }}>
        <CircularProgress/>
        {text && <Typography className={classes.text}>{text}</Typography>}
      </Grid>
    )
  }
  return <>{children}</>
}
