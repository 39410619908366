import { FC, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { TransitionId } from 'types'
import {
  NewQueriesFilters,
  NewQueriesSortOptions, NewQueriesTransitions,
  resetNewQueriesFilters,
  setNewQueriesSortOption,
  setNewQueriesTransition,
  useDashboard
} from 'redux/slices/dashboardSlice'
import Dropdown from 'common/LisaControls/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { ResetFiltersButton } from 'common/Buttons/ResetFiltersButton'
import { useGetNewQueriesQuery } from 'services/api/queryApi'
import { useLisaAuth } from 'hooks/useLisaAuth'

const sortValue = (filters: NewQueriesFilters): NewQueriesSortOptions => {
  if (filters.prioritySort === 'asc') {
    return 'priority_asc'
  }
  if (filters.prioritySort === 'desc') {
    return 'priority_desc'
  }
  if (filters.dueDateSort === 'asc') {
    return 'due_date_asc'
  }
  if (filters.dueDateSort === 'desc') {
    return 'due_date_desc'
  }
  if (filters.timeAddedSort === 'asc') {
    return 'time_added_asc'
  }
  if (filters.timeAddedSort === 'desc') {
    return 'time_added_desc'
  }
  return 'time_added_asc'
}

export const NewQueriesFilter: FC = () => {
  const { newQueriesFilters } = useSelector(useDashboard)
  const { userId } = useLisaAuth()
  const { data: newQueries = [], fulfilledTimeStamp } = useGetNewQueriesQuery(userId)

  const newQueriesTransitions = useMemo<{
    transitionId: TransitionId
    transitionName: string
  }[]>(() => {
    return newQueries.reduce<NewQueriesTransitions[]>((transitions, { transitionId, transitionName }) => {
      if (!transitions.find(_ => _.transitionId === transitionId)) {
        transitions.push({ transitionId, transitionName: transitionName ?? '' })
      }
      return transitions
    }, [])
      .sort((a, z) => a.transitionName.toUpperCase().localeCompare(
        z.transitionName.toUpperCase()))
  }, [fulfilledTimeStamp])

  const dispatch = useDispatch()
  const sortItems = useMemo<{ value: NewQueriesSortOptions, text: string }[]>(() => {
    return [
      { value: 'time_added_asc', text: 'Time added (older first)' },
      { value: 'time_added_desc', text: 'Time added (newer first)' },
      { value: 'priority_desc', text: 'Priority (higher first)' },
      { value: 'priority_asc', text: 'Priority (lower first)' },
      { value: 'due_date_asc', text: 'Due Date (sooner first)' },
      { value: 'due_date_desc', text: 'Due Date (later first)' }
    ]
  }, [])
  return (
    <Grid container spacing={2} alignItems={'flex-end'}>
      <Grid item xs={6} sm={3}>
        <Dropdown
          name={'transition'}
          label={'Asset'}
          showSelectOption={false}
          value={newQueriesFilters.transitionId}
          onChange={(e) => {
            dispatch(setNewQueriesTransition(e.target.value as TransitionId))
          }}
          items={[
            { value: '', text: 'All assets' },
            ...newQueriesTransitions.map(_ => ({ value: _.transitionId, text: _.transitionName }))
          ]}/>
      </Grid>
      <Grid item xs={5} sm={2}>
        <Dropdown
          name={'sortBy'}
          label={'Sort by'}
          showSelectOption={false}
          value={sortValue(newQueriesFilters)}
          onChange={(e) => {
            dispatch(setNewQueriesSortOption(e.target.value as NewQueriesSortOptions))
          }}
          items={sortItems}/>
      </Grid>
      <Grid item xs={1}>
        <ResetFiltersButton onClick={() => dispatch(resetNewQueriesFilters())}/>
      </Grid>
    </Grid>
  )
}
