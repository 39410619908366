import { FC, useMemo } from 'react'
import { Report } from 'common/Report/Report'
import { ChartData, ChartOptions, ChartType } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { WithReportLoader } from 'common/WithLoader/WithLoader'
import { useGetTransitionQuery } from 'services/api/transitionApi'
import { useTransitionId } from 'context/TransitionContext'
import {
  useGetDeliveryBibleWeeklyReportQuery,
  useUpdateDeliveryBibleWeeklyReportNotesMutation
} from 'services/api/documentApi'
import { DeliveryBibleReportValue } from 'types'

const getConfig = (title: string): ChartOptions => ({
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    mode: 'index'
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      fullSize: false,
      align: 'center',
      reverse: true,
      labels: {
        boxWidth: 40,
        font: {
          size: 12
        }
      }
    },
    title: {
      display: true,
      text: title,
      align: 'start',
      padding: {
        bottom: 20
      }
    },
    tooltip: {
      intersect: false,
      itemSort: () => -1,
      callbacks: {
        label: ({ formattedValue, dataset: { label } }) => {
          return `${label}: ${formattedValue}%`
        }
      }
    }
  },
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: false,
      ticks: {
        callback: _ => `${_}%`
      }
    }
  }
})
const initialValue = (): ChartData<ChartType, number[]> => ({
  labels: [],
  datasets: [
    {
      label: 'accepted',
      backgroundColor: ['#cde8b5'],
      maxBarThickness: 30,
      data: []
    },
    {
      label: 'reviewed',
      backgroundColor: ['#02c12b'],
      maxBarThickness: 30,
      data: []
    }
  ]
})

const chartData = (data: DeliveryBibleReportValue[]): ChartData => {
  return data.reduce((cd, { reviewedPercents, week, acceptedPercents }, i) => {
    cd.labels?.push(`W${week}`)
    // const accepted =
    cd.datasets[0].data.push((cd.datasets[0].data[i - 1] ?? 0) + acceptedPercents)
    cd.datasets[1].data.push((cd.datasets[1].data[i - 1] ?? 0) + reviewedPercents)
    return cd
  }, initialValue())
  // chartD.datasets[0].data = chartD.datasets[0].data.map((d: number) => Math.round(d))
}

export const DeliveryBibleReport: FC = () => {
  const { data: transitionData } = useGetTransitionQuery(useTransitionId())
  const transition = transitionData!
  const { transitionId } = transition
  const { data: deliveryBibleReport, isLoading } = useGetDeliveryBibleWeeklyReportQuery(transitionId)
  const [updateNotes, { originalArgs }] = useUpdateDeliveryBibleWeeklyReportNotesMutation()
  const config = useMemo(() => getConfig(transition.name), [transition.name])
  const data = deliveryBibleReport?.data ?? []
  const notes = originalArgs?.notes ?? deliveryBibleReport?.notes ?? ''

  const ChartElement = useMemo(() => {
    return <Bar type={'bar'} data={chartData(data)} options={config} />
  }, [data, config])

  return (
    <Report
      title={'Delivery Bible'}
      info={'Number of reviewed documents in relation to the relevant labelled documents.'}
      reportNote={{
        note: notes,
        saveNote: (notes) => updateNotes({ notes, transitionId })
      }}>
      <WithReportLoader
        noWrapper
        loading={isLoading}
        hasResults={true}>
        { ChartElement }
      </WithReportLoader>
    </Report>
  )
}
