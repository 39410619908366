import { FC, useEffect, useState } from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { formattedDate } from 'utils/date'
import { LogoUploadWithPreview } from 'common/LogoUploadWithPreview/LogoUploadWithPreview'
import Input from 'common/LisaControls/Input'
import { useGetUserQuery } from 'services/api/usersApi'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useEditTenantMutation, useGetTenantQuery, useUploadLogoMutation } from 'services/api/tenantApi'
import { WithLoader } from 'common/WithLoader/WithLoader'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { closeModal, openModal } from 'redux/slices/appSlice'
import { useDispatch } from 'react-redux'
import { tenantInitials } from 'utils/tenant'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBlock: 16
  },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
    whiteSpace: 'normal'
  },
  tenantInfoLine: {
    backgroundColor: theme.palette.common.white,
    marginTop: 4
  },
  info: {
    whiteSpace: 'normal',
    fontSize: 16,
    fontWeight: 600
  },
  avatarPreviewText: {

  }
}))

export interface ClientSetupFormProps {
}

export const ClientSetupForm: FC<ClientSetupFormProps> = () => {
  const dispatch = useDispatch()
  const { userId } = useLisaAuth()
  const { data: user } = useGetUserQuery(userId)
  const { data: tenants = [], isFetching } = useGetTenantQuery(user?.tenantId ?? '', {
    skip: !user || !user.tenantId
  })
  const classes = useStyles()
  const [tenant] = tenants
  const [uploadLogo] = useUploadLogoMutation()
  const [editTenant] = useEditTenantMutation()

  const [name, setName] = useState('')
  const [logo, setLogo] = useState<Blob>()
  const [avatar, setAvatar] = useState<Blob>()

  useEffect(() => {
    if (tenant && !tenant.wasLogged) {
      dispatch(openModal('CLIENT_SETUP_FORM'))
    }
    if (tenant) {
      setName(tenant.name)
    }
  }, [tenant])
  if (!tenant) {
    return null
  }
  return (
    <LisaModal
      modalType={'CLIENT_SETUP_FORM'}
      title={tenant.wasLogged ? `EDIT ${tenant.name} DATA` : `WELCOME ${tenant.name}!`}
      scroll={'body'}>
      <WithLoader loading={isFetching} hasResults={!!tenant}>
        {
          tenant &&
        <Grid container direction={'column'} spacing={2} className={classes.root}>
          {
            !tenant.wasLogged &&
            <Grid item xs>
              <Typography className={classes.title}>
              Congratulations {tenant.name} on your LISA record Management licence purchase!
              </Typography>
            </Grid>
          }
          <Grid item container direction={'column'} spacing={1} xs>
            <Grid item className={classes.tenantInfoLine}>
              <Typography variant={'body2'}>
              Licence package: <b>{tenant.subscriptionType}</b>
              </Typography>
            </Grid>
            {/* <Grid item className={classes.tenantInfoLine}> */}
            {/*  <Typography variant={'body2'}> */}
            {/*  Licence no: <b>{123123123123}</b> */}
            {/*  </Typography> */}
            {/* </Grid> */}
            <Grid item className={classes.tenantInfoLine}>
              <Typography variant={'body2'}>
              Valid until: <b>{tenant.validThru ? formattedDate(new Date(tenant.validThru)) : '-'}</b>
              </Typography>
            </Grid>
            {/* <Grid item className={classes.tenantInfoLine}> */}
            {/*  <Typography variant={'body2'}> */}
            {/*  Domain: <b>test.mylisa.aero</b> */}
            {/*  </Typography> */}
            {/* </Grid> */}
          </Grid>
          {
            !tenant.wasLogged &&
            <Grid item xs>
              <Typography variant={'body2'} className={classes.info}>
              In the next steps you can customise your company profile. You can skip this step and configure it later from
              the user / company profile menu
              </Typography>
            </Grid>
          }
          <Grid item xs container>
            <Grid item xs={6}>
              <Input
                label={'Subscriber Company Name (company name)*'}
                value={name}
                onChange={(e) => setName(e.target.value)}/>
            </Grid>
          </Grid>
          <Grid item>
            <LogoUploadWithPreview
              title={'Company avatar preview'}
              imageSize={'sm'}
              imagePlaceholder={tenantInitials(tenant.name)}
              imageUrl={avatar ? URL.createObjectURL(avatar) : tenant.avatar}
              onImageSelect={setAvatar}>
              <Typography variant={'body2'} className={classes.avatarPreviewText}>
              Company avatar is the representation of company identity (part of the logotype, icon, picture) which is
              used in responsive app states. Recommended image upload size: Image Ratio 1:1; Max. image width/height 640px;
              Png, Jpg, Svg. If no image is added branding shall be displayed as shown in the preview.
              </Typography>
            </LogoUploadWithPreview>
          </Grid>
          <Grid item>
            <LogoUploadWithPreview
              title={'Company Logo preview'}
              imagePlaceholder={tenant.name}
              imageUrl={logo ? URL.createObjectURL(logo) : tenant.logo}
              onImageSelect={setLogo}>
              <Typography variant={'body2'} className={classes.avatarPreviewText}>
              Company logo is representation of company identity which is used in full view app states. Recommended image
              upload size: Image ratio 4:1; Max. Image width/height 800px; Png, Jpg, Svg. If no image is added branding
              shall be displayed as shown in the preview.
              </Typography>
            </LogoUploadWithPreview>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                onClick={async () => {
                  let avatarUrl = tenant.avatar
                  let logoUrl = tenant.logo
                  if (avatar) {
                    const formData = new FormData()
                    formData.set('file', avatar)
                    avatarUrl = await uploadLogo({ body: formData, suffix: 'avatar' }).unwrap()
                  }
                  if (logo) {
                    const formData = new FormData()
                    formData.set('file', logo)
                    logoUrl = await uploadLogo({ body: formData, suffix: 'logo' }).unwrap()
                  }
                  await editTenant({
                    ...tenant,
                    wasLogged: true,
                    avatar: avatarUrl,
                    logo: logoUrl,
                    name
                  }).unwrap()
                  dispatch(closeModal())
                }}
                color="primary">
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={async () => {
                  if (!tenant.wasLogged) {
                    await editTenant({
                      wasLogged: true,
                      tenantId: tenant.tenantId
                    })
                  }
                  dispatch(closeModal())
                }}
                color="primary">
                {tenant.wasLogged ? 'Cancel' : 'Skip'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        }
      </WithLoader>
    </LisaModal>
  )
}
