import { FC, useCallback } from 'react'
import { DocumentStatusChangeForm } from 'pages/Atok/components/DocumentStatusChangeForm'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { useDispatch, useSelector } from 'react-redux'
import { sortBy, SortColumnToConfigMap, useSearchDocuments } from 'redux/slices/searchDocumentsSlice'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { useBulkStatusUpdateMutation } from 'services/api/documentApi'
import {
  ColumnConfiguratorKey,
  getCheckedColumnsFromCheckedColumnsSettings, globalSearchColumns,
  mandatoryColumns
} from 'utils/columnConfigurator'
import { User } from 'types'
import { ColumnConfigurator } from 'common/ColumnConfigurator/ColumnConfigurator'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useUpdateUserSettingsMutation } from 'services/api/usersApi'

export const DocumentSearchModals: FC = () => {
  const dispatch = useDispatch()
  const { user: { settings }, userId } = useLisaAuth()
  const {
    selectedDocuments,
    searchParams,
    selectedLabel
  } = useSelector(useSearchDocuments)
  const [updateUserSettings] = useUpdateUserSettingsMutation()
  const [bulkStatusUpdate] = useBulkStatusUpdateMutation()
  const bulkStatusUpdateCB = useCallback(async (params) => {
    const {
      success,
      message
    } = await bulkStatusUpdate(params).unwrap()
    if (!success && message) {
      dispatch(openToast({
        severity: 'error',
        message: message
      }))
    } else {
      dispatch(openToast({
        severity: 'info',
        message: 'Document status changes successfully applied'
      }))
    }
  }, [])
  return (
    <>
      <LisaModal
        title={`Change status of ${selectedDocuments.length > 0 ? 'selected' : 'all'} documents`}
        modalType={'DOCUMENT_STATUS_CHANGE_SEARCH_FORM'}
        scroll="body">
        <DocumentStatusChangeForm
          label={selectedLabel}
          documentIds={selectedDocuments.map(_ => _.documentId)}
          bulkStatusUpdate={bulkStatusUpdateCB}/>
      </LisaModal>
      <ColumnConfigurator
        modalType={'COLUMN_CONFIGURATOR_SEARCH'}
        columns={globalSearchColumns}
        checkedColumns={getCheckedColumnsFromCheckedColumnsSettings(settings.searchSettings)}
        mandatoryColumns={mandatoryColumns.get('globalSearch')}
        onSave={async (checkedColumns) => {
          const searchSettings = Object.keys(settings.searchSettings).reduce((is, ccKey) => {
            return {
              ...is,
              [ccKey]: checkedColumns.includes(ccKey as keyof User['settings']['searchSettings'])
            }
          }, settings.searchSettings)
          if (!checkedColumns.includes(SortColumnToConfigMap.get(searchParams.sortColumn ?? 'Name') as ColumnConfiguratorKey)) {
            // Sort by name if currently selected column is turned off
            dispatch(sortBy('Name'))
          }
          updateUserSettings({
            userId,
            settings: {
              searchSettings
            }
          })
          dispatch(closeModal(['COLUMN_CONFIGURATOR_SEARCH']))
        }}/>
    </>
  )
}
