import { FC, useEffect, MouseEvent, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ClientDocumentsSortColumn,
  resetSelectedDocuments,
  setPage,
  sortBy,
  toggleAllDocumentIds,
  toggleDocumentId,
  useClientDocuments
} from 'redux/slices/clientDocumentsSlice'
import { useTransitionContext } from 'context/TransitionContext'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Chip, MenuItem, TableSortLabelProps, Typography } from '@material-ui/core'
import { DocumentLink } from 'common/DocumentLink/DocumentLink'
import { useClientLabels } from 'redux/slices/clientLabelsSlice'
import { LisaPaper } from 'common/LisaPaper/LisaPaper'
import { useGetClientFilesFromFolderQuery } from 'services/api/exportApi'
import { DocumentAcceptanceButton } from 'common/Buttons/DocumentAcceptanceButton'
import { openModal, openToast } from 'redux/slices/appSlice'
import { useChangeAcceptanceMutation, useChangeBulkAcceptanceMutation } from 'services/api/documentApi'
import { CheckMark } from 'common/Icons/SvgIcons'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { updateFilters as queryUpdateFilters } from 'redux/slices/queriesSlice'
import { ClientDocument, User } from 'types'
import { useHistory } from 'react-router-dom'
import { clientColumns, ColumnConfiguratorKey, columnConfiguratorLabels } from 'utils/columnConfigurator'
import { Cell, Column, LisaTable, Row } from 'common/LisaTable/LisaTable'
import { AbbreviationTypeIcon } from 'common/AbbreviationTypeIcon/AbbreviationTypeIcon'
import { Label } from 'common/Label/Label'

const useStyles = makeStyles((theme) => ({
  dataTableCheckbox: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.35rem'
    }
  },
  bodyRows: {
    height: '42px',
    '& .MuiChip-label': {
      lineHeight: '18px'
    }
  },
  statusLabel: {
    display: 'flex',
    alignItems: 'center',
    rowGap: '2px',
    paddingRight: '16px'
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  labels: {
    '& .MuiChip-sizeSmall': {
      background: theme.palette.common.white,
      height: '24px',
      fontSize: '13px',
      fontWeight: '600',
      letterSpacing: '.3px',
      color: theme.palette.black.main,
      borderRadius: '4px',
      border: `2px solid ${theme.palette.client.main}`,
      textTransform: 'none'
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 3,
    color: theme.palette.client.main
  },
  defaultTableLabel: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '12px',
    letterSpacing: '.05px',
    cursor: 'default'
  },
  queryCount: {
    minWidth: '36px',
    marginLeft: '4px',
    display: 'flex',
    '& .MuiChip-root, .MuiChip-labelSmall': {
      cursor: 'pointer',
      '&.MuiChip-root:hover': {
        background: theme.palette.lime.dark
      }
    }
  },
  emptyChip: {
    '&.MuiChip-root': {
      pointerEvents: 'none',
      background: 'transparent',
      '&.MuiChip-root:hover': {
        background: 'transparent'
      }
    }
  }
}))

export const ClientDocuments: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { isExternal, user: { settings: { clientSettings } } } = useLisaAuth()
  const { openDocument } = useTransitionContext()
  const { documentIds, filters } = useSelector(useClientDocuments)
  const { sortOrder, sortColumn, page, pageSize } = filters
  const { selected: label } = useSelector(useClientLabels)
  const { transitionId } = useTransitionContext()
  const [changeAcceptance] = useChangeAcceptanceMutation()
  const [changeBulkAcceptance] = useChangeBulkAcceptanceMutation()
  const { data: { rows, pages, total } = { rows: [], pages: 0, total: 0 }, isFetching } = useGetClientFilesFromFolderQuery({
    transitionId,
    labelId: label?.labelId,
    page,
    pageSize,
    sortOrder: sortOrder,
    sortColumn: sortColumn
  }, { skip: !label?.labelId })
  const hasLockedStatus = rows.filter(_ => documentIds.includes(_.documentId)).some(_ => _.statusLocked)

  useEffect(() => {
    dispatch(resetSelectedDocuments())
    dispatch(setPage(1))
  }, [label?.labelId])

  const handleOpenAllSelected = () => {
    documentIds.forEach((documentId) => {
      openDocument(documentId)
    })
    dispatch(resetSelectedDocuments())
  }

  const handleAcceptanceSelected = async (accepted: boolean) => {
    const { success, message } = await changeBulkAcceptance({ documentIds: documentIds, isAccepted: accepted }).unwrap()
    if (!success) {
      dispatch(openToast({ severity: 'error', message: message ?? 'Error' }))
    }
  }

  const items = [
    <MenuItem key='open-selected' disabled={documentIds.length === 0} onClick={handleOpenAllSelected}>
      Open Selected
    </MenuItem>,
    <MenuItem
      key={'configure-table-view'}
      onClick={() => dispatch(openModal('COLUMN_CONFIGURATOR'))}>
      {'Configure table view'}
    </MenuItem>
  ]

  isExternal &&
    items.push(<MenuItem
      key='accept-selected'
      disabled={documentIds.length === 0 || hasLockedStatus}
      onClick={() => handleAcceptanceSelected(true)}
    >
      Accept Selected
    </MenuItem>,
    <MenuItem
      key='remove-accepted-for-selected'
      disabled={!isExternal || documentIds.length === 0 || hasLockedStatus}
      onClick={() => handleAcceptanceSelected(false)}
    >
      Remove Accepted for Selected
    </MenuItem>)

  const title = (
    <Typography
      className={classes.title}
      id='tableTitle'
      component='div'>
      <div className={classes.labels}>
        {label && <Label fromClient label={label}/>}
      </div>
    </Typography>
  )

  const handleChangeAcceptance = async (accepted: boolean, documentId?: string) => {
    const { success, message } = await changeAcceptance({ documentId: documentId!, isAccepted: accepted }).unwrap()
    if (!success) {
      dispatch(openToast({ severity: 'error', message: message ?? 'Error' }))
    }
  }

  const handleOnClick = (e: MouseEvent<HTMLDivElement>, document: ClientDocument) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(queryUpdateFilters({ documentId: document.documentId.toLocaleUpperCase(), documentName: document.name, queryStatus: null, fromClientStructure: true }))
    history.push(`/structures/${'queries'}/${transitionId}`)
  }

  const tableCellProps: Map<ColumnConfiguratorKey, TableSortLabelProps> = useMemo(() => {
    const config: [ColumnConfiguratorKey, ClientDocumentsSortColumn][] = [
      ['clientListFilename', 'Name'],
      ['clientListDocumentType', 'DocType'],
      ['clientListQueries', 'Query'],
      ['clientListDocumentStatus', 'BusinessStatus']
    ]
    return new Map(config.map(([ccKey, sc]) => [ccKey, {
      active: sortColumn === sc,
      direction: sortColumn === sc ? sortOrder || 'asc' : 'asc',
      onClick: () => dispatch(sortBy(sc))
    }]))
  }, [sortOrder, sortColumn])

  const columns: Column[] = useMemo(() => {
    return clientColumns
      .filter((ic) => clientSettings[ic as keyof User['settings']['clientSettings']])
      .map((ccKey) => {
        return {
          id: String(ccKey),
          label: columnConfiguratorLabels.get(ccKey) ?? '-',
          className: ccKey === 'clientListFilename' ? 'fileName' : undefined,
          tableSortLabelProps: tableCellProps.get(ccKey),
          width: ccKey === 'clientListDocumentType' ? '60px' : undefined
        }
      })
  }, [clientSettings, tableCellProps])

  const tableRows: Row[] = useMemo(() => {
    const ccKeys = Object.entries(clientSettings).filter((entry) => entry[1]).map(([ccKey]) => ccKey as ColumnConfiguratorKey)
    return rows.map((document) => {
      const isQueryCountEmpty = document.queryCount === 0 && document.closedQueryCount === 0
      const isLocked = document.statusLocked
      const cells: Cell[] = []
      if (ccKeys.includes('clientListDocumentType')) {
        cells.push({
          id: `document-${document.documentId}-docType`,
          value: (
            <AbbreviationTypeIcon
              docTypeRecognitionResultByPages={document.docTypeRecognitionResultByPages}
              docTypeRecognitionResult={document.docTypeRecognitionResult}/>
          )
        })
      }
      if (ccKeys.includes('clientListFilename')) {
        cells.push({
          id: `document-${document.documentId}-fileName`,
          value: <DocumentLink document={document} noDocType documentNameClass={'fileNameEllipsis'}/>
        })
      }
      if (ccKeys.includes('clientListQueries')) {
        cells.push({
          id: `document-${document.documentId}-processingPriority`,
          value: (
            <div className={classes.queryCount}>
              <Chip
                size="small"
                className={isQueryCountEmpty ? classes.emptyChip : ''}
                label={document.queryCount > 0 && document.queryCount}
                onClick={(e: MouseEvent<HTMLDivElement>) => handleOnClick(e, document)}/>
            </div>
          )
        })
      }
      if (ccKeys.includes('clientListDocumentStatus')) {
        cells.push({
          id: `document-${document.documentId}-importedBy`,
          value: (
            <>
              {!isExternal
                ? <div className={classes.statusLabel}>
                  <Chip
                    className={clsx([
                      'FileStatus',
                      document.businessStatusName
                    ])}
                    label={document.businessStatusName}
                    size="small"
                    variant="outlined"
                    icon={document.businessStatusName === 'Accepted' ? <CheckMark/> : <></>}
                  />
                </div>
                : isLocked
                  ? <div className={classes.statusLabel}>
                    <Chip
                      className={clsx([
                        'FileStatus',
                        document.businessStatusName
                      ])}
                      label={document.businessStatusName}
                      size="small"
                      variant="outlined"
                      icon={document.businessStatusName === 'Accepted' ? <CheckMark/> : <></>}
                    />
                  </div>
                  : <DocumentAcceptanceButton
                    accepted={document.businessStatus}
                    onClick={(ac: boolean) => handleChangeAcceptance(ac, document.documentId)}/>}
            </>
          )
        })
      }
      return {
        id: `document-${document.documentId}`,
        selected: documentIds.includes(document.documentId),
        document,
        cells
      }
    })
  }, [clientSettings, rows, documentIds])

  return (
    <LisaPaper toolbar={{ items, title }}>
      <LisaTable
        columns={columns}
        rows={tableRows}
        onSelectAll={() => dispatch(toggleAllDocumentIds(rows))}
        onRowToggle={(document) => dispatch(toggleDocumentId(document.documentId))}
        selectedRows={documentIds}
        allRows={rows.map(_ => _.documentId)}
        isFetching={isFetching}
        page={page}
        pages={pages}
        pageSize={pageSize}
        total={total}
        onPageChange={(value: number) => dispatch(setPage(value))}/>
    </LisaPaper>
  )
}
