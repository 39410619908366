import { makeStyles, Typography } from '@material-ui/core'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { GridGrow } from 'common/Grid/GridGrow'

const useStyles = makeStyles((theme) => ({
  screenMessage: {
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '32px',
    color: theme.palette.grey2.dark
  }
}))

const ReportsPage = () => {
  const classes = useStyles()
  return (
    <ContentWrapper newLayout>
      <GridGrow container justifyContent={'center'} alignItems={'center'}>
        <Typography className={classes.screenMessage} component={'div'}>Coming Soon</Typography>
      </GridGrow>
    </ContentWrapper>
  )
}

ReportsPage.propTypes = {}

export default ReportsPage
