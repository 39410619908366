import { FC, KeyboardEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { onLabelRenamed, useA2KLabels } from 'redux/slices/a2kLabelsSlice'
import { LisaForm, useLisaForm } from 'common/Form/LisaForm'
import { useTransitionContext } from 'context/TransitionContext'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { hasAllowedCharacters, INVALID_LABEL_NAME } from 'utils/validators'
import { useRenameLabelMutation } from 'services/api/labellingApi'
import Input from 'common/LisaControls/Input'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '32px',
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  paper: {
    padding: '24px'
  },
  sectionTitle: {
    fontSize: '14px',
    margin: '32px 0 6px 0',
    fontWeight: 700,
    letterSpacing: '.8px',
    textTransform: 'uppercase'
  },
  actionButtons: {
    padding: '32px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  }
}))

type FormValues = {
  name: string
}
type FormErrors = {
  name: string
}

const RenameLabelModal: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { selected: label } = useSelector(useA2KLabels)
  const { transitionId } = useTransitionContext()
  const [renameLabel] = useRenameLabelMutation()
  const {
    values, handleInputChange
  } = useLisaForm<FormValues, FormErrors>({ name: label!.name })
  const isEmpty = values.name.trim().length === 0
  const isNameValid = hasAllowedCharacters(values.name)
  const nameError = isNameValid || isEmpty ? '' : INVALID_LABEL_NAME

  const handleRenameLabel = async () => {
    if (isNameValid) {
      const labelName = values.name.trim()
      const { isError, message, structure } = await renameLabel({
        parentId: label!.parentId!,
        labelId: label!.labelId!,
        labelName,
        transitionId
      }).unwrap()
      if (isError) {
        dispatch(openToast({
          severity: 'error',
          message: message
        }))
      } else {
        dispatch(onLabelRenamed({ structure, labelName }))
        dispatch(closeModal())
      }
    }
  }
  const handleKeyUp = async (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      await handleRenameLabel()
    }
  }

  return (
    <div className={classes.root}>
      <LisaForm onSubmit={e => { e.preventDefault() }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Input
              label="Label name"
              name="name"
              required={true}
              value={values.name}
              onChange={handleInputChange}
              onKeyUp={handleKeyUp}
              errMsg={nameError ?? ''}
            />
          </Grid>
        </Grid>
      </LisaForm>
      <div className={classes.actionButtons}>
        <Button
          onClick={handleRenameLabel}
          variant="contained"
          color="primary"
          size="small"
          disabled={values.name === label!.name || isEmpty || !isNameValid}>
          Rename
        </Button>
        <Button
          onClick={() => dispatch(closeModal())}
          variant="contained"
          color="primary"
          size="small"
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default RenameLabelModal
