import { FC } from 'react'
import * as Icons from 'common/Icons/SvgIcons'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBlock: '20px',
    color: theme.palette.black.light100
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      width: '52px',
      height: '52px',
      marginBottom: '24px',
      fill: theme.palette.black.light100
    }
  },
  message: {
    display: 'flex',
    textAlign: 'center',
    fontSize: '20px',
    letterSpacing: '-.3px',
    fontWeight: 600
  }
}))

export type NoResultsProps = {
  type?: 'default' | 'queries' | 'documents' | 'labels' | 'users' | 'transitions',
  message?: string,
  style?: object
}
export const NoResults: FC<NoResultsProps> = ({
  type = 'default',
  message = 'No results found',
  style = {}
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root} style={style} data-testid='noLabelsResult'>
      <div className={classes.icon}>
        {type === 'default' && <Icons.NoResults/>}
        {type === 'queries' && <Icons.NoQueries/>}
        {type === 'documents' && <Icons.NoDocuments/>}
        {type === 'labels' && <Icons.NoLabel/>}
        {type === 'users' && <Icons.User/>}
        {type === 'transitions' && <Icons.NoTransitions/>}
      </div>
      <Typography className={classes.message}>{message}</Typography>
    </div>
  )
}
