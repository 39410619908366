import { FC } from 'react'
import { LisaForm, useLisaForm } from 'common/Form/LisaForm'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { closeModal } from 'redux/slices/appSlice'
import { useDispatch } from 'react-redux'
import TextArea from 'common/LisaControls/TextArea'

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 16
  },
  actionButtons: {
    padding: '32px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  },
  textArea: {
    width: '100%'
  }
}))

type FormValues = {
  text: string
}

type NoteFormProps = {
  note: string,
  onComplete: (text: string) => void
}
export const NoteForm: FC<NoteFormProps> = ({ note, onComplete }) => {
  const classes = useStyles()
  const { values, handleInputChange } = useLisaForm<FormValues, FormValues>({ text: note })
  const dispatch = useDispatch()

  const submitNote = () => {
    onComplete(values.text.trim()) // @todo Trim OK?
    dispatch(closeModal())
  }

  return (
    <LisaForm>
      <Grid container spacing={1} className={classes.wrapper}>
        <Grid item xs={12}>
          <TextArea
            label="Note text"
            name="text"
            minRows={10}
            value={values.text}
            onChange={handleInputChange}
            className={classes.textArea}
          />
        </Grid>
      </Grid>
      <div className={classes.actionButtons}>
        <Button
          onClick={submitNote}
          variant="contained"
          color="primary"
          size="small">
          Save
        </Button>
        <Button
          onClick={() => dispatch(closeModal())}
          variant="contained"
          color="primary"
          size="small"
        >
          Cancel
        </Button>
      </div>
    </LisaForm>
  )
}
