import {
  Label,
  LisaDocumentWithLabels,
  DeletedDocumentsSortColumn,
  DeletedParams, User, DeletedDocument
} from 'types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import { nextSortDirection } from 'utils/filters'
import { toggleDocumentsOnPage2 } from 'utils/documents'

export const SortColumnToConfigMap: Map<DeletedDocumentsSortColumn, keyof User['settings']['recycleBinSettings']> = new Map([
  ['Name', 'recycleBinFilename'],
  ['DocType', 'recycleBinDocumentType'],
  ['ProcessingStatus', 'recycleBinProcessingStatus'],
  ['BusinessStatus', 'recycleBinDocumentStatus'],
  ['ImportFolder', 'recycleBinOrigin'],
  ['DeletedAt', 'recycleBinDeletedAt'],
  ['DeletedBy', 'recycleBinDeletedBy']
])

export interface DeletedDocumentsSlice {
  searchParams: DeletedParams
  labels: Label[]
  selectedLabel: Label | null
  selectedDocuments: LisaDocumentWithLabels[]
  showFilters: boolean,
  searchTerm: string // @todo Messed up components. To avoid double search on search term we will use this property for current search term
}

export const initialState: DeletedDocumentsSlice = {
  searchParams: {
    businessStatus: 5,
    hasFilter: false,
    hasQueries: '',
    noLabel: true,
    label: '',
    labelId: '',
    documentTypes: [],
    matchCase: false,
    operatorAnd: true,
    operatorOr: false,
    searchText: '',
    transitionId: '',
    page: 1,
    size: 25,
    sortOrder: 'asc',
    sortColumn: 'Name'
  },
  labels: [],
  selectedLabel: null,
  selectedDocuments: [],
  showFilters: true,
  searchTerm: ''
}

const deletedDocumentsSlice = createSlice({
  name: 'deletedDocuments',
  initialState,
  reducers: {
    updateSearchParams: (state, { payload }: PayloadAction<Partial<DeletedParams>>) => {
      state.searchParams = {
        ...state.searchParams,
        sortOrder: initialState.searchParams.sortOrder,
        sortColumn: initialState.searchParams.sortColumn,
        ...payload,
        page: 1
      }
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.searchParams.page = payload
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.searchParams.size = payload
    },
    resetSearchParams: (state) => {
      state.searchParams = { ...initialState.searchParams }
      state.searchTerm = ''
    },
    toggleAllDocuments: (state, { payload }: PayloadAction<DeletedDocument[]>) => {
      state.selectedDocuments = toggleDocumentsOnPage2(payload, state.selectedDocuments)
    },
    toggleDocument: (state, { payload }: PayloadAction<LisaDocumentWithLabels>) => {
      if (state.selectedDocuments.some(_ => _.documentId === payload.documentId)) {
        state.selectedDocuments = state.selectedDocuments.filter(_ => _.documentId !== payload.documentId)
      } else {
        state.selectedDocuments = [...state.selectedDocuments, payload]
      }
    },
    sortBy: (state, { payload }: PayloadAction<DeletedDocumentsSortColumn>) => {
      const { sortColumn, sortOrder } = state.searchParams
      state.searchParams.sortOrder = nextSortDirection(sortColumn === payload ? (sortOrder ?? false) : false)
      state.searchParams.sortColumn = payload
      state.searchParams.page = 1
    },
    clearSelectedDocuments: (state) => {
      state.selectedDocuments = []
    },
    resetAll: (state) => {
      const labels = state.labels
      state = { ...initialState }
      state.labels = labels
      state.searchTerm = ''
    },
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload!
    },
    updateSelectedDocuments: (state, { payload }: PayloadAction<DeletedDocument[]>) => {
      state.selectedDocuments = state.selectedDocuments.map((d) => payload.find(_ => _.documentId === d.documentId) ?? d)
    }
  }
})

export const {
  updateSearchParams,
  setPage,
  setPageSize,
  resetSearchParams,
  toggleAllDocuments,
  toggleDocument,
  sortBy,
  clearSelectedDocuments,
  resetAll,
  setSearchTerm,
  updateSelectedDocuments
} = deletedDocumentsSlice.actions

export const useDeletedDocuments = (state: RootState) => state.deletedDocuments

export default deletedDocumentsSlice.reducer
