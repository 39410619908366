import { useDispatch } from 'react-redux'
import { makeStyles, Typography } from '@material-ui/core'
import * as Icons from 'common/Icons/SvgIcons'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { MaybeForbiddenAction } from 'common/MaybeForbiddenAction/MaybeForbiddenAction'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { QueryForm } from 'components/Query/QueryForm'
import { openModal } from 'redux/slices/appSlice'
import { useDocumentId } from 'context/DocumentContext'
import { useGetQueriesByDocumentQuery } from 'services/api/queryApi'
import { QueryItem } from 'pages/Document/components/QueryItem'
import { useGetDocumentMetaDataQuery } from 'services/api/documentApi'
import { BusinessStatus } from 'types'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 96px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '16px',
    padding: '0px',
    '& .MuiFab-primary': {
      background: theme.palette.black.main,
      '&:hover': {
        background: theme.palette.lime.main
      }
    }
  },
  tabTitle: {
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '4px',
    lineHeight: '24px',
    height: '30px'
  },
  queryList: {
    width: '100%',
    overflowY: 'auto'
  },
  addQueryIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    '& .MuiSvgIcon-root': {
      cursor: 'pointer',
      // marginTop:'2px',
      width: '20px',
      height: '20px',
      fill: theme.palette.black.main
    },
    '&:hover': {
      background: theme.palette.lime.main
    }
  },
  addQueryIconDisabled: {
    '& .MuiSvgIcon-root': {
      cursor: 'pointer',
      // marginTop:'2px',
      width: '20px',
      height: '20px',
      fill: theme.palette.grey[500]
    }
  }
}))

const Queries = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const documentId = useDocumentId()
  const { data: queries = [] } = useGetQueriesByDocumentQuery(documentId)
  const { data: documentMetaData } = useGetDocumentMetaDataQuery(documentId)
  const status = documentMetaData?.businessStatus === BusinessStatus.Irrelevant ||
                documentMetaData?.businessStatus === BusinessStatus.Deleted
  const { hasAccess } = useLisaAuth()
  const canCreateQuery = hasAccess('perm_act_query_create') && !status

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.tabTitle} variant="h6">
          Queries
        </Typography>
        <MaybeForbiddenAction tooltip={'Add New Query'} isForbidden={!canCreateQuery}>
          <div
            className={canCreateQuery ? classes.addQueryIcon : classes.addQueryIconDisabled}
            onClick={() => canCreateQuery && dispatch(openModal('CREATE_QUERY'))}>
            <Icons.AddIco />
          </div>
        </MaybeForbiddenAction>
      </div>
      <div className={classes.queryList}>
        {
          queries.map((query) => (
            <QueryItem
              key={query.queryId}
              activeClass={''}
              query={query}/>
          ))
        }
      </div>
      <LisaModal
        title={'Add New Query'}
        scroll={'body'}
        modalType={'CREATE_QUERY'}>
        <QueryForm
          onComplete={() => null}
          showLabelSelector={false}
          documentId={documentId}/>
      </LisaModal>
    </div>
  )
}

export default Queries
