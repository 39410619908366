import { combineReducers } from 'redux'
import documentLabels from 'redux/slices/documentLabelsSlice'
import queryLabels from 'redux/slices/queryLabelsSlice'
import bulkAssignLabels from 'redux/slices/bulkAssignLabelsSlice'
import a2kLabels from 'redux/slices/a2kLabelsSlice'
import mxHistoryLabels from 'redux/slices/mxHistoryLabelsSlice'
import clientLabels from 'redux/slices/clientLabelsSlice'
import transition from 'redux/slices/transitionSlice'
import queries from 'redux/slices/queriesSlice'
import ocrStatus from 'redux/slices/ocrStatusSlice'
import reassignLabels from 'redux/slices/reassignLabelsSlice'
import importDocuments from 'redux/slices/importDocumentsSlice'
import a2kDocuments from 'redux/slices/a2kDocumentsSlice'
import a2kRuleSlice from 'redux/slices/a2kRulesSlice'
import mxHistoryDocuments from 'redux/slices/mxHistoryDocumentsSlice'
import searchDocuments from 'redux/slices/searchDocumentsSlice'
import clientDocuments from 'redux/slices/clientDocumentsSlice'
import document from 'redux/slices/documentSlice'
import app from 'redux/slices/appSlice'
import users from 'redux/slices/usersSlice'
import labels from 'redux/slices/labelsSlice'
import dashboard from 'redux/slices/dashboardSlice'
import projects from 'redux/slices/projectsSlice'
import deletedDocuments from 'redux/slices/deletedDocumentsSlice'
import { lisaApi } from 'services/api/lisaApi'

const rootReducer = combineReducers({
  users,
  documentLabels,
  queryLabels,
  bulkAssignLabels,
  a2kLabels,
  mxHistoryLabels,
  transition,
  queries,
  ocrStatus,
  reassignLabels,
  importDocuments,
  a2kDocuments,
  mxHistoryDocuments,
  searchDocuments,
  clientDocuments,
  document,
  app,
  clientLabels,
  labels,
  dashboard,
  projects,
  a2kRuleSlice,
  deletedDocuments,
  [lisaApi.reducerPath]: lisaApi.reducer
})

export default rootReducer
