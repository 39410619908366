import { ChangeEvent, useEffect, useState, FC, MouseEvent } from 'react'
import { makeStyles, Grid, Typography, Button } from '@material-ui/core'
import ComboBox from 'common/LisaControls/ComboBox'
import { Nullable, Transition } from 'types'
import { FinalUsers, TransitionUsersList, UsersListOption } from 'pages/Administration/components/projects/TransitionForm'
import { useDispatch } from 'react-redux'
import { useGetUsersQuery } from 'services/api/usersApi'
import { useGetTransitionTeamQuery, useUpdateTransitionTeamMutation } from 'services/api/transitionApi'
import { openToast } from 'redux/slices/appSlice'
import Prompt from 'common/Dialogs/Prompt'

const useStyles = makeStyles((theme) => ({
  tabs: {
    backgroundColor: theme.palette.common.white,
    marginBottom: 16,
    color: theme.palette.blue.main,
    '& .MuiTab-textColorInherit': {
      opacity: 1
    }
  },
  tabsIndicator: {
    backgroundColor: `${theme.palette.blue.main} !important`,
    height: '5px !important'
  },
  tabPanel: {
    flexGrow: 1,
    display: 'flex'
  },
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  gridForm: {
    maxWidth: '600px',
    alignSelf: 'center'
  },
  userName: {
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: 600
  },
  actionButtons: {
    paddingTop: '32px',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  },
  comboBox: {
    display: 'flex',
    width: '100%'
  }
}))

type UserListProps = {
  transition: Transition | undefined
}

export const TransitionTeam: FC<UserListProps> = ({ transition }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data: allUsers = [] } = useGetUsersQuery()
  const { data: team = [] } = useGetTransitionTeamQuery(transition?.transitionId!, { refetchOnMountOrArgChange: true })
  const [newUserList, setNewUserList] = useState<string[]>([])
  const [updateTransitionTeam] = useUpdateTransitionTeamMutation()
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedUser, setSelectedUser] = useState<UsersListOption>({ value: '', name: 'Select', email: '' })
  const [valuesDifference, setValuesDifference] = useState(false)

  useEffect(() => {
    team && setNewUserList(team.map(_ => _.userId))
  }, [team, transition])

  const finalUserList: FinalUsers[] = allUsers.map(_ => ({
    value: _.userId,
    name: `${_.firstName} ${_.lastName}`,
    email: _.email
  }))
  const emptyUserList = { value: '', name: 'Select', email: '' }
  const dropdownUserList: UsersListOption[] = [emptyUserList, ...finalUserList]

  const addUserToList = (item: string) => {
    if (!newUserList.includes(item)) {
      setNewUserList([...newUserList, item])
    }
  }

  const remove = (removeItem: string) => {
    setNewUserList(newUserList.filter((item) => item !== removeItem))
  }

  const handleUpdateTeam = async (e: MouseEvent) => {
    e.preventDefault()
    const { isError, message } = await updateTransitionTeam({
      transitionId: transition?.transitionId,
      users: newUserList
    }).unwrap()
    if (!isError) {
      dispatch(openToast({
        severity: 'success',
        message: 'You have successfully updated a transition team.'
      }))
    }
    if (isError && message !== null) {
      dispatch(openToast({ severity: 'error', message: message }))
    }
  }

  useEffect(() => {
    const initialTeam = team.map(_ => _.userId)
    setValuesDifference(JSON.stringify(initialTeam) !== JSON.stringify(newUserList))
  }, [newUserList])

  return (
    <>
      <Grid container
        alignItems='flex-start'
        direction='column'
        alignContent='center'
        style={{ gap: '10px' }}>
        <Grid container className={classes.gridForm}>
          <div className={classes.comboBox}>
            <ComboBox
              label={'Select user'}
              options={dropdownUserList}
              getOptionLabel={(mtu: UsersListOption) => mtu.name ?? ''}
              onInputChange={(e, newInputValue: string) => {
                setInputValue(newInputValue)
              }}
              onChange={(e: ChangeEvent<{}>, newValue: Nullable<UsersListOption>) => {
                newValue && setSelectedUser(newValue)
              }}
              value={selectedUser}
              renderOption={(option: UsersListOption) => (
                <>
                  <div>
                    <div className={classes.userName}>{option.name}</div>
                    <Typography variant='caption'>{option.email}</Typography>
                  </div>
                </>
              )}
              getOptionSelected={(opt, { value }) => opt.value === value}/>
            <Button
              variant='contained'
              color='primary'
              size='medium'
              style={{ margin: '18px 0 0 8px' }}
              disabled={(selectedUser && selectedUser.value === '') || inputValue.length === 0}
              onClick={() => addUserToList(selectedUser.value!)}
            >
              ADD
            </Button>
          </div>
        </Grid>
        <Grid container className={classes.gridForm}>
          <Grid item xs={12}>
            {newUserList &&
              newUserList.map((user, i) => (
                <TransitionUsersList
                  key={i}
                  user={user}
                  remove={(item: string) => remove(item)}
                />
              ))}
          </Grid>
        </Grid>
        <Grid container className={classes.gridForm}>
          <Grid item xs={11} md={7} xl={5}>
            <div className={classes.actionButtons}>
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={handleUpdateTeam}
              >
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Prompt when={valuesDifference} pageName={'user list'} />
    </>
  )
}
