import { ChangeEvent, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useLisaAuth } from 'hooks/useLisaAuth'

const LisaAdministrationTabs = () => {
  const history = useHistory()
  const { hasAccess } = useLisaAuth()

  const changeRoute = (e: ChangeEvent<{}>, route: string) => {
    history.push(route)
  }

  const path = useMemo(() => history.location.pathname, [history.location.pathname])
  return (
    <Tabs
      value={path}
      onChange={changeRoute}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      {hasAccess('perm_administration_users') && <Tab label="users" value="/administration"/>}
      {hasAccess('perm_administration_assets') &&
        <Tab label="entities / assets" value="/administration/projects-transitions"/>}
      {hasAccess('perm_administration_subscriber') &&
        <Tab label="subscribers" value='/administration/subscribers'/>}
      {hasAccess('perm_administration_notifications') &&
        <Tab label="notifications" value="/administration/notifications"/>}
    </Tabs>
  )
}

export default LisaAdministrationTabs
