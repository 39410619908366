import { ReactElement } from 'react'
import * as Icons from 'common/Icons/SvgIcons'
import { makeStyles } from '@material-ui/core'
import { QueryStatus, QueryVisibility } from 'types'

const useStyles = makeStyles((theme) => ({
  criticalColor: {
    fill: theme.palette.statusColors.red
  }
}))

type QueryStatusIconProps = {
  visibility: QueryVisibility
  critical: boolean
  queryStatus: QueryStatus
}
export const QueryStatusIcon = <P extends QueryStatusIconProps>({ query }: {query: P}): ReactElement => {
  const classes = useStyles()
  return (
    <div>
      {query.visibility === QueryVisibility.External && <Icons.ExternalQuery />}
      {query.critical && <Icons.CriticalQuery className={classes.criticalColor} />}
      {query.queryStatus === QueryStatus.Sideletter && <Icons.SideletterQuery />}
    </div>
  )
}
