import { FC } from 'react'
import { DocumentIcon } from 'common/DocumentIcon/DocumentIcon'
import { Grid, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  documentNameRoot: {
    columnGap: 5
  },
  documentIcon: {
    fontSize: 24,
    textAlign: 'center'
  }
}))

type DocumentNameProps = {
  name: string
  extension: string
}
export const DocumentName: FC<DocumentNameProps> = ({ name, extension }) => {
  const classes = useStyles()
  return (
    <Grid container alignItems={'flex-start'} className={classes.documentNameRoot} wrap={'nowrap'}>
      <Grid item>
        <DocumentIcon extension={extension} className={classes.documentIcon} />
      </Grid>
      <Grid item>
        <Typography variant='h6'>{name}</Typography>
      </Grid>
    </Grid>
  )
}
