import { FC, useMemo } from 'react'
import { BusinessStatus } from 'types'
import Dropdown, { DropdownProps } from 'common/LisaControls/Dropdown'

export type BusinessStatusOption = {
  value: BusinessStatus
  text: string
  textSuffix?: string
}
export const businessStatusOptions: BusinessStatusOption[] = [
  {
    value: BusinessStatus.Open,
    text: 'Open'
  },
  {
    value: BusinessStatus.Clarification,
    text: 'Clarification'
  },
  {
    value: BusinessStatus.Irrelevant,
    text: 'Irrelevant'
  },
  {
    value: BusinessStatus.Reviewed,
    text: 'Reviewed'
  },
  {
    value: BusinessStatus.Deleted,
    text: 'Deleted'
  },
  {
    value: BusinessStatus.Accepted,
    text: 'Accepted'
  }
]

type QueryStatusSelectProps = DropdownProps & {
  options?: BusinessStatus[]
  excludeOptions?: BusinessStatus[]
  statusCounts?: Map<BusinessStatus, number>
}

export const DocumentStatusSelect: FC<QueryStatusSelectProps> = ({
  excludeOptions = [],
  options = [],
  statusCounts,
  ...dropDownProps
}) => {
  const items = useMemo<BusinessStatusOption[]>(() => {
    return businessStatusOptions
      .filter((bs) => !options?.length || options.includes(bs.value))
      .filter((bs) => !excludeOptions?.includes(bs.value))
      .map((bs) => {
        if (!statusCounts) {
          return bs
        }
        return {
          ...bs,
          textSuffix: ` (${statusCounts.get(bs.value) ?? 0})`
        }
      })
  }, [excludeOptions, options, statusCounts])
  return (
    <Dropdown
      items={items}
      showSelectOption={false}
      {...dropDownProps}
    />
  )
}
