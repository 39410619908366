import {
  Label,
  LabelResponse,
  LisaDocumentWithLabels,
  SearchDocument,
  SearchDocumentsSortColumn,
  SearchParams, User
} from 'types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import { createHierarchy, toList } from 'utils/labels'
import { nextSortDirection } from 'utils/filters'
import { toggleDocumentsOnPage2 } from 'utils/documents'

export const SortColumnToConfigMap: Map<SearchDocumentsSortColumn, keyof User['settings']['searchSettings']> = new Map([
  ['Name', 'globalSearchListFilename'],
  ['DocType', 'globalSearchListDocumentType'],
  ['ProcessingStatus', 'globalSearchListProcessingStatus'],
  ['ImportDate', 'globalSearchListImportDate'],
  ['ImportedBy', 'globalSearchListImportedBy'],
  ['Query', 'globalSearchListQueries'],
  ['BusinessStatus', 'globalSearchListDocumentStatus'],
  ['ImportFolder', 'globalSearchListOrigin']
])

export interface SearchDocumentsSlice {
  searchParams: SearchParams
  labels: Label[]
  selectedLabel: Label | null
  selectedDocuments: LisaDocumentWithLabels[]
  showFilters: boolean,
  searchTerm: string // @todo Messed up components. To avoid double search on search term we will use this property for current search term
}

export const initialState: SearchDocumentsSlice = {
  searchParams: {
    businessStatus: '',
    hasFilter: false,
    hasQueries: '',
    noLabel: true,
    label: '',
    labelId: '',
    documentTypes: [],
    matchCase: false,
    operatorAnd: true,
    operatorOr: false,
    searchText: '',
    transitionId: '',
    page: 1,
    size: 25,
    sortOrder: 'asc',
    sortColumn: 'Name'
  },
  labels: [],
  selectedLabel: null,
  selectedDocuments: [],
  showFilters: true,
  searchTerm: ''
}

const searchDocumentsSlice = createSlice({
  name: 'searchDocuments',
  initialState,
  reducers: {
    updateSearchParams: (state, { payload }: PayloadAction<Partial<SearchParams>>) => {
      state.searchParams = {
        ...state.searchParams,
        sortOrder: initialState.searchParams.sortOrder,
        sortColumn: initialState.searchParams.sortColumn,
        ...payload,
        page: 1
      }
    },
    setLabels: (state, { payload }: PayloadAction<LabelResponse>) => {
      if (payload === null) {
        state.labels = initialState.labels
      } else {
        state.labels = [...toList(createHierarchy(payload), (label) => label && label.level! > 2)]
          .sort((l1, l2) => l1.name.localeCompare(l2.name))
      }
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.searchParams.page = payload
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.searchParams.size = payload
    },
    resetSearchParams: (state) => {
      state.searchParams = { ...initialState.searchParams }
      state.searchTerm = ''
    },
    toggleAllDocuments: (state, { payload }: PayloadAction<SearchDocument[]>) => {
      state.selectedDocuments = toggleDocumentsOnPage2(payload, state.selectedDocuments)
    },
    toggleDocument: (state, { payload }: PayloadAction<LisaDocumentWithLabels>) => {
      if (state.selectedDocuments.some(_ => _.documentId === payload.documentId)) {
        state.selectedDocuments = state.selectedDocuments.filter(_ => _.documentId !== payload.documentId)
      } else {
        state.selectedDocuments = [...state.selectedDocuments, payload]
      }
    },
    sortBy: (state, { payload }: PayloadAction<SearchDocumentsSortColumn>) => {
      const { sortColumn, sortOrder } = state.searchParams
      state.searchParams.sortOrder = nextSortDirection(sortColumn === payload ? (sortOrder ?? false) : false)
      state.searchParams.sortColumn = payload
      state.searchParams.page = 1
    },
    selectLabel: (state, { payload }: PayloadAction<Label | null>) => {
      state.selectedLabel = payload
    },
    clearSelectedDocuments: (state) => {
      state.selectedDocuments = []
    },
    resetAll: (state) => {
      const labels = state.labels
      state = { ...initialState }
      state.labels = labels
      state.searchTerm = ''
    },
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload!
    },
    updateSelectedDocuments: (state, { payload }: PayloadAction<SearchDocument[]>) => {
      state.selectedDocuments = state.selectedDocuments.map((d) => payload.find(_ => _.documentId === d.documentId) ?? d)
    }
  }
})

export const {
  updateSearchParams,
  setLabels,
  setPage,
  setPageSize,
  resetSearchParams,
  toggleAllDocuments,
  toggleDocument,
  sortBy,
  selectLabel,
  clearSelectedDocuments,
  resetAll,
  setSearchTerm,
  updateSelectedDocuments
} = searchDocumentsSlice.actions

export const useSearchDocuments = (state: RootState) => state.searchDocuments

export default searchDocumentsSlice.reducer
