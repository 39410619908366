import { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { resetSearchParams, updateSearchParams, useSearchDocuments } from 'redux/slices/searchDocumentsSlice'
import { Button, Grid, makeStyles, Theme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { BusinessStatus, DropDownOnChangeEvent, LabelId } from 'types'
import { DocumentTypeSelect } from './DocumentTypeSelect'
import Dropdown from 'common/LisaControls/Dropdown'
import ComboBox from 'common/LisaControls/ComboBox'
import { useLisaAuth } from 'hooks/useLisaAuth'

const useStyles = makeStyles<Theme, { compact: boolean }>((theme) => ({
  resetFilters: {
    marginTop: props => props.compact ? '4px' : '18px',
    width: '100% !important',
    minWidth: 'auto !important',
    lineHeight: '12px !important',
    padding: '13px 10px !important',
    height: '42px !important',
    letterSpacing: '.15px !important',
    background: 'transparent !important',
    color: `${theme.palette.blue.main} !important`,
    '&:hover': {
      background: `${theme.palette.lime.main} !important`,
      color: theme.palette.black.main
    }
  }
}))

type LabelOption = { value: LabelId | '', name: string }

export const SearchFilters: FC<{ compact?: boolean, setSearchText: (val: string) => void }> = ({ compact = false, setSearchText }) => {
  const classes = useStyles({ compact })
  const dispatch = useDispatch()
  const { hasAccess } = useLisaAuth()
  const canSeeAllStatuses = hasAccess('perm_view_document_internal')
  const canSeeExternalStatuses = hasAccess('perm_view_document_external')
  const { searchParams: { label, businessStatus, hasQueries }, labels: allLabels } = useSelector(useSearchDocuments)
  const [searchLabelString, setSearchLabelString] = useState('')
  const [documentTypes, setDocumentTypes] = useState<string[]>([])
  const emptyLabelOption: LabelOption = { value: 0, name: 'No Label' }
  const labelOptions: LabelOption[] = useMemo(() => [
    { value: '', name: '' }, emptyLabelOption, ...allLabels.map(_ => ({ name: _.name, value: _.labelId }))
  ], [])

  useEffect(() => {
    dispatch(updateSearchParams({ documentTypes }))
  }, [documentTypes])

  const handleInputChange = (event: DropDownOnChangeEvent) => {
    const { name, value } = event.target
    if (name) {
      dispatch(updateSearchParams({ [name]: value }))
    }
  }

  const selectedLabelOption: LabelOption = useMemo(() => {
    return labelOptions.find(_ => _.name === label) ?? emptyLabelOption
  }, [label])

  type BusinessStatusOption = {
    value: BusinessStatus | string
    text: string
  }

  const allStatusNames: BusinessStatusOption[] = [{
    value: '',
    text: 'Any state'
  },
  {
    value: BusinessStatus.Open,
    text: 'Open'
  },
  {
    value: BusinessStatus.Clarification,
    text: 'Clarification'
  },
  {
    value: BusinessStatus.Irrelevant,
    text: 'Irrelevant'
  },
  {
    value: BusinessStatus.Reviewed,
    text: 'Reviewed'
  },
  {
    value: BusinessStatus.Accepted,
    text: 'Accepted'
  }]

  const externalBussinesItems: BusinessStatusOption[] = [{
    value: '',
    text: 'Any state'
  },
  {
    value: BusinessStatus.Clarification,
    text: 'Clarification'
  },
  {
    value: BusinessStatus.Reviewed,
    text: 'Reviewed'
  },
  {
    value: BusinessStatus.Accepted,
    text: 'Accepted'
  }]

  const businessNamesItems = canSeeAllStatuses
    ? allStatusNames
    : (
      canSeeExternalStatuses ? externalBussinesItems : []
    )

  return (
    <Grid container spacing={compact ? 1 : 2}>
      <Grid container item xs={compact ? 12 : 11} spacing={compact ? 1 : 2}>
        <Grid item xs={compact ? 6 : 3}>
          <Dropdown
            label="Document state"
            name="businessStatus"
            items={businessNamesItems}
            showSelectOption={false}
            value={businessStatus}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={compact ? 6 : 3}>
          <DocumentTypeSelect
            label={'Document Type'}
            name={'documentTypes'}
            multiple
            onChange={(e) => setDocumentTypes(e.target.value as string[])}
            value={documentTypes}
          />
        </Grid>
        <Grid item xs={compact ? 6 : 3}>
          <ComboBox
            label={'Label'}
            options={labelOptions}
            value={selectedLabelOption}
            required={false}
            onChange={(e, option: LabelOption | null) => {
              if (option !== null) {
                dispatch(updateSearchParams({ label: option.name, labelId: option.value.toString() }))
                setSearchLabelString(option.name)
              } else {
                dispatch(updateSearchParams({ label: '', labelId: '' }))
                setSearchLabelString('')
              }
            }}
            inputValue={searchLabelString}
            onInputChange={(e, value) => {
              setSearchLabelString(value)
            }}
            getOptionLabel={(option) =>
              option ? option.name : ''
            }
            renderOption={(option) => (
              <Fragment>
                <span>{option.name}</span>
              </Fragment>
            )}
          />
        </Grid>
        <Grid item xs={compact ? 6 : 3}>
          <Dropdown
            label="Has queries"
            name="hasQueries"
            showSelectOption={false}
            items={[
              {
                value: '',
                text: 'Any state'
              },
              {
                value: 1,
                text: 'Has queries'
              },
              {
                value: 2,
                text: 'No queries'
              }
            ]}
            value={hasQueries}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
      <Grid item xs={compact ? 12 : 1}>
        <Button
          style={{ fontWeight: 600 }}
          className={classes.resetFilters}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            dispatch(resetSearchParams())
            setSearchText('')
            setDocumentTypes([])
          }}>
          reset
        </Button>
      </Grid>
    </Grid>
  )
}
