import { FC, SyntheticEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { closeToast } from 'redux/actions/toast'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import { closeToast, useApp } from 'redux/slices/appSlice'

const Alert: FC<AlertProps> = (props) => {
  return <MuiAlert elevation={10} variant='filled' {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiAlert-root': {
      marginTop: '60px',
      display: 'flex',
      alignItems: 'center',
      padding: '14px 24px 14px 14px',
      borderRadius: '6px'
    },
    '& > * + *': {
      marginTop: theme.spacing(2)
    },
    '& .MuiAlert-message': {
      fontSize: '1.05rem',
      fontWeight: '600',
      padding: '0'
    },
    '& .MuiAlert-icon': {
      fontSize: '30px',
      lineHeight: '30px',
      opacity: '.35'
    },
    '& .MuiAlert-filledInfo': {
      backgroundColor: 'rgba(0,157,229,.9)'
    },
    '& .MuiAlert-filledError': {
      backgroundColor: 'rgba(226,0,0,.9)'
    },
    '& .MuiAlert-filledSuccess': {
      backgroundColor: 'rgba(0,193,40,.9)'
    }
  }
}))

export default function AlertMessage () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)

  // severity options [error, warning, info, success]
  const { alertToast: { open, severity, message } } = useSelector(useApp)

  useEffect(() => {
    setShow(open)
  }, [open])

  const handleClose = (event?: SyntheticEvent<Element, Event>, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(closeToast())
  }

  return (
    <div className={classes.root}>
      <Snackbar open={show} autoHideDuration={6500} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <div>
          <Alert onClose={handleClose} severity={severity}>
            {message}
          </Alert>
        </div>
      </Snackbar>
    </div>
  )
}
