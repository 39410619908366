import { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.statusColors.green
  },
  inactive: {
    color: theme.palette.statusColors.red
  }
}))

export interface DotIndicatorProps {
  active: boolean
}
export const DotIndicator: FC<DotIndicatorProps> = ({ active }) => {
  const classes = useStyles()
  return (<i className={clsx('fas fa-circle', {
    [classes.active]: active,
    [classes.inactive]: !active
  })}/>)
}
