import { SortDirection } from 'types'

export const nextSortDirection = (sortDirection: SortDirection): SortDirection => {
  switch (sortDirection) {
  case 'asc':
    return 'desc'
  case 'desc':
  case false:
  default:
    return 'asc'
  }
}
