import { ImportDocument, User } from 'types'

export type ColumnConfiguratorKey = keyof User['settings']['importSettings'] |
  keyof User['settings']['a2KSettings'] |
  keyof User['settings']['clientSettings'] |
  keyof User['settings']['searchSettings'] |
  keyof User['settings']['recycleBinSettings']

export type ColumnConfiguratorType = 'import' | 'a2k' | 'client' | 'globalSearch' | 'recycleBin'

export const columnConfiguratorLabels: Map<ColumnConfiguratorKey, string> = new Map([
  ['importListDocumentType', 'Doc Type'],
  ['a2KListDocumentType', 'Doc Type'],
  ['clientListDocumentType', 'Doc Type'],
  ['globalSearchListDocumentType', 'Doc Type'],
  ['recycleBinDocumentType', 'Doc Type'],

  ['importListFilename', 'File Name'],
  ['a2KListFilename', 'File Name'],
  ['clientListFilename', 'File Name'],
  ['globalSearchListFilename', 'File Name'],
  ['recycleBinFilename', 'File Name'],

  ['importListA2KLabel', 'Labels'],
  ['a2KListA2KLabels', 'Labels'],
  ['globalSearchListA2KLabels', 'Labels'],
  ['recycleBinA2KLabels', 'Labels'],

  ['importListImportDate', 'Import Date'],
  ['globalSearchListImportDate', 'Import Date'],

  ['importListImportedBy', 'Imported By'],
  ['globalSearchListImportedBy', 'Imported By'],

  ['importListProcessingPriority', 'Priority'],

  ['importListProcessingStatus', 'Process'],
  ['a2KListProcessingStatus', 'Process'],
  ['globalSearchListProcessingStatus', 'Process'],
  ['recycleBinProcessingStatus', 'Process'],

  ['a2KListQueries', 'Queries'],
  ['clientListQueries', 'Queries'],
  ['globalSearchListQueries', 'Queries'],

  ['a2KListDocumentStatus', 'Document Status'],
  ['clientListDocumentStatus', 'Document Status'],
  ['globalSearchListDocumentStatus', 'Document Status'],
  ['recycleBinDocumentStatus', 'Document Status'],

  ['a2KListOrigin', 'Origin'],
  ['globalSearchListOrigin', 'Origin'],
  ['recycleBinOrigin', 'Origin'],

  ['recycleBinDeletedAt', 'Deleted At'],

  ['recycleBinDeletedBy', 'Deleted By']
])

export const mandatoryColumns: Map<ColumnConfiguratorType, ColumnConfiguratorKey[]> = new Map([
  ['import', ['importListFilename', 'importListProcessingStatus']],
  ['a2k', ['a2KListFilename', 'a2KListQueries', 'a2KListDocumentStatus']],
  ['client', ['clientListFilename', 'clientListQueries', 'clientListDocumentStatus']],
  ['globalSearch', ['globalSearchListFilename']],
  ['recycleBin', ['recycleBinFilename']]
])

export const importColumns: ColumnConfiguratorKey[] = [
  'importListDocumentType',
  'importListFilename',
  'importListA2KLabel',
  'importListProcessingPriority',
  'importListProcessingStatus',
  'importListImportedBy',
  'importListImportDate'
]

export const a2kColumns: ColumnConfiguratorKey[] = [
  'a2KListDocumentType',
  'a2KListFilename',
  'a2KListA2KLabels',
  'a2KListProcessingStatus',
  'a2KListQueries',
  'a2KListDocumentStatus',
  'a2KListOrigin'
]

export const clientColumns: ColumnConfiguratorKey[] = [
  'clientListDocumentType',
  'clientListFilename',
  'clientListQueries',
  'clientListDocumentStatus'
]

export const globalSearchColumns: ColumnConfiguratorKey[] = [
  'globalSearchListDocumentType',
  'globalSearchListFilename',
  'globalSearchListA2KLabels',
  'globalSearchListOrigin',
  'globalSearchListImportDate',
  'globalSearchListImportedBy',
  'globalSearchListProcessingStatus',
  'globalSearchListQueries',
  'globalSearchListDocumentStatus'
]

export const deletedColumns: ColumnConfiguratorKey[] = [
  'recycleBinDocumentType',
  'recycleBinFilename',
  'recycleBinA2KLabels',
  'recycleBinOrigin',
  'recycleBinProcessingStatus',
  'recycleBinDocumentStatus',
  'recycleBinDeletedAt',
  'recycleBinDeletedBy'
]

export const getCheckedColumnsFromCheckedColumnsSettings = (checkedColumnObject: {[key: string]: boolean}): ColumnConfiguratorKey[] => {
  return Object.keys(checkedColumnObject).filter((ccKey) => checkedColumnObject[ccKey])
    .map((ccKey) => ccKey as ColumnConfiguratorKey)
}

export const importDocumentValueMap: Map<keyof User['settings']['importSettings'], keyof ImportDocument> = new Map([
  ['importListDocumentType', 'docTypeRecognitionResult'],
  ['importListFilename', 'docTypeRecognitionResult'],
  ['importListA2KLabel', 'docTypeRecognitionResult'],
  ['importListProcessingPriority', 'docTypeRecognitionResult'],
  ['importListProcessingStatus', 'docTypeRecognitionResult'],
  ['importListImportedBy', 'docTypeRecognitionResult'],
  ['importListImportDate', 'docTypeRecognitionResult']
])
