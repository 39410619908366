import { FC, useState } from 'react'
import { BusinessStatus, DocumentId, Label } from 'types'
import { useDispatch } from 'react-redux'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { DocumentStatusSelect } from 'common/Select/DocumentStatusSelect'
import { AsyncThunk } from '@reduxjs/toolkit'
import { closeModal } from 'redux/slices/appSlice'
import { BulkStatusUpdateParams } from 'services/api/documentApi'
import { useLisaAuth } from 'hooks/useLisaAuth'

const useStyles = makeStyles(() => ({
  root: {
    paddingBlock: 24
  },
  note: {
    fontSize: 16
  },
  actionsContainer: {
    marginTop: 8
  }
}))

type DocumentStatusChangeFormProps = {
  label: Label | null
  documentIds: DocumentId[]
  bulkStatusDispatcher?: AsyncThunk<unknown, BulkStatusUpdateParams, {}> // @todo Not sure why I did this
  bulkStatusUpdate?: (params: BulkStatusUpdateParams) => void
}
export const DocumentStatusChangeForm: FC<DocumentStatusChangeFormProps> = ({
  documentIds,
  label,
  bulkStatusDispatcher,
  bulkStatusUpdate
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { hasAccess } = useLisaAuth()
  const canActMetadata = hasAccess('perm_act_documentmetadata')
  const allowStatusSelection = documentIds.length === 0
  const distinctSelectedStatuses = [
    BusinessStatus.Open, BusinessStatus.Deleted, BusinessStatus.Reviewed, BusinessStatus.Irrelevant
  ]

  const [statusesToChange, setStatusesToChange] = useState<BusinessStatus[]>(distinctSelectedStatuses)
  const [newStatus, setNewStatus] = useState<BusinessStatus>(
    canActMetadata ? BusinessStatus.Reviewed : BusinessStatus.Irrelevant)

  const close = () => dispatch(closeModal(['DOCUMENT_STATUS_CHANGE', 'DOCUMENT_STATUS_CHANGE_SEARCH_FORM']))

  const handleSubmit = () => {
    const params: BulkStatusUpdateParams = {
      labelId: label!.labelId,
      newStatus,
      documentIds,
      statusesToChange: allowStatusSelection ? statusesToChange : []
    }
    bulkStatusDispatcher && dispatch(bulkStatusDispatcher(params))
    bulkStatusUpdate && bulkStatusUpdate(params)
    close()
  }

  return (
    <Grid container direction={'column'} spacing={2} className={classes.root}>
      <Grid item>
        <Typography variant={'inherit'} className={classes.note}>
          <b>PLEASE NOTE:</b> Status CLARIFICATION can not be changed while it contains unresolved queries
        </Typography>
      </Grid>
      <Grid item container spacing={allowStatusSelection ? 2 : 0}>
        {
          allowStatusSelection &&
          <Grid item xs={6}>
            <DocumentStatusSelect
              label={'Change from'}
              name={'statusesToChange'}
              multiple
              excludeOptions={[BusinessStatus.Clarification, BusinessStatus.Accepted]}
              onChange={(e) => setStatusesToChange(e.target.value as BusinessStatus[])}
              value={statusesToChange}/>
          </Grid>
        }
        <Grid item xs={allowStatusSelection ? 6 : 12}>
          <DocumentStatusSelect
            label={!allowStatusSelection ? 'Change selected to' : 'To'}
            name={'newStatus'}
            excludeOptions={[
              BusinessStatus.Clarification,
              BusinessStatus.Accepted,
              ...(!canActMetadata ? [BusinessStatus.Open, BusinessStatus.Reviewed] : [])
            ]}
            onChange={(e) => setNewStatus(e.target.value as BusinessStatus)}
            value={newStatus}/>
        </Grid>
      </Grid>
      <Grid item container spacing={1} className={classes.actionsContainer}>
        <Grid item>
          <Button
            autoFocus
            disabled={statusesToChange.length === 0}
            onClick={() => handleSubmit()}
            variant="contained"
            color="primary"
            size="small">
            Apply
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={close}
            variant="contained"
            color="primary"
            size="small">
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
