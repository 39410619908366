import { FC } from 'react'
import { Icon, makeStyles, Tooltip } from '@material-ui/core'
import { ImportDocument, OcrStatus } from 'types'
import { useSetSingleDocumentPriorityMutation } from 'services/api/documentApi'
import { useLisaAuth } from 'hooks/useLisaAuth'

const useStyles = makeStyles((theme) => ({
  statusLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    rowGap: '2px',
    '& .MuiIcon-root': {
      fontSize: '16px',
      width: '24px',
      cursor: 'pointer'
    },
    '& .normal': {
      color: theme.palette.black.main,
      '&:hover': {
        color: theme.palette.lime.main
      }
    },
    '& .high': {
      transform: 'rotate(-45deg)',
      cursor: 'pointer',
      color: theme.palette.statusColors.red
    }
  }
}))

type DocumentLabelsProps = {
  row: ImportDocument,
}
export const OcrPriorityIndicator: FC<DocumentLabelsProps> = ({ row }) => {
  const classes = useStyles()
  const { hasAccess } = useLisaAuth()
  const canSetOcrPriority = hasAccess('perm_act_sethighocrprio')
  const [setSingleDocumentPriority] = useSetSingleDocumentPriorityMutation()
  const { documentId } = row
  return (
    <div>
      {row.ocrStatus === OcrStatus.Ready || row.ocrStatus === OcrStatus.Processing || row.ocrStatus === OcrStatus.InList
        ? <div className={classes.statusLabel}>
          <Tooltip
            title={!canSetOcrPriority ? '' : (!row.highPriority ? 'Set OCR priority to HIGH' : 'OCR priority HIGH')}>
            <Icon
              fontSize="small"
              className={!row.highPriority ? 'fas fa-long-arrow-alt-right normal' : 'fas fa-long-arrow-alt-right high'}
              onClick={() => {
                if (canSetOcrPriority) {
                  setSingleDocumentPriority(documentId)
                }
              }}
            />
          </Tooltip>
        </div>
        : null
      }
    </div>
  )
}
