import { FC, PropsWithChildren, useCallback, useRef } from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { Nullable } from 'types'
import Resizer from 'react-image-file-resizer'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  title: {},
  content: {
    backgroundColor: theme.palette.common.white,
    padding: 12
  },
  leftColumn: {
    paddingRight: 12
  },
  imageContainer: {
    backgroundColor: theme.palette.blue.main,
    marginBottom: 12,
    maxHeight: 56,
    minHeight: 56,
    '& .company-logo-preview': {
      paddingLeft: 5
    }
  },
  fileUpload: {
    display: 'none'
  },
  imagePlaceholder: {
    fontSize: 32,
    fontWeight: 600,
    lineHeight: '56px',
    color: theme.palette.common.white
  },
  imagePlaceholderText: {
    marginLeft: 12
  },
  imagePlaceholderTextSmall: {
    display: 'flex',
    height: 56,
    width: 56,
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid rgba(255, 255, 255, 0.2)'
  },
  imagePlaceholderCircle: {
    width: 42,
    height: 42,
    borderRadius: 21,
    backgroundColor: theme.palette.lime.main,
    fontSize: 16,
    color: theme.palette.blue.main,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 4
  }
}))

export interface ImagePlaceholderProps {
  isSmall?: boolean
  text?: string
}

export const ImagePlaceholder: FC<ImagePlaceholderProps> = ({
  isSmall = false,
  text = ''
}) => {
  const classes = useStyles()
  if (!isSmall) {
    return (
      <div className={classes.imagePlaceholderText}>
        <Typography className={classes.imagePlaceholder}>{text}</Typography>
      </div>
    )
  }
  return (
    <div className={classes.imagePlaceholder}>
      <div className={classes.imagePlaceholderTextSmall}>
        <div className={classes.imagePlaceholderCircle}>
          {text}
        </div>
      </div>
    </div>
  )
}

export interface LogoUploadWithPreviewProps {
  title: string
  imageUrl: string
  imageSize?: 'sm' | 'md'
  imagePlaceholder?: string
  onImageSelect: (imageBlob: Blob) => void
}

export const LogoUploadWithPreview: FC<PropsWithChildren<LogoUploadWithPreviewProps>> = ({
  imageUrl,
  title,
  imagePlaceholder = '',
  imageSize = 'md',
  children,
  onImageSelect
}) => {
  const classes = useStyles()
  const isSmall = imageSize === 'sm'
  const fileInputRef = useRef<Nullable<HTMLInputElement>>(null)
  const openFileInput = useCallback(() => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click()
    }
  }, [fileInputRef])
  return (
    <Grid container>
      <Grid item className={classes.title}>
        <Typography variant={'body2'}>{title}</Typography>
      </Grid>
      <Grid container item className={classes.content}>
        <Grid item container direction={'column'} xs={5} className={classes.leftColumn}>
          <Grid
            item
            container
            xs
            className={clsx(classes.imageContainer)}
            alignItems={'center'}>
            {imageUrl &&
              <div className={clsx(
                {
                  [classes.imagePlaceholderTextSmall]: isSmall,
                  'company-logo-preview': !isSmall
                }
              )}>
                <img src={imageUrl} style={{ maxWidth: !isSmall ? 196 : 42 }} />
              </div>
            }
            {!imageUrl && <ImagePlaceholder text={imagePlaceholder} isSmall={isSmall}/>}
          </Grid>
          <Grid item xs>
            <input
              type={'file'}
              accept="image/png, image/jpeg"
              ref={fileInputRef}
              className={classes.fileUpload}
              onChange={(e) => {
                try {
                  if (e.target.files && e.target.files[0]) {
                    Resizer.imageFileResizer(
                      e.target.files[0],
                      isSmall ? 42 : 168,
                      42,
                      'PNG',
                      100,
                      0,
                      (uri) => {
                        if (uri instanceof Blob) {
                          onImageSelect(uri)
                        }
                      },
                      'blob'
                      // isSmall ? 42 : 168,
                      // 42
                    )
                  }
                } catch (e) {
                  console.log('error selecting image', e)
                }
              }}/>
            <Button
              variant="contained"
              onClick={openFileInput}
              color="primary">
              Select image
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}
