/**
 * Formats date object to date string in format "dd.mm.yyyy, hh:mm:ss"
 * @param date
 * @param seconds
 * @param fullYear
 */
export const toFormattedString = (date: Date, seconds: boolean = false, fullYear: boolean = false) => {
  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: fullYear ? 'numeric' : '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }
  if (seconds) {
    options.second = '2-digit'
  }
  const dateString = date.toLocaleString('en-CH', options)
  return dateString.replace(/\//g, '.')
}

export const toFormattedDateTime = (date: Date) => toFormattedString(date, false, true)

export const toYMDString = (date: Date, yearIncrement?: number) => {
  if (yearIncrement) {
    return new Date(date.setFullYear(date.getFullYear() + yearIncrement)).toISOString().split('T')[0]
  }
  return date.toISOString().split('T')[0]
}

export const formattedDate = (date: Date = new Date()) => {
  const dateString = date.toLocaleString('en-CH', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  })
  return dateString.replace(/\//g, '.')
}

export const getWeekNumber = (date?: Date): number => {
  const d = date ?? new Date()
  const dUtc = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
  const dayNum = dUtc.getUTCDay() || 7
  dUtc.setUTCDate(dUtc.getUTCDate() + 4 - dayNum)
  const yearStart = new Date(Date.UTC(dUtc.getUTCFullYear(), 0, 1))
  return Math.ceil((((dUtc.valueOf() - yearStart.valueOf()) / 86400000) + 1) / 7)
}

export const toFormattedTimeString = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  }
  return date.toLocaleString('en-CH', options)
}

export const timeElapsed = (from: Date, to?: Date): string => {
  const toDate = to ?? new Date()
  const diff = toDate.getTime() - from.getTime()
  const diffDate = new Date(diff)
  const days = Math.floor(diff / (1000 * 24 * 60 * 60))
  return `${days}d ${toFormattedTimeString(diffDate)}`
}

export const toTimeElapsedString = (totalSeconds: number, showSeconds: boolean = false): string => {
  const s = totalSeconds % 60
  const m = Math.floor(totalSeconds / 60) % 60
  const h = Math.floor(totalSeconds / (60 * 60)) % 60
  const d = Math.floor(totalSeconds / (60 * 60 * 24))
  return `${d}d ${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}${showSeconds ? `:${String(s).padStart(2, '0')}` : ''}`
}

export const toDMYHMSString = (date: Date) => toFormattedString(date, true)
  .replace(/\./g, '').replace(/:/g, '').replace(/, /g, '-')

export const compareDateWithCurrent = (to: Date): boolean => {
  const current = new Date()
  const toDate = to ?? new Date()
  const diff = current.getTime() < toDate.getTime()

  return diff
}

export const convertDateFromUTCtoLocal = (to: Date): Date => {
  const n = new Date(to + 'Z')
  const utc = n.toLocaleString()

  return new Date(utc.toLocaleString())
}

export const addMinutes = (date: Date, minutes: number): Date => {
  date.setMinutes(date.getMinutes() + minutes)

  return date
}
