import { FC, memo, useState } from 'react'
import { makeStyles, TableRow, TableRowProps } from '@material-ui/core'
import { BusinessStatus, DocumentId, ParserStatus, TableViewRow as TableViewRowT } from 'types'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { TableViewCell } from './TableViewCell'
import { RowStatusCell } from './RowStatusCell'
import { selectCell } from 'redux/slices/documentSlice'
import { useUpdateTableViewCellMutation } from 'services/api/tableViewApi'

const useStyles = makeStyles((theme) => ({
  firstColumn: {
    left: 0,
    minWidth: 30,
    maxWidth: 30,
    textAlign: 'center'
  },
  secondColumn: {
    left: 48,
    minWidth: 130,
    maxWidth: 130
  },
  thirdColumn: {
    left: 212,
    minWidth: 50,
    maxWidth: 50
  },
  mediumCol: {
    minWidth: 150
  },
  selected: {
    border: '1px solid red !important'
  },
  clarificationStatus: {
    backgroundColor: theme.palette.statusColors.orange
  },
  openStatus: {
    backgroundColor: theme.palette.statusColors.blue
  },
  reviewedStatus: {
    backgroundColor: theme.palette.statusColors.green
  },
  irrelevantStatus: {
    backgroundColor: theme.palette.statusColors.grey,
    '& td:not(.status):not(.queries)': {
      textDecoration: 'line-through'
    }
  },
  deletedStatus: {
    backgroundColor: theme.palette.statusColors.black
  },
  bodyTableRow: {
    '&:hover td:not(.status):not(.queries)': {
      backgroundColor: theme.palette.blue.main,
      color: theme.palette.common.white
    },
    '& .status': {
      padding: 4
    }
  },
  bodyTableRowSelected: {
    '& td:not(.status):not(.queries)': {
      backgroundColor: theme.palette.blue.main,
      color: theme.palette.common.white
    }
  }
}))

const isMediumColumn = (name: string): boolean => ['Subject'].includes(name)

type TableViewRowProps = TableRowProps & {
  documentId: DocumentId
  rowData: TableViewRowT
  dateColumnIndex?: number[]
  selectedColumn?: number,
}
export const TableViewRow: FC<TableViewRowProps> = memo(({
  rowData,
  documentId,
  selectedColumn,
  ...tableRowProps
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    columns,
    rowIndex,
    rowName
  } = rowData
  const statusCol = columns.find(_ => _.columnIndex === -1)
  const scrollableCols = columns.filter(_ => _.columnIndex !== -1)
  const [bs, setBS] = useState<BusinessStatus>(
    parseInt(statusCol!.cellContent || ParserStatus.Ready + '') as unknown as BusinessStatus) // @todo I do not like this
  const [updateTableViewCell] = useUpdateTableViewCellMutation()

  return (
    <TableRow
      {...tableRowProps}
      className={clsx(classes.bodyTableRow, {
        [classes.bodyTableRowSelected]: selectedColumn !== undefined,
        [classes.irrelevantStatus]: bs === BusinessStatus.Irrelevant
      })}>
      <TableViewCell
        readonly
        sticky
        className={clsx(classes.firstColumn, {
          [classes.irrelevantStatus]: bs === BusinessStatus.Irrelevant
        })}>
        {rowName}
      </TableViewCell>
      {
        statusCol &&
        <RowStatusCell
          readonly
          sticky
          status={bs}
          onStatusChange={(status) => {
            setBS(status)
            updateTableViewCell({
              documentId,
              rowIndex,
              columnIndex: statusCol.columnIndex,
              cellContent: `${status}`
            })
          }}
          className={clsx('status', classes.secondColumn, {
            [classes.openStatus]: bs === BusinessStatus.Open,
            [classes.clarificationStatus]: bs === BusinessStatus.Clarification,
            [classes.deletedStatus]: bs === BusinessStatus.Deleted,
            [classes.irrelevantStatus]: bs === BusinessStatus.Irrelevant,
            [classes.reviewedStatus]: bs === BusinessStatus.Reviewed
          })}>
        </RowStatusCell>
      }
      {
        scrollableCols.map(({
          cellContent,
          columnIndex
        }) => {
          return (
            <TableViewCell
              onClick={() => dispatch(selectCell({
                row: rowData.rowIndex,
                column: columnIndex
              }))}
              onSave={(cellContent) => {
                updateTableViewCell({
                  documentId,
                  rowIndex,
                  columnIndex,
                  cellContent
                })
              }}
              focused={selectedColumn === columnIndex}
              className={clsx({
                [classes.mediumCol]: isMediumColumn(cellContent),
                [classes.selected]: selectedColumn === columnIndex
              })}
              key={cellContent + columnIndex}>
              {cellContent}
            </TableViewCell>
          )
        })
      }
    </TableRow>
  )
})
TableViewRow.displayName = 'TableViewRowMemoized'
