import { FC, MouseEvent, useState } from 'react'
import { TenantId } from 'types'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import { Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  iconButton: {
    padding: '0 10px',
    height: 32
  }
}))

export interface TenantSettingsMenuProps {
  tenantId: TenantId
  isActive: boolean
  onEdit: (tenantId: TenantId) => void
  onDeactivate: (tenantId: TenantId) => void
  onActivate: (tenantId: TenantId) => void
}
export const TenantSettingsMenu: FC<TenantSettingsMenuProps> = ({ onEdit, onDeactivate, onActivate, tenantId, isActive }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  return (
    <div className='menu-item'>
      <IconButton
        aria-controls='simple-menu'
        aria-haspopup='true'
        className={classes.iconButton}
        onClick={(event: MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
        }}
      >
        <Icon className='fas fa-ellipsis-v' fontSize='small' />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        elevation={8}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            onEdit(tenantId)
            setAnchorEl(null)
          }}>
          Edit Subscriber Data
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (isActive) {
              onDeactivate(tenantId)
            } else {
              onActivate(tenantId)
            }
            setAnchorEl(null)
          }}>
          {isActive ? 'Deactivate Subscriber' : 'Activate Subscriber' }
        </MenuItem>
      </Menu>
    </div>
  )
}
