import { ReactElement, ReactNode } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { FormField } from 'common/FormField/FormField'
import { AutocompleteProps } from '@material-ui/lab/Autocomplete/Autocomplete'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  autocomplete: {
    width: '100%'
  },
  textField: {
    width: '100%',
    maxHeight: 42
  }
}))

export interface ComboBoxProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
  > extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>{
  label?: ReactNode
  required?: boolean
}

function ComboBox<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
  > (props: Omit<ComboBoxProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>): ReactElement {
  const classes = useStyles()
  const {
    label,
    required = false,
    className,
    ...autocompleteProps
  } = props

  return (
    <FormField label={label} required={required}>
      <Autocomplete
        size='small'
        className={clsx(classes.autocomplete, className)}
        renderInput={(params) => (
          <TextField
            label={false}
            {...params}
            className={classes.textField}
          />
        )}
        {...autocompleteProps}
      />
    </FormField>
  )
}
export default ComboBox
