import { FC } from 'react'
import { Grid, GridProps, makeStyles, Paper } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    height: '100%',
    padding: '16px',
    cursor: 'pointer',
    borderRadius: '2px',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.15)',
    transition: 'all .15s linear',
    '&:hover': {
      background: 'rgba(255,255,255,.7)',
      boxShadow: 'none'
    }
  }
}))

type LisaBoxProps = GridProps
export const LisaBox: FC<LisaBoxProps> = ({ children, ...props }) => {
  const classes = useStyles()
  return (
    <Grid
      component={Paper}
      className={classes.paper}
      {...props}>
      {children}
    </Grid>
  )
}
