import { FC, MouseEvent, useEffect, useMemo, useState } from 'react'
import { Grid, Icon, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core'
import * as Icons from 'common/Icons/SvgIcons'
import LisaLoader from 'common/Loaders/LisaLoader'
import { useDispatch, useSelector } from 'react-redux'
import { selectQuery, toggleStatusReport, updateFilters, useQueries, showAllQueries } from 'redux/slices/queriesSlice'
import { Query, SortDirection } from 'types'
import { NoResults } from 'common/NoResults/NoResults'
import { MaybeForbiddenAction } from 'common/MaybeForbiddenAction/MaybeForbiddenAction'
import { shouldDoSearch, sortQueriesByDate } from 'utils/queries'
import clsx from 'clsx'
import { QueryForm } from 'components/Query/QueryForm'
import { QueryItem } from 'components/Query/QueryItem'
import { openModal } from 'redux/slices/appSlice'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { ExportQueriesFrom } from './ExportQueriesFrom'
import { useTransitionId } from 'context/TransitionContext'
import { useHistory, useLocation } from 'react-router-dom'
import { useQueryData } from 'hooks/useQueryData'
import { useLisaAuth } from 'hooks/useLisaAuth'
import Dropdown from 'common/LisaControls/Dropdown'
import { RoleName } from 'utils/userRoleSecurity'
import SwitchLabel from 'common/LisaControls/SwitchLabel'

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingRight: '8px',
    background: theme.palette.grey2.light100
  },
  queriesList: {
    flexGrow: 1,
    overflow: 'auto',
    height: 0 // to force vertical scroll on flexGrow
  },
  queriesListEmpty: {
    backgroundColor: '#FFFFFF',
    paddingTop: 100
  },
  treeViewHeader: {
    height: 'auto',
    minHeight: '56px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    borderRadius: '4px 4px 0px 0px',
    alignItems: 'center',
    padding: '7px',
    background: 'white',
    marginBottom: '6px'
  },
  sortDropDown: {
    width: 200
  },
  singleQueryTabs: {
    height: '56px',
    display: 'flex',
    width: '100%',
    borderRadius: '4px 4px 0px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    marginBottom: '6px',
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.blue.main,
      '&:hover': {
        color: theme.palette.blue.main
      },
      '&.Mui-selected': {
        color: theme.palette.blue.main,
        borderBottom: `6px solid ${theme.palette.blue.main}`
      }
    }
  },
  queryActions: {
    display: 'flex'
  },
  menuIcon: {
    width: '42px',
    height: '42px',
    color: theme.palette.black.main,
    '&:hover': {
      background: theme.palette.grey2.light,
      color: theme.palette.black.light
    },
    '& .fas.fa-ellipsis-v': {
      fontSize: '18px'
    }
  },
  emptyContainer: {
    backgroundColor: '#FFFFFF'
  }
}))

export interface QueriesListProps {
  queries: Query[]
  // isFetching: boolean
  isSearching: boolean
}

export const QueriesList: FC<QueriesListProps> = ({ queries, isSearching }) => {
  const history = useHistory()
  const { filters } = useSelector(useQueries)
  const shouldSearch = shouldDoSearch(filters)
  const { hasAccess, user: lisaUser, hasRole } = useLisaAuth()
  const { data: selectedQuery } = useQueryData()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const classes = useStyles()
  const dispatch = useDispatch()
  const canCreateQuery = hasAccess('perm_act_query_create')
  const showSearchResults = filters.searchTerm && !isSearching
  const doneLoading = !isSearching
  const noQueries = doneLoading && queries.length === 0
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const queryId = searchParams.get('qId')
  const transitionId = useTransitionId()
  const isExternal = lisaUser !== null && hasRole(RoleName.External)
  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const sortedQueries = sortQueriesByDate(queries, filters.sortOrder)

  const handleShowAllQueries = () => {
    dispatch(showAllQueries(!filters.related))
  }

  useEffect(() => {
    if (!doneLoading) { // Do nothing while loading
      return
    }
    if (sortedQueries.length === 0) { // If there are no queries in list then nothing can be selected
      dispatch(selectQuery(null))
      return
    }
    if (queryId && sortedQueries.some((sq) => sq.queryId.toLowerCase() === queryId)) {
      // If there is queryId in url and that queryId is in queries list then we can mark it as selected
      dispatch(selectQuery(queryId))
    } else {
      // otherwise we should just select first query from the list
      history.replace({
        pathname: `/structures/queries/${transitionId}`,
        search: `?qId=${sortedQueries[0].queryId.toLowerCase()}`
      })
    }
    return () => {
      // Reset selected queries on unmount to prevent switch query glitch in queries list
      dispatch(selectQuery(null))
    }
  }, [doneLoading, queryId, shouldSearch])

  return (
    <Grid container direction={'column'} className={classes.paper}>
      <Grid container className={classes.treeViewHeader}>
        <Grid item>
          <Dropdown
            className={classes.sortDropDown}
            name="sortQueries"
            items={[
              {
                value: 'desc',
                text: 'Newest first'
              },
              {
                value: 'asc',
                text: 'Oldest first'
              }
            ]}
            value={filters.sortOrder}
            showSelectOption={false}
            onChange={(e) => dispatch(
              updateFilters({ sortOrder: e.target.value as SortDirection }))}
          />
        </Grid>
        <SwitchLabel
          disabled={false}
          variant={'blue'}
          name="ShowAllQueries"
          label={'Show all queries'}
          value={filters.related}
          onChange={ handleShowAllQueries }
        />
        <div className={classes.queryActions}>
          <MaybeForbiddenAction isForbidden={!canCreateQuery} tooltip={'Add New Query'}>
            <IconButton
              className={classes.menuIcon}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={() => dispatch(openModal('CREATE_QUERY'))}>
              <Icons.AddIco/>
            </IconButton>
          </MaybeForbiddenAction>
          <IconButton
            className={classes.menuIcon}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleOpenMenu}
          >
            <Icon className="fas fa-ellipsis-v"/>
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <MenuItem
              disabled={isExternal && noQueries ? !noQueries : noQueries}
              onClick={() => {
                dispatch(openModal('EXPORT_QUERIES_TO_EXCEL'))
                handleClose()
              }}>
              Export to Excel
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(toggleStatusReport())
                handleClose()
              }}>
              Show/Hide Query status
            </MenuItem>
          </Menu>
        </div>
      </Grid>
      <Grid item className={clsx({
        [classes.queriesList]: true,
        [classes.queriesListEmpty]: noQueries || !doneLoading
      })}>
        {
          isSearching &&
          <LisaLoader
            text="Loading query list..."
            normalLoader/>
        }
        {
          noQueries &&
          <div className={classes.emptyContainer}>
            <NoResults type={'queries'} message={'No queries'}/>
          </div>
        }
        {
          doneLoading &&
          sortedQueries.map((q) => (
            <QueryItem
              key={`${showSearchResults ? 'searchResults' : 'queries'}-${q.queryId}`}
              query={{
                ...q,
                queryStatus: selectedQuery && q.queryId === selectedQuery.queryId ? selectedQuery.queryStatus : q.queryStatus
              }}/>))
        }
      </Grid>
      <LisaModal
        title="Add New Query"
        scroll={'body'}
        modalType={'CREATE_QUERY'}>
        <QueryForm onComplete={() => null}/>
      </LisaModal>
      <LisaModal
        title={'Export to Excel'}
        modalType={'EXPORT_QUERIES_TO_EXCEL'}
        scroll="body">
        <ExportQueriesFrom/>
      </LisaModal>
    </Grid>
  )
}
