import { FC, MouseEvent, ReactNode, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useA2KLabels } from 'redux/slices/a2kLabelsSlice'
import {
  resetSelectedDocuments,
  setPage,
  toggleAll,
  toggleDocument,
  useA2KDocuments,
  updateSelectedDocuments,
  sortBy,
  A2KDocumentsSortColumns,
  updateFilters
} from 'redux/slices/a2kDocumentsSlice'
import { updateFilters as queryUpdateFilters } from 'redux/slices/queriesSlice'
import { useTransitionContext } from 'context/TransitionContext'
import { Chip, makeStyles, MenuItem, TableSortLabelProps, Theme, Tooltip } from '@material-ui/core'
import { A2KDocument, BusinessStatus, DocumentId, User } from 'types'
import { filterA2KDocuments } from 'utils/documents'
import { DocumentLink } from 'common/DocumentLink/DocumentLink'
import clsx from 'clsx'
import { LisaPaper } from 'common/LisaPaper/LisaPaper'
import { openModal } from 'redux/slices/appSlice'
import { Label } from 'common/Label/Label'
import { DocumentLabels } from 'common/DocumentLabels/DocumentLabels'
import { useBulkLabelAssignActions } from 'components/BulkLabelAssign/BulkLabelAssign'
import { OpenOrClosed } from 'common/Indicators/OpenOrClosed'
import IconButton from '@material-ui/core/IconButton'
import { useGetDocumentsByLabelQuery } from 'services/api/labellingApi'
import { setDocumentToRename } from 'redux/slices/documentSlice'
import { MaybeForbiddenAction } from 'common/MaybeForbiddenAction/MaybeForbiddenAction'
import {
  CheckMark,
  InfoIcon
} from 'common/Icons/SvgIcons'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useHistory } from 'react-router-dom'
import { hasAdditionalInfo, hasState, isOpenedState } from 'utils/labels'
import { ProcessingStatusIndicator } from 'common/ProcessingStatusIndicator/ProcessingStatusIndicator'
import { Cell, Column, LisaTable, Row } from 'common/LisaTable/LisaTable'
import { a2kColumns, ColumnConfiguratorKey, columnConfiguratorLabels } from 'utils/columnConfigurator'
import { AbbreviationTypeIcon } from 'common/AbbreviationTypeIcon/AbbreviationTypeIcon'
import Dropdown from 'common/LisaControls/Dropdown'
import { WoWpInfo } from 'pages/Atok/components/WoWpInfo'

type StylesProps = {
  isFocused: boolean
}

const useStyle = makeStyles<Theme, StylesProps>(({ palette }) => ({
  dataTableCheckbox: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.35rem'
    }
  },
  bodyRows: {
    '& .MuiChip-label': {
      lineHeight: '18px'
    }
  },
  markForDeletion: {
    background: palette.red.main
  },
  statusLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    rowGap: '2px'
  },
  labels: {
    '& .MuiChip-sizeSmall': {
      background: palette.common.white,
      height: '24px',
      fontSize: '13px',
      fontWeight: '600',
      letterSpacing: '.3px',
      color: palette.black.main,
      borderRadius: '4px',
      border: `2px solid ${palette.statusColors.blue}`,
      textTransform: 'none',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  },
  queryCount: {
    minWidth: '36px',
    marginLeft: '4px',
    display: 'flex',
    '& .MuiChip-root, .MuiChip-labelSmall': {
      cursor: 'pointer',
      '&.MuiChip-root:hover': {
        background: palette.lime.dark
      }
    }
  },
  emptyChip: {
    '&.MuiChip-root': {
      pointerEvents: 'none',
      background: 'transparent',
      '&.MuiChip-root:hover': {
        background: 'transparent'
      }
    }
  },
  openClosedStatus: {
    marginRight: 4,
    height: 23
  }
}))

export const A2KDocuments: FC = () => {
  const { hasAccess, user: { settings: { a2KSettings } } } = useLisaAuth()
  const { selected: label } = useSelector(useA2KLabels)
  const {
    selectedDocuments,
    filters
  } = useSelector(useA2KDocuments)
  const selectedDocumentsIds = selectedDocuments.map(_ => _.documentId)
  const {
    transitionId,
    openDocument
  } = useTransitionContext()
  const { startDocumentAssign, startDocumentsAssign } = useBulkLabelAssignActions()
  const isFocused = true
  const classes = useStyle({ isFocused })
  const dispatch = useDispatch()
  const history = useHistory()
  const numSelected = selectedDocuments.length
  const {
    businessStatus,
    page,
    pageSize,
    sortOrder,
    sortColumn
  } = filters
  const areDocumentsSelected = numSelected > 0
  const {
    data,
    isFetching,
    fulfilledTimeStamp
  } = useGetDocumentsByLabelQuery({
    transitionId,
    labelId: label?.labelId ?? null,
    page,
    pageSize,
    sortOrder,
    sortColumn
  }, { skip: label === null })
  const rows = data?.rows ?? []
  const pages = data?.pages ?? 0
  const total = data?.total ?? 0
  const documents = filterA2KDocuments(rows, businessStatus)
  const rowCount = documents.length
  const hasValidStatusesForBulkChange = documents.some(_ => _.businessStatus !== BusinessStatus.Clarification)
  const [showInfo, toggleInfo] = useState(true)
  const selectedStatusFilter = selectedDocuments.filter(_ => _.businessStatus === BusinessStatus.Clarification || _.businessStatus === BusinessStatus.Accepted)
  const hasValidStatusesForBulkChangeSelected = selectedStatusFilter.length === selectedDocuments.length

  useEffect(() => {
    dispatch(updateSelectedDocuments(rows))
  }, [fulfilledTimeStamp])

  useEffect(() => {
    dispatch(resetSelectedDocuments())
    dispatch(setPage(1))
  }, [label?.labelId])

  const handleOpenAllSelected = () => {
    selectedDocuments.forEach(({ documentId }) => {
      openDocument(documentId)
    })
    dispatch(resetSelectedDocuments())
  }

  const openBulkStatusChangeModal = () => dispatch(openModal('DOCUMENT_STATUS_CHANGE'))
  const isSelectedDocumentOnPage = (documentId: DocumentId) => documents.some(_ => _.documentId === documentId)
  const documentToRename = rows.find((d) => d.documentId === selectedDocuments[0]?.documentId)
  const canTriggerBulkLabelAssign = hasAccess('perm_act_a2klabel')
  const canChangeStatus = hasAccess('perm_act_documentmetadata') || hasAccess('perm_act_deletedocument')

  const documentsWithoutOpenedQueries = documents.filter((document) => document.queryCount === 0)
  const documentsIDsWithoutOpenedQueries = documentsWithoutOpenedQueries.map(_ => _.documentId)
  const hasDocWithClosedQuerySelected = selectedDocumentsIds.every((documentId) => documentsIDsWithoutOpenedQueries.includes(documentId))

  const items = useMemo<ReactNode>(() => {
    return [
      <MenuItem
        key={'open-selected'}
        disabled={!areDocumentsSelected}
        onClick={handleOpenAllSelected}>
        Open Selected
      </MenuItem>,
      <MaybeForbiddenAction key='bulk-label-assign' isForbidden={!canTriggerBulkLabelAssign}>
        <MenuItem
          disabled={!areDocumentsSelected}
          onClick={() => startDocumentsAssign(selectedDocuments)}>
          Bulk label assign
        </MenuItem>
      </MaybeForbiddenAction>,
      <MaybeForbiddenAction key={'change-status-selected'} isForbidden={!canChangeStatus}>
        <MenuItem
          disabled={(hasValidStatusesForBulkChangeSelected && !hasDocWithClosedQuerySelected) || !areDocumentsSelected}
          onClick={openBulkStatusChangeModal}>
        Change status of selected documents
        </MenuItem>
      </MaybeForbiddenAction>,
      <MaybeForbiddenAction key={'change-status-all'} isForbidden={!canChangeStatus}>
        <MenuItem
          disabled={rowCount === 0 || areDocumentsSelected || !hasValidStatusesForBulkChange}
          onClick={openBulkStatusChangeModal}>
        Change status of all documents
        </MenuItem>
      </MaybeForbiddenAction>,
      <MaybeForbiddenAction key={'rename-document'} isForbidden={!hasAccess('perm_act_renamedocument')}>
        <MenuItem
          disabled={selectedDocumentsIds.length !== 1 || !isSelectedDocumentOnPage(selectedDocumentsIds[0])}
          onClick={() => {
            if (documentToRename) {
              dispatch(setDocumentToRename(documentToRename))
              dispatch(openModal('RENAME_DOCUMENT'))
            }
          }}>
        Rename Document
        </MenuItem>
      </MaybeForbiddenAction>,
      <MenuItem
        key={'configure-table-view'}
        onClick={() => dispatch(openModal('COLUMN_CONFIGURATOR'))}>
        {'Configure table view'}
      </MenuItem>
    ]
  }, [areDocumentsSelected, rowCount, hasValidStatusesForBulkChange, selectedDocumentsIds, canTriggerBulkLabelAssign])

  const handleOnClick = (e: MouseEvent<HTMLDivElement>, document: A2KDocument) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(queryUpdateFilters({ documentId: document.documentId.toLocaleUpperCase(), documentName: document.name, queryStatus: null }))
    history.push(`/structures/${'queries'}/${transitionId}`)
  }
  const tableCellProps: Map<ColumnConfiguratorKey, TableSortLabelProps> = useMemo(() => {
    const config: [ColumnConfiguratorKey, A2KDocumentsSortColumns][] = [
      ['a2KListFilename', 'Name'],
      ['a2KListDocumentType', 'DocType'],
      ['a2KListQueries', 'Query'],
      ['a2KListDocumentStatus', 'BusinessStatus'],
      ['a2KListOrigin', 'ImportFolder'],
      ['a2KListProcessingStatus', 'ProcessingStatus']
    ]
    return new Map(config
      .filter(([ccKey]) => ccKey !== 'a2KListDocumentStatus')
      .map(([ccKey, sc]) => [ccKey, {
        active: sortColumn === sc,
        direction: sortColumn === sc ? sortOrder || 'asc' : 'asc',
        onClick: () => dispatch(sortBy(sc))
      }]))
  }, [sortOrder, sortColumn])

  const columns: Column[] = useMemo(() => {
    return a2kColumns
      .filter((ic) => a2KSettings[ic as keyof User['settings']['a2KSettings']])
      .map((ccKey) => {
        const key = columnConfiguratorLabels.get(ccKey)
        const isBusinessStatus = key === 'Document Status'
        return {
          id: String(ccKey),
          label: isBusinessStatus
            ? <Dropdown
              items={[
                {
                  value: 0,
                  text: 'All active'
                },
                {
                  value: BusinessStatus.Open,
                  text: 'Open'
                },
                {
                  value: BusinessStatus.Clarification,
                  text: 'Clarification'
                },
                {
                  value: BusinessStatus.Irrelevant,
                  text: 'Irrelevant'
                },
                {
                  value: BusinessStatus.Reviewed,
                  text: 'Reviewed'
                },
                {
                  value: BusinessStatus.Accepted,
                  text: 'Accepted'
                }
              ]}
              showSelectOption={false}
              compact
              value={businessStatus}
              name={'sortByDocumentState'}
              onChange={(e) => {
                dispatch(updateFilters({ businessStatus: (e.target.value as BusinessStatus | 0) * 1 }))
              }}
            />
            : key,
          className: ccKey === 'a2KListFilename' ? 'fileName' : undefined,
          tableSortLabelProps: tableCellProps.get(ccKey),
          width: ccKey === 'a2KListDocumentType' ? '60px' : undefined
        }
      })
  }, [a2KSettings, tableCellProps, businessStatus])

  const tableRows: Row[] = useMemo(() => {
    const ccKeys = Object.entries(a2KSettings).filter((entry) => entry[1]).map(([ccKey]) => ccKey as ColumnConfiguratorKey)
    return documents.map((document) => {
      const isQueryCountEmpty = document.queryCount === 0 && document.closedQueryCount === 0
      const { labels } = document
      const cells: Cell[] = []
      if (ccKeys.includes('a2KListDocumentType')) {
        cells.push({
          id: `document-${document.documentId}-docType`,
          value: (
            <AbbreviationTypeIcon
              docTypeRecognitionResultByPages={document.docTypeRecognitionResultByPages}
              docTypeRecognitionResult={document.docTypeRecognitionResult}/>
          )
        })
      }
      if (ccKeys.includes('a2KListFilename')) {
        cells.push({
          id: `document-${document.documentId}-fileName`,
          value: <DocumentLink document={document} noDocType documentNameClass={'fileNameEllipsis'}/>
        })
      }
      if (ccKeys.includes('a2KListA2KLabels')) {
        cells.push({
          id: `document-${document.documentId}-labels`,
          value: <DocumentLabels
            withAssignLabelsOption
            labels={labels.slice().sort((a, z) => {
              if (a.labelId === label?.labelId) {
                return 1
              }
              if (z.labelId === label?.labelId) {
                return -1
              }
              return 0
            })}
            onClick={() => startDocumentAssign(document)}/>
        })
      }
      if (ccKeys.includes('a2KListProcessingStatus')) {
        cells.push({
          id: `document-${document.documentId}-processingStatus`,
          value: <ProcessingStatusIndicator
            ocrStatus={document.ocrStatus}
            processingStatus={document.processingStatus}/>
        })
      }
      if (ccKeys.includes('a2KListQueries')) {
        cells.push({
          id: `document-${document.documentId}-processingPriority`,
          value: (
            <div className={classes.queryCount}>
              <Chip size="small" className={isQueryCountEmpty ? classes.emptyChip : ''} label={document.queryCount > 0 && document.queryCount} onClick={(e: MouseEvent<HTMLDivElement>) => handleOnClick(e, document)}/>
            </div>
          )
        })
      }
      if (ccKeys.includes('a2KListDocumentStatus')) {
        cells.push({
          id: `document-${document.documentId}-importedBy`,
          value: (
            <div className={classes.statusLabel}>
              <Chip
                className={clsx([
                  'FileStatus',
                  document.businessStatusName
                ])}
                label={document.businessStatusName}
                size="small"
                variant="outlined"
                icon={document.businessStatusName === 'Accepted' ? <CheckMark /> : <></>}
              />
            </div>
          )
        })
      }
      if (ccKeys.includes('a2KListOrigin')) {
        cells.push({
          id: `document-${document.documentId}-origin`,
          value: (
            <Tooltip title={document.filePath}><span>{document.importFolder}</span></Tooltip>
          )
        })
      }
      return {
        id: `document-${document.documentId}`,
        selected: selectedDocumentsIds.includes(document.documentId),
        document,
        cells
      }
    })
  }, [a2KSettings, documents, selectedDocumentsIds])

  const showInfoButton = useMemo(() => label !== null && hasAdditionalInfo(label), [label])
  return (
    <LisaPaper toolbar={{
      title: (
        <div className={classes.labels}>
          {
            label &&
            <>
              {
                hasState(label) &&
                <OpenOrClosed className={classes.openClosedStatus} state={isOpenedState(label) ? 'open' : 'closed'}/>
              }
              <Label label={label} />
            </>
          }
        </div>
      ),
      items,
      additionalTools: label === null || !showInfoButton
        ? null
        : (
          <IconButton
            className={clsx(classes.infoIcon, {
              [classes.infoIconActive]: showInfo
            })}
            onClick={() => toggleInfo(!showInfo)}>
            <InfoIcon/>
          </IconButton>
        )
    }}>
      <LisaTable
        columns={columns}
        rows={tableRows}
        onSelectAll={() => dispatch(toggleAll(rows))}
        onRowToggle={(document) => dispatch(toggleDocument(document))}
        selectedRows={selectedDocumentsIds}
        allRows={documents.map(_ => _.documentId)}
        isFetching={isFetching}
        headerInfo={showInfo && label !== null && showInfoButton ? <WoWpInfo label={label}/> : undefined}
        page={page}
        pages={pages}
        pageSize={pageSize}
        total={total}
        onPageChange={(value: number) => dispatch(setPage(value))}/>
    </LisaPaper>
  )
}
