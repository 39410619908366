import { makeStyles } from '@material-ui/core'

export const useQueryFormStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiDivider-root': {
      width: '100%',
      margin: '10px 0px 0px 1px'
    },
    '& .MuiFormControlLabel-root': {
      marginRight: '-5px'
    }
  },

  sideIndicator: {
    position: 'absolute',
    overflow: 'hidden',
    height: '100%',
    width: '4px',
    left: '0',
    top: '0',
    '&.high': {
      background: theme.palette.statusColors.red
    },
    '&.low': {
      background: theme.palette.statusColors.green
    },
    '&.medium': {
      background: theme.palette.statusColors.orange
    },
    '&.info': {
      background: theme.palette.statusColors.blue
    }
  },
  groupContainer: {
    marginTop: '15px'
  },
  actionButtons: {
    padding: '32px 0px',
    display: 'flex',
    // columnGap: '10px',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  },
  colapseRow: {
    marginBottom: '4px'
  },
  dynamicOverview: {
    minHeight: '42px',
    background: 'rgba(0,0,0,.1)',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '10px',
    '& .MuiSvgIcon-root': {
      background: 'white',
      marginRight: '8px',
      borderRadius: '4px',
      padding: '0px 2px'
    },
    '& .MuiChip-root': {
      marginRight: '8px',
      borderRadius: '4px'
    },
    '& .QueryStatus': {
      background: 'white',
      padding: '0 6px',
      borderRadius: '4px'
    }
  },
  criticalColor: {
    fill: theme.palette.statusColors.red
  },
  sectionTitle: {
    fontSize: '14px',
    marginTop: '12px',
    fontWeight: 700,
    letterSpacing: '.8px',
    textTransform: 'uppercase'
  },
  atokLetter: {
    fontSize: '14px',
    fontWeight: 700,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    background: theme.palette.black.main,
    color: theme.palette.common.white,
    marginRight: '4px'
  },
  atokLabel: {
    fontSize: '14px',
    fontWeight: 700,
    letterSpacing: '.1px',
    textTransform: 'uppercase'
  },
  labelContainer: {
    width: '100%',
    height: '250px',
    // marginTop: "14px",
    overflow: 'auto',
    padding: '6px 0',
    border: `1px solid ${theme.palette.grey2.main}`,
    background: 'white',
    borderRadius: '4px',
    '&.label-error': {
      border: '1px solid red'
    }
  },
  userName: {
    fontWeight: 600,
    lineHeight: '18px',
    fontSize: '16px'
  },
  userEmail: {
    fontWeight: 500,
    lineHeight: '16px',
    fontSize: '14px'
  },
  labelsContainer: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh / 2)',
    maxWidth: '584px'
  },
  priorityLabels: {},
  dropdownText: {},
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 450
  }
}))
