import {
  AssignedLabel,
  Label,
  LabelBase, LabelId,
  LabelResponse,
  LisaDocumentWithLabels,
  LoadingState,
  Nullable,
  StorageId
} from 'types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  createHierarchy,
  searchTree,
  selectedLabels,
  selectLabelInTree,
  toggleDocumentLabelInTree,
  toggleDocumentLabelInTreeByLabelIds,
  getLabel
} from 'utils/labels'
import { initialLabelsState, labelsReducers, LabelsReducers, LabelsState } from './labelsSlice'
import { RootState } from 'redux/store'
import { getAssignedLabels } from 'utils/documents'

export interface BulkAssignLabelsState extends LabelsState {
  selected: Label[]
  documents: LisaDocumentWithLabels[]
  document: Nullable<LisaDocumentWithLabels>
  storageId: Nullable<StorageId>
  assignmentType: 'storage' | 'single_document' | 'multiple_documents' | null
  title?: string
  labelsToRemove: LabelBase[] // Used only in multiple_documents assignment type
  assignedLabels: AssignedLabel[] // Used only in multiple_documents assignment type
  requiredLabels: LabelId[]
}

export interface BulkAssignLabelsReducers extends LabelsReducers {
}

export const initialState: BulkAssignLabelsState = {
  ...initialLabelsState,
  selected: [],
  documents: [],
  document: null,
  storageId: null,
  assignmentType: null,
  title: 'Assign Labels',
  labelsToRemove: [],
  assignedLabels: [],
  requiredLabels: []
}

const bulkAssignLabelsSlice = createSlice({
  name: 'bulkAssignLabels',
  initialState,
  reducers: {
    search: labelsReducers.search<BulkAssignLabelsState>(),
    toggle: labelsReducers.toggle<BulkAssignLabelsState>(),
    labelAdded: (state, { payload }: PayloadAction<Label>) => {
      state.hierarchy = toggleDocumentLabelInTree(state.hierarchy!, payload.labelId, payload.path!)
      state.selected = selectedLabels(state.hierarchy)
      state.searchResults = searchTree(state.hierarchy, state.searchTerm)
      state.assignedLabels = state.assignedLabels.map(
        (al) => payload.labelId === al.labelId
          ? ({
            ...al,
            assignedToAll: true
          })
          : al)
      state.labelsToRemove = state.labelsToRemove.filter(_ => _.labelId !== payload.labelId)
    },
    labelRemoved: (state, { payload }: PayloadAction<Label>) => {
      const wasSelected = state.selected.some(_ => _.labelId === payload.labelId)
      if (wasSelected) { // This should be skipped for multi document bulk assignment
        state.hierarchy = toggleDocumentLabelInTree(state.hierarchy!, payload.labelId, payload.path!)
        state.selected = selectedLabels(state.hierarchy)
        state.searchResults = searchTree(state.hierarchy, state.searchTerm)
      }
      state.assignedLabels = state.assignedLabels.filter((al) => al.labelId !== payload.labelId)
      if (state.assignmentType !== 'storage') {
        state.labelsToRemove.push(payload)
      }
    },
    reset: (state) => {
      state.searchTerm = ''
      state.searchResults = []
      state.selected = []
      state.hierarchy = selectLabelInTree(state.hierarchy!, null)
      if (state.hierarchy !== null) {
        state.openedLabels = {
          [state.hierarchy.labelId]: true
        }
      }
      state.title = undefined
      state.documents = []
      state.document = null
      state.storageId = null
      state.assignedLabels = []
      state.labelsToRemove = []
    },
    getPreselectedLabel: (state, { payload }: PayloadAction<LabelId>) => {
      if (Number(payload) !== 0) {
        const _hierarchy = selectLabelInTree(state.hierarchy!, null)
        state.hierarchy = _hierarchy
        const selectedLabel = getLabel(_hierarchy, Number(payload))
        state.selected[0] = selectedLabel!
        state.hierarchy = toggleDocumentLabelInTree(state.hierarchy!, selectedLabel?.labelId!, selectedLabel?.path!)
      }
    },
    setHierarchy: (state, { payload }: PayloadAction<LabelResponse>) => {
      state.loadingHierarchy = LoadingState.Completed
      if (payload) { // @todo temporary condition. External users are getting null
        state.hierarchy = createHierarchy(payload)
        state.searchResults = searchTree(state.hierarchy, state.searchTerm)
        state.selected = []
        state.openedLabels[state.hierarchy.labelId] = true // Root is always open
      }
    },
    startSingleDocumentAssignment: (state, { payload }: PayloadAction<{ document: LisaDocumentWithLabels }>) => {
      const { document } = payload
      const { labels } = document
      state.documents = []
      state.assignedLabels = []
      state.document = document
      state.assignmentType = 'single_document'
      state.storageId = null
      const labelIds = (labels ?? []).map(_ => _.labelId)
      state.title = labelIds.length > 0 ? 'Edit Labels' : 'Assign Labels'
      if (labelIds.length > 0 && state.hierarchy) { // Preselect assigned labels if any
        state.hierarchy = toggleDocumentLabelInTreeByLabelIds(state.hierarchy, labelIds)
        state.selected = selectedLabels(state.hierarchy)
      }
    },
    starStorageAssignment: (state, { payload }: PayloadAction<{ storageId: StorageId }>) => {
      state.assignmentType = 'storage'
      state.documents = []
      state.assignedLabels = []
      state.document = null
      state.storageId = payload.storageId
      state.title = 'Assign labels to all unlabelled documents'
    },
    startMultipleDocumentsAssignment: (state, { payload }: PayloadAction<{ documents: LisaDocumentWithLabels[] }>) => {
      state.assignmentType = 'multiple_documents'
      state.documents = payload.documents
      state.document = null
      state.storageId = null
      state.title = 'Bulk label assignment'
      if (state.hierarchy) {
        state.assignedLabels = getAssignedLabels(state.documents, state.hierarchy)
        state.hierarchy = toggleDocumentLabelInTreeByLabelIds(
          state.hierarchy, state.assignedLabels.filter(_ => _.assignedToAll).map(_ => _.labelId))
        state.selected = selectedLabels(state.hierarchy)
      }
    }
  }
})

export const {
  search,
  toggle,
  labelAdded,
  labelRemoved,
  reset,
  getPreselectedLabel,
  setHierarchy,
  starStorageAssignment,
  startMultipleDocumentsAssignment,
  startSingleDocumentAssignment
} = bulkAssignLabelsSlice.actions

export const bulkAssignLabelsReducers: BulkAssignLabelsReducers = {
  search,
  toggle,
  labelAdded,
  labelRemoved
}

export const useBulkAssignLabels = (state: RootState) => state.bulkAssignLabels

export default bulkAssignLabelsSlice.reducer
