import { FC } from 'react'
import { ConfirmDialog } from 'common/Dialogs/ConfirmDialog'
import { makeStyles } from '@material-ui/core'
import { resetSelectedDocuments, useOCRStatus } from 'redux/slices/ocrStatusSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useBulkResendToOcrMutation } from 'services/api/ocrApi'
import { openToast } from 'redux/slices/appSlice'

const useStyles = makeStyles(() => ({
  message: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 500,
    padding: '48px 0'
  }
}))

export const OCRModals: FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { documentIdsForOCR } = useSelector(useOCRStatus)
  const [bulkResendToOCR] = useBulkResendToOcrMutation()
  return (
    <>
      <ConfirmDialog
        title="Send documents to OCR"
        type={'SEND_DOCUMENTS_TO_OCR'}
        onConfirm={async () => {
          const { success } = await bulkResendToOCR(documentIdsForOCR).unwrap()
          if (success) {
            dispatch(openToast({ severity: 'info', message: `${documentIdsForOCR.length} file(s) resent to OCR` }))
            dispatch(resetSelectedDocuments())
          }
        }} >
        <div className={classes.message}>Are you sure you want to send this documents to OCR?</div>
      </ConfirmDialog>
    </>
  )
}
