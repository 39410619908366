import { FC } from 'react'
import { toggleAllDocumentIdsToOCR, useOCRStatus } from 'redux/slices/ocrStatusSlice'
import { useDispatch, useSelector } from 'react-redux'
import { SelectAllCell as SelectAllCellCommon } from 'common/TableData/SelectAllCell'
import { DocumentId, LisaDocument } from 'types'

type SelectAllCellProps = {
  documents: LisaDocument[],
  documentIds: DocumentId[]
}
export const SelectAllCell: FC<SelectAllCellProps> = ({ documents, documentIds }) => {
  const { documentIdsForOCR } = useSelector(useOCRStatus)
  const dispatch = useDispatch()
  return (
    <SelectAllCellCommon
      selectedItems={documentIdsForOCR}
      items={documentIds}
      onChange={() => dispatch(toggleAllDocumentIdsToOCR(documents))}/>
  )
}
