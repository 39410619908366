import { FC, HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { LisaDocument } from 'types'
import { makeStyles, Theme } from '@material-ui/core'
import { useTransitionContext } from 'context/TransitionContext'
import clsx from 'clsx'
import { AbbreviationTypeIcon } from 'common/AbbreviationTypeIcon/AbbreviationTypeIcon'

const useStyles = makeStyles<Theme, { displayOnly: boolean }>((theme) => ({
  documentLink: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    letterSpacing: '.05px',
    whiteSpace: 'normal',
    '& div': {
      cursor: props => props.displayOnly ? 'normal' : 'pointer'
    }
  },
  hover: {
    '& :last-child:hover': {
      transition: 'all .15s linear',
      color: theme.palette.statusColors.blue,
      textDecoration: 'none'
    }
  }
}))

type DocumentLinkProps = HTMLAttributes<HTMLDivElement> & {
  document: LisaDocument
  title?: string
  searchParam?: string
  searchPage?: boolean
  displayOnly?: boolean
  noDocType?: boolean
  documentNameClass?: string
}
export const DocumentLink: FC<DocumentLinkProps> = ({
  document,
  title = '',
  className,
  searchParam,
  displayOnly = false,
  noDocType = false,
  documentNameClass,
  ...props
}) => {
  const classes = useStyles({ displayOnly })
  const { openPDFDocument } = useTransitionContext()
  const ref = useRef<HTMLDivElement>(null)
  const [forcedTooltip, setForcedTooltip] = useState<string>('')

  const maybeForceTooltip = useCallback((element: HTMLDivElement) => {
    const hasOverflowingChildren = element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth
    if (hasOverflowingChildren) {
      setForcedTooltip(document.name)
    } else {
      setForcedTooltip('')
    }
  }, [])

  useEffect(() => {
    if (!ref.current) {
      return
    }
    const resizeObserver = new ResizeObserver(() => {
      if (ref.current) {
        maybeForceTooltip(ref.current)
      }
    })
    resizeObserver.observe(ref.current)
    return () => resizeObserver.disconnect()
  }, [ref.current])

  return (
    <div className={clsx(classes.documentLink, className, { [classes.hover]: !displayOnly })} {...props}>
      {
        !noDocType &&
        <AbbreviationTypeIcon
          docTypeRecognitionResultByPages={document.docTypeRecognitionResultByPages}
          docTypeRecognitionResult={document.docTypeRecognitionResult}/>
      }
      <Tooltip title={title !== '' ? title : forcedTooltip} style={{ marginLeft: 8, marginRight: 8 }}>
        <div
          ref={ref}
          className={documentNameClass}
          onClick={(e) => {
            !displayOnly && openPDFDocument(e, document.documentId, { searchParam })
          }}>
          {document.name}
        </div>
      </Tooltip>
    </div>
  )
}
