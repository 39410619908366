import { FC } from 'react'
import { DashboardSection } from './DashboardSection'
import { Grid } from '@material-ui/core'
import { DocumentStatusIndicator } from 'common/Indicators/DocumentStatusIndicator'
import { QueryCountIndicator } from 'common/Indicators/QueryCountIndicator'
import { TransitionName } from 'common/Names/TransitionName'
import { DocumentName } from 'common/Names/DocumentName'
import { LisaBox } from 'common/LisaBox/LisaBox'
import { useGetRecentDocumentsQuery } from 'services/api/documentApi'
import { useLisaAuth } from 'hooks/useLisaAuth'

export const RecentDocuments: FC = () => {
  const { userId } = useLisaAuth()
  const { data: recentDocuments = [], isLoading } = useGetRecentDocumentsQuery(userId)
  const hasResults = recentDocuments.length > 0
  return (
    <DashboardSection
      type={'documents'}
      title={'Recent files'}
      loading={isLoading}
      hasResults={hasResults}>
      <Grid item container spacing={1}>
        {
          recentDocuments.map((document) => (
            <Grid key={document.documentId} item xs={6}>
              <LisaBox
                container
                direction={'column'}
                style={{ height: 182 }}
                onClick={(e) => {
                  e.stopPropagation()
                  window.open(`/document/${document.documentId}?tId=${document.transitionId}`, '_blank')
                }}>
                <Grid item container justifyContent={'space-between'}>
                  <DocumentStatusIndicator status={document.status}/>
                  <QueryCountIndicator
                    queryCount={document.queriesCount}
                    closedQueryCount={document.closedQueriesCount}/>
                </Grid>
                <Grid item container>
                  <TransitionName transitionName={document.transitionName}/>
                </Grid>
                <Grid item style={{ height: 70, overflow: 'hidden' }}>
                  <DocumentName name={document.documentName} extension={document.extension}/>
                </Grid>
              </LisaBox>
            </Grid>
          ))
        }
      </Grid>
    </DashboardSection>
  )
}
