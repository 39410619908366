import { FC } from 'react'
import TableCell from '@material-ui/core/TableCell'
import { OCRDocumentType, sortByName, sortByOcrStatus, useOCRStatus } from 'redux/slices/ocrStatusSlice'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { useDispatch, useSelector } from 'react-redux'

type SortByFileNameCellProps = {
  ocrDocumentType: OCRDocumentType
}

export const SortByFileNameCell: FC<SortByFileNameCellProps> = ({ ocrDocumentType }) => {
  const { filters } = useSelector(useOCRStatus)
  const { fileName } = filters[ocrDocumentType]
  const dispatch = useDispatch()

  return (
    <TableCell
      align={'left'}
      padding={'none'}
      sortDirection={fileName}>
      <TableSortLabel
        active={fileName !== false}
        direction={fileName || 'asc'}
        onClick={() => dispatch(sortByName(ocrDocumentType))}>
        FILE NAME
      </TableSortLabel>
    </TableCell>
  )
}

type SortByOcrStatusCellProps = {
  ocrDocumentType: OCRDocumentType.Unsupported | OCRDocumentType.Unprocessed | OCRDocumentType.Incomplete
}
export const SortByOcrStatusCell: FC<SortByOcrStatusCellProps> = ({ ocrDocumentType }) => {
  const { filters } = useSelector(useOCRStatus)
  const { ocrStatus } = filters[ocrDocumentType]
  const dispatch = useDispatch()
  return (
    <TableCell
      align={'center'}
      padding={'none'}
      width={'100px'}
      sortDirection={ocrStatus}
      key={'ocrQuality'}>
      <TableSortLabel
        active={ocrStatus !== false}
        direction={ocrStatus || 'asc'}
        onClick={() => dispatch(sortByOcrStatus(ocrDocumentType))}>
        OCR STATUS
      </TableSortLabel>
    </TableCell>
  )
}
