import { FC, useMemo } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { WhitePaper } from 'common/WhitePaper/WhitePaper'
import { Users } from 'common/Users/Users'
import { formattedDate, getWeekNumber } from 'utils/date'
import { useGetTransitionQuery, useGetTransitionTeamQuery } from 'services/api/transitionApi'
import { useTransitionId } from 'context/TransitionContext'

// const dataTypesMock: {title: string, value: string}[] = [
//   { title: 'data type', value: 'VP-BLY / YU-ARB' },
//   { title: 'data type', value: 'A330-100F' },
//   { title: 'data type', value: 'Trent 772-B60' },
//   { title: 'DOM:', value: '10.12.2008' },
//   { title: 'data type', value: 'Lufthansa' },
//   { title: 'EIS', value: '01.10.2022' },
//   { title: 'data type:', value: 'John Doe' }
// ]
const dataTypesMock: {title: string, value: string}[] = []

const useStyles = makeStyles(() => ({
  wrapper: {
  },
  titleWrapper: {
    marginBottom: 6
  },
  transitionName: {
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  transitionDate: {
    fontSize: 14,
    fontWeight: 600,
    '& .fa-calendar-alt': {
      fontSize: 12
    }
  },
  dataTypes: {
    marginTop: 12,
    gap: 2
  },
  dataTypeBox: {
    maxWidth: 220,
    minWidth: 150,
    backgroundColor: '#e4eded',
    flex: 1,
    padding: '8px 12px'
  },
  dataTypeTitle: {
    fontSize: 12
  },
  dataTypeValue: {
    fontWeight: 800,
    fontSize: 14
  }
}))

export const TransitionHeader: FC = () => {
  const classes = useStyles()
  const transitionId = useTransitionId()
  const { data } = useGetTransitionQuery(transitionId)
  const { data: team = [] } = useGetTransitionTeamQuery(transitionId)
  const transition = data!
  const weekNumber = useMemo(getWeekNumber, [])
  return (
    <WhitePaper container item direction={'column'} alignItems={'stretch'} className={classes.wrapper}>
      <Grid container item justifyContent={'space-between'} className={classes.titleWrapper}>
        <Typography className={classes.transitionName}>
          W{weekNumber} Asset status report - {transition.name}
        </Typography>
        <Typography className={classes.transitionDate}><i className="fas fa-calendar-alt"/>&nbsp;{formattedDate()}</Typography>
      </Grid>
      <Grid item>
        <Users users={team}/>
      </Grid>
      <Grid item container className={classes.dataTypes}>
        {
          dataTypesMock.map((dt, index) => (
            <Grid key={dt.value + index} className={classes.dataTypeBox}>
              <div className={classes.dataTypeTitle}>{dt.title}</div>
              <div className={classes.dataTypeValue}>{dt.value}</div>
            </Grid>
          ))
        }
      </Grid>
    </WhitePaper>
  )
}
