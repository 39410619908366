import { FC, SVGProps } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  st0: {
    fill: '#E6ECEC'
  }
}))

export const TransparentLogo: FC<SVGProps<SVGSVGElement>> = (props) => {
  const classes = useStyles()
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 166 28"
      // style={{ enableBackground: 'new 0 0 166 28' }}
      // xml:space="preserve">
      {...props}>
      <g id="Group_2803" transform="translate(-41.769 -1020.4)">
        <rect id="Rectangle_2060" x="41.8" y="1020.4" className={classes.st0} width="5.5" height="27.5"/>
        <path id="Path_559" className={classes.st0} d="M53.6,1028.3h5.5v19.7h-5.5V1028.3"/>
        <path id="Path_560" className={classes.st0} d="M71.2,1048.4c-3.5,0-6.6-1.2-8.3-3.8l3.8-3.2c0.9,1.5,2.7,2.4,4.5,2.3c1.1,0,2.2-0.5,2.2-1.4
c0-0.8-0.5-1.3-2.7-1.9l-1.5-0.4c-3.7-0.9-5.6-3.2-5.5-6.3c0.1-3.6,3.3-5.9,7.6-5.9c2.8-0.2,5.4,1.1,7,3.3l-3.7,2.8
c-0.8-1.1-2.1-1.7-3.4-1.8c-1,0-2,0.5-2,1.3c0,0.6,0.3,1.3,2,1.7l1.9,0.6c3.6,0.9,5.8,2.7,5.8,6.1
C78.9,1045.8,75.5,1048.4,71.2,1048.4"/>
        <path id="Path_561" className={classes.st0} d="M90.2,1027.9c2.2,0,4.4,0.9,5.8,2.6v-2.2h5.5v19.7h-5.5v-2.2c-1.4,1.7-3.6,2.7-5.8,2.6
c-5.3,0-9.4-4.6-9.4-10.2C80.7,1032.5,84.9,1027.9,90.2,1027.9 M91.4,1032.8c-3.1,0-5.1,2.3-5.1,5.4c0,3.1,2.1,5.4,5.1,5.4
c3.1,0,5.1-2.3,5.1-5.4C96.4,1035,94.4,1032.8,91.4,1032.8"/>
        <path id="Path_562" className={classes.st0} d="M112,1028.3h2.8c1.2-0.1,2.2,0.7,2.4,1.8c0,0.1,0,0.3,0,0.4c0,1.1-0.9,2-2,2.1l3.1,3.6h-1.3
l-3.1-3.6H113v3.6h-1V1028.3z M114.7,1031.7c1,0,1.5-0.4,1.5-1.2s-0.6-1.2-1.5-1.2H113v2.4L114.7,1031.7"/>
        <path id="Path_563" className={classes.st0} d="M112,1028.3h2.8c1.2-0.1,2.2,0.7,2.4,1.8c0,0.1,0,0.3,0,0.4c0,1.1-0.9,2-2,2.1l3.1,3.6h-1.3
l-3.1-3.6H113v3.6h-1V1028.3z M114.7,1031.7c1,0,1.5-0.4,1.5-1.2s-0.6-1.2-1.5-1.2H113v2.4L114.7,1031.7z"/>
        <path id="Path_564" className={classes.st0}
          d="M120.9,1028.3h4.8v1h-3.7v2.4h3v1h-3v2.6h3.6v1h-4.8L120.9,1028.3L120.9,1028.3z"/>
        <path id="Path_565" className={classes.st0}
          d="M120.9,1028.3h4.8v1h-3.7v2.4h3v1h-3v2.6h3.6v1h-4.8L120.9,1028.3L120.9,1028.3z"/>
        <path id="Path_566" className={classes.st0} d="M132.2,1028.2c1.5,0,2.9,0.7,3.6,1.9l-0.9,0.6c-0.6-0.9-1.6-1.5-2.7-1.5c-1.7,0-3.1,1.3-3.1,3
c0,0,0,0,0,0.1c0,1.7,1.4,3.1,3.1,3.1c1.1,0,2.1-0.6,2.7-1.5l0.9,0.6c-0.8,1.2-2.1,2-3.6,1.9c-2.3,0-4.2-1.8-4.2-4.1
C128,1030,129.9,1028.2,132.2,1028.2"/>
        <path id="Path_567" className={classes.st0} d="M132.2,1028.2c1.5,0,2.9,0.7,3.6,1.9l-0.9,0.6c-0.6-0.9-1.6-1.5-2.7-1.5c-1.7,0-3.1,1.3-3.1,3
c0,0,0,0,0,0.1c0,1.7,1.4,3.1,3.1,3.1c1.1,0,2.1-0.6,2.7-1.5l0.9,0.6c-0.8,1.2-2.1,2-3.6,1.9c-2.3,0-4.2-1.8-4.2-4.1
C128,1030,129.9,1028.2,132.2,1028.2L132.2,1028.2z"/>
        <path id="Path_568" className={classes.st0} d="M142.5,1036.3c-2.3,0-4.2-1.8-4.2-4.1c0-2.3,1.9-4.1,4.2-4.1l0,0c2.3,0,4.2,1.7,4.2,3.9
c0,0.1,0,0.1,0,0.2C146.7,1034.5,144.9,1036.3,142.5,1036.3C142.5,1036.3,142.5,1036.3,142.5,1036.3 M142.5,1035.3
c1.7,0,3.1-1.3,3.1-3c0,0,0,0,0-0.1c0.1-1.6-1.3-3-3-3.1c-0.1,0-0.1,0-0.2,0c-1.7,0-3.1,1.3-3.1,3c0,0,0,0,0,0.1
c-0.1,1.6,1.3,3,3,3.1C142.4,1035.3,142.5,1035.3,142.5,1035.3"/>
        <path id="Path_569" className={classes.st0} d="M142.5,1036.3c-2.3,0-4.2-1.8-4.2-4.1c0-2.3,1.9-4.1,4.2-4.1l0,0c2.3,0,4.2,1.7,4.2,3.9
c0,0.1,0,0.1,0,0.2C146.7,1034.5,144.9,1036.3,142.5,1036.3C142.5,1036.3,142.5,1036.3,142.5,1036.3z M142.5,1035.3
c1.7,0,3.1-1.3,3.1-3c0,0,0,0,0-0.1c0.1-1.6-1.3-3-3-3.1c-0.1,0-0.1,0-0.2,0c-1.7,0-3.1,1.3-3.1,3c0,0,0,0,0,0.1
c-0.1,1.6,1.3,3,3,3.1C142.4,1035.3,142.5,1035.3,142.5,1035.3z"/>
        <path id="Path_570" className={classes.st0} d="M150,1028.3h2.8c1.2-0.1,2.2,0.7,2.4,1.8c0,0.1,0,0.3,0,0.4c0,1.1-0.9,2-2,2.1l3.1,3.6h-1.3
l-3.2-3.6H151v3.6h-1V1028.3z M152.5,1031.7c1,0,1.5-0.4,1.5-1.2s-0.6-1.2-1.5-1.2h-1.6v2.4L152.5,1031.7"/>
        <path id="Path_571" className={classes.st0} d="M150,1028.3h2.8c1.2-0.1,2.2,0.7,2.4,1.8c0,0.1,0,0.3,0,0.4c0,1.1-0.9,2-2,2.1l3.1,3.6h-1.3
l-3.2-3.6H151v3.6h-1V1028.3z M152.5,1031.7c1,0,1.5-0.4,1.5-1.2s-0.6-1.2-1.5-1.2h-1.6v2.4L152.5,1031.7z"/>
        <path id="Path_572" className={classes.st0} d="M158.8,1028.3h2.3c2.2-0.1,4.2,1.5,4.3,3.7c0,0.1,0,0.2,0,0.3c0,2.2-1.9,4-4.1,4
c-0.1,0-0.1,0-0.2,0h-2.3V1028.3z M161.1,1035.2c1.7,0.1,3.1-1.1,3.2-2.7c0-0.1,0-0.2,0-0.3c0-1.6-1.4-3-3.1-3c-0.1,0-0.1,0-0.2,0
h-1.2v5.9L161.1,1035.2"/>
        <path id="Path_573" className={classes.st0} d="M158.8,1028.3h2.3c2.2-0.1,4.2,1.5,4.3,3.7c0,0.1,0,0.2,0,0.3c0,2.2-1.9,4-4.1,4
c-0.1,0-0.1,0-0.2,0h-2.3V1028.3z M161.1,1035.2c1.7,0.1,3.1-1.1,3.2-2.7c0-0.1,0-0.2,0-0.3c0-1.6-1.4-3-3.1-3c-0.1,0-0.1,0-0.2,0
h-1.2v5.9L161.1,1035.2z"/>
        <path id="Path_574" className={classes.st0} d="M168.8,1034.3c0.4,0.7,1.2,1.1,2,1.1c0.9,0,1.5-0.5,1.5-1.2c0-0.6-0.4-1-1.1-1.3l-1-0.4
c-1.2-0.5-1.8-1.1-1.8-2.2c0-1.3,1-2.1,2.6-2.1c0.9,0,1.8,0.4,2.4,1.1l-0.8,0.6c-0.4-0.5-1-0.8-1.6-0.7c-0.7-0.1-1.3,0.4-1.4,1.1
c0,0.6,0.4,0.9,1.2,1.3l1,0.4c1.2,0.5,1.9,1.1,1.9,2.3c0,1.2-1,2.2-2.3,2.2c-0.1,0-0.2,0-0.3,0c-1.1,0-2.2-0.5-2.9-1.4
L168.8,1034.3z"/>
        <path id="Path_575" className={classes.st0} d="M168.8,1034.3c0.4,0.7,1.2,1.1,2,1.1c0.9,0,1.5-0.5,1.5-1.2c0-0.6-0.4-1-1.1-1.3l-1-0.4
c-1.2-0.5-1.8-1.1-1.8-2.2c0-1.3,1-2.1,2.6-2.1c0.9,0,1.8,0.4,2.4,1.1l-0.8,0.6c-0.4-0.5-1-0.8-1.6-0.7c-0.7-0.1-1.3,0.4-1.4,1.1
c0,0.6,0.4,0.9,1.2,1.3l1,0.4c1.2,0.5,1.9,1.1,1.9,2.3c0,1.2-1,2.2-2.3,2.2c-0.1,0-0.2,0-0.3,0c-1.1,0-2.2-0.5-2.9-1.4
L168.8,1034.3z"/>
        <path id="Path_576" className={classes.st0}
          d="M113,1041.8v6.1h-1v-7.9h1l3.1,3.9l3.1-3.9h1v7.9h-1v-6.1l-3,3.8L113,1041.8z"/>
        <path id="Path_577" className={classes.st0}
          d="M113,1041.8v6.1h-1v-7.9h1l3.1,3.9l3.1-3.9h1v7.9h-1v-6.1l-3,3.8L113,1041.8z"/>
        <path id="Path_578" className={classes.st0} d="M126.3,1040.1h0.9l3.6,7.9h-1.1l-1-2.3h-3.8l-1,2.3h-1.1L126.3,1040.1z M128.2,1044.7l-1.4-3.2
l-1.4,3.2H128.2z"/>
        <path id="Path_579" className={classes.st0} d="M126.3,1040.1h0.9l3.6,7.9h-1.1l-1-2.3h-3.8l-1,2.3h-1.1L126.3,1040.1z M128.2,1044.7l-1.4-3.2
l-1.4,3.2H128.2z"/>
        <path id="Path_580" className={classes.st0} d="M139.6,1047.9l-5-6.1v6.1h-1v-7.9h1l5,6.1v-6.1h1v7.9H139.6z"/>
        <path id="Path_581" className={classes.st0} d="M139.6,1047.9l-5-6.1v6.1h-1v-7.9h1l5,6.1v-6.1h1v7.9H139.6z"/>
        <path id="Path_582" className={classes.st0} d="M146.8,1040.1h0.9l3.6,7.9h-1.1l-1-2.3h-3.8l-1,2.3h-1.1L146.8,1040.1z M148.7,1044.7l-1.4-3.2
l-1.4,3.2H148.7z"/>
        <path id="Path_583" className={classes.st0} d="M146.8,1040.1h0.9l3.6,7.9h-1.1l-1-2.3h-3.8l-1,2.3h-1.1L146.8,1040.1z M148.7,1044.7l-1.4-3.2
l-1.4,3.2H148.7z"/>
        <path id="Path_584" className={classes.st0} d="M157.7,1048c-2.3,0.1-4.3-1.6-4.4-3.8c0-0.1,0-0.2,0-0.3c0-2.2,1.8-4,4-4.1c0.1,0,0.1,0,0.2,0
c1.1,0,2.2,0.4,3.1,1.1l-0.7,0.7c-0.6-0.6-1.5-0.9-2.4-0.8c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.2,3c0.1,0,0.1,0,0.2,0
c0.8,0,1.5-0.2,2.2-0.6v-2.1h-2.3v-0.9h3.3v3.5C160,1047.7,158.9,1048,157.7,1048"/>
        <path id="Path_585" className={classes.st0} d="M157.7,1048c-2.3,0.1-4.3-1.6-4.4-3.8c0-0.1,0-0.2,0-0.3c0-2.2,1.8-4,4-4.1c0.1,0,0.1,0,0.2,0
c1.1,0,2.2,0.4,3.1,1.1l-0.7,0.7c-0.6-0.6-1.5-0.9-2.4-0.8c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.2,3c0.1,0,0.1,0,0.2,0
c0.8,0,1.5-0.2,2.2-0.6v-2.1h-2.3v-0.9h3.3v3.5C160,1047.6,158.9,1048,157.7,1048z"/>
        <path id="Path_586" className={classes.st0} d="M164.2,1040.1h4.8v1h-3.6v2.4h3v1h-3v2.6h3.6v0.8h-4.8V1040.1z"/>
        <path id="Path_587" className={classes.st0} d="M164.2,1040.1h4.8v1h-3.6v2.4h3v1h-3v2.6h3.6v0.8h-4.8V1040.1z"/>
        <path id="Path_588" className={classes.st0}
          d="M173.2,1041.8v6.1h-1v-7.9h1l3.1,3.9l3.1-3.9h1v7.9h-1v-6.1l-3,3.8L173.2,1041.8z"/>
        <path id="Path_589" className={classes.st0}
          d="M173.2,1041.8v6.1h-1v-7.9h1l3.1,3.9l3.1-3.9h1v7.9h-1v-6.1l-3,3.8L173.2,1041.8z"/>
        <path id="Path_590" className={classes.st0} d="M183.9,1040.1h4.8v1h-3.6v2.4h3v1h-3v2.6h3.6v0.8h-4.8V1040.1z"/>
        <path id="Path_591" className={classes.st0} d="M183.9,1040.1h4.8v1h-3.6v2.4h3v1h-3v2.6h3.6v0.8h-4.8V1040.1z"/>
        <path id="Path_592" className={classes.st0} d="M197.9,1047.9l-5-6.1v6.1h-1v-7.9h1l5,6.1v-6.1h1v7.9H197.9z"/>
        <path id="Path_593" className={classes.st0} d="M197.9,1047.9l-5-6.1v6.1h-1v-7.9h1l5,6.1v-6.1h1v7.9H197.9z"/>
        <path id="Path_594" className={classes.st0} d="M204.2,1041h-2.6v-1h6.1v1h-2.6v7h-1L204.2,1041L204.2,1041z"/>
        <path id="Path_595" className={classes.st0} d="M204.2,1041h-2.6v-1h6.1v1h-2.6v7h-1L204.2,1041L204.2,1041z"/>
      </g>
    </svg>

  )
}
