import { FC, ReactElement, ReactNode } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { WhitePaper } from 'common/WhitePaper/WhitePaper'
import { Note, NoteProps } from 'common/Note/Note'
import { HeightProperty } from 'csstype'
import { InfoIndicator } from 'common/Indicators/InfoIndicator'

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    height: '100%'
  },
  title: {
    fontSize: 18,
    fontWeight: 600
  },
  chartContainer: {
    padding: 10,
    border: '2px solid #e8e8e8',
    marginBlock: 12
  },
  info: {
    marginLeft: 8
  }
}))

type ReportProps = {
  title: ReactNode
  info?: string
  filters?: ReactElement,
  chartHeight?: HeightProperty<number>,
  reportNote?: NoteProps
}
export const Report: FC<ReportProps> = ({
  children,
  title,
  info,
  filters,
  chartHeight = 350,
  reportNote
}) => {
  const classes = useStyles()
  return (
    <WhitePaper container item direction={'column'} className={classes.wrapper}>
      <Grid item container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item xs={6} className={classes.title}>{title}</Grid>
        <Grid item xs={6} container alignItems={'center'} justifyContent={'flex-end'}>
          { filters && <Grid item>{filters}</Grid> }
          { info && <InfoIndicator className={classes.info} info={info}/> }
        </Grid>
      </Grid>
      <Grid container item className={classes.chartContainer} style={{ height: chartHeight }}>
        {children}
      </Grid>
      {reportNote && <Note {...reportNote}/>}
    </WhitePaper>
  )
}
