import { FC, useCallback, useState } from 'react'
import { DropDownOnChangeEvent, QueryStatus } from 'types'
import { queryStatusOptions } from 'utils/queries'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { RoleName } from 'utils/userRoleSecurity'
import Dropdown from 'common/LisaControls/Dropdown'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import Input from 'common/LisaControls/Input'
import { useDispatch } from 'react-redux'
import { closeModal, openModal } from 'redux/slices/appSlice'
import { QueryStatusChip } from 'components/Query/QueryStatusChip'

const useStyles = makeStyles(() => ({
  toggleModeContainer: {
    '& .MuiChip-root': {
      marginRight: 4
    }
  }
}))

type QueryStatusSelectProps = {
  label?: string
  labelClassName?: string,
  value: QueryStatus | '',
  onChange?: (value: QueryStatus | '', wotc?: string) => void
  onChangeEvent?: (e: DropDownOnChangeEvent) => void
  showSelectOption?: boolean
  showUnresolvedOption?: boolean
  ignoreRoles?: boolean
  isQueryReportedByMe?: boolean
  disabled?: boolean
  wotc?: string // If string && status is workpack -> open wotc modal; Does not support onChangeEvent,
  mode?: 'dropdown' | 'toggle'
}
export const QueryStatusSelect: FC<QueryStatusSelectProps> = ({
  value,
  onChange,
  onChangeEvent,
  label = 'Query status',
  showSelectOption = false,
  showUnresolvedOption = false,
  ignoreRoles = false,
  isQueryReportedByMe = false,
  disabled = false,
  wotc,
  mode = 'dropdown'
}) => {
  const { hasRole } = useLisaAuth()
  const [queryStatus, setQueryStatus] = useState(value)
  const [_wotc, setWotc] = useState(wotc)
  const dispatch = useDispatch()
  const classes = useStyles()

  const canClose = ignoreRoles || hasRole(RoleName.ProgramManager) || hasRole(RoleName.TechAdmin) || isQueryReportedByMe
  const canSideletter = ignoreRoles || hasRole(RoleName.ProgramManager)
  const items = queryStatusOptions
    .filter(_ => _.value === '' || Object.values(QueryStatus).includes(_.value))
    .filter(_ => showUnresolvedOption || _.value !== 0)
    .filter(_ => _.value !== QueryStatus.Closed || canClose)
    .map(_ => _.value !== QueryStatus.Sideletter
      ? _
      : {
        ..._,
        disabled: !canSideletter
      })

  const onQSToggle = useCallback((qs: QueryStatus) => {
    if (qs === queryStatus) {
      return
    }
    setQueryStatus(qs)
    if (qs === QueryStatus.WorkPack && wotc !== undefined && onChange) {
      dispatch(openModal('CHANGE_WORKPACK_REFERENCE'))
    } else {
      onChange && onChange(qs)
    }
  }, [wotc])

  return (
    <>
      {
        mode === 'dropdown' &&
        <Dropdown
          label={label}
          name="queryStatus"
          showSelectOption={showSelectOption}
          items={items}
          value={value}
          disabled={disabled}
          onChange={(e) => {
            const qs = e.target.value as QueryStatus
            setQueryStatus(qs)
            if (qs === QueryStatus.WorkPack && wotc !== undefined && onChange) {
              dispatch(openModal('CHANGE_WORKPACK_REFERENCE'))
            } else {
              onChange && onChange(qs)
              onChangeEvent && onChangeEvent(e)
            }
          }}
        />
      }
      {
        mode === 'toggle' &&
        <Grid container className={classes.toggleModeContainer} alignItems={'center'}>
          <Typography variant={'body2'}>
            {label}
          </Typography>
          <QueryStatusChip
            onClick={() => onQSToggle(QueryStatus.New)}
            queryStatus={QueryStatus.New}
            clickable
            active={queryStatus === QueryStatus.New}/>
          <QueryStatusChip
            onClick={() => onQSToggle(QueryStatus.Open)}
            queryStatus={QueryStatus.Open}
            clickable
            active={queryStatus === QueryStatus.Open}/>
          <QueryStatusChip
            onClick={() => onQSToggle(QueryStatus.Pending)}
            queryStatus={QueryStatus.Pending}
            clickable
            active={queryStatus === QueryStatus.Pending}/>
          <QueryStatusChip
            onClick={() => onQSToggle(QueryStatus.WorkPack)}
            queryStatus={QueryStatus.WorkPack}
            clickable
            active={queryStatus === QueryStatus.WorkPack}/>
          <QueryStatusChip
            onClick={() => onQSToggle(QueryStatus.Delayed)}
            queryStatus={QueryStatus.Delayed}
            clickable
            active={queryStatus === QueryStatus.Delayed}/>
          <QueryStatusChip
            onClick={() => onQSToggle(QueryStatus.Closed)}
            queryStatus={QueryStatus.Closed}
            clickable
            active={queryStatus === QueryStatus.Closed}/>
          <QueryStatusChip
            onClick={() => onQSToggle(QueryStatus.Sideletter)}
            queryStatus={QueryStatus.Sideletter}
            clickable
            active={queryStatus === QueryStatus.Sideletter}/>
        </Grid>

      }
      {
        wotc !== undefined &&
        <LisaModal
          onCancel={() => setQueryStatus(value)}
          maxWidth={'xs'}
          modalType={'CHANGE_WORKPACK_REFERENCE'}
          title={'Update WO/TC reference'}>
          <Grid container direction="column" spacing={1} style={{ marginBlock: 8 }}>
            <Grid item>
              <Input
                label="WO/TC reference *"
                name="wotc"
                value={_wotc ?? ''}
                onChange={(e) => setWotc(e.target.value)}
              />
            </Grid>
            <Grid item container spacing={1}>
              <Grid item>
                <Button
                  disabled={!_wotc}
                  onClick={() => {
                    if (_wotc) {
                      onChange && onChange(queryStatus, _wotc)
                      dispatch(closeModal())
                    }
                  }}
                  variant="contained"
                  color="primary"
                  size="small">
                  Confirm
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    setWotc(wotc)
                    setQueryStatus(value)
                    dispatch(closeModal())
                  }}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </LisaModal>
      }
    </>
  )
}
