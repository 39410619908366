import { Children, cloneElement, forwardRef, ReactElement } from 'react'
import { Tooltip } from '@material-ui/core'

type MaybeForbiddenActionProps = {
  isForbidden: boolean,
  children: ReactElement,
  tooltip?: string
}
export const MaybeForbiddenAction = forwardRef<HTMLElement, MaybeForbiddenActionProps>((props, ref) => {
  const { children, isForbidden, tooltip } = props
  if (!children) {
    return null
  }
  if (!isForbidden) {
    const childrenWithRef = Children.map(children, (child) =>
      cloneElement<HTMLFormElement>(child, { ref }))
    if (!tooltip) {
      return (<>{childrenWithRef}</>)
    }
    return (
      <Tooltip ref={ref} title={tooltip} placement={'bottom'}>
        {children}
      </Tooltip>
    )
  }
  return (
    <Tooltip ref={ref} title={'Forbidden action'} placement="bottom-start">
      <div>
        {
          Children.map(children, (child) =>
            cloneElement<HTMLFormElement>(child, { disabled: true, onChange: () => {} }))
        }
      </div>
    </Tooltip>
  )
})
MaybeForbiddenAction.displayName = 'MaybeForbiddenAction'
