import { FC, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { MinimalButton, Spinner, TextBox } from '@react-pdf-viewer/core'
import { Match, NextIcon, PreviousIcon, RenderSearchProps, SearchPlugin } from '@react-pdf-viewer/search'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { LoadingState } from 'types'
import { isInViewportOf } from 'utils/dom'
import { AbbreviationTypeIcon } from 'common/AbbreviationTypeIcon/AbbreviationTypeIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBlock: 16,
    color: theme.palette.common.black,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.15)',
    width: 230,
    '& .rpv-core__textbox:focus-visible': {
      borderColor: theme.palette.blue.main
    },
    '& .rpv-core__icon': {
      color: `${theme.palette.common.black} !important`
    }
  },
  resultsInfo: {
    marginTop: 16,
    paddingInline: 16,
    marginBottom: 8,
    fontSize: 14,
    '& .rpv-core__minimal-button': {
      padding: 6
    },
    '& .rpv-core__icon': {
      width: 16
    }
  },
  resultsContainer: {
    paddingInline: 8,
    borderTop: `1px solid ${theme.palette.grey2.dark}`,
    paddingTop: 16
  },
  resultsItem: {
    width: '100%'
  },
  results: {
    paddingInline: 8,
    // height: 600,
    maxHeight: '60vh',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      height: '3px',
      width: '3px',
      background: 'transparent'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.6)',
      borderRadius: '1.5px'
    }
  },
  pageNumber: {
    fontSize: 16,
    textAlign: 'right'
  },
  resultBox: {
    border: `1px solid ${theme.palette.grey2.dark}`,
    borderRadius: 2,
    cursor: 'pointer',
    overflowWrap: 'anywhere',
    padding: 8,
    marginTop: 8,
    marginBottom: 16,
    color: theme.palette.black.main,
    backgroundColor: theme.palette.common.white
  },
  resultBoxCurrent: {
    backgroundColor: theme.palette.grey2.light100
  },
  highlighted: {
    backgroundColor: theme.palette.lime.main
  },
  spinner: {
    alignItems: 'center',
    display: 'flex',
    bottom: 0,
    position: 'absolute',
    right: 20,
    top: 0
  }
}))

enum SearchStatus {
  NotSearchedYet,
  Searching,
  FoundResults,
}

interface SearchSidebarProps {
  searchPluginInstance: SearchPlugin
  defaultKeyword?: string
  documentTypesPerPage?: string[]
}

export const PdfSearchPopover: FC<SearchSidebarProps> = ({
  searchPluginInstance, defaultKeyword, documentTypesPerPage = []
}) => {
  const classes = useStyles()
  const [searchStatus, setSearchStatus] = useState(SearchStatus.NotSearchedYet)
  const [matches, setMatches] = useState<Match[]>([])

  const { Search } = searchPluginInstance

  const renderMatchSample = (match: Match) => {
    const wordsBefore = match.pageText.slice(match.startIndex - 20, match.startIndex)
    let words = wordsBefore.split(' ')
    words.shift()
    const begin = words.length === 0 ? wordsBefore : words.join(' ')

    const wordsAfter = match.pageText.slice(match.endIndex, match.endIndex + 60)
    words = wordsAfter.split(' ')
    words.pop()
    const end = words.length === 0 ? wordsAfter : words.join(' ')

    return (
      <div>
        {begin}
        <span className={classes.highlighted}>
          {match.pageText.substring(match.startIndex, match.endIndex)}
        </span>
        {end}
      </div>
    )
  }

  return (
    <Search>
      {(renderSearchProps: RenderSearchProps) => {
        const { currentMatch, keyword, setKeyword, jumpToMatch, jumpToNextMatch, jumpToPreviousMatch, search, clearKeyword } =
          renderSearchProps

        const [initialSearch, setInitialSearch] = useState<LoadingState>(LoadingState.Init)
        const resultsRef = useRef<HTMLDivElement>(null)

        const doSearch = () => {
          setSearchStatus(SearchStatus.Searching)
          if (!keyword) {
            clearKeyword()
            setSearchStatus(SearchStatus.NotSearchedYet)
          } else {
            search().then((matches) => {
              setSearchStatus(SearchStatus.FoundResults)
              setMatches(matches)
            })
          }
        }

        useEffect(() => {
          if (defaultKeyword) {
            setKeyword(defaultKeyword)
            setInitialSearch(LoadingState.Pending)
          }
        }, [])

        useEffect(() => {
          if (initialSearch === LoadingState.Pending) {
            doSearch()
            setInitialSearch(LoadingState.Completed)
          }
        }, [initialSearch])

        const searchCompleted = searchStatus === SearchStatus.FoundResults
        const noResults = searchCompleted && matches.length === 0
        const hasResults = searchCompleted && matches.length > 0

        useEffect(() => {
          const currentMatchEl = document.getElementsByClassName(`result-box-${currentMatch}`)?.item(0)
          if (!isInViewportOf(currentMatchEl, resultsRef.current)) {
            currentMatchEl?.scrollIntoView(false)
          }
        }, [currentMatch])

        const handleSearchKeyDown = (e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            doSearch()
          }
        }
        return (
          <Grid
            container
            direction={'column'}
            className={classes.root}>
            <Grid item>
              <div id={'search-text-box-wrapper'} style={{ position: 'relative', paddingInline: 16 }}>
                <TextBox
                  autoFocus
                  placeholder="Enter to search"
                  value={keyword}
                  onChange={setKeyword}
                  onKeyDown={handleSearchKeyDown}
                />
                {
                  searchStatus === SearchStatus.Searching &&
                  <div className={classes.spinner}>
                    <Spinner size="1.5rem" />
                  </div>
                }
              </div>
            </Grid>
            {
              noResults &&
                <Grid item className={classes.resultsInfo}>
                  No results
                </Grid>
            }
            {
              hasResults &&
                <Grid container direction={'column'}>
                  <Grid
                    item
                    container
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    className={classes.resultsInfo}>
                    <Grid item xs={8}>
                      Found {matches.length} results
                    </Grid>
                    <Grid item xs={4} container justifyContent={'space-evenly'}>
                      <MinimalButton onClick={jumpToPreviousMatch}>
                        <PreviousIcon />
                      </MinimalButton>
                      <MinimalButton onClick={jumpToNextMatch}>
                        <NextIcon />
                      </MinimalButton>
                    </Grid>
                  </Grid>

                  <Grid item className={classes.resultsContainer} ref={resultsRef}>
                    <Grid
                      container
                      className={classes.results}>
                      {
                        matches.map((match, index) => (
                          <Grid
                            item
                            key={`result-${index}`}
                            className={clsx(`result-box-${index + 1}`, classes.resultsItem)}>
                            <Grid container direction={'column'}>
                              <Grid item container justifyContent={'space-between'}>
                                <Grid item>{index + 1}</Grid>
                                <Grid item>
                                  {
                                    documentTypesPerPage[match.pageIndex] &&
                                      <AbbreviationTypeIcon
                                        docTypeRecognitionResult={documentTypesPerPage[match.pageIndex]}/>
                                  }
                                </Grid>
                                <Grid item>
                                    Pg. {match.pageIndex + 1}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                className={clsx(
                                  classes.resultBox, { [classes.resultBoxCurrent]: currentMatch === index + 1 }
                                )}
                                onClick={() => jumpToMatch(index + 1)}>
                                <Typography variant={'body2'} component={'div'}>{renderMatchSample(match)}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
            }
          </Grid>
        )
      }}
    </Search>
  )
}
