import { FC } from 'react'
import { Icon, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'rotate(-45deg)',
    color: theme.palette.statusColors.red,
    fontSize: 16
  }
}))

export const HighPriorityIndicator: FC = () => {
  const classes = useStyles()
  return (
    <Icon
      fontSize='small'
      className={clsx(classes.root, 'fas fa-long-arrow-alt-right')}/>
  )
}
