import { ReactNode, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, IconButton, ListItemIcon, makeStyles, MenuItem, Tooltip } from '@material-ui/core'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import * as Icons from 'common/Icons/SvgIcons'
import { setHierarchy, toggleEmptyLabels, useA2KLabels, labelAdded } from 'redux/slices/a2kLabelsSlice'
import { A2KDocuments } from './components/A2KDocuments'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { MaybeForbiddenAction } from 'common/MaybeForbiddenAction/MaybeForbiddenAction'
import { openModal } from 'redux/slices/appSlice'
import { LisaPaper } from 'common/LisaPaper/LisaPaper'
import { GridGrow } from 'common/Grid/GridGrow'
import { A2KModals } from './components/A2KModals'
import { WithLabelsLoader } from 'common/WithLoader/WithLoader'
import { SearchDocuments } from 'components/Transitions/SearchDocuments'
import { useHistory } from 'react-router-dom'
import { LabelSearchAutoComplete } from 'common/LabelSearch/LabelSearchAutoComplete'
import { LabelsProvider, LabelsType } from 'context/LabelsContext'
import { LabelTree } from 'common/LabelTree/LabelTree'
import { useTransitionId } from 'context/TransitionContext'
import { useGetA2KLabelsQuery } from 'services/api/labellingApi'
import { setHierarchy as setReassignLabelsHierarchy } from 'redux/slices/reassignLabelsSlice'
import { DocumentRemoval } from 'common/DocumentRemoval/DocumentRemoval'
const useStyles = makeStyles(() => ({
  toolbarContainer: {
    paddingLeft: 8
  },
  autocompleteContainer: {
    paddingRight: 16
  }
}))

const Atok = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    selected: label,
    hierarchy,
    emptyLabels
  } = useSelector(useA2KLabels)
  const hasLabels = hierarchy !== null && hierarchy.children.length > 0
  const isAmosLabel = !!label?.workpackageUuid || !!label?.eventUuid || !!label?.workorderUuid
  const { hasAccess } = useLisaAuth()
  const canViewA2K = hasAccess('perm_view_a2kstructure')
  if (!canViewA2K) {
    // @todo DRY - Not sure where is best place
    history.push(hasAccess('perm_view_query') ? '/dashboard' : '/dashboard/my-transitions')
  }
  const canManageA2KLabels = hasAccess('perm_act_a2kadmin')
  const isActionDisabled = !canManageA2KLabels || label === null || isAmosLabel
  const transitionId = useTransitionId()
  const { data: a2kLabels, fulfilledTimeStamp, isFetching } = useGetA2KLabelsQuery({ transitionId, showAll: emptyLabels })
  useEffect(() => {
    if (a2kLabels) {
      dispatch(setHierarchy(a2kLabels))
      dispatch(setReassignLabelsHierarchy(a2kLabels))
    }
  }, [fulfilledTimeStamp])

  const items = useMemo<ReactNode>(() => {
    return [
      <MaybeForbiddenAction key='add-sub-label' isForbidden={!canManageA2KLabels}>
        <MenuItem
          disabled={isActionDisabled || !emptyLabels}
          onClick={() => dispatch(openModal('NEW_A2K_LABEL'))}>
          <ListItemIcon>
            <Icons.AddIco/>
          </ListItemIcon>
          Add sub-label
        </MenuItem>
      </MaybeForbiddenAction>,
      <MaybeForbiddenAction key='rename label' isForbidden={!canManageA2KLabels}>
        <MenuItem
          disabled={isActionDisabled || label.level! <= 1 || !emptyLabels}
          onClick={() => dispatch(openModal('RENAME_A2K_LABEL'))}>
          <ListItemIcon>
            <Icons.ReplaceIco/>
          </ListItemIcon>
          Rename label
        </MenuItem>
      </MaybeForbiddenAction>,
      <MaybeForbiddenAction key='delete-label' isForbidden={!canManageA2KLabels}>
        <MenuItem
          disabled={isActionDisabled || label.level! <= 1 || !emptyLabels}
          onClick={() => dispatch(openModal('DELETE_A2K_LABEL'))}>
          <ListItemIcon>
            <Icons.DeleteIco/>
          </ListItemIcon>
          Delete label
        </MenuItem>
      </MaybeForbiddenAction>
    ]
  }, [emptyLabels, isActionDisabled, canManageA2KLabels, label?.level])

  const title = (
    <Grid className={classes.autocompleteContainer}>
      <LabelSearchAutoComplete
        onLabelsSelect={(labels) => {
          const [label] = labels
          if (label) {
            dispatch(labelAdded(label))
          }
        }}/>
    </Grid>
  )

  const option = useMemo(() => {
    return <Tooltip title={emptyLabels ? 'Hide empty labels' : 'Show all labels'}>
      <IconButton onClick={() => dispatch(toggleEmptyLabels())}>
        { !emptyLabels ? <Icons.ShowAllLabels/> : <Icons.HideEmptyLabels/> }
      </IconButton>
    </Tooltip>
  }, [emptyLabels])

  return (
    <ContentWrapper newLayout>
      <GridGrow container direction="column">
        <Grid item container direction="row">
          <Grid item style={{ flex: 1 }}>
            <SearchDocuments/>
          </Grid>
          <Grid item>
            <DocumentRemoval />
          </Grid>
        </Grid>
        <GridGrow container item className={'columnGap1'}>
          <Grid
            // Left Side
            item
            container
            direction='column'
            xs={5}
            lg={4}>
            <LabelsProvider labelsType={LabelsType.A2K}>
              <LisaPaper toolbar={{ items, title, option }} classes={{ toolbarContainer: classes.toolbarContainer }}>
                <WithLabelsLoader
                  loading={isFetching}
                  loadingText={'Loading file tree data...'}
                  hasResults={hasLabels}>
                  <LabelTree renderSearchResultsTree/>
                </WithLabelsLoader>
              </LisaPaper>
            </LabelsProvider>
          </Grid>
          <GridGrow
            // Right side
            item
            container
            xs={7}
            lg={8}>
            <A2KDocuments/>
          </GridGrow>
        </GridGrow>
      </GridGrow>
      <A2KModals/>
    </ContentWrapper>

  )
}

export default Atok
