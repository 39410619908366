import { FC, ReactNode } from 'react'
import { Grid, Typography } from '@material-ui/core'

export type FormFieldProps = {
  label?: ReactNode
  required?: boolean
  error?: string
}
export const FormField: FC<FormFieldProps> = ({
  label = '',
  required = false,
  children,
  error
}) => {
  return (
    <Grid container direction={'column'} justifyContent={'flex-end'}>
      { label && <Typography variant='body2'>{label}&nbsp;{`${required ? '*' : ''}`}</Typography> }
      { children }
      {error && <Typography variant='body2' color='error'>{error}</Typography>}
    </Grid>
  )
}
