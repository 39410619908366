import { FC, useEffect, useState } from 'react'
import { LinearProgress, makeStyles, Tooltip, Typography } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import { useTransitionId } from 'context/TransitionContext'
import { useCheckImportProgressQuery, useGetImportFileTreeQuery } from 'services/api/importApi'
import { useDispatch, useSelector } from 'react-redux'
import { openToast } from 'redux/slices/appSlice'
import { useImportDocuments } from 'redux/slices/importDocumentsSlice'

const useStyles = makeStyles((theme) => ({
  treeViewLoader: {
    width: '100%',
    height: '100px',
    margin: '6px',
    borderRadius: '4px',
    background: theme.palette.statusColors.bluelight,
    borderBottom: `1px solid ${theme.palette.grey2.main}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '12px 16px',
    '& .dropdown': {
      width: 200
    },
    '& .header': {
      color: theme.palette.black,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .title': {
        fontSize: '14px',
        fontWeight: '600',
        textTransform: 'uppercase'
      },
      '& .right': {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.statusColors.blue,
        '& .refresh': {
          fontSize: '14px',
          marginRight: '6px',
          '&:hover': {
            cursor: 'pointer',
            color: theme.palette.black.main
          }
        },
        '& .percent': {
          color: theme.palette.black.light,
          fontSize: '14px'
        }
      }
    },
    '& .loader': {
      color: theme.palette.statusColors.blue,
      marginTop: '8px',
      '& .file-progress': {
        fontSize: '13px',
        lineHeight: '1',
        fontWeight: '500',
        color: theme.palette.black.light,
        margin: '4px 0'
      },
      '& .MuiLinearProgress-colorPrimary': {
        background: 'white'
      },
      '& .MuiLinearProgress-bar': {
        background: theme.palette.statusColors.blue
      }
    }
  }
}))

export interface ImportProgressProps {
  transitionImportInProgress: boolean
}
export const ImportProgress: FC<ImportProgressProps> = ({ transitionImportInProgress }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const transitionId = useTransitionId()
  const { onlyUnlabelledFolders } = useSelector(useImportDocuments)
  const [wasInProgress, setWasInProgress] = useState(false)
  const [pollingInterval, setPollingInterval] = useState<number | undefined>()
  const { data: importProgress, refetch } = useCheckImportProgressQuery(transitionId, { pollingInterval })
  const { refetch: refetchImportFileTree } = useGetImportFileTreeQuery({ transitionId, unlabelled: onlyUnlabelledFolders })
  const totalCount = importProgress?.totalCount ?? 0
  const importedCount = importProgress?.importedCount ?? 0
  const importedPercent = importProgress?.importedPercent ?? 0
  const currentCount = importedCount
  const totalPercent = importedPercent

  useEffect(() => {
    setPollingInterval(transitionImportInProgress ? 20 * 1000 : undefined)
    if (transitionImportInProgress) {
      setWasInProgress(true)
    }
    if (!transitionImportInProgress && wasInProgress) {
      dispatch(openToast({ severity: 'info', message: 'Import structure synchronization completed' }))
      setWasInProgress(false)
    }
  }, [transitionImportInProgress])

  useEffect(() => {
    if (importedPercent && wasInProgress) {
      refetchImportFileTree()
    }
  }, [importedPercent])

  if (!transitionImportInProgress) {
    return null
  }

  return (
    <div className={classes.treeViewLoader}>
      <div className="header">
        <Typography className={'title'}>
          STRUCTURE IMPORT IN PROGRESS
        </Typography>
        <div className="right">
          <Tooltip title="refresh progress">
            <Icon
              fontSize="small"
              className={'refresh fas fa-sync'}
              onClick={refetch}
            />
          </Tooltip>
          <Typography className={'percent'}>{totalPercent}%</Typography>
        </div>
      </div>
      <div className="loader">
        <LinearProgress variant="determinate" value={totalPercent} />
        <Typography className={'file-progress'}>
          <b>{currentCount}</b> of <b>{totalCount}</b> documents imported
        </Typography>
      </div>
    </div>
  )
}
