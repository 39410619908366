import { FC } from 'react'
import { DocumentType, DocumentTypeId } from 'types'
import Dropdown from 'common/LisaControls/Dropdown'
import { Chip } from '@material-ui/core'

type DocumentTypeSelectProps = {
  value: DocumentTypeId | ''
  onChange(value: DocumentTypeId): void
  documentTypes: DocumentType[]
  disabled?: boolean
  noLabel?: boolean
  className?: string
}

type DocumentTypeSelectByPageProps = DocumentTypeSelectProps & {
  currentPage: number,
  pageType: string
}

export const DocumentTypeSelect: FC<DocumentTypeSelectProps> = ({
  value,
  onChange,
  documentTypes,
  disabled = false,
  noLabel = false,
  className
}) => {
  return (
    <Dropdown
      className={className}
      label={noLabel ? '' : 'Document Type'}
      name="documentType"
      disabled={disabled}
      showSelectOption={false}
      items={[
        { value: '', text: 'Select document type' },
        ...documentTypes.map(_ => ({ value: _.id, text: _.name }))
      ]}
      value={value}
      onChange={(e) => onChange(e.target.value as DocumentTypeId)}
    />
  )
}

export const DocumentTypeSelectByPage: FC<DocumentTypeSelectByPageProps> = ({
  value,
  onChange,
  documentTypes,
  disabled = false,
  currentPage,
  pageType
}) => {
  return (
    <>
      <Chip size="small" label={`Current page: ${currentPage + 1} | Page type: ${pageType}`} />
      <Dropdown
        label="Document Type By page"
        name="documentType"
        disabled={disabled}
        showSelectOption={false}
        items={[
          { value: '', text: 'Select document type' },
          ...documentTypes.map(_ => ({ value: _.id, text: _.name }))
        ]}
        value={value}
        onChange={(e) => onChange(e.target.value as DocumentTypeId)}
      />
    </>
  )
}
