import { ChangeEvent, FC, FormEvent, Fragment, MouseEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Collapse, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Alert from '@material-ui/lab/Alert'
import { LisaForm, useLisaForm } from 'common/Form/LisaForm'
import { Nullable, Roles, User, UserId } from 'types'
import { Role, RoleName } from 'utils/userRoleSecurity'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { useGetRolesQuery, useUpdateOrCreateUserMutation } from 'services/api/usersApi'
import { AllLisaTransitions, useGetAllTransitionsQuery } from 'services/api/transitionApi'
import { WithLoader } from 'common/WithLoader/WithLoader'
import { useAuth } from 'react-oidc-context'
import ComboBox from 'common/LisaControls/ComboBox'
import Input from 'common/LisaControls/Input'
import Dropdown from 'common/LisaControls/Dropdown'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0 32px 0',
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiDivider-root': {
      width: '100%',
      margin: '10px 0px 0px 1px'
    }
  },
  sideIndicator: {
    position: 'absolute',
    overflow: 'hidden',
    height: '100%',
    width: '4px',
    left: 0,
    top: 0,
    '&.high': {
      background: theme.palette.statusColors.red
    },
    '&.low': {
      background: theme.palette.statusColors.green
    },
    '&.medium': {
      background: theme.palette.statusColors.orange
    },
    '&.info': {
      background: theme.palette.statusColors.blue
    }
  },
  userRole: {
    marginTop: 8,
    '& .MuiAlert-standardInfo': {
      background: 'rgba(255,255,255,.5)',
      padding: '0px 14px 0 16px',
      '& .MuiAlert-message': {
        padding: '8px 0',
        fontSize: '16px',
        fontWeight: 600,
        color: theme.palette.black.main
      },
      '& .MuiIconButton-colorInherit': {
        color: theme.palette.black.main
      }
    }
  },
  controlsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  sectionTitle: {
    fontSize: '14px',
    marginTop: '12px',
    fontWeight: 700,
    letterSpacing: '.8px',
    textTransform: 'uppercase'
  },
  actionButtons: {
    padding: '32px 0px',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  }
}))

type UserRoleProps = {
  role?: string,
  finalRoles?: FinalRoles[],
  removeRole: (val: Roles['name']) => void
}

export const UserRole: FC<UserRoleProps> = ({ role, finalRoles, removeRole }) => {
  const classes = useStyles()

  return (
    <div className={classes.userRole}>
      <Collapse in={true}>
        <Alert
          icon={false}
          severity='info'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                removeRole(role!)
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
        >
          {finalRoles!.find((elem) => elem.value === role)?.text || ''}
        </Alert>
      </Collapse>
    </div>
  )
}

interface SingleTransitionProps {
  name: string
  finalTransitions: FinalTransitions[]
  removeTransition: (val: string) => void
}

export const SingleTransition: FC<SingleTransitionProps> = ({ name, finalTransitions, removeTransition }) => {
  const classes = useStyles()

  return (
    <div className={classes.userRole}>
      <Collapse in={true}>
        <Alert
          icon={false}
          severity='info'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                removeTransition(name)
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
        >
          {finalTransitions!.find((elem: FinalTransitions) => elem.value === name)?.name}
        </Alert>
      </Collapse>
    </div>
  )
}

interface FormValues {
  firstName: string
  lastName: string
  company: string
  email: string
  jobTitle: string
}

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  company: '',
  email: '',
  jobTitle: ''
}

interface AdditionalValues {
  transitions: string[],
  roles: Role[],
  userId: UserId,
  createdBy: string
}

type FormErrors = {
  email?: string
}

export interface FinalRoles {
  value: string
  text: string
}

interface FinalTransitions extends Partial<AllLisaTransitions> {
  value: string
  name: string
}

type EditUserProps ={
  selectedUser?: User
}

export const UserForm: FC<EditUserProps> = ({ selectedUser }) => {
  const dispatch = useDispatch()
  const { userId: authUserId } = useLisaAuth()
  const { user, signinSilent } = useAuth()
  const classes = useStyles()
  const isEdit = selectedUser !== undefined
  const {
    roles,
    transitionList,
    firstName,
    lastName,
    company,
    jobTitle,
    email,
    userId
  } = selectedUser ?? {}
  const [_roles, setRoles] = useState<string[]>(isEdit ? roles!.map(item => item.name) : [])
  const [transition, setTransition] = useState<FinalTransitions>({ value: '', name: 'Select' })
  const [selectedRole, setSelectedRole] = useState<string>('')
  const [transitions, setTransitions] = useState<string[]>(isEdit && transitionList!.length > 0 ? transitionList!.map((tran) => tran.transitionId) : [])
  const [selectedTransition, setSelectedTransition] = useState<string>('')
  const [updateOrCreateUser, { isLoading }] = useUpdateOrCreateUserMutation()
  const { data: userRoles = [] } = useGetRolesQuery()
  const { data: allLisaTransitions = [], isFetching } = useGetAllTransitionsQuery(authUserId!)

  const canSeeRole = useCallback((role: Role['roleName']): boolean => {
    return userRoles.some(_ => _.roleName === role)
  }, [userRoles])
  const finalRoles: FinalRoles[] = userRoles.map(_ => ({
    value: _.roleName,
    text: _.roleName
  }))
  const finalTransitions: FinalTransitions[] = allLisaTransitions.map((item) => ({
    value: item.transitionId!,
    name: item.transitionName!
  }))
  const dropdownTransitions: FinalTransitions[] = [{ value: '', name: 'Select' }, ...finalTransitions]
  const validate = useCallback((values: FormValues) => {
    const validationFields: (keyof FormErrors)[] = ['email']
    const emailRegex = /^\S+@\S+\.\S+$/
    return validationFields.reduce(
      ({ errors: e, isValid }: {errors: FormErrors, isValid: boolean}, field: keyof FormErrors) => {
        if (!values[field]) {
          e[field] = 'This field is required.'
          isValid = false
        } else {
          switch (values[field]) {
          case values.email: {
            if (!emailRegex.test(values[field])) {
              e.email = 'Email is not valid.'
              isValid = false
            }
            break
          }
          default:
            break
          }
        }
        return { errors: e, isValid }
      }, { errors: {}, isValid: true })
  }, [])

  const {
    values,
    errors,
    handleInputChange,
    resetForm,
    checkForErrors
  } = useLisaForm<FormValues, FormErrors>(
    isEdit
      ? {
        firstName: firstName!,
        lastName: lastName!,
        company: company!,
        jobTitle: jobTitle!,
        email: email!
      }
      : { ...initialValues },
    validate,
    false
  )

  useEffect(() => resetForm, [])

  const handleTransitionInputChange = (item: FinalTransitions) => {
    if (item) {
      setSelectedTransition(item.value!)
    }
    setTransition(item)
  }

  const handleRemoveRole = (removeItem: Roles['name']) => {
    setRoles(_roles.filter((item) => item !== removeItem))
  }

  const handleRemoveTransition = (removeItem: string) => {
    setTransitions(transitions.filter((item) => item !== removeItem))
  }

  const handleRolesInOut = useCallback(() => {
    if (!_roles.includes(selectedRole)) {
      if (selectedRole === 'External') {
        if (_roles.length === 0) {
          setRoles([selectedRole])
        } else {
          dispatch(openToast({ severity: 'warning', message: 'You cannot assign external role to internal user! First remove all internal roles.' }))
        }
      } else {
        if (_roles.includes('External')) {
          dispatch(openToast({ severity: 'warning', message: 'You cannot assign internal role to external user! First remove exernal role.' }))
        } else {
          setRoles([..._roles, selectedRole])
        }
      }
    }
    setSelectedRole('')
  }, [selectedRole])

  useEffect(() => {
    if (selectedRole.length !== 0) {
      handleRolesInOut()
    }
  }, [selectedRole])

  const handleTransitionsInOut = useCallback(() => {
    if (!transitions.includes(selectedTransition)) {
      setTransitions([...transitions, selectedTransition])
    }
  }, [selectedTransition])

  useEffect(() => {
    if (selectedTransition.length !== 0) {
      handleTransitionsInOut()
    }
  }, [selectedTransition])

  const handleClose = () => {
    dispatch(closeModal(['EDIT_USER', 'INVITE_NEW_USER']))
  }

  const handleSubmit = async (e: MouseEvent | FormEvent) => {
    e.preventDefault()
    const setRoleIds: Role[] = userRoles.filter((role) => _roles.includes(role.roleName))
    const isValid = checkForErrors()

    if (isValid) {
      if (setRoleIds.length === 0) {
        dispatch(openToast({ severity: 'warning', message: 'User must have at least one role selected.' }))
        return
      }
      const params: FormValues = {
        ...values
      }
      const additionalValues: AdditionalValues = {
        transitions,
        roles: setRoleIds,
        userId: isEdit ? userId! : '',
        createdBy: user!.profile.sub!
      }
      const sendObject = { ...params, ...additionalValues }
      try {
        const { success, message } = await updateOrCreateUser(sendObject).unwrap()
        if (message && !success) {
          dispatch(openToast({ severity: 'error', message }))
        }
        if (success) {
          dispatch(openToast({
            severity: 'success',
            message: isEdit
              ? `You successfully updated user - [${values.firstName} ${values.lastName}].`
              : 'You successfully created new user'
          }))
          handleClose()
        }
        if (user?.profile.sub! === userId) {
          await signinSilent()
        }
      } catch (e) {
        const { data: { message } } = e as { data: { message: string } }
        if (message) {
          dispatch(openToast({
            severity: 'error',
            message
          }))
        }
      }
    }
  }

  return (
    <div className={classes.root}>
      <WithLoader loading={isFetching} hasResults={true} loadingText={'Loading assets...'}>
        <LisaForm>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography className={classes.sectionTitle}>USER DATA</Typography>
            </Grid>
            {isEdit
              ? <>
                <Grid item xs={6}>
                  <Input
                    disabled
                    label='First name'
                    name='firstName'
                    value={values.firstName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    disabled
                    label='Last name'
                    name='lastName'
                    value={values.lastName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    disabled
                    label='Email'
                    name='email'
                    value={values.email}
                    onChange={handleInputChange}
                    errMsg={errors?.email}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    disabled
                    label='Company'
                    name='company'
                    value={values.company}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    disabled
                    label='Job title'
                    name='jobTitle'
                    value={values.jobTitle}
                    onChange={handleInputChange}
                  />
                </Grid></>
              : <Grid item xs={6}>
                <Input
                  required
                  label='Email'
                  name='email'
                  value={values.email}
                  onChange={handleInputChange}
                  disabled={isEdit}
                  errMsg={errors?.email}
                />
              </Grid>}
            <Grid item xs={12}>
              <Typography className={classes.sectionTitle}>USER ROLES</Typography>
            </Grid>
            <Grid item xs={12} className={classes.controlsWrapper}>
              <Dropdown
                label='Select user role'
                name='roles'
                items={finalRoles}
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value as string)}
              />
            </Grid>
            <Grid item xs={12}>
              {_roles.length > 0 &&
              _roles.filter((r) => canSeeRole(r as RoleName)).map((role, i: number) => (
                <UserRole
                  key={i}
                  role={role}
                  finalRoles={finalRoles}
                  removeRole={(removeItem) => handleRemoveRole(removeItem)}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.sectionTitle}>
              AUTHORIZED ASSETS
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.controlsWrapper}>
              <ComboBox
                label={'Select asset'}
                options={dropdownTransitions}
                getOptionLabel={(option: Nullable<FinalTransitions>) =>
                  option ? option.name : ''
                }
                onChange={(e: ChangeEvent<{}>, newValue: Nullable<FinalTransitions>) => {
                  newValue && handleTransitionInputChange(newValue)
                }}
                value={transition}
                renderOption={(option: FinalTransitions) => (
                  <Fragment>
                    <span>{option.name}</span>
                  </Fragment>
                )}
                getOptionSelected={(option, t) => option?.value === t?.value}/>
            </Grid>
            <Grid item xs={12}>
              {transitions.length > 0 &&
              transitions.map((name: string, i: number) => (
                <SingleTransition
                  key={i}
                  name={name}
                  finalTransitions={finalTransitions}
                  removeTransition={(removeItem) => handleRemoveTransition(removeItem)}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <div className={classes.actionButtons}>
                <Button
                  type='submit'
                  disabled={isLoading}
                  onClick={handleSubmit}
                  variant='contained'
                  color='primary'
                  size='small'>
                  { isEdit ? 'Edit' : 'Save' }
                </Button>
                <Button
                  disabled={isLoading}
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={handleClose}>
                Cancel
                </Button>
              </div>
            </Grid>
          </Grid>
        </LisaForm>
      </WithLoader>
    </div>
  )
}
