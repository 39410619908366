import { lisaApi } from 'services/api/lisaApi'
import { LisaResponseExtended, Tenant, TenantId } from 'types'

export const tenantApi = lisaApi.injectEndpoints({
  endpoints: build => ({
    getTenants: build.query<Tenant[], void>({
      query: () => ({
        url: 'tenant'
      }),
      providesTags: ['Tenants']
    }),
    getTenant: build.query<Tenant[], TenantId>({
      query: (tenantId) => ({
        url: `tenant?id=${tenantId}`
      }),
      providesTags: ['Tenant']
    }),
    createTenant: build.mutation<LisaResponseExtended, Partial<Tenant>>({
      query: (tenant) => ({
        url: 'tenant',
        method: 'POST',
        body: tenant
      }),
      invalidatesTags: ['Tenants']
    }),
    editTenant: build.mutation<LisaResponseExtended, Partial<Tenant>>({
      query: (tenant) => ({
        url: 'tenant',
        method: 'PUT',
        body: tenant
      }),
      invalidatesTags: ['Tenants', 'Tenant', 'User']
    }),
    uploadLogo: build.mutation<string, { body: FormData, suffix: string }>({
      query: ({ body, suffix }) => ({
        url: `tenant/UploadPicture?suffix=${suffix}`,
        method: 'POST',
        body
      })
    })
  })
})

export const {
  useGetTenantsQuery,
  useGetTenantQuery,
  useCreateTenantMutation,
  useEditTenantMutation,
  useUploadLogoMutation
} = tenantApi
