import { ChangeEvent, FC, useEffect, useState } from 'react'
import { IconButton, makeStyles, TableCell, TableCellProps, Theme } from '@material-ui/core'
import clsx from 'clsx'
import TextArea from 'common/LisaControls/TextArea'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles<Theme, { sticky: boolean }>((theme) => ({
  root: {
    border: '1px solid #696969',
    minWidth: 75,
    padding: 8
  },
  rootSticky: {
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    zIndex: 2
  },
  rootEditing: {
    padding: 0
  },
  head: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textAlign: 'center'
  },
  headSticky: {
    top: 0,
    zIndex: 3
  },
  body: {
    whiteSpace: 'pre-wrap'
  },
  textWrapper: {
    position: 'relative'
  },
  icon: {
  },
  iconWrapper: {
    position: 'absolute',
    top: '100%',
    backgroundColor: 'white',
    display: 'flex',
    border: '1px solid #696969'
  },
  okIcon: {
    // left: 0,
    color: theme.palette.statusColors.green
  },
  cancelIcon: {
    // left: 50,
    color: theme.palette.statusColors.red
  }
}))

export type TableViewCellProps = TableCellProps & {
  sticky?: boolean
  readonly?: boolean
  focused?: boolean
  onSave?: (value: string) => void
}
export const TableViewCell: FC<TableViewCellProps> = ({
  children,
  sticky = false,
  readonly = false,
  focused = false, // Cell focus. Not input keyboard focus
  onSave = () => null,
  ...props
}) => {
  const classes = useStyles({ sticky })
  const isEditable = !readonly && typeof children === 'string'
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState<string>(isEditable ? children : '')
  const [prevValue, setPrevValue] = useState<string>(value)
  const onDoubleClick = () => {
    if (isEditable) {
      setEditing(true)
      setPrevValue(value)
    }
  }
  const isInEditMode = editing && focused && !readonly
  const cols = value.split('\n').length

  useEffect(() => {
    if (!focused) {
      setEditing(false)
    }
  }, [focused])

  return (
    <TableCell
      onDoubleClick={onDoubleClick}
      classes={{
        root: clsx(classes.root, { [classes.rootSticky]: sticky, [classes.rootEditing]: editing }),
        head: clsx(classes.head, { [classes.headSticky]: sticky }),
        body: clsx(classes.body)
      }}
      {...props}>
      {
        readonly && children
      }
      {!isInEditMode && value}
      {
        isInEditMode &&
          <div className={classes.textWrapper}>
            <TextArea
              autoFocus
              style={{ width: '100%', height: '100%' }}
              value={value}
              minRows={cols < 3 ? 3 : cols}
              onChange={(e: ChangeEvent<{ value: string }>) => setValue(e.target.value)}/>
            <div className={classes.iconWrapper}>
              <IconButton
                onClick={() => {
                  onSave(value)
                  setEditing(false)
                }}
                className={clsx(classes.icon, classes.okIcon)}>
                <CheckIcon/>
              </IconButton>
              <IconButton
                onClick={() => {
                  setValue(prevValue)
                  setEditing(false)
                }}
                className={clsx(classes.icon, classes.cancelIcon)}>
                <CloseIcon/>
              </IconButton>
            </div>
          </div>
      }
    </TableCell>
  )
}
