import { makeStyles } from '@material-ui/core'

export const useAutocompleteStyle = makeStyles(() => ({
  autocomplete: {
    '& .MuiOutlinedInput-root': {
      height: '42px'
    },
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      width: '100%',
      paddingBlock: 0
    }
  },
  textField: {
    width: '100%',
    maxHeight: 42
  }
}))
