import { Grid, Hidden, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import airplaneWheel from './components/dashboard_image.jpg'
import airplaneHand from './components/airplane_hand.webp'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { RecentTransitions } from './components/RecentTransitions'
import { DashboardSection } from './components/DashboardSection'
import { RecentQueries } from './components/RecentQueries'
import { RecentDocuments } from './components/RecentDocuments'
import { GridGrow } from 'common/Grid/GridGrow'
import { OcrQueue } from './components/OcrQueue'

const useStyles = makeStyles(() => ({
  imageWrapper: {
    width: '100%',
    backgroundImage: `url(${airplaneWheel})`,
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1100px 510px',
    '&.ocr': {
      backgroundImage: `url(${airplaneWheel})`
    },
    '&.queries': {
      backgroundImage: `url(${airplaneHand})`
    }
  },
  row: {
    paddingInline: '12px !important',
    minHeight: 444 // For loaders
  }
}))

const Dashboard = () => {
  const classes = useStyles()
  const { hasAccess } = useLisaAuth()
  const canSeeOCRQueue = hasAccess('perm_view_dashboard_ocrqueue')
  const canSeeDashboardQueries = hasAccess('perm_view_query')

  return (
    <ContentWrapper newLayout>
      <GridGrow container direction={'column'} spacing={2}>
        {/* Row 1 - Transitions and OCRQueue/Image */}
        <Grid item container spacing={5} className={classes.row}>
          <Grid item xs={12} lg={6}>
            <RecentTransitions/>
          </Grid>
          {
            canSeeOCRQueue &&
            <Grid item xs={12} lg={6}>
              <OcrQueue/>
            </Grid>
          }
          {
            !canSeeOCRQueue &&
            <Hidden mdDown>
              <Grid item lg={6}>
                <DashboardSection>
                  <div className={clsx(classes.imageWrapper, 'ocr')}/>
                </DashboardSection>
              </Grid>
            </Hidden>
          }
        </Grid>
        {/* Row 2 - Queries and Documents */}
        <Grid item container spacing={5} className={classes.row}>
          { canSeeDashboardQueries &&
            <Grid item xs={12} lg={6}>
              <RecentQueries/>
            </Grid>
          }
          {
            !canSeeDashboardQueries &&
            <Hidden mdDown>
              <Grid item lg={6}>
                <DashboardSection>
                  <div className={clsx(classes.imageWrapper, 'queries')}/>
                </DashboardSection>
              </Grid>
            </Hidden>
          }
          <Grid item xs={12} lg={6}>
            <RecentDocuments/>
          </Grid>
        </Grid>
      </GridGrow>
    </ContentWrapper>
  )
}

export default Dashboard
