import { FC, ReactElement, useMemo } from 'react'
import { AbbreviationTypeIcon } from 'common/AbbreviationTypeIcon/AbbreviationTypeIcon'
import { CurrentPageLabelProps } from '@react-pdf-viewer/page-navigation'

type DocumentTypePageNavigationProps = {
  CurrentPageLabel: (props: CurrentPageLabelProps) => ReactElement,
  jumpToPage: (pageIndex: number) => void
  documentTypes: string[]
}

export const DocumentTypePageNavigation: FC<DocumentTypePageNavigationProps> = ({
  CurrentPageLabel,
  jumpToPage,
  documentTypes
}) => {
  // [documentType, start, end]
  const documentTypesPerPage = useMemo(() => {
    return documentTypes.reduce<[string, number, number][]>((typePage, type, index) => {
      const typeLength = typePage.length
      const prevTypeIndex = typeLength - 1
      if (typeLength === 0) { // if empty then just add current type
        return [[type, index, index]]
      }
      const [lastType] = typePage[prevTypeIndex]
      if (lastType !== type) { // if previous type is same as current type then just skip it
        typePage[prevTypeIndex][2] = index - 1
        typePage.push([type, index, index])
      } else {
        typePage[prevTypeIndex][2]++
      }
      return typePage
    }, [])
  }, [documentTypes.join(',')])

  return (
    <CurrentPageLabel>
      {
        (props) => (
          <>
            {
              documentTypesPerPage.map(([type, start, end]) => (
                <div key={`${type}-${start}`} style={{ marginTop: 2 }}>
                  <AbbreviationTypeIcon
                    active={props.currentPage >= start && props.currentPage <= end}
                    variation={'clickable'}
                    onClick={() => jumpToPage(start)}
                    docTypeRecognitionResult={type}/>
                </div>
              ))
            }
          </>
        )
      }
    </CurrentPageLabel>
  )
}
