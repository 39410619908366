import { TimePicker as TPicker, TimePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import { FormField } from 'common/FormField/FormField'
import { FC } from 'react'
import { ClockIcon } from 'common/Icons/SvgIcons'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiIconButton-root': {
      marginRight: '-15px !important'
    },
    '& .Mui-error.MuiFormHelperText-root': {
      marginLeft: 0,
      marginTop: 0,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '18px',
      letterSpacing: '-.1px',
      height: 18,
      '&::first-letter': {
        textTransform: 'capitalize'
      }
    }
  },
  timepicker: {
    paddingBlock: 0,
    height: '42px',
    '&.MuiOutlinedInput-adornedEnd': {
      paddingRight: '0px !important',
      width: '100%'
    },
    '&.Mui-error.MuiFormHelperText-root': {
      fontSize: '20px !important',
      fontWeight: 500,
      lineHeight: '18px',
      letterSpacing: '-.1px',
      height: 18,
      '&::first-letter': {
        textTransform: 'capitalize'
      }
    },
    '& .MuiOutlinedInput-input': {
      cursor: 'pointer'
    }
  },
  fieldWrapper: {
    position: 'relative',
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  fieldIcon: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
    cursor: 'pointer !important'
  }
}))

type PickerProps = TimePickerProps & {
  errMessage?: string
}

const TimePicker: FC<PickerProps> = ({ label, required, InputProps, InputLabelProps, errMessage, ...props }) => {
  const classes = useStyles()
  return (
    <FormField label={label} required={required} error={errMessage}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.fieldWrapper}>
          <TPicker
            autoOk
            ampm={false}
            variant="static"
            inputVariant="outlined"
            placeholder={'HH:mm'}
            format={'HH:mm'}
            InputProps={{
              className: classes.timepicker,
              ...InputProps
            }}
            InputLabelProps={{
              shrink: true,
              ...InputLabelProps
            }}
            {...props}
          />
          <div className={classes.fieldIcon}>
            <ClockIcon />
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </FormField>
  )
}

export default TimePicker
