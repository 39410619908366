import { lisaApi } from 'services/api/lisaApi'
import {
  Attachment,
  AttachmentId,
  CommentVisibility,
  DocumentId,
  ExcelFile,
  LisaResponseExtended,
  QueriesExportParams,
  QueriesRequestParams,
  Query,
  QueryComment,
  QueryId,
  QueryStatus,
  QueryStatusReport,
  QueryType,
  TransitionId,
  Label,
  User,
  UserId,
  WeeklyQueryStatusReport,
  WeeklyQueryStatusReportValue,
  Nullable
} from 'types'
import { sortByWeek } from 'utils/transitionReports'

export const queryApi = lisaApi.injectEndpoints({
  endpoints: (builder) => ({
    getNewQueriesCount: builder.query<number, UserId>({
      query: (userId) => ({
        url: 'Query/NewQueriesCount',
        params: { userId }
      }),
      transformResponse: (response: { count: number }) => response.count
    }),
    getRecentQueries: builder.query<Query[], UserId>({
      query: (userId) => ({
        url: 'Query/Recent',
        params: { userId }
      })
    }),
    getNewQueries: builder.query<Query[], UserId>({
      query: (userId) => ({
        url: `Query/Dashboard?userId=${userId}`
      })
    }),
    getWeeklyReport: builder.query<WeeklyQueryStatusReport, TransitionId>({
      query: (transitionId) => ({
        url: 'Query/weeklyReports',
        params: { transitionId }
      }),
      transformResponse: (response: {
        queryStatusReportNotes: string
        queryStatusWeeklyReports: (WeeklyQueryStatusReportValue)[]
      }) => ({
        notes: response.queryStatusReportNotes,
        data: sortByWeek(response.queryStatusWeeklyReports)
      })
    }),
    updateQueryWeeklyReportNotes: builder.mutation<LisaResponseExtended, {
      transitionId: TransitionId
      notes: string
    }>({
      query: ({
        notes,
        transitionId
      }) => ({
        url: 'Query/updateQueryStatusNotes',
        method: 'POST',
        body: {
          queryStatusReportNotes: notes,
          transitionId
        }
      })
    }),
    getQueriesByTransition: builder.query<Query[], { transitionId: TransitionId, unresolved?: boolean }>({
      query: ({ transitionId, unresolved = true }) => ({
        url: `/Query/QueriesByTransition?transitionId=${transitionId}&unresolved=${unresolved ? 1 : 0}`
      }),
      transformResponse: (response: { queries: Query[] }) => response.queries,
      providesTags: ['Queries']
    }),
    getQueriesByDocument: builder.query<Query[], DocumentId>({
      query: (documentId) => ({
        url: 'Query/QueriesByDocument',
        params: { documentId }
      }),
      providesTags: ['Queries']
    }),
    searchQueries: builder.query<Query[], QueriesRequestParams>({
      query: (params) => ({
        url: 'Query/v2/Search',
        method: 'POST',
        body: params
      }),
      transformResponse: (response: { queryList: Query[] }) => response.queryList, // Ignoring report
      providesTags: ['Queries']
    }),
    getQueryData: builder.query<Query, QueryId>({
      query: (queryId) => ({
        url: `Query?id=${queryId}`
      }),
      providesTags: ['Query']
    }),
    getQueryComments: builder.query<QueryComment[], QueryId>({
      query: (queryId) => ({
        url: 'Query/CommentsByQuery',
        params: { queryId }
      }),
      providesTags: ['QueryComments']
    }),
    getQueryAttachments: builder.query<Attachment[], QueryId>({
      query: (queryId) => ({
        url: 'Query/AttachmentsByQuery',
        params: { queryId }
      }),
      providesTags: ['QueryAttachments']
    }),
    exportToExcel: builder.mutation<ExcelFile, QueriesExportParams>({
      query: (params) => ({
        url: 'Query/Excel',
        method: 'POST',
        body: params
      })
    }),
    deleteQueryAttachment: builder.mutation<LisaResponseExtended, {
      attachmentId: AttachmentId
      userId: UserId
    }>({
      query: ({
        attachmentId,
        userId
      }) => ({
        url: `/Query/Attachment/Remove?attachmentId=${attachmentId}&userId=${userId}`,
        method: 'PUT'
      }),
      invalidatesTags: ['QueryAttachments']
    }),
    setAttachmentAsReference: builder.mutation<LisaResponseExtended, {
      attachmentId: Nullable<AttachmentId>,
      queryId: QueryId
    }>({
      query: (params) => ({
        url: 'Query/Attachment/SetAsReference',
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['Query']
    }),
    AddA2KLabelToAttachment: builder.mutation<LisaResponseExtended, {
      attachmentId: Nullable<AttachmentId>,
      queryId: QueryId,
      labels: Label[]
    }>({
      query: (params) => ({
        url: 'Query/Attachment/AddA2KLabel',
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['Query']
    }),
    getQueryStatusReport: builder.query<QueryStatusReport, {
      transitionId: TransitionId
      assignTo?: UserId
      reporter?: UserId
      queryType?: QueryType
    }>({
      query: ({ transitionId }) => ({
        url: `/Query/QueryStatusReport?transitionId=${transitionId}`
      })
    }),
    updateQueryStatus: builder.mutation<LisaResponseExtended, {
      queryId: QueryId
      userId: UserId
      status: QueryStatus
      wotc?: string
    }>({
      query: ({
        queryId,
        userId,
        status,
        wotc
      }) => ({
        url: `/Query/UpdateStatus?queryId=${queryId}&userId=${userId}&status=${status}${wotc !== undefined ? `&wotc=${wotc}` : ''}`,
        method: 'PUT'
      }),
      invalidatesTags: ['Query', 'Queries', 'DocumentMetaData']
    }),
    createQuery: builder.mutation<LisaResponseExtended, Partial<Query>>({
      query: (params) => ({
        url: 'Query/create',
        method: 'POST',
        body: {
          ...params,
          queryRelation: params.documentId ? 2 : 1,
          reporter: params.createdBy
        }
      }),
      invalidatesTags: ['Queries', 'DocumentMetaData']
    }),
    editQuery: builder.mutation<LisaResponseExtended, Partial<Query>>({
      query: (params) => ({
        url: 'Query/Update',
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['Query', 'Queries', 'DocumentMetaData']
    }),
    addQueryComment: builder.mutation<LisaResponseExtended, {
      queryId: QueryId,
      comment: string,
      assignTo: UserId,
      createdBy: UserId,
      transitionId: TransitionId,
      commentVisibility: CommentVisibility
    }>({
      query: (params) => ({
        url: 'Query/Comment/Add',
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['QueryComments']
    }),
    getAssigneeUsers: builder.query<User[], {
      transitionId: TransitionId
      isExternal?: boolean
    }>({
      query: ({
        transitionId,
        isExternal = true
      }) => ({
        url: 'Query/AssigneeUsers',
        params: {
          transitionId,
          isExternal
        }
      })
    })
  })
})

export const {
  useGetNewQueriesCountQuery,
  useGetRecentQueriesQuery,
  useGetNewQueriesQuery,
  useGetWeeklyReportQuery,
  useUpdateQueryWeeklyReportNotesMutation,
  useGetQueriesByTransitionQuery,
  useGetQueriesByDocumentQuery,
  useSearchQueriesQuery,
  useGetQueryDataQuery,
  useGetQueryCommentsQuery,
  useGetQueryAttachmentsQuery,
  useExportToExcelMutation,
  useDeleteQueryAttachmentMutation,
  useGetQueryStatusReportQuery, // @todo Unused because of elastica lag
  useUpdateQueryStatusMutation,
  useCreateQueryMutation,
  useEditQueryMutation,
  useAddQueryCommentMutation,
  useGetAssigneeUsersQuery,
  useSetAttachmentAsReferenceMutation,
  useAddA2KLabelToAttachmentMutation
} = queryApi
