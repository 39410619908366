import { FC, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import {
  OCRDocumentType,
  toggleDocumentIdForOCR,
  useOCRStatus
} from 'redux/slices/ocrStatusSlice'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import * as Icons from 'common/Icons/SvgIcons'
import { OCRDocument } from 'types'
import { sortOcrDocuments } from 'utils/documents'
import { useTransitionId } from 'context/TransitionContext'
import { TableData } from 'common/TableData/TableData'
import { SelectAllCell } from './SelectAllCell'
import { SortByFileNameCell, SortByOcrStatusCell } from './SortCells'
import Icon from '@material-ui/core/Icon'
import { DocumentLink } from 'common/DocumentLink/DocumentLink'
import { ConfirmDialog } from 'common/Dialogs/ConfirmDialog'
import { openConfirmDialog } from 'redux/slices/appSlice'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useGetOCRUnsupportedDocumentsQuery } from 'services/api/ocrApi'
import { useRemoveDocumentMutation } from 'services/api/documentApi'

const useStyles = makeStyles((theme) => ({
  dataTableCheckbox: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.35rem'
    }
  },
  bodyRows: {
    height: '42px'
  },
  statusLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // rowGap: "2px",
    '& .MuiIcon-root': {
      fontSize: '16px',
      '&.fas.fa-check-circle': {
        color: theme.palette.statusColors.green
      },
      '&.fas.fa-exclamation-triangle': {
        color: theme.palette.statusColors.red
      },
      '&.fas.fa-spinner': {
        color: theme.palette.statusColors.blue
      },
      '&.fas.fa-exclamation-circle': {
        color: theme.palette.statusColors.orange
      },
      '&.far.fa-pause-circle': {
        color: theme.palette.statusColors.blue
      }
    }
  },
  deleteIco: {
    cursor: 'pointer'
  },
  dialogMessage: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 500,
    padding: '48px 0'
  },
  last: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

export const UnsupportedDocuments: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    documentIdsForOCR,
    filters
  } = useSelector(useOCRStatus)
  const { data: unsupportedDocuments = [], isFetching } = useGetOCRUnsupportedDocumentsQuery(useTransitionId())
  const {
    fileName,
    ocrStatus
  } = filters[OCRDocumentType.Unsupported]
  const { userId } = useLisaAuth()
  const [documentToRemove, setDocumentToRemove] = useState<OCRDocument | null>(null)
  const [removeDocument] = useRemoveDocumentMutation()

  const handleOpenDeleteDialog = (document: OCRDocument) => {
    setDocumentToRemove(document)
    dispatch(openConfirmDialog('MARK_DOCUMENT_FOR_DELETION'))
  }

  const handleDeleteDocument = async () => {
    if (documentToRemove) {
      await removeDocument({
        documentId: documentToRemove.documentId,
        userId: userId!
      })
      setDocumentToRemove(null)
    }
  }

  return (
    <TableData
      loading={isFetching}
      headCells={[
        <SelectAllCell
          key={'checkbox'}
          documents={unsupportedDocuments}
          documentIds={unsupportedDocuments.map(_ => _.documentId)}/>,
        <SortByFileNameCell key={'fileName'} ocrDocumentType={OCRDocumentType.Unsupported}/>,
        <SortByOcrStatusCell key={'ocrStatus'} ocrDocumentType={OCRDocumentType.Unsupported}/>,
        <TableCell style={{ width: 50 }} key={'empty'}/>
      ]}
      tableRows={
        sortOcrDocuments(unsupportedDocuments, {
          fileName,
          ocrStatus
        })
          .map((row) => {
            const isItemSelected = documentIdsForOCR.includes(row.documentId)
            const labelId = `enhanced-table-checkbox-${row.documentId}`

            return (
              <TableRow
                hover={!isItemSelected}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.documentId}
                selected={isItemSelected}
                className={classes.bodyRows}
              >
                <TableCell
                  padding="checkbox"
                  onClick={() =>
                    dispatch(toggleDocumentIdForOCR(row.documentId))
                  }
                  style={{ width: '40px' }}
                >
                  <Checkbox
                    className={classes.dataTableCheckbox}
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                <TableCell
                  id={labelId}
                  scope="row"
                  padding="none"
                  style={{ width: 'auto' }}>
                  <DocumentLink document={row} title={row.filePath}/>
                </TableCell>
                <TableCell align="center" style={{ width: '100px' }} padding="none">
                  <div className={classes.statusLabel}>
                    <Tooltip title="Unsupported">
                      <Icon
                        fontSize="small"
                        className={
                          'fas fa-exclamation-circle'
                        }
                        style={{ width: '20px' }}
                      />

                    </Tooltip>
                  </div>
                </TableCell>
                <TableCell align="left" style={{ width: '50px' }} padding="none">
                  <Tooltip title="Remove document">
                    <div className={classes.last}>
                      <Icons.DeleteIco
                        onClick={() => handleOpenDeleteDialog(row)}
                      />
                    </div>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )
          })
      }>
      <ConfirmDialog
        title="Mark Document for Deletion"
        type={'MARK_DOCUMENT_FOR_DELETION'}
        onConfirm={handleDeleteDocument}>
        <div className={classes.dialogMessage}>Are you sure you want to mark {documentToRemove?.name} document for deletion?</div>
      </ConfirmDialog>
    </TableData>
  )
}
