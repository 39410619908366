import { ChangeEvent, FC, FormHTMLAttributes, useState } from 'react'

export const useLisaForm = <V extends object, E extends object>(
  initialFValues: V,
  validateFn?: (values: V) => {errors: E | null, isValid: boolean},
  validateOnChange = true
) => {
  const [values, setV] = useState<V>(initialFValues)
  const [errors, setErrors] = useState<E | null>(null)

  const resetForm = () => {
    setV(initialFValues)
    setErrors(null)
  }
  const checkForErrors = () => {
    if (validateFn !== undefined) {
      const {
        errors,
        isValid
      } = validateFn(values)
      isValid ? setErrors(null) : setErrors(errors)
      return isValid
    }
    return true
  }

  const setValue = (name: string, value: any) => {
    setV({
      ...values,
      [name]: value
    })
  }

  const setValues = (v: Partial<V>) => {
    setV({ ...values, ...v })
  }

  // @todo bad typing for value
  const handleInputChange = (e: ChangeEvent<{ value: any, name?: string }>) => {
    const { value, name } = e.target
    if (name) {
      setValue(name, value)
      if (validateOnChange) {
        checkForErrors()
      }
    }
  }

  return {
    values,
    setValue,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    checkForErrors,
    resetForm
  }
}

export const LisaForm: FC<FormHTMLAttributes<HTMLFormElement>> = (props) => {
  const { children, ...other } = props

  return (
    <form autoComplete={'off'} {...other}>
      {children}
    </form>
  )
}
