import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { openConfirmDialog, openModal, openToast } from 'redux/slices/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { toggleViewMode, useDocument } from 'redux/slices/documentSlice'
import { DocumentTypeSelect, DocumentTypeSelectByPage } from 'common/Select/DocumentTypeSelect'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { DatatableForm } from './DatatableForm'
import { LisaProgress } from 'common/Loaders/LisaProgress'
import { DocumentType, DocumentTypeId, FileTypeEnum, ParserStatus } from 'types'
import { ConfirmDialog } from 'common/Dialogs/ConfirmDialog'
import { useTransitionId } from 'context/TransitionContext'
import {
  useChangeDocumentTypeMutation,
  useChangeDocumentTypePerPageMutation,
  useCheckParserStatusQuery,
  useGetDocumentQuery,
  useGetDocumentTypesQuery
} from 'services/api/documentApi'
import { useDocumentId } from 'context/DocumentContext'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useGetTableViewQuery } from 'services/api/tableViewApi'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 16
  },
  addMetaDataIcon: {
    fill: theme.palette.black.main,
    cursor: 'pointer',
    padding: '9px 0',
    '&:hover': {
      fill: theme.palette.black.light
    }
  },
  message: {
    fontSize: 14,
    color: theme.palette.statusColors.red,
    padding: '24px 0'
  },
  messageNormal: {
    padding: '24px 0'
  },
  createDataTableBtn: {
    marginTop: 10,
    textTransform: 'none',
    fontSize: 16
  },
  progressWrapper: {
    marginBlock: 8
  }
}))

const progressStrings = {
  [ParserStatus.Ready]: 'conversion to Excel in progress',
  [ParserStatus.Processing]: 'datatable creation in progress'
}

export const DatatableOptions: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const documentId = useDocumentId()
  const { data: document } = useGetDocumentQuery({ id: documentId, transitionId: useTransitionId() })
  const { viewMode, currentPage, pageType } = useSelector(useDocument)
  const { data: documentTypes = [], isLoading } = useGetDocumentTypesQuery()
  const getDocumentType = useCallback(() => {
    return documentTypes.find(_ => _.id === document?.documentTypeId) ?? null
  }, [document?.documentId, documentTypes])
  const [documentType, setDocumentType] = useState<DocumentType | null>(getDocumentType())
  const [documentTypeByPage, setDocumentTypeByPage] = useState<DocumentType | null>(getDocumentType())
  const [changeDocumentType] = useChangeDocumentTypeMutation()
  const [changeDocumentTypePerPage] = useChangeDocumentTypePerPageMutation()
  const { hasAccess } = useLisaAuth()
  const [parserCheckInterval, setParserCheckInterval] = useState<number | undefined>(undefined)
  const isDocumentParserSet = document?.documentParserId !== null
  const { data: parserStatus } = useCheckParserStatusQuery(documentId, {
    pollingInterval: parserCheckInterval,
    skip: !isDocumentParserSet
  })
  const { data: tableView, isLoading: loadingTableView } = useGetTableViewQuery(documentId, {
    skip: parserStatus !== ParserStatus.Success
  })

  const hasDatatable = !!tableView && isDocumentParserSet
  const canEditPageType = hasAccess('perm_act_documentmetadata') // @todo Check this
  const isParserProcessing = document !== null && isDocumentParserSet && (
    parserStatus === ParserStatus.Processing || parserStatus === ParserStatus.Ready)

  const showDatatableButton = useMemo(() => {
    const hasValidDocumentType = (documentTypes.find(_ => _.id === document?.documentTypeId)?.type ?? '') === FileTypeEnum.Excel
    return hasValidDocumentType && !isParserProcessing && !loadingTableView
  },
  [document?.documentTypeId, documentTypes, isParserProcessing, loadingTableView])

  useEffect(() => {
    if (isParserProcessing) {
      setParserCheckInterval(10 * 1000)
    } else {
      setParserCheckInterval(undefined)
    }
  }, [isParserProcessing])

  useEffect(() => {
    if (!hasDatatable && viewMode === 'datatable') {
      dispatch(toggleViewMode())
    }
  }, [hasDatatable])

  useEffect(() => {
    if (!isLoading) {
      setDocumentType(getDocumentType())
    }
  }, [isLoading])

  const changeDocumentTypeCB = useCallback(async () => {
    if (documentType === null) {
      return
    }
    await changeDocumentType({
      documentId: document!.documentId,
      documentTypeId: documentType.id
    }).unwrap()
    dispatch(openToast({ severity: 'info', message: `Document type successfully changed to ${documentType.name}` }))
  }, [document!.documentId, documentType])

  if (documentTypes.length === 0) {
    return null
  }

  const changeDocumentTypePerPageCB = async (documentType: DocumentType) => {
    const { id, name } = documentType
    if (id === null) {
      return
    }
    await changeDocumentTypePerPage({
      updatedDocumentTypeId: id,
      documentId: document!.documentId,
      documentPageNumber: currentPage + 1
    }).unwrap()
    setDocumentTypeByPage(null)
    dispatch(openToast({ severity: 'info', message: `Document page No.${currentPage + 1} successfully changed to "${name}" type` }))
  }

  if (documentTypes.length === 0) {
    return null
  }

  return (
    <Grid container direction={'column'} className={classes.root}>
      <Grid item>
        <DocumentTypeSelect
          disabled={isParserProcessing || !canEditPageType}
          value={documentType?.id ?? ''}
          onChange={(dtId) => {
            const documentType = documentTypes.find(_ => _.id === dtId)
            if (documentType) {
              setDocumentType(documentType)
              if (document?.documentParserId) {
                dispatch(openConfirmDialog('CHANGE_DOCUMENT_TYPE_DATATABLE'))
              } else {
                dispatch(openConfirmDialog('CHANGE_DOCUMENT_TYPE'))
              }
            }
          }}
          documentTypes={[...documentTypes].sort((a, z) =>
            a.name.localeCompare(z.name))}/>
      </Grid>
      {
        isParserProcessing &&
        <Grid item className={classes.progressWrapper}>
          <LisaProgress title={progressStrings[parserStatus]}/>
        </Grid>
      }
      {
        parserStatus === ParserStatus.Failed &&
        <Typography variant={'body2'} color={'error'}>Failed to parse document</Typography>
      }
      {
        showDatatableButton &&
        <Grid item>
          <Button
            disabled={!canEditPageType}
            className={classes.createDataTableBtn}
            onClick={() => dispatch(openModal(hasDatatable ? 'RECREATE_DATATABLE' : 'CREATE_DATATABLE'))}
            variant="contained"
            color="primary"
            size="small">
            {hasDatatable ? 'Recreate datatable' : 'Create datatable'}
          </Button>
        </Grid>
      }
      <Grid item style={{ marginTop: '20px' }}>
        <DocumentTypeSelectByPage
          disabled={isParserProcessing || !canEditPageType}
          value={documentTypeByPage?.id ?? ''}
          onChange={async (dtId: DocumentTypeId) => {
            const documentType = documentTypes.find(_ => _.id === dtId)
            setDocumentTypeByPage(documentType!)
            await changeDocumentTypePerPageCB(documentType!)
          }}
          documentTypes={[...documentTypes].sort((a, z) =>
            a.name.localeCompare(z.name))}
          currentPage={currentPage}
          pageType={pageType}/>
      </Grid>
      <LisaModal
        title={!hasDatatable ? 'Add datatable' : 'Recreate datatable'}
        modalType={['CREATE_DATATABLE', 'RECREATE_DATATABLE']}>
        <DatatableForm recreate={hasDatatable}/>
      </LisaModal>
      <ConfirmDialog
        title='Change document type'
        type={'CHANGE_DOCUMENT_TYPE_DATATABLE'}
        labels={{ yes: 'Change' }}
        onCancel={() => {
          setDocumentType(getDocumentType()) // reset document type
        }}
        onConfirm={changeDocumentTypeCB}>
        <div className={classes.message}>
          Are you sure you want to change document type?
          <br/>
          If a current document contains table data it will be lost!
        </div>
      </ConfirmDialog>
      <ConfirmDialog
        title='Change document type'
        type={'CHANGE_DOCUMENT_TYPE'}
        labels={{ yes: 'Change' }}
        onCancel={() => {
          setDocumentType(getDocumentType()) // reset document type
        }}
        onConfirm={changeDocumentTypeCB}>
        <div className={classes.messageNormal}>
          Are you sure you want to change document type to {documentType?.name}?
        </div>
      </ConfirmDialog>
    </Grid>
  )
}
