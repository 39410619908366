import { FC, ReactNode, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, makeStyles, Tooltip, Typography, MenuItem, Chip } from '@material-ui/core'
import { Attachment as AttachmentType, AttachmentId } from 'types'
import { downloadQueryAttachment } from 'redux/slices/queriesSlice'
import { toFormattedDateTime } from 'utils/date'
import { MaybeForbiddenAction } from 'common/MaybeForbiddenAction/MaybeForbiddenAction'
import { LisaPaper } from 'common/LisaPaper/LisaPaper'
import { useGetQueryDataQuery } from 'services/api/queryApi'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '4px 0',
    padding: 16,
    background: theme.palette.grey2.light100,
    fontSize: '16px',
    color: theme.palette.black.main,
    borderRadius: 4
  },
  alertMessage: {
    flex: 1
  },
  attachmentName: {
    overflowWrap: 'anywhere',
    cursor: 'pointer',
    marginBlock: 8,
    fontWeight: 600,
    '&:hover': {
      color: theme.palette.statusColors.blue
    }
  },
  toolbarContainer: {
    borderBottom: `1px solid ${theme.palette.grey2.main}`,
    boxShadow: '0 0 0 0 rgba(0,0,0,0)',
    paddingLeft: '16px',
    borderRadius: '4px 4px 0 0',
    backgroundColor: `${theme.palette.grey2.main}`
  },
  attachmentChip: {
    height: '24px',
    fontSize: '13px',
    fontWeight: 500,
    background: theme.palette.blue.main,
    color: theme.palette.common.white,
    borderRadius: '4px',
    border: `2px solid ${theme.palette.blue.main}`,
    transition: 'all .1s linear',
    margin: '0 4px 4px 0'
  }
}))

type AttachmentProps = {
  attachment: AttachmentType,
  onDelete: (attachmentId: AttachmentId) => void,
  onSetReference: (attachmentId: AttachmentId) => void,
  onSetLabelsToDocument: (attachmentId: AttachmentId) => void,
  canDelete: boolean
}
export const Attachment: FC<AttachmentProps> = ({ attachment, onDelete, onSetReference, onSetLabelsToDocument, canDelete }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { attachmentId, queryId, isUsedAsRefDoc, isUpgradedToDocument } = attachment
  const { data: query } = useGetQueryDataQuery(queryId)
  const queryRefFileNotExists = !!query?.documentId

  const menuItems = useMemo<ReactNode>(() => {
    return [
      <MaybeForbiddenAction key={'delete-attachments'} isForbidden={!canDelete}>
        <MenuItem onClick={() => onDelete(attachmentId)} >
            Delete Attachment
        </MenuItem>
      </MaybeForbiddenAction>,
      <MaybeForbiddenAction key={'set-as-reference-file'} isForbidden={isUsedAsRefDoc || !queryRefFileNotExists}>
        <MenuItem onClick={() => onSetReference(attachmentId)}>
            Set as a Reference File
        </MenuItem>
      </MaybeForbiddenAction>,
      <MaybeForbiddenAction key={'add-a2k-label'} isForbidden={isUpgradedToDocument}>
        <MenuItem onClick={() => onSetLabelsToDocument(attachmentId)}>
           Add A2K label
        </MenuItem>
      </MaybeForbiddenAction>
    ]
  }, [])

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.alertMessage}>
        <Grid container direction={'column'}>
          <Grid item>
            <Typography variant={'body2'}>{toFormattedDateTime(new Date(attachment.createdDate))}</Typography>
          </Grid>
          <Grid item>
            <Tooltip title='download document'>
              <div className={classes.attachmentName} onClick={() => dispatch(downloadQueryAttachment(attachment.attachmentId))}>
                {attachment.name}
              </div>
            </Tooltip>
          </Grid>
          {isUsedAsRefDoc && <Grid item>
            <Chip
              className={classes.attachmentChip}
              variant='outlined'
              size='small'
              component={'span'}
              label={'Reference file'}/>
          </Grid>}
        </Grid>
      </Grid>
      <Grid item>
        <LisaPaper
          classes={{ toolbarContainer: classes.toolbarContainer }}
          direction={'column'}
          toolbar={{
            title: '',
            items: menuItems
          }}>
        </LisaPaper>
      </Grid>
    </Grid>
  )
}
