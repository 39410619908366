import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  bouncingLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '20px',
    '& div': {
      width: '10px',
      height: '10px',
      margin: '0px 2px',
      borderRadius: '50%',
      backgroundColor: '#a3a1a1',
      opacity: 1,
      animation: 'bouncing-loader 0.6s infinite alternate'
    },
    '& div:nth-child(2)': {
      animationDelay: '0.2s'
    },
    '& div:nth-child(3)': {
      animationDelay: '0.4s'
    }
  },
  '@global': {
    '@keyframes bouncing-loader': {
      to: {
        opacity: 0.1
      }
    }
  }
}))

// Children div's are acctualy the dots.
export const LoadingDotsIcon = () => {
  const classes = useStyles()
  return (
    <div className={classes.bouncingLoader}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
