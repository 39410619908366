import { lisaApi } from 'services/api/lisaApi'
import { DocumentId, LisaResponseExtended, TableView, TableViewCell, TableViewRow } from 'types'

export const tableViewApi = lisaApi.injectEndpoints({
  endpoints: (builder) => ({
    getTableView: builder.query<TableView, DocumentId>({
      query: (documentId) => ({
        url: `Document/TableView?documentId=${documentId}`
      }),
      providesTags: ['TableView']
    }),
    updateTableViewCell: builder.mutation<TableViewCell & LisaResponseExtended, {
      documentId: DocumentId
      rowIndex: number
      columnIndex: number
      cellContent: string
    }>({
      query: (params) => ({
        url: 'Document/TableViewCell',
        method: 'PUT',
        body: params
      })
    }),
    addTableViewRow: builder.mutation<TableViewRow & {
      message: string
      success: boolean
    }, {
      documentId: DocumentId
      rowIndex?: number
    }>({
      query: (params) => ({
        url: 'Document/TableView/AddRow',
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['TableView']
    })
  })
})

export const {
  useGetTableViewQuery,
  useUpdateTableViewCellMutation,
  useAddTableViewRowMutation
} = tableViewApi
