import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectId, QuerySortOptions, SortDirection, TransitionId, UserId } from 'types'
import { RootState } from 'redux/store'

export interface MyTransitionProjects {
  projectId: ProjectId
  projectName: string
}

export interface MyTransitionUsers {
  userId: UserId
  name: string
}

export interface NewQueriesTransitions {
  transitionId: TransitionId
  transitionName: string
}

export type NewQueriesSortOptions = 'time_added_asc' | 'time_added_desc' | 'priority_asc' | 'priority_desc'
  | 'due_date_asc' | 'due_date_desc'

export interface NewQueriesFilters extends QuerySortOptions {
  transitionId: TransitionId
}

export interface MyTransitionsFilters {
  term: string,
  projectId: ProjectId
  userId: UserId
  sort: SortDirection
}

export interface DashboardState {
  myTransitionsFilters: MyTransitionsFilters
  newQueriesFilters: NewQueriesFilters
}

const initialState: DashboardState = {
  myTransitionsFilters: {
    term: '',
    projectId: '',
    userId: '',
    sort: 'asc'
  },
  newQueriesFilters: {
    transitionId: '',
    timeAddedSort: 'desc',
    prioritySort: false,
    dueDateSort: false
  }
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateMyTransitionsFilters: (state, { payload }: PayloadAction<Partial<MyTransitionsFilters>>) => {
      state.myTransitionsFilters = {
        ...state.myTransitionsFilters,
        ...payload
      }
    },
    resetMyTransitionFilters: (state) => {
      state.myTransitionsFilters = initialState.myTransitionsFilters
    },
    setNewQueriesSortOption: (state, { payload }: PayloadAction<NewQueriesSortOptions>) => {
      switch (payload) {
      case 'due_date_asc':
      case 'due_date_desc':
        state.newQueriesFilters.prioritySort = false
        state.newQueriesFilters.timeAddedSort = false
        state.newQueriesFilters.dueDateSort = payload === 'due_date_asc' ? 'asc' : 'desc'
        break
      case 'time_added_asc':
      case 'time_added_desc':
        state.newQueriesFilters.prioritySort = false
        state.newQueriesFilters.dueDateSort = false
        state.newQueriesFilters.timeAddedSort = payload === 'time_added_asc' ? 'asc' : 'desc'
        break
      case 'priority_asc':
      case 'priority_desc':
        state.newQueriesFilters.timeAddedSort = false
        state.newQueriesFilters.dueDateSort = false
        state.newQueriesFilters.prioritySort = payload === 'priority_asc' ? 'asc' : 'desc'
        break
      }
    },
    setNewQueriesTransition: (state, { payload }: PayloadAction<TransitionId>) => {
      state.newQueriesFilters.transitionId = payload
    },
    resetNewQueriesFilters: (state) => {
      state.newQueriesFilters = initialState.newQueriesFilters
    }
  }
})

export const {
  updateMyTransitionsFilters,
  setNewQueriesSortOption,
  setNewQueriesTransition,
  resetMyTransitionFilters,
  resetNewQueriesFilters
} = dashboardSlice.actions

export const useDashboard = (state: RootState) => state.dashboard

export default dashboardSlice.reducer
