import { FC, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { LisaForm, useLisaForm } from 'common/Form/LisaForm'
import { closeModal } from 'redux/slices/appSlice'
import { DocumentParserId } from 'types'
import Dropdown from 'common/LisaControls/Dropdown'
import { WithLoader } from 'common/WithLoader/WithLoader'
import { useDocumentId } from 'context/DocumentContext'
import { useChangeDocumentParserMutation, useGetDocumentParsersQuery } from 'services/api/documentApi'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '32px',
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  paper: {
    padding: '24px'
  },
  sectionTitle: {
    fontSize: '14px',
    margin: '32px 0 6px 0',
    fontWeight: 700,
    letterSpacing: '.8px',
    textTransform: 'uppercase'
  },
  actionButtons: {
    padding: '32px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  }
}))

const tableSourceOptions = [
  // { value: '', text: 'Select table source' },
  { value: 'this_doc', text: 'This document' }
  // { value: 'excel', text: 'Excel' },
  // { value: 'xml', text: 'XML' }
]

type FormValues = {
  tableSource: '' | 'this_doc' | 'excel' | 'xml'
  parser: DocumentParserId
}
type FormErrors = {
  tableSource: boolean
  parser: boolean
}
const validate = ({ tableSource }: FormValues): {isValid: boolean, errors: FormErrors | null} => {
  if (tableSource === '') {
    return {
      isValid: false,
      errors: { parser: true, tableSource: false }
    }
  }
  return { isValid: true, errors: null }
}

type DatatableFormProps = {
  recreate?: boolean
}
export const DatatableForm: FC<DatatableFormProps> = ({ recreate = false }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data: documentParsers = [], isLoading } = useGetDocumentParsersQuery()
  const documentId = useDocumentId()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const {
    values, setValue
  } = useLisaForm<FormValues, FormErrors>(
    { tableSource: 'this_doc', parser: documentParsers[0]?.id ?? '' }, validate, true)
  const { parser, tableSource } = values
  const [changeDocumentParser] = useChangeDocumentParserMutation()

  useEffect(() => {
    if (!isLoading) {
      setValue('parser', documentParsers[0]?.id ?? '')
    }
    if (buttonRef.current) {
      buttonRef.current.focus()
    }
  }, [isLoading])

  return (
    <WithLoader loading={isLoading} hasResults={true}>
      <div className={classes.root}>
        <LisaForm onSubmit={e => { e.preventDefault() }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Dropdown
                label={'Table source'}
                name={'tableSource'}
                value={tableSource}
                onChange={(e) => setValue('tableSource', e.target.value)}
                items={tableSourceOptions}/>
            </Grid>
            <Grid item xs={6}>
              <Dropdown
                label={'Select Data Parser'}
                disabled={tableSource === ''}
                name={'tableSource'}
                value={parser}
                onChange={(e) => setValue('parser', e.target.value as DocumentParserId | '')}
                items={documentParsers.map(_ => ({ value: _.id, text: _.name }))}/>
            </Grid>
            {
              recreate &&
              <Grid item xs={12}>
                <Typography color={'error'} variant={'body2'}>
                  Recreating the datatable will result in the loss of all row-status and
                  references to queries or other documents
                </Typography>
              </Grid>
            }
          </Grid>
        </LisaForm>
        <div className={classes.actionButtons}>
          <Button
            ref={buttonRef}
            onClick={() => {
              if (parser !== '') {
                changeDocumentParser({
                  documentParserId: parser,
                  documentId
                })
                dispatch(closeModal())
              }
            }}
            variant="contained"
            color="primary"
            size="small"
            disabled={false}>
            Start
          </Button>
          <Button
            onClick={() => dispatch(closeModal())}
            variant="contained"
            color="primary"
            size="small">
            Cancel
          </Button>
        </div>
      </div>
    </WithLoader>
  )
}
