import { lisaApi } from 'services/api/lisaApi'
import { LisaResponse, Project, ProjectId, UserId } from 'types'

type BasicProjectProps = {
  projectId?: ProjectId,
  name?: string,
  userId?: UserId | null
}
type CreateOrEditProjectProps = BasicProjectProps & {
  description?: string,
  isActive?: boolean
}

export const projectsApi = lisaApi.injectEndpoints({
  endpoints: (builder) => ({
    getSingleProject: builder.query<Project, ProjectId>({
      query: (id) => `Project/${id}`,
      providesTags: ['Project']
    }),
    getAllProjects: builder.query<Project[], void>({
      query: () => 'Project',
      providesTags: ['Projects']
    }),
    getAllProjectsSearch: builder.query<(Project & { transitions: null })[], string>({
      query: (search) => `Project/TransitionList?search=${search}`,
      providesTags: ['Projects']
    }),
    updateOrCreateProject: builder.mutation<LisaResponse, CreateOrEditProjectProps>({
      query: (params) => ({
        url: `Project/${params.projectId ? 'update' : 'create'}`,
        method: params.projectId ? 'PUT' : 'POST',
        body: params
      }),
      invalidatesTags: ['Projects']
    }),
    archiveProject: builder.mutation<LisaResponse, BasicProjectProps>({
      query: (params) => ({
        url: 'Project/delete',
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['Project', 'Projects']
    })
  })
})

export const {
  useGetSingleProjectQuery,
  useGetAllProjectsQuery,
  useGetAllProjectsSearchQuery,
  useUpdateOrCreateProjectMutation,
  useArchiveProjectMutation
} = projectsApi
