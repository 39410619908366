import { ChangeEvent } from 'react'

export enum LoadingState {
  Init,
  Pending,
  Completed
}

export type ModalType = null | // If null -> modal closed
  'NEW_A2K_LABEL' | 'RENAME_A2K_LABEL' | 'DELETE_A2K_LABEL' |
  'DOCUMENT_STATUS_CHANGE' | 'DOCUMENT_STATUS_CHANGE_SEARCH_FORM' |
  'ADD_FROM_ONE_DRIVE' | 'UPLOAD_FILES' | 'ASSIGN_LABELS' |
  'ONE_DRIVE_IMPORT' | 'EXPORT_DELIVERY_BIBLE' |
  'DOCUMENT_SEARCH' | 'EXPORT_QUERIES_TO_EXCEL' | 'EDIT_USER' | 'EDIT_NOTE' |
  'INVITE_NEW_USER' | 'EDIT_PROFILE' | 'CLONE_TO_A2K' | 'TRANSITION_FORM' |
  'TRANSITION_FORM_PROJECTS' | 'TRANSITION_FORM_MENU' | 'PROJECT_FORM' | 'PROJECT_FORM_EDIT' |
  'CREATE_DATATABLE' | 'EDIT_DATATABLE' | 'CREATE_QUERY' | 'EDIT_QUERY' |
  'TERMS' | 'PRIVACY_POLICY' | 'LEGAL' | 'ADD_COMMENT_TO_QUERY' | 'DISCLAIMER' | 'RECREATE_DATATABLE' |
  'RENAME_DOCUMENT' | 'ADD_TABLE_VIEW_ROW' | 'CHANGE_WORKPACK_REFERENCE' | 'CREATE_NOTIFICATION' | 'EDIT_NOTIFICATION' |
  'COLUMN_CONFIGURATOR' | 'COLUMN_CONFIGURATOR_SEARCH' | // Need two CC modals because search modal is on same page as a2k, import and client
  'SUBSCRIBER_FORM' | 'CLIENT_SETUP_FORM' | 'RECYCLE_BIN' | 'COLUMN_CONFIGURATOR_RECYCLE_BIN' |
  'ADD_A2K_LABEL_REF_FILE' | 'CREATE_FOLDER' | 'UPLOAD_FOLDER' | 'RENAME_FOLDER' | 'UPLOAD_LARGE_FILE'

export type ConfirmDialogType = null | // If null -> dialog closed
  'SET_HIGH_OCR_PRIORITY' | 'SEND_DOCUMENTS_TO_OCR' | 'MARK_DOCUMENT_FOR_DELETION' |
  'REPLACE_DOCUMENT' | 'DOCUMENT_OCR_INFO' | 'DEACTIVATE_USER' | 'REACTIVATE_USER' | 'RESEND_INVITATION' |
  'DEACTIVATE_TRANSITION' | 'DEACTIVATE_PROJECT' | 'OPEN_ABOUT_DIALOG' | 'ATTACHMENT_DELETE' |
  'IMPORT_STRUCTURE' | 'CHANGE_DOCUMENT_TYPE' | 'CHANGE_DOCUMENT_TYPE_DATATABLE' | 'END_IMPORT_PROCESS' |
  'PROMPT_DIALOG' | 'DELETE_NOTIFICATION' | 'SET_DEFAULT_RULE_DIALOG' | 'EXCLUDE_A2K_RULES_DOCUMENTS' |
  'ACTIVATE_TENANT' | 'DEACTIVATE_TENANT' | 'REMOVE_DEFAULT_RULE_DIALOG' | 'SET_AS_REF_FILE' | 'DELETE_FOLDER' | 'INIT_ARCHIVE_FINISHED'

export interface PaginatedResults<T> {
  rows: T[]
  page: number
  pageSize: number
  pages: number
  total: number
}

export type UserId = string
export type QueryId = string
export type SortDirection = 'asc' | 'desc' | false
export type SortColumn = 'Name' | 'Query' | 'Email' | 'Company' | 'Labels'
  | 'Roles' | 'OcrPriority' | 'Quality' | 'BusinessStatus' | 'OcrStatus' | 'ProcessStatus'
  | 'DocType' | 'ImportedBy' | 'ImportDate'

export enum CommentVisibility {
  Internal = 1,
  External = 2
}

export enum QueryVisibility {
  Internal = 1,
  External = 2
}

export enum QueryFlag {
  Critical = 'Critical',
  Sideletter = 'Sideletter'
}

export enum QueryStatus {
  Unresolved = 0, // Unresolved exists only in UI to show all but closed and workpack queries
  New = 1,
  Open = 2,
  Pending = 3,
  Delayed = 4,
  Closed = 5,
  Sideletter = 6,
  WorkPack = 7
}

export enum QueryType {
  DeliveryDocuments = 1,
  Physical = 2,
  AmpLdnd = 3,
  Util = 4,
  AD = 5,
  SB = 6,
  Mod = 7,
  HTC = 8,
  OCCM = 9,
  LDG = 10,
  Eng1 = 11,
  Eng2 = 12,
  Eng3 = 13,
  Eng4 = 14,
  APU = 15,
  Repairs = 16,
  Manuals = 17,
  NLG = 18,
  MLGLH = 19,
  MLGRH = 20,
  CLGLH = 21,
  CLGRH = 22
}

export type DocumentTypeId = string

export enum FileTypeEnum {
  PDF = 1,
  Excel = 2,
  Text = 3,
  Image = 4
}

export interface DocumentType {
  id: DocumentTypeId
  name: string
  type: FileTypeEnum
  code: string
}

export type DocumentParserId = string

export interface DocumentParser {
  id: DocumentParserId
  name: string
}

export enum QueryPriority {
  None = 0,
  Critical = 1,
  Normal = 2,
  High = 3
}

export enum RiskLevel {
  None = 0,
  Information = 1,
  Low = 2,
  Medium = 3,
  High = 4
}

export enum TransitionType {
  PhaseIn = 1,
  PhaseOut = 2,
  PrePurchaseInspection = 3,
  ArcReview = 4,
  PhaseInOut = 5,
  UnassignedDocuments = 6,
  AssetManagement = 7,
  LessorAircraft = 8,
  PreliminaryInspection = 9
}

export interface QuerySortOptions {
  prioritySort: SortDirection
  dueDateSort: SortDirection
  timeAddedSort: SortDirection
}

export interface Query {
  assignTo: UserId
  assignToFirstName: Nullable<string>
  assignToLastName: Nullable<string>
  assignToUrl: Nullable<string>
  ataChapter: string // 'as' ?
  closedDate: string | null
  createdBy: UserId
  createdDate: string
  critical: boolean
  deletedBy: UserId | null
  documentId: DocumentId | null
  documentName: string
  dueDate: string | Date
  isDeleted: boolean
  isError: boolean
  labelId: LabelId | null
  labelName: string
  message: string
  partNo: string
  queryId: string
  queryPriority: QueryPriority
  queryReference: string
  queryRelation: number
  queryStatus: QueryStatus
  queryText: string
  queryType: QueryType
  reporter: UserId
  reporterFirstName: Nullable<string>
  reporterLastName: Nullable<string>
  reporterUrl: Nullable<string>
  riskLevel: RiskLevel
  serialNo: string
  title: string
  transitionId: TransitionId
  transitionName: string | null
  updatedBy: UserId | null
  updatedDate: string | null
  visibility: QueryVisibility
  wotc: Nullable<string>
  reporterPermissionGroup: ReporterPermissionGroup
}

export interface LabelResponse extends WorkorderWorkpackage {
  labelId: LabelId
  parentId: LabelId | null
  documentId: DocumentId | null
  isSelected: boolean
  labelNo?: string
  name: string
  closedQueryCount?: number
  createdDate?: string | null
  documentCount?: number
  hasDocuments?: boolean
  queryCount?: number
  children: LabelResponse[]
  isTemplateLabel: boolean,
  ruleIndicator?: Nullable<boolean>
}

export interface StructureLabelsResponse<L = LabelResponse> {
  structure: L
  isError: boolean
  message: string
}

export interface MxHistoryKpisResponse {
  labelMxHistoryKpis: {
    wpOpenCount: number
    wpClosedCount: number
    woOpenCount: number
    woClosedCount: number
  }
  message: string
  isError: boolean
}

// @todo All types from here should live in their specific hook or reducer

// Projects
export type ProjectId = string

// @todo remove string
export type TransitionFieldType =
  'transitionName'
  | 'transitionComponent'
  | 'transitionType'
  | 'manufacturer'
  | 'aircraftType'
  |
  'variation'
  | 'msn'
  | 'previousRegistration'
  | 'newRegistration'
  | 'owner'
  | 'previousOperator'
  | 'newOperator'
  |
  'apuPn'
  | 'apuSn'
  | 'engineAmount'
  | 'landingGears'
  | 'engineESN1'
  | 'engineESN2'
  | 'engineESN3'
  | 'engineESN4'
  |
  'landingGearPNs'
  | 'landingGearSNs'
  | 'familyType'
  | 'manufacturerName'
  | 'modelName'
  |
  'e1PropellerPN'
  | 'e1PropellerSN'
  | 'e2PropellerPN'
  | 'e2PropellerSN'
  | 'engineType'

export enum AircraftType {
  A220 = 1,
  A318,
  A319,
  A320,
  A321,
  A310,
  A330,
  A340,
  A350,
  A380,
  B737,
  B757,
  B767,
  B777,
  B787,
  B747,
  OTHER,
  ERJ145,
  ERJ170,
  ERJ190,
  ERJ170E2,
  ERJ190E2,
  CRJ100,
  CRJ200,
  CRJ700,
  CRJ900,
  CRJ1000,
  Q400,
  DHC8,
  ATR42,
  ATR72
}

export enum FamilyType {
  NB = 1,
  WB,
  RJ
}

export enum TransitionComponent {
  Aircraft = 1,
  Helicopter = 2,
  Engine = 3,
  LandingGear = 4
}

export enum Manufacturer {
  Boeing = 1,
  Airbus,
  Embraer,
  BombardierAerospace,
  Comac,
  Other,
  DeHavilandCanada,
  AvionTransportDeRegional
}

export type Nullable<T> = T | null

export enum LandingGear {
  NLG = 'NLG',
  LHMLG = 'LHMLG',
  RHMLG = 'RHMLG',
  LHCLG = 'LHCLG',
  RHCLG = 'RHCLG',
  CLDG = 'CLDG'
}

export enum EngineType {
  Jet = 1,
  Turboprop = 2
}

export interface Transition {
  aircraftType: AircraftType | 0
  familyType: Nullable<FamilyType>
  apuPn: Nullable<string>
  apuSn: Nullable<string>
  cLdhPN: Nullable<string>
  cLdhSN: Nullable<string>
  engineType: Nullable<EngineType>
  engineAmount: number
  engineESN1: Nullable<string>
  engineESN2: Nullable<string>
  engineESN3: Nullable<string>
  engineESN4: Nullable<string>
  e1PropellerPN: Nullable<string>
  e1PropellerSN: Nullable<string>
  e2PropellerPN: Nullable<string>
  e2PropellerSN: Nullable<string>
  landingGears: Nullable<LandingGear[]>
  lhClgPN: Nullable<string>
  lhClgSN: Nullable<string>
  lhMlgPN: Nullable<string>
  lhMlgSN: Nullable<string>
  manufacturer: Nullable<Manufacturer>
  manufacturerName: Nullable<string>
  msn: Nullable<string>
  newOperator: Nullable<string>
  nlgPN: Nullable<string>
  nlgSN: Nullable<string>
  owner: Nullable<string>
  previousOperator: Nullable<string>
  rhClgPN: Nullable<string>
  rhClgSN: Nullable<string>
  rhMlgPN: Nullable<string>
  rhMlgSN: Nullable<string>
  transitionComponent: TransitionComponent | 0
  variation: Nullable<number>
  description: string
  isActive: boolean
  isError: boolean
  message: string
  model: string
  modelName: Nullable<string>
  name: string
  projectId: ProjectId
  registration: string
  registrationNew: string
  transitionId: TransitionId
  transitionType: TransitionType
  transitionTeamMembersCount: number
  userId: UserId
  users: Nullable<UserId[]>
  importInProgress: boolean
  tenantId: string
  initArchiveFinished: boolean
}

export interface TransitionItem {
  closedQueryCount: number
  createdDate: string
  estimatedCount: number
  projectId: ProjectId
  projectName: string
  queryCount: number
  transitionId: TransitionId
  transitionName: string
  users: User[]
}

export interface UserSettings {
  sidebarExpanded: boolean
  showSearchFilters: boolean
  a2KSettings: {
    a2KListA2KLabels: boolean
    a2KListDocumentStatus: boolean
    a2KListDocumentType: boolean
    a2KListFilename: boolean
    a2KListOrigin: boolean
    a2KListProcessingStatus: boolean
    a2KListQueries: boolean
  },
  clientSettings: {
    clientListDocumentStatus: boolean
    clientListDocumentType: boolean
    clientListFilename : boolean
    clientListQueries: boolean
  },
  importSettings: {
    importListA2KLabel: boolean
    importListDocumentType: boolean
    importListFilename: boolean
    importListImportDate: boolean
    importListProcessingPriority: boolean
    importListProcessingStatus: boolean
    importListImportedBy: boolean
  },
  searchSettings: {
    globalSearchListA2KLabels: boolean
    globalSearchListDocumentStatus: boolean
    globalSearchListDocumentType: boolean
    globalSearchListFilename: boolean
    globalSearchListImportDate: boolean
    globalSearchListImportedBy: boolean
    globalSearchListOrigin: boolean
    globalSearchListProcessingStatus: boolean
    globalSearchListQueries: boolean
  },
  recycleBinSettings: {
    recycleBinA2KLabels: boolean
    recycleBinDocumentStatus: boolean
    recycleBinDocumentType: boolean
    recycleBinFilename: boolean
    recycleBinOrigin: boolean
    recycleBinProcessingStatus: boolean
    recycleBinDeletedAt: boolean
    recycleBinDeletedBy: boolean
  }
}

export interface User {
  userId: UserId
  firstName: string
  lastName: string
  email: string
  profilePictureURL: string,
  jobTitle: string
  company: string
  settings: UserSettings
  tenantId: TenantId
  wasLogged: boolean
  tenants: {
    tenantId: TenantId
    code: string
    logo: string
    avatar: string
    name: string
  }[],
  roles: Roles[],
  transitionList: TransitionList[]
}

export interface TransitionList {
  description: string
  isActive: boolean
  isError: boolean
  message: null
  name: string
  projectId: string
  registration: string
  registrationNew: string
  transitionId: string
  transitionType: number
  userId: string
  users: string
}

// @todo For example ProjectState should probably live in useProject hook
export interface Project {
  projectId: ProjectId
  name: string
  description: string
  userId: UserId
  message: string
  isActive: boolean
  transitionCount: number
  isError: boolean
  transitions: Transition[]
}

export interface UsersList {
  rows: UserListRow[]
  page: number
  pageSize: number
  pages: number
  total: number
}

export interface Roles {
  name: string
  roleId: string
}

export interface UserListRow {
  company: string
  createdBy: null
  email: string
  firstName: string
  isError: false
  jobTitle: string
  lastName: string
  message: string
  newPassword: null
  password: null
  roles: Roles[]
  transitionList: TransitionList[]
  transitions: null,
  profilePictureURL: string
  url: string
  userId: string
  isActive: boolean
}

export type LabelId = number
export type DocumentId = string
export type TransitionId = string
export type StorageId = string
export type ClientFolderId = number
export type WorkpackageUuid = string
export type WorkorderUuid = string
export type EventUuid = string

export interface LabelBase {
  labelId: LabelId
  name: string
}

export type WorkorderState = 'O' | 'C'
export type WorkpackageStatus = 'Open' | 'Closed'
export type EventState = string
export interface WorkorderWorkpackage {
  workpackageUuid?: Nullable<WorkpackageUuid>
  workpackageStatus?: Nullable<WorkpackageStatus>
  workorderUuid?: Nullable<WorkorderUuid>
  workorderState?: Nullable<WorkorderState>
  eventUuid?: Nullable<EventUuid>
  eventState?: Nullable<EventState>
}

export interface Label extends LabelBase, WorkorderWorkpackage {
  parentId: LabelId | null
  documentId: DocumentId | null
  isSelected: boolean
  transitionId?: TransitionId | null
  closedQueryCount?: number
  createdDate?: string | null
  documentCount?: number
  hasDocuments?: boolean
  queryCount?: number
  children: Label[]
  // custom properties
  path?: LabelId[] // for live updates
  parentName?: string // for hover tooltip
  level?: number // Only level > 2 can be added to the document
  isTemplateLabel: boolean,
  ruleIndicator?: Nullable<boolean>
}

export interface AssignedLabel extends Label {
  assignedToAll: boolean
}

export enum BusinessStatus {
  OcrProcessing = 0,
  Open = 1,
  Clarification = 2,
  Irrelevant = 3,
  Reviewed = 4,
  Deleted = 5,
  Accepted = 6
}

export enum ImportFileStatus {
  Ready,
  Working,
  Finished,
  Cancelled,
  NotFound,
  Error
}

export enum OcrStatus {
  Ready = 1,
  Processing = 2,
  Success = 3,
  Failed = 4,
  Unsupported = 5,
  InList = 6,
  Timeout = 7,
  Cancelled = 8,
  ExtractedText = 9,
  Incomplete = 10
}

export enum DtrStatus {
  Ready = 0,
  Failed = 1,
  Processing = 2,
  Success = 3
}

export enum ParserStatus {
  Ready = 1,
  Processing = 2,
  Success = 3,
  Failed = 4
}

export interface ImportDocument extends LisaDocumentWithOCRStatus, LisaDocumentWithLabels {
  businessStatus: BusinessStatus
  highPriority: boolean
  importStatus: ImportFileStatus
  storageId: StorageId
  processingStatus: string
  importedBy: string
  importDate: string
}

export interface A2KDocument extends LisaDocumentWithStatus, LisaDocumentWithQueries, LisaDocumentWithOCRStatus, LisaDocumentWithLabels, WithOrigin {
  businessStatus: BusinessStatus
  businessStatusName: string
  highPriority: false
  importStatus: number
  storageId: StorageId
}

export interface OCRDocument extends LisaDocument {
  filePath: string
  ocrMessages: string[]
  quality: number
}

export interface LisaDocumentWithStatus extends LisaDocument {
  businessStatus: BusinessStatus
  businessStatusName: string
}

export interface LisaDocumentWithOCRStatus extends LisaDocument {
  ocrStatus: OcrStatus
}

export interface LisaDocumentWithQueries extends LisaDocument {
  closedQueryCount: number
  queryCount: number
}

export interface LisaDocumentWithLabels extends LisaDocumentWithStatus {
  labels: LabelBase[]
}

export interface WithOrigin {
  filePath: string
  importFolder: string
}

export interface WithImport {
  importedBy: string
  importDate: string
}

export interface ClientDocument extends LisaDocumentWithStatus, LisaDocumentWithQueries {
  statusLocked: boolean
  labels: LabelBase[] // @todo It is always null actually
}

export interface SearchDocument extends LisaDocumentWithQueries, LisaDocumentWithStatus, LisaDocumentWithOCRStatus, LisaDocumentWithLabels, WithOrigin, WithImport {
  fileName: string
  status: BusinessStatus
  storageId: StorageId
}

export interface DeletedDocument extends LisaDocumentWithQueries, LisaDocumentWithStatus, LisaDocumentWithOCRStatus, LisaDocumentWithLabels, WithOrigin, WithImport {
  fileName: string
  status: BusinessStatus
  storageId: StorageId
  deletedBy: string
  deletedDate: string
}

export interface LisaDocument {
  documentId: DocumentId
  extension: string
  name: string,
  typeKey: string,
  typeName: string,
  documentTypeId: DocumentTypeId,
  docTypeRecognitionResult: string,
  docTypeRecognitionResultByPages: [],
  processingStatus: string
}

export interface RecentDocument {
  closedQueriesCount: number
  documentId: DocumentId
  documentName: string
  extension: string
  queriesCount: 1
  status: BusinessStatus
  transitionId: TransitionId
  transitionName: string
}

export interface OCRReport {
  avgPages: number
  avgQuality: number
  documentsInProgress: number
  filesAwaitingOCR: number
  ocrEndDate: string | null
  ocrStartDate: string | null
  processedDocuments: number
  processedPages: number
  processedWithErrors: number
  totalDocuments: number
  unsupportedDocuments: number
}

export interface ProcessingReport {
    dataExtractionInQueue: number,
    dataExtractionSuccess: number,
    dataExtractionCompleted: number,
    dataExtractionIncomplete: number,
    dataExtractionExtractedText: number,
    dataExtractionError: number,
    dataExtractionUnsupported: number,
    dataExtractionFailed: number,
    dataExtractionCanceled: number,
    dataExtractionTimeout: number,
    dtrInQueue: number,
    dtrSuccess: number,
    dtrError: number,
    autoLabellingInQueue: number,
    autoLabellingSuccess: number,
    autoLabellingError: number,
    autoLabellingNoMatch: number,
    processingStarted: string | Date,
    filesUploaded: number,
    extractedPages: number,
    avgExtractedPagesPerFile: number,
    avgOcrQuality: number
  }

export interface OCRUnprocessedDocuments {
  documents: OCRDocument[]
  isError: boolean
  message: string | null
}

export type OCRUnsupportedDocuments = OCRDocument[]
export type OCRLowQualityDocuments = PaginatedResults<OCRDocument>
export type AttachmentId = number

export interface Attachment {
  attachmentId: AttachmentId
  createdBy: string
  createdDate: string
  extension: string
  fileStoragePath: string
  guid: string
  isError: boolean
  message: string | null
  name: string
  queryId: QueryId
  isUsedAsRefDoc: boolean
  isUpgradedToDocument: boolean
}

export type QueryCommentId = number

export interface QueryComment {
  assignTo: UserId
  comment: string
  commentVisibility: CommentVisibility // Set to 2 If visible to external user
  createdBy: UserId
  createdDate: string
  isError: boolean
  message: string | null
  queryCommentId: QueryCommentId
  queryId: QueryId
  isExternal: boolean, // Only TRUE if created by External user
  createdByUrl: string,
  createdByFirstName: string,
  createdByLastName: string
}

export interface QueriesRequestParams {
  assignTo: UserId
  queryStatus: QueryStatus | ''
  queryType: QueryType | ''
  reporter: UserId
  searchText: string
  transitionId: TransitionId
  userId: UserId
  wildcard: boolean,
  labelId: string,
  label: string,
  documentId: string,
  documentName: string,
  fromClientStructure?: boolean,
  related: boolean
}

export interface QueriesExportParams {
  transitionId: TransitionId
  userId: UserId
  queryFlags?: QueryFlag[],
  queryStatuses?: QueryStatus[],
  visibility?: QueryVisibility[],
  related: boolean
}

export interface ExcelFile {
  isError: boolean,
  message: string,
  fileName: string,
  content: string
}

export interface LisaFile {
  content: string | null
  documentId: DocumentId
  duration: string
  excelConverterEnabled: boolean
  excelUrl: string
  extension: string
  originalFileId: number
  isError: boolean
  isProcessed: boolean
  lowConfident: string
  message: string
  name: string // with extension
  ocrStatus: OcrStatus
  pages: string
  queriesCount: string
  size: number
  storageId: StorageId
  totalCharacters: string
  uncertainCharacters: string
  url: string // Use this to fetch content
  documentTypeId: DocumentTypeId | null
  documentParserId: DocumentParserId | null
  typeKey: string,
  typeName: string,
  docTypeRecognitionResult: string,
  docTypeRecognitionResultByPages: string[]
}

export interface DocumentMetaData {
  businessStatus: BusinessStatus
  comment: string
  createdBy: UserId | null
  createdDate: string | null
  documentId: DocumentId
  documentIds: DocumentId[] | null // or string | null ?
  importFolder: string
  isError: false
  message: string
  metadataId: string
  references: DocumentReference[]
  updatedBy: UserId | null
  updatedDate: string | null,
  filePath: string,
  statusLocked: boolean
}

export type ReferenceId = string

export interface DocumentReference {
  createdBy: UserId | null
  createdDate: string | null
  documentId: DocumentId
  isError: boolean
  message: string
  referenceId: ReferenceId
  referenceKey: string
  referenceValue: string // Seems to be DocumentId
  updatedBy: UserId | null
  updatedDate: string | null
}

export type StructureId = string
export type FolderId = string // all upper case hex
export interface Folder {
  id: FolderId
  extension: string | null
  size: number
  isFolder: boolean
  name: string
  storageId: StorageId | null
}

export interface FileTree extends Folder {
  children: FileTree[]
  filesNo: number
  unlabelledDocsCount: number
  parentId: StructureId | null
}

// TableView
export interface TableViewCell<T extends any = string> {
  cellContent: T
  columnIndex: number
}

export interface TableViewRow {
  columns: TableViewCell[]
  rowIndex: number
  documentId: Nullable<DocumentId>
  rowName: string
  rowOrder: number
}

export interface TableView {
  documentId: DocumentId
  header: TableViewRow
  rows: TableViewRow[]
}

export enum TableViewRowType {
  Context,
  New
}

// end of TableView

export type FileType = 'video' | 'image' | 'pdf' | 'other'

export enum ProgressType {
  High = 3,
  Medium = 2,
  Low = 1
}

export interface FileUpload {
  loading: LoadingState
  isError: boolean
  uploadPercent: number
}

export interface Note {
  noteId: string
  text: string
}

export interface LisaResponse {
  isError: boolean
  message: string | null
}

export interface LisaResponseExtended {
  success: boolean
  message: Nullable<string>
  statusCode: number
  isSuccessStatusCode: boolean
}

export interface OCRQueue {
  importDate: string
  importType: string
  transitionId: TransitionId
  transitionName: string
  docsInOrWaitingOCRCount: number
  TotalDocuments: number
  isHighPriority: boolean
  ocrQueuePercents: number
  timeElapsed: number
}

// Transition reports
export interface TransitionReport<D> {
  notes: string
  data: D[]
}

export interface WeeklyValue {
  year: number
  week: number
}

export type OverallTransitionProgressReportValue = WeeklyValue & {
  transitionPercents: number
  progress: ProgressType
}
export type OverallTransitionProgressReport = TransitionReport<OverallTransitionProgressReportValue>
export type DocumentStatusReportValue = WeeklyValue & { uploadedDocsCount: number, digitalisedDocsCount: number }
export type DocumentStatusReport = TransitionReport<DocumentStatusReportValue>
export type WeeklyQueryStatusReportValue = WeeklyValue & {
  closedQueriesCount: number
  delayedQueriesCount: number
  newQueriesCount: number
  openQueriesCount: number
  pendingQueriesCount: number
  sideletterQueriesCount: number
  workpackQueriesCount: number
}
export type WeeklyQueryStatusReport = TransitionReport<WeeklyQueryStatusReportValue>
export type DeliveryBibleReportValue = WeeklyValue & {
  reviewedDocsCount: number
  reviewedPercents: number
  acceptedDocsCount: number
  acceptedPercents: number
}
export type DeliveryBibleReport = TransitionReport<DeliveryBibleReportValue>

export interface QueryStatusReport {
  closedQueriesCount: number
  newQueriesCount: number
  openQueriesCount: number
  pendingQueriesCount: number
  delayedQueriesCount: number
  sideletterQueriesCount: number
  workPackQueriesCount: number
  totalQueriesCount: number
}

export type SearchDocumentsSortColumn = 'Name' | 'ProcessingStatus' | 'Query' | 'BusinessStatus' | 'DocType'
  | 'ImportFolder' | 'ImportDate' | 'ImportedBy'

export type DeletedDocumentsSortColumn = 'Name' | 'ProcessingStatus' | 'Query' | 'BusinessStatus' | 'DocType'
  | 'ImportFolder' | 'ImportDate' | 'ImportedBy' | 'DeletedAt' | 'DeletedBy'

export type SearchParams = {
  transitionId: TransitionId
  operatorAnd: boolean
  operatorOr: boolean
  matchCase: boolean
  searchText: string
  page: number
  size: number
  hasFilter: boolean
  noLabel: boolean
  businessStatus: BusinessStatus | ''
  label: string
  labelId: string | ''
  hasQueries: string | number
  documentTypes: DocumentTypeId[]
  sortOrder?: SortDirection,
  sortColumn?: SearchDocumentsSortColumn
}

export type DeletedParams = {
  transitionId: TransitionId
  operatorAnd: boolean
  operatorOr: boolean
  matchCase: boolean
  searchText: string
  page: number
  size: number
  hasFilter: boolean
  noLabel: boolean
  businessStatus: BusinessStatus | ''
  label: string
  labelId: string | ''
  hasQueries: string | number
  documentTypes: DocumentTypeId[]
  sortOrder?: SortDirection,
  sortColumn?: DeletedDocumentsSortColumn
}
export type SearchResults = PaginatedResults<SearchDocument>
export type DeletedResults = PaginatedResults<DeletedDocument>

export type DropDownOnChangeEvent = ChangeEvent<{ name?: string; value: unknown }>

// @todo bad namings
export enum A2KConditionType {
  FILENAME = 'filename',
  DOCUMENT_TYPE = 'doctyperecognitionresult',
  CONTENT_TYPE = 'textcontent'
}

export enum A2KConditionOperator {
  EQUAL = '=',
  CONTAINS = 'CONTAINS',
  STARTS_WITH = 'STARTS',
  ENDS_WITH = 'ENDS',
  DOES_NOT_CONTAINS = 'NOT_CONTAINS',
  IS = '=',
  IS_NOT = '!='
}

export interface A2KCondition {
  type: A2KConditionType
  operator: A2KConditionOperator
  value: string
}

export enum A2KLogicalOperator {
  AND = 'AND',
  OR = 'OR'
}

// Tenants
export type TenantId = string
export interface Tenant {
  tenantId: TenantId
  name: string
  email: string
  emailConfirmed: boolean
  logo: string
  avatar: string
  active: boolean
  tenantTitle: string
  tenantFirstName: string
  tenantLastName: string
  tenantJobDescription: string
  tenantEmail: string
  tenantEmailConfirmed: boolean
  tenantTelephone: string
  tenantTelephoneConfirmed: boolean
  tenantAddress: string
  tenantCity: string
  tenantCountry: string
  tenantZipCode: string
  billingAddressSame: boolean
  billingAddress: string
  billingCity: string
  billingCountry: string
  billingZipCode: string
  validSince: Nullable<string>
  validThru: Nullable<string>
  subscriptionType: string // @todo
  superAdmin: boolean
  expirationNotify: boolean
  userCount: number
  assetCount: number
  isDefault: boolean
  wasLogged: boolean
}

export enum ProcessingStatusesNames {
  DATA_EXTRACTION = 'Data Extraction',
  DTR = 'Document Type Recognition',
  SHORT_DTR = 'DTR',
  AUTO_LABELING = 'Auto Labeling'
}

// Notifications

export enum NotificationType {
  DOWNTIME = 1,
  RELEASE = 2,
  UNPLANNED = 3
}

export interface Notification {
    notificationId: number,
    userId: UserId,
    notificationType: number,
    notificationTypeName: string,
    notificationTemplateId: NotificationType,
    seen: boolean,
    active: boolean,
    content: string
    createdTime: Date,
    scheduledTime: Date,
    expirationTime: Date,
    outageFrom: Date,
    outageTo: Date,
    createdByName: string,
    createdByUrl: string,
    createdByUserId: UserId
}

export enum ReporterPermissionGroup {
  INTERNAL = 'Internal',
  EXTERNAL = 'External'
}
