import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import * as Icons from 'common/Icons/SvgIcons'
import { setHierarchy, toggleEmptyLabels, useMxHistoryLabels, labelAdded, setWpStatus, setWpStartDate, setWpEndDate, setWoState, resetFilters } from 'redux/slices/mxHistoryLabelsSlice'
import { MxHistoryDocuments } from './components/MxHistoryDocuments'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { LisaPaper } from 'common/LisaPaper/LisaPaper'
import { GridGrow } from 'common/Grid/GridGrow'
import { MxHistoryModals } from './components/MxHistoryModals'
import { WithLabelsLoader } from 'common/WithLoader/WithLoader'
import { useHistory } from 'react-router-dom'
import { LabelSearchAutoComplete } from 'common/LabelSearch/LabelSearchAutoComplete'
import { LabelsProvider, LabelsType } from 'context/LabelsContext'
import { LabelTree } from 'common/LabelTree/LabelTree'
import { useTransitionId } from 'context/TransitionContext'
import { useGetMxHistoryLabelsQuery, useGetMxHistoryKpisQuery } from 'services/api/labellingApi'
import { setHierarchy as setReassignLabelsHierarchy } from 'redux/slices/reassignLabelsSlice'
import Dropdown from 'common/LisaControls/Dropdown'
import DatePicker from 'common/LisaControls/DatePicker'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { toYMDString } from 'utils/date'
import { Gauge, gaugeClasses, GaugeFormatterParams } from '@mui/x-charts/Gauge'
import Stack from '@mui/material/Stack'
import { openToast } from 'redux/slices/appSlice'
const useStyles = makeStyles((theme) => ({
  toolbarContainer: {
    paddingLeft: 8
  },
  autocompleteContainer: {
    paddingRight: 16
  },
  lisaControls: {
    columnGap: 8,
    display: 'flex',
    paddingBottom: 6
  },
  resetFilters: {
    marginTop: '20px',
    minWidth: '110px !important',
    lineHeight: '12px !important',
    padding: '13px 10px !important',
    height: '42px !important',
    letterSpacing: '.15px !important',
    background: 'transparent !important',
    color: `${theme.palette.blue.main} !important`,
    '&:hover': {
      background: `${theme.palette.lime.main} !important`,
      color: theme.palette.black.main
    }
  },
  gaugeLabelRight: {
    position: 'absolute'
  },
  gaugeLabelLeft: {
    position: 'absolute',
    left: '17%'
  }
}))

const MxHistory = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    hierarchy,
    emptyLabels,
    wpStatus,
    woState,
    wpStartDate,
    wpEndDate
  } = useSelector(useMxHistoryLabels)
  const hasLabels = hierarchy !== null && hierarchy.children.length > 0
  const { hasAccess } = useLisaAuth()
  const canViewMxHistory = hasAccess('perm_view_mxHistoryStructure')
  if (!canViewMxHistory) {
    history.push(hasAccess('perm_view_query') ? '/dashboard' : '/dashboard/my-transitions')
  }
  const transitionId = useTransitionId()
  const { data: mxHistoryLabels, fulfilledTimeStamp, isFetching } = useGetMxHistoryLabelsQuery({
    transitionId,
    showAll: emptyLabels,
    onlyMxHistory: true,
    wpStatus: wpStatus,
    wpStartDateTime: wpStartDate == null ? '' : toYMDString(wpStartDate),
    wpEndDateTime: wpEndDate == null ? '' : toYMDString(wpEndDate),
    workorderState: woState
  })
  useEffect(() => {
    if (mxHistoryLabels) {
      dispatch(setHierarchy(mxHistoryLabels))
      dispatch(setReassignLabelsHierarchy(mxHistoryLabels))
    }
  }, [fulfilledTimeStamp])

  const {
    data: mxHistoryKpis
  } = useGetMxHistoryKpisQuery({ transitionId })
  if (mxHistoryKpis && mxHistoryKpis.isError) {
    dispatch(openToast({
      severity: 'error',
      message: 'Could not fetch KPIs!'
    }))
  }

  const gaugeSettings = {
    height: 112,
    startAngle: -90,
    endAngle: 90,
    innerRadius: '55%',
    outerRadius: '100%',
    text: (params: GaugeFormatterParams) => {
      return params.value != null ? `${params.value} / ${params.valueMax}` : ''
    },
    sx: () => ({
      [`& .${gaugeClasses.valueArc}`]: {
        fill: '#9929BD'
      },
      [`& .${gaugeClasses.valueText}`]: {
        transform: 'translate(0px, -8px)'
      }
    })
  }

  function GetKpiPercentage (openItems: number, closedItems: number) {
    const allItems = openItems + closedItems
    if (allItems === 0) {
      return 0
    }

    return Math.round(closedItems * 10000 / allItems) / 100
  }

  const kpis = (
    mxHistoryKpis && <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 3 }}>
      <h3 className={classes.gaugeLabelRight}>
        WORK PACKAGES<br/>
        { GetKpiPercentage(mxHistoryKpis.labelMxHistoryKpis.wpOpenCount, mxHistoryKpis.labelMxHistoryKpis.wpClosedCount) }%
      </h3>
      <Gauge
        {...gaugeSettings}
        value={ mxHistoryKpis.labelMxHistoryKpis.wpClosedCount }
        valueMax={ mxHistoryKpis.labelMxHistoryKpis.wpOpenCount + mxHistoryKpis.labelMxHistoryKpis.wpClosedCount }
      >
      </Gauge>
      <h3 className={classes.gaugeLabelLeft}>
        WORK ORDERS<br/>
        { GetKpiPercentage(mxHistoryKpis.labelMxHistoryKpis.woOpenCount, mxHistoryKpis.labelMxHistoryKpis.woClosedCount) }%
      </h3>
      <Gauge
        {...gaugeSettings}
        value={ mxHistoryKpis.labelMxHistoryKpis.woClosedCount }
        valueMax={ mxHistoryKpis.labelMxHistoryKpis.woOpenCount + mxHistoryKpis.labelMxHistoryKpis.woClosedCount }
      >
      </Gauge>
    </Stack>
  )

  const title = (
    <Grid className={classes.autocompleteContainer}>
      <LabelSearchAutoComplete
        onLabelsSelect={(labels) => {
          const [label] = labels
          if (label) {
            dispatch(labelAdded(label))
          }
        }}/>
    </Grid>
  )

  const option = useMemo(() => {
    return <Tooltip title={emptyLabels ? 'Hide empty labels' : 'Show all labels'}>
      <IconButton onClick={() => dispatch(toggleEmptyLabels())}>
        { !emptyLabels ? <Icons.ShowAllLabels/> : <Icons.HideEmptyLabels/> }
      </IconButton>
    </Tooltip>
  }, [emptyLabels])

  return (
    <ContentWrapper newLayout>
      <GridGrow container direction="column">
        <Grid item xs={12} lg={12} className={classes.lisaControls}>
          <Dropdown
            label={'WP status'}
            name='workPackageStatus'
            items={[
              { value: '', text: 'All statuses' },
              { value: 'Open', text: 'Open' },
              { value: 'Closed', text: 'Closed' }
            ]}
            showSelectOption={false}
            value={wpStatus}
            onChange={(e) => dispatch(setWpStatus(e.target.value as string))}/>
          <DatePicker
            label="WP Date From"
            name="fromstartdate"
            value={wpStartDate}
            onChange={(date: MaterialUiPickersDate) => dispatch(setWpStartDate(date))}/>
          <DatePicker
            label="WP Date To"
            name="tillenddate"
            value={wpEndDate}
            onChange={(date: MaterialUiPickersDate) => dispatch(setWpEndDate(date))}/>
          <Dropdown
            label={'WO status'}
            name='workOrderStatus'
            items={[
              { value: '', text: 'All statuses' },
              { value: 'O', text: 'Open' },
              { value: 'C', text: 'Closed' }
            ]}
            showSelectOption={false}
            value={woState}
            onChange={(e) => dispatch(setWoState(e.target.value as string))}/>
          <Button
            className={classes.resetFilters}
            style={{ fontWeight: 600 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              dispatch(resetFilters())
            }}>
              reset
          </Button>
        </Grid>
        <GridGrow container item className={'columnGap1'}>
          <Grid
            // Left Side
            item
            container
            direction='column'
            xs={5}
            lg={4}>
            <LabelsProvider labelsType={LabelsType.MxHistory}>
              <LisaPaper header={kpis} toolbar={{ title, option }} classes={{ toolbarContainer: classes.toolbarContainer }}>
                <WithLabelsLoader
                  loading={isFetching}
                  loadingText={'Loading file tree data...'}
                  hasResults={hasLabels}>
                  <LabelTree renderSearchResultsTree/>
                </WithLabelsLoader>
              </LisaPaper>
            </LabelsProvider>
          </Grid>
          <GridGrow
            // Right side
            item
            container
            xs={7}
            lg={8}>
            <MxHistoryDocuments/>
          </GridGrow>
        </GridGrow>
      </GridGrow>
      <MxHistoryModals/>
    </ContentWrapper>

  )
}

export default MxHistory
