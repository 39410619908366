import { useEffect, useRef, useState } from 'react'
import { Avatar, AvatarProps, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { UserId } from 'types'
import useOnScreen from 'hooks/useIsOnScreen'
import { useGetProfilePictureQuery } from 'services/api/usersApi'

const useStyles = makeStyles((theme) => ({
  user: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& .userName': {
      display: 'block',
      fontSize: '16px',
      lineHeight: '18px',
      fontWeight: 600,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textTransform: 'capitalize'
    },
    '& .notificationName': {
      display: 'block',
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 600,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textTransform: 'uppercase'
    },
    '& .footNote': {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 600,
      marginBottom: '8px'
    },
    '& .label': {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500
    },

    '& .MuiAvatar-root': {
      width: '56px',
      height: '56px',
      marginRight: '6px',
      textTransform: 'uppercase',
      '&.opposite-color': {
        background: theme.palette.common.white
      }
    }
  },
  notificationAvatar: {
    '& .MuiAvatar-root': {
      width: '40px',
      height: '40px',
      marginRight: '6px',
      textTransform: 'uppercase'
    }
  },
  strikeTrough: {
    textDecoration: 'line-through'
  },
  reverse: {
    flexDirection: 'row-reverse',
    textAlign: 'right',
    '& .MuiAvatar-root': {
      marginRight: '0px',
      marginLeft: '6px'
    }
  }
}))

type UserForAvatar = {
  url?: string
  firstName: string
  lastName: string,
  userId: UserId
}
interface UserAvatarProps<UA extends UserForAvatar> extends AvatarProps {
  user: UA | null
  url?: string
  oppositeColor?: boolean
}

export const UserAvatar = <T extends UserForAvatar>({ user, url, oppositeColor = false, ...props }: UserAvatarProps<T>) => {
  const ref = useRef<HTMLDivElement>(null)
  const [fetchProfilePicture, setFetchProfilePicture] = useState(false)
  const isVisible = useOnScreen(ref)
  const avatarUrl = user?.url ?? url
  const { data: profilePicture } = useGetProfilePictureQuery(avatarUrl ?? '', { skip: !fetchProfilePicture })

  useEffect(() => {
    if (isVisible && avatarUrl && !fetchProfilePicture) {
      setFetchProfilePicture(true)
    }
  }, [isVisible, avatarUrl, fetchProfilePicture])

  return (
    <Avatar ref={ref} src={avatarUrl && profilePicture} {...props} className={clsx(props.className, { 'opposite-color': oppositeColor })}>
      { avatarUrl && user === null ? 'NN' : `${user?.firstName.slice(0, 1)}${user?.lastName.slice(0, 1)}` }
    </Avatar>
  )
}

interface UserAvatarWithNameProps<UA extends UserForAvatar> extends UserAvatarProps<UA> {
  label?: string
  footNote?: string
  reverse?: boolean,
  notificationTypeName?: string,
  options?: Partial<{
    textDecoration: 'none' | 'strikethrough'
  }>
}
export const UserAvatarWithName = <T extends UserForAvatar>({
  user,
  label,
  footNote,
  reverse = false,
  options = { textDecoration: 'none' },
  oppositeColor = false
}: UserAvatarWithNameProps<T>) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.user, { [classes.reverse]: reverse })}>
      <UserAvatar user={user} oppositeColor={oppositeColor}/>
      <div className={'userData'}>
        <Typography className={clsx('label', {
          [classes.strikeTrough]: options?.textDecoration === 'strikethrough'
        })} variant="body2">{label}</Typography>
        <span className={'userName'}>
          {user === null ? 'NN' : `${user.firstName} ${user.lastName}`}
        </span>
        <span className={'footNote'}>{footNote && footNote}</span>
      </div>
    </div>
  )
}

export const NotificationsAvatar = <T extends UserForAvatar>({
  user,
  label,
  notificationTypeName
}: UserAvatarWithNameProps<T>) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.user, classes.notificationAvatar)}>
      <UserAvatar user={user}/>
      <div>
        <Typography className='label' variant="body2">{label}</Typography>
        <span className={'notificationName'}>
          {notificationTypeName}
        </span>
      </div>
    </div>
  )
}
