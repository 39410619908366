import { useMemo } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles, TableCellProps } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  tableCell: {
    width: 20,
    backgroundColor: theme.palette.common.white
  },
  dataTableCheckbox: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.35rem'
    }
  }
}))

interface SelectAllCellProps<I> {
  items: I[]
  selectedItems: I[]
  onChange: () => void
}

export const SelectAllCell = <I extends unknown>({
  items, selectedItems, onChange
}: SelectAllCellProps<I>) => {
  const classes = useStyles()
  const itemsCount = items.length
  const diffCount = useMemo(() => items.filter(_ => selectedItems.includes(_)).length,
    [items, selectedItems])
  return (
    <TableCell padding="checkbox" className={classes.tableCell}>
      <Checkbox
        className={classes.dataTableCheckbox}
        indeterminate={itemsCount > 0 && diffCount > 0 && diffCount < itemsCount}
        checked={itemsCount > 0 && diffCount === itemsCount}
        disabled={itemsCount === 0}
        onChange={onChange}
        inputProps={{ 'aria-label': 'Toggle all documents' }}
      />
    </TableCell>
  )
}
interface SelectAllCellProp2<I> extends SelectAllCellProps<I>, TableCellProps {
  items: I[]
  selectedItems: I[]
  onChange: () => void
}

export const SelectAllCell2 = <I extends unknown>({
  items, selectedItems, onChange, className, ...tcProps
}: SelectAllCellProp2<I>) => {
  const classes = useStyles()
  const itemsCount = items.length
  const diffCount = useMemo(() => items.filter(_ => selectedItems.includes(_)).length,
    [items, selectedItems])
  return (
    <TableCell size={'small'} padding={'checkbox'} className={clsx(classes.tableCell, className)} {...tcProps}>
      <Checkbox
        className={classes.dataTableCheckbox}
        indeterminate={itemsCount > 0 && diffCount > 0 && diffCount < itemsCount}
        checked={itemsCount > 0 && diffCount === itemsCount}
        disabled={itemsCount === 0}
        onChange={onChange}
        inputProps={{ 'aria-label': 'Toggle all documents' }}
      />
    </TableCell>
  )
}
