import { FC, ReactNode, MouseEvent } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, Typography } from '@material-ui/core'
import { ConfirmDialogType } from 'types'
import { useDispatch, useSelector } from 'react-redux'
import { closeConfirmDialog, useApp } from 'redux/slices/appSlice'

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '600px'
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dialogContent: {
    padding: '0px'
  }
}))
type ConfirmDialogProps = {
  title: ReactNode
  type: ConfirmDialogType
  onConfirm?: () => void
  onCancel?: () => void
  info?: boolean,
  labels?: {
    yes?: string,
    cancel?: string
  }
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  children,
  type,
  onConfirm = () => null,
  info = false,
  onCancel = () => null,
  labels
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { activeConfirmDialog } = useSelector(useApp)
  const close = () => dispatch(closeConfirmDialog())
  const lbls = {
    yes: 'Yes',
    cancel: 'Cancel',
    ...labels
  }

  const handleClose = (e: MouseEvent<HTMLElement>, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      e.stopPropagation()
      onCancel()
      close()
    }
  }

  return (
    <Dialog
      // disableEscapeKeyDown
      open={type === activeConfirmDialog}
      scroll={'body'}
      onClose={handleClose}
      aria-labelledby="confirm-dialog">
      <DialogTitle disableTypography className={classes.modalHeader}>
        <Typography variant="body1">{title}</Typography>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {
          info &&
          <Button
            autoFocus
            variant="contained"
            onClick={() => {
              close()
              onConfirm()
            }}
            color="primary">
            Ok
          </Button>
        }
        {
          !info &&
          <>
            <Button
              autoFocus
              variant="contained"
              onClick={() => {
                close()
                onConfirm()
              }}
              color="primary">
              { lbls.yes }
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onCancel && onCancel()
                close()
              }}
              color="primary">
              { lbls.cancel }
            </Button>
          </>
        }
      </DialogActions>
    </Dialog>
  )
}
