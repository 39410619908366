import { FC, MouseEvent } from 'react'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, Typography } from '@material-ui/core'
import { ModalType } from 'types'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, useApp } from 'redux/slices/appSlice'

const useStyles = makeStyles(() => ({
  root: {},
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 24px',
    color: 'white',
    '& .MuiSvgIcon-root': {
      color: 'white'
    }
  },
  dialogContent: {
    padding: '0px'
  },
  modalAlignment: {
    alignItems: 'center'
  },
  absoluteClose: {
    position: 'absolute',
    right: 16,
    top: 16
  }
}))

type LisaModalProps = Partial<DialogProps> & {
  title: string
  noTitle?: boolean
  modalType: ModalType | ModalType[]
  noCloseButton?: boolean
  noPadding?: boolean
  onCancel?: () => void
}

export const LisaModal: FC<LisaModalProps> = ({
  title,
  noTitle = false,
  children,
  modalType,
  noCloseButton = false,
  noPadding = false,
  onCancel = () => null,
  ...props
}) => {
  const { activeModal } = useSelector(useApp)
  const classes = useStyles()
  const dispatch = useDispatch()
  const modals: ModalType[] = Array.isArray(modalType) ? modalType : [modalType]
  const open = modals.reduce((isOpen, mt) => {
    return isOpen || activeModal.some(ac => ac === mt)
  }, false)
  const close = () => {
    dispatch(closeModal(modals))
    onCancel()
  }

  const handleClose = (e: MouseEvent<HTMLElement>, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      e.stopPropagation()
      close()
    }
  }
  return (
    <div>
      <Dialog
        classes={{
          scrollPaper: classes.modalAlignment,
          scrollBody: classes.modalAlignment
        }}
        {...props}
        // open={Array.isArray(modalType) ? modalType.includes(activeModal) : activeModal === modalType}
        open={open}
        onClose={handleClose}
        fullWidth={true}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {
          !noTitle &&
          <DialogTitle disableTypography className={classes.modalHeader}>
            <Typography variant="body1">{title}</Typography>
            {
              !noCloseButton &&
              <IconButton aria-label="close" onClick={close}>
                <CloseIcon />
              </IconButton>
            }
          </DialogTitle>
        }
        {
          noTitle && !noCloseButton &&
            <IconButton aria-label="close" onClick={close} className={classes.absoluteClose}>
              <CloseIcon />
            </IconButton>
        }
        <DialogContent
          dividers={true}
          className={noPadding ? classes.dialogContent : ''}>
          {children}
        </DialogContent>
      </Dialog>
    </div>
  )
}
