import { FC, useEffect, useRef } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import lisaApi from 'api/lisaApi'
import { DocumentViewerToolbar } from './DocumentViewerToolbar'
import { GridGrow } from 'common/Grid/GridGrow'
import { DocumentViewerProps } from './documentViewerTypes'

const useStyles = makeStyles(() => ({
  content: {
    marginTop: 40
  },
  img: {
    maxWidth: '90%',
    maxHeight: '80vh'
  }
}))

export const ImageViewer: FC<DocumentViewerProps> = (props) => {
  const classes = useStyles()
  const imgRef = useRef<HTMLImageElement>(null)
  const { document } = props
  useEffect(() => {
    lisaApi.get(document!.url, { responseType: 'blob' }).then(({ data }) => {
      if (imgRef && imgRef.current) {
        const src = URL.createObjectURL(data)
        imgRef.current.src = src
        window.document.getElementById('image-viewer-thumb')?.setAttribute('src', src)
        // imgRef.load()
      }
    })
  }, [])

  const goFullScreen = async () => {
    if (imgRef && imgRef.current) {
      await imgRef.current.requestFullscreen()
    }
  }

  return (
    <GridGrow
      container
      item
      justifyContent={'center'}
      alignItems={'stretch'}
      direction={'column'}>
      <Grid item>
        <DocumentViewerToolbar
          fullScreenHandler={goFullScreen}
          document={document!}
          compact={props.options?.view === 'compact'}/>
      </Grid>
      <GridGrow item container justifyContent='center' className={classes.content}>
        <img id={'document-viewer-img'} alt={document?.name} ref={imgRef} className={classes.img} />
      </GridGrow>
    </GridGrow>
  )
}
