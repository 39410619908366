import { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  base: {
    fontSize: 16,
    color: theme.palette.common.black,
    transform: 'rotate(-135deg)'
  },
  reverse: {
    color: theme.palette.common.white
  }
}))

export const ExternalCommentIndicator: FC<{ reverse?: boolean }> = ({ reverse = false }) => {
  const classes = useStyles()
  return (
    <span className={'query-comment-indicator'}>
      <i className={clsx(classes.base, 'fas fa-arrow-right', { [classes.reverse]: reverse })}/>
    </span>
  )
}
