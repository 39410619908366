import { SortDirection } from '@material-ui/core'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { nextSortDirection } from 'utils/filters'
import { SortColumn } from 'types'
import { RootState } from 'redux/store'

export interface UsersStateSlice {
  userLastName: SortDirection,
  email: SortDirection,
  roles: SortDirection,
  company: SortDirection,
  searchTerm: string,
  page: number,
  sortOrder: SortDirection,
  sortColumn: SortColumn,
  isActive: SortDirection,
  includeDeactivated: boolean
}

export const initialState: UsersStateSlice = {
  userLastName: 'asc',
  email: false,
  roles: false,
  company: false,
  searchTerm: '',
  page: 1,
  sortOrder: 'asc',
  sortColumn: 'Name',
  isActive: false,
  includeDeactivated: false
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    sortByName: (state) => {
      state.userLastName = nextSortDirection(state.userLastName)
      state.company = false
      state.email = false
      state.sortOrder = state.userLastName
      state.sortColumn = 'Name'
      state.page = 1
    },
    sortByEmail: (state) => {
      state.email = nextSortDirection(state.email)
      state.company = false
      state.userLastName = false
      state.sortOrder = state.email
      state.sortColumn = 'Email'
      state.page = 1
    },
    sortByCompany: (state) => {
      state.company = nextSortDirection(state.company)
      state.userLastName = false
      state.email = false
      state.sortOrder = state.company
      state.sortColumn = 'Company'
      state.page = 1
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload
    },
    resetSortOptions: () => initialState,
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload
    },
    setIncludeDeactivated: (state, { payload }: PayloadAction<boolean>) => {
      state.includeDeactivated = payload
    }
  }
})

export const {
  sortByName,
  sortByEmail,
  sortByCompany,
  setPage,
  resetSortOptions,
  setSearchTerm,
  setIncludeDeactivated
} = usersSlice.actions

export const useUsers = (state: RootState) => state.users

export default usersSlice.reducer
