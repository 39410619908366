// https://github.com/mui/material-ui/issues/13394
import { unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core/styles'
import createPalette from '@material-ui/core/styles/createPalette'
import { PaletteColorOptions } from '@material-ui/core'
import { TypographyOptions } from '@material-ui/core/styles/createTypography'

declare module '@material-ui/core/styles/createPalette' {
  type StatusColors = {
    green: string,
    blue: string,
    bluelight: string,
    orange: string,
    red: string,
    yellow: string,
    grey: string,
    black: string
  }
  type PaletteColorOpts = PaletteColorOptions & {
    light100?: string
    dark100?: string
  }

  interface PaletteColor {
    light100?: string
    dark100?: string
  }

  interface PaletteOptions {
    blue: PaletteColorOpts
    black: PaletteColorOpts
    grey2: PaletteColorOpts
    lime: PaletteColorOpts
    red: PaletteColorOpts
    linkBlue: PaletteColorOpts
    import: PaletteColorOpts
    a2k: PaletteColorOpts
    client: PaletteColorOpts
    mxHistory: PaletteColorOpts
    statusColors: StatusColors
  }

  interface Palette {
    blue: PaletteColor
    black: PaletteColor
    grey2: PaletteColor
    lime: PaletteColor
    red: PaletteColor
    linkBlue: PaletteColor
    import: PaletteColor
    a2k: PaletteColor
    client: PaletteColor
    mxHistory: PaletteColor
    statusColors: StatusColors
  }
}
const palette = createPalette({
  black: {
    main: '#1E1E1E',
    dark: '#0F0F0F',
    light: '#6E6E6E',
    light100: '#bebebe'
  },
  blue: {
    main: '#001E50',
    dark: '#000532',
    light: '#677996'
  },
  grey2: {
    main: '#e7eaea',
    dark: '#b7c4c4',
    dark100: '#7e9494',
    light: '#f0f3f3',
    light100: '#e6ecec'
  },
  lime: {
    main: '#DDFF66',
    dark: '#C8F500',
    light: '#F0FFBA'
  },
  red: {
    main: '#FFE4E1'
  },
  statusColors: {
    green: '#00C128',
    blue: '#009DE5',
    bluelight: '#D4EEFB',
    orange: '#FF9D1f',
    red: '#E20001',
    yellow: '#ffef00',
    grey: '#CBC9C9',
    black: '#575757'
  },
  linkBlue: {
    main: '#0086e6'
  },
  import: {
    main: '#FF9D1F'
  },
  a2k: {
    main: '#009DE5'
  },
  client: {
    main: '#00C128'
  },
  mxHistory: {
    main: '#9929BD'
  }
})

const typography: TypographyOptions = {
  fontFamily: ['Source Sans Pro'].join(','),
  subtitle2: {
    fontSize: '16px',
    lineHeight: '17px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '6px',
    letterSpacing: '.2px'
  },
  h5: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
    letterSpacing: '-.1px'
  },
  h6: {
    display: 'block',
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 500,
    overflow: 'hidden',
    marginRight: '6px',
    letterSpacing: '-.1px',
    lineBreak: 'anywhere'
  },
  body1: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 700,
    letterSpacing: '-.1px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  body2: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 500,
    letterSpacing: '-.1px'
  },
  caption: {
    width: 'auto',
    display: 'block',
    fontSize: '13px',
    fontWeight: 500,
    letterSpacing: '.1px',
    textTransform: 'lowercase',
    lineHeight: '20px',
    marginLeft: '1px',
    height: '20px'
  }
}

const globalTheme = createTheme({
  typography,
  palette
})

const theme = createTheme({
  transitions: {
    // So we have `transition: none;` everywhere
    create: () => 'none'
  },
  typography: globalTheme.typography,

  palette: globalTheme.palette,

  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 992,
      lg: 1441,
      xl: 1920
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        '&.MuiDialog-paperWidthSm': {
          minWidth: '480px'
        },
        '& .MuiAutocomplete-listbox': {
          overflowX: 'hidden',
          overflowY: 'auto'
        },
        '& .MuiAutocomplete-option': {
          fontSize: '16px',
          fontWeight: '500',
          height: '42px',
          '&:hover': {
            background: globalTheme.palette.grey2.light
          },
          '& span': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }
        },
        '& .MuiToolbar-root.MuiPickersToolbar-toolbar': {
          backgroundColor: globalTheme.palette.blue.main
        },
        '& .MuiPickersDay-daySelected': {
          backgroundColor: globalTheme.palette.blue.main
        }
      }
    },
    MuiIconButton: {
      root: {
        padding: '9px'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
        borderRadius: '2px',
        fontWeight: 600,
        padding: '1px 8px',
        color: globalTheme.palette.black.main,
        backgroundColor: 'rgba(221,255,102,0.95)',
        boxShadow: '0 4px 12px rgba(0,0,0,.25)'
      }
    },
    MuiTabs: {
      root: {
        overflow: 'visible',
        height: '56px',
        '& .MuiTabs-indicator': {
          position: 'absolute',
          background: globalTheme.palette.lime.main,
          height: '0px'
        },
        '& .MuiTabs-fixed': {
          overflow: 'visible !important'
        },
        '& .MuiTab-textColorPrimary': {
          color: 'white',
          minHeight: '56px',
          minWidth: '56px',
          padding: '3px 28px 0px 28px',
          overflow: 'visible',
          [globalTheme.breakpoints.down('md')]: {
            padding: '3px 20px 0px 20px'
          },
          borderBottom: '6px solid transparent',
          transition: 'all .15s linear',
          '&:hover': {
            color: 'rgba(255,255,255,0.6)',
            '& .MuiBadge-colorSecondary': {
              background: globalTheme.palette.lime.main
            }
          },
          '&.importTab.Mui-selected': {
            color: globalTheme.palette.common.white,
            borderBottom: `6px solid ${globalTheme.palette.statusColors.orange}`
          },
          '&.a2kTab.Mui-selected': {
            color: globalTheme.palette.common.white,
            borderBottom: `6px solid ${globalTheme.palette.statusColors.blue}`
          },
          '&.clientTab.Mui-selected': {
            color: globalTheme.palette.common.white,
            borderBottom: `6px solid ${globalTheme.palette.statusColors.green}`
          },
          '&.mxHistoryTab.Mui-selected': {
            color: globalTheme.palette.common.white,
            borderBottom: `6px solid ${globalTheme.palette.mxHistory.main}`
          },
          '&.Mui-selected': {
            color: globalTheme.palette.common.white,
            borderBottom: `6px solid ${globalTheme.palette.lime.main}`
          }
        },
        '& .MuiTab-wrapper': {
          fontSize: '14px',
          fontWeight: '600',
          letterSpacing: '.35px'
        }
      }
    },
    MuiAvatar: {
      root: {
        zIndex: 0,
        '&.MuiAvatar-colorDefault': {
          background: globalTheme.palette.grey2.light100,
          color: globalTheme.palette.black.main,
          fontSize: '16px',
          fontWeight: '600'
        },
        '&:hover': {
          zIndex: '4'
        }
      }
    },
    MuiCircularProgress: {
      root: {
        padding: '24px',
        '&.MuiCircularProgress-colorPrimary': {
          color: '#c8f500 !important'
        }
      }
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '0',
        '& .MuiFormControlLabel-label': {
          paddingLeft: '4px'
        }
      }
    },
    MuiSwitch: {
      root: {
        width: '48px',
        height: '25px',
        padding: '12px',
        borderRadius: '23px',
        border: `1px solid ${globalTheme.palette.grey2.main}`,
        background: globalTheme.palette.common.white,
        '& .MuiSwitch-colorPrimary': {
          color: globalTheme.palette.grey2.main,
          background: globalTheme.palette.common.white,
          '&.Mui-checked': {
            color: globalTheme.palette.statusColors.green
          }
        },
        '& .MuiFormControlLabel-label': {
          marginLeft: '7px'
        },
        '& .PrivateSwitchBase': {
          background: 'yellow !important'
        },
        '& .MuiSwitch-switchBase': {
          top: '-7px',
          left: '12px'
        },
        '& .MuiIconButton-label': {
          marginLeft: '-9px',
          width: '20px !important'
        },
        '& .MuiSwitch-thumb': {
          boxShadow: 'none'
        }
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,.75)'
      }
    },
    MuiBadge: {
      root: {
        '& .MuiBadge-colorSecondary': {
          fontSize: '14px',
          lineHeight: '24px',
          background: globalTheme.palette.grey2.main,
          border: 'none',
          height: '24px',
          minWidth: '24px',
          borderRadius: '10px 10px 10px 0',
          color: globalTheme.palette.black.main,
          fontWeight: '700',
          letterSpacing: '-.1px'
        }
      }
    },
    MuiList: {
      root: {
        '&.MuiList-padding': {
          paddingTop: '8px',
          paddingBottom: '0px',
          marginTop: '-8px',
          maxHeight: '400px',
          '&.MuiMenuItem-root': {
            height: '42px'
          }
        }
      }
    },
    MuiButtonBase: {
      root: {
        '&.MuiButton-root': {
          padding: '14px 20px',
          fontSize: '14px',
          lineHeight: '13px',
          fontWeight: '700',
          letterSpacing: '1px',
          borderRadius: '4px',
          minWidth: '110px',
          height: '42px'
        },
        '&.MuiButton-contained': {
          boxShadow: 'none'
        },
        '&.MuiButton-contained:hover': {
          boxShadow: 'none'
        },
        '&.MuiButton-contained:active': {
          boxShadow: 'none'
        },
        '&.MuiButton-containedPrimary, &.MuiButton-containedSecondary': {
          color: globalTheme.palette.common.white,
          backgroundColor: globalTheme.palette.blue.main,
          transition: 'all .15s linear',
          '&:hover': {
            backgroundColor: globalTheme.palette.lime.main,
            color: globalTheme.palette.black.main
          }
        },
        '&:hover.MuiListItem-button': {
          background: globalTheme.palette.grey2.main,
          transition: 'all .15s linear'
        },
        '&.MuiTableSortLabel-root': {
          fontSize: '12px',
          lineHeight: '12px',
          color: globalTheme.palette.black.main,
          letterSpacing: '.05px',
          fontWeight: '700',
          '& .MuiTableSortLabel-icon': {
            opacity: '0.25',
            width: '14px',
            height: '14px',
            marginLeft: '0',
            lineHeight: '14px',
            fill: globalTheme.palette.black.main
          },
          '& .MuiTableRow-root.MuiTableRow-hover:hover': {
            // transition:'all .15s linear',
            backgroundColor: globalTheme.palette.blue.main,
            '& td, .MuiChip-root.FileStatus': {
              color: 'white'
            },
            '& .MuiCheckbox-colorSecondary, & ': {
              color: globalTheme.palette.common.white
            }
          }
        }
      }
    },
    MuiListItem: {
      root: {
        '&.MuiListItem-gutters': {
          paddingLeft: '10px'
        },
        '&.Mui-selected': {
          position: 'relative',
          background: globalTheme.palette.blue.main,
          color: 'white',
          borderRight: `6px solid ${globalTheme.palette.lime.main}`,
          '& .MuiSvgIcon-fontSizeLarge': {
            fill: 'rgba(255,255,255,0.5) !important'
          },
          '&:hover': {
            background: globalTheme.palette.blue.main,
            color: 'white',
            '& .MuiSvgIcon-fontSizeLarge': {
              fill: 'rgba(255,255,255,0.5) !important'
            }
          }
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '36px'
      }
    },
    MuiListItemText: {
      root: {
        '& .MuiTypography-displayBlock': {
          fontSize: '14px',
          fontWeight: '600',
          letterSpacing: '.5px',
          textTransform: 'uppercase'
        }
      }
    },
    MuiChip: {
      root: {
        color: globalTheme.palette.black.main,
        border: 'none',
        height: '24px',
        fontSize: '14px',
        backgroundColor: globalTheme.palette.grey2.main,
        fontWeight: 700,
        borderRadius: '10px 10px 10px 0',
        letterSpacing: '0px',
        minWidth: '24px',
        maxWidth: '320px',
        '&.QueryStatus': {
          backgroundColor: 'transparent',
          fontSize: '12px',
          fontWeight: '700',
          border: 'none',
          borderRadius: 'none',
          textTransform: 'uppercase',
          '& .MuiChip-label': {
            padding: '0',
            marginLeft: '2px',
            overflow: 'visible',
            '&:after': {
              content: '""',
              marginLeft: '4px',
              width: '12px',
              height: '12px',
              display: 'inline-block',
              borderRadius: '50%',
              backgroundColor: 'black',
              verticalAlign: '-2px'
            }
          },
          '&.Info': {
            '& .MuiChip-label': {
              '&:after': {
                backgroundColor: globalTheme.palette.statusColors.grey
              }
            }
          },
          '&.Medium': {
            '& .MuiChip-label': {
              '&:after': {
                backgroundColor: globalTheme.palette.statusColors.yellow
              }
            }
          },
          '&.Low': {
            '& .MuiChip-label': {
              '&:after': {
                backgroundColor: globalTheme.palette.statusColors.blue
              }
            }
          },
          '&.High': {
            '& .MuiChip-label': {
              '&:after': {
                backgroundColor: globalTheme.palette.statusColors.orange
              }
            }
          },
          '&.Critical': {
            '& .MuiChip-label': {
              '&:after': {
                backgroundColor: globalTheme.palette.statusColors.red
              }
            }
          }
        },
        '&.FileStatus': {
          backgroundColor: 'transparent',
          fontSize: '12px',
          lineHeight: '13px',
          fontWeight: '700',
          border: 'none',
          borderRadius: 'none',
          textTransform: 'uppercase',
          letterSpacing: '.1px',
          '& .MuiChip-label': {
            padding: '0',
            overflow: 'visible',
            '&:before': {
              content: '""',
              marginRight: '4px',
              width: '12px',
              height: '12px',
              display: 'inline-block',
              borderRadius: '50%',
              backgroundColor: 'black',
              verticalAlign: '-2px'
            }
          },
          '&.Open': {
            '& .MuiChip-label': {
              '&:before': {
                backgroundColor: globalTheme.palette.statusColors.blue
              }
            }
          },
          '&.Clarification': {
            '& .MuiChip-label': {
              '&:before': {
                backgroundColor: globalTheme.palette.statusColors.orange
              }
            }
          },
          '&.Reviewed': {
            '& .MuiChip-label': {
              '&:before': {
                backgroundColor: globalTheme.palette.statusColors.green
              }
            }
          },
          '&.Irrelevant': {
            '& .MuiChip-label': {
              '&:before': {
                backgroundColor: globalTheme.palette.statusColors.grey
              }
            }
          },
          '&.Deleted': {
            '& .MuiChip-label': {
              '&:before': {
                backgroundColor: globalTheme.palette.statusColors.black
              }
            }
          },
          '&.Accepted': {
            '& .MuiChip-label': {
              '&:before': {
                content: '""',
                width: '0px',
                marginRight: '4px'
              }
            }
          },
          '&.MuiChip-outlined .MuiChip-iconSmall': {
            width: '10px',
            marginRight: '0px',
            padding: '0px',
            color: globalTheme.palette.statusColors.green
          }
        }

      }
    },
    MuiLinearProgress: {
      root: {
        '&.MuiLinearProgress-colorPrimary': {
          backgroundColor: globalTheme.palette.grey2.main,
          borderRadius: '2px',
          overflow: 'hidden'
        }
      }
    },
    MuiDrawer: {
      root: {
        '& .MuiDrawer-paperAnchorDockedLeft': {
          borderRight: 'none'
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontWeight: 500,
        fontSize: '16px',
        '&.Mui-selected': {
          background: globalTheme.palette.grey2.main,
          color: globalTheme.palette.black.main,
          borderRight: 'none',
          '&:hover': {
            background: globalTheme.palette.grey2.dark,
            color: globalTheme.palette.black.main
          }
        },
        '&.MuiListItem-button': {
          padding: '11px 24px',
          fontSize: '16px',
          fontWeight: '500',
          letterSpacing: '.1px',
          '&:hover': {
            background: globalTheme.palette.grey2.light
          }
        }
      }
    },
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          height: '3px',
          width: '3px',
          background: 'transparent'
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'none'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.6)',
          borderRadius: '1.5px'
        }
      }
    },
    MuiDialogTitle: {
      root: {
        padding: '24px',
        background: globalTheme.palette.blue.main,
        color: 'white',
        '& p': {
          fontSize: '16px',
          fontWeight: '700',
          textTransform: 'uppercase',
          letterSpacing: '.5px',
          color: globalTheme.palette.common.white,
          margin: '0'
        }
      }
    },
    MuiDialogContent: {
      root: {
        fontSize: '18px',
        padding: '0 32px',
        background: globalTheme.palette.grey2.light100,
        overflow: 'hidden !important',
        '&.MuiDialogContent-dividers': {
          border: 'none',
          padding: '0 32px'
        }
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'flex-start',
        padding: '0 24px 32px 32px',
        background: globalTheme.palette.grey2.light100
      }
    },
    MuiInput: {
      root: {
        fontSize: '16px',
        fontWeight: 500
      }
    },
    MuiInputBase: {
      root: {}
    },
    MuiOutlinedInput: {
      root: {
        fontSize: '16px',
        fontWeight: 500,
        background: globalTheme.palette.common.white,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: globalTheme.palette.grey2.main
        }
      }
    },
    MuiPopover: {}
  },
  props: {
    MuiTextField: {
      variant: 'outlined'
    },
    MuiSelect: {
      variant: 'outlined'
    }
  }
})

export default theme
