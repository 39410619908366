import { FC } from 'react'
import { Button, Chip, makeStyles, Theme, Tooltip } from '@material-ui/core'
import { LabelBase } from 'types'
import clsx from 'clsx'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useLabelsPath } from 'redux/slices/labelsSlice'
import { useSelector } from 'react-redux'

const useStyles = makeStyles<Theme, { forbidden: boolean }>((theme) => ({
  labels: {
    display: 'flex',
    '& .MuiChip-label': {
      // overflow: 'visible'
    },
    '& .MuiChip-sizeSmall': {
      background: theme.palette.common.white,
      height: '24px',
      fontSize: '13px',
      fontWeight: '600',
      letterSpacing: '.3px',
      color: theme.palette.black.main,
      marginRight: '2px',
      borderRadius: '4px',
      border: `2px solid ${theme.palette.statusColors.blue}`,
      cursor: (props: { forbidden: boolean }) => !props.forbidden ? 'pointer' : 'normal',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '140px',
      lineHeight: 1
    }
  },
  tooltipItem: {
    fontSize: '13px',
    fontWeight: 600,
    letterSpacing: '.3px'
  },
  assignLabelsButton: {
    backgroundColor: theme.palette.statusColors.blue,
    color: theme.palette.common.white,
    height: '24px !important',
    padding: '4px 8px !important',
    minWidth: 'auto !important',
    border: `1px solid ${theme.palette.statusColors.blue}`,
    '& .MuiButton-label': {
      fontSize: 13,
      fontWeight: 600,
      textTransform: 'none',
      letterSpacing: 0
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey2.light,
      borderColor: theme.palette.grey2.main,
      color: theme.palette.grey2.dark
    },
    '&:hover': {
      borderColor: theme.palette.lime.main,
      background: theme.palette.lime.main,
      color: theme.palette.blue.main
    }
  }
}))

type DocumentLabelsProps = {
  labels?: LabelBase[]
  labelNames?: string[]
  onClick?: (labels: LabelBase[]) => void
  withAssignLabelsOption?: boolean
}
export const DocumentLabels: FC<DocumentLabelsProps> = ({
  labels,
  labelNames,
  onClick = () => {},
  withAssignLabelsOption = false
}) => {
  const { hasAccess } = useLisaAuth()
  const canBulkAssign = hasAccess('perm_act_a2klabel')
  const classes = useStyles({ forbidden: !canBulkAssign })
  const names = labelNames ?? labels?.map(_ => _.name) ?? []
  const [label] = labels ?? []
  const labelPathName = useSelector(useLabelsPath(undefined, label?.labelId))
  return (
    <div onClick={() => onClick(labels ?? [])}>
      {
        names.length > 0 &&
        <div className={classes.labels}>
          <Tooltip title={labelPathName}>
            <Chip size="small" label={names[0]}/>
          </Tooltip>
          {
            names.length > 1 &&
            <Tooltip
              placement="right-start"
              title={
                <div>
                  {names.slice(1).map((lab) => (
                    <div key={lab} className={classes.tooltipItem}>{lab}</div>)
                  )}
                </div>
              }>
              <Chip size="small" label={'...'}/>
            </Tooltip>
          }
        </div>
      }
      {
        names.length === 0 && withAssignLabelsOption &&
        <Button
          disabled={!canBulkAssign}
          className={clsx(classes.assignLabelsButton, 'assignLabels')}
          onClick={() => onClick([])}>
          Assign Labels
        </Button>
      }
    </div>
  )
}
