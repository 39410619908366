import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import {
  clearSelectedDocuments,
  resetAll,
  resetSearchParams,
  selectLabel,
  useSearchDocuments
} from 'redux/slices/searchDocumentsSlice'
import { useTransitionContext } from 'context/TransitionContext'
import { SearchResults } from './SearchResults'
import { GridGrow } from 'common/Grid/GridGrow'
import { SearchInputWithConditions } from './SearchInputWithConditions'
import { useDocumentSearch } from './useDocumentSearch'
import { SearchFilters } from './SearchFilters'
import SwitchLabel from 'common/LisaControls/SwitchLabel'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useUpdateUserSettingsMutation } from 'services/api/usersApi'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.black.main
  },
  searchResults: {
    marginTop: '12px'
  },
  resetFilters: {
    marginTop: '18px',
    minWidth: 'auto !important',
    lineHeight: '12px !important',
    padding: '13px 10px !important',
    height: '42px !important',
    letterSpacing: '.15px !important',
    background: 'transparent !important',
    color: `${theme.palette.blue.main} !important`,
    '&:hover': {
      background: `${theme.palette.lime.main} !important`,
      color: theme.palette.black.main
    }
  },
  searchToolbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '18px',
    '& .searchInput': {
      marginRight: 8
    },
    '& .center-align': {
      justifyContent: 'center'
    }
  },
  filterWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '& .MuiOutlinedInput-root': {
      marginRight: '16px'
    },
    '& .MuiAutocomplete-root': {
      paddingRight: '16px'
    }
  },
  searchFieldContainer: {
    height: 58
  }
}))

export const Search: FC = () => {
  const { searchParams, labels: allLabels } = useSelector(useSearchDocuments)
  const { user: { settings: { showSearchFilters } }, userId } = useLisaAuth()
  const { transitionId } = useTransitionContext()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { search, setSearchText, searchText, handleCondition, condition } = useDocumentSearch(transitionId, searchParams.searchText)
  const [updateUserSettings] = useUpdateUserSettingsMutation()

  const onLabelSelect = (labelName: string) => {
    dispatch(selectLabel(allLabels.find(_ => _.name === labelName) ?? null))
  }

  const doSearch = () => search(searchParams)

  useEffect(() => {
    // Select Label
    onLabelSelect(searchParams.label)
  }, [searchParams.label])

  useEffect(() => {
    dispatch(clearSelectedDocuments())
  }, [searchParams.businessStatus, searchParams.label, searchParams.hasQueries, searchParams.searchText])

  useEffect(() => {
    return () => {
      dispatch(resetAll())
      dispatch(resetSearchParams())
    }
  }, [])

  return (
    <GridGrow container direction={'column'} className={classes.root}>
      <Grid item container direction={'column'} spacing={2} className={classes.searchToolbar}>
        <Grid item className={classes.searchFieldContainer}>
          <Grid container className="center-align">
            <Grid item container xs={12} md={8} lg={5} alignItems={'center'}>
              <Grid item xs>
                <SearchInputWithConditions
                  conditionPicker={'dropdown'}
                  searchText={searchText}
                  onSearchTextChange={setSearchText}
                  condition={condition}
                  onConditionChange={handleCondition}
                  onClick={() => doSearch()}/>
              </Grid>
              <SwitchLabel
                label={'Filters'}
                value={showSearchFilters}
                onChange={() => {
                  updateUserSettings({
                    userId,
                    settings: { showSearchFilters: !showSearchFilters }
                  })
                }}/>
            </Grid>
          </Grid>
        </Grid>
        {
          showSearchFilters &&
          <Grid item container justifyContent={'center'}>
            <Grid item xs={12} lg={8}>
              <SearchFilters setSearchText={setSearchText} />
            </Grid>
          </Grid>
        }
      </Grid>
      <GridGrow container item direction={'column'} className={classes.searchResults}>
        <SearchResults />
      </GridGrow>
    </GridGrow>
  )
}
