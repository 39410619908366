import { FC } from 'react'
import { BusinessStatus } from 'types'
import { TableViewCell, TableViewCellProps } from './TableViewCell'
import { DocumentStatusSelect } from 'common/Select/DocumentStatusSelect'

type RowStatusCellProps = TableViewCellProps & {
  status: BusinessStatus
  onStatusChange: (bs: BusinessStatus) => void
}
export const RowStatusCell: FC<RowStatusCellProps> = ({ status, onStatusChange, ...props }) => {
  return (
    <TableViewCell {...props}>
      <DocumentStatusSelect
        label={''}
        value={status}
        onChange={(e) => {
          onStatusChange(e.target.value as BusinessStatus)
        }}/>
    </TableViewCell>
  )
}
