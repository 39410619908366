import { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import Dashboard from 'pages/Dashboard/Dashboard'
import MyTransitions from 'pages/Dashboard/MyTransitions'
import NewQueries from 'pages/Dashboard/NewQueries'
import { ErrorPage } from 'pages/ErrorPage/ErrorPage'

export const DashboardRoutes: FC = () => {
  return (
    <Switch>
      <Route exact path="/dashboard" component={Dashboard}/>
      <Route
        exact
        path="/dashboard/my-transitions"
        component={MyTransitions}/>
      <Route
        exact
        path="/dashboard/new-queries"
        component={NewQueries}/>
      <Route path="*">
        <ErrorPage/>
      </Route>
    </Switch>
  )
}
