import { FC } from 'react'
import Chip from '@material-ui/core/Chip'

import { makeStyles } from '@material-ui/core'
import { DocumentLabels } from './DocumentLabels'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useDocumentId } from 'context/DocumentContext'
import { useGetDocumentMetaDataQuery, useRemoveDocumentReferenceMutation } from 'services/api/documentApi'
import { BusinessStatus } from 'types'
import { isCompleted } from 'utils/documents'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    height: 'auto',
    paddingLeft: 12,
    paddingRight: 12
  },
  referencesRow: {
    display: 'inline-block',
    '& .MuiChip-sizeSmall': {
      height: '24px',
      fontSize: '13px',
      fontWeight: 600,
      color: theme.palette.common.white,
      margin: '0 4px 4px 0px',
      border: `2px solid ${theme.palette.black.light}`,
      borderRadius: '4px',
      background: theme.palette.black.main,
      boxShadow: '0 2px 8px 0 rgba(0,0,0,.4)',
      '&:hover': {
        background: theme.palette.black.main
      }
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.common.white,
      '&:hover': {
        color: theme.palette.statusColors.red
      }
    }
  },
  labelsRow: {
    display: 'inline-block',
    '& .MuiChip-sizeSmall': {
      height: '24px',
      fontSize: '13px',
      fontWeight: 600,
      color: theme.palette.black.main,
      margin: '0 4px 4px 0px',
      border: `2px solid ${theme.palette.statusColors.blue}`,
      borderRadius: '4px',
      boxShadow: '0 2px 8px 0 rgba(0,0,0,.4)',
      background: theme.palette.common.white,
      '&:hover': {
        background: theme.palette.common.white
      }
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.black.light,
      '&:hover': {
        color: theme.palette.statusColors.red
      }
    }
  },
  buttons: {
    display: 'block',
    flexDirection: 'column',
    rowGap: '5px',
    padding: '0px',
    '& .MuiButtonBase-root.MuiButton-root': {
      padding: '4px 10px',
      height: 'auto',
      fontSize: '12px'
    }
  }
}))

const ReferenceHeader: FC<{ className?: string }> = ({ className }) => {
  const classes = useStyles()
  const { hasAccess } = useLisaAuth()
  const documentId = useDocumentId()
  const { data: { references, businessStatus } = { references: [] } } = useGetDocumentMetaDataQuery(documentId)
  const [removeDocumentReference] = useRemoveDocumentReferenceMutation()

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.referencesRow}>
        {
          references.map((item, i) => (
            <Chip
              key={i + ''}
              variant="outlined"
              size="small"
              label={`${item.referenceKey} ${item.referenceValue}`}
              onDelete={() => hasAccess('perm_act_a2klabel') && removeDocumentReference(item.referenceId)}
            />
          ))
        }
      </div>
      <div className={classes.labelsRow}>
        <DocumentLabels
          allowEmpty={!isCompleted({ businessStatus: businessStatus ?? BusinessStatus.Open })}/>
      </div>
    </div>
  )
}

ReferenceHeader.propTypes = {}

export default ReferenceHeader
