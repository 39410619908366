import { Label, LoadingState } from 'types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createHierarchy, searchTree, selectLabelInTree } from 'utils/labels'
import { initialLabelsState, labelsReducers, LabelsReducers, LabelsState } from './labelsSlice'
import { RootState } from 'redux/store'

export interface ReassignLabelsState extends LabelsState {
  from: Label | null // This is label we are reassigning from
  selected: Label | null // This is label we are reassigning to
}

export interface ReassignLabelsReducers extends LabelsReducers {
}

export const initialState: ReassignLabelsState = {
  ...initialLabelsState,
  selected: null,
  from: null
}

const reassignLabelsSlice = createSlice({
  name: 'reassignLabelsSlice',
  initialState,
  reducers: {
    search: labelsReducers.search<ReassignLabelsState>(),
    toggle: labelsReducers.toggle<ReassignLabelsState>(),
    setFrom: (state, { payload }: PayloadAction<Label>) => {
      state.from = payload
      state.disabled = Array.from(new Set([...state.disabled, payload.labelId]).values())
    },
    setHierarchy: (state, { payload }: PayloadAction<Label>) => {
      state.hierarchy = createHierarchy(payload)
      state.searchResults = searchTree(state.hierarchy, state.searchTerm)
      state.openedLabels[state.hierarchy.labelId] = true // Root is always open
      state.loadingHierarchy = LoadingState.Completed
    },
    selectLabelToReassign: (state, { payload }: PayloadAction<Label>) => {
      state.selected = payload
    },
    assignTo: (state, { payload }: PayloadAction<Label>) => {
      state.hierarchy = selectLabelInTree(state.hierarchy!, payload, true)
      state.selected = {
        ...payload,
        isSelected: true
      }
      state.searchResults = searchTree(state.hierarchy, state.searchTerm)
    },
    clearAssignSelection: (state, { payload }: PayloadAction<Label>) => {
      state.hierarchy = selectLabelInTree(state.hierarchy!, payload, false)
      state.selected = null
      state.searchResults = searchTree(state.hierarchy, state.searchTerm)
    },
    reset: (state) => {
      if (state.selected !== null) {
        state.hierarchy = selectLabelInTree(state.hierarchy!, state.selected, false)
      }
      state.selected = null
      state.searchTerm = ''
      state.searchResults = []
      state.openedLabels = {
        [state.hierarchy!.labelId]: true
      }
      state.disabled = []
    }
  }
})

export const {
  search,
  toggle,
  assignTo,
  clearAssignSelection,
  reset,
  setHierarchy,
  setFrom
} = reassignLabelsSlice.actions

export const reassignLabelsReducers: ReassignLabelsReducers = {
  search,
  toggle,
  labelAdded: assignTo,
  labelRemoved: clearAssignSelection
}

export const useReassignLabels = (state: RootState) => state.reassignLabels

export default reassignLabelsSlice.reducer
