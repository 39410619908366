import { FC } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import AppLogo from 'public/images/lrm.png'
import { ConfirmDialog } from 'common/Dialogs/ConfirmDialog'
import { LisaModal } from 'common/Dialogs/LisaModal'
import Copyright from 'common/Copyright/Copyright'
import Navigation from 'common/NavigationBar/components/NavigationBar'
import { openConfirmDialog, openModal } from 'redux/slices/appSlice'
import { useLisaAuth } from 'hooks/useLisaAuth'
import packageJson from '../../../package.json'

const useStyles = makeStyles((theme) => ({
  drawer: {
    padding: '10px 16px 0 16px',
    minHeight: 'calc(100vh - 56px)',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    backgroundColor: theme.palette.grey2.light100
  },
  drawerOpen: {
    float: 'right',
    // minHeight: "calc(100vh - 56px)",
    [theme.breakpoints.up('md')]: {
      width: 'calc(100vw - 240px)'
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100vw - 57px)'
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    float: 'right',
    // minHeight: "calc(100vh - 56px)",
    [theme.breakpoints.up('md')]: {
      width: `calc(100vw - ${theme.spacing(7) + 1}px)`
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100vw - 57px)'
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  footer: {
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 2px',
    width: '100%',
    bottom: '0',
    left: '0px',
    fontSize: '14px',
    color: theme.palette.black.light,
    '& .copyright': {},
    '& .tcs': {
      '& a': {
        marginLeft: '12px',
        textDecoration: 'none',
        color: theme.palette.black.light,
        '&:hover': {
          color: theme.palette.blue.main
        }
      }
    }
  },
  dialogWrapper: {
    maxWidth: '360px',
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 0 32px 0',
    background: 'transparent',
    '& .app-name': {
      fontSize: '22px',
      fontWeight: '500',
      letterSpacing: '-.6px',
      marginBottom: '6px',
      textTransform: 'uppercase'
    },
    '& .app-ver': {
      fontSize: '16px',
      fontWeight: '600',
      marginBottom: '16px'
    },
    '& .body': {
      fontSize: '16px',
      fontWeight: '500',
      marginBottom: '8px'
    },
    ' & .small': {
      fontSize: '14px',
      fontWeight: '500',
      margin: '16px 0'
    }
  },
  logo: {
    backgroundColor: 'transparent',
    height: '36px',
    marginBottom: '36px',
    backgroundImage: `url("${AppLogo}")`,
    backgroundRepeat: 'no-repeat'
  },
  legalCopy: {
    margin: '24px 0',
    display: 'block',
    width: '100%',
    height: 'calc(100vh - 200px)',
    padding: '16px 32px 48px 32px',
    overflowY: 'auto',
    background: theme.palette.common.white,
    '& a': {
      color: theme.palette.blue.main,
      fontWeight: '600'
    },
    '& strong': {
      fontWeight: '600'
    },
    '& h1': {
      fontSize: '25px',
      lineHeight: '1',
      letterSpacing: '-0.25px',
      fontWeight: '600',
      textTransform: 'uppercase',
      paddingBottom: '24px',
      borderBottom: '1px solid rgba(0,0,0,.4)'
    },
    '& h2': {
      fontSize: '20px',
      letterSpacing: '-.24px',
      lineHeight: '1',
      fontWeight: '600',
      margin: '20px 0 10px 0'
    },
    '& h3': {
      fontSize: '18px',
      lineHeight: '1',
      fontWeight: '700',
      margin: '16px 0 8px 0'
    },
    '& h4': {
      fontSize: '15px',
      lineHeight: '1',
      fontWeight: '700',
      margin: '12px 0 4px 0'
    },
    '& p': {
      margin: '0 0 6px 0',
      fontSize: '16px',
      lineHeight: '1.5'
    },
    '& ul': {
      padding: '0 16px',
      '& li': {
        margin: '0',
        fontSize: '15px',
        lineHeight: '2'
      }
    }
  },
  newLayout: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px 0 24px',
    '& .columnGap1 > div:not(:first-child)': {
      paddingLeft: 8
    }
  }
}))

type ContentWrapperProps = {
  newLayout?: boolean
}
const ContentWrapper: FC<ContentWrapperProps> = (props) => {
  const classes = useStyles({ newLayout: props.newLayout })
  const { user: { settings: { sidebarExpanded: open } } } = useLisaAuth()
  const dispatch = useDispatch()

  return (
    <>
      <Navigation/>
      <div
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
          [classes.newLayout]: props.newLayout
        })}
      >
        {props.children}
        <div className={classes.footer}>
          <Copyright />
          <span className={'tcs'}>
            <a href="#" onClick={() => dispatch(openModal('PRIVACY_POLICY'))}>
            Privacy Policy
            </a>
            <a href="#" onClick={() => dispatch(openModal('TERMS'))}>
            Terms of use
            </a>
            <a href="#" onClick={() => dispatch(openModal('DISCLAIMER'))}>
            Disclaimer
            </a>
            <a href="#" onClick={() => dispatch(openConfirmDialog('OPEN_ABOUT_DIALOG'))}>
            About
            </a>
          </span>
        </div>
        <ConfirmDialog
          title='About'
          type={'OPEN_ABOUT_DIALOG'}
          info={true}
        >
          <div className={classes.dialogWrapper}>
            <div className={classes.logo}/>
            <div className={'app-name'}>
              <strong>lisa</strong> RECORDS MANAGEMENT
            </div>
            <div className={'app-ver'}>version {packageJson.version}</div>
            <div className={'body'}>Licence: AMROS Innovations Ltd. Subscription</div>
            <div className={'small'}>
            AMROS, the AMROS LISA Logo and LISA RECORDS MANAGEMENT are trademarks or
            registered trademarks of Amros Innovation Ltd. All other trademarks
            are property of their respective owners.{' '}
            </div>
            <div className={'body'}>&copy; 2021 - {new Date().getFullYear()} AMROS Innovations Ltd.</div>
          </div>
        </ConfirmDialog>

        <LisaModal
          title='Legal'
          scroll={'body'}
          modalType={'TERMS'}
        >
          <div className={classes.legalCopy}>
            <h1>Terms of use</h1>
            <p>
              <strong>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
          Please read these terms of service ("terms of service", "terms") carefully before using AMROSLISA website (“website”, "service") operated by AMROS Innovations Ltd. (“AMROS”, "us", 'we", "our").
              </strong>
            </p>
            <h2>1. Conditions of use</h2>
            <p>By using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to leave the website accordingly. AMROS only grants use and access of this website, its products, and its services to those who have accepted its terms.</p>
            <h2>2. Privacy policy</h2>
            <p>
          Before you continue using our website, we advise you to read our <a href='#' onClick={() => dispatch(openModal('PRIVACY_POLICY'))}>Privacy Policy</a> regarding our user data collection. It will help you better understand our practices.
            </p>
            <h2>3. Intellectual property</h2>
            <p>
          You agree that all materials, products, and services provided on this website are the property of AMROS, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute the AMROS’ intellectual property in any way, including electronic, digital, or new trademark registrations.
            </p>
            <p>
          You grant AMROS a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. For issues regarding intellectual property claims, you should contact the company in order to come to an agreement prior upload.
            </p>
            <h2>4. User accounts</h2>
            <p>
          As a user of this website, you may be asked to register with us and provide limited private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password.
            </p>
            <p>
          If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address it accordingly.
            </p>
            <p>
          We reserve all rights to terminate accounts, edit or remove content and cancel orders in our sole discretion.
            </p>
            <h2>5. Applicable law</h2>
            <p>
          By visiting this website, you agree that the laws of Switzerland, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between AMROS and you, or its business partners and associates.
            </p>
            <h2>6. Disputes</h2>
            <p>
          Any dispute related in any way to your visit to this website or to products you purchase from us shall be arbitrated by state or federal court in Zurich, Switzerland and you consent to exclusive jurisdiction and venue of such courts.
            </p>
            <h2>7. Indemnification</h2>
            <p>
          You agree to indemnify AMROS and its affiliates and hold AMROS harmless against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel.
            </p>
            <h2>8. Limitation on liability</h2>
            <p>
          AMROS is not liable for any damages that may occur to you as a result of your misuse of our website.
            </p>
            <p>
          AMROS reserves the right to edit, modify, and change this Agreement any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between AMROS and the user, and this supersedes and replaces all prior agreements regarding the use of this website.
            </p>
          </div>
        </LisaModal>
        <LisaModal
          title='Privacy Policy'
          scroll={'body'}
          modalType={'PRIVACY_POLICY'}
        >
          <div className={classes.legalCopy}>
            <h1>PRIVACY POLICY</h1>
            <h2>1. Purposes of the present privacy policy</h2>
            <p>The present privacy policy relates to the handling of personal data of the natural persons of customers and other interested persons (the “data subjects”) by AMROS Innovations Ltd., Lindenstrasse 25, CH-8302 Kloten or one of its subsidiaries within the AMROS Group (hereinafter “AMROS”, “we” or “us”).</p>
            <p>Please be aware that this privacy policy may be subject to changes.</p>
            <h2>2. Your partners</h2>
            <p>
          In general, the organizations of the AMROS process personal data of data subjects independently. In principle, the data subject can assume that the organization of AMROS that they are in contact with or associated with is the organization responsible for the processing of their personal data.
            </p>
            <p>
          Exceptions to this principle are possible. This is particularly the case with regard to the dissemination of newsletters or other marketing initiatives that are implemented for Europe by AMROS.
            </p>
            <h2>3. Purposes and principles of the data processing</h2>
            <p>
          We process personal data of data subjects based on various principles: For contract initiation, particularly for the purposes of making an offer and establishing contact by request. For fulfilling contractual obligations and claiming contractual rights from concluded contacts, particularly for the purposes of dispatching ordered products, processing customer inquiries including relevant correspondence, collection purposes, etc. The legal basis for this is an existing or initiated contractual relationship (legal basis within the scope of the GDPR: Art. 6 (1b); For implementing the recruiting process, in the context of applications for positions advertised. The legal basis for this is once again the initiation of a contractual relationship (legal basis within the scope of the GDPR: Art. 6 (1b); For bookkeeping in accordance with applicable law, as well as for fulfilling additional legal requirements and for internal archiving (legal basis within the scope of the GDPR: Art. 6 (1c) and Art. 6 (1f), where applicable); For maintaining the online presence of AMROS. Here, it is particularly worth mentioning the processing of the IP address as a log file for the purposes of operating the website, which lies in the legitimate interest of AMROS (legal basis within the scope of the GDPR: Art. 6 (1f));
            </p>
            <h2>4. Rights of data subjects</h2>
            <p>
          In connection with the processing of personal data, data subjects are granted the rights outlined below by law. Please be aware that their existence and scope can differ in individual cases depending on the data protection legislation that is specifically applicable: Information: Data subjects have the right to find out from AMROS whether any of their personal data is being processed and, if so, what this personal data may be. Information requests can be submitted for this purpose.
            </p>
            <p>
              <strong>Rectification:</strong> Should personal data be incorrect, the data subjects are legally entitled to rectification of this data. Objection: Provided the processing of personal data is based on the legitimate interest of AMROS, data subjects can object to the processing of their personal data. However, this can only occur if you are in a special situation where your personal interest precludes the processing of your personal data. In this case, the mandatory, overriding reasons of AMROS also remain reserved. Please also be aware that data subjects can object to the use of their personal data for direct advertising purposes. Processing that is supported by other principles remains unaffected by the objection.
            </p>
            <p>
              <strong>Withdrawal of consent:</strong> If the processing of the personal data is based on a declaration of consent by the data subject, the data subject can withdraw this. Processing that is supported by other principles remains unaffected by the withdrawal of consent. The withdrawal will only be valid for the future. Restriction: Under certain conditions, the data subject can demand the restriction of the processing of their personal data. This can take place if the validity of the data is in doubt, for example. Please be aware that, in certain cases, this can potentially restrict the provision of services by AMROS or render the provision of these services completely impossible.
            </p>
            <p>
              <strong>Data portability:</strong> If provided for by law, the data subject can demand return of the personal data that they have submitted to us in a conventional and machine-readable format and continue to use this, subject to conflicting interests that may carry more weight. Erasure: The data subject is legally entitled to have their personal data erased if this is no longer required for the purpose that it was collected for, if there is a legitimate objection or if consent has been withdrawn. In any case, usage for legally required reasons and for assertion, exercising and safeguarding of legal claims remains reserved.
            </p>
            <p>
              <strong>Complaint:</strong> We also wish to inform you that, according to the applicable data protection law, data subjects possess a legal right to lodge a complaint with the data protection authority that is responsible for them if necessary, should they not agree with how their personal data is being handled.
            </p>
            <h2>5. Other recipients of personal data</h2>
            <p>
          AMROS may employ external service providers as contracted data processors, particularly for the provision of services or for organizational purposes. These service providers only process the personal data on behalf of AMROS. Examples of such service providers include distribution partners, external IT service providers, external consultants, external administrative/commercial service providers or other organizations within the AMROS. The data protection legislation is maintained. Transfer of personal data to another organization of AMROS may occur if this is desired or if this seems necessary or advisable in the context of a contractual relationship, as well as for the purposes of implementing marketing initiatives.
            </p>
            <h2>6. Location of the data processing</h2>
            <p>
          In principle, data processing undertaken by AMROS takes places in Switzerland or in the country of the AMROS branch office/subsidiary that is concerned with the specific matter (the “country of origin”). A cross-border disclosure of personal data from the country of origin is possible. For this, the disclosure occurs in countries that have an equivalent data protection standard as the country of origin, or it occurs in the application of appropriate data protection safeguards or in light of the consent of the data subject. If you have any questions regarding this, please feel free to contact us.
            </p>
            <h2>7. Duration of storage of personal data</h2>
            <p>Personal data of data subjects is stored for as long as is necessary for fulfilling the purposes of the processing or for as long as this remains in AMROS’s legitimate interest. In the context of contractual relationships, the duration of the contractual relationship is binding, along with the duration of applicable guarantee periods and periods of limitation within the meaning of minimum retention periods. In addition, retention periods provided for by law are also binding, particularly for the storage of relevant business documentation.
            </p>
            <p>
          Personal data that is used for marketing purposes is erased in connection with the cancellation of the specific marketing initiative (e.g. of the newsletter).
            </p>
            <h2>8. Contact</h2>
            <p>
          We are happy to be contacted by data subjects with inquires in connection with the processing of personal data and the exercising of claims: <strong>AMROS Innovations Ltd., Lindenstrasse 25, CH-8302 Kloten</strong>. We endeavor to process all incoming inquiries in a timely manner.
            </p>
          </div>
        </LisaModal>
        <LisaModal
          title='Legal'
          scroll={'body'}
          modalType={'DISCLAIMER'}
        >
          <div className={classes.legalCopy}>
            <h1>Disclaimer</h1>
            <h2>1. Use of AMROSLISA website</h2>
            <p>
          The entire content of this website is subject to copyright of AMROS Innovations Ltd. with all rights reserved. For non-commercial use, you may download or print out individual pages and/or sections of the AMROSLISA website, provided that you do not remove any copyright or proprietary notices. Any downloading or other copying from the AMROSLISA website does not transfer title to any software or material to you. Any use of such downloaded or copied materials is subject to AMROS Innovations’ prior written authorization.
            </p>
            <h2>2. No warranty</h2>
            <p>
          The information and opinions contained on the AMROSLISA website are provided without any warranty of any kind, either express or implied.
            </p>
            <h2>3. Linked websites</h2>
            <p>
            AThe mentioning or inclusion of any links to another website, or reference to any product or service does not necessarily constitute or imply an endorsement or recommendation. AMROS Innovations Ltd. and their related, affiliated and subsidiary companies do not endorse or accept any responsibility in relation to such websites owned by third parties, neither for the content of such websites linked to the AMROSLISA website, nor for the products, services or other items offered through such websites.
            </p>
            <h2>4. Disclaimer and Limitation of liability</h2>
            <p>
          AMROS Innovations Ltd. assumes no responsibility for errors or omissions in the contents of AMROSLISA website.
            </p>
            <p>
          In no event, including (without limitation) negligence, shall AMROS Innovations Ltd. or any other related, affiliated or subsidiary company or its employees and officers be liable for any loss or damage of any kind, including (without limitation) any direct, indirect, special, incidental or consequential damages or any damage whatsoever, whether or not AMROSLISA has been advised of the possibility of such damages, and on any theory of liability, arising out of or in connection with the access of, use of, misuse of, inability to use of, performance of, browsing in or linking to other sites from the AMROSLISA website.
            </p>
            <p>
          AMROS Innovations Ltd. reserves the right to make additions, deletions, or modifications to the contents on the AMROSLISA website at any time without prior notice.
            </p>
            <h2>5. Software Quality</h2>
            <p>
          AMROS Innovations Ltd. and their related affiliated and subsidiary companies do not warrant that the information, content or software contained in one of their Web pages is free of viruses or other harmful elements.
            </p>
            <h2>6. Security statement</h2>
            <p>
          All information exchanged between users and AMROSLISA will be sent over an insecure line.
            </p>
          </div>
        </LisaModal>
      </div>
    </>
  )
}

export default ContentWrapper
