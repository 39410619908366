import { FC } from 'react'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { ExportForm } from './ExportForm'
import { ColumnConfigurator } from 'common/ColumnConfigurator/ColumnConfigurator'
import {
  clientColumns,
  ColumnConfiguratorKey,
  getCheckedColumnsFromCheckedColumnsSettings,
  mandatoryColumns
} from 'utils/columnConfigurator'
import { User } from 'types'
import { closeModal } from 'redux/slices/appSlice'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useDispatch, useSelector } from 'react-redux'
import { sortBy, SortColumnToConfigMap, useClientDocuments } from 'redux/slices/clientDocumentsSlice'
import { useUpdateUserSettingsMutation } from 'services/api/usersApi'

export const ClientModals: FC = () => {
  const { user: { settings }, userId } = useLisaAuth()
  const { filters } = useSelector(useClientDocuments)
  const dispatch = useDispatch()
  const [updateUserSettings] = useUpdateUserSettingsMutation()
  return (
    <>
      <LisaModal
        title="Export Delivery Bible"
        modalType={'EXPORT_DELIVERY_BIBLE'}
        scroll='body'>
        <ExportForm />
      </LisaModal>
      <ColumnConfigurator
        columns={clientColumns}
        checkedColumns={getCheckedColumnsFromCheckedColumnsSettings(settings.clientSettings)}
        mandatoryColumns={mandatoryColumns.get('client')}
        onSave={async (checkedColumns) => {
          const clientSettings = Object.keys(settings.clientSettings).reduce((is, ccKey) => {
            return {
              ...is,
              [ccKey]: checkedColumns.includes(ccKey as keyof User['settings']['clientSettings'])
            }
          }, settings.clientSettings)
          if (!checkedColumns.includes(SortColumnToConfigMap.get(filters.sortColumn) as ColumnConfiguratorKey)) {
            // Sort by name if currently selected column is turned off
            dispatch(sortBy('Name'))
          }
          updateUserSettings({
            userId,
            settings: {
              clientSettings
            }
          })
          dispatch(closeModal())
        }}/>
    </>
  )
}
