import { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Prompt as P, useHistory, useLocation } from 'react-router-dom'
import { closeConfirmDialog, openConfirmDialog } from 'redux/slices/appSlice'
import { ConfirmDialog } from './ConfirmDialog'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  message: {
    fontSize: '20px',
    padding: '48px 0'
  }
}))

interface IPromptProps {
  when: boolean,
  pageName: string
}

const Prompt: FC<IPromptProps> = ({ when, pageName }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [lastLocation, setLastLocation] = useState(location.pathname)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const handleBlockedNavigation = (nextLocation: { pathname: string }) => {
    const locationL = location.pathname
    const nextL = nextLocation.pathname

    if (!confirmedNavigation && when && locationL !== nextL) {
      dispatch(openConfirmDialog('PROMPT_DIALOG'))
      setLastLocation(nextL)
      return false
    }
    return true
  }

  const handleClose = () => {
    dispatch(closeConfirmDialog())
  }

  const handleOnConfirm = () => {
    setConfirmedNavigation(true)
    handleClose()
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation)
    }
  }, [confirmedNavigation, lastLocation, history])

  return (
    <>
      <P when={when} message={handleBlockedNavigation}/>
      <ConfirmDialog
        title='Change location'
        type={'PROMPT_DIALOG'}
        info={false}
        labels={{ yes: 'OK' }}
        onConfirm={handleOnConfirm}
      >
        <div className={classes.message}>{`If you leave ${pageName} page you will lose changes.`}</div>
      </ConfirmDialog>
    </>
  )
}

export default Prompt
