import { Grid, makeStyles } from '@material-ui/core'
import { LisaBox } from 'common/LisaBox/LisaBox'
import { NotificationsAvatar } from 'common/UserAvatar/UserAvatar'
import { FC } from 'react'
import { Notification } from 'types'
import { toFormattedString } from 'utils/date'
import NotificationMenu from './NotificationMenu'
import { NotificationItems } from './NotificationSelect'

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: 10,
    fontSize: '16px',
    lineHeight: 1.5,
    whiteSpace: 'pre-line'
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 600,
    paddingBottom: 10
  }
}))

type NotificationCardProps = {
  notification: Notification
}

export const NotificationCard: FC<NotificationCardProps> = ({ notification }) => {
  const classes = useStyles()
  const userNameSplit = notification.createdByName.split(' ', 2)
  const createdBy = {
    url: notification.createdByUrl,
    firstName: userNameSplit[0],
    lastName: userNameSplit[1],
    userId: notification.createdByUserId
  }
  const notificationType = NotificationItems.filter(n => n.value === notification.notificationTemplateId)
  return (
    <LisaBox
      container
      direction={'column'}
      style={{ minHeight: 142, rowGap: 20 }}
      justifyContent={'space-between'}
    >
      <Grid container item justifyContent={'space-between'}>
        <div>
          <NotificationsAvatar
            user={createdBy}
            notificationTypeName={notificationType[0].text}
            label={toFormattedString(new Date(notification.createdTime + 'Z'), true)}/>
        </div>
        <NotificationMenu notification={notification} />
      </Grid>
      <Grid container item>
        <div className={classes.content}>{notification.content}</div>
      </Grid>
    </LisaBox>
  )
}

export const SimpleNotificationCard: FC<NotificationCardProps> = ({ notification }) => {
  const classes = useStyles()
  const notificationType = NotificationItems.filter(n => n.value === notification.notificationTemplateId)
  return (
    <LisaBox
      container
      direction={'column'}
      style={{ minHeight: 120, maxWidth: 500, background: '#e7eaea' }}
      justifyContent={'space-between'}
    >
      <Grid container item justifyContent={'space-between'}>
        <div className={classes.title}>{notificationType[0].text}</div>
      </Grid>
      <Grid container item>
        <div className={classes.content}>{notification.content}</div>
      </Grid>
    </LisaBox>
  )
}
