import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConfirmDialogType, ModalType } from 'types'
import { RootState } from 'redux/store'
import { Color } from '@material-ui/lab/Alert'

export type AlertToastType = {
  open: boolean,
  severity: Color,
  message: string,
}

export interface AppState {
  activeModal: ModalType[]
  activeConfirmDialog: ConfirmDialogType
  alertToast: AlertToastType
  drawer: {
    width: number // @todo Not sure if needed
  },
  endSession: boolean // Set this to true to end users session
}

export const initialState: AppState = {
  activeModal: [],
  activeConfirmDialog: null,
  alertToast: {
    open: false,
    severity: 'info',
    message: ''
  },
  drawer: {
    width: 240
  },
  endSession: false
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openModal: (state, { payload }: PayloadAction<ModalType>) => {
      state.activeModal = [...state.activeModal, payload]
      state.activeConfirmDialog = null
    },
    closeModal: (state, { payload }: PayloadAction<undefined | ModalType[]>) => {
      if (payload === undefined) {
        state.activeModal = []
      } else {
        state.activeModal = state.activeModal.filter(_ => !payload.includes(_))
      }
    },
    openConfirmDialog: (state, { payload }: PayloadAction<ConfirmDialogType>) => {
      state.activeConfirmDialog = payload
      // state.activeModal = []
    },
    closeConfirmDialog: (state) => {
      state.activeConfirmDialog = null
    },
    openToast: (state, { payload }: PayloadAction<{ severity: Color, message: string }>) => {
      state.alertToast.open = true
      state.alertToast.severity = payload.severity
      state.alertToast.message = payload.message
    },
    closeToast: (state) => {
      state.alertToast = initialState.alertToast
    },
    endSession: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.endSession = payload ?? true
    }
  }
})

export const {
  openModal,
  closeModal,
  openConfirmDialog,
  closeConfirmDialog,
  openToast,
  closeToast,
  endSession
} = appSlice.actions

export const useApp = (state: RootState) => state.app

export const useEndSessionFlag = (state: RootState) => state.app.endSession

export default appSlice.reducer
