import { LisaModal } from 'common/Dialogs/LisaModal'
import * as Icons from 'common/Icons/SvgIcons'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/slices/appSlice'
import { IconButton, Tooltip, makeStyles } from '@material-ui/core'
import { RecycleBin } from './components/RecycleBin'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    color: theme.palette.black.main,
    background: theme.palette.statusColors.orange,
    padding: '2px 8px',
    borderRadius: '4px'
  },
  arrow: {
    color: theme.palette.statusColors.orange
  },
  modal: {
    '& .MuiDialogContent-root': {
      display: 'flex',
      paddingBottom: 32
    }
  }
}))

export const DocumentRemoval = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  return (
    <>
      <Tooltip title={'Deleted Documents'} placement='left'>
        <IconButton onClick={ () => dispatch(openModal('RECYCLE_BIN'))}>
          <Icons.RecycleBin />
        </IconButton>
      </Tooltip>
      <LisaModal
        className={classes.modal}
        fullScreen
        title={'Deleted Documents Search'}
        noTitle
        modalType={'RECYCLE_BIN'}
        maxWidth={'xl'}
      >
        <RecycleBin />
      </LisaModal>
    </>
  )
}
