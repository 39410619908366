import { FC } from 'react'
import { BusinessStatus } from 'types'
import clsx from 'clsx'
import { Chip, makeStyles } from '@material-ui/core'
import { businessStatusOptions } from 'common/Select/DocumentStatusSelect'
import { CheckMark } from 'common/Icons/SvgIcons'

const useStyles = makeStyles(() => ({
  documentStatusChip: {
    '&.MuiChip-root': {
      cursor: 'inherit'
    }
  }
}))

interface DocumentStatusIndicatorProps {
  status: BusinessStatus
}
export const DocumentStatusIndicator: FC<DocumentStatusIndicatorProps> = ({ status }) => {
  const classes = useStyles()
  const statusName = businessStatusOptions.find(_ => _.value === status)?.text ?? 'New'
  return (
    <Chip
      className={clsx([
        classes.documentStatusChip,
        'FileStatus',
        statusName
      ])}
      label={statusName}
      size="small"
      variant="outlined"
      icon={statusName === 'Accepted' ? <CheckMark /> : <></>}
    />
  )
}
