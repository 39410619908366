import { FC } from 'react'
import { Chip, ChipProps, makeStyles, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import { LabelBase, LabelId } from 'types'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useSelector } from 'react-redux'
import { useLabelsPath } from 'redux/slices/labelsSlice'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '24px',
    fontSize: '13px',
    fontWeight: 600,
    background: theme.palette.common.white,
    '&:focus': {
      backgroundColor: `${theme.palette.common.white} !important`
    },
    borderRadius: '4px',
    border: `2px solid ${theme.palette.statusColors.blue}`,
    transition: 'all .1s linear',
    margin: '0 4px 4px 0'
  },
  noMargin: {
    margin: 0
  },
  addable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${theme.palette.lime.main} !important`
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 3,
    color: theme.palette.a2k.main
  },
  deleteIcon: {
    display: 'flex',
    alignItems: 'center'
  },
  fromClient: {
    borderColor: theme.palette.statusColors.green
  },
  fromClientIcon: {
    color: theme.palette.statusColors.green
  }
}))

export type LabelProps = {
  label: LabelBase & {isSelected: boolean, path?: LabelId[]}
  variant?: ChipProps['variant']
  noMargin?: boolean
  documentIcon?: boolean
  fromClient?: boolean
  handleAddLabel?: () => void // @todo Probably not needed
  handleDeleteLabel?: () => void
}

export const Label: FC<LabelProps> = ({
  handleDeleteLabel,
  handleAddLabel,
  label,
  variant = 'outlined',
  noMargin,
  documentIcon = false,
  fromClient
}) => {
  const classes = useStyles()
  const { hasAccess } = useLisaAuth()
  const canDeleteLabel = hasAccess('perm_act_a2klabel')
  const labelPath = useSelector(useLabelsPath(label.path, label.labelId))
  return (
    <Tooltip title={labelPath}>
      <Chip
        className={clsx({
          [classes.root]: true,
          [classes.addable]: handleAddLabel !== undefined,
          [classes.noMargin]: noMargin,
          [classes.fromClient]: fromClient
        })}
        variant={variant}
        size="small"
        component={'span'}
        label={label.name}
        onDelete={label.isSelected ? handleDeleteLabel : undefined}
        onClick={() => handleAddLabel && handleAddLabel()}
        deleteIcon={canDeleteLabel ? <span data-testid='deleteIcon' className={`fas fa-times-circle ${classes.deleteIcon}`} /> : <></>}
        icon={<span className={clsx(classes.icon, { [classes.fromClientIcon]: fromClient })}><i data-testid='folderIcon' className={`fas fa-${documentIcon ? 'file' : 'folder'}`}/></span>}/>
    </Tooltip>
  )
}
