import { FC, useState } from 'react'
import { ColumnConfiguratorKey, columnConfiguratorLabels } from 'utils/columnConfigurator'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import SwitchLabel from 'common/LisaControls/SwitchLabel'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { useDispatch } from 'react-redux'
import { closeModal } from 'redux/slices/appSlice'

const useStyles = makeStyles(() => ({
  root: {
    paddingBlock: 16
  },
  configuratorContainer: {
    marginBlock: 8
  }
}))

export interface ColumnConfiguratorProps {
  columns: ColumnConfiguratorKey[]
  checkedColumns: ColumnConfiguratorKey[]
  mandatoryColumns?: ColumnConfiguratorKey[]
  onSave: (columns: ColumnConfiguratorKey[]) => void
  modalType?: 'COLUMN_CONFIGURATOR' | 'COLUMN_CONFIGURATOR_SEARCH' | 'COLUMN_CONFIGURATOR_RECYCLE_BIN'
}

export const ColumnConfigurator: FC<ColumnConfiguratorProps> = ({
  columns,
  checkedColumns,
  mandatoryColumns = [],
  onSave,
  modalType = 'COLUMN_CONFIGURATOR'
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [_checkedColumns, setCheckedColumns] = useState(checkedColumns)
  return (
    <LisaModal modalType={modalType} title={'Table View Configuration'}>
      <Grid container direction={'column'} spacing={2} className={classes.root}>
        <Grid item>
          <Typography>Please select table columns you would like to show/hide</Typography>
        </Grid>
        <Grid item container direction={'column'} spacing={1} className={classes.configuratorContainer}>
          {
            columns.map((column, index) => (
              <Grid key={`${String(column)}-${index}`} item>
                <SwitchLabel
                  variant={'inverted'}
                  disabled={mandatoryColumns.includes(column)}
                  label={columnConfiguratorLabels.get(column)}
                  value={mandatoryColumns.includes(column) || _checkedColumns.includes(column)}
                  onChange={(e, checked) => {
                    if (checked) {
                      setCheckedColumns([..._checkedColumns, column])
                    } else {
                      setCheckedColumns(_checkedColumns.filter(_ => _ !== column))
                    }
                  }}/>
              </Grid>
            ))
          }
        </Grid>
        <Grid item container spacing={2}>
          <Grid item>
            <Button
              disabled={false}
              onClick={() => onSave(_checkedColumns)}
              variant="contained"
              color="primary"
              size="small">
            Apply
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={false}
              onClick={() => {
                dispatch(closeModal([modalType]))
              }}
              variant="contained"
              color="primary"
              size="small">
            Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </LisaModal>
  )
}
