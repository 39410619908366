import { ChangeEvent, FC, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { makeStyles } from '@material-ui/core'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useGetNewQueriesCountQuery } from 'services/api/queryApi'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-table',
    verticalAlign: 'middle',
    width: 24,
    height: 20,
    backgroundColor: `${theme.palette.background.paper}`,
    borderRadius: '10px 10px 10px 0',
    marginLeft: '6px'
  },
  content: {
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center',
    color: 'black'
  }
}))

enum DashboardLinks {
  DASHBOARD = '/dashboard',
  MY_TRANSITIONS = '/dashboard/my-transitions',
  NEW_QUERIES = '/dashboard/new-queries'
}

const LisaDashboardTabs: FC = () => {
  const history = useHistory()
  const { hasAccess, userId } = useLisaAuth()
  const classes = useStyles()
  const canViewDashboardQueries = hasAccess('perm_view_query')
  const { data: queriesCount = 0 } = useGetNewQueriesCountQuery(userId!)

  const handleChange = (event: ChangeEvent<{}>, route: string) => {
    history.push(route)
  }
  const path = useMemo(() => history.location.pathname, [history.location.pathname])

  return (
    <Tabs
      value={path}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      centered>
      <Tab label="recent" value={DashboardLinks.DASHBOARD}/>
      <Tab label="My assets" value={DashboardLinks.MY_TRANSITIONS}/>
      {
        canViewDashboardQueries &&
        <Tab
          label={
            <div>
              NEW QUERIES{queriesCount > 0 && queriesCount
                ? <div className={classes.root}><span className={classes.content}>{queriesCount}</span></div>
                : <></>}
            </div>
          }
          value={DashboardLinks.NEW_QUERIES}/>
      }
    </Tabs>
  )
}

export default LisaDashboardTabs
