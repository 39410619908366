import { lisaApi } from 'services/api/lisaApi'
import { LisaResponseExtended, TenantId, User, UserId, UserListRow, UserSettings, UsersList } from 'types'
import { SortDirection } from '@material-ui/core'
import { Role } from 'utils/userRoleSecurity'

type UserArgs = {
  transitions: string[],
  roles: Role[],
  createdBy: string,
  firstName: string,
  lastName: string,
  company: string,
  email: string,
  jobTitle: string
}

export const usersApi = lisaApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, UserId>({
      query: (userId) => `User/${userId}`,
      providesTags: ['User']
    }),
    getUsers: builder.query<UserListRow[], void>({
      query: () => 'user',
      keepUnusedDataFor: 60
    }),
    getLisaUsers: builder.query<UsersList, {
      userId?: UserId,
      page?: number,
      pageSize?: number,
      sortOrder?: SortDirection,
      sortColumn?: string,
      includeDeactivated?: boolean,
    }>({
      query: ({
        userId = null,
        page = 1,
        pageSize = 15,
        sortOrder = 'asc',
        sortColumn = 'Name',
        includeDeactivated = false
      }) => ({
        url: 'User/UserList',
        params: {
          userId,
          page,
          pageSize,
          sortOrder,
          sortColumn,
          includeDeactivated
        }
      }),
      providesTags: ['Users']
    }),
    searchLisaUsers: builder.query<UserListRow[], {search:string, includeDeactivated:boolean}>({
      query: ({ search, includeDeactivated }) => ({
        url: 'User/Search',
        params: { search, includeDeactivated }
      }),
      providesTags: ['Users']
    }),
    updateOrCreateUser: builder.mutation<LisaResponseExtended, UserArgs & { userId?: UserId }>({
      query: (params) => ({
        url: `User/${params.userId ? 'Update' : 'Create'}`,
        method: params.userId ? 'PUT' : 'POST',
        body: params
      }),
      invalidatesTags: ['Users']
    }),
    deactivateUser: builder.mutation<LisaResponseExtended, {
      userId?: UserId
    }>({
      query: (params) => ({
        url: `/Users/${params.userId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Users']
    }),
    reactivateUser: builder.mutation<LisaResponseExtended, {
      userId?: UserId
    }>({
      query: (params) => ({
        url: `/users/reactivateuser/${params.userId}`,
        method: 'POST'
      }),
      invalidatesTags: ['Users']
    }),
    resendInvitation: builder.mutation<LisaResponseExtended, {
      userId?: UserId
    }>({
      query: (params) => ({
        url: `/users/resendinvitation/${params.userId}`,
        method: 'POST'
      })
    }),
    getRoles: builder.query<Role[], void>({
      query: () => 'Role'
    }),
    updatePassword: builder.mutation<LisaResponseExtended, { guid: UserId, newPassword: string }>({
      query: (params) => ({
        url: `User/ResetPassword?guid=${params.guid}&newPassword=${encodeURIComponent(params.newPassword)}`,
        method: 'POST'
      })
    }),
    uploadProfilePicture: builder.mutation<string, FormData>({
      query: (params) => ({
        url: 'User/UploadPicture',
        method: 'POST',
        body: params,
        responseHandler: 'text'
      })
    }),
    updateProfile: builder.mutation<{
      isError: boolean,
      message: string,
      userId: UserId,
      profilePictureURL: string
    }, {
      userId: string
      firstName: string
      lastName: string
      email: string
      jobTitle: string
      company: string
      password: string
      newPassword: string
      repeatNewPassword: string
      profilePictureURL?: string
      url: string
    }>({
      query: (params) => ({
        url: '/User/UpdateProfile',
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['User', 'Query', 'QueryComments', 'Transitions', 'TransitionTeam']
    }),
    sendResetPasswordMail: builder.mutation<void, string>({
      query: (email) => ({
        url: `User/ForgotPassword?email=${email}`,
        method: 'POST'
      })
    }),
    getProfilePicture: builder.query<string, string>({
      keepUnusedDataFor: 300, // 5 minutes
      query: (pictureUrl) => ({
        url: pictureUrl,
        responseHandler: (response) => response.blob().then((blob) => URL.createObjectURL(blob))
      })
    }),
    updateUserSettings: builder.mutation<User, {userId: UserId, settings: Partial<UserSettings>}>({
      query: ({ userId, settings }) => ({
        url: `User/UpdateSettings?userId=${userId}`,
        method: 'PUT',
        body: { settings }
      }),
      onQueryStarted: async ({ userId, settings }, { dispatch, queryFulfilled }) => {
        const patchResults = dispatch(usersApi.util.updateQueryData('getUser', userId, (draft) => {
          Object.assign(draft, {
            settings: {
              ...draft.settings,
              ...settings
            }
          })
        }))
        queryFulfilled.catch(patchResults.undo)
      }
    }),
    changeTenant: builder.mutation<LisaResponseExtended, TenantId>({
      query: (tenantId) => ({
        url: `User/changeTenant?tenantCode=${tenantId}`
      }),
      invalidatesTags: ['User']
    })
  })
})

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useGetLisaUsersQuery,
  useSearchLisaUsersQuery,
  useUpdateOrCreateUserMutation,
  useDeactivateUserMutation,
  useReactivateUserMutation,
  useGetRolesQuery,
  useUpdatePasswordMutation,
  useUploadProfilePictureMutation,
  useUpdateProfileMutation,
  useSendResetPasswordMailMutation,
  useGetProfilePictureQuery,
  useUpdateUserSettingsMutation,
  useChangeTenantMutation,
  useResendInvitationMutation
} = usersApi
