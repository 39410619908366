import { FC } from 'react'
import { LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core'
import { FileUpload, LoadingState } from 'types'
import clsx from 'clsx'

const useStyles = makeStyles<Theme, { isCompact: boolean }>((theme) => ({
  uploadProgress: {
    width: 'calc(100% - 12px)',
    marginInline: props => props.isCompact ? 0 : 6,
    marginBlock: props => props.isCompact ? 0 : 6,
    borderRadius: '4px',
    background: theme.palette.statusColors.bluelight,
    borderBottom: `1px solid ${theme.palette.grey2.main}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: props => props.isCompact ? '6px 16px 7px 16px' : '12px 16px',
    '& .header': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .title': {
        fontSize: props => props.isCompact ? 13 : 14,
        fontWeight: 600,
        textTransform: 'uppercase'
      },
      '& .right': {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.statusColors.blue,
        '& .percent': {
          color: theme.palette.black.light,
          fontSize: '14px'
        }
      }
    }
  },
  loader: {
    color: theme.palette.statusColors.blue,
    marginTop: props => props.isCompact ? 0 : 8,
    '& .file-progress': {
      fontSize: '13px',
      lineHeight: '1',
      fontWeight: 500,
      color: theme.palette.black.light,
      margin: '4px 0'
    },
    '& .MuiLinearProgress-colorPrimary': {
      background: 'white'
    },
    '& .MuiLinearProgress-bar': {
      background: theme.palette.statusColors.blue
    }
  }
}))

type FileUploadProgressProps = {
  fileUpload: FileUpload
  variant?: 'normal' | 'compact'
  className?: string
}
export const FileUploadProgress: FC<FileUploadProgressProps> = ({
  fileUpload,
  className,
  variant = 'normal'
}) => {
  const classes = useStyles({ isCompact: variant === 'compact' })
  if (fileUpload.loading !== LoadingState.Pending) {
    return null
  }
  return (
    <div className={clsx(classes.uploadProgress, className)}>
      <div className="header">
        <Typography className={'title'}>
          FILE UPLOAD IN PROGRESS
        </Typography>
        <div className="right">
          <Typography className={'percent'}>{fileUpload.uploadPercent}%</Typography>
        </div>
      </div>
      <div className={classes.loader}>
        <LinearProgress variant="determinate" value={fileUpload.uploadPercent} />
      </div>
    </div>
  )
}
