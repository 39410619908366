import { FC } from 'react'
import { QueryVisibility } from 'types'
import Dropdown, { DropdownProps } from 'common/LisaControls/Dropdown'

export type SelectOptions = {
  value: QueryVisibility
  text: string
  textSuffix?: string
}

export const queryVisibilityItems: SelectOptions[] = [
  {
    value: QueryVisibility.Internal,
    text: 'Internal'
  },
  {
    value: QueryVisibility.External,
    text: 'External'
  }
]

type VisibilitySelect = DropdownProps & {
  reduce: boolean
}

export const QueryVisibilitySelect: FC<VisibilitySelect> = (props) => {
  const { reduce } = props
  const reducedItems = !reduce ? queryVisibilityItems.filter(_ => _.text === 'External') : queryVisibilityItems
  return (
    <Dropdown
      items={reducedItems}
      multiple
      {...props}
    />
  )
}
