import { FC } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core'
import { User } from 'types'
import { UserAvatar } from 'common/UserAvatar/UserAvatar'

const useStyles = makeStyles((theme) => ({
  users: {
    display: 'flex',
    width: '100%',
    flexFlow: 'wrap',
    '& .MuiAvatar-root': {
      marginRight: '-10px',
      marginTop: '8px',
      '&:hover': {
        background: 'white',
        boxShadow: '0 2px 8px 0 rgba(0,0,0,.24)'
      }
    },
    owner: {
      '& .MuiAvatar-root': {
        marginRight: '0px'
      }
    }
  },
  avatar: {
  },
  teamLeadAvatar: {
    color: theme.palette.common.white
  }
}))

type UsersProps = {
  users: User[]
}
export const Users: FC<UsersProps> = ({ users }) => {
  const classes = useStyles()
  return (
    <div className={classes.users}>
      {users.map((user, index) => {
        const tooltipTitle = !user.firstName ? user.email : `${user.firstName} ${user.lastName}`
        return (
          <Tooltip key={`${user.userId}_${index}`} title={tooltipTitle}>
            <div>
              <UserAvatar user={{ ...user, url: user.profilePictureURL }}/>
            </div>
          </Tooltip>
        )
      })}
    </div>
  )
}
