import { lisaApi } from 'services/api/lisaApi'
import {
  ClientDocument,
  LabelResponse,
  LisaResponseExtended,
  PaginatedResults,
  SortDirection,
  TransitionId,
  UserId
} from 'types'

export type ExportProgress = {
  exportInProgres: boolean
  exportedCount: number
  exportedPercent: number
  totalCount: number
}

export const exportApi = lisaApi.injectEndpoints({
  endpoints: (builder) => ({
    getClientFilesFromFolder: builder.query<PaginatedResults<ClientDocument>, {
      transitionId: string,
      labelId?: number,
      page?: number,
      pageSize?: number,
      sortOrder?: SortDirection,
      sortColumn?: string
    }>({
      query: ({
        transitionId,
        labelId,
        page = 1,
        pageSize = 200,
        sortOrder = 'asc',
        sortColumn = 'Name'
      }) => ({
        url: 'Export/v2/Label/Documents',
        params: {
          transitionId,
          labelId,
          page,
          pageSize,
          sortOrder,
          sortColumn
        }
      }),
      providesTags: ['Documents']
    }),
    exportDeliveryBible: builder.mutation<LisaResponseExtended, {
      transitionId: TransitionId
      exportName: string
      userId: UserId
    }>({
      query: ({
        transitionId,
        exportName,
        userId
      }) => ({
        url: `Export/DeliveryBible?exportName=${exportName}&transitionId=${transitionId}&userId=${userId}`,
        method: 'POST'
      })
      // invalidatesTags: ['ExportProgress'] @todo Wont invalidate now. Export is having lag
    }),
    checkExportProgress: builder.query<ExportProgress, {
      transitionId: TransitionId
      exportName: string
    }>({
      query: (params) => ({
        url: 'Export/Progress',
        params
      }),
      providesTags: ['ExportProgress']
    }),
    getClientLabels: builder.query<LabelResponse, {
      transitionId: TransitionId
      showAll: boolean
    }>({
      query: ({
        transitionId,
        showAll = true
      }) => ({
        url: 'Export/Structure',
        params: {
          transitionId,
          showAll
        }
      }),
      transformResponse: (response: { structure: LabelResponse }) => response.structure
    })
  })
})

export const {
  useGetClientFilesFromFolderQuery,
  useExportDeliveryBibleMutation,
  useCheckExportProgressQuery,
  useGetClientLabelsQuery
} = exportApi
