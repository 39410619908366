import { useMemo } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx'
import '@react-pdf-viewer/thumbnail/lib/styles/index.css'
import { CircularProgress, Tooltip, Typography } from '@material-ui/core'
import { resolveDocumentType } from 'utils/documents'
import { OCRDocumentInfo } from './OCRDocumentInfo'
import { ConfirmDialog } from 'common/Dialogs/ConfirmDialog'
import { openConfirmDialog } from 'redux/slices/appSlice'
import { BusinessStatus, OcrStatus } from 'types'
import { businessStatusOptions } from 'common/Select/DocumentStatusSelect'
import { AbbreviationTypeIcon } from 'common/AbbreviationTypeIcon/AbbreviationTypeIcon'
import { useGetDocumentMetaDataQuery, useGetDocumentQuery, useGetOCRMessagesQuery, useGetProcessingStatusQuery } from 'services/api/documentApi'
import { useTransitionId } from 'context/TransitionContext'
import { useDocumentId } from 'context/DocumentContext'
import { useAuth } from 'react-oidc-context'
import { ProcessingStatusIndicator } from 'common/ProcessingStatusIndicator/ProcessingStatusIndicator'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0px 16px 16px 16px',
    padding: '4px',
    position: 'relative',
    minHeight: '165px',
    border: `1px solid ${theme.palette.blue.main}`,
    background: theme.palette.blue.main,
    borderRadius: '3px',
    overflow: 'hidden',
    '& canvas.react-pdf__Page__canvas': {
      width: ['100%', '!important'],
      height: ['auto', '!important'],
      cursor: 'default'
    },
    // HIDES TEXT OVERLAY IN DOC THUMBNAILS
    '& .react-pdf__Page__textContent': {
      display: 'none !important'
    }
  },
  thumbfileName: {
    fontSize: '15px',
    fontWeight: 600,
    color: theme.palette.common.white,
    textAlign: 'center',
    padding: '4px 4px 0 4px',
    overflowWrap: 'break-word',
    maxWidth: 170 // for single long word
  },
  ocrStatus: {
    position: 'absolute',
    zIndex: 2,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 10px',
    background: theme.palette.blue.main,
    borderRadius: '4px',
    cursor: 'pointer'
  },
  thumbHeader: {
    height: '25px',
    paddingBottom: '5px',
    display: 'flex',
    columnGap: '5px'
  },
  headerStatus: {
    flex: 2,
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    borderRadius: '2px',
    paddingLeft: '4px'
  },
  headerExtension: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    cursor: 'default'
  },
  thumbExtension: {
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: '.1px',
    color: 'white'
  },
  ocrStatusCompleted: {
    background: theme.palette.statusColors.green,
    color: theme.palette.common.white,
    borderRadius: '4px',
    boxShadow: '0 0 10px 0 rgba(0,0,0,.2)',
    '&:hover': {
      background: theme.palette.blue.main
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&:focus': {
      background: theme.palette.statusColors.green,
      color: theme.palette.common.white
    }
  },
  ocrStatusCompletedWithErrors: {
    background: theme.palette.statusColors.red,
    color: theme.palette.common.white,
    borderRadius: '4px',
    boxShadow: '0 0 10px 0 rgba(0,0,0,.2)',
    '&:hover': {
      background: theme.palette.blue.main
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&:focus': {
      background: theme.palette.statusColors.red,
      color: theme.palette.common.white
    }
  },
  ocrStatusProgress: {
    background: theme.palette.statusColors.blue,
    color: theme.palette.common.white,
    borderRadius: '4px',
    boxShadow: '0 0 10px 0 rgba(0,0,0,.2)',
    '&:hover': {
      background: theme.palette.blue.main
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&:focus': {
      background: theme.palette.statusColors.blue,
      color: theme.palette.common.white
    }
  },
  ocrStatusUnsupported: {
    background: theme.palette.statusColors.orange,
    color: theme.palette.common.white,
    borderRadius: '4px',
    boxShadow: '0 0 10px 0 rgba(0,0,0,.2)',
    '&:hover': {
      background: theme.palette.blue.main
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&:focus': {
      background: theme.palette.statusColors.orange,
      color: theme.palette.common.white
    }
  },
  loaderLabel: {
    fontSize: '14px',
    color: theme.palette.common.white,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,.45)'
  },
  thumbWrapper: {
    minHeight: 90, // @todo Temporary fix. Thumbnail will be reworked soon
    position: 'relative',
    display: 'flex',
    alignItems: 'end'
  },
  videoThumb: {
    width: '100%',
    height: 90,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    '& .fa-play-circle': {
      fontSize: 60,
      color: theme.palette.blue.main
    }
  },
  imgThumb: {
    width: 178,
    '& img': {
      width: 178
    }
  },
  documentType: {
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50px',
    cursor: 'pointer',
    '& .document-type-container': {
      width: '100%',
      lineHeight: 1.6
    }
  }
}))

const handleDocumentState = (bs: BusinessStatus) => {
  return businessStatusOptions.find(_ => _.value === bs)?.text ?? ''
}

const Thumbnail = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  const { user } = useAuth()
  const documentId = useDocumentId()
  const { data: { businessStatus } = { businessStatus: BusinessStatus.Open } } = useGetDocumentMetaDataQuery(documentId)
  const { data: document, isLoading: documentSpinner } = useGetDocumentQuery({ transitionId: useTransitionId(), id: documentId })
  const { url, extension, name, ocrStatus } = document ?? {
    isProcessed: false, url: '', extension: '', name: '', ocrStatus: OcrStatus.Ready
  }
  const { data: ocrMessages = [] } = useGetOCRMessagesQuery(documentId)
  const { data: documentStatus, isLoading: statusLoading } = useGetProcessingStatusQuery(documentId)
  const documentType = resolveDocumentType(extension, ocrStatus)
  const setConfirmOpen = () => dispatch(openConfirmDialog('DOCUMENT_OCR_INFO'))

  const file = useMemo(() => ({
    url: url && process.env.REACT_APP_BASE_URL + url,
    httpHeaders: {
      Authorization: user!.token_type + ' ' + user!.access_token
    }
  }), [])

  if (document === null) {
    return null
  }
  return (
    <>
      <div className={classes.root}>
        <div className={classes.thumbHeader}>
          <div className={classes.headerStatus}>
            <Chip
              className={clsx(['FileStatus', businessStatus && handleDocumentState(businessStatus)])}
              label={businessStatus && handleDocumentState(businessStatus)}
              size="small"
              variant="outlined"
            />
          </div>
          <Tooltip title={'Document origin type'}>
            <div className={classes.headerExtension}>
              <Typography className={classes.thumbExtension}>
                {extension}
              </Typography>
            </div>
          </Tooltip>
        </div>
        {documentSpinner && (
          <div className={classes.spinnerWrapper}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
              <Typography className={classes.loaderLabel}>Loading thumbnail</Typography>
            </div>
          </div>
        )}
        <div className={classes.thumbWrapper}>
          {(!documentSpinner && !statusLoading) && (<>
            <div className={classes.ocrStatus} onClick={() => setConfirmOpen()}>
              <ProcessingStatusIndicator
                ocrStatus={document?.ocrStatus!}
                processingStatus={`${documentStatus ?? '000'}`}
              />
            </div>
            <div className={classes.documentType}>
              <AbbreviationTypeIcon
                docTypeRecognitionResultByPages={document?.docTypeRecognitionResultByPages ?? []}
                docTypeRecognitionResult={document?.docTypeRecognitionResult ?? ''}/>
            </div>
          </>
          )}

          {
            !documentSpinner && documentType === 'pdf' && (
              <Document
                file={file}
              >
                <Page pageNumber={1} />
              </Document>
            )}
          {
            documentType === 'video' &&
              <div className={classes.videoThumb}>
                <i className="far fa-play-circle"/>
              </div>
          }
          {
            documentType === 'image' &&
            <div className={classes.imgThumb}>
              <img alt={name} id={'image-viewer-thumb'}/>
            </div>
          }
        </div>
        <div className={classes.thumbfileName}>{name}</div>
      </div>
      <ConfirmDialog
        info
        title="Document OCR info"
        type={'DOCUMENT_OCR_INFO'}>
        {
          document &&
          <OCRDocumentInfo document={document} ocrMessages={ocrMessages}/>
        }
      </ConfirmDialog>
    </>
  )
}

export default Thumbnail
