import { FC } from 'react'
import clsx from 'clsx'
import { makeStyles, Typography, Theme } from '@material-ui/core'

const useStyles = makeStyles<Theme, { majorRow: boolean, childRow: boolean }>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 12px 0px 0px',
    marginTop: (props) => props.childRow ? 1 : 6,
    width: '100%',
    background: (props) => props.majorRow ? theme.palette.grey2.main : theme.palette.grey2.light,
    borderRadius: '2px'
  },
  dataWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '4px',
    flex: 1
  },
  pie: {
    padding: '70px',
    position: 'relative'
  },
  transitionName: {
    padding: '10px'
  },
  text: {
    fontWeight: (props) => props.majorRow ? 900 : 600
  },
  value: {
    fontWeight: (props) => props.majorRow ? 900 : 600
  },
  indicator: {
    display: 'flex',
    flexDirection: 'row',
    width: '5px',
    alignSelf: 'stretch',
    '&.inQueue': {
      background: '#008000'
    },
    '&.success': {
      background: '#00C128'
    },
    '&.error': {
      background: '#E20001'
    }
  }

}))

type IndicatorColor = 'inQueue' | 'success' | 'error'

type SingleListItemProps = {
  text: string
  value: string | number,
  majorRow?: boolean,
  childRow?: boolean,
  indicatorColor?: IndicatorColor
}
const SingleListItem: FC<SingleListItemProps> = ({ text, value, majorRow = false, childRow = false, indicatorColor = 'inQueue' }) => {
  const classes = useStyles({ majorRow, childRow })

  return (
    <div className={classes.root}>
      {majorRow && <div className={clsx(classes.indicator, `${indicatorColor}`)}></div>}
      {childRow && <span style={{ paddingLeft: 4 }}>:</span>}
      <div className={classes.dataWrapper}>
        <div>
          <Typography variant="subtitle1" className={classes.text}>{text}</Typography>
        </div>
        <div >
          <Typography variant="subtitle1" className={classes.value}>{value}</Typography>
        </div>
      </div>
    </div>
  )
}

export default SingleListItem
