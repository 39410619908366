import { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { TransitionSettings } from 'pages/Settings/TransitionSettings'
import { TransitionTeamSettings } from 'pages/Settings/TransitionTeamSettings'
import { LabellingRules } from 'pages/Settings/LabellingRules'
import { useLisaAuth } from 'hooks/useLisaAuth'

export const TransitionSettingsRoutes: FC = () => {
  const { hasAccess } = useLisaAuth()
  if (!hasAccess('perm_act_transitionsettings')) {
    return (
      <Redirect from={'/structures/settings'} to={'/'}/>
    )
  }
  return (
    <Switch>
      <Route path={'/structures/settings/transition/:id'} component={TransitionSettings} />
      <Route path={'/structures/settings/team/:id'} component={TransitionTeamSettings} />
      {
        hasAccess('perm_act_transitionsettings_a2krule')
          ? <Route path={'/structures/settings/rules/:id'} component={LabellingRules}/>
          : <Redirect from={'/structures/settings/rules/:id'} to={'/structures/settings/transition/:id'}/>
      }
      <Redirect from={'/structures/settings/:id'} to={'/structures/settings/transition/:id'} />
    </Switch>
  )
}
