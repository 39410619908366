import { Grid, makeStyles } from '@material-ui/core'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { useDashboard } from 'redux/slices/dashboardSlice'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { WithQueriesLoader } from 'common/WithLoader/WithLoader'
import { QueryItem } from 'common/Items/QueryItem'
import { useHistory } from 'react-router-dom'
import { NewQueriesFilter } from './components/NewQueriesFilter'
import { filterQueriesByTransition, sortQueries } from 'utils/queries'
import { useGetNewQueriesQuery } from 'services/api/queryApi'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 16
  }
}))

const NewQueries = () => {
  const classes = useStyles()
  const history = useHistory()
  const { newQueriesFilters } = useSelector(useDashboard)
  const { userId } = useLisaAuth()
  const { data: newQueries = [], isLoading } = useGetNewQueriesQuery(userId)

  const queries = sortQueries(filterQueriesByTransition(newQueries, newQueriesFilters.transitionId), newQueriesFilters)
  return (
    <ContentWrapper newLayout>
      {
        newQueries.length > 0 &&
        <NewQueriesFilter/>
      }
      <WithQueriesLoader
        loading={isLoading}
        hasResults={queries.length > 0}>
        <Grid container spacing={2} className={classes.root}>
          {
            queries.map((query) => (
              <Grid key={query.queryId} item xs={6} md={4} lg={3}>
                <QueryItem
                  query={query}
                  onClick={(q) => {
                    history.push({
                      pathname: `/structures/queries/${q.transitionId}`,
                      search: `?qId=${q.queryId}`
                    })
                  }}/>
              </Grid>
            ))
          }
        </Grid>
      </WithQueriesLoader>
    </ContentWrapper>
  )
}

export default NewQueries
