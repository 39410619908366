import { FC } from 'react'
import { LabelFolder } from 'common/LabelFolder/LabelFolder'
import { Label } from 'types'
import { NoResults } from 'common/NoResults/NoResults'

export type LabelListProps = {
  labels: Label[]
}
/**
 * @TODO This should probably be names LabelsSearchResults and pull labels from closest providers store
 * @param labels
 * @constructor
 */
export const LabelList: FC<LabelListProps> = ({ labels }) => {
  return (
    <>
      {
        labels.map((label) => (
          <LabelFolder
            key={label.labelId}
            label={label}
            canBeOpened={false} />
        ))
      }
      {
        labels.length === 0 &&
          <NoResults/>
      }
    </>
  )
}
