import Dropdown from 'common/LisaControls/Dropdown'
import { FC, useEffect } from 'react'
import {
  AircraftType,
  EngineType,
  FamilyType,
  LandingGear,
  Manufacturer,
  TransitionComponent,
  TransitionType
} from 'types'

type TransitionComponentSelectProps = {
  value: TransitionComponent | 0,
  onChange: (to: TransitionComponent) => void,
  error?: string
}
export const TransitionComponentSelect: FC<TransitionComponentSelectProps> = ({ value, onChange, error }) => {
  return (
    <Dropdown
      required
      label={'Asset Item'}
      name='transitionObject'
      showSelectOption={false}
      items={[
        { value: 0, text: 'Select' },
        { value: TransitionComponent.Aircraft, text: 'Aircraft' },
        { value: TransitionComponent.Helicopter, text: 'Helicopter' },
        { value: TransitionComponent.Engine, text: 'Engine' },
        { value: TransitionComponent.LandingGear, text: 'Landing Gear' }
      ]}
      error={error}
      value={value}
      onChange={(e) => onChange(e.target.value as TransitionComponent)}/>
  )
}

// Manufacturer
type ManufacturerSelectProps = {
  value: Manufacturer
  onChange: (manufacturer: Manufacturer, manufacturerName: string) => void
  error?: string
}
export const manufacturers = [
  { value: Manufacturer.Airbus, text: 'Airbus' },
  { value: Manufacturer.Boeing, text: 'Boeing' },
  { value: Manufacturer.Embraer, text: 'Embraer' },
  { value: Manufacturer.BombardierAerospace, text: 'Bombardier Aerospace' },
  // { value: Manufacturer.Comac, text: 'Comac' },
  { value: Manufacturer.DeHavilandCanada, text: 'De Haviland Canada' },
  { value: Manufacturer.AvionTransportDeRegional, text: 'Avion Transport de Regional' },
  { value: Manufacturer.Other, text: 'Other' }
]
export const ManufacturerSelect: FC<ManufacturerSelectProps> = ({ value, onChange, error }) => {
  return (
    <Dropdown
      required
      error={error}
      label={'Manufacturer'}
      name='manufacturer'
      showSelectOption={false}
      items={[{ value: 0, text: 'Select' }, ...manufacturers]}
      value={value}
      onChange={(e) => {
        const manufacturer = e.target.value as Manufacturer
        onChange(manufacturer, manufacturers.find(_ => _.value === manufacturer)?.text ?? '')
      }}/>
  )
}

type ModelSelectProps = {
  manufacturer: Manufacturer
  value: AircraftType,
  onChange: (model: AircraftType) => void
  error?: string
}
export const aircraftTypeItems = (manufacturer?: Manufacturer) => {
  const items = []
  for (const model in AircraftType) {
    if (isNaN(+model)) {
      const isOther = model === 'OTHER'
      if (!isOther) {
        if (manufacturer === Manufacturer.Airbus && !/A[0-9]+/g.test(model)) {
          continue // if Airbus use only A***
        }
        if (manufacturer === Manufacturer.Boeing && model[0] !== 'B') {
          continue // if Boeing use only B***
        }
        if (manufacturer === Manufacturer.Embraer && model.slice(0, 3) !== 'ERJ') {
          continue // if Embraer use only ERJ***
        }
        if (manufacturer === Manufacturer.BombardierAerospace && model.slice(0, 3) !== 'CRJ' && model[0] !== 'Q') {
          continue // if BombardierAerospace use only CRJ*** AND Q***
        }
        if (manufacturer === Manufacturer.DeHavilandCanada && model.slice(0, 3) !== 'DHC') {
          continue // if DeHavilandCanada use only DHC***
        }
        if (manufacturer === Manufacturer.AvionTransportDeRegional && model.slice(0, 3) !== 'ATR') {
          continue // if AvionTransportDeRegional use only ATR***
        }
      }

      items.push({
        value: Number(AircraftType[model]) as AircraftType,
        text: model
      })
    }
  }
  return items.sort((a, z) => {
    if (a.text === 'OTHER') {
      return 1
    }
    if (z.text === 'OTHER') {
      return -1
    }
    return 0
  })
}
export const ModelSelect: FC<ModelSelectProps> = ({ manufacturer, value, onChange, error }) => {
  const aircraftItems = [{ value: 0, text: 'Select' }, ...aircraftTypeItems(manufacturer)]
  const hasThisModel = aircraftItems.map(_ => _.value).includes(value)

  useEffect(() => {
    if (!hasThisModel) {
      onChange(0)
    }
  }, [hasThisModel])
  return (
    <Dropdown
      required
      error={error}
      label={'Model'}
      showSelectOption={false}
      name='aircraftType'
      items={aircraftItems}
      value={hasThisModel ? value : 0}
      onChange={(e) => onChange(e.target.value as AircraftType)}/>
  )
}
type FamilyTypeProps = {
  disabled?: boolean
  value: FamilyType | ''
  onChange: (familyType: FamilyType) => void
}
export const FamilyTypeSelect: FC<FamilyTypeProps> = ({ value, onChange, disabled }) => {
  return (
    <Dropdown
      disabled={disabled}
      label={'Family'}
      name='familyType'
      showSelectOption={false}
      items={[
        { value: '', text: 'Select' },
        { value: FamilyType.WB, text: 'WB' },
        { value: FamilyType.NB, text: 'NB' },
        { value: FamilyType.RJ, text: 'RJ' }
      ]}
      value={value}
      onChange={(e) => onChange(e.target.value as FamilyType)}/>
  )
}

type TypeSelectProps = {
  value: TransitionType | 0
  onChange: (type: TransitionType) => void
  error?: string
}
export const TypeSelect: FC<TypeSelectProps> = ({ value, onChange, error }) => {
  return (
    <Dropdown
      required
      label={'Asset Type'}
      name='transitionType'
      items={[
        { value: TransitionType.AssetManagement, text: 'Asset Management' },
        { value: TransitionType.PhaseIn, text: 'Aircraft Transition: Phase-In' },
        { value: TransitionType.PhaseOut, text: 'Aircraft Transition: Phase-Out' },
        { value: TransitionType.LessorAircraft, text: 'Lessor Aircraft (Re-)Delivery' },
        { value: TransitionType.PrePurchaseInspection, text: 'Pre-purchase inspection' },
        { value: TransitionType.PreliminaryInspection, text: 'Preliminary Inspection' },
        { value: TransitionType.ArcReview, text: 'Airworthiness Review' },
        { value: TransitionType.UnassignedDocuments, text: 'AMOS Unassigned documenets' }
      ].sort((a, b) => a.text.localeCompare(b.text))}
      showSelectOption={false}
      error={error}
      value={value}
      onChange={(e) => onChange(e.target.value as TransitionType)}/>
  )
}

type EnginesSelectProps = {
  disabled?: boolean
  value: number
  onChange: (engineNumber: number) => void
  max?: number
}
export const EngineNumberSelect: FC<EnginesSelectProps> = ({ value, onChange, disabled = false, max }) => {
  return (
    <Dropdown
      disabled={disabled}
      label={'Engines'}
      name='engineAmount'
      showSelectOption={false}
      items={[
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' }
      ].filter(({ value }) => max && value <= max)}
      value={value}
      onChange={(e) => onChange(e.target.value as number)}/>
  )
}

export const landingGearOptions = [
  { value: LandingGear.NLG, text: 'NLG', property: 'nlg' },
  { value: LandingGear.LHMLG, text: 'LH MLG', property: 'lhMlg' },
  { value: LandingGear.RHMLG, text: 'RH MLG', property: 'rhMlg' },
  { value: LandingGear.LHCLG, text: 'LH CLG', property: 'lhClg' },
  { value: LandingGear.RHCLG, text: 'RH CLG', property: 'rhClg' },
  { value: LandingGear.CLDG, text: 'C LDG', property: 'cLdh' }
]
type LandingGearSelectProps = {
  disabled?: boolean
  value: string[]
  onChange: (landingGears: string[]) => void
  error?: string
}
export const LandingGearSelect: FC<LandingGearSelectProps> = ({ value, onChange, error, disabled = false }) => {
  return (
    <Dropdown
      disabled={disabled}
      required
      multiple
      label={'Landing Gears'}
      name='engineAmount'
      items={landingGearOptions}
      value={value}
      error={error}
      onChange={(e) => onChange(e.target.value as string[])}/>
  )
}

export const engineTypeOptions = [
  { value: EngineType.Jet, text: 'Jet' },
  { value: EngineType.Turboprop, text: 'Turboprop' }
]
type EngineTypeSelectProps = {
  disabled?: boolean
  value: EngineType | ''
  onChange: (engineType: EngineType) => void
  error?: string
}
export const EngineTypeSelect: FC<EngineTypeSelectProps> = ({ value, onChange, error, disabled = false }) => {
  return (
    <Dropdown
      disabled={disabled}
      required
      label={'Engine Type'}
      name='engineType'
      items={engineTypeOptions}
      value={value}
      error={error}
      onChange={(e) => {
        const et = e.target.value as EngineType
        if (et) {
          onChange(et)
        }
      }}/>
  )
}
