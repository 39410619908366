import { ChangeEvent, FC, useRef, useState, useCallback } from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { useDispatch, useSelector } from 'react-redux'
import { addQueryAttachment, useQueries, useQueryId } from 'redux/slices/queriesSlice'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { MaybeForbiddenAction } from 'common/MaybeForbiddenAction/MaybeForbiddenAction'
import { Attachment } from './Attachment'
import { AttachmentId, LoadingState, Nullable, ReporterPermissionGroup } from 'types'
import clsx from 'clsx'
import { FileUploadProgress } from 'common/FileUploadProgress/FileUploadProgress'
import {
  useDeleteQueryAttachmentMutation,
  useGetQueryAttachmentsQuery,
  useGetQueryDataQuery,
  useSetAttachmentAsReferenceMutation,
  useAddA2KLabelToAttachmentMutation
} from 'services/api/queryApi'
import { ConfirmDialog } from 'common/Dialogs/ConfirmDialog'
import { closeModal, openConfirmDialog, openModal, openToast } from 'redux/slices/appSlice'
import { RoundIconButton } from 'common/RoundIconButton/RoundIconButton'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { LabelTree } from 'common/LabelTree/LabelTree'
import { LabelsProvider, LabelsType } from 'context/LabelsContext'
import { LabelSearch } from 'common/LabelSearch/LabelSearch'
import { useBulkAssignLabels, labelRemoved, reset as resetLabels, getPreselectedLabel } from 'redux/slices/bulkAssignLabelsSlice'
import { LabelsSection } from 'components/BulkLabelAssign/LabelsSection'
import LisaLoader from 'common/Loaders/LisaLoader'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  selectedLabelContainer: {
    width: '100%',
    paddingBottom: '12px',
    '& .MuiChip-sizeSmall': {
      '&:hover': {
        background: theme.palette.common.white
      }
    },
    '& .MuiChip-deleteIcon': {
      '&:hover': {
        color: theme.palette.statusColors.red
      }
    }
  },
  actionButtons: {
    padding: '32px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  },
  labelListResults: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '400px',
    overflow: 'auto',
    borderRadius: '4px',
    marginTop: '16px',
    background: theme.palette.common.white
  },
  fileAttachment: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    position: 'relative'
  },
  uploadProgress: {
    width: '100%'
  },
  dialogMessage: {
    fontSize: '20px',
    fontWeight: 500,
    paddingTop: '48px',
    paddingBottom: '48px'
  },
  attachmentIcon: {
    transform: 'rotate(45deg)'
  },
  searchSpiner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiCircularProgress-colorPrimary': {
      color: '#001E50 !important'
    }
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1101
  }
}))

type QueryAttachmentsProps = {
  className?: string
}

export const QueryAttachments: FC<QueryAttachmentsProps> = ({ className }) => {
  const dispatch = useDispatch()
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const { attachmentUpload } = useSelector(useQueries)
  const {
    selected: selectedLabels,
    assignmentType
  } = useSelector(useBulkAssignLabels)
  const queryId = useQueryId()
  const { data: query } = useGetQueryDataQuery(queryId)
  const { data: attachments = [] } = useGetQueryAttachmentsQuery(queryId)
  const classes = useStyles()
  const { hasAccess, userId, isExternal } = useLisaAuth()
  const canAddAttachments = hasAccess('perm_act_query_edit') &&
    (query?.assignTo === userId || query?.reporter === userId)
  const canDeleteAttachment = hasAccess('perm_act_query_edit') &&
    (query?.assignTo === userId || query?.reporter === userId)
  const isReporterPermissionExternal = query?.reporterPermissionGroup === ReporterPermissionGroup.EXTERNAL
  const [deleteAttachmentId, setDeleteAttachmentId] = useState<Nullable<AttachmentId>>(null)
  const [deleteQueryAttachment] = useDeleteQueryAttachmentMutation()
  const [setAttachmentAsReference] = useSetAttachmentAsReferenceMutation()
  const [addA2KLabelToAttachment] = useAddA2KLabelToAttachmentMutation()
  const isMultiDocumentAssignment = assignmentType === 'multiple_documents'
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const onClose = () => {
    dispatch(closeModal(['ADD_A2K_LABEL_REF_FILE']))
    dispatch(resetLabels())
  }

  const handleAttachmentInputClick = () => {
    if (hiddenFileInput !== null && hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }

  const setAttachmentAsRef = useCallback(async (aId) => {
    const { success } = await setAttachmentAsReference({
      attachmentId: aId,
      queryId: queryId
    }).unwrap()
    if (success) {
      dispatch(openToast({
        severity: 'success',
        message: 'Attachment set as reference file!'
      }))
    }
  }, [])

  const setA2kLabelAssign = useCallback(async (aId, selectedLabels) => {
    setIsLoading(true)
    const { success } = await addA2KLabelToAttachment({
      attachmentId: aId,
      queryId: queryId,
      labels: selectedLabels
    }).unwrap()
    if (success) {
      dispatch(onClose())
      dispatch(openToast({
        severity: 'success',
        message: 'Labels added to attachment!'
      }))
    }
    dispatch(resetLabels())
    setIsLoading(false)
  }, [])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { files } = e.target
    if (files !== null) {
      dispatch(addQueryAttachment({
        queryId: query!.queryId,
        files,
        userId: userId!
      }))
      if (hiddenFileInput?.current) {
        hiddenFileInput.current.value = ''
      }
    }
  }

  const fileUpload = attachmentUpload[query!.queryId]

  return (
    <Grid container direction={'column'} spacing={1} className={clsx(className)}>
      <Grid item container justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant="subtitle2">
          {attachments.length > 0 && `${attachments.length} `}
          {attachments.length % 10 === 1 ? 'ATTACHMENT' : 'ATTACHMENTS'}
        </Typography>
        {
          (!fileUpload || fileUpload.loading !== LoadingState.Pending) &&
              <MaybeForbiddenAction isForbidden={!canAddAttachments}>
                <RoundIconButton
                  icon={<AttachFileIcon className={classes.attachmentIcon}/>}
                  onClick={() => canAddAttachments && handleAttachmentInputClick()}
                  color="primary"
                  size="small" />
              </MaybeForbiddenAction>
        }
      </Grid>
      {
        attachments.length > 0 &&
        <Grid item>
          {
            attachments.map((item) => {
              const canDelete = canDeleteAttachment || item.createdBy === userId
              const canExternalDelete = item.createdBy === userId || isReporterPermissionExternal
              return <Attachment
                key={item.attachmentId}
                attachment={item}
                canDelete={isExternal ? canExternalDelete : canDelete}
                onDelete={(attachmentId) => {
                  setDeleteAttachmentId(attachmentId)
                  dispatch(openConfirmDialog('ATTACHMENT_DELETE'))
                }}
                onSetReference={(attachmentId) => {
                  setDeleteAttachmentId(attachmentId)
                  dispatch(openConfirmDialog('SET_AS_REF_FILE'))
                }}
                onSetLabelsToDocument={(attachmentId) => {
                  setDeleteAttachmentId(attachmentId)
                  dispatch(openModal('ADD_A2K_LABEL_REF_FILE'))
                  dispatch(getPreselectedLabel(query?.labelId!))
                }}
              />
            })}
        </Grid>
      }
      {
        fileUpload &&
        <Grid className={classes.fileAttachment} item xs={12}>
          <FileUploadProgress
            variant={'compact'}
            className={classes.uploadProgress}
            fileUpload={fileUpload}/>
        </Grid>
      }
      <input
        // accept="image"
        multiple
        ref={hiddenFileInput}
        style={{ display: 'none' }}
        onChange={handleInputChange}
        type="file"
      />
      <ConfirmDialog
        title="Delete attachment"
        type={'ATTACHMENT_DELETE'}
        onConfirm={async () => {
          if (deleteAttachmentId) {
            deleteQueryAttachment({
              attachmentId: deleteAttachmentId,
              userId: userId!
            })
          }
        }}>
        <Typography
          className={classes.dialogMessage}
        >{'Are you sure you want to delete this attachment?'}</Typography>
      </ConfirmDialog>
      <ConfirmDialog
        title='Set as reference file'
        type={'SET_AS_REF_FILE'}
        onConfirm={() => setAttachmentAsRef(deleteAttachmentId)}>
        <Typography
          className={classes.dialogMessage}>
            Are you sure you want to set this as a reference file?
        </Typography>
      </ConfirmDialog>
      <LisaModal
        title={'Add A2K label to reference file'}
        modalType={'ADD_A2K_LABEL_REF_FILE'}
        maxWidth={'sm'}
        scroll="body"
        onCancel={() => dispatch(resetLabels())}
      >
        <LabelsProvider labelsType={LabelsType.BulkAssign}>
          {isLoading && <div className={classes.overlay}/>}
          <div className={classes.root}>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <LabelSearch/>
              </Grid>
              <Grid item xs={12} className={classes.selectedLabelContainer}>
                {
                  !isMultiDocumentAssignment && selectedLabels.length > 0 &&
                  <LabelsSection
                    labels={selectedLabels}
                    title={'Selected Labels'}
                    onLabelRemove={(label) => {
                      dispatch(labelRemoved(label))
                    }}/>
                }
              </Grid>
              <div className={classes.labelListResults}>
                <LabelTree/>
              </div>
            </Grid>
          </div>
          <div className={classes.actionButtons}>
            <Button
              disabled={isLoading}
              onClick={() => setA2kLabelAssign(deleteAttachmentId, selectedLabels)}
              variant='contained'
              color='primary'
              size='small'>
              Save
            </Button>
            <Button
              onClick={onClose}
              variant='contained'
              color='primary'
              size='small'>
              Cancel
            </Button>
          </div>
          {isLoading && <div className={classes.searchSpiner}>
            <LisaLoader text={'Creating a new document with labels...'} />
          </div>
          }
        </LabelsProvider>
      </LisaModal>
    </Grid>
  )
}
