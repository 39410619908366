import { ReactNode, useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import clsx from 'clsx'
import { SelectProps } from '@material-ui/core/Select/Select'
import { FormField } from 'common/FormField/FormField'
import Checkbox from '@material-ui/core/Checkbox'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles<Theme, { compact: boolean | undefined }>(() => ({
  select: {
    width: props => props.compact ? '130px' : '100%',
    maxHeight: '42px',
    '& .MuiOutlinedInput-input': {
      padding: props => `${props.compact ? '8.5px' : '18.5'} 14px`
    }
  },
  checkbox: {
    marginRight: 8,
    padding: 0
  }
}))

export interface SelectedItems {
  text: string | ReactNode
  value: string | ReadonlyArray<string> | number | undefined
  textSuffix?: string
  disabled?: boolean
}

export type DropdownProps = Omit<SelectProps, 'error'> & {
  items?: SelectedItems[]
  error?: string
  showSelectOption?: boolean
  compact?: boolean
}

const Dropdown = ({
  label,
  required,
  items = [],
  error,
  showSelectOption = true,
  className,
  compact,
  ...props
}: DropdownProps) => {
  const classes = useStyles({ compact })
  const multiple = props.multiple
  const value = props.value

  const MenuItems: ReactNode = useMemo(() => {
    const Items = items.map((item) => (
      <MenuItem
        key={`${item.value}`}
        value={item.value}
        disabled={item.disabled}
        className={clsx({ [classes.checkbox]: multiple })}>
        {
          multiple &&
          <Checkbox
            className={classes.checkbox}
            color='primary'
            size={'small'}
            checked={Array.isArray(value) && (value).includes(item.value)}/>
        }
        {item.text}{item.textSuffix ?? ''}
      </MenuItem>
    ))
    if (!multiple && showSelectOption) {
      return [(
        <MenuItem key={'select'} value={''}>
          Select
        </MenuItem>
      ), ...Items]
    }
    return Items
  }, [items, showSelectOption, multiple, value])

  const renderValue = useCallback((selected: DropdownProps['value']) => {
    return items.filter(_ => (selected as unknown[]).includes(_.value)).map(_ => _.text).join(', ')
  }, [multiple, value])

  return (
    <FormField label={label} required={required} error={error}>
      <Select
        fullWidth
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        renderValue={multiple ? renderValue : undefined}
        className={clsx(classes.select, className)}
        {...props}>
        {MenuItems}
      </Select>
    </FormField>
  )
}

export default Dropdown
