import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles, MenuItem } from '@material-ui/core'
import { LowQualityDocuments } from './components/LowQualityDocuments'
import { UnsupportedDocuments } from './components/UnsupportedDocuments'
import { UnprocessedDocuments } from './components/UnprocesssedDocuments'
import { OCRDocumentType, resetSelectedDocuments, selectView, useOCRStatus } from 'redux/slices/ocrStatusSlice'
import { LisaPaper } from 'common/LisaPaper/LisaPaper'
import { OCRModals } from './components/OCRModals'
import { openConfirmDialog } from 'redux/slices/appSlice'
import Dropdown from 'common/LisaControls/Dropdown'
import { ProcessingReport } from './components/ProcessingReport'
import { IncompleteDocuments } from './components/IncompleteDocuments'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& #mui-component-select-docTypePicker': {
      textTransform: 'capitalize'
    }
  },
  docTypePicker: {
    width: 190
  }
}))

const Ocr = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { selectedView, documentIdsForOCR } = useSelector(useOCRStatus)
  const hasDocumentIdsForOCR = documentIdsForOCR.length > 0

  useEffect(() => {
    dispatch(resetSelectedDocuments())
  }, [selectedView])

  useEffect(() => {
    dispatch(selectView(OCRDocumentType.Unprocessed))
  }, [])

  const items = useMemo(() => {
    return [
      <MenuItem
        key={'resend-to-ocr'}
        disabled={!hasDocumentIdsForOCR}
        onClick={() => dispatch(openConfirmDialog('SEND_DOCUMENTS_TO_OCR'))}>
        Resend Files to OCR
      </MenuItem>
    ]
  }, [selectedView, hasDocumentIdsForOCR])

  const title = (
    <div className={classes.docTypePicker}>
      <Dropdown
        name={'docTypePicker'}
        items={[
          {
            value: OCRDocumentType.Unprocessed,
            text: 'Files With Errors'
          },
          {
            value: OCRDocumentType.Unsupported,
            text: 'Unsupported Files'
          },
          {
            value: OCRDocumentType.LowQuality,
            text: 'OCR Low Quality Files'
          },
          {
            value: OCRDocumentType.Incomplete,
            text: 'Incomplete Files'
          }
        ]}
        showSelectOption={false}
        value={selectedView}
        onChange={(e) => {
          dispatch(selectView(e.target.value as OCRDocumentType))
        }}/>
    </div>
  )

  return (
    <Grid className={classes.root} container spacing={2}>
      <Grid
        // left side
        item
        container
        xs={12}
        sm={6}
        md={8}
        direction={'column'} style={{ minHeight: 500 }}>
        <LisaPaper toolbar={{ items, title }}>
          <Grid container direction={'column'}>
            {selectedView === OCRDocumentType.Unprocessed && <UnprocessedDocuments />}
            {selectedView === OCRDocumentType.Unsupported && <UnsupportedDocuments />}
            {selectedView === OCRDocumentType.LowQuality && <LowQualityDocuments />}
            {selectedView === OCRDocumentType.Incomplete && <IncompleteDocuments />}
          </Grid>
        </LisaPaper>
      </Grid>
      <Grid
        // right side
        item
        xs={12}
        sm={6}
        md={4}>
        <ProcessingReport />
      </Grid>
      <OCRModals/>
    </Grid>
  )
}

export default Ocr
