import { FC } from 'react'
import { LinearProgress, LinearProgressProps, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    marginBottom: 2
  },
  progressRoot: {
    backgroundColor: `${theme.palette.common.white} !important`
  },
  progressBarPrimary: {
    backgroundColor: theme.palette.statusColors.blue
  }
}))

type LisaProgressProps = LinearProgressProps & {
  title?: string
}
export const LisaProgress: FC<LisaProgressProps> = ({ title, classes: cls, ...props }) => {
  const classes = useStyles()
  return (
    <>
      {title && <div className={classes.title}>{title}</div>}
      <LinearProgress
        classes={{
          root: classes.progressRoot,
          barColorPrimary: classes.progressBarPrimary,
          ...cls
        }}
        {...props}/>
    </>
  )
}
