import { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Import from 'pages/Import/Import'
import Atok from 'pages/Atok/Atok'
import Client from 'pages/Client/Client'
import MxHistory from 'pages/MxHistory/MxHistory'
import { Queries } from 'pages/Queries/Queries'
import { TransitionProvider } from 'context/TransitionContext'
import { TransitionReports } from 'pages/TransitionReports/TransitionReports'
import { ErrorPage } from 'pages/ErrorPage/ErrorPage'
import { BulkLabelAssignModal } from 'components/BulkLabelAssign/BulkLabelAssign'
import { RenameDocumentForm } from 'common/RenameDocumentForm/RenameDocumentForm'
import { TransitionSettingsRoutes } from 'routes/TransitionSettingsRoutes'
import { useLisaAuth } from 'hooks/useLisaAuth'

export const TransitionRoutes: FC = () => {
  const { hasAccess } = useLisaAuth()

  return (
    <TransitionProvider>
      <Switch>
        {
          hasAccess('perm_view_impstructure')
            ? <Route path={'/structures/import/:id'} component={Import}/>
            : <Redirect from={'/structures/import/:id'} to={'/structures/atok/:id'}/>
        }
        {
          hasAccess('perm_view_a2kstructure')
            ? <Route path={'/structures/atok/:id'} component={Atok}/>
            : <Redirect from={'/structures/atok/:id'} to={'/structures/client/:id'}/>
        }
        {hasAccess('perm_view_clientstructure') && <Route path={'/structures/client/:id'} component={Client}/>}
        {
          hasAccess('perm_view_mxHistoryStructure')
            ? <Route path={'/structures/mxHistory/:id'} component={MxHistory}/>
            : <Redirect from={'/structures/mxHistory/:id'} to={'/structures/client/:id'}/>
        }
        {hasAccess('perm_view_query') && <Route path={'/structures/queries/:id'} component={Queries}/>}
        {hasAccess('perm_view_areports') && <Route path={'/structures/reports/:id'} component={TransitionReports}/>}
        {hasAccess('perm_act_transitionsettings') &&
          <Route path={'/structures/settings'} component={TransitionSettingsRoutes}/>}
        <Route path="*">
          <ErrorPage/>
        </Route>
      </Switch>
      <BulkLabelAssignModal/>
      <RenameDocumentForm/>
    </TransitionProvider>
  )
}
