import { ChangeEvent, FC, KeyboardEvent, useEffect, useMemo, useState } from 'react'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { Button, Grid, makeStyles } from '@material-ui/core'
import Input from 'common/LisaControls/Input'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { Nullable } from 'types'
import { hasAllowedCharacters, INVALID_FILE_NAME } from 'utils/validators'
import { useRenameDocumentMutation } from 'services/api/importApi'
import { useDocument } from 'redux/slices/documentSlice'

const useStyles = makeStyles(() => ({
  root: {
    marginBlock: 16
  },
  extension: {
    marginLeft: 5,
    marginTop: 5
  }
}))

export const RenameDocumentForm: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { documentToRename } = useSelector(useDocument)
  const { name, extension, storageId } = documentToRename ?? { name: '', extension: '', storageId: '' }
  const justName = useMemo(() => name.replace(extension, ''), [name, extension, storageId])
  const [newName, setNewName] = useState(justName)
  const [renameDocument, { isLoading }] = useRenameDocumentMutation()

  const errMsg: Nullable<string> = useMemo(() => {
    if (newName.trim().length === 0) {
      return 'File name cannot be empty'
    }
    if (!hasAllowedCharacters(newName.trim())) {
      return INVALID_FILE_NAME
    }
    if (newName.trim().length + extension.length > 750) {
      return 'File name limit is 750 characters'
    }
    return null
  }, [newName])

  useEffect(() => {
    setNewName(justName)
  }, [storageId])

  const onCancel = () => {
    dispatch(closeModal())
    setNewName(justName)
  }
  const handleRename = async () => {
    try {
      const { message, success } = await renameDocument({
        name: `${newName.trim()}${extension}`,
        storageId
      }).unwrap()
      if (message) {
        dispatch(openToast({
          severity: success ? 'success' : 'error',
          message
        }))
        if (success) {
          dispatch(closeModal())
        }
      }
    } catch (e) {
      const { data: { message } } = e as { data: { message: string } }
      if (message) {
        dispatch(openToast({
          severity: 'error',
          message
        }))
      }
    }
  }

  return (
    <LisaModal
      onCancel={() => setNewName(justName)}
      modalType={'RENAME_DOCUMENT'}
      title="Rename document"
      maxWidth="sm">
      <Grid container direction={'column'} spacing={2} className={classes.root}>
        <Grid item container alignItems={'center'} style={{ marginBottom: !errMsg ? 18 : 0 }}>
          <Grid item xs>
            <Input
              errMsg={errMsg ?? ''}
              fullWidth
              label={''}
              value={newName}
              onChange={(e: ChangeEvent<{value: string}>) => setNewName(e.target.value)}
              onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
                e.preventDefault()
                if (e.key === 'Enter') {
                  handleRename()
                }
              }}/>
          </Grid>
          <Grid item className={classes.extension}>{extension}</Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>
            <Button
              disabled={errMsg !== null || isLoading}
              onClick={handleRename}
              variant="contained"
              color="primary"
              size="small">
            Rename
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={isLoading}
              onClick={onCancel}
              variant="contained"
              color="primary"
              size="small">
            Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </LisaModal>
  )
}
