import { FC, useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Label, Nullable } from 'types'
import { useGetEventQuery, useGetWorkOrderQuery, useGetWorkPackageQuery } from 'services/api/wowpApi'
import { WithLoader } from 'common/WithLoader/WithLoader'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f3f6f6',
    padding: 16
  },
  blockRoot: {
    width: 'calc(100% / 6)' // @todo Probably bad
  },
  title: {
    color: theme.palette.black.light
  },
  text: {
    fontWeight: 600,
    color: '#3b3d3f'
  }
}))

type Block = {
  title: string
  text: string
}

export const InfoBlock: FC<Block> = ({ title, text }) => {
  const classes = useStyles()
  return (
    <Grid container direction={'column'}>
      <Grid item className={classes.title}>{title}</Grid>
      <Grid item className={classes.text}>{text || '-'}</Grid>
    </Grid>
  )
}

type WoWpInfoProps = {
  label: Label
}

export const WoWpInfo: FC<WoWpInfoProps> = ({ label }) => {
  const classes = useStyles()
  const isWoWpLabel = label.workorderUuid || label.workpackageUuid || label.eventUuid
  const {
    data: workorder,
    isFetching: isFetchingWorkOrder,
    fulfilledTimeStamp: fulfilledTimeStampWorkOrder
  } = useGetWorkOrderQuery(label.workorderUuid!, { skip: !label.workorderUuid })
  const {
    data: workpackage,
    isFetching: isFetchingWorkPackage,
    fulfilledTimeStamp: fulfilledTimeStampWorkPackage
  } = useGetWorkPackageQuery(label.workpackageUuid!, { skip: !label.workpackageUuid })
  const {
    data: event,
    isFetching: isFetchingEvent,
    fulfilledTimeStamp: fulfilledTimeStampEvent
  } = useGetEventQuery(label.eventUuid!, { skip: !label.eventUuid })
  const isFetching = isFetchingWorkOrder || isFetchingWorkPackage || isFetchingEvent

  const headerBlock: Block[] = useMemo(() => {
    if (label.workorderUuid && workorder) {
      return [
        { title: 'ATA chapter', text: workorder.ataChapter },
        { title: 'Workorder Type', text: workorder.workorderType },
        { title: 'Aircraft type', text: workorder.aircraftType }
      ]
    }
    if (label.workpackageUuid && workpackage) {
      return [
        { title: 'Station', text: workpackage.station },
        { title: 'Start Time', text: workpackage.startDateTime },
        { title: 'End Time', text: workpackage.endDateTime }
      ]
    }
    if (label.eventUuid && event) {
      return [
        { title: 'ATA Chapter', text: event.ataChapter },
        { title: 'Doc. Type', text: event.documentType },
        { title: 'Doc. Sub Type', text: event.documentSubType },
        { title: 'P/N', text: event.partNumber },
        { title: 'S/N', text: event.serialNumber },
        { title: 'Closing Time', text: event.closingDate }
      ]
    }
    return []
  }, [fulfilledTimeStampWorkOrder, fulfilledTimeStampWorkPackage, fulfilledTimeStampEvent])
  const description: Nullable<Block> = useMemo(() => {
    if (label.workpackageUuid && workpackage) {
      return {
        title: 'Description',
        text: workpackage.description
      }
    }
    if (label.eventUuid && event) {
      return {
        title: 'Report back message',
        text: event.reportBackMessage
      }
    }
    return null
  }, [fulfilledTimeStampWorkOrder, fulfilledTimeStampWorkPackage, fulfilledTimeStampEvent])

  if (!isWoWpLabel) {
    return null
  }
  return (
    <Grid style={{ width: 'calc(100% - 4px)' }}>
      <WithLoader loading={isFetching} hasResults={true}>
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12}>
            <Grid container>
              {
                headerBlock.map((block, i) => (
                  <Grid className={classes.blockRoot} item key={i}>
                    <InfoBlock {...block}/>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
          {
            description !== null &&
          <Grid item xs={12}>
            <InfoBlock {...description}/>
          </Grid>
          }
        </Grid>
      </WithLoader>
    </Grid>
  )
}
