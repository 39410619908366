import { FC } from 'react'
import { Grid, GridProps, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: 20
  }
}))

export const WhitePaper: FC<GridProps> = ({ children, className, ...props }) => {
  const classes = useStyles()
  return (
    <Grid className={clsx(classes.root, className)} {...props}>
      {children}
    </Grid>
  )
}
