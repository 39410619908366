import { FC, ReactNode } from 'react'
import { LisaDocumentWithLabels } from 'types'
import {
  Checkbox,
  makeStyles,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  TableSortLabelProps,
  Theme
} from '@material-ui/core'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import { SelectAllCell2 } from 'common/TableData/SelectAllCell'
import clsx from 'clsx'
import TableBody from '@material-ui/core/TableBody'
import { WithDocumentsLoader } from 'common/WithLoader/WithLoader'
import { WithPagination } from 'common/WithPagination/WithPagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'

const fileNameWidthCalculator = (colNo: number) => {
  const winWidth = window.innerWidth
  if (colNo > 4) {
    return winWidth * 0.13
  }
  if (winWidth >= 1900) {
    return 800 - colNo * 70
  } else {
    return 800 - colNo * 100
  }
}

export const useStyles = makeStyles<Theme, {
  isLoading: boolean
  hasPagination: boolean
  hasHeaderRow: boolean
  hasResults: boolean
  columnNo: number
}>((theme) => ({
  tableContainer: {
    width: '100%',
    overflow: ({
      isLoading,
      hasResults
    }) => isLoading || !hasResults ? 'hidden' : 'auto',
    paddingRight: ({
      isLoading,
      hasResults
    }) => isLoading || !hasResults ? 3 : '0',
    height: ({ hasPagination }) => `calc(100% - ${hasPagination ? '56px' : '0'})`,
    '& .fileName': {
      // width: '99%'
      // minWidth: ({ columnNo }) => fileNameWidthCalculator(columnNo),
      minWidth: ({ columnNo }) => fileNameWidthCalculator(columnNo),
      maxWidth: ({ columnNo }) => fileNameWidthCalculator(columnNo)
    },
    '& .fileNameMD': { // For smaller tables
      minWidth: ({ columnNo }) => fileNameWidthCalculator(columnNo) * 0.5,
      maxWidth: ({ columnNo }) => fileNameWidthCalculator(columnNo) * 0.5
    },
    '& .fileNameEllipsis': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginLeft: '0 !important'
    },
    '& .MuiTableRow-root.MuiTableRow-hover:hover': {
      backgroundColor: theme.palette.blue.main,
      '& td, .MuiChip-root.FileStatus': {
        color: 'white'
      },
      '& .document-type-container': {
        background: theme.palette.lime.main,
        color: theme.palette.blue.main,
        '&.error': {
          background: theme.palette.statusColors.red,
          color: theme.palette.lime.main
        }
      },
      '& .MuiCheckbox-colorSecondary, & ': {
        color: theme.palette.common.white
      },
      '& .MuiSvgIcon-root': {
        fill: 'white'
      },
      '& .MuiIcon-fontSizeSmall': {
        color: 'white'
      }
    },
    '& .MuiTableRow-root.Mui-selected td': {
      backgroundColor: theme.palette.grey2.light
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.blue.main,
      fontSize: '1rem'
    },
    '& .MuiFormControl-root .MuiTypography-body1': {
      fontSize: '12px !important',
      lineHeight: '12px !important',
      textTransform: 'uppercase',
      fontWeight: '700 !important',
      letterSpacing: '.05px',
      color: theme.palette.black.light
    },
    '& .MuiIcon-fontSizeSmall': {
      fontSize: '1rem',
      color: theme.palette.common.black
    }
  },
  table: {
    height: 'auto'
  },
  tbody: {
    '& tr:last-child td': {
      borderBottom: 'none'
    }
  },
  trHead: {
    height: 56
  },
  trBody: {
    height: 42
  },
  tdHidden: {
    height: 0
  },
  th: {
    whiteSpace: 'nowrap',
    padding: '0 24px 0 0',
    lineHeight: '12px',
    fontWeight: 600,
    color: theme.palette.black.main,
    backgroundColor: '#fff',
    '& .MuiTableSortLabel-root': {
      fontSize: 14,
      fontWeight: 600
    }
  },
  td: {
    padding: '0 24px 0 0',
    maxHeight: 42,
    whiteSpace: 'nowrap'
  },
  fileName: {
    minWidth: 250,
    maxWidth: 250
  },
  fileNameEllipsis: {
    minWidth: 250,
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: '0 !important'
  },
  loaderOverlay: {
    height: ({ hasPagination, hasHeaderRow }) =>
      `calc(100% - 56px - ${hasPagination ? '48px' : '0px'} -  ${hasHeaderRow ? '192px' : '0px'})`,
    top: ({ hasHeaderRow }) => `calc(48px + ${hasHeaderRow ? '192px' : '0px'})`,
    display: 'flex',
    width: '100%',
    position: 'absolute',
    backgroundColor: 'white'
  },
  paginationContainer: {
    height: 48,
    width: '100%'
  },
  dataTableCheckbox: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.35rem'
    }
  }
}))

export interface Column extends TableCellProps {
  id: string
  label: ReactNode
  className?: string
  tableSortLabelProps?: TableSortLabelProps
}

export interface Cell extends TableCellProps {
  id: string
  value: ReactNode
}

export interface Row {
  id: string
  selected: boolean
  cells: Cell[]
  document: LisaDocumentWithLabels
}

export interface LisaTableProps {
  columns: Column[]
  rows: Row[]
  onSelectAll?: () => void
  onRowToggle?: (document: LisaDocumentWithLabels) => void
  selectedRows?: any[]
  allRows?: any[]
  isFetching: boolean
  page: number
  pages: number
  pageSize: number
  total: number
  onPageChange: (page: number) => void
  disableRowsSelection?: boolean
  headerInfo?: ReactNode
}

export const LisaTable: FC<LisaTableProps> = ({
  columns,
  rows,
  isFetching,
  selectedRows = [],
  onSelectAll = () => null,
  onRowToggle = () => null,
  allRows = [],
  page = 1,
  pages = 1,
  pageSize = 0,
  total = 0,
  onPageChange,
  disableRowsSelection = false,
  headerInfo
}) => {
  const hasResults = rows.length > 0
  const classes = useStyles({
    hasPagination: pages > 0 && hasResults,
    hasResults,
    isLoading: isFetching,
    columnNo: columns.length,
    hasHeaderRow: headerInfo !== undefined
  })
  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow className={classes.trHead}>
              {
                !disableRowsSelection &&
                <SelectAllCell2
                  selectedItems={selectedRows}
                  items={allRows}
                  onChange={onSelectAll}/>
              }
              {
                columns.map(({ id, className, label, tableSortLabelProps, ...tcProps }, i) => (
                  <TableCell
                    key={id}
                    variant={'head'}
                    padding={disableRowsSelection && i === 0 ? 'checkbox' : 'none'}
                    size={'small'}
                    className={clsx(classes.th, className)}
                    {...tcProps}>
                    {
                      !tableSortLabelProps
                        ? label
                        : <TableSortLabel {...tableSortLabelProps} >{label}</TableSortLabel>
                    }
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody className={classes.tbody}>
            {
              headerInfo
                ? (
                  <TableRow>
                    <TableCell colSpan={columns.length + (disableRowsSelection ? 0 : 1)} padding={'none'}>
                      {headerInfo}
                    </TableCell>
                  </TableRow>
                )
                : null
            }
            {
              rows.map(({
                selected,
                id,
                cells,
                document
              }) => {
                return (
                  <TableRow key={id} selected={selected} hover={!selected} className={classes.trBody}>
                    {
                      !disableRowsSelection &&
                      <TableCell
                        size={'small'}
                        className={clsx(classes.td)}
                        padding="checkbox"
                        onClick={() => onRowToggle(document)}>
                        <Checkbox
                          className={classes.dataTableCheckbox}
                          checked={selected}
                          inputProps={{ 'aria-labelledby': document.documentId }}
                        />
                      </TableCell>
                    }
                    {
                      cells.map((cell, index) => {
                        const col = columns[index]
                        return (
                          <TableCell
                            key={cell.id}
                            size={'small'}
                            padding={disableRowsSelection && index === 0 ? 'checkbox' : 'none'}
                            className={clsx(classes.td, col?.className)}>
                            {cell.value}
                          </TableCell>
                        )
                      })
                    }</TableRow>)
              })
            }
          </TableBody>
        </Table>
      </TableContainer>

      {
        (isFetching || !hasResults) &&
        <div className={classes.loaderOverlay}>
          <WithDocumentsLoader loading={isFetching} hasResults={hasResults}/></div>
      }
      <div className={classes.paginationContainer}>
        <WithPagination
          options={{
            pageSize,
            total,
            selectedCounter: disableRowsSelection ? undefined : selectedRows.length,
            alwaysShow: true
          }}
          page={page}
          pages={pages}
          setPage={onPageChange}/>
      </div>
    </>
  )
}
