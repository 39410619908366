import { FC, MouseEvent } from 'react'
import { InputBaseProps, Theme, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import * as Icons from 'common/Icons/SvgIcons'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none',
    borderRadius: '4px',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.grey2.main}`,
    '&:hover': {
      borderColor: theme.palette.black.main
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: theme.palette.black.main
    }
  },
  input: {
    marginLeft: '16px',
    fontSize: '16px',
    fontWeight: 500,
    flex: 1,
    '&::placeholder': {
      color: 'red'
    },
    '&.MuiInputBase-input': {
      padding: '0'
    }
  },
  iconButton: {
    padding: 0,
    width: '40px',
    height: '40px',
    borderRadius: '0px',
    background: 'transparent',
    transition: 'all .1s linear',
    color: theme.palette.blue.main,
    '&:hover': {
      background: theme.palette.blue.main,
      color: theme.palette.common.white
    },
    '&:active': {
      background: theme.palette.lime.main,
      color: theme.palette.blue.main
    }
  },
  divider: {
    height: 28,
    margin: 4
  },
  searchIco: {
    width: '16px'
  }
}))

export interface InputLinkProps extends InputBaseProps {
  label?: string
  value: string
  iconAlign?: string
  onClick: (e: MouseEvent<HTMLElement>) => Promise<void>
}

const InputLink: FC<InputLinkProps> = (props) => {
  const {
    label,
    value,
    onClick,
    ...rest
  } = props

  const classes = useStyles()

  return (
    <Paper component='form' className={classes.root} onSubmit={e => { e.preventDefault() }}>
      <InputBase
        className={classes.input}
        placeholder={`${label}...`}
        inputProps={{ 'aria-label': `${label}...` }}
        value={value}
        autoComplete='off'
        {...rest}
      />
      <Tooltip title='copy link to clipboard'>
        <IconButton
          disabled={!!(value && value?.length < 3)}
          onClick={onClick}
          className={classes.iconButton}
          aria-label='search'
        >
          <Icons.LinkIco className={classes.searchIco} />
        </IconButton>
      </Tooltip>
    </Paper>
  )
}

export default InputLink
