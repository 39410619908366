import { FC } from 'react'
import { useTransitionId } from 'context/TransitionContext'
import { useGetTransitionQuery } from 'services/api/transitionApi'
import SettingsPageWrapper from 'pages/Settings/SettingsPageWrapper'
import { TransitionTeam } from 'components/Transitions/TransitionTeam'

export const TransitionTeamSettings: FC = () => {
  const transitionId = useTransitionId()
  const { data: transition } = useGetTransitionQuery(transitionId)
  if (!transition) {
    return null
  }
  return (
    <SettingsPageWrapper>
      <TransitionTeam transition={transition}/>
    </SettingsPageWrapper>
  )
}
