import { FC } from 'react'
import { Label } from 'common/Label/Label'
import { useSelector } from 'react-redux'
import { useLabelsContext } from 'context/LabelsContext'
import { useLabels } from 'redux/slices/labelsSlice'

export const PickedLabels: FC = () => {
  const { removeLabel } = useLabelsContext()
  const { selected } = useSelector(useLabels)
  return !selected
    ? null
    : <Label
      label={selected}
      handleDeleteLabel={() => removeLabel(selected)}/>
}
