export const isInViewportOf = (el: Element | null, reference: Element | null): boolean => {
  if (el === null || reference === null) {
    return false
  }
  const {
    top,
    bottom
  } = el.getBoundingClientRect()
  const containerRect = reference.getBoundingClientRect()
  return top > containerRect.top && bottom < containerRect.bottom
}

/**
 * Only use this if autoFocus attribute cannot be used
 * Common use case is focus on element becoming visible from hidden state
 * @param el
 */
export const autoFocus = (el: HTMLInputElement | null) => {
  if (el) {
    setTimeout(() => el.focus(), 0)
  }
}
