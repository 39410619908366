import { Label as LabelT, LabelId } from 'types'
import { FC } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { Label } from 'common/Label/Label'

const useStyles = makeStyles(() => ({
  sectionTitle: {
    fontSize: '14px',
    margin: '6px 0 6px 0',
    fontWeight: 700,
    letterSpacing: '.8px',
    textTransform: 'uppercase'
  }
}))

interface LabelsSectionProps {
  labels: LabelT[]
  title: string
  onLabelRemove: (label: LabelT) => void
  requiredLabels?: LabelId[]
}
export const LabelsSection: FC<LabelsSectionProps> = ({
  labels,
  title,
  onLabelRemove,
  requiredLabels = []
}) => {
  const classes = useStyles()
  if (labels.length === 0) {
    return null
  }
  return (
    <>
      <Typography
        className={clsx(classes.sectionTitle)}
        variant="h5">
        {title}
      </Typography>
      {
        labels.slice().sort((a, z) => a.name.toUpperCase().localeCompare(z.name.toUpperCase()))
          .map((label) => ( // @todo <Label /> does not cover all use cases anymore. Need to refactored
            <Label
              key={label.labelId}
              label={{
                ...label,
                isSelected: true
              }}
              handleDeleteLabel={requiredLabels.includes(label.labelId) ? undefined : () => onLabelRemove(label)}/>
          ))
      }
    </>
  )
}
