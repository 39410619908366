import {
  AircraftType,
  EngineType,
  FamilyType,
  LandingGear,
  Manufacturer,
  Nullable,
  ProjectId,
  SortDirection,
  Transition,
  TransitionComponent,
  TransitionFieldType,
  TransitionId,
  TransitionItem,
  User,
  UserId
} from 'types'

export const wantsTransitionField = (
  transitionComponent: TransitionComponent,
  transitionField: TransitionFieldType
): boolean => {
  if ([TransitionComponent.Aircraft, TransitionComponent.Helicopter].includes(transitionComponent)) {
    return true
  }
  if (transitionComponent === TransitionComponent.Engine) {
    return !['landingGears', 'landingGearPNs', 'landingGearSNs', 'engineAmount', 'apuPn', 'apuSn'].includes(transitionField)
  }
  if (transitionComponent === TransitionComponent.LandingGear) {
    return !['engineAmount', 'engineESN1', 'engineESN2', 'engineESN3', 'engineESN4'].includes(transitionField)
  }
  return true
}

const NB_AIRCRAFT_TYPES = [
  AircraftType.A318, AircraftType.A319, AircraftType.A320, AircraftType.A321,
  AircraftType.B737, AircraftType.B757
]

const WB_AIRCRAFT_TYPES = [
  AircraftType.A310, AircraftType.A330, AircraftType.A340, AircraftType.A350, AircraftType.A380,
  AircraftType.B767, AircraftType.B777, AircraftType.B787, AircraftType.B747
]

const RJ_AIRCRAFT_TYPES = [
  AircraftType.ERJ145, AircraftType.ERJ170, AircraftType.ERJ190, AircraftType.ERJ190E2, AircraftType.ERJ170E2,
  AircraftType.CRJ100, AircraftType.CRJ200, AircraftType.CRJ700, AircraftType.CRJ900, AircraftType.CRJ1000,
  AircraftType.Q400, AircraftType.DHC8, AircraftType.ATR42, AircraftType.ATR72, AircraftType.A220
]

const TWO_ENGINE_AIRCRAFT_TYPES = [
  AircraftType.A220, AircraftType.A318, AircraftType.A319, AircraftType.A320, AircraftType.A321, AircraftType.A310,
  AircraftType.A330, AircraftType.A350, AircraftType.B737, AircraftType.B757, AircraftType.B767, AircraftType.B777,
  AircraftType.B787, AircraftType.ERJ145, AircraftType.ERJ170, AircraftType.ERJ190, AircraftType.ERJ190E2,
  AircraftType.ERJ170E2, AircraftType.CRJ100, AircraftType.CRJ200, AircraftType.CRJ700, AircraftType.CRJ900,
  AircraftType.CRJ1000, AircraftType.Q400, AircraftType.DHC8, AircraftType.ATR42, AircraftType.ATR72
]
const FOUR_ENGINE_AIRCRAFT_TYPES = [
  AircraftType.A340, AircraftType.A380, AircraftType.B747
]

const PROPELLER_ENGINE_TYPES = [AircraftType.Q400, AircraftType.DHC8, AircraftType.ATR72, AircraftType.ATR42]

const getLendingGearsForTransition = (transition: Partial<Transition>): Nullable<LandingGear[]> => {
  switch (Number(transition.manufacturer)) {
  case Manufacturer.Airbus:
    switch (Number(transition.aircraftType)) {
    case AircraftType.A380:
      return [LandingGear.NLG, LandingGear.RHMLG, LandingGear.LHMLG, LandingGear.RHCLG, LandingGear.LHCLG]
    case AircraftType.A340:
      return [LandingGear.NLG, LandingGear.RHMLG, LandingGear.LHMLG, LandingGear.CLDG]
    default:
      return [LandingGear.NLG, LandingGear.RHMLG, LandingGear.LHMLG]
    }
  case Manufacturer.Boeing:
    switch (Number(transition.aircraftType)) {
    case AircraftType.B747:
      return [LandingGear.NLG, LandingGear.RHMLG, LandingGear.LHMLG, LandingGear.RHCLG, LandingGear.LHCLG]
    default:
      return [LandingGear.NLG, LandingGear.RHMLG, LandingGear.LHMLG]
    }
  case Manufacturer.Embraer:
  case Manufacturer.AvionTransportDeRegional:
  case Manufacturer.BombardierAerospace:
  case Manufacturer.DeHavilandCanada:
    return [LandingGear.NLG, LandingGear.RHMLG, LandingGear.LHMLG]
  default:
    return null
  }
}

export const applyManufacturerParams = (transition: Partial<Transition>): Partial<Transition> => {
  const aircraftType: AircraftType = Number(transition.aircraftType) ?? AircraftType.OTHER
  const t = { ...transition }
  // apply family
  if (NB_AIRCRAFT_TYPES.includes(aircraftType)) {
    t.familyType = FamilyType.NB
  }
  if (WB_AIRCRAFT_TYPES.includes(aircraftType)) {
    t.familyType = FamilyType.WB
  }
  if (RJ_AIRCRAFT_TYPES.includes(aircraftType)) {
    t.familyType = FamilyType.RJ
  }
  // Apply engine numbers
  if (TWO_ENGINE_AIRCRAFT_TYPES.includes(aircraftType)) {
    t.engineAmount = 2
  }
  if (FOUR_ENGINE_AIRCRAFT_TYPES.includes(aircraftType)) {
    t.engineAmount = 4
  }
  t.engineType = PROPELLER_ENGINE_TYPES.includes(aircraftType) ? EngineType.Turboprop : EngineType.Jet
  // Apply landing gear for transition. Ignore if not applicable
  const landingGears = getLendingGearsForTransition(t)
  if (landingGears !== null) {
    t.landingGears = landingGears
  }
  return t
}

export const sortTransitionsByCreateDate = (transitions: TransitionItem[], direction: SortDirection): TransitionItem[] => {
  return [...transitions].sort((a, z) => {
    return a.createdDate.localeCompare(z.createdDate) * (direction === 'asc' ? -1 : 1)
  })
}

export const filterTransitionsByProjectId = <T extends { projectId: ProjectId }> (transitions: T[], projectId: ProjectId): T[] => {
  return transitions.filter(_ => projectId === '' || _.projectId === projectId)
}

export const filterTransitionsByName = <T extends { transitionName: string, projectName: string }> (
  transitions: T[], name: string): T[] => {
  return transitions.filter(_ => _.transitionName.toUpperCase().includes(name.toUpperCase()) ||
    _.projectName.toUpperCase().includes(name.toUpperCase()))
}

export const filterTransitionsByUser = <T extends { users: User[] }> (transitions: T[], userId: UserId) =>
  transitions.filter((t) => !userId || t.users.some((u) => u.userId === userId))

export const deliveryBibleStorageKey = (transitionId: string) => `deliveryName_${transitionId}`

export const setDeliveryBibleName = (transitionId: TransitionId, deliveryBibleName: string) => {
  localStorage.setItem(deliveryBibleStorageKey(transitionId), deliveryBibleName)
}

export const getDeliveryBibleName = (transitionId: TransitionId) =>
  localStorage.getItem(deliveryBibleStorageKey(transitionId))

export const unsetDeliveryBibleName = (transitionId: TransitionId) =>
  localStorage.removeItem(deliveryBibleStorageKey(transitionId))
