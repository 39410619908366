import { ChangeEvent, useState } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Input from 'common/LisaControls/Input'
import Dropdown from 'common/LisaControls/Dropdown'
import TextArea from 'common/LisaControls/TextArea'
import { DropDownOnChangeEvent } from 'types'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiDivider-root': {
      width: '100%',
      margin: '10px 0px 0px 1px'
    },
    '& .MuiAccordionSummary-root': {
      flexDirection: 'row-reverse',
      padding: '0px'
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px 10px 10px 10px'
    }
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px'
  },
  sideIndicator: {
    position: 'absolute',
    overflow: 'hidden',
    height: '100%',
    width: '4px',
    left: '0',
    top: '0',
    '&.high': {
      background: theme.palette.statusColors.red
    },
    '&.low': {
      background: theme.palette.statusColors.green
    },
    '&.medium': {
      background: theme.palette.statusColors.orange
    },
    '&.info': {
      background: theme.palette.statusColors.blue
    }
  },
  userRole: {
    marginBottom: '2px'
  },
  controlsWrapper: {
    display: 'flex',
    columnGap: '10px',
    alignItems: 'center'
  }
}))

// @todo Unused
export default function InfoForm () {
  const [values, setValues] = useState({
    componentType: '',
    component: '',
    description: '',
    newComponentType: '',
    newDescription: '',
    msnasn: '',
    oldRegistration: '',
    newRegistration: '',
    secondType: '',
    subtype: '',
    transitionName: '',
    transitionType: 1
  })

  const [expanded, setExpanded] = useState<string | boolean>(false)

  const classes = useStyles()

  const handleInputChange = (e: DropDownOnChangeEvent) => {
    if (e.target.name) {
      setValues({
        ...values,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleChange = (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded === 'jedan'} onChange={handleChange('jedan')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${'jedan'}bh-content`}
          id={`${'jedan'}bh-header`}
        >
          <Typography variant='body2' className={classes.heading}>SELECT FROM EXISTING COMPONENTS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input
                label="type"
                name="componentType"
                value={values.transitionName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Dropdown
                label="component"
                name="component"
                items={[
                  {
                    value: 1,
                    text: 'Phase-In'
                  },
                  {
                    value: 2,
                    text: 'Phase-Out'
                  }
                ]}
                value={values.transitionType}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextArea
                label='description'
                name='description'
                value={values.description}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'dva'} onChange={handleChange('dva')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${'dva'}bh-content`}
          id={`${'dva'}bh-header`}
        >
          <Typography variant='body2' className={classes.heading}>CREATE NEW COMPONENT</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input
                label="type"
                name="newComponentType"
                value={values.newComponentType}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="description"
                name="newDescription"
                value={values.description}
                onChange={handleInputChange}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextArea
                label='MSN/ASN/ESN/SN'
                name='msnasn'
                value={values.msnasn}
                onChange={handleInputChange}
                required={true}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label='old registration'
                name='oldRegistration'
                value={values.oldRegistration}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label='new registration'
                name='newRegistration'
                value={values.newRegistration}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label='type'
                name='secondType'
                value={values.secondType}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label='subtype'
                name='subtype'
                value={values.subtype}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
