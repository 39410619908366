import { FC } from 'react'
import { Chip, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '16px',
    fontSize: '14px',
    fontWeight: 600,
    '&.MuiChip-colorPrimary': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: '20px'
    }
  }
}))

type QueryReferenceLabelProps = {
    queryReference: string
}

export const QueryReferenceLabel: FC<QueryReferenceLabelProps> = ({ queryReference }) => {
  const classes = useStyles()

  return (
    <Chip className={clsx(classes.root, 'queryRefLabel')} label={queryReference} size='small' color='primary' />
  )
}
