import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { makeStyles, Paper, Typography } from '@material-ui/core'
import clsx from 'clsx'
import * as Icons from 'common/Icons/SvgIcons'
import { Query, QueryStatus } from 'types'
import { useDispatch, useSelector } from 'react-redux'
import { selectQuery, useQueries } from 'redux/slices/queriesSlice'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { QueryReferenceLabel } from './QueryReferenceLabel'
import { useHistory } from 'react-router-dom'
import useOnScreen from 'hooks/useIsOnScreen'
import { QueryStatusChip } from 'components/Query/QueryStatusChip'
import { QueryRiskLevelIndicator } from 'components/Query/QueryRiskLevelIndicator'
import { formattedDate } from 'utils/date'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .fas.fa-exclamation-triangle': {
      color: theme.palette.statusColors.red
    }
  },
  paper: {
    padding: '10px 16px 10px 16px',
    cursor: 'pointer',
    display: 'block',
    justifyContent: 'space-between',
    borderRadius: '4px',
    boxShadow: 'none',
    transition: 'all .15s linear',
    marginBottom: '8px',
    '& .MuiChip-root': {
      background: 'rgba(255,255,255,.3)',
      borderRadius: '4px',
      padding: '4px',
      marginRight: '-4px',
      color: 'black'
    },
    '&:hover': {
      background: 'rgba(255,255,255,.7)',
      boxShadow: 'none'
    },
    '&.active': {
      background: theme.palette.blue.main,
      color: 'white !important',
      '& .MuiChip-root': {
        background: theme.palette.common.white
      }
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '4px'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '12px',
    '& .MuiTypography-body2': {
      fontWeight: 500
    },
    '& .queryProp': {
      paddingLeft: '5px',
      fontSize: '16px',
      fontWeight: 600
    },
    '& .MuiChip-colorPrimary': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: '20px'
    }
  },
  queryTitle: {
    width: '90%',
    fontSize: '18px',
    fontWeight: 600,
    whiteSpace: 'normal'
  },
  statusIcons: {
    flex: 1,
    marginLeft: '-5px'
  },
  queryReference: {
    display: 'flex',
    fontWeight: 600,
    justifyContent: 'flex-end',
    flex: 1,
    '&.orderOne': {
      order: -1,
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-start'
    }
  },
  queryStatusButton: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: 8
  },
  criticalColor: {
    fill: theme.palette.statusColors.red
  },
  showMore: {
    display: 'flex',
    padding: '12px 0 0 0',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '13px',
    lineHeight: '13px',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: theme.palette.black.light,
    textDecoration: 'none !important',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.black.main
    },
    '& .MuiSvgIcon-root': {
      height: '20px'
    }
  },
  dueDate: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    '&.orderTwo': {
      order: 2,
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end'
    }
  },
  ataChapter: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
    order: -1
  }
}))
type QueryItemProps = {
  query: Query,
  activeClass?: string,
  moreDetails?: ReactNode
}
export const QueryItem: FC<QueryItemProps> = ({ query, activeClass, moreDetails }) => {
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const { selectedQuery } = useSelector(useQueries)
  const dispatch = useDispatch()
  const isThisQuerySelected = selectedQuery === query.queryId
  const history = useHistory()
  const isActive = query.queryId.toUpperCase() === (selectedQuery || '').toUpperCase()
  const isSideletter = query.queryStatus === QueryStatus.Sideletter

  useEffect(() => {
    if (!isThisQuerySelected) {
      setExpanded(false)
    }
  }, [isThisQuerySelected])

  useEffect(() => {
    if (!isVisible && isActive) {
      ref && ref.current && ref.current.scrollIntoView(false)
    }
  }, [isActive])

  return (
    <div className={classes.root} ref={ref}>
      <Paper
        square={true}
        elevation={0}
        className={clsx(classes.paper, {
          [activeClass ?? 'active']: isActive
        })}
        style={{ cursor: moreDetails ? 'default' : 'pointer' }}
        onClick={() => {
          if (moreDetails) {
            dispatch(selectQuery(query.queryId))
          } else {
            history.push({
              pathname: `/structures/queries/${query.transitionId.toLowerCase()}`,
              search: `?qId=${query.queryId.toLowerCase()}`
            })
          }
        }}
      >
        <div className={classes.header}>
          <div className={classes.statusIcons}>
            {query.visibility === 2 && <Icons.ExternalQuery />}
            {query.critical && (
              <Icons.CriticalQuery className={classes.criticalColor} />
            )}
            {isSideletter && <Icons.SideletterQuery />}
          </div>
          <div className={classes.queryStatusButton}>
            <QueryStatusChip queryStatus={query.queryStatus}/>
            <QueryRiskLevelIndicator riskLevel={query.riskLevel} />
          </div>
        </div>
        <Typography className={classes.queryTitle}>{query.title}</Typography>
        <div className={classes.footer}>
          <div className={clsx([classes.dueDate, {
            orderTwo: moreDetails !== undefined
          }])}>
            <Typography variant="body2">
              {moreDetails === undefined ? 'Due date: ' : 'Due: '}
              <span className={'queryProp'}>{formattedDate(new Date(query.dueDate.toString()))}</span>
            </Typography>
          </div>
          {moreDetails === undefined && <Typography variant="body2" className={classes.ataChapter}>
            <>ATA Chapter: <span className={'queryProp'}>{query.ataChapter.length > 0 ? query.ataChapter : 'n/a'}</span></>
          </Typography>
          }
          <div className={clsx([classes.queryReference, {
            orderOne: moreDetails !== undefined
          }])}>
            {query.queryReference !== '' && <QueryReferenceLabel queryReference={query.queryReference}/>}
          </div>
        </div>
        { expanded ? moreDetails : null }
        {
          moreDetails !== undefined &&
          <div
            className={classes.showMore}
            onClick={() => {
              if (!expanded) {
                dispatch(selectQuery(query.queryId))
              }
              setExpanded(!expanded)
            }}>
            {expanded ? 'Show less' : 'Show more'}
            {expanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}
          </div>
        }
      </Paper>
    </div>
  )
}
