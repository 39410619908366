import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDocumentLabels } from 'redux/slices/documentLabelsSlice'
import { Label } from 'common/Label/Label'
import { useLabelsContext } from 'context/LabelsContext'
import { useLisaAuth } from 'hooks/useLisaAuth'

export interface DocumentLabelsProps {
  allowEmpty?: boolean
}
export const DocumentLabels: FC<DocumentLabelsProps> = ({ allowEmpty }) => {
  const { removeLabel, setRequiredLabels } = useLabelsContext()
  const { hasAccess } = useLisaAuth()
  const { selected } = useSelector(useDocumentLabels)
  useEffect(() => {
    if (selected.length === 1) {
      setRequiredLabels(selected.map(_ => _.labelId))
    } else {
      setRequiredLabels([])
    }
  }, [selected.length])
  return (
    <>
      {
        selected.filter(_ => _.isSelected).map((label) => (
          <Label
            key={label.labelId}
            label={label}
            handleDeleteLabel={allowEmpty || selected.length > 1
              ? () => {
                if (hasAccess('perm_act_a2klabel')) {
                  removeLabel(label)
                }
              }
              : undefined}/>
        ))
      }
    </>
  )
}
