import { makeStyles } from '@material-ui/core'
import { FC } from 'react'
import { useAuth } from 'react-oidc-context'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '50%',
    paddingLeft: '20px',
    alignItems: 'center',
    padding: '16px',
    fontSize: '14px'
  }
})

const Copyright: FC = () => {
  const classes = useStyles()
  const { isAuthenticated } = useAuth()

  return (
    <div className={isAuthenticated ? '' : classes.root}>
      <span>
        <strong>Copyright &#169;</strong> 2021 - {new Date().getFullYear()} AMROS Innovations AG. All rights
          reserved.
      </span>
    </div>
  )
}

export default Copyright
