import { FC } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Label } from 'common/Label/Label'
import { useDocumentContext } from 'context/DocumentContext'
import { labelAdded, useDocumentLabels } from 'redux/slices/documentLabelsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useGetRecentLabelsQuery } from 'services/api/labellingApi'
import { preselectRecentLabels } from 'utils/labels'

const useStyles = makeStyles({
  recentLabels: {
    margin: '0px 0 16px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    '& .recent-references-box': {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap'
    }
  },
  sectionTitle: {
    fontSize: '14px',
    marginBottom: '6px',
    fontWeight: 700,
    letterSpacing: '.8px',
    marginTop: '8px',
    textTransform: 'uppercase'
  }
})

export const RecentLabels: FC = () => {
  const classes = useStyles()
  const {
    transitionId,
    documentId,
    addDocumentLabel
  } = useDocumentContext()
  const { selected } = useSelector(useDocumentLabels)
  const dispatch = useDispatch()
  const { data = [], isLoading } = useGetRecentLabelsQuery({ transitionId, documentId })
  const recent = preselectRecentLabels(data, selected)

  if (isLoading && recent.length === 0) {
    return null
  }
  return (
    <div className={classes.recentLabels}>
      <Typography className={classes.sectionTitle} variant="h5">
        Recent Labels
      </Typography>
      <div className="recent-references-box">
        {recent.map((label) => (
          <Label
            key={label.labelId}
            label={label}
            handleAddLabel={() => {
              if (!label.isSelected) {
                addDocumentLabel(label)
                dispatch(labelAdded(label))
              }
            }}
          />
        ))}
      </div>
    </div>
  )
}
