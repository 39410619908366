import * as React from 'react'

import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, LinearProgress, makeStyles, Typography } from '@material-ui/core'
import { useImportDocuments, uploadLargeFile } from 'redux/slices/importDocumentsSlice'
import { useTransitionContext } from 'context/TransitionContext'
import { LoadingState } from 'types'
import { closeModal, openToast } from 'redux/slices/appSlice'

const useStyles = makeStyles((theme) => ({
  uploadFilesForm: {
    margin: '32px 0'
  },
  sectionTitle: {
    fontSize: '14px',
    margin: '32px 0 12px 0',
    fontWeight: 700,
    letterSpacing: '.8px',
    textTransform: 'uppercase'
  },
  filesWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    background: theme.palette.common.white,
    padding: '5px 7px 5px 16px',
    marginBottom: '4px',
    borderRadius: '4px',
    fontSize: '16px',
    lineHeight: '24px',
    '&:hover': {
      background: theme.palette.grey2.light
    }
  },
  selectedFiles: {
    margin: '32px 0',
    maxHeight: '50vh',
    overflow: 'auto'
  },
  filesWrapperDisabled: {
    background: theme.palette.grey2.light
  },
  progressWrapper: {
    height: 42,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.statusColors.blue,
    marginTop: '8px',
    '& .file-progress': {
      fontSize: '13px',
      lineHeight: '1',
      fontWeight: '500',
      color: theme.palette.black.light,
      margin: '4px 0'
    },
    '& .MuiLinearProgress-colorPrimary': {
      background: 'white'
    },
    '& .MuiLinearProgress-bar': {
      background: theme.palette.statusColors.blue
    }
  },
  progressBar: {
    width: '100%',
    height: 20
  },
  customFileUpload: {
    margin: '32px 0'
  }
}))

export const ImportLargeFileModal: FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { fileUpload, selectedFolder } = useSelector(useImportDocuments)
  const { id: folderId } = selectedFolder!
  const { transitionId } = useTransitionContext()
  const uploadInProgress = fileUpload.loading === LoadingState.Pending // @todo Probably redundant but keeping it for now

  useEffect(() => {
    if (fileUpload.loading === LoadingState.Pending && !fileUpload.isError) {
      dispatch(closeModal())
    }
  }, [fileUpload.isError, fileUpload.loading])

  function isValidFile (eventTarget:any) {
    if (eventTarget !== undefined) {
      const input = eventTarget[0] as HTMLInputElement
      if (input !== undefined) {
        const files = input.files
        if (files !== undefined && files![0] !== undefined) {
          if (files![0].size > 104857600) {
            return true
          }
        }
      }
    }

    return false
  }

  async function handleSubmit (event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    if (isValidFile(event.currentTarget)) {
      dispatch(uploadLargeFile({
        file: event.currentTarget,
        folderId,
        transitionId
      }))
    } else {
      dispatch(openToast({
        severity: 'error',
        message: 'File size needs to be bigger than 100 MB.'
      }))
    }
  }

  return (
    <div>
      <div className={classes.uploadFilesForm}>
        <Typography className={classes.sectionTitle}>Select Folder</Typography>
        <form id="upload-large-file-form" encType='multipart/form-data' onSubmit={handleSubmit}>
          <input id="file" type="file" name="file"/>
        </form>
        {
          !uploadInProgress &&
          <Button
            type="submit"
            form="upload-large-file-form"
            variant="contained"
            color="primary"
            className={classes.customFileUpload}>
            UPLOAD FILE
          </Button>
        }
        {
          uploadInProgress &&
          <div className={classes.progressWrapper}>
            <LinearProgress className={classes.progressBar} variant="determinate" value={fileUpload.uploadPercent} />
          </div>
        }
      </div>
    </div>
  )
}
