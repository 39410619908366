import { ChangeEvent, useState } from 'react'
import { Button, makeStyles, Tooltip, Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Chip from '@material-ui/core/Chip'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import * as Icons from 'common/Icons/SvgIcons'
import { DatatableOptions } from './DatatableOptions'
import FolderPath from './FolderPath'
import { useDocumentId } from 'context/DocumentContext'
import {
  useAddDocumentReferenceMutation,
  useGetDocumentMetaDataQuery,
  useGetDocumentRecentReferencesQuery, useUpdateDocumentMutation
} from 'services/api/documentApi'
import Input from 'common/LisaControls/Input'
import { useLisaAuth } from 'hooks/useLisaAuth'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 96px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .MuiInputBase-input': {
      backgroundColor: theme.palette.common.white,
      fontSize: '14px',
      lineHeight: '14px',
      fontWeight: 600,
      padding: '12px',
      border: `1px solid ${theme.palette.grey2.main}`,
      borderRadius: '4px'
    },
    '& .MuiChip-sizeSmall': {
      height: '24px',
      fontSize: '13px',
      // padding:'0 8px',
      background: 'white',
      borderRadius: '4px',
      border: `2px solid ${theme.palette.import.main}`
    },
    '& #text-area': {
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      fontSize: '16px',
      padding: '4px',
      width: '97%',
      minWidth: '97%',
      maxHeight: '97%',
      borderRadius: '4px',
      '&:focus': {
        outline: 'none',
        border: `1px solid ${theme.palette.grey2.dark100}`
      }
    },
    '& .MuiGrid-root': {
      flexWrap: 'unset',
      columnGap: '20px',
      '& .MuiTextField-root': {
        backgroundColor: 'white'
      }
    }
  },
  formWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    minWidth: 'auto',
    '& span': {
      marginRight: '6px',
      '&:last-of-type': {
        marginRight: '0px'
      }
    },
    '& .MuiFormControl-root': {
      minWidth: 'auto'
    }
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    marginTop: '10px',
    '& .MuiTextField-root': {
      backgroundColor: 'white',
      '& label': {
        fontSize: '12px'
      }
    }
  },
  recentReferences: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    '& .recent-references-box': {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      gap: '4px',
      '& .MuiChip-sizeSmall': {
        background: theme.palette.black.main,
        height: '24px',
        fontSize: '13px',
        fontWeight: 600,
        letterSpacing: '.3px',
        color: theme.palette.common.white,
        borderColor: theme.palette.black.light,
        borderRadius: '4px',
        transition: 'all .1s linear',
        '&:hover': {
          background: theme.palette.lime.main,
          borderColor: theme.palette.lime.main,
          color: theme.palette.black.main,
          boxShadow: 'none'
        }
      }
    }
  },
  addReference: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: '5px'
  },
  largeIcon: {
    transform: 'scale(1)'
  },
  metaDataTitle: {
    marginBottom: '16px',
    color: theme.palette.black.main
  },
  tabTitle: {
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '4px',
    lineHeight: '24px',
    height: '30px'
  },
  docDescription: {
    border: `1px solid ${theme.palette.grey2.dark}`
  },
  sectionTitle: {
    fontSize: '14px',
    marginBottom: '6px',
    fontWeight: 700,
    letterSpacing: '.8px',
    textTransform: 'uppercase'
  },
  errorMessage: {
    display: 'inline-block',
    textTransform: 'unset',
    width: 'auto',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.statusColors.red} `,
    fontSize: '14px',
    color: theme.palette.statusColors.red,
    background: theme.palette.common.white,
    padding: '0 6px',
    marginTop: '6px'
  },
  addMetaDataIcon: {
    fill: theme.palette.black.main,
    cursor: 'pointer',
    padding: '9px 0',
    '&:hover': {
      fill: theme.palette.black.light
    }
  }
}))

const MetaData = () => {
  const classes = useStyles()
  const {
    userId,
    hasAccess
  } = useLisaAuth()
  const documentId = useDocumentId()
  const { data: documentMetaData } = useGetDocumentMetaDataQuery(documentId)
  const { data: recentReferences = [] } = useGetDocumentRecentReferencesQuery(documentId)
  const {
    metadataId,
    businessStatus,
    importFolder,
    comment,
    filePath
  } = documentMetaData! // @todo should be fine
  const [localDescription, setLocalDescription] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [addDocumentReference] = useAddDocumentReferenceMutation()
  const [updateDocument] = useUpdateDocumentMutation()

  const [inputValues, setInputValues] = useState({
    referenceKey: '',
    referenceValue: ''
  })

  const handleInputValues = (event: ChangeEvent<{ name: string, value: string }>) => {
    const {
      name,
      value
    } = event.target
    setInputValues({
      ...inputValues,
      [name]: value
    })
  }

  const handleDescription = (e: ChangeEvent<{ value: string }>) => {
    setLocalDescription(e.target.value)
  }
  const handleUpdateDocument = () => {
    updateDocument({
      documentId,
      createdBy: userId!,
      comment: localDescription,
      businessStatus,
      metadataId
    })
  }
  const handleAddReference = () => {
    const {
      referenceKey,
      referenceValue
    } = inputValues
    if (referenceKey === '' || referenceValue === '') {
      setErrorMessage('Reference name or reference ID missing!')
    } else {
      setErrorMessage('')
      addDocumentReference({
        documentId,
        referenceKey,
        referenceValue,
        createdBy: userId!
      })
      setInputValues({
        referenceKey: '',
        referenceValue: ''
      })
    }
  }
  return (
    <div className={classes.root}>
      <div className={classes.metaDataTitle}>
        <Typography className={classes.tabTitle} variant="h6">
          Meta Data
        </Typography>
      </div>
      <FolderPath filePath={filePath} importFolder={importFolder}/>
      <DatatableOptions/>
      <div className={classes.description}>
        <Typography variant="body2">Description</Typography>
        <TextareaAutosize
          className={classes.docDescription}
          disabled={!hasAccess('perm_act_documentmetadata')}
          id="text-area"
          aria-label="minimum height"
          minRows={10}
          placeholder="document description"
          onChange={hasAccess('perm_act_documentmetadata') ? handleDescription : undefined}
          defaultValue={comment}/>

        {comment !== localDescription && localDescription !== '' && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginTop: '8px' }}
            onClick={handleUpdateDocument}
          >
            Update description
          </Button>
        )}
      </div>

      {recentReferences.length > 0 && (
        <div className={classes.recentReferences}>
          <Typography className={classes.sectionTitle} variant="h5">
            Document References
          </Typography>
          <div className="recent-references-box">
            {recentReferences.map((item) => (
              <Chip
                variant="outlined"
                size="small"
                key={`${item.referenceKey}-${item.referenceValue}`}
                label={`${item.referenceKey} ${item.referenceValue}`}
                component="a"
                clickable
              />
            ))}
          </div>
        </div>
      )}
      {
        hasAccess('perm_act_documentmetadata') &&
        <div className={classes.addReference}>
          <Typography className={classes.sectionTitle} variant="h5">
            Add Reference
          </Typography>
          <form noValidate autoComplete="off">
            <div className={classes.formWrapper}>
              <span>
                <Input
                  label="Reference Name"
                  name="referenceKey"
                  required={true}
                  value={inputValues.referenceKey}
                  onChange={handleInputValues}
                />
              </span>
              <span>
                <Input
                  label="Reference ID"
                  name="referenceValue"
                  required={true}
                  value={inputValues.referenceValue}
                  onChange={handleInputValues}
                />
              </span>
              <Tooltip title={'Add Reference'}>
                <span>
                  <FormControl>
                    <Icons.AddIco
                      className={classes.addMetaDataIcon}
                      onClick={handleAddReference}/>
                  </FormControl>
                </span>
              </Tooltip>
            </div>
            {errorMessage.length > 0 && (
              <Typography className={classes.errorMessage} variant="caption">
                {errorMessage}
              </Typography>
            )}
          </form>
        </div>
      }

    </div>
  )
}

export default MetaData
