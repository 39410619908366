import { ChangeEvent, FC, KeyboardEvent, useState } from 'react'
import LoginWallpaper from 'public/images/login-wallpaper.jpg'
import AmrosLogo from 'public/images/LOGO-01-Custom.png'
import { Button, makeStyles } from '@material-ui/core'
import Copyright from 'common/Copyright/Copyright'
import { useSendResetPasswordMailMutation } from 'services/api/usersApi'
import Input from 'common/LisaControls/Input'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    backgroundImage: `url('${LoginWallpaper}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    '& .formWrapper': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '50%',
      height: '100vh',
      '@media (max-width: 992px)': {
        width: '100%'
      }
    },
    '& .copyright': {
      fontSize: '14px',
      padding: '16px',
      textAlign: 'left',
      display: 'block',
      width: '100%',
      position: 'absolute',
      bottom: '0',
      '@media (max-width: 992px)': {
        width: '100%',
        textAlign: 'center'
      }
    }
  },
  logoSection: {
    width: 'auto',
    padding: '24px',
    display: 'block',
    boxSizing: 'border-box',
    position: 'absolute',
    top: 0,
    left: 0,
    '& img': {
      width: '200px'
    },
    '@media (max-width: 992px)': {
      width: '100%',
      textAlign: 'center'
    }
  },
  formSection: {
    boxSizing: 'border-box',
    border: 'none',
    borderRadius: '2px',
    maxWidth: '400px',
    width: 'calc(100% - 32px)',
    margin: '80px 16px',
    padding: '48px 36px',
    boxShadow: '0 0 32px 0 rgb(0 0 0 / 25%)',
    background: 'white',
    height: 'auto'
  },
  form: {
    width: '100%'
  },
  paper: {
    padding: '24px',
    height: '40%',
    width: '40%'
  },
  email: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '8px'
  },
  password: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '8px',
    '& .MuiSvgIcon-root': {
      width: '18px',
      height: '18px'
    }
  },
  forgotPassword: {
    textDecoration: 'none',
    marginTop: '16px',
    fontSize: '14px'
  },
  errorMessage: {
    color: theme.palette.statusColors.red,
    textDecoration: 'none',
    fontSize: '14px'
  },
  formText: {
    display: 'flex',
    flexDirection: 'column',
    '& .title': {
      fontSize: '24px',
      fontWeight: '700'
    },
    '& p': {
      fontSize: '16px',
      margin: 0
    },
    '& span': {
      fontSize: '16px',
      fontWeight: 700,
      marginBottom: '28px'
    }
  },
  checkText: {
    background: 'white',
    padding: '34px',
    fontSize: '24px',
    fontWeight: 600
  }
}))

const MailForm: FC = () => {
  const [values, setValues] = useState({
    email: '',
    error: ''
  })
  const [sendResetPasswordMail] = useSendResetPasswordMailMutation()

  const [checkMessage, setCheckMessage] = useState(false)
  const classes = useStyles()
  const emailRegex: RegExp = /^(('[\w-\s]+')|([\w-]+(?:\.[\w-]+)*)|('[\w-\s]+')([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value
    })
  }
  const validate = () => emailRegex.test(values.email) && values.email.length > 0

  const validateEntry = () => {
    if (validate()) {
      setCheckMessage(true)
      sendResetPasswordMail(values.email)
    } else {
      setValues({ ...values, error: 'Email pattern is not correct.' })
    }
  }

  const enterPressed = (e: KeyboardEvent<HTMLImageElement>) => {
    if (e.key === 'Enter') {
      validateEntry()
    }
  }

  return (
    <div className={classes.root}>
      <div className={'formWrapper'}>
        <div className={classes.logoSection}>
          <img src={AmrosLogo} alt={'amros-logo'}/>
        </div>
        {!checkMessage
          ? <div className={classes.formSection}>
            <div className={classes.form}>
              <div className={classes.formText} style={{ marginBottom: '32px' }}>
                <div className={'title'}>Forgot Password</div>
                <p className={'notes'}>Reset your password in couple of easy steps</p>
              </div>
              <div className={classes.email}>
                <Input
                  label='email'
                  name='email'
                  value={values.email}
                  onChange={handleInputChange}
                  onKeyUp={enterPressed}
                  errMsg={validate() ? '' : values.error}
                />
              </div>
              <p className={'notes'}>Type the address linked to your account and we&apos;ll send you password reset instructions. They might end up in your spam folder, so please check there as well.</p>
              <Button
                disabled={!validate()}
                variant='contained'
                color='primary'
                size='large'
                style={{ marginTop: '12px', width: '100%' }}
                onClick={validateEntry} >
                  RESET
              </Button>
            </div>
          </div>

          : <div className={classes.formSection}>
            <div className={classes.formText}>
              <div className={'title'}>Please Check your email</div>
              <p className={'notes'}>Email has been sent to {values.email}</p>
            </div>
          </div>
        }
      </div>
      <Copyright />
    </div>
  )
}

export default MailForm
