import { FC, ReactNode, useEffect, useMemo } from 'react'
import { MenuItem, TableSortLabelProps } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  ImportDocumentsSortColumns,
  resetSelectedDocuments,
  setPage,
  sortBy,
  toggleAllDocumentIds,
  toggleDocumentId,
  toggleUnlabelled,
  updateSelectedDocuments,
  useImportDocuments
} from 'redux/slices/importDocumentsSlice'
import { DocumentId, OcrStatus, User } from 'types'
import { DocumentLink } from 'common/DocumentLink/DocumentLink'
import { OcrPriorityIndicator } from './OcrPriorityIndicator'
import { MaybeForbiddenAction } from 'common/MaybeForbiddenAction/MaybeForbiddenAction'
import { useTransitionContext, useTransitionId } from 'context/TransitionContext'
import { openConfirmDialog, openModal } from 'redux/slices/appSlice'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { LisaPaper } from 'common/LisaPaper/LisaPaper'
import { DocumentLabels } from 'common/DocumentLabels/DocumentLabels'
import { useBulkLabelAssignActions } from 'components/BulkLabelAssign/BulkLabelAssign'
import { useGetFilesFromFolderQuery } from 'services/api/importApi'
import { setDocumentToRename } from 'redux/slices/documentSlice'
import SwitchLabel from 'common/LisaControls/SwitchLabel'
import { useGetTransitionQuery } from 'services/api/transitionApi'
import { AbbreviationTypeIcon } from 'common/AbbreviationTypeIcon/AbbreviationTypeIcon'
import { ColumnConfiguratorKey, columnConfiguratorLabels, importColumns } from 'utils/columnConfigurator'
import { ProcessingStatusIndicator } from 'common/ProcessingStatusIndicator/ProcessingStatusIndicator'
import { Cell, Column, LisaTable, Row } from 'common/LisaTable/LisaTable'
import { formattedDate } from 'utils/date'

export const ImportDocumentsList: FC = () => {
  const dispatch = useDispatch()
  const transitionId = useTransitionId()
  const {
    filters: {
      ocrStatus,
      sortOrder,
      sortColumn,
      unlabelled,
      page,
      pageSize,
      deleted
    },
    importFolderId,
    selectedDocuments,
    onlyUnlabelledFolders
  } = useSelector(useImportDocuments)
  const documentIds = selectedDocuments.map(_ => _.documentId)
  const { openDocument } = useTransitionContext()
  const {
    hasAccess,
    user: { settings: { importSettings } }
  } = useLisaAuth()
  const canTriggerBulkLabelAssign = hasAccess('perm_act_a2klabel')
  const {
    startDocumentAssign,
    startDocumentsAssign,
    startStorageAssign
  } = useBulkLabelAssignActions()
  const { data: transition } = useGetTransitionQuery(transitionId)
  const {
    data,
    isFetching,
    fulfilledTimeStamp,
    refetch
  } = useGetFilesFromFolderQuery({
    id: importFolderId,
    page,
    pageSize,
    deleted,
    unlabelled,
    sortOrder,
    sortColumn
  }, { skip: !importFolderId })
  const rows = data?.rows ?? []
  const pages = data?.pages ?? 0
  const total = data?.total ?? 0
  const documents = rows.filter((item) => ocrStatus.length === 0 || ocrStatus.includes(item.ocrStatus))
  const docStatusFilter = rows.some(_ => documentIds.includes(_.documentId) && _.ocrStatus === OcrStatus.Success)

  useEffect(() => {
    dispatch(updateSelectedDocuments(rows))
  }, [fulfilledTimeStamp])

  useEffect(() => {
    if (!transition?.importInProgress) {
      refetch()
    }
  }, [transition?.importInProgress])

  const handleOpenAllSelected = () => {
    documentIds.forEach((documentId) => {
      openDocument(documentId)
    })
    dispatch(resetSelectedDocuments())
  }

  const isSelectedDocumentOnPage = (documentId: DocumentId) => documents.some(_ => _.documentId === documentId)
  const documentToRename = rows.find(_ => _.documentId === selectedDocuments[0]?.documentId)

  const items = useMemo<ReactNode>(() => {
    const areDocumentSelected = documentIds.length > 0
    return [
      <MaybeForbiddenAction key="bulk-label-assign" isForbidden={!canTriggerBulkLabelAssign}>
        <MenuItem
          disabled={!areDocumentSelected}
          onClick={() => startDocumentsAssign(selectedDocuments)}>
          Bulk label assign
        </MenuItem>
      </MaybeForbiddenAction>,
      <MaybeForbiddenAction
        key="set-high-ocr" isForbidden={!hasAccess('perm_act_sethighocrprio')}>
        <MenuItem
          disabled={!areDocumentSelected || docStatusFilter}
          onClick={() => dispatch(openConfirmDialog('SET_HIGH_OCR_PRIORITY'))}>
        Set high OCR priority
        </MenuItem>
      </MaybeForbiddenAction>,
      <MenuItem key="open-selected" disabled={!areDocumentSelected} onClick={handleOpenAllSelected}>
        Open Selected
      </MenuItem>,
      <MaybeForbiddenAction key="bulk-label-assign-to-unlabelled" isForbidden={!canTriggerBulkLabelAssign}>
        <MenuItem
          onClick={() => startStorageAssign(importFolderId)}
          disabled={total === 0}>
          Assign labels to unlabelled documents
        </MenuItem>
      </MaybeForbiddenAction>,
      <MaybeForbiddenAction key={'rename-document'} isForbidden={!hasAccess('perm_act_renamedocument')}>
        <MenuItem
          onClick={() => {
            if (documentToRename) {
              dispatch(setDocumentToRename(documentToRename))
              dispatch(openModal('RENAME_DOCUMENT'))
            }
          }}
          disabled={documentIds.length !== 1 || !isSelectedDocumentOnPage(documentIds[0])}>
        Rename Document
        </MenuItem>
      </MaybeForbiddenAction>,
      <MenuItem
        key={'configure-table-view'}
        onClick={() => dispatch(openModal('COLUMN_CONFIGURATOR'))}>
        {'Configure table view'}
      </MenuItem>
    ]
  }, [documentIds, total, canTriggerBulkLabelAssign])

  const title = useMemo<ReactNode>(() => {
    return (
      <SwitchLabel
        disabled={onlyUnlabelledFolders}
        variant={'blue'}
        name="unlabelledOnly"
        label={'Unlabelled only'}
        value={unlabelled}
        onChange={() => {
          dispatch(toggleUnlabelled())
        }}
      />
    )
  }, [unlabelled, importFolderId, onlyUnlabelledFolders])

  const tableCellProps: Map<ColumnConfiguratorKey, TableSortLabelProps> = useMemo(() => {
    const config: [ColumnConfiguratorKey, ImportDocumentsSortColumns][] = [
      ['importListFilename', 'Name'],
      ['importListDocumentType', 'DocType'],
      ['importListProcessingStatus', 'ProcessingStatus'],
      ['importListImportDate', 'ImportDate'],
      ['importListImportedBy', 'ImportedBy'],
      ['importListProcessingPriority', 'OcrPriority']
    ]
    return new Map(config.map(([ccKey, sc]) => [ccKey, {
      active: sortColumn === sc,
      direction: sortColumn === sc ? sortOrder || 'asc' : 'asc',
      onClick: () => dispatch(sortBy(sc))
    }]))
  }, [sortOrder, sortColumn])

  const columns: Column[] = useMemo(() => {
    return importColumns
      .filter((ic) => importSettings[ic as keyof User['settings']['importSettings']])
      .map((ccKey) => {
        return {
          id: String(ccKey),
          label: columnConfiguratorLabels.get(ccKey) ?? '-',
          className: ccKey === 'importListFilename' ? 'fileName' : undefined,
          tableSortLabelProps: tableCellProps.get(ccKey),
          width: ccKey === 'importListDocumentType' ? '60px' : undefined
        }
      })
  }, [importSettings, tableCellProps])

  const tableRows: Row[] = useMemo(() => {
    const ccKeys = Object.entries(importSettings).filter((entry) => entry[1]).map(([ccKey]) => ccKey as ColumnConfiguratorKey)
    return documents.map((document) => {
      const cells: Cell[] = []
      if (ccKeys.includes('importListDocumentType')) {
        cells.push({
          id: `document-${document.documentId}-docType`,
          value: (
            <AbbreviationTypeIcon
              docTypeRecognitionResultByPages={document.docTypeRecognitionResultByPages}
              docTypeRecognitionResult={document.docTypeRecognitionResult}/>
          )
        })
      }
      if (ccKeys.includes('importListFilename')) {
        cells.push({
          id: `document-${document.documentId}-fileName`,
          value: <DocumentLink document={document} noDocType documentNameClass={'fileNameEllipsis'}/>
        })
      }
      if (ccKeys.includes('importListA2KLabel')) {
        cells.push({
          id: `document-${document.documentId}-labels`,
          value: <DocumentLabels
            withAssignLabelsOption
            labels={document.labels}
            onClick={() => startDocumentAssign(document)}/>
        })
      }
      if (ccKeys.includes('importListProcessingPriority')) {
        cells.push({
          id: `document-${document.documentId}-processingPriority`,
          value: <OcrPriorityIndicator row={document}/>
        })
      }
      if (ccKeys.includes('importListProcessingStatus')) {
        cells.push({
          id: `document-${document.documentId}-processingStatus`,
          value: <ProcessingStatusIndicator ocrStatus={document.ocrStatus}
            processingStatus={document.processingStatus}/>
        })
      }
      if (ccKeys.includes('importListImportedBy')) {
        cells.push({
          id: `document-${document.documentId}-importedBy`,
          value: document.importedBy
        })
      }
      if (ccKeys.includes('importListImportDate')) {
        cells.push({
          id: `document-${document.documentId}-importDate`,
          value: formattedDate(new Date(document.importDate))
        })
      }
      return {
        id: `document-${document.documentId}`,
        selected: documentIds.includes(document.documentId),
        document,
        cells
      }
    })
  }, [importSettings, documents, documentIds])

  return (
    <LisaPaper
      toolbar={{
        items,
        title
      }}>
      <LisaTable
        columns={columns}
        rows={tableRows}
        onSelectAll={() => dispatch(toggleAllDocumentIds(rows))}
        onRowToggle={(document) => {
          dispatch(toggleDocumentId(document))
        }}
        selectedRows={documentIds}
        allRows={documents.map(_ => _.documentId)}
        isFetching={isFetching}
        page={page}
        pages={pages}
        pageSize={pageSize}
        total={total}
        onPageChange={(value: number) => dispatch(setPage(value))}/>
    </LisaPaper>
  )
}
