import { FC, useState } from 'react'
import { DocumentViewerToolbar } from './DocumentViewerToolbar'
import { PageChangeEvent, RenderError, Viewer, Worker } from '@react-pdf-viewer/core'
import { searchPlugin } from '@react-pdf-viewer/search'
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import { CircularProgress, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { DocumentViewerProps } from './documentViewerTypes'
import { GridGrow } from 'common/Grid/GridGrow'
import { TableView } from 'components/TableView/TableView'
import clsx from 'clsx'
import { DocumentTypePageNavigation } from './DocumentTypePageNavigation'
import { supportedFilesDotsOff } from 'utils/documents'
import { useAuth } from 'react-oidc-context'
import { setDocumentCurrentPage } from 'redux/slices/documentSlice'
import { useDispatch } from 'react-redux'
import { DocumentDownloadProgress } from 'common/DocumentDownloadProgress/DocumentDownloadProgress'

const useStyles = makeStyles<Theme, {compact: boolean}>((theme) => ({
  root: {
    '& .rpv-core-doc-error': {
      background: 'white !important'
    }
  },
  loaderLabel: {
    fontSize: '14px',
    color: theme.palette.common.white,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  pdfToolBar: {
    alignItems: 'center',
    display: 'flex',
    padding: '0 10px',
    height: '56px',
    minWidth: '360px',
    justifyContent: 'space-between'
  },
  loaderBackdrop: {
    position: 'relative'
  },
  a123: {
    width: '100%',
    backgroundColor: '#464646',
    height: props => props.compact ? 'calc(100vh - 56px)' : 'calc(100vh - 56px)',
    marginTop: props => props.compact ? 0 : -42,
    overflow: 'auto',
    flexGrow: 1,
    background: '#464646'
  },
  hideSection: {
    display: 'none'
  },
  tableViewWrapper: {
    height: 'calc(100vh - 90px)'
  },
  messageBackdrop: {
    alignItems: 'center',
    border: 'none',
    display: 'flex',
    height: '100%',
    justifyContent: 'center'
  },
  message: {
    backgroundColor: theme.palette.black.main,
    borderRadius: '0.25rem',
    color: '#fff',
    padding: '24px',
    boxShadow: '0 0 10px 0 rgba(0,0,0,.3)',
    textAlign: 'center',
    margin: '36px'
  },
  loaderWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  thumbnails: {
    position: 'fixed',
    left: 0,
    top: 300,
    width: 220,
    display: 'flex',
    justifyContent: 'center',
    '& .rpv-thumbnail__list': {
      height: 'calc(100vh - 300px)',
      overflow: 'auto'
    }
  },
  pageNavigationContainer: {
    zIndex: 11,
    height: 'calc(100vh - 118px)', // sidebar is kinda absolute positioned
    overflow: 'auto',
    marginTop: 4,
    paddingLeft: 12,
    paddingRight: 2,
    // @todo This is thin dark scroll. Should have its own global class or something
    '&::-webkit-scrollbar': {
      height: '3px',
      width: '3px',
      background: 'transparent'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.6)',
      borderRadius: '1.5px'
    }
  },
  pageNavigationContainerCompact: {
    marginTop: 20,
    height: 'calc(100vh - 102px)'
  },
  progressBarContainer: {
    width: '30%'
  }
}))

type PdfViewerProps = DocumentViewerProps
export const PdfViewer: FC<PdfViewerProps> = ({
  document,
  loadingDocument,
  options = {}
}) => {
  const { tableView, datatableParsed } = options
  const isCompact = options.view === 'compact'
  const classes = useStyles({ compact: isCompact })
  const { url } = document
  const { user } = useAuth()
  const toolbarPluginInstance = toolbarPlugin()
  const searchPluginInstance = searchPlugin()
  const pageNavigationPluginInstance = pageNavigationPlugin()
  const { Toolbar } = toolbarPluginInstance
  const { jumpToPage, CurrentPageLabel } = pageNavigationPluginInstance
  const [documentLoaded, setDocumentLoaded] = useState(false)
  const dispatch = useDispatch()

  const renderError: RenderError = (error) => {
    let message
    const extension = document.extension
    switch (error.name) {
    case 'InvalidPDFException':
      message = supportedFilesDotsOff.includes(extension.toLowerCase())
        ? 'OCR in Progress, you can download the original file'
        : 'Document type is not supported. You can download the original document.'
      break
    default:
      message = 'Document type is not supported. You can download the original document.'
      break
    }

    return (
      <div className={classes.messageBackdrop}>
        <div className={classes.message}>{message}</div>
      </div>
    )
  }

  const handlePageChange = (e: PageChangeEvent) => {
    const { currentPage } = e
    const pageType = document.docTypeRecognitionResultByPages.filter((_, index) => index === currentPage)
    dispatch(setDocumentCurrentPage({ currentPage, pageType: pageType.toString() }))
  }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.11.338/build/pdf.worker.js">
      <DocumentViewerToolbar
        document={document}
        Toolbar={tableView ? undefined : Toolbar}
        Search={!documentLoaded ? undefined : searchPluginInstance}
        activeOptions={tableView ? ['tableView'] : []}
        compact={isCompact}
        datatableParsed={datatableParsed}/>

      <Grid container>
        {
          !tableView && document.docTypeRecognitionResultByPages.length > 0 &&
          <Grid
            item
            className={clsx(classes.pageNavigationContainer, { [classes.pageNavigationContainerCompact]: isCompact })}>
            <DocumentTypePageNavigation
              CurrentPageLabel={CurrentPageLabel}
              jumpToPage={jumpToPage}
              documentTypes={document.docTypeRecognitionResultByPages}/>
          </Grid>
        }
        <Grid item xs>
          <GridGrow
            container
            justifyContent={'center'}
            className={clsx(classes.loaderBackdrop, { [classes.tableViewWrapper]: tableView })}>
            {
              loadingDocument &&
              <div className={classes.loaderWrapper}>
                <CircularProgress />
                <Typography className={classes.loaderLabel}>
                Loading PDF document
                </Typography>
              </div>
            }
            <div id='main-pdf-canvas-container' className={clsx(classes.a123, { [classes.hideSection]: tableView })}>
              <Viewer
                fileUrl={`${process.env.REACT_APP_BASE_URL}${url}`}
                httpHeaders={{
                  Authorization: user!.token_type + ' ' + user!.access_token
                }}
                renderLoader={(percentage) => {
                  return (
                    <div className={classes.progressBarContainer}>
                      <DocumentDownloadProgress percentage={percentage} size={document.size} />
                    </div>
                  )
                }}
                onDocumentLoad={() => setDocumentLoaded(true)}
                renderError={renderError}
                plugins={[toolbarPluginInstance, searchPluginInstance, pageNavigationPluginInstance]}
                onPageChange={handlePageChange}
              />
            </div>
            <TableView className={clsx({ [classes.hideSection]: !tableView })}/>
          </GridGrow>
        </Grid>
      </Grid>
    </Worker>
  )
}
