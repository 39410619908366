import { Label, LabelId, LabelResponse, LoadingState } from 'types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createHierarchy, getLabel, replaceLabelInTree, searchTree, selectLabelInTree } from 'utils/labels'
import { initialLabelsState, labelsReducers, LabelsReducers, LabelsState, OpenedLabels } from './labelsSlice'
import { RootState } from 'redux/store'

export interface A2KLabelsState extends LabelsState {
  selected: Label | null
  addingSubLabel: LoadingState
  renamingLabel: LoadingState
  removingLabel: LoadingState
  emptyLabels: boolean
}

export interface A2KLabelsReducers extends LabelsReducers {
}

export const initialState: A2KLabelsState = {
  ...initialLabelsState,
  selected: null,
  addingSubLabel: LoadingState.Init,
  renamingLabel: LoadingState.Init,
  removingLabel: LoadingState.Init,
  emptyLabels: true
}

const a2kLabelsSlice = createSlice({
  name: 'a2kLabels',
  initialState,
  reducers: {
    search: labelsReducers.search<A2KLabelsState>(),
    toggle: labelsReducers.toggle<A2KLabelsState>(),
    labelAdded: (state, { payload }: PayloadAction<Label>) => {
      if (payload.level! > 0) {
        state.hierarchy = selectLabelInTree(state.hierarchy!, payload)
        state.selected = payload
        state.searchResults = searchTree(state.hierarchy, state.searchTerm)
      }
    },
    reset: (state) => {
      if (state.hierarchy !== null) {
        if (state.selected !== null) {
          state.hierarchy = selectLabelInTree(state.hierarchy!, state.selected)
        }
        state.openedLabels = {
          [state.hierarchy.labelId]: true
        }
      }
      state.selected = null
      state.searchResults = []
      state.searchTerm = ''
    },
    resetAll: () => initialState,
    toggleEmptyLabels: (state) => {
      state.emptyLabels = !state.emptyLabels
    },
    toggleLabelsInTree: (state, { payload }: PayloadAction<{ labelIds: LabelId[], labelsState?: boolean }>) => {
      const { labelsState, labelIds } = payload
      if (state.hierarchy) {
        state.openedLabels = {
          [state.hierarchy.labelId]: true, // Root is always open
          ...labelIds.reduce<OpenedLabels>((opened, labelId) => {
            opened[labelId] = labelsState ?? !opened[labelId]
            return opened
          }, {})
        }
      }
    },
    setHierarchy: (state, { payload }: PayloadAction<LabelResponse>) => {
      state.loadingHierarchy = LoadingState.Completed
      if (payload) { // @todo temporary condition. External users are getting null
        state.hierarchy = createHierarchy(payload)
        state.openedLabels[state.hierarchy.labelId] = true // Root is always open
        if (state.selected) {
          state.hierarchy = selectLabelInTree(state.hierarchy, state.selected)
          // We will need to update selected label to get fresh data
          const selected = getLabel(state.hierarchy, state.selected.labelId)
          if (selected) {
            state.selected = selected
          }
        }
      }
    },
    onSubLabelAdded: (state, { payload }: PayloadAction<LabelResponse>) => {
      state.hierarchy = replaceLabelInTree(state.hierarchy!, payload)
      if (state.selected) {
        state.hierarchy = selectLabelInTree(state.hierarchy!, state.selected)
      }
    },
    onLabelRenamed: (state, { payload }: PayloadAction<{ structure: LabelResponse, labelName: string }>) => {
      state.hierarchy = replaceLabelInTree(state.hierarchy!, payload.structure)
      if (state.selected) {
        state.hierarchy = selectLabelInTree(state.hierarchy!, state.selected)
        state.selected.name = payload.labelName
      }
    },
    onLabelRemoved: (state, { payload }: PayloadAction<LabelResponse>) => {
      state.removingLabel = LoadingState.Completed
      state.hierarchy = replaceLabelInTree(state.hierarchy!, payload)
      state.selected = null
    }
  }
})

export const {
  search,
  toggle,
  labelAdded,
  reset,
  resetAll,
  toggleEmptyLabels,
  setHierarchy,
  onSubLabelAdded,
  onLabelRenamed,
  onLabelRemoved,
  toggleLabelsInTree
} = a2kLabelsSlice.actions

export const a2kLabelsReducers: A2KLabelsReducers = {
  search,
  toggle,
  labelAdded
}

export const useA2KLabels = (state: RootState) => state.a2kLabels

export default a2kLabelsSlice.reducer
