import { FC, MouseEvent, useEffect, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import { Menu, MenuItem, Typography } from '@material-ui/core'
import { UserListRow } from 'types'
import { UserForm } from './UserForm'
import { ConfirmDialog } from 'common/Dialogs/ConfirmDialog'
import { useDispatch, useSelector } from 'react-redux'
import { openConfirmDialog, openModal, openToast, useApp } from 'redux/slices/appSlice'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { useDeactivateUserMutation, useGetUserQuery, useReactivateUserMutation, useResendInvitationMutation } from 'services/api/usersApi'

const useStyles = makeStyles(() => ({
  iconButton: {
    padding: '0 10px'
  },
  dialogTItle: {
    '& .MuiTypography-h6': {
      lineHeight: 1
    }
  },
  dialogMessage: {
    fontSize: '20px',
    fontWeight: 500,
    paddingTop: '48px'
  },
  dialogSubMessage: {
    fontSize: '16px',
    fontWeight: 500,
    paddingBottom: '48px'
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(76, 175, 80, 0.3)'
    }
  },
  message: {
    fontSize: '20px',
    padding: '48px 0'
  }
}))

export interface Props {
  _user: UserListRow
}

export const UserSettingsMenu: FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [editMode, setEditMode] = useState(false)
  const [editModeDialog, setEditModeDialog] = useState(false)
  const [reactivateUserDialog, setReactivateUserDialog] = useState(false)
  const [resendInvitationDialog, setResendInvitationDialog] = useState(false)
  const { activeModal } = useSelector(useApp)
  const dispatch = useDispatch()
  const classes = useStyles()
  const { _user } = props
  const { data: singleUser, isLoading } = useGetUserQuery(_user.userId, { skip: !editMode })
  const [deactivateUser] = useDeactivateUserMutation()
  const [reactivateUser] = useReactivateUserMutation()
  const [resendInvitation] = useResendInvitationMutation()

  useEffect(() => {
    if (editMode && !isLoading) {
      dispatch(openModal('EDIT_USER'))
    }
  }, [editMode, isLoading])

  useEffect(() => { // to handle cancel or X of modal
    if (activeModal.length === 0) {
      setEditMode(false)
      setEditModeDialog(false)
      setReactivateUserDialog(false)
      setResendInvitationDialog(false)
    }
  }, [activeModal.length])

  useEffect(() => {
    if (editModeDialog) {
      dispatch(openConfirmDialog('DEACTIVATE_USER'))
    }
  }, [editModeDialog])

  const handleUserSettings = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseUserSettings = () => {
    setAnchorEl(null)
  }

  const handleEditUser = () => {
    setEditMode(true)
    handleCloseUserSettings()
  }

  const handleDeleteUser = () => {
    setEditModeDialog(true)
    setAnchorEl(null)
  }

  const openReactivateUserDialog = () => {
    setReactivateUserDialog(true)
    dispatch(openConfirmDialog('REACTIVATE_USER'))
    handleCloseUserSettings()
  }

  const openResendInvitationDialog = () => {
    setResendInvitationDialog(true)
    dispatch(openConfirmDialog('RESEND_INVITATION'))
    handleCloseUserSettings()
  }
  const handleDeactivateUser = async () => {
    try {
      await deactivateUser({ userId: _user.userId }).unwrap()
      dispatch(openToast({
        severity: 'success',
        message: 'User successfully deactivated'
      }))
    } catch (e) {
      const { data: { message, statusCode } } = e as { data: { message: string, statusCode: number } }
      dispatch(openToast({
        severity: `${statusCode === 422 ? 'warning' : 'error'}`,
        message
      }))
    }
  }
  const handleReactivateUser = async () => {
    try {
      await reactivateUser({ userId: _user.userId }).unwrap()
      dispatch(openToast({
        severity: 'success',
        message: 'User successfully reactivated!'
      }))
      setReactivateUserDialog(false)
    } catch (e) {
      const { data: { message, statusCode } } = e as { data: { message: string, statusCode: number } }
      dispatch(openToast({
        severity: `${statusCode === 422 ? 'warning' : 'error'}`,
        message
      }))
    }
  }
  const handleResendInvitation = async () => {
    try {
      await resendInvitation({ userId: _user.userId }).unwrap()
      dispatch(openToast({
        severity: 'success',
        message: 'Invitation successfully resent!'
      }))
      setResendInvitationDialog(false)
    } catch (e) {
      const { data: { message, statusCode } } = e as { data: { message: string, statusCode: number } }
      dispatch(openToast({
        severity: `${statusCode === 422 ? 'warning' : 'error'}`,
        message
      }))
    }
  }

  return (
    <div className='menu-item'>
      <IconButton
        aria-controls='simple-menu'
        aria-haspopup='true'
        className={classes.iconButton}
        onClick={handleUserSettings}
      >
        <Icon className='fas fa-ellipsis-v' fontSize='small' />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        elevation={8}
        open={Boolean(anchorEl)}
        onClose={handleCloseUserSettings}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {_user.isActive && <MenuItem
          onClick={handleEditUser}
        >
          Edit User
        </MenuItem>
        }
        {_user.isActive && <MenuItem
          onClick={openResendInvitationDialog}
        >
          Resend invitation
        </MenuItem>
        }
        {_user.isActive && <MenuItem
          onClick={handleDeleteUser}
        >
          Deactivate User
        </MenuItem>
        }
        {!_user.isActive && <MenuItem
          onClick={openReactivateUserDialog}
        >
          Reactivate User
        </MenuItem>
        }
      </Menu>
      {editModeDialog && <ConfirmDialog
        title='Deactivate user'
        type={'DEACTIVATE_USER'}
        onConfirm={handleDeactivateUser}
        onCancel={() => {
          setEditModeDialog(false)
        }}>
        <div className={classes.message}>
          <Typography variant='inherit'>{`Are you sure you want to deactivate ${_user.firstName} ${_user.lastName}?`}</Typography>
        </div>
      </ConfirmDialog>
      }
      {resendInvitationDialog && <ConfirmDialog
        title='Resend invitation'
        type={'RESEND_INVITATION'}
        onConfirm={handleResendInvitation}
        onCancel={() => {
          setResendInvitationDialog(false)
        }}>
        <div className={classes.message}>
          <Typography variant='inherit'>{`Are you sure you want to resend invitation to ${_user.email}?`}</Typography>
          <Typography variant='body2'>{'This action will reset password for that user!'}</Typography>
        </div>
      </ConfirmDialog>
      }
      {reactivateUserDialog && <ConfirmDialog
        title='Reactivate user'
        type={'REACTIVATE_USER'}
        onConfirm={handleReactivateUser}
        onCancel={() => {
          setReactivateUserDialog(false)
        }}>
        <div className={classes.message}>
          <Typography variant='inherit'>{`Are you sure you want to reactivate user with email ${_user.email}?`}</Typography>
        </div>
      </ConfirmDialog>
      }
      {editMode &&
      <LisaModal
        modalType={'EDIT_USER'}
        title={`Edit user - [${_user.firstName} ${_user.lastName}]`}
        scroll={'body'}>
        <UserForm
          selectedUser={singleUser}
        />
      </LisaModal>
      }
    </div>
  )
}

export default UserSettingsMenu
