import { FC } from 'react'
import { ProgressType } from 'types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  base: {
    fontSize: 12,
    borderRadius: 50,
    width: 26,
    height: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white
  },
  high: {
    backgroundColor: '#02c12b',
    transform: 'rotate(-45deg)'
  },
  medium: {
    backgroundColor: '#ff9e20'
  },
  low: {
    backgroundColor: '#e20000',
    transform: 'rotate(45deg)'
  }
}))

type ProgressIndicatorProps = {
  level?: ProgressType
}
export const ProgressIndicator: FC<ProgressIndicatorProps> = ({ level }) => {
  const classes = useStyles()
  return (
    <div
      className={clsx(classes.base, {
        [classes.low]: level === ProgressType.Low,
        [classes.medium]: level === ProgressType.Medium,
        [classes.high]: level === ProgressType.High
      })}>
      {level && <i className="fas fa-arrow-right"/>}
    </div>
  )
}
