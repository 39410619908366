import { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { RoleName } from 'utils/userRoleSecurity'
import { SignInLoader } from 'common/Loaders/LisaLoader'
import { useLisaAuth } from 'hooks/useLisaAuth'

export const SignIn: FC = () => {
  const history = useHistory()
  const { hasRole } = useLisaAuth()
  useEffect(() => {
    history.push(hasRole(RoleName.Operator) ? '/dashboard/my-transitions' : '/dashboard')
  }, [])
  return <SignInLoader/>
}
