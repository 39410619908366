import { FC, useEffect } from 'react'
import { SignOutLoader } from 'common/Loaders/LisaLoader'
import { useAuth } from 'react-oidc-context'

export const SignOut: FC = () => {
  const { signinRedirect } = useAuth()
  useEffect(() => {
    signinRedirect().then()
  }, [])
  return <SignOutLoader/>
}
