import { FC } from 'react'
import { TransitionId, TransitionItem as TransitionItemT } from 'types'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { QueryCountIndicator } from 'common/Indicators/QueryCountIndicator'
import { ProgressBar } from 'common/ProgressBar/ProgressBar'
import { LisaBox } from 'common/LisaBox/LisaBox'
import { TransitionName } from 'common/Names/TransitionName'
import { Users } from 'common/Users/Users'

const useStyles = makeStyles(() => ({
  header: {
    height: '24px',
    '& .project-name': {
      fontSize: '14px',
      fontWeight: 600,
      marginRight: 4
    }
  },
  projectName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  transitionIcon: {
    marginRight: '6px'
  },
  progressBar: {
    '& .MuiBox-root': {
      display: 'block',
      padding: '2px 0'
    }
  },
  usersContainer: {
    minHeight: 48
  }
}))

type TransitionItemProps = {
  transition: TransitionItemT
  onClick: (transitionId: TransitionId) => void
}
export const TransitionItem: FC<TransitionItemProps> = ({ transition, onClick }) => {
  const classes = useStyles()
  return (
    <LisaBox
      container
      direction={'column'}
      onClick={() => onClick(transition.transitionId)}>
      <Grid container item justifyContent={'space-between'} alignItems={'center'} className={classes.header}>
        <Grid item xs className={classes.projectName}>
          <Typography className={'project-name'}>{transition.projectName.toUpperCase()}</Typography>
        </Grid>
        <Grid item>
          <QueryCountIndicator queryCount={transition.queryCount} closedQueryCount={transition.closedQueryCount}/>
        </Grid>
      </Grid>
      <Grid container item>
        <TransitionName transitionName={transition.transitionName} size={'lg'}/>
      </Grid>
      <Grid container item className={classes.progressBar}>
        <ProgressBar
          value={transition.estimatedCount}
          label={_ => `Estimated ${Math.round(_)}%`}/>
      </Grid>
      <Grid item className={classes.usersContainer}>
        <Users users={transition.users}/>
      </Grid>
    </LisaBox>
  )
}
