import { FC } from 'react'
import { QueryStatus } from 'types'
import Dropdown, { DropdownProps } from 'common/LisaControls/Dropdown'

export type SelectOptions = {
  value: QueryStatus
  text: string
  textSuffix?: string
}

export const QueryStatusItems: SelectOptions[] = [
  {
    value: QueryStatus.New,
    text: 'New'
  },
  {
    value: QueryStatus.Open,
    text: 'Open'
  },
  {
    value: QueryStatus.Pending,
    text: 'Pending'
  },
  {
    value: QueryStatus.Delayed,
    text: 'Delayed'
  },
  {
    value: QueryStatus.WorkPack,
    text: 'WorkPack'
  },
  {
    value: QueryStatus.Closed,
    text: 'Closed'
  },
  {
    value: QueryStatus.Sideletter,
    text: 'Sideletter'
  }
]
export const QueryStatusSelect: FC<DropdownProps> = (props) => {
  return (
    <Dropdown
      items={QueryStatusItems}
      multiple
      {...props}
    />
  )
}
