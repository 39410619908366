import { createContext, FC, useCallback, useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { DocumentId, Label, TransitionId } from 'types'
import { LabelsProvider, LabelsType } from './LabelsContext'
import { isErrorCode } from 'utils/http'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useGetDocumentQuery } from 'services/api/documentApi'
import { useAddLabelToDocumentMutation, useRemoveLabelFromDocumentMutation } from 'services/api/labellingApi'
import { openToast } from 'redux/slices/appSlice'
import { useTransitionId } from 'context/TransitionContext'

export interface Document {
  documentId: DocumentId
  transitionId: TransitionId
  addDocumentLabel(label: Label): void
  removeDocumentLabel(label: Label): void
}

export const DEFAULT_DOCUMENT: Document = {
  documentId: '',
  transitionId: '',
  addDocumentLabel: () => {},
  removeDocumentLabel: () => {}
}

export const DocumentContext = createContext<Document>(DEFAULT_DOCUMENT)

type DocumentProviderProps = {
  transitionId?: TransitionId
}
export const DocumentProvider: FC<DocumentProviderProps> = ({
  children,
  transitionId: transitionIdFromProps
}) => {
  const { id } = useParams<{id: string}>()
  const dispatch = useDispatch()
  const history = useHistory()
  const tId = useTransitionId()
  const transitionId = transitionIdFromProps ?? tId
  const { data: document, isLoading } = useGetDocumentQuery({ id, transitionId }, {
    skip: !transitionId
  })
  const { userId } = useLisaAuth()
  const [addLabelToDocument] = useAddLabelToDocumentMutation()
  const [removeLabelFromDocument] = useRemoveLabelFromDocumentMutation()

  useEffect(() => {
    if (!isLoading) {
      if (isErrorCode(document?.message ?? 200)) {
        history.replace(`/error/code/${document!.message}`)
      }
    }
  }, [isLoading, document?.message])

  const addDocumentLabel = useCallback(async (label: Label) => {
    const { success, message } = await addLabelToDocument({
      labelId: label.labelId,
      documentId: id,
      transitionId,
      userId: userId!
    }).unwrap()
    if (!success) {
      dispatch(openToast({ severity: 'error', message: message ?? '' }))
    }
  }, [id, transitionId, userId])

  const removeDocumentLabel = useCallback(async (label: Label) => {
    const { success, message } = await removeLabelFromDocument({
      labelId: label.labelId,
      documentId: id,
      transitionId,
      userId: userId!
    }).unwrap()
    if (!success) {
      dispatch(openToast({ severity: 'error', message: message ?? '' }))
    }
  }, [id, transitionId, userId])

  const value = {
    documentId: id,
    transitionId: transitionId,
    addDocumentLabel,
    removeDocumentLabel
  }

  return (
    <DocumentContext.Provider value={value}>
      <LabelsProvider
        labelsType={LabelsType.Document}
        onLabelAdd={addDocumentLabel}
        onLabelRemove={removeDocumentLabel}>
        {children}
      </LabelsProvider>
    </DocumentContext.Provider>
  )
}

export const useDocumentContext = () => useContext(DocumentContext)
export const useDocumentId = () => useDocumentContext().documentId
