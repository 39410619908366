import { ChangeEvent, FC } from 'react'
import { LisaForm, useLisaForm } from 'common/Form/LisaForm'
import { Label } from 'types'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import Input from 'common/LisaControls/Input'
import { LabelPicker } from 'common/LabelPicker/LabelPicker'
import { LabelsProvider, LabelsType } from 'context/LabelsContext'
import { useDispatch, useSelector } from 'react-redux'
import { useImportDocuments } from 'redux/slices/importDocumentsSlice'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { hasAllowedCharacters, INVALID_LABEL_NAME } from 'utils/validators'
import { useCloneToA2KMutation } from 'services/api/labellingApi'

const useStyles = makeStyles(() => ({
  form: {
    marginBottom: 20
  },
  header: {
    whiteSpace: 'normal',
    fontSize: 18,
    fontWeight: 600,
    marginTop: 30,
    marginBottom: 10
  }
}))

interface FormValues {
  suffix: string
  label: Label | null
}

const initialValues: FormValues = {
  suffix: '',
  label: null
}

export const CloneToA2KForm: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { values, setValue } = useLisaForm<FormValues, {}>(initialValues)
  const { importFolderId } = useSelector(useImportDocuments)
  const close = () => dispatch(closeModal())
  const isSuffixNameValid = hasAllowedCharacters(values.suffix.trim())
  const suffixError = isSuffixNameValid ? '' : INVALID_LABEL_NAME
  const [cloneToA2K, { isLoading }] = useCloneToA2KMutation()

  return (
    <LisaForm>
      <Grid className={classes.form} container direction={'column'} spacing={2}>
        <Grid item>
          <Typography className={classes.header}>
            Please note: Selected folder, it&apos;s subfolders and containing documents will be cloned to A2K structure.
            Folder names must be unique. You can add suffix to cloned labels to avoid duplicate names.
          </Typography>
        </Grid>
        <Grid item>
          <Input
            label={''}
            name={'suffix'}
            placeholder={'Add suffix (optional)'}
            fullWidth
            value={values.suffix}
            errMsg={suffixError}
            onChange={(e: ChangeEvent<{value: string}>) => setValue('suffix', e.target.value)}/>
        </Grid>
        <Grid item>
          <LabelsProvider labelsType={LabelsType.Transition}>
            <LabelPicker
              label={'Select parent label to clone to'}
              onChange={(label) => setValue('label', label)}/>
          </LabelsProvider>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <Button
              onClick={async () => {
                if (importFolderId && values.label?.labelId) {
                  const { message, isError } = await cloneToA2K({
                    importStructureId: importFolderId,
                    suffix: values.suffix.trim(),
                    labelId: values.label!.labelId
                  }).unwrap()
                  if (!isError) {
                    dispatch(openToast({ severity: 'info', message: 'Selected structure successfully cloned to A2K' }))
                    close()
                  } else {
                    dispatch(openToast({ severity: 'error', message: message ?? 'Cloning aborted!' }))
                  }
                }
              }}
              variant="contained"
              color="primary"
              size="small"
              disabled={values.label === null || !isSuffixNameValid || isLoading}>
              Ok
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={close}
              variant="contained"
              color="primary"
              size="small">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </LisaForm>
  )
}
