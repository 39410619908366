import { A2KCondition, A2KLogicalOperator, PaginatedResults } from 'types'
import { A2KRuleDocument, A2KRuleNode, A2KRulePreviewResponse, A2KRulePreviewType } from 'services/api/labelRuleApi'

export const rulesToUserFriendlyQuery = (rules: A2KCondition[], operators: A2KLogicalOperator[]): string => {
  if (rules.length === 0 || rules.length !== operators.length + 1) {
    return ''
  }
  const [rule, ...restRules] = rules
  const [operator, ...restOperators] = operators
  const ufq = `${rule.type} ${rule.operator} '${rule.value}'`
  if (restRules.length === 0) {
    return ufq
  }
  return `${ufq} ${operator} ${rulesToUserFriendlyQuery(restRules, restOperators)}`
}

export const normalizeA2KRuleTree = (a2kRuleTree: A2KRuleNode): {conditions: A2KCondition[], operators: A2KLogicalOperator[]} => {
  if ('variable' in a2kRuleTree) {
    const rule: A2KCondition = {
      type: a2kRuleTree.variable.variableName,
      value: a2kRuleTree.variableValue.tokenValue,
      operator: a2kRuleTree.operator.tokenValue
    }
    return {
      conditions: [rule],
      operators: []
    }
  } else {
    const left = normalizeA2KRuleTree(a2kRuleTree.left)
    const right = normalizeA2KRuleTree(a2kRuleTree.right)
    const operator = a2kRuleTree.logicalOperator.tokenValue
    return {
      conditions: [left.conditions, right.conditions].flatMap(_ => _),
      operators: [operator, left.operators, right.operators].flatMap(_ => _)
    }
  }
}

export const extractDocumentsForPreviewType = (viewType: A2KRulePreviewType, data?: A2KRulePreviewResponse): PaginatedResults<A2KRuleDocument> => {
  if (data === undefined) {
    return {
      page: 1,
      pages: 0,
      total: 0,
      pageSize: 200,
      rows: []
    }
  }
  switch (viewType) {
  case A2KRulePreviewType.List:
    return data.documentsMatchedNewRules
  case A2KRulePreviewType.Added:
    return data.documentsAddedFromNewRule
  case A2KRulePreviewType.Excluded:
    return data.documentsExcludedFromNewRule
  case A2KRulePreviewType.Skipped:
    return data.documentsSkipList
  }
}
