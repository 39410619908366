import { ChangeEvent, FC, FormEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Avatar, Button, Grid, Icon, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { LisaForm, useLisaForm } from 'common/Form/LisaForm'
import { Nullable } from 'types'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { WithLoader } from 'common/WithLoader/WithLoader'
import { UserAvatar } from 'common/UserAvatar/UserAvatar'
import Resizer from 'react-image-file-resizer'
import { useUpdateProfileMutation, useUploadProfilePictureMutation } from 'services/api/usersApi'
import { useLisaAuth } from 'hooks/useLisaAuth'
import SwitchLabel from 'common/LisaControls/SwitchLabel'
import Input from 'common/LisaControls/Input'
import { hasCompleteProfile } from 'utils/user'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiDivider-root': {
      width: '100%',
      margin: '10px 0px 0px 1px'
    },
    '& .MuiSvgIcon-root': {
      width: '18px',
      height: '18px',
      '&.MuiAvatar-fallback': {
        width: '56px !important',
        height: '56px !important'
      }
    }
  },
  groupContainer: {
    marginTop: '15px'
  },
  actionButtons: {
    padding: '32px 0px',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  },
  changePasswordContainer: {
    marginTop: '30px'
  },
  noteDescription: {
    marginTop: '20px'
  },
  passwordSwitcher: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiFormControlLabel-root': {
      marginRight: '0px'
    }
  },
  labelList: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '4px',
    columnGap: '4px',
    padding: '8px 0px 16px 0px'
  },
  userImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '150px',
    '& .image-placehloder': {
      position: 'relative',
      '& .MuiButtonBase-root.MuiIconButton-root': {
        cursor: 'default'
      }
    },
    '& .large': {
      zIndex: '0 !important',
      width: theme.spacing(14),
      height: theme.spacing(14),
      backgroundColor: theme.palette.grey2.dark,
      fontSize: 22
    },
    '& .MuiAvatar-root.MuiAvatar-colorDefault': {
      color: theme.palette.common.white
    },
    '& .MuiIconButton-root': {
      padding: '0px 0px 0px 0px'
    }
  },
  bodyTxt: {
    fontWeight: 900
  },
  sectionTitle: {
    fontSize: '14px',
    fontWeight: 700,
    letterSpacing: '.8px',
    textTransform: 'uppercase'
  },
  editUserPicture: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    '& .editProfile.fas.fa-pen': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '42px',
      height: '42px',
      background: theme.palette.blue.main,
      borderRadius: '50%',
      fontSize: '16px',
      color: theme.palette.lime.main,
      '&:hover': {
        background: theme.palette.lime.main,
        color: theme.palette.blue.main,
        cursor: 'pointer'
      }
    }
  }
}))

interface FormValues {
    firstName: string
    userId: string
    email: string
    jobTitle: string
    lastName: string
    newPassword: string
    password: string
    repeatNewPassword: string
    company: string
}

type FormErrors = {
  firstName?: string
  jobTitle?: string
  lastName?: string
  newPassword?: string
  password?: string,
  repeatNewPassword?: string
}

enum FormErrorsText {
  REQUIRED_FIELD = 'This field is required.',
  PASSWORD_PATTERN = 'Password pattern is not correct',
  PASSWORD_MATCH = 'Password must match!'
}

export const EditProfileModal: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [localVisibility, setLocalVisibility] = useState<boolean>(false)
  const { userId, user: lisaUser, refetchUser, isLoading } = useLisaAuth()
  const [localProfilePicture, setLocalProfilePicture] = useState<string>('')
  const [localFormData, setLocalFormData] = useState<Nullable<FormData>>(null)
  const [updateProfile, { data: updateProfileResponse }] = useUpdateProfileMutation()
  const [updateProfilePicture] = useUploadProfilePictureMutation()
  const firstLogin = !hasCompleteProfile(lisaUser)

  const user = {
    url: lisaUser.profilePictureURL,
    firstName: lisaUser.firstName,
    lastName: lisaUser?.lastName,
    userId: userId!
  }

  useEffect(() => {
    refetchUser()
  }, [])

  const close = () => dispatch(closeModal(['EDIT_PROFILE']))
  // @todo needs to check validations in this form
  const validate = useCallback((values: FormValues) => {
    const validationFields: (keyof FormErrors)[] = ['firstName', 'lastName', 'jobTitle', 'password', 'newPassword', 'repeatNewPassword']
    const validationPasswordFields: (keyof FormErrors)[] = ['password', 'newPassword', 'repeatNewPassword']
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@:#$!.%*?&])[A-Za-z\d@:#$!.%*?&]{8,}$/

    return validationFields.reduce(
      ({ errors: e, isValid }: {errors: FormErrors, isValid: boolean}, field: keyof FormErrors) => {
        if (!values[field]) {
          if (!localVisibility) {
            if (!validationPasswordFields.includes(field)) {
              e[field] = FormErrorsText.REQUIRED_FIELD
              isValid = false
            }
          } else {
            e[field] = FormErrorsText.REQUIRED_FIELD
            isValid = false
          }
        } else {
          switch (values[field]) {
          case values.newPassword: {
            e.newPassword = passwordRegex.test(values[field]) ? '' : FormErrorsText.PASSWORD_PATTERN
            break
          }
          case values.repeatNewPassword: {
            e.repeatNewPassword = values.repeatNewPassword !== values.newPassword ? FormErrorsText.PASSWORD_MATCH : ''
            break
          }
          default:
            break
          }
          // isValid = !e.newPassword && !e.repeatNewPassword
        }
        return { errors: e, isValid }
      }, { errors: {}, isValid: true })
  }, [localVisibility])

  const {
    values,
    setValues: setUserValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
    checkForErrors
  } = useLisaForm<FormValues, FormErrors>(
    {
      userId,
      firstName: lisaUser.firstName,
      lastName: lisaUser.lastName,
      email: lisaUser.email,
      company: lisaUser.company,
      jobTitle: lisaUser.jobTitle,
      password: '',
      newPassword: '',
      repeatNewPassword: ''
    },
    validate,
    false
  )
  useEffect(() => {
    setUserValues(
      {
        ...values,
        password: '',
        newPassword: '',
        repeatNewPassword: ''
      })
    if (!localVisibility) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      shrinkErrors()
    }
  }, [localVisibility])

  useEffect(() => {
    if (updateProfileResponse?.isError && updateProfileResponse?.message) {
      dispatch(openToast({ severity: 'error', message: updateProfileResponse?.message }))
    } else if (updateProfileResponse?.message === 'Success') {
      resetForm()
      close()
      // fetchUserInfo()
      dispatch(openToast({
        severity: 'success',
        message: `You successfully updated user - [${values.firstName} ${values.lastName}].`
      }))
    }
  }, [updateProfileResponse?.message, updateProfileResponse?.isError])

  const handleSubmit = async (e: MouseEvent | FormEvent) => {
    e.preventDefault()
    const isValid = checkForErrors()
    if (isValid) {
      let profilePicture = ''
      if (localFormData !== null) {
        profilePicture = await updateProfilePicture(localFormData).unwrap()
      }
      await updateProfile({ ...values, url: profilePicture }).unwrap()
    }
  }
  const handleClose = (e: MouseEvent) => {
    e.preventDefault()
    setLocalProfilePicture('')
    dispatch(closeModal(['EDIT_PROFILE']))
  }

  const handleReplaceImageInputClick = () => {
    hiddenFileInput.current!.click()
  }

  const handleReplacePicture = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const formdata = new FormData()
    if (e.target.files![0]) {
      try {
        Resizer.imageFileResizer(
        e.target.files![0],
        110,
        110,
        'PNG',
        100,
        0,
        (uri) => {
          if (uri instanceof Blob) {
            setLocalProfilePicture(URL.createObjectURL(uri))
            formdata.append('file', uri)
            setLocalFormData(formdata)
          }
        },
        'blob',
        110, // @todo We use 40x40 everywhere. 110x100 only when editing profile
        110
        )
      } catch (err) {
        dispatch(openToast({ severity: 'error', message: 'Cannot upload picture, please try again.' }))
      }
    }
  }
  const shrinkErrors = () => {
    setErrors(current => {
      const copy = { ...current }
      delete copy.password
      delete copy.newPassword
      delete copy.repeatNewPassword
      return copy
    })
  }
  return (
    <div className={classes.root}>
      <WithLoader loading={isLoading} hasResults={true} loadingText={'Loading profile...'}>
        <LisaForm onSubmit={handleSubmit}>
          <Grid container direction='column' spacing={1}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className={classes.userImage}>
                  <input
                    accept='image/*'
                    ref={hiddenFileInput}
                    style={{ display: 'none' }}
                    onChange={handleReplacePicture}
                    type='file'
                  />
                  <div className={'image-placehloder'}>
                    <label htmlFor='contained-button-file'>
                      <IconButton>
                        {localProfilePicture
                          ? <Avatar style={{ height: '110px', width: '110px', zIndex: 0 }} src={localProfilePicture}/>
                          : <UserAvatar
                            className='large'
                            user={user}
                          />
                        }
                      </IconButton>
                      <Tooltip title='Change Profile Picture'>
                        <IconButton
                          onClick={handleReplaceImageInputClick}
                          className={classes.editUserPicture}
                        >
                          <Icon className='editProfile fas fa-pen' />
                        </IconButton>
                      </Tooltip>
                    </label>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.groupContainer}>
                <Typography className={classes.sectionTitle}>
                PROFILE DATA
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Input
                  label='First name'
                  name='firstName'
                  required={true}
                  value={values.firstName}
                  onChange={handleInputChange}
                  errMsg={errors?.firstName}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  label='Last name'
                  name='lastName'
                  value={values.lastName}
                  required={true}
                  onChange={handleInputChange}
                  errMsg={errors?.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label='Email Address'
                  name='email'
                  value={values.email}
                  onChange={handleInputChange}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  label='Company'
                  name='company'
                  disabled={true}
                  value={values.company}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  label='Job Title'
                  name='jobTitle'
                  value={values.jobTitle}
                  required={true}
                  onChange={handleInputChange}
                  errMsg={errors?.jobTitle}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems='center' className={classes.changePasswordContainer}>
              <Grid item xs={6}>
                <Typography className={classes.sectionTitle}>
                CHANGE PASSWORD
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.passwordSwitcher}>
                <SwitchLabel
                  label=''
                  name='visibility'
                  value={localVisibility}
                  onChange={(e, checked) => setLocalVisibility(checked)}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label='Current password'
                  name='password'
                  type={'password'}
                  allowShowPassword
                  value={values.password}
                  onChange={handleInputChange}
                  disabled={!localVisibility}
                  errMsg={errors?.password}
                />
                {
                  (localVisibility &&
                  updateProfileResponse?.isError) &&
                    <Typography variant='body2' color='error'>{updateProfileResponse.message}</Typography>
                }
              </Grid>
              <Grid item xs={12}>
                <Input
                  label='New password'
                  name='newPassword'
                  type={'password'}
                  allowShowPassword
                  value={values.newPassword}
                  disabled={!localVisibility}
                  onChange={handleInputChange}
                  errMsg={errors?.newPassword}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label='Repeat new password'
                  name='repeatNewPassword'
                  type={'password'}
                  value={values.repeatNewPassword}
                  disabled={!localVisibility}
                  onChange={handleInputChange}
                  errMsg={errors?.repeatNewPassword}
                />
              </Grid>
              <Grid item xs={12} className={classes.noteDescription} >
                <Typography variant='body2'> <span className={classes.bodyTxt}>Note: </span>password must have 8 characters minimum, 1 uppercase letter, 1 number and 1 special character ( !, #, @, $, %, &, *, ? ) minimum (e.g. H3lloW@rld)</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className={classes.actionButtons}>
                  <Button
                    type='submit'
                    onClick={handleSubmit}
                    variant='contained'
                    color='primary'
                    size='small'
                  >
                  Save
                  </Button>
                  {
                    !firstLogin &&
                    <Button
                      onClick={handleClose}
                      variant='contained'
                      color='primary'
                      size='small'
                    >
                    Cancel
                    </Button>
                  }
                </div>
              </Grid>
            </Grid>
          </Grid>
        </LisaForm>
      </WithLoader>
    </div>
  )
}
