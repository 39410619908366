import { useState, useRef, KeyboardEvent } from 'react'
import { GridGrow } from 'common/Grid/GridGrow'
import { makeStyles, Grid, Tooltip } from '@material-ui/core'
import InputSearch from 'common/LisaControls/InputSearch'
import { DocumentsTable } from './DocumentsTable'
import { updateSearchParams } from 'redux/slices/deletedDocumentsSlice'
import useOutsideClick from 'hooks/useOutsideClick'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.black.main
  },
  searchResults: {
    marginTop: '12px'
  },
  resetFilters: {
    marginTop: '18px',
    minWidth: 'auto !important',
    lineHeight: '12px !important',
    padding: '13px 10px !important',
    height: '42px !important',
    letterSpacing: '.15px !important',
    background: 'transparent !important',
    color: `${theme.palette.blue.main} !important`,
    '&:hover': {
      background: `${theme.palette.lime.main} !important`,
      color: theme.palette.black.main
    }
  },
  searchToolbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '18px',
    '& .searchInput': {
      marginRight: 8
    },
    '& .center-align': {
      justifyContent: 'center'
    }
  },
  filterWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '& .MuiOutlinedInput-root': {
      marginRight: '16px'
    },
    '& .MuiAutocomplete-root': {
      paddingRight: '16px'
    }
  },
  searchFieldContainer: {
    height: 58
  },
  tooltip: {
    color: theme.palette.black.main,
    background: theme.palette.statusColors.orange,
    padding: '2px 8px',
    borderRadius: '4px'
  },
  arrow: {
    color: theme.palette.statusColors.orange
  }
}))

export const RecycleBin = () => {
  const [searchText, setSearchText] = useState('')
  const dispatch = useDispatch()
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)
  const ref = useRef(null)
  const classes = useStyles()

  useOutsideClick(ref, () => {
    setTooltipIsOpen(false)
  })

  const handleClick = () => {
    dispatch(updateSearchParams({ searchText }))
  }

  const enterPressed = (event: KeyboardEvent) => {
    event.preventDefault()
    if (searchText.length === 0 || searchText.length > 2) {
      setTooltipIsOpen(false)
      if (event.key === 'Enter') {
        dispatch(updateSearchParams({ searchText }))
      }
    } else {
      if (event.key === 'Enter') {
        setTooltipIsOpen(true)
      }
    }
  }
  return (
    <GridGrow container direction={'column'} className={classes.root}>
      <Grid item container spacing={2} className={classes.searchToolbar}>
        <Grid item xs={12} md={8} lg={5} alignItems={'center'} className={classes.searchFieldContainer}>
          <Tooltip
            open={tooltipIsOpen}
            title="Min. 3 characters needed to perform search"
            placement="left"
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            classes={{
              arrow: classes.arrow,
              tooltip: classes.tooltip
            }}>
            <div ref={ref}>
              <InputSearch
                name="searchText"
                label="Find deleted document..."
                onChange={(e) => setSearchText(e.target.value)}
                onClick={handleClick}
                value={searchText}
                onKeyUp={enterPressed}/>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
      <GridGrow container item spacing={2} direction={'column'} className={classes.searchResults}>
        <DocumentsTable />
      </GridGrow>
    </GridGrow>
  )
}
