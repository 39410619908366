import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { makeStyles, Tab, Tabs } from '@material-ui/core'
import { FC } from 'react'
import { useTransitionId } from 'context/TransitionContext'
import { Link } from 'react-router-dom'
import { useLisaAuth } from 'hooks/useLisaAuth'

const useStyles = makeStyles((theme) => ({
  tabs: {
    backgroundColor: theme.palette.common.white,
    marginBottom: 16,
    color: theme.palette.blue.main,
    '& .MuiTab-textColorInherit': {
      opacity: 1
    }
  },
  tabsIndicator: {
    backgroundColor: `${theme.palette.blue.main} !important`,
    height: '5px !important'
  },
  tabPanel: {
    flexGrow: 1,
    display: 'flex',
    padding: 0
  },
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  gridForm: {
    maxWidth: '600px',
    alignSelf: 'center'
  },
  userName: {
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: 600
  },
  actionButtons: {
    paddingTop: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  },
  comboBox: {
    display: 'flex',
    width: '100%'
  },
  boxPadding: {
    padding: '0px'
  }
}))

const SettingsPageWrapper: FC = ({ children }) => {
  const classes = useStyles()
  const transitionId = useTransitionId()
  const { hasAccess } = useLisaAuth()

  const transitionFormUrl = `/structures/settings/transition/${transitionId}`
  const transitionTeamUrl = `/structures/settings/team/${transitionId}`
  const labellingRulesUrl = `/structures/settings/rules/${transitionId}`

  return (
    <ContentWrapper newLayout>
      <Tabs
        classes={{
          root: classes.tabs,
          indicator: classes.tabsIndicator
        }}
        centered
        value={window.location.pathname}
        aria-label='Settings view'>
        <Tab
          value={transitionFormUrl}
          component={Link}
          label="Asset"
          to={transitionFormUrl}/>
        <Tab
          value={transitionTeamUrl}
          component={Link}
          label="Team"
          to={transitionTeamUrl}/>
        {
          hasAccess('perm_act_transitionsettings_a2krule') &&
            <Tab
              value={labellingRulesUrl}
              component={Link}
              label="Labelling Rules"
              to={labellingRulesUrl}/>
        }
      </Tabs>
      {children}
    </ContentWrapper>
  )
}

export default SettingsPageWrapper
