import { FC, ReactNode } from 'react'
import { CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core'

const useStyles = makeStyles<Theme, {minusHeight: number, inline: boolean, size?: number}>((theme) => ({
  root: {
    position: 'relative',
    height: ({ minusHeight }) => `calc(100vh - ${minusHeight}px)`,
    background: 'white'
  },
  rootNormal: {
    // background: 'white',
    height: ({ inline, size = 125 }) => inline ? 'none' : size
  },
  normal: {
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: ({ inline }) => inline ? 'row' : 'column',
    '& .MuiCircularProgress-root': {
      width: '80px !important',
      height: '80px !important'
    },
    '& .MuiCircularProgress-colorPrimary': {
      color: '#001E50 !important'
    }
  },
  circular: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiCircularProgress-root': {
      width: ({ size = 125 }) => `${size}px !important`,
      height: ({ size = 125 }) => `${size}px !important`
    },
    '& .MuiCircularProgress-colorPrimary': {
      color: '#001E50 !important'
    }
  },
  inline: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    '& .MuiCircularProgress-root': {
      width: '80px !important',
      height: '80px !important'
    },
    '& .MuiCircularProgress-colorPrimary': {
      color: '#001E50 !important'
    }
  },
  loaderLabel: {
    display: 'flex',
    fontSize: '14px',
    color: theme.palette.black.light,
    fontWeight: 600,
    textTransform: 'uppercase'
  }
}))

type LisaLoaderProps = {
  text: ReactNode
  minusHeight?: number
  inline?: boolean
  normalLoader?: boolean
  size?: number
}
const LisaLoader: FC<LisaLoaderProps> = ({ text, minusHeight = 0, inline = false, normalLoader = false, size }) => {
  const classes = useStyles({ minusHeight, inline, size })
  let className
  if (normalLoader) {
    className = classes.normal
  } else {
    className = inline ? classes.inline : classes.circular
  }
  return (
    <div className={normalLoader ? classes.rootNormal : classes.root} data-testid={'lisaLoader'}>
      <div className={className}>
        <CircularProgress size={size}/>
        <Typography className={classes.loaderLabel}>
          {text}
        </Typography>
      </div>
    </div>
  )
}

export default LisaLoader

export const SignInLoader: FC = () => {
  return <LisaLoader text={'Signing in...'} size={40}/>
}
export const SignOutLoader: FC = () => {
  return <LisaLoader text={'Signing out...'} size={40}/>
}
