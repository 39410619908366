import { FC, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import Users from 'pages/Administration/Users'
import ProjectsAndTransitions from 'pages/Administration/ProjectsAndTransitions'
import { ErrorPage } from 'pages/ErrorPage/ErrorPage'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { Tenants } from 'pages/Administration/Tenants'
import Notifications from 'pages/Administration/Notifications'

export const AdministrationRoutes: FC = () => {
  const { hasAccess } = useLisaAuth()
  const history = useHistory()
  const [navReady, setNavReady] = useState(false)
  const hasUserAccess = hasAccess('perm_administration_users')
  const hasAssetsAccess = hasAccess('perm_administration_assets')
  const hasSubscriberAccess = hasAccess('perm_administration_subscriber')
  const hasNotificationAccess = hasAccess('perm_administration_assets')

  useEffect(() => {
    if (history.location.pathname === '/administration' && !hasUserAccess) {
      if (hasAssetsAccess) {
        return history.replace('/administration/projects-transitions')
      }
      if (hasSubscriberAccess) {
        return history.replace('/administration/subscribers')
      }
      if (hasNotificationAccess) {
        return history.replace('/administration/notifications')
      }
    }
    setNavReady(true)
  }, [history.location.pathname])

  if (!navReady) {
    return null
  }

  if (!hasAccess('perm_view_administration')) {
    return <Redirect to={'/dashboard'}/>
  }

  return (
    <Switch>
      {hasAccess('perm_administration_users') && <Route exact path="/administration" component={Users}/>}
      {
        hasAccess('perm_administration_assets') &&
        <Route
          exact
          path="/administration/projects-transitions"
          component={ProjectsAndTransitions}/>
      }
      {
        hasAccess('perm_administration_subscriber') &&
        <Route
          exact
          path="/administration/subscribers"
          component={Tenants}/>
      }
      {
        hasAccess('perm_administration_notifications') &&
        <Route
          exact
          path="/administration/notifications"
          component={Notifications}/>
      }
      <Route path="*">
        <ErrorPage/>
      </Route>
    </Switch>
  )
}
