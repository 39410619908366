import { makeStyles, Typography } from '@material-ui/core'
import { LabelTree } from 'common/LabelTree/LabelTree'
import { LabelSearch } from 'common/LabelSearch/LabelSearch'
import { RecentLabels } from './RecentLabels'
import { useEffect, useRef } from 'react'
import { setScrollListener } from 'redux/slices/documentLabelsSlice'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.black.main,
    height: 'calc(100vh - 96px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .MuiGrid-root': {
      flexWrap: 'unset',
      columnGap: '20px',
      '& .MuiTextField-root': {
        backgroundColor: 'white'
      }
    },
    '& .MuiToggleButtonGroup-root': {
      display: 'flex',
      '& .MuiToggleButton-root': {
        flex: 1,
        border: 'none',
        fontWeight: 600,
        '&:hover': {
          background: 'none'
        },
        '&.Mui-selected': {
          background: 'none',
          borderRadius: '0px',
          boxShadow: `inset 0 -4px ${theme.palette.lime.main}`
        }
      }
    }
  },
  labelingTitle: {
    marginBottom: '16px',
    color: theme.palette.black.main
  },
  tabTitle: {
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '4px',
    lineHeight: '24px',
    height: '30px'
  },
  labelContainer: {
    width: '100%',
    maxWidth: '328px',
    overflow: 'auto',
    padding: '6px 0',
    borderTop: `1px solid ${theme.palette.grey2.dark}`,
    maxHeight: 'calc(100vh - 220px)'
  },
  sectionTitle: {
    fontSize: '14px',
    marginBottom: '6px',
    fontWeight: 700,
    letterSpacing: '.8px',
    marginTop: '8px',
    textTransform: 'uppercase'
  }
}))

const Labeling = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const rootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const div = rootRef.current
    let timer: any = null
    if (div) {
      div.addEventListener('scroll', () => {
        if (timer !== null) {
          dispatch(setScrollListener(true))
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          dispatch(setScrollListener(false))
        }, 1000)
      })
      return () => {
        div.removeEventListener('scroll', () => {
          dispatch(setScrollListener(null))
        })
      }
    }
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.labelingTitle}>
        <Typography className={classes.tabTitle} variant="h6">
            Labelling
        </Typography>
      </div>
      <LabelSearch />
      <RecentLabels/>
      <Typography className={classes.sectionTitle} variant="h5">
          All Labels
      </Typography>
      <div className={classes.labelContainer} ref={rootRef}>
        <LabelTree/>
      </div>
    </div>
  )
}

export default Labeling
