import { resetSearchParams, updateSearchParams } from 'redux/slices/searchDocumentsSlice'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { SearchParams, TransitionId } from 'types'

type Condition = 'operatorOr' | 'operatorAnd' | 'matchCase' | null

export const useDocumentSearch = (transitionId: TransitionId, defaultSearchText: string = '') => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState<string>(defaultSearchText)
  const [condition, setCondition] = useState<Condition>('operatorAnd')

  const search = (searchParams: SearchParams) => {
    dispatch(updateSearchParams({
      ...searchParams,
      searchText: searchText.length < 3 ? '' : searchText,
      transitionId: transitionId.toUpperCase()
    }))
  }

  const reset = () => dispatch(resetSearchParams())

  const handleCondition = (newCondition: Condition) => {
    const matchCase = newCondition === 'matchCase'
    const operatorAnd = newCondition === 'operatorAnd'
    const operatorOr = newCondition === 'operatorOr'
    if (newCondition !== null) {
      dispatch(updateSearchParams({
        matchCase,
        operatorAnd,
        operatorOr
      }))
      if (matchCase) {
        setCondition('matchCase')
      }
      if (operatorAnd) {
        setCondition('operatorAnd')
      }
      if (operatorOr) {
        setCondition('operatorOr')
      }
    } else {
      setCondition(null)
    }
  }

  return {
    search,
    reset,
    handleCondition,
    searchText,
    setSearchText,
    condition
    // searchQuery
  }
}
