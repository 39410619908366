import { FC, ReactNode, useState } from 'react'
import { Grid, Icon, makeStyles, Menu, Toolbar } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  toolbarContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4
  },
  toolbar: {
    flexGrow: 1,
    height: '56px',
    minHeight: '56px',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.black.light,
    fontWeight: 700,
    textTransform: 'uppercase',
    '& .dropdown': {
      width: 200
    }
  },
  title: {
    fontSize: '14px',
    flexGrow: 1
  },
  menuIcon: {
    width: '42px !important',
    height: '42px !important',
    padding: '11px',
    marginRight: '5px',
    textAlign: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'all .1s linear',
    color: theme.palette.black.main,
    '&:hover': {
      background: theme.palette.grey2.light,
      color: theme.palette.black.light
    },
    '& .fas.fa-ellipsis-v': {
      fontSize: '18px'
    }
  }
}))

export type LisaToolbarProps = {
  title?: ReactNode
  items?: ReactNode
  disabled?: boolean
  className?: string
  toolbarClassName?: string
  option?: ReactNode
  additionalTools?: ReactNode
  noTitle?: boolean
}
export const LisaToolbar: FC<LisaToolbarProps> = ({
  title = null,
  items = null,
  disabled = false,
  className = '',
  option = null,
  additionalTools = null,
  children,
  noTitle = false,
  toolbarClassName
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  return (
    <Grid item container className={clsx(classes.toolbarContainer, { [className]: className !== undefined })}>
      <Toolbar
        className={clsx(classes.toolbar, toolbarClassName)}
        disableGutters>
        {
          !noTitle &&
          <Typography
            className={classes.title}
            id="tableTitle"
            component="div">
            {title}
          </Typography>
        }
        {option}
        {children}
        {additionalTools}
        {
          items !== null &&
          <div
            className={classes.menuIcon}
            onClick={(e) => !disabled && setAnchorEl(e.currentTarget)}>
            <Icon
              className="fas fa-ellipsis-v"
              fontSize="small"
            />
          </div>
        }
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          onClick={() => setAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
          {items}
        </Menu>
      </Toolbar>
    </Grid>
  )
}
