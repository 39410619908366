import { FC } from 'react'
import { Chip, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  queryCountChip: {
    '&.MuiChip-root': {
      cursor: 'inherit'
    }
  }
}))

type QueryCountIndicatorProps = {
  queryCount: number
  closedQueryCount: number
}
export const QueryCountIndicator: FC<QueryCountIndicatorProps> = ({ closedQueryCount, queryCount }) => {
  const classes = useStyles()
  if (queryCount === 0 && closedQueryCount === 0) {
    return null
  }
  return <Chip size="small" label={queryCount > 0 && queryCount} className={classes.queryCountChip}/>
}
