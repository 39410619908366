import { useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useDashboard } from 'redux/slices/dashboardSlice'
import { WithTransitionsLoader } from 'common/WithLoader/WithLoader'
import { TransitionItem } from 'common/Items/TransitionItem'
import { useHistory } from 'react-router-dom'
import { MyTransitionsFilter } from './components/MyTransitionsFilter'
import {
  filterTransitionsByName,
  filterTransitionsByProjectId,
  filterTransitionsByUser,
  sortTransitionsByCreateDate
} from 'utils/transition'
import { useGetMyTransitionsQuery } from 'services/api/transitionApi'

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 16
  }
}))

const MyTransitions = () => {
  const classes = useStyles()
  const { hasAccess, userId } = useLisaAuth()
  const { myTransitionsFilters } = useSelector(useDashboard)
  const { data: myTransitions = [], isLoading } = useGetMyTransitionsQuery(userId)
  const canViewA2k = hasAccess('perm_view_a2kstructure')
  const history = useHistory()

  const transitions =
    filterTransitionsByProjectId(
      filterTransitionsByName(
        filterTransitionsByUser(
          sortTransitionsByCreateDate(myTransitions, myTransitionsFilters.sort),
          myTransitionsFilters.userId
        ),
        myTransitionsFilters.term),
      myTransitionsFilters.projectId)

  return (
    <ContentWrapper newLayout>
      {
        myTransitions.length > 0 &&
        <MyTransitionsFilter/>
      }
      <WithTransitionsLoader
        noResultsProps={{ message: 'No Assets' }}
        loading={isLoading}
        hasResults={transitions.length > 0}>
        <Grid container spacing={2} className={classes.root}>
          {
            transitions.map((transition) => (
              <Grid item xs={6} md={4} lg={3} key={transition.transitionId}>
                <TransitionItem
                  transition={transition}
                  onClick={() => {
                    history.push(`/structures/${canViewA2k ? 'import' : 'client'}/${transition.transitionId}`)
                  }}/>
              </Grid>
            ))
          }
        </Grid>
      </WithTransitionsLoader>
    </ContentWrapper>
  )
}

export default MyTransitions
