import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Grid, makeStyles } from '@material-ui/core'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import Projects from './components/projects/Projects'
import { ProjectForm } from './components/projects/ProjectForm'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { openModal } from 'redux/slices/appSlice'
import { setSearchTerm } from 'redux/slices/projectsSlice'
import { GridGrow } from 'common/Grid/GridGrow'
import InputSearch from 'common/LisaControls/InputSearch'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: 0,
    '& .MuiGrid-container': {
      marginBottom: '8px',
      '&:first-child': {}
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  paper: {
    margin: '0px 10px 0px 0px',
    borderRadius: '4px',
    marginBottom: '0px',
    '& .MuiTreeView-root': {
      overflowY: 'auto'
    }
  },
  treeViewHeader: {
    height: '56px',
    borderBottom: `1px solid ${theme.palette.grey2.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '5px',
    '& .dropdown': {
      width: 200
    }
  },
  search: {
    marginBottom: '10px',
    '& .fas.fa-info-circle': {
      display: 'none'
    }
  },
  inviteButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '10px'
  },
  loader: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 200px)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '2px',
    margin: '4px'
  },
  noDocuments: {
    display: 'flex',
    margin: '5px',
    background: 'rgba(255,255,255,.4)',
    height: 'calc(100vh - 200px)',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.palette.black.light100
  },
  noDocumentIco: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      width: '52px',
      height: '52px',
      marginBottom: '24px',
      fill: theme.palette.black.light100
    }
  },
  projectsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto'
  }
}))

export const ProjectsAndTransitions: FC = () => {
  const classes = useStyles()
  const [searchText, setSearchText] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(setSearchTerm(''))
    }
  }, [])

  const updateSearchTerm = () => {
    dispatch(setSearchTerm(searchText))
  }

  const handleValue = (e: ChangeEvent<{value: string}>) => {
    e.preventDefault()
    const { value } = e.target
    setSearchText(value)
  }

  const enterPressed = (e: KeyboardEvent) => {
    e.preventDefault()
    if (searchText && searchText.length > 2) {
      if (e.key === 'Enter') {
        updateSearchTerm()
      }
    }
    if (e.key === 'Backspace') {
      if (searchText.length === 0) {
        updateSearchTerm()
      }
    }
  }

  return (
    <ContentWrapper newLayout>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={8} lg={4} className={classes.search}>
            <InputSearch
              name='search'
              label='Find entity / asset'
              onChange={handleValue}
              onClick={(e) => {
                e.preventDefault()
                updateSearchTerm()
              }}
              value={searchText}
              onKeyUp={enterPressed}
            />
          </Grid>
          <Grid item xs={4} lg={8} className={classes.inviteButton}>
            <Button
              variant='contained'
              color='primary'
              size='large'
              onClick={() => dispatch(openModal('PROJECT_FORM'))}
            >
              ADD ENTITY
            </Button>
          </Grid>
        </Grid>
        <GridGrow container>
          <Grid item xs={12}>
            <div className={classes.projectsWrapper}>
              <Projects />
            </div>
          </Grid>
        </GridGrow>
        <LisaModal
          title={'Add new Entity'}
          modalType={'PROJECT_FORM'}
          scroll='body'>
          <ProjectForm />
        </LisaModal>
      </div>
    </ContentWrapper>
  )
}

export default ProjectsAndTransitions
