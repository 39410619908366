import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Grid, makeStyles, MenuItem, Typography } from '@material-ui/core'
import { GridGrow } from 'common/Grid/GridGrow'
import { LisaPaper } from 'common/LisaPaper/LisaPaper'
import { LabelsProvider, LabelsType } from 'context/LabelsContext'
import { WithLabelsLoader } from 'common/WithLoader/WithLoader'
import { LabelTree } from 'common/LabelTree/LabelTree'
import { useGetA2KLabelsQuery } from 'services/api/labellingApi'
import { useTransitionId } from 'context/TransitionContext'
import { labelAdded, setHierarchy, useA2KLabels } from 'redux/slices/a2kLabelsSlice'
import { setHierarchy as setReassignLabelsHierarchy } from 'redux/slices/reassignLabelsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { LabelSearchAutoComplete } from 'common/LabelSearch/LabelSearchAutoComplete'
import { A2KCondition } from 'components/A2KCondition/A2KCondition'
import {
  addNewCondition,
  clear,
  removeCondition,
  setConditionsAndOperators,
  updateOperator,
  updateCondition,
  useA2KRule,
  updateCurrentRuleQuery
} from 'redux/slices/a2kRulesSlice'
import Dropdown from 'common/LisaControls/Dropdown'
import { A2KLogicalOperator } from 'types'
import { LabellingRulesDocuments } from 'pages/Settings/components/LabellingRulesDocuments'
import SettingsPageWrapper from 'pages/Settings/SettingsPageWrapper'
import {
  A2KRulePreviewResponse,
  A2KRulePreviewType,
  useGetRulesQuery,
  usePreviewRuleMutation,
  useSaveRuleMutation
} from 'services/api/labelRuleApi'
import { normalizeA2KRuleTree, rulesToUserFriendlyQuery } from 'utils/labellingRules'
import Prompt from 'common/Dialogs/Prompt'
import { ConfirmDialog } from 'common/Dialogs/ConfirmDialog'
import { openConfirmDialog } from 'redux/slices/appSlice'

const useStyles = makeStyles((theme) => ({
  toolbarContainer: {
    paddingInline: 0
  },
  autocompleteContainer: {
    marginInline: 8
  },
  conditionsContainer: {
    paddingTop: 8,
    paddingInline: 8,
    overflow: 'auto',
    height: 0
  },
  addConditionContainer: {
    marginTop: 8,
    marginBottom: 16
  },
  saveAndApplyContainer: {
    paddingBlock: 8,
    boxShadow: '0 -3px 3px 0px rgb(0 0 0 / 5%)'
  },
  operatorDropDown: {
    marginBlock: 8,
    height: 36
  },
  previewButton: {
    marginRight: 6
  },
  dialogMessage: {
    fontSize: '20px',
    fontWeight: 500,
    paddingTop: '48px',
    paddingBottom: '48px'
  },
  note: {
    color: theme.palette.statusColors.red,
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word'
  }
}))

const andOrItems = [{
  value: A2KLogicalOperator.AND,
  text: 'AND'
}, {
  value: A2KLogicalOperator.OR,
  text: 'OR'
}]

export const LabellingRules: FC = () => {
  const classes = useStyles()
  const transitionId = useTransitionId()
  const dispatch = useDispatch()
  const {
    hierarchy,
    selected: label
  } = useSelector(useA2KLabels)
  const {
    data: a2kLabels,
    isFetching,
    fulfilledTimeStamp
  } = useGetA2KLabelsQuery({
    transitionId,
    showAll: true
  })
  const {
    conditions,
    operators,
    ruleId,
    currentRuleQuery,
    filters,
    selectedDocuments
  } = useSelector(useA2KRule)
  const {
    data: rulesData = [],
    fulfilledTimeStamp: fulfilledTimeStampRules
  } = useGetRulesQuery(label?.labelId ?? 0, { skip: label === null })
  const [saveRule] = useSaveRuleMutation()
  const [previewRule, { data, isLoading, fulfilledTimeStamp: newPreviewTimeStamp }] = usePreviewRuleMutation()
  const [previewData, setPreviewData] = useState<A2KRulePreviewResponse | undefined>()
  const userFriendlyQuery = useMemo(() => rulesToUserFriendlyQuery(conditions, operators), [conditions, operators])
  const [valuesDifference, setValuesDifference] = useState(false)
  const [rule] = rulesData

  useEffect(() => {
    setPreviewData(data)
  }, [newPreviewTimeStamp])

  useEffect(() => {
    setPreviewData(undefined)
  }, [rule?.ruleId, label?.labelId])

  useEffect(() => {
    if (a2kLabels) {
      dispatch(setHierarchy(a2kLabels))
      dispatch(setReassignLabelsHierarchy(a2kLabels))
    }
  }, [fulfilledTimeStamp])

  useEffect(() => {
    if (rule) {
      dispatch(setConditionsAndOperators({
        ...normalizeA2KRuleTree(rule.ruleTree.compoundA2KRuleTree),
        ruleId: rule.ruleId
      }))
    } else {
      dispatch(clear())
    }
  }, [fulfilledTimeStampRules])

  const previewRuleCB = useCallback(() => {
    if (userFriendlyQuery) {
      previewRule({
        transitionId: transitionId.toUpperCase(),
        userFriendlyQuery: userFriendlyQuery,
        oldUserFriendlyQuery: currentRuleQuery,
        labelId: label?.labelId!,
        ...filters
      })
    }
  }, [filters, userFriendlyQuery, currentRuleQuery, transitionId])

  useEffect(() => {
    previewRuleCB()
  }, [filters, ruleId])

  const hasValidRule = useMemo(() => {
    if (conditions.length === 0) {
      return false
    }
    return conditions.reduce((isValid, c) => isValid && !!c.value && !!c.operator && !!c.type, true)
  }, [conditions])

  const ruleChanged = useMemo(() => {
    return currentRuleQuery !== rulesToUserFriendlyQuery(conditions, operators)
  }, [currentRuleQuery, conditions, operators])

  const saveRuleCB = useCallback(async (isDefaultRule: boolean = false) => {
    if (!label) {
      return
    }

    return await saveRule({
      transitionId,
      ruleId,
      labelId: label.labelId!,
      isDefaultRule,
      userFriendlyQuery: rulesToUserFriendlyQuery(conditions, operators),
      excludedListIgnoredDocs: selectedDocuments
    })
  }, [label, ruleId, conditions, operators, transitionId, selectedDocuments])

  const searchBox = useMemo(() => (
    <Grid className={classes.autocompleteContainer}>
      <LabelSearchAutoComplete
        onLabelsSelect={(labels) => {
          const [label] = labels
          if (label) {
            dispatch(labelAdded(label))
          }
        }}/>
    </Grid>
  ), [])

  const preview = useMemo(() => {
    return (
      <Button
        className={classes.previewButton}
        onClick={() => previewRule({
          transitionId: transitionId.toUpperCase(),
          userFriendlyQuery: rulesToUserFriendlyQuery(conditions, operators),
          oldUserFriendlyQuery: currentRuleQuery,
          labelId: label?.labelId!,
          ...filters,
          previewType: A2KRulePreviewType.List
        })}
        color={'primary'}
        variant={'contained'}
        disabled={!hasValidRule}>
        Preview
      </Button>
    )
  }, [hasValidRule, conditions, operators, transitionId, currentRuleQuery])

  const conditionItems = useMemo(() => {
    const canSaveAsDefault = hasValidRule && label?.isTemplateLabel
    return [
      <MenuItem
        // Can save if rule is valid and if rule changed or if it is not default rule
        disabled={!(canSaveAsDefault && (ruleChanged || !rule?.isDefaultRule))}
        key={'set-as-default-rule'}
        onClick={() => dispatch(openConfirmDialog('SET_DEFAULT_RULE_DIALOG'))}>
        Set as Default Rule
      </MenuItem>,
      <MenuItem
        disabled={!(canSaveAsDefault && (ruleChanged || rule?.isDefaultRule))}
        key={'remove-default-rule'}
        onClick={() => dispatch(openConfirmDialog('REMOVE_DEFAULT_RULE_DIALOG'))}>
        Remove Default Rule
      </MenuItem>
    ]
  }, [hasValidRule, saveRuleCB, ruleChanged, label?.isTemplateLabel, rule?.isDefaultRule])

  const hasLabels = hierarchy !== null && hierarchy.children.length > 0

  useEffect(() => {
    setValuesDifference(currentRuleQuery !== userFriendlyQuery)
    return () => {
      clear()
    }
  }, [userFriendlyQuery, currentRuleQuery])

  return (
    <SettingsPageWrapper>
      <GridGrow container className={'columnGap1'}>
        <Grid item container xs={3}>
          <LabelsProvider labelsType={LabelsType.A2K}>
            <LisaPaper toolbar={{ title: searchBox }} classes={{ toolbarContainer: classes.toolbarContainer }}>
              <WithLabelsLoader
                loading={isFetching}
                loadingText={'Loading file tree data...'}
                hasResults={hasLabels}>
                <LabelTree renderSearchResultsTree a2kRules/>
              </WithLabelsLoader>
            </LisaPaper>
          </LabelsProvider>
        </Grid>
        <Grid item container xs={3}>
          <LisaPaper toolbar={{
            items: conditionItems,
            additionalTools: preview
          }}>
            <Grid container direction={'column'}>
              <GridGrow item className={classes.conditionsContainer}>
                {
                  conditions.map((condition, i) => (
                    <Grid item key={`rule-${i}`} xs={12}>
                      <A2KCondition
                        onUpdate={(c) => dispatch(updateCondition({ condition: c, index: i }))}
                        onClose={() => dispatch(removeCondition(i))}
                        condition={condition}/>
                      {
                        operators[i] !== undefined &&
                      <Dropdown
                        fullWidth
                        value={operators[i]}
                        items={andOrItems}
                        onChange={(e) => {
                          dispatch(updateOperator({ operator: e.target.value as A2KLogicalOperator, index: i }))
                        }}
                        className={classes.operatorDropDown}
                        showSelectOption={false}/>
                      }
                    </Grid>
                  ))
                }
                <Grid item container justifyContent={'center'} className={classes.addConditionContainer} xs={12}>
                  <Button
                    disabled={!label || ((label.level ?? 0) <= 1)}
                    onClick={() => {
                      dispatch(addNewCondition())
                    }}
                    variant={'contained'}
                    color={'primary'}>
                    Add Condition
                  </Button>
                </Grid>
              </GridGrow>
              <Grid item container className={classes.saveAndApplyContainer} justifyContent={'center'}>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  disabled={!ruleChanged || isLoading}
                  onClick={async (event) => {
                    if (event.detail !== 1) {
                      return
                    }
                    if (isLoading) {
                      return
                    }
                    await saveRuleCB()
                    previewRule({
                      transitionId: transitionId.toUpperCase(),
                      userFriendlyQuery: userFriendlyQuery,
                      oldUserFriendlyQuery: userFriendlyQuery,
                      labelId: label?.labelId!,
                      ...filters
                    })
                    dispatch(updateCurrentRuleQuery(userFriendlyQuery))
                    dispatch(clear())
                  }}>
                  Save and apply
                </Button>
              </Grid>
            </Grid>
          </LisaPaper>
        </Grid>
        <Grid item container xs={6}>
          <LabellingRulesDocuments
            data={userFriendlyQuery ? previewData : undefined}
            isLoading={isLoading}/>
        </Grid>
      </GridGrow>
      <Prompt when={valuesDifference} pageName={'labeling rules'} />
      <ConfirmDialog
        title="Set as default rule"
        type={'SET_DEFAULT_RULE_DIALOG'}
        onConfirm={async () => {
          await saveRuleCB(true)
          dispatch(updateCurrentRuleQuery(userFriendlyQuery))
          dispatch(clear())
        }}>
        <Typography
          className={classes.dialogMessage}
        >{'Are you sure you want to set a Default Rule?'}
          <div className={classes.note}>
            {'PLEASE NOTE: Default Rule it shall be applied to all future Assets!'}</div></Typography>
      </ConfirmDialog>
      <ConfirmDialog
        title="Remove default rule"
        type={'REMOVE_DEFAULT_RULE_DIALOG'}
        onConfirm={async () => {
          await saveRuleCB(false)
          dispatch(updateCurrentRuleQuery(userFriendlyQuery))
          dispatch(clear())
        }}>
        <Typography
          className={classes.dialogMessage}
        >{'Are you sure you want to remove a Default Rule?'}
          <div className={classes.note}>
            {'PLEASE NOTE: Default Rule removal it shall be applied to all future Assets!'}</div></Typography>
      </ConfirmDialog>
      <ConfirmDialog
        title="Exlude documents"
        type={'EXCLUDE_A2K_RULES_DOCUMENTS'}
        onConfirm={async () => {
          await saveRuleCB(false)
          dispatch(updateCurrentRuleQuery(userFriendlyQuery))
          dispatch(clear())
        }}>
        <Typography
          className={classes.dialogMessage}
        >{'Are you sure you want to exclude selected documents?'}
        </Typography>
      </ConfirmDialog>
    </SettingsPageWrapper>
  )
}
