import { FC, ReactNode } from 'react'
import { Grid, makeStyles, Table, TableBody, TableContainer, TableHead, TableRow, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { WithDocumentsLoader } from 'common/WithLoader/WithLoader'
import { NoResultsProps } from 'common/NoResults/NoResults'

const useStyles = makeStyles<Theme, { noGrow: boolean, tinyHeading: boolean }>((theme) => ({
  root: {
    '& .MuiTableRow-root.MuiTableRow-hover:hover': {
      backgroundColor: theme.palette.blue.main,
      '& td, .MuiChip-root.FileStatus': {
        color: 'white'
      },
      '& .document-type-container': {
        background: theme.palette.lime.main,
        color: theme.palette.blue.main,
        '&.error': {
          background: theme.palette.statusColors.red,
          color: theme.palette.lime.main
        }
      },
      '& .MuiCheckbox-colorSecondary, & ': {
        color: theme.palette.common.white
      },
      '& .MuiSvgIcon-root': {
        fill: 'white'
      },
      '& .MuiIcon-fontSizeSmall': {
        color: 'white'
      }
    },
    '& .MuiTableRow-root.Mui-selected td': {
      backgroundColor: theme.palette.grey2.light
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.blue.main,
      fontSize: '1rem'
    },
    '& .MuiFormControl-root .MuiTypography-body1': {
      fontSize: '12px !important',
      lineHeight: '12px !important',
      textTransform: 'uppercase',
      fontWeight: '700 !important',
      letterSpacing: '.05px',
      color: theme.palette.black.light
    },
    '& .MuiIcon-fontSizeSmall': {
      fontSize: '1rem',
      color: theme.palette.common.black
    },
    flexGrow: props => props.noGrow ? 0 : 1,
    boxShadow: 'none !important',
    borderRadius: '0 0 4px 4px !important'
  },
  tableContainer: {
    marginBottom: 0,
    '& tr.Mui-selected': {
      '& .assignLabels:hover': {
        backgroundColor: theme.palette.statusColors.blue,
        borderColor: theme.palette.statusColors.blue,
        color: theme.palette.common.white
      }
    }
  },
  table: {
    alignSelf: 'stretch',
    backgroundColor: theme.palette.common.white,
    '& .MuiTableCell-paddingCheckbox': {
      padding: '0 3px 0 7px !important'
    }
  },
  headerTable: {
    boxShadow: '0 3px 3px 0px rgba(0,0,0,.05)',
    zIndex: 2,
    height: props => props.tinyHeading ? 36 : 56,
    '& .MuiTableRow-root': {
      height: props => props.tinyHeading ? 36 : 56
    }
  },
  noShadow: {
    boxShadow: 'none'
  },
  mainTable: {
    flexGrow: 1,
    overflow: 'auto',
    height: 0,
    justifyContent: 'center',
    '& .MuiTableRow-root': {
      height: 42 // tr height
    },
    '& .MuiTableCell-root': {
      lineHeight: '0',
      whiteSpace: 'nowrap',
      padding: 0
    },
    borderRadius: '0 0 4px 4px'
  },
  noGrow: {
    height: 'inherit'
  },
  headRow: {
    zIndex: 2,
    height: '56px',
    '& .MuiTableCell-head': {
      lineHeight: '0',
      whiteSpace: 'nowrap',
      padding: 0
    },
    '& .MuiTableCell-head:last-child': {
      // paddingRight: 16
    }
  },
  noData: {
    marginBottom: 56 // To compensate for table header row
  }
}))
type TableDataProps = {
  header?: ReactNode
  headCells?: ReactNode[]
  tableRows?: ReactNode[]
  loading?: boolean,
  loadingText?: ReactNode,
  noResultsProps?: NoResultsProps,
  noShadow?: boolean
  noGrow?: boolean,
  tinyHeading?: boolean
}

export const TableData: FC<TableDataProps> = ({
  header = null,
  children,
  headCells = [],
  tableRows = [],
  loading = false,
  loadingText,
  noResultsProps = {},
  noShadow = false,
  noGrow = false,
  tinyHeading = false
}) => {
  const classes = useStyles({ noGrow, tinyHeading })
  const noData = loading || tableRows?.length === 0
  return (
    <Grid container direction={'column'} alignItems='center' className={classes.root}>
      <Grid item className={clsx(classes.table, classes.headerTable, { [classes.noShadow]: noShadow })}>
        <TableContainer className={classes.tableContainer}>
          <Table size={'medium'}>
            <TableHead>
              <TableRow className={classes.headRow}>
                {
                  headCells.map((hc) => hc)
                }
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Grid>
      {header}
      <Grid
        item
        container
        direction={noData ? 'column' : 'row'}
        justifyContent={noData ? 'center' : 'flex-start'}
        className={clsx(classes.table, classes.mainTable, { [classes.noData]: noData, [classes.noGrow]: noGrow })}>
        <TableContainer className={classes.tableContainer}>
          <WithDocumentsLoader
            loading={loading}
            hasResults={tableRows?.length > 0}
            noResultsProps={noResultsProps}
            loadingText={loadingText}
          >
            <Table size={'medium'}>
              <TableBody>
                {
                  tableRows?.map((tr) => tr)
                }
              </TableBody>
            </Table>
          </WithDocumentsLoader>
        </TableContainer>
        {children}
      </Grid>
    </Grid>
  )
}
