import { FC, useMemo } from 'react'
import { GridGrow } from 'common/Grid/GridGrow'
import { Grid, makeStyles, Theme } from '@material-ui/core'
import { resolveDocumentType } from 'utils/documents'
import { VideoViewer } from './VideoViewer'
import { PdfViewer } from './PdfViewer'
import { ImageViewer } from './ImageViewer'
import ReferenceHeader from 'pages/Document/components/ReferenceHeader'
import { DocumentViewerProps } from './documentViewerTypes'
import { CompactReferenceHeader } from './CompactReferenceHeader'
import clsx from 'clsx'

const useStyles = makeStyles<Theme, { isCompact: boolean, tableView: boolean }>((theme) => ({
  viewerContainer: {
    width: '100%'
  },
  referenceTitle: {
    color: '#fff',
    display: 'flex',
    fontSize: 14,
    lineHeight: '14px',
    borderBottom: `2px solid ${theme.palette.lime.main}`,
    marginBottom: 6,
    paddingBottom: 2,
    justifyContent: 'space-between'
  },
  referenceTitleCompare: {
    borderColor: theme.palette.statusColors.blue
  },
  compactReferenceHeaderWrapper: {
    backgroundImage: 'linear-gradient(180deg, rgba(0,0,0,8) -55%, rgba(255,255,255,0) 75%)',
    paddingInline: 10
  },
  referenceWrapper: {
    position: 'absolute',
    width: '100%',
    zIndex: 10,
    top: ({
      isCompact,
      tableView
    }) => {
      if (tableView) {
        return 60
      }
      return isCompact ? 56 : 103
    }
  },
  referenceRootForMxdDocuments: {
    paddingLeft: 76,
    width: 'calc(100% - 106px)'
  },
  compactReferenceRootForMxdDocuments: {
    paddingLeft: 64,
    width: 'calc(100% - 64px)'
  }
}))

export const DocumentViewer: FC<DocumentViewerProps> = (props) => {
  const {
    document,
    labels,
    documentReferences,
    options: { view = 'normal', referenceTitle = '', referenceVariant = 'main', tableView = false } = {}
  } = props
  const classes = useStyles({ isCompact: view === 'compact', tableView })
  const documentType = useMemo(() => resolveDocumentType(document?.extension ?? '', document?.ocrStatus),
    [document.extension])
  return (
    <GridGrow container direction={'column'} className={classes.viewerContainer} style={{ position: 'relative' }}>
      <Grid item className={classes.referenceWrapper}>
        {
          view === 'normal' &&
          <ReferenceHeader className={clsx({
            [classes.referenceRootForMxdDocuments]: document.docTypeRecognitionResultByPages.length > 0
          })}/>
        }
        {
          view === 'compact' &&
            <div className={classes.compactReferenceHeaderWrapper}>
              {
                referenceTitle &&
                <div className={clsx(
                  classes.referenceTitle, {
                    [classes.referenceTitleCompare]: referenceVariant === 'compare'
                  })}>
                  {referenceTitle}
                </div>
              }
              <CompactReferenceHeader
                document={document}
                labels={labels}
                documentReferences={documentReferences}
                className={clsx({
                  [classes.compactReferenceRootForMxdDocuments]: document.docTypeRecognitionResultByPages.length > 0
                })}/>
            </div>
        }
      </Grid>
      {
        documentType === 'video' &&
          <VideoViewer {...props}/>
      }
      {
        documentType === 'pdf' &&
          <PdfViewer {...props}/>
      }
      {
        documentType === 'image' &&
          <ImageViewer {...props}/>
      }
    </GridGrow>
  )
}
