import { FC } from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles<Theme, { checked: boolean, disabled: boolean }>((theme) => ({
  root: {
    fontSize: '10px',
    '& span': {
      paddingLeft: '0px',
      fontSize: '16px',
      fontWeight: 500
    }
  },
  blue: {
    '& .MuiSwitch-root': {
      backgroundColor: '#d1d0d0',
      '& .MuiSwitch-colorPrimary': {
        color: theme.palette.common.white,
        background: 'transparent'
      },
      '& .Mui-checked': {
        color: theme.palette.statusColors.green
      }
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      fontWeight: 700
    }
  },
  inverted: {
    '& .MuiSwitch-root': {
      border: '2px solid #d4d8d8',
      backgroundColor: ({ checked, disabled }) => {
        if (checked) {
          return disabled ? '#d4d8d8' : theme.palette.statusColors.green
        } else {
          return theme.palette.common.white
        }
      },
      '& .MuiSwitch-colorPrimary': {
        color: ({ checked, disabled }) => {
          if (checked) {
            return disabled ? '#eaecec' : theme.palette.common.white
          } else {
            return '#d4d8d8'
          }
        },
        background: 'transparent'
      }
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      fontWeight: 600
    }
  }
}))

export interface SwitchLabelProps extends Omit<FormControlLabelProps, 'control'> {
  value?: boolean | undefined
  variant?: 'default' | 'blue' | 'inverted'
}

const SwitchLabel: FC<SwitchLabelProps> = (props) => {
  const { name, label, value, onChange, disabled, variant = 'default', className } = props
  const classes = useStyles({ checked: !!value, disabled: !!disabled })

  return (
    <FormGroup row>
      <FormControlLabel
        className={clsx(classes.root, className, {
          [classes.blue]: variant === 'blue',
          [classes.inverted]: variant === 'inverted',
          checked: value
        })}
        onChange={onChange}
        control={
          <Switch
            disabled={disabled}
            checked={value}
            name={name}
            color="primary"
          />
        }
        label={label}
      />
    </FormGroup>
  )
}

export default SwitchLabel
