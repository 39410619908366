export enum RoleName {
  TechAdmin = 'Tech Admin',
  Administrator = 'Subscriber Administrator',
  ProgramManager = 'Program Manager',
  Operator = 'Operator',
  TechnicalSpecialist = 'Technical Specialist',
  KeyAccountManager = 'Key Account Manager',
  External = 'External'
}

export interface AccessRights {
  // Client level
  perm_view_administration: boolean // Allows view and access to Administration Navigation
  perm_administration_users: boolean // Allows access and usage of the User Administration
  perm_administration_assets: boolean // Allows access and usage of the Entities/Assets Administration
  perm_administration_subscriber: boolean // Allows access and usage of the Subscriber Administration
  perm_administration_notifications: boolean // Allows access and usage of the Notifications Administration
  // Asset/Transition Level - User must be in the Transition (Asset) Team or must have the “Tech-Admin” user role to access an Asset/Transition
  perm_view_impstructure: boolean // Visibility of Link in Navigation and Import Structure List. If User does not have this right, he will be forwarded to the A2K List when opening an Asset.
  perm_view_a2kstructure: boolean // Visibility of Link in Navigation and A2K Structure List.
  perm_view_clientstructure: boolean // Visibility of Link in Navigation and Client Structure List.
  perm_view_mxHistoryStructure: boolean // Visibility of Link in Navigation and MxHistory Structure List.
  perm_view_query: boolean // Allows read-access to (external) queries in accessible Assets/Transitions
  perm_view_query_internal: boolean // Allows read-access for internal queries
  perm_view_areports: boolean // Allows view and access to Reports related to an Asset/Transition (top navigation inside an Asset)
  perm_view_areport_asset: boolean // Allows view and access to Asset Report
  perm_view_areport_processing: boolean // Allows view and access to Processing Report
  perm_view_creports: boolean // Allows view and access to Reports related to a Subscriber/Client (left navigation)
  perm_view_document_internal: boolean // Can see all document statues
  perm_view_document_external: boolean // Can see only review, accepted and clarification document statuses
  perm_view_dashboard_ocrqueue: boolean // Can see ocr queue on dashboard
  perm_act_transitionsettings: boolean // Allows access and modifications to the Asset Settings
  perm_act_transitionsettings_a2krule: boolean // Allows access and modifications to A2K Rule Management
  perm_act_uploaddocument: boolean // Allows usage of Import from OneDrive, Update Import Structure
  perm_act_fileupload: boolean // Allows usage of the File Upload function
  perm_act_sethighocrprio: boolean // Allows the usage of the “Set high OCR Priority” function
  perm_act_clonetoa2k: boolean // Allows usage of “Clone selected to A2K” function
  perm_act_a2klabel: boolean // Allows assignment of A2K Labels to documents including “Bulk Label assign” and “Assign label to unlabelled documents”
  perm_act_a2kadmin: boolean // Allows adding, modifying and removing of A2K Labels
  perm_act_documentmetadata: boolean // Allows modification of document metadata (doc type, description, references) and Document Status (open, clarification, reviewed, Irrelevant) in Document View as well as the Bulk Document Status change function.
  perm_act_renamedocument: boolean // Allows renaming of documents
  perm_act_deletedocument: boolean // Allows setting the document status to “Irrelevant” and “For Deletion”, enables Bulk Document Status change with option “Irrelevant” and “For Deletion”
  perm_act_acceptdocument: boolean // Allows setting the document status “Accepted”, enable Bulk Document Status change with option “Accepted” (if documents had necessary Status Reviewed or Clarification)
  perm_act_exportdeliverybible: boolean // Enables the “Export Delivery Bible” function in the Client View
  perm_act_query_create: boolean // Allows creation of Query
  perm_act_query_edit: boolean // Allows editing of a Query
  perm_act_query_edit_reported: boolean // Allows editing of a Query if the query is reported by the user
  perm_act_query_edit_assigned: boolean // Allows editing of a Query if the query is assigned to the user
  perm_act_query_comment: boolean // Can write a comment to a query unrelated to assignment or reporter
  perm_act_query_comment_assigned: boolean // Can write a comment to a query if the query is assigned to the user
  perm_act_query_comment_reported: boolean // Can write a comment to a query if the query was reported by the user
  perm_act_query_attachement: boolean // Allows adding attachments to Queries. User can add attachments always if the query is assigned to him or if he reported the query
  perm_act_replacedocument: boolean // Allows usage of the Replace Document function in the document view
  internal_external_flag: boolean // Flag which determines if a new Query is by default internal (true) or external (false). If false the “External Query” switch in Query Form is set to External and disabled.
  perm_act_resendtoocr: boolean // Allows the usage of the “Resend to OCR” function in the OCR reports
  perm_act_areports_notes: boolean // Allows editing of notes and setting overall Status in Asset Report
}

export interface Role {
  roleName: RoleName,
  accessRights: AccessRights
}

export const Roles: Role[] = [
  {
    roleName: RoleName.TechAdmin,
    accessRights: {
      perm_view_administration: true,
      perm_administration_users: true,
      perm_administration_assets: true,
      perm_administration_subscriber: true,
      perm_administration_notifications: true,
      perm_view_impstructure: true,
      perm_view_a2kstructure: true,
      perm_view_clientstructure: true,
      perm_view_mxHistoryStructure: true,
      perm_view_query: true,
      perm_view_query_internal: true,
      perm_view_areports: true,
      perm_view_areport_asset: true,
      perm_view_areport_processing: true,
      perm_view_creports: false,
      perm_view_document_internal: true,
      perm_view_document_external: true,
      perm_view_dashboard_ocrqueue: true,
      perm_act_transitionsettings: true,
      perm_act_transitionsettings_a2krule: true,
      perm_act_uploaddocument: true,
      perm_act_fileupload: true,
      perm_act_sethighocrprio: true,
      perm_act_clonetoa2k: true,
      perm_act_a2klabel: true,
      perm_act_a2kadmin: true,
      perm_act_documentmetadata: true,
      perm_act_renamedocument: true,
      perm_act_deletedocument: true,
      perm_act_acceptdocument: false,
      perm_act_exportdeliverybible: true,
      perm_act_query_create: true,
      perm_act_query_edit: true,
      perm_act_query_edit_reported: true,
      perm_act_query_edit_assigned: true,
      perm_act_query_comment: false,
      perm_act_query_comment_assigned: true,
      perm_act_query_comment_reported: true,
      perm_act_query_attachement: true,
      perm_act_replacedocument: true,
      internal_external_flag: true,
      perm_act_resendtoocr: true,
      perm_act_areports_notes: true
    }
  },
  {
    roleName: RoleName.Administrator,
    accessRights: {
      perm_view_administration: true,
      perm_administration_users: true,
      perm_administration_assets: true,
      perm_administration_subscriber: false,
      perm_administration_notifications: false,
      perm_view_impstructure: true,
      perm_view_a2kstructure: true,
      perm_view_clientstructure: true,
      perm_view_mxHistoryStructure: true,
      perm_view_query: true,
      perm_view_query_internal: true,
      perm_view_areports: true,
      perm_view_areport_asset: true,
      perm_view_areport_processing: true,
      perm_view_creports: false,
      perm_view_document_internal: true,
      perm_view_document_external: true,
      perm_view_dashboard_ocrqueue: true,
      perm_act_transitionsettings: true,
      perm_act_transitionsettings_a2krule: true,
      perm_act_uploaddocument: true,
      perm_act_fileupload: true,
      perm_act_sethighocrprio: true,
      perm_act_clonetoa2k: true,
      perm_act_a2klabel: true,
      perm_act_a2kadmin: true,
      perm_act_documentmetadata: false,
      perm_act_renamedocument: true,
      perm_act_deletedocument: true,
      perm_act_acceptdocument: false,
      perm_act_exportdeliverybible: true,
      perm_act_query_create: true,
      perm_act_query_edit: true,
      perm_act_query_edit_reported: true,
      perm_act_query_edit_assigned: true,
      perm_act_query_comment: false,
      perm_act_query_comment_assigned: true,
      perm_act_query_comment_reported: true,
      perm_act_query_attachement: true,
      perm_act_replacedocument: true,
      internal_external_flag: true,
      perm_act_resendtoocr: true,
      perm_act_areports_notes: false
    }
  },
  {
    roleName: RoleName.ProgramManager,
    accessRights: {
      perm_view_administration: false,
      perm_administration_users: false,
      perm_administration_assets: false,
      perm_administration_subscriber: false,
      perm_administration_notifications: false,
      perm_view_impstructure: true,
      perm_view_a2kstructure: true,
      perm_view_clientstructure: true,
      perm_view_mxHistoryStructure: true,
      perm_view_query: true,
      perm_view_query_internal: true,
      perm_view_areports: true,
      perm_view_areport_asset: true,
      perm_view_areport_processing: false,
      perm_view_creports: false,
      perm_view_document_internal: true,
      perm_view_document_external: true,
      perm_view_dashboard_ocrqueue: true,
      perm_act_transitionsettings: true,
      perm_act_transitionsettings_a2krule: false,
      perm_act_uploaddocument: false,
      perm_act_fileupload: true,
      perm_act_sethighocrprio: true,
      perm_act_clonetoa2k: true,
      perm_act_a2klabel: true,
      perm_act_a2kadmin: true,
      perm_act_documentmetadata: true,
      perm_act_renamedocument: true,
      perm_act_deletedocument: true,
      perm_act_acceptdocument: false,
      perm_act_exportdeliverybible: true,
      perm_act_query_create: true,
      perm_act_query_edit: true,
      perm_act_query_edit_reported: true,
      perm_act_query_edit_assigned: true,
      perm_act_query_comment: false,
      perm_act_query_comment_assigned: true,
      perm_act_query_comment_reported: true,
      perm_act_query_attachement: true,
      perm_act_replacedocument: true,
      internal_external_flag: true,
      perm_act_resendtoocr: true,
      perm_act_areports_notes: true
    }
  },
  {
    roleName: RoleName.Operator,
    accessRights: {
      perm_view_administration: false,
      perm_administration_users: false,
      perm_administration_assets: false,
      perm_administration_subscriber: false,
      perm_administration_notifications: false,
      perm_view_impstructure: true,
      perm_view_a2kstructure: true,
      perm_view_clientstructure: true,
      perm_view_mxHistoryStructure: true,
      perm_view_query: false,
      perm_view_query_internal: true,
      perm_view_areports: true,
      perm_view_areport_asset: true,
      perm_view_areport_processing: false,
      perm_view_creports: false,
      perm_view_document_internal: true,
      perm_view_document_external: true,
      perm_view_dashboard_ocrqueue: false,
      perm_act_transitionsettings: false,
      perm_act_transitionsettings_a2krule: false,
      perm_act_uploaddocument: false,
      perm_act_fileupload: true,
      perm_act_sethighocrprio: true,
      perm_act_clonetoa2k: true,
      perm_act_a2klabel: false,
      perm_act_a2kadmin: false,
      perm_act_documentmetadata: false,
      perm_act_renamedocument: true,
      perm_act_deletedocument: true,
      perm_act_acceptdocument: false,
      perm_act_exportdeliverybible: false,
      perm_act_query_create: false,
      perm_act_query_edit: false,
      perm_act_query_edit_reported: true,
      perm_act_query_edit_assigned: true,
      perm_act_query_comment: false,
      perm_act_query_comment_assigned: false,
      perm_act_query_comment_reported: false,
      perm_act_query_attachement: true,
      perm_act_replacedocument: true,
      internal_external_flag: true,
      perm_act_resendtoocr: true,
      perm_act_areports_notes: false
    }
  },
  {
    roleName: RoleName.TechnicalSpecialist,
    accessRights: {
      perm_view_administration: false,
      perm_administration_users: false,
      perm_administration_assets: false,
      perm_administration_subscriber: false,
      perm_administration_notifications: false,
      perm_view_impstructure: true,
      perm_view_a2kstructure: true,
      perm_view_clientstructure: true,
      perm_view_mxHistoryStructure: true,
      perm_view_query: true,
      perm_view_query_internal: true,
      perm_view_areports: true,
      perm_view_areport_asset: true,
      perm_view_areport_processing: false,
      perm_view_creports: false,
      perm_view_document_internal: true,
      perm_view_document_external: true,
      perm_view_dashboard_ocrqueue: false,
      perm_act_transitionsettings: false,
      perm_act_transitionsettings_a2krule: false,
      perm_act_uploaddocument: false,
      perm_act_fileupload: true,
      perm_act_sethighocrprio: true,
      perm_act_clonetoa2k: true,
      perm_act_a2klabel: true,
      perm_act_a2kadmin: false,
      perm_act_documentmetadata: true,
      perm_act_renamedocument: true,
      perm_act_deletedocument: true,
      perm_act_acceptdocument: false,
      perm_act_exportdeliverybible: false,
      perm_act_query_create: true,
      perm_act_query_edit: true,
      perm_act_query_edit_reported: true,
      perm_act_query_edit_assigned: true,
      perm_act_query_comment: true,
      perm_act_query_comment_assigned: true,
      perm_act_query_comment_reported: true,
      perm_act_query_attachement: true,
      perm_act_replacedocument: true,
      internal_external_flag: true,
      perm_act_resendtoocr: true,
      perm_act_areports_notes: false
    }
  },
  {
    roleName: RoleName.KeyAccountManager,
    accessRights: {
      perm_view_administration: false,
      perm_administration_users: false,
      perm_administration_assets: false,
      perm_administration_subscriber: false,
      perm_administration_notifications: false,
      perm_view_impstructure: true,
      perm_view_a2kstructure: true,
      perm_view_clientstructure: true,
      perm_view_mxHistoryStructure: true,
      perm_view_query: true,
      perm_view_query_internal: true,
      perm_view_areports: true,
      perm_view_areport_asset: true,
      perm_view_areport_processing: false,
      perm_view_creports: false,
      perm_view_document_internal: true,
      perm_view_document_external: true,
      perm_view_dashboard_ocrqueue: false,
      perm_act_transitionsettings: false,
      perm_act_transitionsettings_a2krule: false,
      perm_act_uploaddocument: false,
      perm_act_fileupload: false,
      perm_act_sethighocrprio: false,
      perm_act_clonetoa2k: false,
      perm_act_a2klabel: false,
      perm_act_a2kadmin: false,
      perm_act_documentmetadata: false,
      perm_act_renamedocument: false,
      perm_act_deletedocument: false,
      perm_act_acceptdocument: false,
      perm_act_exportdeliverybible: false,
      perm_act_query_create: false,
      perm_act_query_edit: false,
      perm_act_query_edit_reported: true,
      perm_act_query_edit_assigned: true,
      perm_act_query_comment: false,
      perm_act_query_comment_assigned: true,
      perm_act_query_comment_reported: false,
      perm_act_query_attachement: true,
      perm_act_replacedocument: false,
      internal_external_flag: true,
      perm_act_resendtoocr: true,
      perm_act_areports_notes: false
    }
  },
  {
    roleName: RoleName.External,
    accessRights: {
      perm_view_administration: false,
      perm_administration_users: false,
      perm_administration_assets: false,
      perm_administration_subscriber: false,
      perm_administration_notifications: false,
      perm_view_impstructure: false,
      perm_view_a2kstructure: false,
      perm_view_clientstructure: true,
      perm_view_mxHistoryStructure: false,
      perm_view_query: true,
      perm_view_query_internal: false,
      perm_view_areports: false,
      perm_view_areport_asset: false,
      perm_view_areport_processing: false,
      perm_view_creports: false,
      perm_view_document_internal: false,
      perm_view_document_external: true,
      perm_view_dashboard_ocrqueue: false,
      perm_act_transitionsettings: false,
      perm_act_transitionsettings_a2krule: false,
      perm_act_uploaddocument: false,
      perm_act_fileupload: false,
      perm_act_sethighocrprio: false,
      perm_act_clonetoa2k: false,
      perm_act_a2klabel: false,
      perm_act_a2kadmin: false,
      perm_act_documentmetadata: false,
      perm_act_renamedocument: false,
      perm_act_deletedocument: false,
      perm_act_acceptdocument: true,
      perm_act_exportdeliverybible: false,
      perm_act_query_create: true,
      perm_act_query_edit: true,
      perm_act_query_edit_reported: true,
      perm_act_query_edit_assigned: true,
      perm_act_query_comment: true,
      perm_act_query_comment_assigned: true,
      perm_act_query_comment_reported: true,
      perm_act_query_attachement: true,
      perm_act_replacedocument: false,
      internal_external_flag: false,
      perm_act_resendtoocr: false,
      perm_act_areports_notes: false
    }
  }
]
