import { FC, useMemo } from 'react'
import { User, UserId } from 'types'
import { makeStyles, Typography } from '@material-ui/core'
import { useGetTransitionTeamQuery } from 'services/api/transitionApi'
import { useTransitionId } from 'context/TransitionContext'
import Dropdown from 'common/LisaControls/Dropdown'

const useStyles = makeStyles(() => ({
  dropdownText: {
    '& p': {
      fontSize: '16px',
      fontWeight: '600'
    }
  }
}))

type TransitionTeamItemProps = {
  user: User
}
export const TransitionTeamItem: FC<TransitionTeamItemProps> = ({ user }) => {
  const classes = useStyles()
  return (
    <div className={classes.dropdownText}>
      <Typography variant='body2'>{`${user.firstName} ${user.lastName}`}</Typography>
      <Typography variant='caption'>{user.email}</Typography>
    </div>
  )
}

type TransitionTeamSelectProps = {
  label: string
  userId: UserId
  onChange(userId: UserId): void
}
export const TransitionTeamSelect: FC<TransitionTeamSelectProps> = ({
  label, userId, onChange
}) => {
  const { data: team = [] } = useGetTransitionTeamQuery(useTransitionId())
  const items = useMemo(() => {
    return [...team.map((u) => ({
      value: u.userId,
      text: <TransitionTeamItem user={u}/>
    }))]
  }, [team])
  return (
    <Dropdown
      label={label}
      name="assignTo"
      items={items}
      value={userId}
      onChange={(e) => onChange(e.target.value as UserId)}
    />
  )
}
