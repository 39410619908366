import { FC } from 'react'
import { useGetDocumentTypesQuery } from 'services/api/documentApi'
import Dropdown, { DropdownProps } from 'common/LisaControls/Dropdown'

export type SelectOptions = {
  value: string
  text: string
  textSuffix?: string
}

export const DocumentTypeSelect: FC<DropdownProps> = (props) => {
  const { data: documentTypes = [] } = useGetDocumentTypesQuery()
  const documentTypeItems: SelectOptions[] = documentTypes
    .map(type => ({ value: type.id.toUpperCase(), text: type.name }))

  return (
    <Dropdown
      items={documentTypeItems}
      multiple
      showSelectOption={false}
      {...props}
    />
  )
}
