import { FC, useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { AuthProvider, AuthProviderProps, useAuth } from 'react-oidc-context'
import { WebStorageStateStore } from 'oidc-client-ts'
import './App.css'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme'
import Reports from './pages/Reports/ReportsPageContainer'
import Document from './pages/Document/Document'
import Compare from './pages/Compare/Compare'
import AlertMessage from 'common/AlertMessage/AlertMessage'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { TransitionRoutes } from 'routes/TransitionRoutes'
import { ErrorPage } from 'pages/ErrorPage/ErrorPage'
import { RoleName } from 'utils/userRoleSecurity'
import { SignInLoader, SignOutLoader } from 'common/Loaders/LisaLoader'
import { setAuthHeader } from 'utils/axiosHeaders'
import { DashboardRoutes } from 'routes/DashboardRoutes'
import { AdministrationRoutes } from 'routes/AdministrationRoutes'
import { PublicRoutes } from 'routes/PublicRoutes'
import { ClientSetupForm } from 'components/ClientSetupForm/ClientSetupForm'
import { useDispatch, useSelector } from 'react-redux'
import { endSession, openModal, useEndSessionFlag } from 'redux/slices/appSlice'
import { hasCompleteProfile } from 'utils/user'
import { useGetTenantQuery } from 'services/api/tenantApi'

const HOME_PAGE_ROUTES = ['/', '', '/signin-oidc']

const Main: FC = () => {
  const dispatch = useDispatch()
  const { hasRole, isLoading, user: lisaUser } = useLisaAuth()
  const hasRoleAdmin = hasRole(RoleName.Administrator) || hasRole(RoleName.TechAdmin)
  const endSessionFlag = useSelector(useEndSessionFlag)
  const {
    data: tenants = [],
    isFetching: fetchingTenant
  } = useGetTenantQuery(lisaUser?.tenantId ?? '', { skip: !lisaUser || !lisaUser.tenantId || !hasRoleAdmin })
  const [tenant] = tenants

  const auth = useAuth()
  const { user, signoutRedirect } = auth

  useEffect(() => {
    if (auth.isAuthenticated) {
      setAuthHeader(user?.access_token ?? null)
    }
  }, [auth.isAuthenticated])

  useEffect(() => {
    if (!lisaUser) {
      return
    }
    if (hasRoleAdmin) {
      if (fetchingTenant || !tenant) {
        return
      }
      if (!tenant.wasLogged) {
        return // Skip profile check if tenant hasn't logged in before
      }
    }
    if (!hasCompleteProfile(lisaUser)) {
      dispatch(openModal('EDIT_PROFILE'))
    }
  }, [lisaUser, fetchingTenant, tenant])

  useEffect(() => {
    if (endSessionFlag) {
      dispatch(endSession(false)) // maybe not needed. App state should reload anyway
      signoutRedirect().then()
    }
  }, [endSessionFlag])

  switch (auth.activeNavigator) {
  case 'signinSilent':
    return <SignInLoader/>
  case 'signoutRedirect':
    return <SignOutLoader/>
  }

  if (auth.isLoading || isLoading) {
    return <SignInLoader/>
  }

  if (auth.error) {
    console.error(auth.error)
  }

  if (!auth.isAuthenticated) {
    return <PublicRoutes/>
  }
  return (
    <>
      <Switch>
        <Route exact path={HOME_PAGE_ROUTES}>
          <Redirect to={'/dashboard'}/>
        </Route>
        <Route path="/structures/:page/:page?/:id" component={TransitionRoutes}/>
        <Route path="/dashboard/:page?" component={DashboardRoutes}/>
        <Route path="/administration/:page?" component={AdministrationRoutes}/>
        <Route exact path="/document/:id" component={Document}/>
        <Route exact path="/compare/:id/:compareId/:transitionId" component={Compare}/>
        <Route path="/reports" component={Reports}/>
        <Route exact path={'/error/code/:code'}>
          <ErrorPage/>
        </Route>
        <Route path="*">
          <ErrorPage/>
        </Route>
      </Switch>
      {hasRoleAdmin && <ClientSetupForm/>}
    </>
  )
}

const config: AuthProviderProps = {
  authority: process.env.REACT_APP_AUTHORITY!,
  client_id: process.env.REACT_APP_CLIENT_ID!,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI!,
  response_type: 'code',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  scope: 'openid profile roles identity.api lisa.api offline_access',
  post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  loadUserInfo: true,
  automaticSilentRenew: true
}

const App: FC = () => {
  const history = useHistory()
  return (
    <AuthProvider
      {...config}
      onSigninCallback={(user) => {
        const redirectUrl = (user?.state ?? '') as string
        if (redirectUrl && !HOME_PAGE_ROUTES.includes(redirectUrl)) {
          history.replace((user?.state ?? '') as string)
        } else {
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname
          )
        }
      }}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <AlertMessage/>
          <Main/>
        </div>
      </ThemeProvider>
    </AuthProvider>
  )
}

export default App
