import {
  A2KCondition,
  A2KConditionOperator,
  A2KLogicalOperator,
  A2KConditionType,
  SortDirection,
  DocumentId
} from 'types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import { rulesToUserFriendlyQuery } from 'utils/labellingRules'
import { nextSortDirection } from 'utils/filters'
import { A2KRuleDocument, A2KRulePreviewType } from 'services/api/labelRuleApi'
import { toggleDocumentsOnPage } from 'utils/documents'

export type A2KRuleDocumentsSortColumn = 'DocType' | 'Filename'

export interface A2KRuleFilters {
  page: number
  pageSize: number
  sortOrder: SortDirection
  sortColumn: A2KRuleDocumentsSortColumn
  previewType: A2KRulePreviewType
}
export interface A2KRuleSlice {
  ruleId?: number
  conditions: A2KCondition[]
  operators: A2KLogicalOperator[]
  currentRuleQuery: string
  selectedDocuments: DocumentId[]
  filters: A2KRuleFilters
}

const initialState: A2KRuleSlice = {
  conditions: [],
  operators: [],
  currentRuleQuery: '',
  selectedDocuments: [],
  filters: {
    page: 1,
    pageSize: 200,
    sortOrder: 'asc',
    sortColumn: 'Filename',
    previewType: A2KRulePreviewType.List
  }
}

const a2kRuleSlice = createSlice({
  name: 'a2kRuleSlice',
  initialState,
  reducers: {
    addNewCondition: (state) => {
      state.conditions.push({
        type: A2KConditionType.FILENAME,
        operator: A2KConditionOperator.EQUAL,
        value: ''
      })
      if (state.operators.length + 1 < state.conditions.length) {
        state.operators.push(A2KLogicalOperator.AND)
      }
    },
    removeCondition: (state, { payload }: PayloadAction<number>) => {
      state.conditions = state.conditions.filter((_, i) => i !== payload)
      state.operators = state.operators.filter((_, i) => i !== (payload - 1 < 0 ? 0 : payload - 1))
    },
    updateCondition: (state, { payload }: PayloadAction<{ condition: A2KCondition, index: number }>) => {
      const { index, condition } = payload
      const typeChanged = state.conditions[index]?.type !== condition.type
      state.conditions[index] = condition
      if (typeChanged) {
        state.conditions[index].value = ''
        state.conditions[index].operator = condition.type === A2KConditionType.DOCUMENT_TYPE
          ? A2KConditionOperator.IS
          : condition.type === A2KConditionType.CONTENT_TYPE ? A2KConditionOperator.CONTAINS : A2KConditionOperator.EQUAL
      }
    },
    updateOperator: (state, { payload }: PayloadAction<{ operator: A2KLogicalOperator, index: number }>) => {
      state.operators[payload.index] = payload.operator
    },
    setConditionsAndOperators: (state, { payload }: PayloadAction<{
      ruleId?: number
      conditions: A2KCondition[]
      operators: A2KLogicalOperator[]
    }>) => {
      state.ruleId = payload.ruleId
      state.conditions = payload.conditions
      state.operators = payload.operators
      state.currentRuleQuery = rulesToUserFriendlyQuery(state.conditions, state.operators)
    },
    updateCurrentRuleQuery: (state, { payload }: PayloadAction<string>) => { // @todo Probably not needed
      // state.currentRuleQuery = rulesToUserFriendlyQuery(state.conditions, state.operators)
      state.currentRuleQuery = payload
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.filters.page = payload
    },
    sortBy: (state, { payload }: PayloadAction<A2KRuleDocumentsSortColumn>) => {
      const {
        sortColumn,
        sortOrder
      } = state.filters
      state.filters.sortOrder = nextSortDirection(sortColumn === payload ? (sortOrder ?? false) : false)
      state.filters.sortColumn = payload
      state.filters.page = 1
    },
    setPreviewType: (state, { payload }: PayloadAction<A2KRulePreviewType>) => {
      state.filters.previewType = payload
    },
    clear: () => initialState,
    toggleAll: (state, { payload }: PayloadAction<A2KRuleDocument[]>) => {
      state.selectedDocuments = toggleDocumentsOnPage(payload.map(_ => _.documentId), state.selectedDocuments)
    },
    toggleDocument: (state, { payload }: PayloadAction<DocumentId>) => {
      if (state.selectedDocuments.includes(payload)) {
        state.selectedDocuments = state.selectedDocuments.filter(_ => _ !== payload)
      } else {
        state.selectedDocuments = [...state.selectedDocuments, payload]
      }
    }
  }
})

export const {
  addNewCondition,
  removeCondition,
  updateCondition,
  updateOperator,
  setConditionsAndOperators,
  updateCurrentRuleQuery,
  setPage,
  sortBy,
  setPreviewType,
  clear,
  toggleAll,
  toggleDocument
} = a2kRuleSlice.actions

export const useA2KRule = (state: RootState) => state.a2kRuleSlice

export default a2kRuleSlice.reducer
