import { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, makeStyles } from '@material-ui/core'
import {
  labelRemoved,
  reset as resetLabels,
  starStorageAssignment,
  startMultipleDocumentsAssignment,
  startSingleDocumentAssignment,
  useBulkAssignLabels
} from 'redux/slices/bulkAssignLabelsSlice'
import { LabelSearch } from 'common/LabelSearch/LabelSearch'
import { LabelTree } from 'common/LabelTree/LabelTree'
import { useLisaAuth } from 'hooks/useLisaAuth'
import {
  LabelId,
  LisaDocumentWithLabels,
  LisaResponseExtended,
  StorageId
} from 'types'
import { closeModal, openModal, openToast } from 'redux/slices/appSlice'
import { DocumentLink } from 'common/DocumentLink/DocumentLink'
import { LabelsProvider, LabelsType, useLabelsContext } from 'context/LabelsContext'
import { LisaModal } from 'common/Dialogs/LisaModal'
import {
  useBulkLabelAssignToDocumentsMutation,
  useBulkLabelsAssignToStructureMutation
} from 'services/api/labellingApi'
import { isCompleted } from 'utils/documents'
import { LabelsSection } from 'components/BulkLabelAssign/LabelsSection'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '32px'
  },
  selectedLabelContainer: {
    width: '100%',
    paddingBottom: '12px',
    '& .MuiChip-sizeSmall': {
      '&:hover': {
        background: theme.palette.common.white
      }
    },
    '& .MuiChip-deleteIcon': {
      '&:hover': {
        color: theme.palette.statusColors.red
      }
    }
  },
  searchFilter: {
    width: '100%',
    '& .fa-info-circle': {
      display: 'none'
    }
  },
  heading: {
    marginBottom: 32
  },
  actionButtons: {
    padding: '32px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  },
  labelListResults: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '400px',
    overflow: 'auto',
    borderRadius: '4px',
    marginTop: '16px',
    background: theme.palette.common.white
  }
}))

export const BulkLabelAssign: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { userId } = useLisaAuth()
  const {
    selected: selectedLabels,
    assignmentType,
    documents,
    document,
    storageId,
    labelsToRemove,
    assignedLabels
  } = useSelector(useBulkAssignLabels)
  const onClose = () => dispatch(closeModal(['ASSIGN_LABELS']))
  const { setRequiredLabels } = useLabelsContext()
  const showDocumentName = assignmentType === 'single_document'
  const documentIds = documents.map(_ => _.documentId)
  const isMultiDocumentAssignment = assignmentType === 'multiple_documents'
  const [bulkLabelsAssignToDocuments, { isLoading: isSavingForDocuments }] = useBulkLabelAssignToDocumentsMutation()
  const [bulkLabelsAssignToStructure, { isLoading: isSavingForStructure }] = useBulkLabelsAssignToStructureMutation()
  const isLoading = isSavingForStructure || isSavingForDocuments

  useEffect(() => () => {
    dispatch(resetLabels())
  }, [])

  const handleDocumentAssignmentResponse = useCallback(({ success, message }: LisaResponseExtended) => {
    if (!success && message) {
      dispatch(openToast({
        severity: 'error',
        message: message
      }))
    } else if (success) {
      dispatch(openToast({
        severity: 'info',
        message: 'Labels Successfully Changed'
      }))
    }
  }, [])

  const handleStorageAssignmentResponse = useCallback(({ message, success }: LisaResponseExtended) => {
    if (!success) {
      dispatch(openToast({
        severity: 'info',
        message: !message
          ? 'All documents have at least one label'
          : message
      }))
    } else {
      dispatch(openToast({
        severity: 'info',
        message: 'Labels Successfully Assigned to All Unlabelled Documents'
      }))
    }
  }, [])

  // handle wrong data; shouldn't happen; to prevent white pages
  if (assignmentType === 'single_document' && document === null) {
    return null
  }
  if (assignmentType === 'multiple_documents' && documentIds.length === 0) {
    return null
  }
  if (assignmentType === 'storage' && storageId === null) {
    return null
  }
  const labelsToRemoveIds = labelsToRemove.map(_ => _.labelId)

  const handleBulkAssign = async () => {
    const labelsToAdd = selectedLabels.map((label) => label.labelId)
    if (assignmentType === 'storage') {
      handleStorageAssignmentResponse(await bulkLabelsAssignToStructure({
        labels: labelsToAdd,
        userId: userId!,
        storageId: storageId!
      }).unwrap())
    } else {
      handleDocumentAssignmentResponse(await bulkLabelsAssignToDocuments({
        userId: userId!,
        documents: assignmentType === 'single_document' ? [document!.documentId] : documentIds,
        labelsToAdd,
        labelsToRemove: labelsToRemoveIds
      }).unwrap())
    }
    onClose()
  }

  const requiredLabels: LabelId[] = selectedLabels.length > 1 // If there are at least two labels then you can remove any of them
    ? []
    : (assignmentType === 'single_document' ? [document] : documents)
      .reduce<LabelId[]>((reqLabels, doc) => {
        if (doc === null || !isCompleted(doc)) {
          // We do not care for documents that are not reviewed nor accepted
          return reqLabels
        }
        const leftoverLabels = doc.labels.filter(({ labelId }) => !labelsToRemoveIds.includes(labelId))
        const hasJustOne = leftoverLabels.length === 1 && selectedLabels.length === 0
        const hasJustOneAndSameAsGlobalLabel = leftoverLabels.length === 1 && selectedLabels.length === 1 &&
          leftoverLabels[0].labelId === selectedLabels[0].labelId
        if (hasJustOne || hasJustOneAndSameAsGlobalLabel) {
          // If there is exactly one label then it is required
          return [...reqLabels, leftoverLabels[0].labelId]
        }
        if (leftoverLabels.length === 0 && selectedLabels.length === 1) {
          // If there are no labels in document and there is exactly one label in selected section that it is required
          return [...reqLabels, selectedLabels[0].labelId]
        }
        return reqLabels
      }, [])

  useEffect(() => {
    setRequiredLabels(requiredLabels)
  }, [requiredLabels.join()])

  return (
    <div className={classes.root}>
      <div className={classes.selectedLabelContainer}>
        {
          showDocumentName &&
          <DocumentLink className={classes.heading} document={document!} displayOnly/>
        }
        {
          isMultiDocumentAssignment &&
          <LabelsSection
            labels={selectedLabels}
            title={'Labels assigned to all selected documents'}
            requiredLabels={requiredLabels}
            onLabelRemove={(label) => {
              dispatch(labelRemoved(label))
            }}/>
        }
        {
          isMultiDocumentAssignment &&
          <LabelsSection
            labels={assignedLabels.filter((al) => !al.assignedToAll)}
            title={'Labels assigned to some of the selected documents'}
            requiredLabels={requiredLabels}
            onLabelRemove={(label) => {
              dispatch(labelRemoved(label))
            }}/>
        }
        {
          !isMultiDocumentAssignment && selectedLabels.length > 0 &&
          <LabelsSection
            labels={selectedLabels}
            title={'Selected Labels'}
            requiredLabels={requiredLabels}
            onLabelRemove={(label) => {
              dispatch(labelRemoved(label))
            }}/>
        }
      </div>
      <div className={classes.searchFilter}>
        <LabelSearch/>
      </div>
      <div className={classes.labelListResults}>
        <LabelTree/>
      </div>

      <div className={classes.actionButtons}>
        <Button
          disabled={isLoading}
          onClick={handleBulkAssign}
          variant="contained"
          color="primary"
          size="small">
          Save
        </Button>
        <Button
          disabled={isLoading}
          onClick={onClose}
          variant="contained"
          color="primary"
          size="small">
          Cancel
        </Button>
      </div>
    </div>
  )
}

export const BulkLabelAssignModal: FC = () => {
  const { title } = useSelector(useBulkAssignLabels)
  return (
    <LisaModal
      modalType={['ASSIGN_LABELS']}
      title={title ?? 'Assign Labels'}
      maxWidth="sm"
      scroll="body">
      <LabelsProvider labelsType={LabelsType.BulkAssign}>
        <BulkLabelAssign/>
      </LabelsProvider>
    </LisaModal>
  )
}

export const useBulkLabelAssignActions = () => {
  const dispatch = useDispatch()
  const { hasAccess } = useLisaAuth()
  const canTriggerBulkLabelAssign = hasAccess('perm_act_a2klabel')
  return {
    startDocumentAssign: (document: LisaDocumentWithLabels) => {
      if (canTriggerBulkLabelAssign) {
        dispatch(startSingleDocumentAssignment({ document }))
        dispatch(openModal('ASSIGN_LABELS'))
      }
    },
    startDocumentsAssign: (documents: LisaDocumentWithLabels[]) => {
      if (canTriggerBulkLabelAssign) {
        dispatch(startMultipleDocumentsAssignment({ documents }))
        dispatch(openModal('ASSIGN_LABELS'))
      }
    },
    startStorageAssign: (storageId: StorageId) => {
      if (canTriggerBulkLabelAssign) {
        dispatch(starStorageAssignment({ storageId }))
        dispatch(openModal('ASSIGN_LABELS'))
      }
    }
  }
}
