import { FC } from 'react'
import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  resetFilters: {
    minWidth: 'auto !important',
    background: 'transparent !important',
    color: `${theme.palette.blue.main} !important`,
    '&:hover': {
      background: `${theme.palette.lime.main} !important`,
      color: theme.palette.black.main
    }
  }
}))

type ResetFiltersButtonProps = {
  onClick: () => void
}

export const ResetFiltersButton: FC<ResetFiltersButtonProps> = ({ onClick }) => {
  const classes = useStyles()
  return (
    <Button
      fullWidth
      className={classes.resetFilters}
      style={{ fontWeight: 600 }} // fontWeight too restrictive. Fixed in v5
      variant="contained"
      color="primary"
      size="small"
      onClick={onClick}>
      Reset
    </Button>
  )
}
