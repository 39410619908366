import { A2KDocument, BusinessStatus, LisaDocumentWithLabels, PaginatedResults, SortDirection, User } from 'types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import { filterA2KDocuments, toggleDocumentsOnPage2 } from 'utils/documents'
import { nextSortDirection } from 'utils/filters'

export type A2KDocumentsSortColumns =
  'Name'
  | 'Query'
  | 'DocType'
  | 'BusinessStatus'
  | 'ImportFolder'
  | 'ProcessingStatus'

export const SortColumnToConfigMap: Map<A2KDocumentsSortColumns, keyof User['settings']['a2KSettings']> = new Map([
  ['Name', 'a2KListFilename'],
  ['DocType', 'a2KListDocumentType'],
  ['Query', 'a2KListQueries']
])

export interface A2KDocumentsFilters {
  businessStatus: BusinessStatus | 0
  page: number
  pageSize: number
  sortOrder: SortDirection
  processStatus: SortDirection
  sortColumn: A2KDocumentsSortColumns
}

export type A2KDocuments = PaginatedResults<A2KDocument>

export interface A2KDocumentsSlice {
  selectedDocuments: LisaDocumentWithLabels[]
  filters: A2KDocumentsFilters
}

export const initialState: A2KDocumentsSlice = {
  selectedDocuments: [],
  filters: {
    businessStatus: 0,
    page: 1,
    pageSize: 200,
    sortOrder: 'asc',
    processStatus: false,
    sortColumn: 'Name'
  }
}

const a2kDocumentsSlice = createSlice({
  name: 'a2kDocuments',
  initialState,
  reducers: {
    toggleAll: (state, { payload }: PayloadAction<A2KDocument[]>) => {
      state.selectedDocuments = toggleDocumentsOnPage2(
        filterA2KDocuments(payload, state.filters.businessStatus),
        state.selectedDocuments
      )
    },
    toggleDocument: (state, { payload }: PayloadAction<LisaDocumentWithLabels>) => {
      if (state.selectedDocuments.some((sd) => sd.documentId === payload.documentId)) {
        state.selectedDocuments = state.selectedDocuments.filter(_ => _.documentId !== payload.documentId)
      } else {
        state.selectedDocuments = [...state.selectedDocuments, payload]
      }
    },
    updateFilters: (state, { payload }: PayloadAction<Partial<A2KDocumentsFilters>>) => {
      state.filters = {
        ...state.filters,
        ...payload
      }
    },
    sortBy: (state, { payload }: PayloadAction<A2KDocumentsSortColumns>) => {
      const {
        sortColumn,
        sortOrder
      } = state.filters
      state.filters.sortOrder = nextSortDirection(sortColumn === payload ? (sortOrder ?? false) : false)
      state.filters.sortColumn = payload
      state.filters.page = 1
    },
    emptyDocuments: (state) => {
      state.selectedDocuments = []
    },
    resetSelectedDocuments: (state) => {
      state.selectedDocuments = []
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.filters.page = payload
    },
    updateSelectedDocuments: (state, { payload }: PayloadAction<A2KDocument[]>) => {
      state.selectedDocuments = state.selectedDocuments.map((sd) => {
        // @todo Might be expensive. Need to update selected documents after fetch
        return payload.find(_ => _.documentId === sd.documentId) ?? sd
      })
    }
  }
})

export const {
  toggleAll,
  toggleDocument,
  updateFilters,
  emptyDocuments,
  setPage,
  resetSelectedDocuments,
  sortBy,
  updateSelectedDocuments
} = a2kDocumentsSlice.actions

export const useA2KDocuments = (state: RootState) => state.a2kDocuments

export default a2kDocumentsSlice.reducer
