import { FC } from 'react'
import { DashboardSection } from './DashboardSection'
import { Query } from 'types'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { QueryItem } from 'common/Items/QueryItem'
import { useGetRecentQueriesQuery } from 'services/api/queryApi'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { isResolved } from 'utils/query'

export const RecentQueries: FC = () => {
  const history = useHistory()
  const { userId } = useLisaAuth()
  const { data: recentQueries = [], isLoading } = useGetRecentQueriesQuery(userId)

  const onClick = (query: Query) => {
    history.push({
      pathname: `/structures/queries/${query.transitionId}`,
      search: `?qId=${query.queryId}${isResolved(query) ? `&qs=${query.queryStatus}` : ''}`
    })
  }

  return (
    <DashboardSection
      title={'Recent queries'}
      loading={isLoading}
      hasResults={recentQueries.length > 0}
      type={'queries'}>
      <Grid container item spacing={1}>
        {
          recentQueries.map((query) => (
            <Grid key={query.queryId} item xs={6}>
              <QueryItem
                query={query}
                onClick={onClick}/>
            </Grid>
          ))
        }
      </Grid>
    </DashboardSection>
  )
}
