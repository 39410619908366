import { lisaApi } from 'services/api/lisaApi'
import { EventUuid, WorkorderState, WorkorderUuid, WorkpackageStatus, WorkpackageUuid } from 'types'

export const wowpApi = lisaApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkOrder: builder.query<{
      workorderState: WorkorderState
      ataChapter: string
      workorderType: string
      aircraftType: string
    }, WorkorderUuid>({
      query: (workorderUuid) => ({
        url: `workorder/${workorderUuid}`
      })
    }),
    getWorkPackage: builder.query<{
      status: WorkpackageStatus
      station: string
      startDateTime: string
      endDateTime: string
      description: string
    }, WorkpackageUuid>({
      query: (workpackageUuid) => ({
        url: `workpackage/${workpackageUuid}`
      })
    }),
    getEvent: builder.query<{
      eventState: string
      ataChapter: string
      reportBackMessage: string
      documentType: string
      documentSubType: string
      partNumber: string
      serialNumber: string
      closingDate: string
    }, EventUuid>({
      query: (eventId) => ({
        url: `event/${eventId}`
      })
    })
  })
})

export const {
  useGetWorkOrderQuery,
  useGetWorkPackageQuery,
  useGetEventQuery
} = wowpApi
