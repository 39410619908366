import { FC, KeyboardEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { LisaForm, useLisaForm } from 'common/Form/LisaForm'
import { useTransitionContext } from 'context/TransitionContext'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { hasAllowedCharacters, INVALID_FOLDER_NAME } from 'utils/validators'
import Input from 'common/LisaControls/Input'
import { useImportDocuments } from 'redux/slices/importDocumentsSlice'
import { useCreateFolderMutation } from 'services/api/importApi'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '32px',
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  paper: {
    padding: '24px'
  },
  sectionTitle: {
    fontSize: '14px',
    margin: '32px 0 6px 0',
    fontWeight: 700,
    letterSpacing: '.8px',
    textTransform: 'uppercase'
  },
  actionButtons: {
    padding: '32px 0',
    display: 'flex',
    // columnGap: "10px",
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  }
}))

interface FormValues {
  name: string
}
interface FormErrors {
  name: string
}
const initialFormValues: FormValues = {
  name: ''
}

export const CreateFolderModal: FC = () => {
  const classes = useStyles()
  const { selectedFolder } = useSelector(useImportDocuments)
  const { id: folderId } = selectedFolder!
  const { transitionId } = useTransitionContext()
  const dispatch = useDispatch()
  const [createFolder] = useCreateFolderMutation()

  const {
    values, handleInputChange
  } = useLisaForm<FormValues, FormErrors>(initialFormValues)

  const isEmpty = values.name.trim().length === 0
  const isNameValid = hasAllowedCharacters(values.name)
  const nameAlreadyExists = selectedFolder?.children.some(x => x.name === values.name)

  const nameError = isNameValid || isEmpty ? '' : INVALID_FOLDER_NAME
  const nameExistsError = nameAlreadyExists === true ? 'Folder with the same name already exists' : ''

  const close = () => dispatch(closeModal())

  const handleCreateNewFolder = async () => {
    const folderName = values.name.trim()

    if (isNameValid && !nameAlreadyExists) {
      const { message, success } = await createFolder({
        parentId: folderId!,
        name: folderName,
        transitionId
      }).unwrap()

      if (!success) {
        dispatch(openToast({
          severity: 'error',
          message: message!
        }))
      } else {
        close()
      }
    }
  }

  const handleKeyUp = async (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      await handleCreateNewFolder()
    }
  }

  return (
    <div className={classes.root}>
      <LisaForm onSubmit={e => { e.preventDefault() }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Input
              label="Folder name"
              name="name"
              required={true}
              value={values.name}
              onChange={handleInputChange}
              onKeyUp={handleKeyUp}
              errMsg={nameError !== '' ? nameError : (nameExistsError ?? '')}
            />
          </Grid>
        </Grid>
      </LisaForm>
      <div className={classes.actionButtons}>
        {
          <Button
            onClick={handleCreateNewFolder}
            variant="contained"
            color="primary"
            size="small"
            disabled={isEmpty || !isNameValid}>
            Create
          </Button>
        }
        <Button
          onClick={close}
          variant="contained"
          color="primary"
          size="small"
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}
