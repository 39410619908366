import { Label, LabelResponse, LoadingState } from 'types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createHierarchy, searchTree, selectLabelInTree } from 'utils/labels'
import { initialLabelsState, labelsReducers, LabelsReducers, LabelsState } from './labelsSlice'
import { RootState } from 'redux/store'

export interface ClientLabelsState extends LabelsState {
  selected: Label | null
}

export interface ClientLabelsReducers extends LabelsReducers {
}

export const initialState: ClientLabelsState = {
  ...initialLabelsState,
  selected: null,
  emptyLabels: true
}

const clientLabelsSlice = createSlice({
  name: 'clientLabels',
  initialState,
  reducers: {
    search: labelsReducers.search<ClientLabelsState>(),
    toggle: labelsReducers.toggle<ClientLabelsState>(),
    labelAdded: (state, { payload }: PayloadAction<Label>) => {
      if (payload.level! > 1) {
        state.hierarchy = selectLabelInTree(state.hierarchy!, payload)
        state.selected = payload
        state.searchResults = searchTree(state.hierarchy, state.searchTerm)
      }
    },
    reset: (state) => {
      if (state.hierarchy !== null) {
        if (state.selected !== null) {
          state.hierarchy = selectLabelInTree(state.hierarchy!, state.selected)
        }
        state.openedLabels = {
          [state.hierarchy.labelId]: true
        }
      }
      state.selected = null
      state.searchResults = []
      state.searchTerm = ''
    },
    toggleEmptyLabels: (state) => {
      state.emptyLabels = !state.emptyLabels
    },
    setHierarchy: (state, { payload }: PayloadAction<LabelResponse>) => {
      state.loadingHierarchy = LoadingState.Completed
      state.hierarchy = createHierarchy(payload)
      state.openedLabels[state.hierarchy.labelId] = true
      if (state.selected) {
        state.hierarchy = selectLabelInTree(state.hierarchy, state.selected)
      }
    }
  }
})

export const {
  search,
  toggle,
  reset,
  labelAdded,
  toggleEmptyLabels,
  setHierarchy
} = clientLabelsSlice.actions

export const clientLabelsReducers: ClientLabelsReducers = {
  search,
  toggle,
  labelAdded
}

export const useClientLabels = (state: RootState) => state.clientLabels

export default clientLabelsSlice.reducer
