import { FC, KeyboardEvent, useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import {
  OCRDocumentType,
  setLQThreshold,
  setPage,
  sortByOcrQuality,
  toggleDocumentIdForOCR,
  useOCRStatus
} from 'redux/slices/ocrStatusSlice'
import { useDispatch, useSelector } from 'react-redux'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import * as Icons from 'common/Icons/SvgIcons'
import { useTransitionId } from 'context/TransitionContext'
import { TableData } from 'common/TableData/TableData'
import { SelectAllCell } from './SelectAllCell'
import { SortByFileNameCell } from './SortCells'
import { DocumentLink } from 'common/DocumentLink/DocumentLink'
import { WithPagination } from 'common/WithPagination/WithPagination'
import { MaybeForbiddenAction } from 'common/MaybeForbiddenAction/MaybeForbiddenAction'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useGetOCRLowQualityDocumentsQuery, useResendToOcrMutation } from 'services/api/ocrApi'
import { openToast } from 'redux/slices/appSlice'
import Input from 'common/LisaControls/Input'

const useStyles = makeStyles((theme) => ({
  dataTableCheckbox: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.35rem'
    }
  },
  ocrInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& .MuiFormControl-root': {
      width: '55px !important',
      '& .MuiOutlinedInput-inputMarginDense': {
        textAlign: 'center'
      }
    }
  },
  bodyRows: {
    height: '42px'
  },
  cellFont: {
    fontSize: '16px',
    fontWeight: 600,
    paddingRight: '6px'
  },
  resendIcon: {
    display: 'flex',
    fontSize: '18px',
    fill: theme.palette.black.main,
    cursor: 'pointer',
    textAlign: 'center',
    justifyContent: 'center'
  },
  last: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

export const LowQualityDocuments: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { hasAccess } = useLisaAuth()
  const {
    documentIdsForOCR,
    filters
  } = useSelector(useOCRStatus)
  const {
    page, sortOrder, sortColumn, lqThreshold, ocrQuality
  } = filters[OCRDocumentType.LowQuality]
  const {
    data: lowQualityDocuments = {
      page: 1,
      pages: 0,
      pageSize: 15,
      rows: [],
      total: 0
    },
    isFetching
  } = useGetOCRLowQualityDocumentsQuery({
    transitionId: useTransitionId(),
    threshold: lqThreshold,
    page,
    sortOrder,
    sortColumn
  })
  const {
    rows: documents,
    total,
    pages,
    pageSize
  } = lowQualityDocuments
  const documentsLoaded = !isFetching
  const loadingDocuments = isFetching
  const rowCount = documents.length
  const [resendToOcr] = useResendToOcrMutation()

  const enterPressed = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.key === 'Enter') {
      dispatch(setLQThreshold(+(e.target as HTMLInputElement).value))
      // setLqThreshold(lqT)
    }
  }, [])

  return (
    <WithPagination
      options={{
        pageSize,
        total,
        selectedCounter: documentIdsForOCR.length
      }}
      page={page}
      pages={pages}
      setPage={(value: number) => dispatch(setPage(value))}>
      <TableData
        loading={loadingDocuments}
        headCells={[
          <SelectAllCell
            key={'checkbox'}
            documents={documents}
            documentIds={documents.map(_ => _.documentId)}/>,
          <SortByFileNameCell key={'fileName'} ocrDocumentType={OCRDocumentType.LowQuality}/>,
          <TableCell
            align={'right'}
            padding={'none'}
            sortDirection={filters[OCRDocumentType.LowQuality].ocrQuality}
            key={'ocrQuality'}>
            <div className={classes.ocrInput}>
              <div>
                <TableSortLabel
                  active={ocrQuality !== false}
                  direction={ocrQuality || 'asc'}
                  onClick={() => dispatch(sortByOcrQuality())}>
                  OCR QUALITY BELOW (%)
                </TableSortLabel>
              </div>
              <div>
                <Input
                  style={{ width: '2em' }}
                  label=""
                  name="threshold"
                  defaultValue={lqThreshold}
                  onKeyUp={enterPressed}
                />
              </div>
            </div>
          </TableCell>,
          <TableCell style={{ width: 50 }} key={'empty'}/>
        ]}
        tableRows={
          !documentsLoaded || rowCount === 0
            ? []
            : documents.map((row) => {
              const isItemSelected = documentIdsForOCR.includes(row.documentId)
              const labelId = `enhanced-table-checkbox-${row.documentId}`

              return (
                <TableRow
                  hover={!isItemSelected}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.documentId}
                  selected={isItemSelected}
                  className={classes.bodyRows}
                >
                  <TableCell
                    padding="checkbox"
                    onClick={() =>
                      dispatch(toggleDocumentIdForOCR(row.documentId))
                    }
                    style={{ width: '4%' }}
                  >
                    <Checkbox
                      className={classes.dataTableCheckbox}
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </TableCell>
                  <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    style={{ width: 'auto' }}>
                    <DocumentLink document={row} title={row.filePath}/>
                  </TableCell>
                  <TableCell align="right" style={{ width: '100px' }} padding="none">
                    <span className={classes.cellFont}>{row.quality}%</span>
                  </TableCell>
                  <TableCell style={{ width: '50px' }}>
                    <MaybeForbiddenAction
                      isForbidden={!hasAccess('perm_act_resendtoocr')}
                      tooltip={'Resend to OCR'}>
                      <div className={classes.last}>
                        <Icons.ResendDocument
                          className={classes.resendIcon}
                          onClick={async () => {
                            if (!hasAccess('perm_act_resendtoocr')) {
                              return
                            }
                            const { success } = await resendToOcr(row.documentId).unwrap()
                            if (success) {
                              dispatch(openToast({ severity: 'info', message: `${row.name} resent to OCR` }))
                            }
                          }}
                        />
                      </div>
                    </MaybeForbiddenAction>
                  </TableCell>
                </TableRow>
              )
            })
        }>
      </TableData>
    </WithPagination>
  )
}
