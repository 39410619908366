import { FC, ReactNode, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles, MenuItem } from '@material-ui/core'
import { RecursiveTreeView as TreeView } from './components/TreeView'
import { ImportDocumentsList } from './components/ImportDocumentList'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { useTransitionContext, useTransitionId } from 'context/TransitionContext'
import { LabelsProvider, LabelsType } from 'context/LabelsContext'
import { toggleShowOnlyUnlabelledFolders, useImportDocuments } from 'redux/slices/importDocumentsSlice'
import { ImportProgress } from './components/ImportProgress'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { MaybeForbiddenAction } from 'common/MaybeForbiddenAction/MaybeForbiddenAction'
import { ImportModals } from './components/ImportModals'
import { openConfirmDialog, openModal, openToast } from 'redux/slices/appSlice'
import { LisaPaper } from 'common/LisaPaper/LisaPaper'
import { GridGrow } from 'common/Grid/GridGrow'
import { WithDocumentsLoader, WithTransitionsLoader } from 'common/WithLoader/WithLoader'
import { SearchDocuments } from 'components/Transitions/SearchDocuments'
import { useHistory } from 'react-router-dom'
import { FileUploadProgress } from 'common/FileUploadProgress/FileUploadProgress'
import {
  useGetImportFileTreeQuery,
  useGetImportHeaderInProgressQuery,
  useStartImportFromOneDriveMutation
} from 'services/api/importApi'
import { useGetTransitionQuery } from 'services/api/transitionApi'
import { DocumentRemoval } from 'common/DocumentRemoval/DocumentRemoval'
import { ImportHeaderProgress } from './components/ImportHeaderProgress'

const useStyles = makeStyles(() => ({
  treeContainer: {
    height: 0,
    overflow: 'auto'
  }
}))

const Import: FC = () => {
  const classes = useStyles()
  const { transitionId } = useTransitionContext()
  const {
    selectedFolder,
    fileUpload,
    onlyUnlabelledFolders,
    currentImportHeaderInProgress
  } = useSelector(useImportDocuments)

  const { isLoading: isTransitionLoading, data: transition } = useGetTransitionQuery(useTransitionId())
  const { data: importHeaderInProgress } = useGetImportHeaderInProgressQuery({ id: currentImportHeaderInProgress! })

  const dispatch = useDispatch()
  const history = useHistory()
  const { hasAccess } = useLisaAuth()
  const [startImportFromOneDrive] = useStartImportFromOneDriveMutation()
  const isFolderSelected = selectedFolder !== null
  const {
    data: fileTree,
    isLoading
  } = useGetImportFileTreeQuery({ transitionId, unlabelled: onlyUnlabelledFolders })
  const isImportInProgress = transition?.importInProgress

  useEffect(() => {
    if (!hasAccess('perm_view_impstructure')) {
      history.push(hasAccess('perm_view_query') ? '/dashboard' : '/dashboard/my-transitions')
    }
  }, [])

  const handleUpdateStructure = async () => {
    if (fileTree) {
      const { success, message } = await startImportFromOneDrive({
        transitionId,
        path: `/${fileTree.name}`
      }).unwrap()
      if (!success && message) {
        dispatch(openToast({ severity: 'error', message }))
      } else {
        dispatch(openToast({
          severity: 'info',
          message: 'Update imports structure started.'
        }))
      }
    }
  }

  const menuItems = useMemo<ReactNode>(() => {
    if (isImportInProgress) {
      return ([
        <MenuItem key='update-import-structure' onClick={() => dispatch(openConfirmDialog('END_IMPORT_PROCESS'))}>
          End import process
        </MenuItem>
      ])
    }
    if (!fileTree) {
      return [
        <MaybeForbiddenAction key="on-drive-import" isForbidden={!hasAccess('perm_act_uploaddocument')}>
          <MenuItem onClick={() => dispatch(openModal('ONE_DRIVE_IMPORT'))}>
            Add structure from one drive
          </MenuItem>
        </MaybeForbiddenAction>
      ]
    }

    if (!isTransitionLoading) {
      return [
      transition!.initArchiveFinished === false &&
        <MenuItem key="update-import-structure" onClick={handleUpdateStructure}>
          Update import structure
        </MenuItem>,
      transition!.initArchiveFinished === false && <MenuItem key="init-archive-finished" onClick={() => dispatch(openConfirmDialog('INIT_ARCHIVE_FINISHED'))}>
        Complete initial archive
      </MenuItem>,
      <MaybeForbiddenAction key={'upload-files'} isForbidden={!hasAccess('perm_act_fileupload')}>
        <MenuItem onClick={() => dispatch(openModal('UPLOAD_FILES'))} disabled={!isFolderSelected || !fileTree.id || transition?.initArchiveFinished === null || transition?.initArchiveFinished === false}>
          Upload files
        </MenuItem>
      </MaybeForbiddenAction>,
      <MaybeForbiddenAction key={'upload-large-file'} isForbidden={!hasAccess('perm_act_fileupload')}>
        <MenuItem onClick={() => dispatch(openModal('UPLOAD_LARGE_FILE'))} disabled={!isFolderSelected || !fileTree.id || transition?.initArchiveFinished === false}>
        Upload large file (&gt;100MB)
        </MenuItem>
      </MaybeForbiddenAction>,
      <MaybeForbiddenAction key={'upload-folder'} isForbidden={!hasAccess('perm_act_fileupload')}>
        <MenuItem onClick={() => dispatch(openModal('UPLOAD_FOLDER'))} disabled={!isFolderSelected || !fileTree.id || transition?.initArchiveFinished === false}>
         Upload folder
        </MenuItem>
      </MaybeForbiddenAction>,
      <MenuItem key="create-folder" onClick={() => dispatch(openModal('CREATE_FOLDER'))} disabled={!isFolderSelected || !fileTree.id || transition?.initArchiveFinished === false}>
       Create folder
      </MenuItem>,
      <MenuItem key="rename-folder" onClick={() => dispatch(openModal('RENAME_FOLDER'))} disabled={!isFolderSelected || !fileTree.id || transition?.initArchiveFinished === false}>
        Rename folder
      </MenuItem>,
      <MaybeForbiddenAction key="delete-folder-action" isForbidden={!hasAccess('perm_act_uploaddocument')}>
        <MenuItem key="delete-folder" onClick={() => dispatch(openConfirmDialog('DELETE_FOLDER'))} disabled={!isFolderSelected || !fileTree.id || transition?.initArchiveFinished === false}>
        Delete folder
        </MenuItem>
      </MaybeForbiddenAction>,
      <MaybeForbiddenAction key="clone-a2k" isForbidden={!hasAccess('perm_act_clonetoa2k')}>
        <MenuItem onClick={() => dispatch(openModal('CLONE_TO_A2K'))} disabled={!isFolderSelected || !fileTree.id}>
        Clone selected to A2K
        </MenuItem>
      </MaybeForbiddenAction>,
      <MenuItem key="only-unlabelled" onClick={() => dispatch(toggleShowOnlyUnlabelledFolders())}>
        {!onlyUnlabelledFolders ? 'Show unlabelled only' : 'Show all folders'}
      </MenuItem>
      ]
    }
  }, [fileTree, isFolderSelected, isImportInProgress, onlyUnlabelledFolders, transition?.initArchiveFinished])

  return (
    <LabelsProvider labelsType={LabelsType.BulkAssign}>
      <ContentWrapper newLayout>
        <GridGrow container direction={'column'}>
          <Grid item container direction="row">
            <Grid item style={{ flex: 1 }}>
              <SearchDocuments/>
            </Grid>
            <Grid item>
              <DocumentRemoval />
            </Grid>
          </Grid>
          <WithTransitionsLoader loading={isTransitionLoading} hasResults={true}>
            <GridGrow container item className={'columnGap1'}>
              <Grid
                // Left Side
                item
                container
                direction="column"
                xs={5}
                lg={4}>
                <LisaPaper
                  direction={'column'}
                  toolbar={{
                    title: <span>Archive</span>,
                    items: menuItems
                  }}>
                  <Grid item container>
                    <ImportProgress transitionImportInProgress={transition?.importInProgress ?? false}/>
                    <ImportHeaderProgress importHeaderInProgress={importHeaderInProgress?.importInProgres ?? false}/>
                    <FileUploadProgress fileUpload={fileUpload}/>
                  </Grid>
                  <GridGrow item container className={classes.treeContainer}>
                    <WithDocumentsLoader
                      loading={isLoading}
                      loadingText={'Loading folder structure...'}
                      hasResults={!!fileTree}>
                      <TreeView/>
                    </WithDocumentsLoader>
                  </GridGrow>
                </LisaPaper>
              </Grid>
              <GridGrow
                // Right side
                item
                container
                xs={7}
                lg={8}>
                <ImportDocumentsList/>
              </GridGrow>
            </GridGrow>
          </WithTransitionsLoader>
        </GridGrow>
      </ContentWrapper>
      <ImportModals/>
    </LabelsProvider>
  )
}

export default Import
