import { FC, useCallback, useMemo, useState } from 'react'
import { Grid, GridProps, makeStyles } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import clsx from 'clsx'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useChangeTenantMutation } from 'services/api/usersApi'
import { useAuth } from 'react-oidc-context'
import { TenantId } from 'types'
import { useDashboardNavigator } from 'hooks/useDashboardNavigator'
import { useLocation } from 'react-router-dom'
import { inInTransition } from 'utils/router'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '56px',
    justifyContent: 'flex-start',
    background: theme.palette.blue.main,
    color: 'white',
    borderRight: '1px solid rgba(255,255,255,.2)'
  },
  logo: {
    display: 'flex',
    width: 56,
    minWidth: 56,
    height: 256,
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      width: 56,
      height: 56
    }
  },
  logoWide: {
    width: 220,
    '& .MuiSvgIcon-root': {
      width: 220
    }
  },
  tenant: {
    cursor: 'pointer',
    minHeight: 56,
    alignItems: 'center',
    fontSize: 24,
    padding: '5px 8px'
  },
  currentTenant: {
    fontSize: 24,
    fontWeight: 600
  },
  tenantXS: {
    fontSize: 16,
    paddingInline: 0
  },
  elipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flex: 1,
    textOverflow: 'ellipsis',
    lineHeight: 1,
    textAlign: 'center'
  },
  pointer: {
    cursor: 'default'
  }
}))

export interface TenantNameProps extends GridProps {
  name: string
  code: string
  tenantId: TenantId
  img?: string
  wide: boolean
  onSwitch?: (tenantId: TenantId) => void
}
export const TenantName: FC<TenantNameProps> = ({
  name,
  code,
  tenantId,
  img,
  wide,
  onSwitch,
  ...gridProps
}) => {
  const classes = useStyles()
  if (img) {
    return (
      <Grid item onClick={() => onSwitch && onSwitch(tenantId)} {...gridProps} style={{ flex: wide ? 1 : 'none' }}>
        <img style={{ maxWidth: wide ? 196 : 42, maxHeight: 42 }} src={img} alt={'tenant-img'}/>
      </Grid>
    )
  }

  return (
    <Grid item onClick={() => onSwitch && onSwitch(tenantId)} className={classes.elipsis} {...gridProps}>
      {wide ? name : code}
    </Grid>
  )
}

export interface TenantSwitchProps {
  wide?: boolean
}

export const TenantSwitch: FC<TenantSwitchProps> = ({ wide = true }) => {
  const classes = useStyles()
  const { currentTenant, tenants, user, isLoading } = useLisaAuth()
  const { signinSilent } = useAuth()
  const [expanded, setExpanded] = useState(false)
  const [changeTenant] = useChangeTenantMutation()
  const { goToDashboard } = useDashboardNavigator()
  const location = useLocation()
  const hasTenants = tenants.length > 0 && currentTenant
  const hasMoreThanOneTenant = hasTenants && tenants.length > 1
  const userTenants = user.tenants
  const open = user.settings.sidebarExpanded ?? true
  const tenant = userTenants.find(_ => _.tenantId === currentTenant)

  const height = useMemo(() => {
    if (tenants.length === 0 || !expanded) {
      return 56
    }
    return tenants.length * 56
  }, [tenants, expanded, open])

  const switchTenant = useCallback(async (tenantId: TenantId) => {
    await changeTenant(tenantId).unwrap()
    setExpanded(false)
    await signinSilent()
    if (inInTransition(location.pathname)) {
      goToDashboard()
    }
  }, [])

  if (isLoading) {
    return null
  }

  return (
    <div className={classes.root} style={{ height }}>
      {
        <Grid container direction={'column'}>
          <Grid
            className={clsx(classes.tenant, classes.currentTenant, {
              [classes.tenantXS]: !wide,
              [classes.pointer]: !hasMoreThanOneTenant
            })}
            item
            container
            justifyContent={wide ? 'space-between' : 'center'}
            onClick={() => setExpanded(!expanded)}>
            <TenantName
              tenantId={tenant?.tenantId ?? ''}
              name={tenant?.name ?? ''}
              code={tenant?.code ?? ''}
              wide={wide}
              img={wide ? tenant?.logo : tenant?.avatar}/>
            {
              hasMoreThanOneTenant && wide &&
              <Grid item>{expanded ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}</Grid>
            }
          </Grid>
          {
            userTenants.filter((t) => expanded && t.tenantId !== currentTenant)
              .map((tenant) => (
                <TenantName
                  item
                  container
                  justifyContent={wide ? 'flex-start' : 'center'}
                  tenantId={tenant.tenantId}
                  name={tenant.name}
                  code={tenant.code}
                  img={wide ? tenant.logo : tenant.avatar}
                  wide={wide}
                  onSwitch={switchTenant}
                  className={clsx(classes.tenant, { [classes.tenantXS]: !wide })}
                  key={tenant.code}/>
              ))
          }
        </Grid>
      }
    </div>
  )
}
