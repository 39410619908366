import { FC } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  paginationWrapper: {
    height: '100%',
    paddingInline: 16,
    zIndex: 2,
    boxShadow: '0 -3px 3px 0px rgb(0 0 0 / 5%)',
    '& .fa-check-square,.fa-list-ul': {
      fontSize: 14
    }
  },
  pagination: {
    marginTop: '8px',
    marginBottom: '8px',
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
      background: theme.palette.blue.main,
      '&:hover': {
        background: theme.palette.blue.main
      }
    },
    '& .MuiPaginationItem-page:hover': {
      background: theme.palette.grey2.main
    }
  },
  iconText: {
    fontWeight: 400,
    marginInline: 6,
    fontSize: 14
  }
}))

type WithPaginationProps = {
  page: number
  pages: number
  setPage(page: number): void
  options?: {
    pageSize?: number
    total?: number
    selectedCounter?: number
    onlyPages?: boolean
    alwaysShow?: boolean
  }
}
export const WithPagination: FC<WithPaginationProps> = ({
  page,
  pages,
  setPage,
  children,
  options = {}
}) => {
  const classes = useStyles()
  const { pageSize, selectedCounter, total, onlyPages = false, alwaysShow = false } = options
  return (
    <>
      {children}
      {
        (alwaysShow || pages > 1) &&
        <Grid
          item
          container
          alignItems={'center'}
          className={classes.paginationWrapper}>
          {
            !onlyPages &&
            <Grid item xs={2} container justifyContent={'flex-start'} alignItems={'center'}>
              {
                selectedCounter !== undefined &&
                <>
                  <span className="far fa-check-square"/>
                  <span className={classes.iconText}>{selectedCounter}</span>
                </>
              }
            </Grid>
          }
          <Grid item xs={onlyPages ? 12 : 8} container justifyContent={'center'}>
            {
              pages > 1 &&
              <Pagination
                className={classes.pagination}
                count={pages}
                siblingCount={0}
                page={page}
                color="primary"
                onChange={(event, value) => setPage(value)}
              />
            }
          </Grid>
          {
            !onlyPages &&
            <Grid item xs={2} container justifyContent={'flex-end'} alignItems={'center'}>
              {
                pageSize !== undefined && total !== undefined &&
                <>
                  <span className={classes.iconText}>
                    { (page - 1) * pageSize }-{page * pageSize > total ? total : page * pageSize}
                  </span>
                  <span className="fas fa-list-ul"/>
                </>
              }
            </Grid>
          }
        </Grid>
      }
    </>
  )
}
