import { Label, LabelId, LabelResponse, LoadingState, Nullable } from 'types'
import { ActionCreatorWithPayload, CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createHierarchy, getLabel, labelPathName, searchTree, selectLabelInTree } from 'utils/labels'
import { RootState } from 'redux/store'

export interface OpenedLabels {
  [key: LabelId]: boolean
}

export interface LabelsState {
  hierarchy: Label | null // Holds label structure
  searchResults: Label[] // Search results. Only level > 2 and matching searchTerm
  openedLabels: OpenedLabels // To preserve state of opened labels
  searchTerm: string
  loadingHierarchy: LoadingState
  disabled: LabelId[],
  emptyLabels: boolean
}

export interface LabelSearchArgs {
  search: Nullable<string | Label>
  minLevel?: number
}

export interface LabelsReducers {
  search: ActionCreatorWithPayload<LabelSearchArgs>
  toggle: ActionCreatorWithPayload<LabelId>
  labelAdded?: ActionCreatorWithPayload<Label>
  labelRemoved?: ActionCreatorWithPayload<Label>
}

export const initialLabelsState: LabelsState = {
  hierarchy: null,
  searchResults: [],
  openedLabels: {},
  searchTerm: '',
  loadingHierarchy: LoadingState.Init,
  disabled: [],
  emptyLabels: true
}

/**
 * Search labels in labels tree
 * if search is null then clear search term and search results
 * If search is string then perform recursive search down the tree
 * If search is label then set that label as result and its name as search term
 */
const searchFn = <T extends LabelsState>(): CaseReducer<T, PayloadAction<LabelSearchArgs>> => (state, { payload }) => {
  const { search, minLevel } = payload
  if (search === null) {
    state.searchTerm = ''
    state.searchResults = []
  } else if (typeof search === 'string') {
    state.searchTerm = search
    state.searchResults = searchTree(state.hierarchy, search, minLevel)
  } else {
    state.searchTerm = search.name
    state.searchResults = [search]
  }
}

/**
 * Open/Close label tree
 */
const toggleFn = <T extends LabelsState> (): CaseReducer<T, PayloadAction<LabelId>> => (state, { payload }: PayloadAction<LabelId>) => {
  state.openedLabels[payload] = !state.openedLabels[payload]
}

export const labelsReducers = {
  search: searchFn,
  toggle: toggleFn
}

// ** New Stuff ** All single label pickers should use this //
export interface LabelsSlice extends LabelsState {
  selected: Label | null // Single pick for now
}

const initialState: LabelsSlice = {
  ...initialLabelsState,
  selected: null
}
const labelsSlice = createSlice({
  name: 'labels',
  initialState,
  reducers: {
    search: searchFn<LabelsSlice>(),
    toggle: toggleFn<LabelsSlice>(),
    labelAdded: (state, { payload }: PayloadAction<Label>) => {
      state.hierarchy = selectLabelInTree(state.hierarchy!, payload, true)
      state.selected = {
        ...payload,
        isSelected: true
      }
      state.searchResults = searchTree(state.hierarchy, state.searchTerm)
    },
    labelRemoved: (state, { payload }: PayloadAction<Label>) => {
      state.hierarchy = selectLabelInTree(state.hierarchy!, payload, false)
      state.selected = null
      state.searchResults = searchTree(state.hierarchy, state.searchTerm)
    },
    reset: (state) => {
      state.selected = null
      state.searchTerm = ''
      state.searchResults = []
      state.hierarchy = selectLabelInTree(state.hierarchy!, state.selected, false)
      if (state.hierarchy) {
        state.openedLabels = {
          [state.hierarchy!.labelId]: true
        }
      }
    },
    setHierarchy: (state, { payload }: PayloadAction<LabelResponse>) => {
      state.loadingHierarchy = LoadingState.Completed
      state.hierarchy = createHierarchy(payload)
      state.searchResults = searchTree(state.hierarchy, state.searchTerm)
      state.openedLabels[state.hierarchy.labelId] = true // Root is always open
    }
  }
})

export const {
  search,
  toggle,
  labelAdded,
  labelRemoved,
  reset,
  setHierarchy
} = labelsSlice.actions

export const useLabels = (state: RootState) => state.labels

const lpnMap: Map<LabelId, string> = new Map()

export const useLabelsPath = (labelPath?: LabelId[], labelId?: LabelId) => (state: RootState) => {
  const existingLpn = lpnMap.get(labelId ?? 0)
  if (existingLpn) {
    return existingLpn
  }
  if (labelPath === undefined && labelId === undefined) {
    return ''
  }
  const { hierarchy } = state.labels
  if (hierarchy === null) {
    return ''
  }
  let lp: LabelId[] = []
  if (labelPath !== undefined) {
    lp = labelPath
  }
  if (labelPath === undefined && hierarchy && labelId !== undefined) {
    lp = getLabel(hierarchy, labelId)?.path ?? []
  }
  const lpn = labelPathName(state.labels.hierarchy, lp)
  if (labelId) {
    lpnMap.set(labelId, lpn)
  }
  return lpn
}

export const labelReducers: LabelsReducers = {
  search,
  toggle,
  labelAdded,
  labelRemoved
}

export default labelsSlice.reducer
