import { useAuth } from 'react-oidc-context'
import { AccessRights, Role, RoleName, Roles } from 'utils/userRoleSecurity'
import { useCallback, useMemo } from 'react'
import { TenantId, UserId } from 'types'
import { useGetUserQuery } from 'services/api/usersApi'

export const useLisaAuth = () => {
  const { user } = useAuth()
  const userId: UserId = user?.profile?.sub ?? ''
  const ur = (user?.profile?.role ?? []) as string | string[]
  const userRoles = Array.isArray(ur) ? ur : [ur]
  const roles: Role[] = useMemo(() => {
    if (user === null || ur.length === 0) {
      return []
    }
    return Roles.filter((role) => userRoles.includes(role.roleName) && role.accessRights
    )
  }, [userRoles.join()])
  let profileTenants = user?.profile?.tenants ?? []
  if (!Array.isArray(profileTenants)) {
    profileTenants = [profileTenants]
  }
  const tenants: TenantId[] = profileTenants as TenantId[]
  const currentTenant: TenantId = (user?.profile?.current_tenant ?? '') as TenantId

  const hasAccess = useCallback((perm: keyof AccessRights) => {
    return roles.some((role) => role.accessRights[perm])
  }, [roles])

  const hasRole = useCallback((roleName: RoleName) => {
    return roles.some((role) => role.roleName === roleName)
  }, [roles])

  const {
    data,
    refetch,
    isLoading
  } = useGetUserQuery(userId, { skip: !userId }) // Just for profile picture for now...

  const isExternal = user !== null && userRoles.includes(RoleName.External)
  return {
    userId,
    roles,
    hasAccess,
    hasRole,
    isExternal,
    user: data!,
    refetchUser: refetch,
    isLoading,
    currentTenant,
    tenants
  }
}
