import { FC, MouseEvent, useRef } from 'react'
import { Chip, makeStyles, Theme, Tooltip } from '@material-ui/core'
import { Label, Nullable } from 'types'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { LabelsType, useLabelsContext } from 'context/LabelsContext'
import clsx from 'clsx'
import { useTransitionContext } from 'context/TransitionContext'
import { updateFilters } from 'redux/slices/queriesSlice'
import { useDocumentLabels } from 'redux/slices/documentLabelsSlice'
import { OpenOrClosed } from 'common/Indicators/OpenOrClosed'
import useTooltipEllipsis from 'hooks/useTooltipEllipsis'
import { hasState, isOpenedState } from 'utils/labels'

type StylesProps = {
  canBeAdded: boolean,
  isFocused: boolean
  size: 'big' | 'small'
}
const useStyles = makeStyles<Theme, StylesProps>(({ palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderBottom: `1px solid ${palette.grey2.light100}`,
    fontSize: ({ size }) => size === 'big' ? 16 : 'inherit',
    height: 41
  },
  rootHover: {
    '&:hover': {
      backgroundColor: ({ canBeAdded }) => canBeAdded ? palette.blue.main : '#FFFFFF',
      color: ({ canBeAdded }) => canBeAdded ? '#FFFFFF' : 'inherit'
    }
  },
  focusable: {
    backgroundColor: ({ isFocused }) => isFocused ? palette.blue.main : '#FFFFFF',
    color: ({ isFocused }) => isFocused ? '#FFFFFF' : 'inherit',
    cursor: 'pointer'
  },
  focusableHover: {
    '&:hover': {
      backgroundColor: ({ isFocused }) => isFocused ? palette.blue.main : palette.grey2.light,
      color: ({ isFocused }) => isFocused ? '#FFFFFF' : 'inherit'
    }
  },
  toggleArea: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingBlock: 8,
    maxWidth: '84%',
    minWidth: 0
  },
  arrow: {
    fontSize: 10,
    textAlign: 'center'
  },
  rootLabel: {
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: '.1px',
    textTransform: 'uppercase'
  },
  commonLabel: {
    fontSize: 16,
    lineHeight: '2',
    marginRight: 4,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  rootLabelIcon: {
    fontSize: 14,
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: palette.black.main,
    backgroundColor: palette.grey2.light100,
    width: 35,
    height: 24,
    lineHeight: '24px',
    borderRadius: '50%',
    marginLeft: 10,
    marginRight: 8
  },
  commonLabelIcon: {
    color: palette.a2k.main,
    marginLeft: 10,
    marginRight: 5,
    fontSize: '1.25em',
    '& i': {
      width: 23
    }
  },
  clientLabelIcon: {
    color: palette.client.main
  },
  mxHistoryLabelIcon: {
    color: palette.mxHistory.main
  },
  actionWrapper: {
    marginLeft: 'auto',
    marginRight: 10,
    fontSize: 20,
    width: 20,
    borderRadius: '50%',
    textAlign: 'center',
    cursor: 'pointer',
    paddingBlock: 8
  },
  actionIconAdd: {
    color: '#FFFFFF',
    marginRight: 20,
    '&:hover': {
      color: palette.lime.main
    }
  },
  actionIconRemove: {
    color: palette.blue.main,
    marginRight: 20,
    '&:hover': {
      color: palette.statusColors.red
    }
  },
  countsWrapper: {
    marginLeft: 'auto',
    display: 'flex',
    paddingRight: '10px',
    alignItems: 'center'
  },
  fileNumber: {
    color: '#858585',
    display: 'flex',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    justifyContent: 'flex-end',
    marginRight: '5px',
    '& span': {
      marginLeft: '2px',
      letterSpacing: '-.4px',
      fontWeight: '400'
    }
  },
  queryCount: {
    minWidth: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiChip-root, .MuiChip-labelSmall': {
      cursor: 'pointer',
      '&.MuiChip-root:hover': {
        background: palette.lime.dark
      }
    }
  },
  disabled: {},
  ocIcon: {
    marginRight: 4
  },
  ruleDot: {
    height: '10px',
    width: '10px',
    background: palette.statusColors.blue,
    borderRadius: '50%',
    marginLeft: '10px'
  },
  defaultRule: {
    background: palette.statusColors.red
  }
}))

export type FolderType = 'root' | 'common'

export type FolderProps = {
  label: Label
  canBeOpened?: boolean
  a2kRules?: boolean
}
/**
 * @todo This one is doing to much. Consider splitting
 * @param label
 * @param canBeOpened
 * @constructor
 */
export const LabelFolder: FC<FolderProps> = ({
  label,
  canBeOpened = true,
  a2kRules = false
}) => {
  const {
    selector,
    reducers: { toggle },
    removeLabel,
    addLabel,
    labelsType,
    minLabelLevel,
    requiredLabels
  } = useLabelsContext()
  const dispatch = useDispatch()
  const history = useHistory()
  const { transitionId } = useTransitionContext()
  const canBeAdded = !label.isSelected && label.level! > minLabelLevel
  const shouldRenderOpenHandler = canBeOpened && label.children.length > 0
  const type: FolderType = label.level === 1 ? 'root' : 'common'
  const hasActions = ![LabelsType.A2K, LabelsType.Client, LabelsType.MxHistory].includes(labelsType)
  const isFocusable = !hasActions // Means it can be opened on click anywhere
  const isFocused = isFocusable && label.isSelected
  const classes = useStyles({ canBeAdded, isFocused, size: !hasActions ? 'big' : 'small' })
  const { openedLabels, disabled } = useSelector(selector)
  const isOpen = openedLabels[label.labelId] ?? false
  const isDisabled = disabled.includes(label.labelId)
  const level = canBeOpened ? label.level! : 1
  const labelTextRef = useRef(null)
  const hasTooltipActive = useTooltipEllipsis(labelTextRef)
  const { isScrolling } = useSelector(useDocumentLabels)

  let folderLabel = label.name
  let openIcon = <div className={'fas fa-sort-up'} style={{ opacity: 0 }}/>
  let labelIcon
  if (shouldRenderOpenHandler) {
    openIcon = <div data-testid='toggleHandler' className={`fas fa-sort-${isOpen ? 'up' : 'down'}`}/>
  }
  switch (type) {
  case 'root': {
    const [firstLetter, ...rest] = folderLabel.split(' ')
    labelIcon = <span data-testid='firstLetter'>{firstLetter}</span>
    folderLabel = rest.join(' ')
  }
    break
  case 'common':
  default:
    labelIcon = <i data-testid='labelIcon' className={isFocused ? 'fas fa-folder-open' : 'far fa-folder'}/>
    break
  }

  const ruleBadge = (indicator: Nullable<boolean>) => {
    if (indicator === null) return null
    return <Tooltip title={indicator ? 'Default Rule' : 'Asset Rule'} placement='top'><span className={clsx(classes.ruleDot, {
      [classes.defaultRule]: indicator
    })}/>
    </Tooltip>
  }

  const handleOnClick = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    const fromCLient = history.location.pathname.includes('structures/client')
    dispatch(updateFilters({ labelId: label.labelId.toString(), label: label.name, queryStatus: null, fromClientStructure: fromCLient }))
    history.push(`/structures/${'queries'}/${transitionId}`)
  }
  let actionIcon = null
  let actionClassName = [classes.actionWrapper]
  const sumQueries = label.queryCount! + label.closedQueryCount!
  if (!hasActions && label.level! >= minLabelLevel) {
    actionClassName = [classes.countsWrapper]
    actionIcon = (
      <>
        {
          label.documentCount! > 0 &&
          <div data-testid='documentsCount' className={classes.fileNumber}>{label.documentCount}<span>doc</span></div>
        }
        {
          sumQueries > 0 &&
          <div data-testid='queryCount' className={classes.queryCount} onClick={handleOnClick}><Chip size="small" label={label.queryCount! > 0 ? label.queryCount : ''} /></div>
        }
        {
          a2kRules && ruleBadge(label.ruleIndicator!)
        }
      </>
    )
  } else {
    if (label.isSelected && hasActions && !requiredLabels.includes(label.labelId)) {
      actionClassName.push(classes.actionIconRemove)
      actionIcon = <i data-testid={`remove_${label.name}`} className='fas fa-times-circle' onClick={() => {
        removeLabel(label)
      }}/>
    }
    if (canBeAdded) {
      actionClassName.push(classes.actionIconAdd)
      actionIcon = <i data-testid={`add_${label.name}`} className='fas fa-plus-circle' onClick={() => addLabel(label)}/>
    }
  }
  const rootClasses = [classes.root]
  if (isFocusable) {
    rootClasses.push(classes.focusable)
    rootClasses.push(classes.focusableHover)
  } else {
    rootClasses.push(classes.rootHover)
  }
  const onClick = () => {
    if (isFocusable) {
      dispatch(toggle(label.labelId))
      if (!isFocused) {
        addLabel(label)
      }
    }
  }

  return (
    <div
      className={rootClasses.join(' ')}
      onClick={onClick}>
      <div
        className={classes.toggleArea}
        style={{ paddingLeft: 10 + (level - 1) * 20 }}
        onClick={() => !isFocusable && dispatch(toggle(label.labelId))}>
        <div
          className={classes.arrow}
          style={{ marginTop: !isOpen ? '-4px' : '5px' }}>
          {openIcon}
        </div>
        <div
          className={clsx({
            [classes.commonLabelIcon]: type === 'common',
            [classes.clientLabelIcon]: type === 'common' && labelsType === LabelsType.Client,
            [classes.mxHistoryLabelIcon]: type === 'common' && labelsType === LabelsType.MxHistory,
            [classes.rootLabelIcon]: type !== 'common'
          })}>
          {labelIcon}
        </div>
        {
          (LabelsType.A2K === labelsType || LabelsType.MxHistory === labelsType) && hasState(label) &&
          <OpenOrClosed className={classes.ocIcon} state={isOpenedState(label) ? 'open' : 'closed'} variant={'small'}/>
        }
        <Tooltip title={isScrolling ? '' : folderLabel} placement="bottom-start" disableHoverListener={!hasTooltipActive}>
          <div ref={labelTextRef} className={type === 'common' ? classes.commonLabel : classes.rootLabel} data-testid='labelName'>
            {folderLabel}
          </div>
        </Tooltip>
      </div>
      {
        actionIcon && !isDisabled &&
        <div data-testid='actionButton' className={actionClassName.join(' ')}>
          {actionIcon}
        </div>
      }
    </div>
  )
}
