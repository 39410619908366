import { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateFilters, useQueries } from 'redux/slices/queriesSlice'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { QueriesFilter } from './components/QueriesFilter'
import { Grid, makeStyles } from '@material-ui/core'
import { QueriesList } from './components/QueriesList'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { QueryDetails } from './components/QueryDetails'
import { QueriesStatusReport } from './components/QueriesStatusReport'
import { filterToQueryParams } from 'utils/queries'
import { useSearchQueriesQuery } from 'services/api/queryApi'
import { useTransitionId } from 'context/TransitionContext'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useHistory, useLocation } from 'react-router-dom'
import { QueryStatus } from 'types'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  singleQueryTabs: {
    height: '56px',
    display: 'flex',
    width: '100%',
    borderRadius: '4px 4px 0px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    marginBottom: '6px',
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.blue.main,
      '&:hover': {
        color: theme.palette.blue.main
      },
      '&.Mui-selected': {
        color: theme.palette.blue.main,
        borderBottom: `6px solid ${theme.palette.blue.main}`
      }
    }
  },
  detailsContainer: {
    flexGrow: 1,
    backgroundColor: '#FFF',
    overflow: 'auto',
    height: 0 // to force vertical scroll on flexGrow
  },
  reportContainer: {
    marginTop: 16,
    height: 80,
    backgroundColor: theme.palette.common.white
  },
  mainSection: {
    flexGrow: 1,
    paddingTop: 16
  }
}))

export const Queries: FC = () => {
  const { showStatusReport } = useSelector(useQueries)
  const dispatch = useDispatch()
  const classes = useStyles()
  const transitionId = useTransitionId()
  const { filters } = useSelector(useQueries)
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const queryStatus = searchParams.get('qs')
  const _filters = { ...filters, queryStatus: queryStatus ? Number(queryStatus) as QueryStatus : filters.queryStatus }
  const history = useHistory()
  const { userId } = useLisaAuth()
  const {
    data: queriesSearch = [],
    isFetching: isSearching
  } = useSearchQueriesQuery(filterToQueryParams(_filters, transitionId, userId))
  const queries = queriesSearch

  useEffect(() => {
    if (queryStatus) {
      dispatch(updateFilters({ queryStatus: Number(queryStatus) as QueryStatus }))
      searchParams.delete('qs')
      history.replace({ search: searchParams.toString() })
    }
  }, [])

  return (
    <ContentWrapper newLayout>
      <Grid container className={classes.root} direction={'column'}>
        <Grid item>
          <QueriesFilter/>
        </Grid>
        <Grid item container className={classes.mainSection}>
          <Grid item container xs={5} lg={4}>
            <QueriesList queries={queries} isSearching={isSearching}/>
          </Grid>
          <Grid container item xs={7} lg={8} direction={'column'} >
            <Grid item className={classes.singleQueryTabs}>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="primary"
                centered>
                <Tab label="query" />
                <Tab label="history" />
              </Tabs>
            </Grid>
            <Grid item className={classes.detailsContainer}>
              <QueryDetails/>
            </Grid>
          </Grid>
        </Grid>
        {
          showStatusReport &&
          <Grid item className={classes.reportContainer}>
            <QueriesStatusReport queries={queries}/>
          </Grid>
        }
      </Grid>
    </ContentWrapper>
  )
}
