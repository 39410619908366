export const INVALID_CHARACTERS = '~ " \' # % & * : < > ? / \\ { | }.'

export const hasAllowedCharacters = (text: string): Boolean => {
  if (!text) {
    return true
  }
  return /^[^\\*/.:?"'<>{}#%~|&=]+$/.test(text)
}

export const INVALID_LABEL_NAME = `A label name can't contain any of the following characters: ${INVALID_CHARACTERS}`
export const INVALID_FILE_NAME = `A file name can't contain any of the following characters: ${INVALID_CHARACTERS}`
export const INVALID_FOLDER_NAME = `A folder name can't contain any of the following characters: ${INVALID_CHARACTERS}`
