import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import { DocumentId, LisaDocument, SortColumn, SortDirection } from 'types'
import { nextSortDirection } from 'utils/filters'
import { toggleDocumentsOnPage } from 'utils/documents'

export enum OCRDocumentType {
  LowQuality = 'lowQuality',
  Unprocessed = 'unprocessed',
  Unsupported = 'unsupported',
  Incomplete = 'incomplete'
}

export interface OCRStatusSlice {
  selectedView: OCRDocumentType
  documentIdsForOCR: DocumentId[]
  filters: {
    [OCRDocumentType.LowQuality]: {
      fileName: SortDirection
      ocrQuality: SortDirection
      lqThreshold: number
      page: number
      sortOrder: SortDirection
      sortColumn: SortColumn
    },
    [OCRDocumentType.Unsupported]: {
      fileName: SortDirection
      ocrStatus: SortDirection
    },
    [OCRDocumentType.Unprocessed]: {
      fileName: SortDirection
      ocrStatus: SortDirection
    },
    [OCRDocumentType.Incomplete]: {
      fileName: SortDirection
      ocrStatus: SortDirection
    },
  },
}

export const initialState: OCRStatusSlice = {
  selectedView: OCRDocumentType.Unprocessed,
  documentIdsForOCR: [],
  filters: {
    [OCRDocumentType.LowQuality]: {
      fileName: 'asc',
      ocrQuality: false,
      lqThreshold: 95,
      sortOrder: 'asc',
      sortColumn: 'Name',
      page: 1
    },
    [OCRDocumentType.Unprocessed]: {
      fileName: 'asc',
      ocrStatus: false
    },
    [OCRDocumentType.Unsupported]: {
      fileName: 'asc',
      ocrStatus: false
    },
    [OCRDocumentType.Incomplete]: {
      fileName: 'asc',
      ocrStatus: false
    }
  }
}

const ocrStatusSlice = createSlice({
  name: 'ocrStatus',
  initialState,
  reducers: {
    toggleDocumentIdForOCR: (state, { payload }: PayloadAction<DocumentId>) => {
      if (state.documentIdsForOCR.includes(payload)) {
        state.documentIdsForOCR = state.documentIdsForOCR.filter(_ => _ !== payload)
      } else {
        state.documentIdsForOCR = [...state.documentIdsForOCR, payload]
      }
    },
    toggleAllDocumentIdsToOCR: (state, { payload }: PayloadAction<LisaDocument[]>) => {
      state.documentIdsForOCR = toggleDocumentsOnPage(payload.map(_ => _.documentId), state.documentIdsForOCR)
    },
    sortByName: (state, { payload }: PayloadAction<OCRDocumentType>) => {
      state.filters[payload].fileName = nextSortDirection(state.filters[payload].fileName)
      if (payload === OCRDocumentType.LowQuality) {
        state.filters[OCRDocumentType.LowQuality].ocrQuality = false
        state.filters[OCRDocumentType.LowQuality].sortOrder = state.filters[payload].fileName
        state.filters[OCRDocumentType.LowQuality].sortColumn = 'Name'
        state.filters[OCRDocumentType.LowQuality].page = 1
      } else {
        state.filters[payload].ocrStatus = false
        state.filters[OCRDocumentType.LowQuality].sortOrder = state.filters[payload].fileName
        state.filters[OCRDocumentType.LowQuality].sortColumn = 'Name'
      }
    },
    sortByOcrQuality: (state) => {
      state.filters[OCRDocumentType.LowQuality].ocrQuality = nextSortDirection(state.filters[OCRDocumentType.LowQuality].ocrQuality)
      state.filters[OCRDocumentType.LowQuality].fileName = false
    },
    sortByOcrStatus: (state, { payload }: PayloadAction<OCRDocumentType.Unprocessed | OCRDocumentType.Unsupported | OCRDocumentType.Incomplete>) => {
      state.filters[payload].ocrStatus = nextSortDirection(state.filters[payload].ocrStatus)
      state.filters[payload].fileName = false
    },
    setLQThreshold: (state, { payload }: PayloadAction<number>) => {
      state.filters[OCRDocumentType.LowQuality].lqThreshold = payload
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.filters[OCRDocumentType.LowQuality].page = payload
    },
    resetSelectedDocuments: (state) => {
      state.documentIdsForOCR = []
    },
    selectView: (state, { payload }: PayloadAction<OCRDocumentType>) => {
      state.selectedView = payload
    }
  }
})

export const {
  toggleDocumentIdForOCR,
  toggleAllDocumentIdsToOCR,
  sortByOcrQuality,
  setLQThreshold,
  sortByOcrStatus,
  sortByName,
  setPage,
  resetSelectedDocuments,
  selectView
} = ocrStatusSlice.actions

export const useOCRStatus = (state: RootState) => state.ocrStatus

export default ocrStatusSlice.reducer
