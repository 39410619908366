import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sortBy, SortColumnToConfigMap, useDeletedDocuments } from 'redux/slices/deletedDocumentsSlice'
import { closeModal } from 'redux/slices/appSlice'
import {
  ColumnConfiguratorKey,
  deletedColumns,
  getCheckedColumnsFromCheckedColumnsSettings,
  mandatoryColumns
} from 'utils/columnConfigurator'
import { User } from 'types'
import { ColumnConfigurator } from 'common/ColumnConfigurator/ColumnConfigurator'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useUpdateUserSettingsMutation } from 'services/api/usersApi'

export const RecycleBinModals: FC = () => {
  const dispatch = useDispatch()
  const { user: { settings }, userId } = useLisaAuth()
  const {
    searchParams
  } = useSelector(useDeletedDocuments)
  const [updateUserSettings] = useUpdateUserSettingsMutation()

  return (
    <>
      {/* @todo Concidere to add confirmation modal for document recover */}
      <ColumnConfigurator
        modalType={'COLUMN_CONFIGURATOR_RECYCLE_BIN'}
        columns={deletedColumns}
        checkedColumns={getCheckedColumnsFromCheckedColumnsSettings(settings.recycleBinSettings)}
        mandatoryColumns={mandatoryColumns.get('recycleBin')}
        onSave={async (checkedColumns) => {
          const recycleBinSettings = Object.keys(settings.recycleBinSettings).reduce((rb, ccKey) => {
            return {
              ...rb,
              [ccKey]: checkedColumns.includes(ccKey as keyof User['settings']['recycleBinSettings'])
            }
          }, settings.recycleBinSettings)
          if (!checkedColumns.includes(SortColumnToConfigMap.get(searchParams.sortColumn ?? 'Name') as ColumnConfiguratorKey)) {
            // Sort by name if currently selected column is turned off
            dispatch(sortBy('Name'))
          }
          updateUserSettings({
            userId,
            settings: {
              recycleBinSettings
            }
          })
          dispatch(closeModal(['COLUMN_CONFIGURATOR_RECYCLE_BIN']))
        }}/>
    </>
  )
}
