import { FC, useCallback } from 'react'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { DocumentStatusChangeForm } from 'pages/Atok/components/DocumentStatusChangeForm'
import { sortBy, SortColumnToConfigMap, useMxHistoryDocuments } from 'redux/slices/mxHistoryDocumentsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useMxHistoryLabels } from 'redux/slices/mxHistoryLabelsSlice'
import { useBulkStatusUpdateMutation } from 'services/api/documentApi'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { ColumnConfigurator } from 'common/ColumnConfigurator/ColumnConfigurator'
import {
  a2kColumns,
  ColumnConfiguratorKey,
  getCheckedColumnsFromCheckedColumnsSettings,
  mandatoryColumns
} from 'utils/columnConfigurator'
import { User } from 'types'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useUpdateUserSettingsMutation } from 'services/api/usersApi'

export const MxHistoryModals: FC = () => {
  const { selectedDocuments, filters } = useSelector(useMxHistoryDocuments)
  const { selected: label } = useSelector(useMxHistoryLabels)
  const dispatch = useDispatch()
  const { user: { userId, settings } } = useLisaAuth()
  const areDocumentsSelected = selectedDocuments.length > 0
  const [bulkStatusUpdate] = useBulkStatusUpdateMutation()
  const [updateUserSettings] = useUpdateUserSettingsMutation()
  const bulkStatusUpdateCB = useCallback(async (params) => {
    const { success, message } = await bulkStatusUpdate(params).unwrap()
    if (!success && message) {
      dispatch(openToast({
        severity: 'error',
        message: message
      }))
    } else {
      dispatch(openToast({
        severity: 'info',
        message: 'Document status changes successfully applied'
      }))
    }
  }, [])
  return (
    <>
      <LisaModal
        title={`Change status of ${areDocumentsSelected ? 'selected' : 'all'} documents`}
        modalType={'DOCUMENT_STATUS_CHANGE'}
        scroll='body'>
        <DocumentStatusChangeForm
          label={label}
          documentIds={selectedDocuments.map(_ => _.documentId)}
          bulkStatusUpdate={bulkStatusUpdateCB}/>
      </LisaModal>
      <ColumnConfigurator
        columns={a2kColumns}
        checkedColumns={getCheckedColumnsFromCheckedColumnsSettings(settings.a2KSettings)}
        mandatoryColumns={mandatoryColumns.get('a2k')}
        onSave={async (checkedColumns) => {
          const a2KSettings = Object.keys(settings.a2KSettings).reduce((is, ccKey) => {
            return {
              ...is,
              [ccKey]: checkedColumns.includes(ccKey as keyof User['settings']['a2KSettings'])
            }
          }, settings.a2KSettings)
          if (!checkedColumns.includes(SortColumnToConfigMap.get(filters.sortColumn) as ColumnConfiguratorKey)) {
            // Sort by name if currently selected column is turned off
            dispatch(sortBy('Name'))
          }
          updateUserSettings({
            userId,
            settings: {
              a2KSettings
            }
          })
          dispatch(closeModal())
        }}/>
    </>
  )
}
