import {
  A2KCondition as A2KConditionInterface,
  A2KConditionOperator,
  A2KConditionType
} from 'types'
import { Grid, makeStyles, TextField } from '@material-ui/core'
import Dropdown from 'common/LisaControls/Dropdown'
import { DocumentTypeSelect } from 'common/Select/DocumentTypeSelect'
import { useGetDocumentTypesQuery } from 'services/api/documentApi'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { getIdForDocumentTypeCode, getCodeForDocumentTypeId } from 'utils/documentTypes'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey2.light100,
    padding: '10px 6px 10px 10px',
    borderRadius: 5
  },
  dropDown: {
    height: 36
  },
  textField: {
    height: 36
  },
  closeButtonContainer: {
    paddingLeft: 4,
    paddingTop: 4
  }
}))

const conditionTypeItems = [
  {
    text: 'File Name',
    value: A2KConditionType.FILENAME
  },
  {
    text: 'Document Type',
    value: A2KConditionType.DOCUMENT_TYPE
  },
  {
    text: 'Content',
    value: A2KConditionType.CONTENT_TYPE
  }
]

const fileNameOperators = [
  {
    text: 'Equal',
    value: A2KConditionOperator.EQUAL
  },
  {
    text: 'Contains',
    value: A2KConditionOperator.CONTAINS
  },
  {
    text: 'Starts with',
    value: A2KConditionOperator.STARTS_WITH
  },
  {
    text: 'Ends with',
    value: A2KConditionOperator.ENDS_WITH
  },
  {
    text: 'Doesn\'t contains',
    value: A2KConditionOperator.DOES_NOT_CONTAINS
  }
]

const documentTypeOperators = [
  {
    text: 'Is',
    value: A2KConditionOperator.IS
  },
  {
    text: 'Is Not',
    value: A2KConditionOperator.IS_NOT
  }
]

export interface A2KConditionProps {
  condition: A2KConditionInterface
  onClose: () => void
  onUpdate: (condition: A2KConditionInterface) => void
}

export const A2KCondition = ({ condition, onClose, onUpdate }: A2KConditionProps) => {
  const classes = useStyles()

  const { data: documentTypes = [] } = useGetDocumentTypesQuery()
  const {
    type,
    value,
    operator
  } = condition

  const updateCb = (key: keyof typeof condition, value: any) => onUpdate({ ...condition, [key]: value })

  const chooseA2kCondition = () => {
    let res
    switch (type) {
    case A2KConditionType.FILENAME:
      res = fileNameOperators
      break
    case A2KConditionType.DOCUMENT_TYPE:
      res = documentTypeOperators
      break
    case A2KConditionType.CONTENT_TYPE:
      res = fileNameOperators.filter(op => op.value !== A2KConditionOperator.EQUAL)
      break
    default:
      res = fileNameOperators
      break
    }
    return res
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs>
        <Grid item container spacing={1}>
          <Grid item container spacing={1}>
            <Grid item xs>
              <Dropdown
                className={classes.dropDown}
                showSelectOption={false}
                value={type}
                onChange={(e) => updateCb('type', e.target.value)}
                items={conditionTypeItems}/>
            </Grid>
            <Grid item xs>
              <Dropdown
                className={classes.dropDown}
                showSelectOption={false}
                value={operator}
                onChange={(e) => updateCb('operator', e.target.value)}
                items={chooseA2kCondition()}/>
            </Grid>
          </Grid>
          <Grid item xs>
            {
              type === A2KConditionType.FILENAME &&
              <TextField
                value={value}
                fullWidth
                className={classes.textField}
                onChange={(e) => updateCb('value', e.target.value)}/>
            }
            {
              type === A2KConditionType.DOCUMENT_TYPE &&
              <DocumentTypeSelect
                className={classes.dropDown}
                value={getIdForDocumentTypeCode(documentTypes, value)}
                documentTypes={documentTypes}
                noLabel
                onChange={(documentTypeId) => {
                  const documentTypeName = getCodeForDocumentTypeId(documentTypes, documentTypeId)
                  updateCb('value', documentTypeName)
                }}/>
            }
            {
              type === A2KConditionType.CONTENT_TYPE &&
              <TextField
                value={value}
                fullWidth
                className={classes.textField}
                onChange={(e) => updateCb('value', e.target.value)}/>
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.closeButtonContainer}>
        <IconButton aria-label="close" size={'small'} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
