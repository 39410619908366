import { createContext, FC, useContext, useState } from 'react'
import { RootState } from 'redux/store'
import { documentLabelsReducers, useDocumentLabels } from 'redux/slices/documentLabelsSlice'
import { Selector } from '@reduxjs/toolkit'
import { queryLabelsReducers, useQueryLabels } from 'redux/slices/queryLabelsSlice'
import { labelReducers, LabelsReducers, LabelsState, useLabels } from 'redux/slices/labelsSlice'
import { Label, LabelId } from 'types'
import { useDispatch } from 'react-redux'
import { bulkAssignLabelsReducers, useBulkAssignLabels } from 'redux/slices/bulkAssignLabelsSlice'
import { a2kLabelsReducers, useA2KLabels } from 'redux/slices/a2kLabelsSlice'
import { clientLabelsReducers, useClientLabels } from 'redux/slices/clientLabelsSlice'
import { reassignLabelsReducers, useReassignLabels } from 'redux/slices/reassignLabelsSlice'
import { mxHistoryLabelsReducers, useMxHistoryLabels } from 'redux/slices/mxHistoryLabelsSlice'

export enum LabelsType {
  Document = 'DOCUMENT',
  BulkAssign = 'BULK_ASSIGN',
  A2K = 'A2K',
  Client = 'Client',
  MxHistory = 'MxHistory',
  Transition = 'Transition', // Transition labels. Used in Query form, labels delete form and when cloning import -> a2k
  Query = 'QUERY', // @deprecated
  LabelReassign = 'LABEL_REASSIGN' // @deprecated
}

export interface Labels {
  selector: Selector<RootState, LabelsState>
  reducers: LabelsReducers
  addLabel: (label: Label) => void
  removeLabel: (label: Label) => void
  labelsType: LabelsType
  minLabelLevel: number
  requiredLabels: LabelId[]
  setRequiredLabels: (labelIds: LabelId[]) => void
}

const DEFAULT_LABELS: Labels = {
  selector: useDocumentLabels,
  reducers: documentLabelsReducers,
  addLabel: () => {},
  removeLabel: () => {},
  labelsType: LabelsType.Document,
  minLabelLevel: 2,
  requiredLabels: [],
  setRequiredLabels: () => null
}

export const LabelsContext = createContext<Labels>(DEFAULT_LABELS)

export type LabelsProviderProps = {
  labelsType: LabelsType
  onLabelAdd?: (label: Label) => void
  onLabelRemove?: (label: Label) => void
}
export const LabelsProvider: FC<LabelsProviderProps> = ({
  labelsType,
  onLabelAdd,
  onLabelRemove,
  children
}) => {
  const dispatch = useDispatch()
  const [requiredLabels, setReqLabels] = useState<LabelId[]>([])
  const value: Labels = {
    ...DEFAULT_LABELS,
    labelsType
  }
  switch (labelsType) {
  case LabelsType.Query:
    value.selector = useQueryLabels
    value.reducers = queryLabelsReducers
    value.minLabelLevel = 1
    break
  case LabelsType.BulkAssign:
    value.selector = useBulkAssignLabels
    value.reducers = bulkAssignLabelsReducers
    value.minLabelLevel = 1
    break
  case LabelsType.A2K:
    value.selector = useA2KLabels
    value.reducers = a2kLabelsReducers
    break
  case LabelsType.Client:
    value.selector = useClientLabels
    value.reducers = clientLabelsReducers
    break
  case LabelsType.MxHistory:
    value.selector = useMxHistoryLabels
    value.reducers = mxHistoryLabelsReducers
    break
  case LabelsType.LabelReassign:
    value.selector = useReassignLabels
    value.reducers = reassignLabelsReducers
    break
  case LabelsType.Transition:
    value.selector = useLabels
    value.reducers = labelReducers
    value.minLabelLevel = 0
    break
  case LabelsType.Document:
  default:
    value.selector = useDocumentLabels
    value.reducers = documentLabelsReducers
    value.minLabelLevel = 1
    break
  }
  // @todo Adding and removing label code needs improvements
  value.addLabel = (label: Label) => {
    onLabelAdd && onLabelAdd(label)
    if (value.reducers.labelAdded) {
      dispatch(value.reducers.labelAdded(label))
    }
  }
  value.removeLabel = (label: Label) => {
    onLabelRemove && onLabelRemove(label)
    if (value.reducers.labelRemoved) {
      dispatch(value.reducers.labelRemoved(label))
    }
  }
  value.requiredLabels = requiredLabels
  value.setRequiredLabels = (labelIds) => setReqLabels(labelIds)
  return (
    <LabelsContext.Provider value={value}>
      {children}
    </LabelsContext.Provider>
  )
}

export const useLabelsContext = () => useContext(LabelsContext)
