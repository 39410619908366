import { FC, useEffect } from 'react'
import { Label } from 'types'
import { LabelSearch } from 'common/LabelSearch/LabelSearch'
import { LabelTree } from 'common/LabelTree/LabelTree'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { PickedLabels } from './PickedLabels'
import { useDispatch, useSelector } from 'react-redux'
import { reset, useLabels } from 'redux/slices/labelsSlice'

const useStyles = makeStyles((theme) => ({
  searchFilter: {
    width: '100%',
    '& .fa-info-circle': {
      display: 'none'
    }
  },
  labelListResults: {
    flexWrap: 'wrap',
    height: 400,
    overflow: 'auto',
    borderRadius: 4,
    background: theme.palette.common.white,
    maxWidth: '576px'
  },
  label: {
    fontWeight: 600,
    fontSize: 18
  },
  pickedLabels: {
    paddingBlock: 5
    // height: 40
  }
}))

type LabelPickerProps = {
  onChange?: (label: Label | null) => void,
  label?: string
}
export const LabelPicker: FC<LabelPickerProps> = ({ onChange = () => {}, label }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { selected } = useSelector(useLabels)

  useEffect(() => {
    onChange(selected)
  }, [selected?.labelId])

  useEffect(() => {
    dispatch(reset())
  }, [])

  return (
    <Grid item container direction={'column'}>
      {label && <Typography className={classes.label} variant={'body1'}>{label}</Typography>}
      <Grid item className={classes.pickedLabels}>
        <PickedLabels/>
      </Grid>
      <Grid item className={classes.searchFilter}>
        <LabelSearch/>
      </Grid>
      <Grid item className={classes.labelListResults}>
        <LabelTree />
      </Grid>
    </Grid>
  )
}
