import { ChangeEvent, FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ProjectsHeadMenu } from './ProjectsHeadMenu'
import { TransitionsDataTable } from './TransitionsDataTable'
import { TransitionForm } from './TransitionForm'
import { ProjectId } from 'types'
import { useProjects } from 'redux/slices/projectsSlice'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { useGetAllProjectsQuery, useGetAllProjectsSearchQuery } from 'services/api/projectsApi'
import { WithDocumentsLoader } from 'common/WithLoader/WithLoader'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiAccordion-root': {
      marginBottom: '8px',
      '&:before': {
        display: 'none'
      },
      '&.Mui-expanded': {
        margin: '32px 0',
        boxShadow: '0 0 24px 0 rgba(0,0,0,.08)',
        '&:first-child': {
          margin: '0 0 24px 0'
        }
      },
      '& .MuiAccordionSummary-root': {
        padding: '2px 16px 2px 6px',
        flexDirection: 'row-reverse',
        '&.Mui-expanded': {
          minHeight: '0px !important',
          borderBottom: `3px solid ${theme.palette.lime.main}`,
          borderRadius: '4px 4px 0 0'
        }
      },
      '& .MuiAccordionSummary-content': {
        justifyContent: 'space-between',
        alignItems: 'center',
        '&.Mui-expanded': {
          margin: '12px 0',
          minHeight: '0px'
        }
      }
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px 0px'
    }
  },
  projectName: {
    fontSize: '18px',
    marginLeft: '12px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    '& .transitionCount': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '13px',
      lineHeight: '0',
      height: '18px',
      minWidth: '18px',
      fontWeight: '600',
      color: theme.palette.black.main,
      padding: '4px',
      background: theme.palette.lime.main,
      borderRadius: '4px',
      marginLeft: '6px'

    }
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  projectAccordion: {
    borderRadius: '4px !important',
    border: 'none !important',
    margin: '0',
    '&:hover': {
      background: theme.palette.grey2.light
    }
  }
}))

export const Projects: FC = () => {
  const classes = useStyles()
  const [projectId, setProjectId] = useState<ProjectId>('')
  const [expanded, setExpanded] = useState<string | false>(false)
  const { searchTerm } = useSelector(useProjects)
  const { data: allProjects = [], isFetching } = useGetAllProjectsQuery(undefined, { skip: !!searchTerm })
  const { data: searchProjects = [], isFetching: isSearching } = useGetAllProjectsSearchQuery(searchTerm, { skip: !searchTerm })
  const projects = !searchTerm ? allProjects : searchProjects
  const isLoading = isFetching || isSearching
  const handleChange = (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setProjectId(panel)
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      <WithDocumentsLoader
        loading={isLoading}
        hasResults={projects.length > 0}
        loadingText={'Loading entities...'}
        noResultsProps={{ message: 'No entities' }}>
        {projects?.map((project) => (
          <Accordion
            TransitionProps={{ unmountOnExit: true, appear: true }}
            className={classes.projectAccordion}
            key={project.projectId}
            expanded={expanded === project.projectId}
            onChange={handleChange(project.projectId)}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${project.projectId}bh-content`}
              id={`${project.projectId}bh-header`}>
              <span className={classes.projectName}>{project.name}
                <span className={'transitionCount'}>{project.transitionCount}</span>
              </span>
              <ProjectsHeadMenu project={project}/>
            </AccordionSummary>
            <AccordionDetails>
              <TransitionsDataTable
                project={project}/>
            </AccordionDetails>
          </Accordion>
        ))}
        <LisaModal
          title={'Add new Asset'}
          scroll={'body'}
          modalType={'TRANSITION_FORM'}>
          <TransitionForm
            projectId={projectId}
          />
        </LisaModal>
      </WithDocumentsLoader>
    </div>
  )
}

export default Projects
