import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FileTree,
  FileUpload,
  FolderId,
  ImportDocument, LisaDocumentWithLabels,
  LoadingState,
  SortDirection,
  StorageId,
  TransitionId, User
} from 'types'
import lisaApi from 'api/lisaApi'
import { lisaApi as apiRTK } from 'services/api/lisaApi'
import { RootState } from 'redux/store'
import { nextSortDirection } from 'utils/filters'
import { toggleDocumentsOnPage2 } from 'utils/documents'
import { openToast } from './appSlice'

export type ImportDocumentsSortColumns =
  'Name'
  | 'OcrPriority'
  | 'ProcessingStatus'
  | 'DocType'
  | 'ImportedBy'
  | 'ImportDate'
  | 'OcrStatus'

export const SortColumnToConfigMap: Map<ImportDocumentsSortColumns, keyof User['settings']['importSettings']> = new Map([
  ['Name', 'importListFilename'],
  ['DocType', 'importListDocumentType'],
  ['ProcessingStatus', 'importListProcessingStatus'],
  ['ImportDate', 'importListImportDate'],
  ['ImportedBy', 'importListImportedBy'],
  ['OcrPriority', 'importListProcessingPriority']
])

export interface ImportDocumentsSlice {
  selectedDocuments: LisaDocumentWithLabels[]
  filters: {
    ocrStatus: number[]
    pageSize: number
    page: number
    unlabelled: boolean
    deleted: boolean
    sortOrder: SortDirection
    sortColumn: ImportDocumentsSortColumns
  },
  importFolderId: StorageId
  selectedFolder: FileTree | null
  fileUpload: FileUpload
  folderState: FolderId[]
  onlyUnlabelledFolders: boolean
  currentImportHeaderInProgress: string | null
}

export const initialState: ImportDocumentsSlice = {
  selectedDocuments: [],
  filters: {
    ocrStatus: [],
    page: 1,
    pageSize: 200,
    unlabelled: false,
    deleted: false,
    sortOrder: 'asc',
    sortColumn: 'Name'
  },
  importFolderId: '',
  selectedFolder: null,
  fileUpload: {
    isError: false,
    loading: LoadingState.Init,
    uploadPercent: 0
  },
  folderState: [],
  onlyUnlabelledFolders: false,
  currentImportHeaderInProgress: null
}

interface UploadFileParams {
  files: File[]
  folderId: StorageId
  storageId: StorageId
  transitionId: TransitionId
}

interface UploadFolderParams {
  files: File[]
  folderId: StorageId
  storageId: StorageId
  transitionId: TransitionId
  importHeaderId: string
}

interface UploadLargeFileParams {
  file: any
  folderId: StorageId
  transitionId: TransitionId
}

export const uploadFiles = createAsyncThunk( // RTK does not provide upload progress. Using axios for now
  'importDocuments/uploadFiles',
  async (params: UploadFileParams, {
    rejectWithValue,
    dispatch
  }) => {
    const {
      transitionId,
      folderId,
      files
    } = params
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      e.returnValue = ''
    }
    try {
      const formData = new FormData()
      formData.append('parentId', folderId)
      formData.append('transitionId', transitionId)
      files.forEach((file) => {
        formData.append('files', file)
      })
      window.addEventListener('beforeunload', onBeforeUnload)
      const { data } = await lisaApi.post<{ success: boolean, message: string }>('/File', formData, {
        headers: {
          'Content-Type': 'application/json'
        },
        onUploadProgress: (pe: ProgressEvent) => {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          dispatch(updateUploadPercent({
            total: pe.total,
            loaded: pe.loaded
          }))
        }
      })
      if (data.success) {
        dispatch(openToast({
          severity: 'info',
          message: 'Documents uploaded successfully'
        }))
        dispatch(apiRTK.util.invalidateTags(['ImportStructure', 'Documents']))
      } else {
        dispatch(openToast({
          severity: 'error',
          message: data.message
        }))
      }
      window.removeEventListener('beforeunload', onBeforeUnload)
      return data
    } catch (e) {
      window.removeEventListener('beforeunload', onBeforeUnload)
      dispatch(openToast({
        severity: 'error',
        message: 'File(s) to large'
      })) // @todo Waiting on BE to fix 500 error
      return rejectWithValue({})
    }
  }
)

export const uploadFolder = createAsyncThunk( // RTK does not provide upload progress. Using axios for now
  'importDocuments/uploadFolder',
  async (params: UploadFolderParams, {
    rejectWithValue,
    dispatch
  }) => {
    const {
      transitionId,
      folderId,
      importHeaderId,
      files
    } = params
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      e.returnValue = ''
    }
    try {
      const formData = new FormData()
      formData.append('parentId', folderId)
      formData.append('transitionId', transitionId)
      formData.append('importHeaderId', importHeaderId!)
      files.forEach((file) => {
        formData.append('files', file)
      })
      window.addEventListener('beforeunload', onBeforeUnload)
      const { data } = await lisaApi.post<{ success: boolean, message: string }>('/File/folder', formData, {
        headers: {
          'Content-Type': 'application/json'
        },
        onUploadProgress: (pe: ProgressEvent) => {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          dispatch(updateUploadPercent({
            total: pe.total,
            loaded: pe.loaded
          }))
        }
      })
      if (data.success) {
        dispatch(openToast({
          severity: 'info',
          message: 'Documents uploaded successfully'
        }))
        dispatch(apiRTK.util.invalidateTags(['ImportStructure', 'Documents']))
      } else {
        dispatch(openToast({
          severity: 'error',
          message: data.message
        }))
      }
      window.removeEventListener('beforeunload', onBeforeUnload)
      return data
    } catch (e) {
      window.removeEventListener('beforeunload', onBeforeUnload)
      dispatch(openToast({
        severity: 'error',
        message: 'File(s) to large'
      })) // @todo Waiting on BE to fix 500 error
      return rejectWithValue({})
    }
  }
)

export const uploadLargeFile = createAsyncThunk( // RTK does not provide upload progress. Using axios for now
  'importDocuments/uploadLargeFile',
  async (params: UploadLargeFileParams, {
    rejectWithValue,
    dispatch
  }) => {
    const {
      file,
      transitionId,
      folderId
    } = params
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      e.returnValue = ''
    }
    try {
      const formData = new FormData(file)

      window.addEventListener('beforeunload', onBeforeUnload)
      const { data } = await lisaApi.post<{ success: boolean, message: string }>(`/File/UploadLargeFile?transitionId=${transitionId}&parentId=${folderId}`, formData, {
        onUploadProgress: (pe: ProgressEvent) => {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          dispatch(updateUploadPercent({
            total: pe.total,
            loaded: pe.loaded
          }))
        }
      })

      if (data.success) {
        dispatch(openToast({
          severity: 'info',
          message: 'Document uploaded successfully'
        }))
        dispatch(apiRTK.util.invalidateTags(['ImportStructure', 'Documents']))
      } else {
        dispatch(openToast({
          severity: 'error',
          message: data.message
        }))
      }
      window.removeEventListener('beforeunload', onBeforeUnload)
      return data
    } catch (e) {
      window.removeEventListener('beforeunload', onBeforeUnload)
      dispatch(openToast({
        severity: 'error',
        message: 'File(s) to large'
      })) // @todo Waiting on BE to fix 500 error
      return rejectWithValue({})
    }
  }
)

const importDocumentsSlice = createSlice({
  name: 'importDocuments',
  initialState,
  reducers: {
    setFolderId: (state, { payload }: PayloadAction<StorageId>) => {
      state.importFolderId = payload
    },
    selectFolder: (state, { payload }: PayloadAction<FileTree | null>) => {
      state.selectedFolder = payload
    },
    sortBy: (state, { payload }: PayloadAction<ImportDocumentsSortColumns>) => {
      const { sortColumn, sortOrder } = state.filters
      state.filters.sortOrder = nextSortDirection(sortColumn === payload ? (sortOrder ?? false) : false)
      state.filters.sortColumn = payload
      state.filters.page = 1
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.filters.page = payload
    },
    toggleAllDocumentIds: (state, { payload }: PayloadAction<ImportDocument[]>) => {
      state.selectedDocuments = toggleDocumentsOnPage2(payload, state.selectedDocuments)
    },
    toggleDocumentId: (state, { payload }: PayloadAction<LisaDocumentWithLabels>) => {
      if (state.selectedDocuments.some(_ => _.documentId === payload.documentId)) {
        state.selectedDocuments = state.selectedDocuments.filter(_ => _.documentId !== payload.documentId)
      } else {
        state.selectedDocuments = [...state.selectedDocuments, payload]
      }
    },
    toggleUnlabelled: (state) => {
      const unlabelled = !state.filters.unlabelled
      state.filters = {
        ...initialState.filters,
        unlabelled
      }
    },
    resetSelectedDocuments: (state) => {
      state.selectedDocuments = []
    },
    clearDocumentList: (state) => {
      state.importFolderId = ''
    },
    resetAll: (state) => {
      state = { ...initialState }
      return state
    },
    updateUploadPercent: (state, { payload }: PayloadAction<{ total: number, loaded: number }>) => {
      const {
        total,
        loaded
      } = payload
      state.fileUpload.uploadPercent = total > 0 ? Math.floor(100 * loaded / total) : 0
      if (state.fileUpload.uploadPercent > 0 && state.fileUpload.uploadPercent < 100) {
        state.fileUpload.loading = LoadingState.Pending
      } else if (state.fileUpload.uploadPercent === 100) {
        state.fileUpload.loading = LoadingState.Completed
      }
    },
    resetUploadFileState: (state) => {
      state.fileUpload = { ...initialState.fileUpload }
    },
    toggleFolder: (state, { payload }: PayloadAction<FolderId>) => {
      if (state.folderState.includes(payload)) {
        state.folderState = state.folderState.filter(_ => _ !== payload)
      } else {
        state.folderState.push(payload)
      }
    },
    updateSelectedDocuments: (state, { payload }: PayloadAction<ImportDocument[]>) => {
      state.selectedDocuments = state.selectedDocuments.map((sd) => {
        // @todo Might be expensive. Need to update selected documents after fetch
        return payload.find(_ => _.documentId === sd.documentId) ?? sd
      })
    },
    toggleShowOnlyUnlabelledFolders: (state) => {
      state.onlyUnlabelledFolders = !state.onlyUnlabelledFolders
      if (state.onlyUnlabelledFolders && !state.filters.unlabelled) {
        state.filters.unlabelled = true
      }
      if (!state.onlyUnlabelledFolders && state.filters.unlabelled) {
        state.filters.unlabelled = false
      }
    },
    setCurrentImportHeaderInProgress: (state, { payload }: PayloadAction<string | null>) => {
      state.currentImportHeaderInProgress = payload
    }
  },
  extraReducers: {
    [uploadFiles.fulfilled.type]: (state) => {
      state.fileUpload = {
        isError: false,
        loading: LoadingState.Completed,
        uploadPercent: 0
      }
    },
    [uploadFiles.rejected.type]: (state) => {
      state.fileUpload = {
        isError: true,
        loading: LoadingState.Completed,
        uploadPercent: 0
      }
    },
    [uploadFiles.pending.type]: (state) => {
      state.fileUpload = {
        isError: false,
        loading: LoadingState.Pending,
        uploadPercent: 0
      }
    },
    [uploadFolder.fulfilled.type]: (state) => {
      state.fileUpload = {
        isError: false,
        loading: LoadingState.Completed,
        uploadPercent: 0
      }
    },
    [uploadFolder.rejected.type]: (state) => {
      state.fileUpload = {
        isError: true,
        loading: LoadingState.Completed,
        uploadPercent: 0
      }
    },
    [uploadFolder.pending.type]: (state) => {
      state.fileUpload = {
        isError: false,
        loading: LoadingState.Pending,
        uploadPercent: 0
      }
    }
  }
})

export const {
  setPage,
  toggleAllDocumentIds,
  toggleDocumentId,
  setFolderId,
  toggleUnlabelled,
  sortBy,
  resetSelectedDocuments,
  clearDocumentList,
  selectFolder,
  resetAll,
  updateUploadPercent,
  resetUploadFileState,
  toggleFolder,
  updateSelectedDocuments,
  toggleShowOnlyUnlabelledFolders,
  setCurrentImportHeaderInProgress
} = importDocumentsSlice.actions

export const useImportDocuments = (state: RootState) => state.importDocuments

export default importDocumentsSlice.reducer
