import { FC, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { TransitionMenu } from './TransitionMenu'
import { Button, Grid } from '@material-ui/core'
import * as Icons from 'common/Icons/SvgIcons'
import { AircraftType, Manufacturer, Project, TransitionId, TransitionType } from 'types'
import { TableData } from 'common/TableData/TableData'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { WithPagination } from 'common/WithPagination/WithPagination'
import { aircraftTypeItems, manufacturers } from 'pages/Administration/TransitionForm/TransitionElements'
import { useGetSingleProjectQuery } from 'services/api/projectsApi'
import { openModal } from 'redux/slices/appSlice'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    margin: '0px !important',
    '& .MuiGrid-container': {
      margin: '0px !important'
    },
    '& .MuiTableCell-root': {
      fontSize: '12px',
      lineHeight: '12px',
      letterSpacing: '.05px',
      fontWeight: '700',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  },
  bodyRows: {
    height: '46px'
  },
  transitionName: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      color: `${theme.palette.statusColors.blue} !important`,
      textDecoration: 'underline'
    },
    '& span': {
      paddingLeft: 8,
      height: 15,
      maxWidth: 250,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  firstCell: {
    paddingLeft: '16px !important'
  },
  tableText: {
    fontSize: '16px',
    fontWeight: 500
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '55px',
    padding: '8px 0',
    '& .Mui-selected': {
      background: theme.palette.blue.main,
      color: theme.palette.common.white
    }
  },
  newTransitionButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '80px 0px'
  },
  paginationContainer: {
    height: 48,
    width: '100%'
  }
}))

type DataTableProps = {
  project: Project
}

export const TransitionsDataTable: FC<DataTableProps> = ({
  project
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [rowsPerPage] = useState(10)
  const history = useHistory()
  const { hasAccess } = useLisaAuth()
  const aircraftTypes = useMemo(aircraftTypeItems, [])
  const hasTransitions = project.transitionCount > 0
  const {
    data: singleProject,
    isLoading
  } = useGetSingleProjectQuery(project.projectId, { skip: !hasTransitions })
  const transitions = singleProject?.transitions ?? []

  const canViewA2k = hasAccess('perm_view_a2kstructure')

  const openTransition = (transitionId: TransitionId) => {
    history.push(`/structures/${canViewA2k ? 'import' : 'client'}/${transitionId}`)
  }
  const isDecimal = (transitions.length / rowsPerPage) % 1 !== 0
  const pages = Math.floor(transitions.length / rowsPerPage) + (isDecimal ? 1 : 0)

  if (!hasTransitions) {
    return (
      <div className={classes.newTransitionButton}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(openModal('TRANSITION_FORM'))
          }}>
        ADD ASSET
        </Button>
      </div>
    )
  }

  return (
    <Grid container direction={'column'} className={classes.root}>
      <TableData
        noShadow
        noGrow
        tinyHeading
        loading={isLoading}
        loadingText="Loading Assets"
        noResultsProps={{
          type: 'transitions',
          message: 'No assets'
        }}
        headCells={[
          <TableCell key="transitions" width={'24%'} className={classes.firstCell}>
            ASSET
          </TableCell>,
          <TableCell key="type" width={'14%'} align="left">
            ASSET TYPE
          </TableCell>,
          <TableCell key="aircraftType" width={'16%'} align="left">
            AIRCRAFT TYPE
          </TableCell>,
          <TableCell key="registration" width={'13%'} align="left">
            PREV. REG.
          </TableCell>,
          <TableCell key="registrationNew" width={'13%'} align="left">
            NEW REG.
          </TableCell>,
          <TableCell key="team" width={'8%'} align="left">
            TEAM
          </TableCell>,
          <TableCell key="status" width={'8%'} align="left">
            STATUS
          </TableCell>,
          <TableCell key="menu" width={'4%'}/>
        ]}
        tableRows={
          transitions.slice((page - 1) * rowsPerPage, page * rowsPerPage)
            .map((row) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.transitionId}
                  className={classes.bodyRows}>
                  <TableCell
                    id={row.userId}
                    width={'24%'}
                    align={'left'}
                    style={{ paddingLeft: 16 }}>
                    <div className={classes.transitionName}
                      onClick={() => row.isActive ? openTransition(row.transitionId) : null}>
                      <Icons.PlaneIco/>
                      <span>{row.name}</span>
                    </div>
                  </TableCell>
                  <TableCell width={'14%'} align="left">
                    <span className={classes.tableText}>
                      {row.transitionType === TransitionType.PhaseIn && 'Aircraft Transition: Phase-In'}
                      {row.transitionType === TransitionType.PhaseOut && 'Aircraft Transition: Phase-Out'}
                      {row.transitionType === TransitionType.ArcReview && 'Airworthiness Review'}
                      {row.transitionType === TransitionType.UnassignedDocuments && 'AMOS Unassigned documenets'}
                      {row.transitionType === TransitionType.AssetManagement && 'Asset Management'}
                      {row.transitionType === TransitionType.LessorAircraft && 'Lessor Aircraft (Re-)Delivery'}
                      {row.transitionType === TransitionType.PrePurchaseInspection && 'Pre-purchase inspection'}
                      {row.transitionType === TransitionType.PreliminaryInspection && 'Preliminary Inspection'}
                    </span>
                  </TableCell>
                  <TableCell width={'16%'} align="left">
                    <span className={classes.tableText}>
                      {
                        manufacturers.find(_ => _.value === row.manufacturer && _.value !== Manufacturer.Other)?.text ??
                        (row.manufacturerName || 'Other')
                      }
                      &nbsp;
                      {aircraftTypes.find(_ => _.value === row.aircraftType && _.value !== AircraftType.OTHER)?.text}
                      &nbsp;
                      {row.variation}
                    </span>
                  </TableCell>
                  <TableCell width={'13%'} align="left">
                    <span className={classes.tableText}>
                      {row.registration || '-'}
                    </span>
                  </TableCell>
                  <TableCell width={'13%'} align="left">
                    <span className={classes.tableText}>
                      {row.registrationNew || '-'}
                    </span>
                  </TableCell>
                  <TableCell width={'8%'} align="left">
                    <span className={classes.tableText}>
                      {row.transitionTeamMembersCount || 0}
                    </span>
                  </TableCell>
                  <TableCell width={'8%'} align="left">
                    <span className={classes.tableText}>
                      {row.isActive ? 'Active' : 'Inactive'}
                    </span>
                  </TableCell>
                  <TableCell align="right" width={'4%'} style={{ paddingRight: 16 }}>
                    <TransitionMenu projectId={project!.projectId} singleTransition={row} isActive={row.isActive}/>
                  </TableCell>
                </TableRow>
              )
            })
        }>
      </TableData>
      <div className={classes.paginationContainer}>
        <WithPagination
          page={page}
          pages={pages}
          setPage={(value: number) => setPage(value)}>
        </WithPagination>
      </div>
    </Grid>
  )
}
