import { FC, ReactElement, useCallback, useMemo, useState } from 'react'
import { ToolbarProps } from '@react-pdf-viewer/toolbar'
import { ClickAwayListener, Grid, IconButton, makeStyles, Theme, Tooltip } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { toggleViewMode } from 'redux/slices/documentSlice'
import { BusinessStatus, LisaFile, OcrStatus } from 'types'
import { businessStatusOptions } from 'common/Select/DocumentStatusSelect'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { ConfirmDialog } from 'common/Dialogs/ConfirmDialog'
import { PrimaryButton } from '@react-pdf-viewer/core'
import clsx from 'clsx'
import { DocumentStatusToggle } from 'common/Toggle/DocumentStatusToggle'
import { SearchPlugin } from '@react-pdf-viewer/search'
import { PdfSearchPopover } from './PdfSearchPopover'
import { useLocation } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'
import { autoFocus } from 'utils/dom'
import { DocumentActionsMenu } from './DocumentActionsMenu'
import { RenameDocumentForm } from 'common/RenameDocumentForm/RenameDocumentForm'
import {
  useCanUpdateDocumentStatusMutation,
  useGetDocumentMetaDataQuery,
  useRemoveDocumentMutation,
  useUpdateDocumentMutation
  , useChangeAcceptanceMutation
} from 'services/api/documentApi'
import { openToast } from 'redux/slices/appSlice'
import { DocumentAcceptanceButton } from 'common/Buttons/DocumentAcceptanceButton'

const useStyles = makeStyles<Theme, { compact: boolean, hasTopBar: boolean }>((theme) => ({
  toolbar: {
    paddingBottom: props => props.compact ? 0 : 0,
    minHeight: props => !props.hasTopBar ? 90 : 0,
    '& .rpv-core__minimal-button': {
      // fontSize: 16,
      '& :hover': {},
      '& svg': {
        width: 18,
        height: 18,
        strokeWidth: 2,
        color: '#fff'
      }
    },
    '& .rpv-core__tooltip-body': {
      color: theme.palette.black.main,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '20px',
      marginTop: 6,
      borderRadius: 2,
      backgroundColor: theme.palette.lime.main,
      '& .rpv-core__tooltip-body-arrow': {
        // backgroundColor: theme.palette.lime.main
        display: 'none'
      },
      '& .rpv-core__tooltip-body-content': {
        padding: '0 5px'
      }
    },
    '& .document-actions-menu': {},
    '& .MuiIconButton-root': {
      width: 36,
      height: 36,
      background: '#1E1E1E',
      marginRight: '6px',
      '& .stateButtons': {
        fill: theme.palette.grey2.light
      },
      '&:hover:not(.no-hover)': {
        background: theme.palette.lime.main,
        color: theme.palette.black.main,
        '& .stateButtons': {
          fill: theme.palette.black.main
        },
        '& svg': {
          fill: theme.palette.black.main
        },
        '& i.fas': {
          color: theme.palette.black.main
        }
      }
    },
    '& .MuiButtonBase-root.Mui-disabled': {
      background: theme.palette.black.main,
      '& svg': {
        fill: theme.palette.black.light
      }
    },
    '& .zoom-dd-wrapper .rpv-core__minimal-button': {
      backgroundColor: theme.palette.common.white
    }
  },
  toolbarTopRow: {
    paddingInline: 10,
    backgroundColor: '#1e1e1e',
    height: 56,
    zIndex: 20
  },
  toolbarBottomRow: {
    padding: '6px 0px 0px 12px',
    height: 42,
    zIndex: 2,
    width: 'calc(100% - 18px)'
  },
  commonActions: {
    color: theme.palette.common.white
  },
  statusPickerWrapper: {
    width: 216,
    '& .MuiInputBase-root': {
      height: 36
    }
  },
  pageWrapper: {
    color: theme.palette.common.white,
    '& .rpv-core__textbox': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.black.main
    }
  },
  message: {
    fontSize: '20px',
    padding: '48px 0'
  },
  compactDocumentName: {
    fontSize: '15px',
    lineHeight: '17px',
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'flex-start',
    height: '34px',
    overflow: 'hidden',
    lineBreak: 'anywhere'
  },
  tableViewIcon: {
    fontSize: 16
  },
  activeButton: {
    backgroundColor: `${theme.palette.lime.main} !important`,
    '& i.fas': {
      color: theme.palette.black.main
    }
  },
  disabledButton: {
    '& i.fas': {
      color: theme.palette.black.light
    }
  },
  searchButton: {
    color: theme.palette.common.white
  },
  searchButtonActive: {
    color: theme.palette.lime.main
  },
  searchPopoverContainer: {
    position: 'absolute',
    zIndex: 10,
    top: 56
  },
  hide: {
    display: 'none'
  }
}))

type ButtonOption = 'tableView'

type DocumentViewerToolbarProps = {
  Toolbar?: (props: ToolbarProps) => ReactElement
  Search?: SearchPlugin
  fullScreenHandler?: () => void
  printHandler?: () => void
  compact?: boolean,
  document: LisaFile,
  activeOptions?: ButtonOption[]
  datatableParsed?: boolean
}
export const DocumentViewerToolbar: FC<DocumentViewerToolbarProps> = ({
  document,
  Toolbar,
  Search,
  fullScreenHandler,
  printHandler,
  compact = false,
  activeOptions = [],
  datatableParsed = false
}) => {
  const hasTopBar = !!(Toolbar || fullScreenHandler || printHandler)
  const classes = useStyles({
    compact,
    hasTopBar
  })
  const {
    hasAccess,
    userId
  } = useLisaAuth()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const isDocumentSearchable = !compact && (document.ocrStatus === OcrStatus.Success || document.ocrStatus === OcrStatus.Incomplete) && document.isProcessed
  const queryString = useMemo(() => new URLSearchParams(search)?.get('s') ?? '', [search])
  const [showSearch, setShowSearch] = useState(queryString !== '')
  const { data: documentMetaData } = useGetDocumentMetaDataQuery(document.documentId)
  const [canUpdateDocumentStatus] = useCanUpdateDocumentStatusMutation()
  const [updateDocument] = useUpdateDocumentMutation()
  const [removeDocument] = useRemoveDocumentMutation()
  const [changeAcceptance] = useChangeAcceptanceMutation()

  const handleStatusChange = useCallback(async (businessStatus: BusinessStatus) => {
    if (!documentMetaData || userId === null) {
      return
    }
    let canUpdate = true
    if (businessStatus === BusinessStatus.Reviewed) {
      canUpdate = false
      const {
        success,
        message
      } = await canUpdateDocumentStatus(document.documentId).unwrap()
      canUpdate = success
      message && dispatch(openToast({
        severity: 'error',
        message
      }))
    }
    if (canUpdate) {
      const businessStatusName = businessStatusOptions.find(_ => _.value === businessStatus)?.text
      await updateDocument({
        documentId: document.documentId,
        businessStatus,
        comment: documentMetaData.comment,
        metadataId: documentMetaData.metadataId,
        createdBy: userId
      }).unwrap()
      if (businessStatusName) {
        dispatch(openToast({
          severity: 'info',
          message: `Document state changed to ${businessStatusName}`
        }))
        if (businessStatus === BusinessStatus.Deleted) {
          setTimeout(() => {
            window.open('', '_self')
            window.close()
          }, 2000)
        }
      }
    }
  }, [document.documentId, documentMetaData?.metadataId, userId])

  const handleDeleteDocument = async () => { // @todo Seems unused. Not tested after converting to RTK api
    if (document !== null) {
      await removeDocument({
        documentId: document.documentId,
        userId: userId!
      }).unwrap()
      dispatch(openToast({
        severity: 'info',
        message: 'You Deleted this document'
      }))
      setTimeout(() => {
        window.open('', '_self')
        window.close()
      }, 3000)
    }
  }

  const handleChangeAcceptance = async (accepted: boolean) => {
    if (documentMetaData) {
      const { success, message } = await changeAcceptance({ documentId: documentMetaData.documentId, isAccepted: accepted }).unwrap()
      if (!success) {
        dispatch(openToast({ severity: 'error', message: message ?? 'Error' }))
      }
    }
  }

  return (
    <Grid container item direction={'column'} className={classes.toolbar}>
      {
        !Toolbar && (fullScreenHandler || printHandler) &&
        <Grid container item justifyContent={'space-between'} alignItems={'center'} className={classes.toolbarTopRow}>
          <Grid item container xs justifyContent={'flex-end'}>
            {
              printHandler &&
              <Tooltip title={'Print'}>
                <div aria-describedby="rpv-core__tooltip-body-print">
                  <button aria-label="Print" aria-keyshortcuts="Ctrl+P" className="rpv-core__minimal-button"
                    type="button" data-testid="full-screen__enter-button">
                    <PrimaryButton onClick={printHandler}/>
                  </button>
                </div>
              </Tooltip>
            }
          </Grid>
        </Grid>
      }
      {
        Toolbar &&
        <Toolbar>
          {(ToolbarSlot) => {
            const {
              CurrentPageInput,
              // Download,
              EnterFullScreen,
              GoToNextPage,
              GoToPreviousPage,
              NumberOfPages,
              Print,
              Zoom,
              ZoomIn,
              ZoomOut
            } = ToolbarSlot
            return (
              <Grid container item justifyContent={'space-between'} alignItems={'center'}
                className={classes.toolbarTopRow}>
                {
                  compact &&
                  <Grid item container xs={5} className={classes.compactDocumentName}>
                    {document?.name}
                  </Grid>
                }
                <Grid
                  item container xs
                  className={classes.pageWrapper}
                  alignItems={'center'}
                  justifyContent={compact ? 'flex-end' : 'flex-start'}>
                  {
                    !compact &&
                    <ClickAwayListener onClickAway={() => setShowSearch(false)}>
                      <Grid item>
                        <Tooltip title={'Search document'}>
                          <span>
                            <IconButton
                              disabled={!Search || !isDocumentSearchable}
                              disableRipple
                              className={clsx(classes.searchButton, 'no-hover', { [classes.searchButtonActive]: showSearch })}
                              onClick={() => {
                                autoFocus(window.document.querySelector('#search-text-box-wrapper input'))
                                setShowSearch(!showSearch)
                              }}>
                              <SearchIcon fontSize={'large'}/>
                            </IconButton>
                          </span>
                        </Tooltip>
                        <div className={clsx(classes.searchPopoverContainer, { [classes.hide]: !showSearch })}>
                          {
                            Search &&
                            <PdfSearchPopover
                              documentTypesPerPage={document.docTypeRecognitionResultByPages}
                              searchPluginInstance={Search}
                              defaultKeyword={queryString}/>}
                        </div>
                      </Grid>
                    </ClickAwayListener>
                  }
                  <GoToPreviousPage/>
                  <CurrentPageInput/><span style={{ paddingBottom: 3 }}>/ <NumberOfPages/></span>
                  <GoToNextPage/>
                </Grid>
                <Grid item container xs alignItems={'center'} justifyContent={compact ? 'flex-end' : 'center'}>
                  <ZoomOut/>
                  <div className={'zoom-dd-wrapper'}>
                    <Zoom/>
                  </div>
                  <ZoomIn/>
                </Grid>
                {
                  !compact &&
                  <Grid item container xs alignItems={'center'} justifyContent={'flex-end'}>
                    <EnterFullScreen/>
                    <Print/>
                  </Grid>
                }
              </Grid>
            )
          }}
        </Toolbar>
      }
      {
        !compact &&
        <Grid container item justifyContent={'space-between'} alignItems={'center'}
          className={classes.toolbarBottomRow}>
          <Grid item container style={{ width: 'auto' }} >
            { !hasAccess('perm_act_acceptdocument')
              ? <DocumentStatusToggle
                disabled={!hasAccess('perm_act_documentmetadata') && !hasAccess('perm_act_deletedocument')}
                documentStatus={documentMetaData!.businessStatus}
                lockedStatus={documentMetaData?.statusLocked}
                hasOpenQueries={+document.queriesCount}
                onChange={handleStatusChange}/>
              : <Grid item>
                <DocumentAcceptanceButton disabled={documentMetaData?.statusLocked} accepted={documentMetaData!.businessStatus} onClick={(ac: boolean) => handleChangeAcceptance(ac)} compact/>
              </Grid> }
          </Grid>
          <Grid xs item container justifyContent={'flex-end'} alignItems={'center'}>
            {
              document.documentParserId !== null &&
              <Tooltip title="Table View" disableFocusListener={!datatableParsed}>
                <span>
                  <IconButton
                    disabled={!datatableParsed}
                    onClick={() => dispatch(toggleViewMode())}
                    className={clsx({
                      [classes.activeButton]: activeOptions.includes('tableView'),
                      [classes.disabledButton]: !datatableParsed
                    })}>
                    <i className={clsx('fas fa-table', classes.commonActions, classes.tableViewIcon)}/>
                  </IconButton>
                </span>
              </Tooltip>
            }
            <DocumentActionsMenu document={document}/>
            {/* @todo Not sure if we want to remove delete option for document */}
            {/* <MaybeForbiddenAction */}
            {/*  isForbidden={!hasAccess('perm_act_deletedocument')} */}
            {/*  tooltip={'Mark document for deletion'}> */}
            {/*  <IconButton onClick={() => dispatch(openConfirmDialog('MARK_DOCUMENT_FOR_DELETION'))}> */}
            {/*    <Icons.DeleteIco className={classes.commonActions}/> */}
            {/*  </IconButton> */}
            {/* </MaybeForbiddenAction> */}
          </Grid>
        </Grid>
      }
      <>
        <ConfirmDialog
          title="Mark Document for Deletion"
          type="MARK_DOCUMENT_FOR_DELETION"
          onConfirm={handleDeleteDocument}>
          <div className={classes.message}>Are you sure you want to mark this document for deletion?</div>
        </ConfirmDialog>
        <RenameDocumentForm/>
      </>
    </Grid>
  )
}
