import { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  dot: {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    background: theme.palette.grey2.light100,
    '&.yellow': {
      background: theme.palette.statusColors.yellow
    },
    '&.green': {
      background: theme.palette.statusColors.green
    },
    '&.red': {
      background: theme.palette.statusColors.red
    }
  },
  dotsWrapper: {
    display: 'flex',
    columnGap: '2px'
  },
  tooltipText: {}
}))

const dotColors = {
  white: '0',
  red: '1',
  yellow: '2',
  green: '3'
}

  type DotProps = {
    digit: string
  }

const Dot: FC<DotProps> = ({ digit }) => {
  const classes = useStyles()
  const dotColorName = (Object.keys(dotColors) as (keyof typeof dotColors)[]).find((key) => dotColors[key] === digit)

  return (
    <span className={clsx(classes.dot, dotColorName)}></span>
  )
}

type StatusDotsProps = {
    statusDigits: string[]
  }

export const StatusDots: FC<StatusDotsProps> = ({ statusDigits }) => {
  const classes = useStyles()
  return (
    <div className={classes.dotsWrapper}>
      <Dot digit={statusDigits[0]}/>
      <Dot digit={statusDigits[1]}/>
      <Dot digit={statusDigits[2]}/>
    </div>
  )
}
