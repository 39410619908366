import { FC, MouseEvent, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQueries } from 'redux/slices/queriesSlice'
import { Grid, Icon, IconButton, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { UserAvatarWithName } from 'common/UserAvatar/UserAvatar'
import { QueryStatusSelect } from 'common/Select/QueryStatusSelect'
import { RoleName } from 'utils/userRoleSecurity'
import { MaybeForbiddenAction } from 'common/MaybeForbiddenAction/MaybeForbiddenAction'
import { queryTypeOptions } from 'common/Select/QueryTypeSelect'
import { QueryAttachments } from 'components/Query/QueryAttachments'
import { useTransitionContext } from 'context/TransitionContext'
import { QueryComments } from 'components/Query/QueryComments'
import { QueryStatus } from 'types'
import LisaLoader from 'common/Loaders/LisaLoader'
import { NoResults } from 'common/NoResults/NoResults'
import { labelForStatus } from 'utils/queries'
import { QueryForm } from 'components/Query/QueryForm'
import { MessagesForm } from 'components/Query/MessagesForm'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { closeModal, openModal, openToast } from 'redux/slices/appSlice'
import { QueryReferenceLabel } from 'components/Query/QueryReferenceLabel'
import { useGetQueryDataQuery, useUpdateQueryStatusMutation } from 'services/api/queryApi'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useQueryData } from 'hooks/useQueryData'
import { QueryRiskLevelIndicator } from 'components/Query/QueryRiskLevelIndicator'
import { formattedDate, toFormattedDateTime } from 'utils/date'
import { Label } from 'common/Label/Label'

const useStyles = makeStyles<Theme, {showStatusReport: boolean}>((theme) => ({
  root: {
  },
  mainSection: {
    padding: '32px 48px 0px 48px'
  },
  subsection: {
    borderBottom: `1px solid ${theme.palette.grey2.dark}`,
    paddingBlock: 16
  },
  addComment: {
    width: '64px',
    height: '64px',
    background: theme.palette.blue.main,
    color: theme.palette.common.white,
    position: 'fixed',
    right: '50px',
    bottom: _ => _.showStatusReport ? 150 : 50,
    zIndex: 12,
    boxShadow: '2px 2px 10px 0 rgba(0,0,0,.3)',
    '&:hover': {
      background: theme.palette.lime.main,
      color: theme.palette.black.main
    },
    '& .MuiIcon-fontSizeSmall': {
      fontSize: '20px',
      lineHeight: '22px'
    }
  },
  disabledComments: {
    background: theme.palette.grey[400],
    '&:hover': {
      background: theme.palette.grey[400],
      color: theme.palette.common.white
    }
  },
  title: {
    display: 'flex',
    '& .MuiButtonBase-root': {
      background: theme.palette.blue.main,
      width: '42px',
      height: '42px',
      '&:hover': {
        background: theme.palette.lime.main,
        '& .editQuery': {
          color: theme.palette.black.main
        }
      }
    },
    '& .editQuery': {
      color: 'white',
      fontSize: '16px'
    }
  },
  editQueryDisabled: {
    background: `${theme.palette.grey[400]} !important`,
    color: 'white'
  },
  queryTitle: {
    width: '90%',
    fontSize: '24px',
    lineHeight: '1',
    letterSpacing: '-.25px',
    fontWeight: 600,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  queryReference: {
    padding: '16px 0px',
    '& .MuiChip-colorPrimary': {
      color: theme.palette.common.black
    }
  },
  queryBody: {
    width: '100%',
    fontSize: '18px',
    lineHeight: '1.4',
    whiteSpace: 'pre-wrap',
    paddingBlock: 12
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    flexWrap: 'wrap',
    '& .MuiChip-sizeSmall': {
      height: '24px',
      fontSize: '13px',
      fontWeight: 600,
      marginLeft: '6px',
      width: 'auto',
      overflow: 'visible',
      textOverflow: 'clip !important',
      background: theme.palette.common.white,
      borderRadius: '4px',
      border: `2px solid ${theme.palette.statusColors.blue}`,
      transition: 'all .1s linear'
    },
    '& span': {
      whiteSpace: 'nowrap'
    },
    '& a': {
      color: theme.palette.linkBlue.main,
      textDecoration: 'none',
      marginLeft: '6px',
      fontWeight: 600,
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  wotc: {
    marginLeft: '5px',
    fontWeight: 600
  },
  queryInfo: {
    paddingTop: '12px',
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    justifyContent: 'center',
    '& .label': {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500
    },
    '& .infoData': {
      fontSize: '16px',
      lineHeight: '18px',
      fontWeight: 600,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  emptyContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 100
  },
  greyLabel: {
    color: '#929292',
    textTransform: 'uppercase',
    fontWeight: 700,
    marginRight: 4,
    fontSize: 12
  },
  messagesContainer: {
    marginBlock: 32
  },
  marginTop: {
    marginTop: '5px'
  }
}))

export const QueryDetails: FC = () => {
  const dispatch = useDispatch()
  const { hasAccess, userId, hasRole } = useLisaAuth()
  const { selectedQuery: selectedQueryId, showStatusReport } = useSelector(useQueries)
  const { data: selectedQuery, isFetching } = useQueryData()
  const classes = useStyles({ showStatusReport })
  const { openPDFDocument } = useTransitionContext()
  const scroll = useRef(null)
  const isAssignedToMe = selectedQuery?.assignTo === userId
  const isReportedByMe = selectedQuery?.reporter === userId
  const [updateQueryStatus] = useUpdateQueryStatusMutation()
  const { data: _query, isLoading, refetch } = useGetQueryDataQuery(selectedQuery?.queryId!)

  const handleEditSingleQuery = () => {
    refetch()
    if (!isLoading) {
      dispatch(openModal('EDIT_QUERY'))
    }
  }

  const onStatusChange = useCallback(async (queryStatus: QueryStatus | '', wotc?: string) => {
    if (!selectedQuery || !queryStatus) {
      return
    }
    if (!hasRole(RoleName.ProgramManager) && !isReportedByMe && queryStatus === QueryStatus.Closed) {
      dispatch(openToast({ severity: 'error', message: 'You are not allowed to close the query.' }))
    } else {
      const { success } = await updateQueryStatus({
        status: queryStatus,
        queryId: selectedQuery.queryId,
        userId: userId!,
        wotc
      }).unwrap()
      if (success) {
        dispatch(openToast({
          severity: 'success',
          message: `Query status changed to '${labelForStatus(queryStatus)}'`
        }))
      }
    }
  }, [selectedQuery?.queryId])

  if (isFetching) {
    return (
      <div className={classes.loadingContainer}>
        <LisaLoader text={'Loading query....'} normalLoader/>
      </div>
    )
  }

  if (!selectedQueryId || (!selectedQuery && !isFetching)) {
    return (
      <div className={classes.emptyContainer}>
        <NoResults type={'queries'} message={'No queries'}/>
      </div>
    )
  }

  if (!selectedQuery) {
    return null
  }

  const canQueryComment = hasAccess('perm_act_query_comment')
  const canEditQuery = hasAccess('perm_act_query_edit')
  const canAddComment = canQueryComment ||
    (isAssignedToMe && hasAccess('perm_act_query_comment_assigned')) ||
    (isReportedByMe && hasAccess('perm_act_query_comment_reported'))
  const canEdit = canEditQuery && (isAssignedToMe || isReportedByMe)

  const hasLabel = selectedQuery.labelId && selectedQuery.labelName
  const hasDocument = selectedQuery.documentId && selectedQuery.documentName
  const showRefersTo = hasLabel || hasDocument
  const hasWotc = selectedQuery.wotc !== ''

  return (
    <div ref={scroll} className={classes.root}>
      <Tooltip title="add comment" placement="left">
        <IconButton
          className={clsx(classes.addComment, {
            [classes.disabledComments]: !canAddComment
          })}
          aria-label="add comment"
          onClick={() => canAddComment && dispatch(openModal('ADD_COMMENT_TO_QUERY'))}>
          <Icon className="far fa-comment-alt" fontSize="small" />
        </IconButton>
      </Tooltip>
      <Grid className={classes.mainSection} container direction={'column'}>
        {/* Status and Priority (Risk Level) */}
        <Grid item container justifyContent={'space-between'} className={classes.subsection}>
          <Grid item>
            <Grid container alignItems={'center'}>
              <Grid item>
                <Typography className={classes.greyLabel} variant={'body2'}>Status:</Typography>
              </Grid>
              <Grid item>
                <MaybeForbiddenAction isForbidden={!canEdit}>
                  <QueryStatusSelect
                    label={''}
                    disabled={!canEdit}
                    showSelectOption={false}
                    value={selectedQuery.queryStatus}
                    onChange={onStatusChange}
                    isQueryReportedByMe={isReportedByMe}
                    wotc={selectedQuery.wotc ?? ''}
                    mode={'toggle'}/>
                </MaybeForbiddenAction>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={'center'} justifyContent={'flex-end'}>
              <Typography className={classes.greyLabel} variant={'body2'}>Priority:</Typography>
              <QueryRiskLevelIndicator riskLevel={selectedQuery.riskLevel} withText/>
            </Grid>
          </Grid>
        </Grid>
        {/* Reporter and Query reference */}
        <Grid className={classes.subsection} item container justifyContent={'space-between'}>
          <Grid item>
            <UserAvatarWithName
              label={'Reporter'}
              user={{
                firstName: selectedQuery.reporterFirstName ?? '',
                lastName: selectedQuery.reporterLastName ?? '',
                url: selectedQuery.reporterUrl ?? '',
                userId: selectedQuery.reporter ?? ''
              } ?? null}
              footNote={`${toFormattedDateTime(new Date(selectedQuery.createdDate))}`}/>
          </Grid>
          <Grid item className={classes.queryReference} >
            {selectedQuery.queryReference !== '' && <QueryReferenceLabel queryReference={selectedQuery.queryReference}/>}
          </Grid>
        </Grid>
        {/* Query title, query text and Edit button */}
        <Grid className={clsx(classes.title, classes.subsection)} item container>
          <Grid item style={{ flex: 1 }}>
            <Grid container direction={'column'}>
              <span className={classes.queryTitle}>{selectedQuery.title}</span>
              <Grid item className={classes.queryBody}>
                {selectedQuery.queryText}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <MaybeForbiddenAction isForbidden={!canEdit} tooltip={'Edit query'}>
              <IconButton
                disabled={!canEdit}
                aria-label="edit query"
                onClick={handleEditSingleQuery}
                className={!canEdit ? classes.editQueryDisabled : ''}>
                <Icon className='editQuery fas fa-pen' fontSize="small" />
              </IconButton>
            </MaybeForbiddenAction>
          </Grid>
        </Grid>
        {/* Reference */}
        {
          showRefersTo &&
          <Grid item container className={classes.subsection} direction='column' alignContent='flex-start'>
            <Grid item className={classes.label}>
              <span>Reference:</span>
              {
                hasLabel &&
                <Label
                  noMargin
                  label={{
                    labelId: Number(selectedQuery.labelId!), // Comes as string
                    name: selectedQuery.labelName,
                    isSelected: false
                  }}/>
              }
              {
                hasDocument &&
                <a href="#" onClick={(e: MouseEvent<HTMLElement>) => openPDFDocument(e, selectedQuery.documentId!)}>
                  {selectedQuery.documentName}
                </a>
              }
            </Grid>
            {
              hasWotc && <Grid item alignContent='flex-start' className={clsx(classes.label, classes.marginTop)}>
                <span>WO/TC ref:</span>
                <div className={classes.wotc}>{selectedQuery.wotc}</div>
              </Grid>
            }
          </Grid>
        }
        {/* Assignee, due date, ata and query type */}
        <Grid className={classes.subsection} item container>
          <Grid item sm={12} lg={4}>
            <UserAvatarWithName
              user={{
                firstName: selectedQuery.assignToFirstName ?? '',
                lastName: selectedQuery.assignToLastName ?? '',
                url: selectedQuery.assignToUrl ?? '',
                userId: selectedQuery.assignTo ?? ''
              } ?? null}
              label={'Current assignee'}/>
          </Grid>
          <Grid item sm={4} lg={3} className={classes.queryInfo}>
            <Typography className={'label'}>Due date</Typography>
            <Typography className={'infoData'}>
              {formattedDate(new Date(selectedQuery.dueDate))}
            </Typography>
          </Grid>
          <Grid item sm={4} lg={2} className={classes.queryInfo}>
            <Typography className={'label'}>ATA Chapter</Typography>
            <Typography className={'infoData'}>
              {selectedQuery.ataChapter?.length > 0 ? selectedQuery.ataChapter : 'n/a'}
            </Typography>
          </Grid>
          <Grid item sm={4} lg={3} className={classes.queryInfo}>
            <Typography className={'label'}>Query type</Typography>
            <Typography className={'infoData'}>
              {queryTypeOptions.find(_ => _.value === selectedQuery.queryType)?.text ?? ''}
            </Typography>
          </Grid>
        </Grid>
        {/* Query attachments */}
        <Grid item className={classes.subsection}>
          <QueryAttachments/>
        </Grid>
        {/* Query Comments */}
        <div className={clsx(classes.messagesContainer)}>
          <QueryComments/>
        </div>
      </Grid>
      <LisaModal
        title="Edit Query"
        scroll={'body'}
        modalType={'EDIT_QUERY'}>
        <QueryForm
          onComplete={() => null}
          query={_query} />
      </LisaModal>
      <LisaModal
        title='Add comment to the query'
        scroll={'body'}
        modalType={'ADD_COMMENT_TO_QUERY'}>
        <MessagesForm
          onComplete={() => dispatch(closeModal(['ADD_COMMENT_TO_QUERY']))}
        />
      </LisaModal>
    </div>
  )
}
