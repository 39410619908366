import { LisaFile, Nullable, StorageId } from 'types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import lisaApi from 'api/lisaApi'
import { downloadExcelFile, downloadFile } from 'utils/file'

type RenameDocument = {
  storageId: StorageId,
  name: string,
  extension: string
}

export interface DocumentState {
  documentToRename: Nullable<RenameDocument>
  viewMode: 'document' | 'datatable'
  selectedCell: [number, number] | null
  currentPage: number
  pageType: string,
  videoLoader: boolean
}

const initialState: DocumentState = {
  documentToRename: null,
  viewMode: 'document',
  selectedCell: null,
  currentPage: 0,
  pageType: '',
  videoLoader: false
}

export const downloadOriginalDocument = createAsyncThunk(
  'documentSlice/downloadOriginalDocument',
  async (storageId: StorageId, { rejectWithValue }) => {
    try {
      const { data } = await lisaApi.get<LisaFile>('/Document/download', {
        params: {
          id: storageId
        }
      })
      const {
        content,
        name
      } = data
      if (content) {
        downloadFile(content, name)
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const downloadProcessedDocument = createAsyncThunk(
  'documentSlice/downloadProcessedDocument',
  async (storageId: StorageId, { rejectWithValue }) => {
    try {
      const { data } = await lisaApi.get<LisaFile>('/Document/downloadProcessed', {
        params: {
          id: storageId
        }
      })
      const {
        content,
        name
      } = data
      if (content) {
        downloadFile(content, `${name.slice(0, name.lastIndexOf('.'))}.pdf`, 'application/pdf')
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const downloadExcelDocument = createAsyncThunk(
  'documentSlice/downloadExcelDocument',
  async ({
    excelUrl,
    documentName
  }: { excelUrl: string, documentName: string }, { rejectWithValue }) => {
    try {
      const { data } = await lisaApi.get(excelUrl, {
        responseType: 'arraybuffer'
      })
      const removedExt = documentName?.substring(0, documentName.lastIndexOf('.'))
      downloadExcelFile(data, removedExt!)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

const documentSlice = createSlice({
  name: 'documentSlice',
  initialState,
  reducers: {
    toggleViewMode: (state) => {
      state.viewMode = state.viewMode === 'datatable' ? 'document' : 'datatable'
    },
    setDocumentToRename: (state, { payload }: PayloadAction<RenameDocument>) => {
      state.documentToRename = payload
    },
    selectCell: (state, { payload }: PayloadAction<{ row: number, column: number }>) => {
      const {
        row,
        column
      } = payload
      if (row > 0) {
        state.selectedCell = [row, column]
      }
    },
    setDocumentCurrentPage: (state, { payload }: PayloadAction<{currentPage: number, pageType: string}>) => {
      state.currentPage = payload.currentPage
      state.pageType = payload.pageType
    },
    setVideoLoader: (state) => {
      state.videoLoader = !state.videoLoader
    }
  }
})

export const {
  toggleViewMode,
  setDocumentToRename,
  selectCell,
  setDocumentCurrentPage,
  setVideoLoader
} = documentSlice.actions

export const useDocument = (state: RootState) => state.document
export const useDocumentViewMode = (state: RootState) => state.document.viewMode

export default documentSlice.reducer
