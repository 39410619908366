import { FC } from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'
import { OcrStatus, ProcessingStatusesNames } from 'types'
import { dtrAndLabelTooltipText, ocrProcessingTooltipText } from 'utils/documents'
import { StatusDots } from './StatusDots'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    rowGap: '2px'
  }
}))

type ProcessingStatusProps = {
  ocrStatus: OcrStatus
  processingStatus: string
}
type ExtractText = {
  value: number,
  text: string
}

export const ProcessingStatusIndicator: FC<ProcessingStatusProps> = ({ ocrStatus, processingStatus }) => {
  const classes = useStyles()
  const statusDigits = (processingStatus ?? '000').split('')
  const handleTooltipText = () => {
    const ocrText: ExtractText[] = ocrProcessingTooltipText.filter(item => item.value === ocrStatus)
    const dtrText: ExtractText[] = dtrAndLabelTooltipText.filter(item => item.value === +statusDigits[1])
    const labelText: ExtractText[] = dtrAndLabelTooltipText.filter(item => item.value === +statusDigits[2])
    const finalText = [
      { label: ProcessingStatusesNames.DATA_EXTRACTION, text: ocrText[0].text },
      { label: ProcessingStatusesNames.SHORT_DTR, text: dtrText[0].text },
      { label: ProcessingStatusesNames.AUTO_LABELING, text: labelText[0].text }]

    return (
      <div>
        {finalText.map((_) => (
          <div key={_.label}>{`${_.label}: ${_.text}`}</div>)
        )}
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Tooltip placement='left' title={handleTooltipText()}>
        <div><StatusDots statusDigits={statusDigits}/></div>
      </Tooltip>
    </div>

  )
}
