import { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import {
  OCRDocumentType,
  toggleDocumentIdForOCR,
  useOCRStatus
} from 'redux/slices/ocrStatusSlice'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import * as Icons from 'common/Icons/SvgIcons'
import { useTransitionId } from 'context/TransitionContext'
import { TableData } from 'common/TableData/TableData'
import { SelectAllCell } from './SelectAllCell'
import { SortByFileNameCell, SortByOcrStatusCell } from './SortCells'
import Icon from '@material-ui/core/Icon'
import { DocumentLink } from 'common/DocumentLink/DocumentLink'
import { useGetOCRUnprocessedDocumentsQuery, useResendToOcrMutation } from 'services/api/ocrApi'
import { openToast } from 'redux/slices/appSlice'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { sortOcrDocuments } from 'utils/documents'

const useStyles = makeStyles((theme) => ({
  dataTableCheckbox: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.35rem'
    }
  },
  bodyRows: {
    height: '42px'
  },
  resendIcon: {
    display: 'flex',
    fontSize: '18px',
    fill: theme.palette.black.main,
    cursor: 'pointer',
    selfAlign: 'center',
    justifyContent: 'center'
  },
  statusLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // rowGap: "2px",
    '& .MuiIcon-root': {
      fontSize: '16px',
      '&.fas.fa-check-circle': {
        color: theme.palette.statusColors.green
      },
      '&.fas.fa-exclamation-triangle': {
        color: theme.palette.statusColors.red
      },
      '&.fas.fa-spinner': {
        color: theme.palette.statusColors.blue
      },
      '&.fas.fa-exclamation-circle': {
        color: theme.palette.statusColors.orange
      },
      '&.far.fa-pause-circle': {
        color: theme.palette.statusColors.blue
      }
    }
  },
  last: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

export const UnprocessedDocuments: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { hasAccess } = useLisaAuth()
  const {
    documentIdsForOCR,
    filters
  } = useSelector(useOCRStatus)
  const {
    data: { documents } = {
      documents: [],
      isError: false,
      message: null
    },
    isFetching
  } = useGetOCRUnprocessedDocumentsQuery(useTransitionId())
  const [resendToOcr] = useResendToOcrMutation()
  const { fileName, ocrStatus } = filters[OCRDocumentType.Unprocessed]

  return (
    <TableData
      loading={isFetching}
      headCells={[
        <SelectAllCell
          key={'checkbox'}
          documents={documents}
          documentIds={documents.map(_ => _.documentId)}/>,
        <SortByFileNameCell key={'fileName'} ocrDocumentType={OCRDocumentType.Unprocessed}/>,
        <SortByOcrStatusCell key={'ocrStatus'} ocrDocumentType={OCRDocumentType.Unprocessed}/>,
        <TableCell style={{ width: '50px' }} key={'empty'}/>
      ]}
      tableRows={
        sortOcrDocuments(documents, {
          fileName,
          ocrStatus
        }).map((row) => {
          const isItemSelected = documentIdsForOCR.includes(row.documentId)
          const labelId = `enhanced-table-checkbox-${row.documentId}`
          return (
            <TableRow
              hover={!isItemSelected}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.documentId}
              selected={isItemSelected}
              className={classes.bodyRows}
            >
              <TableCell
                padding="checkbox"
                onClick={() =>
                  dispatch(toggleDocumentIdForOCR(row.documentId))
                }
                style={{ width: '40px' }}
              >
                <Checkbox
                  className={classes.dataTableCheckbox}
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </TableCell>
              <TableCell
                id={labelId}
                scope="row"
                padding="none"
                style={{ width: 'auto' }}>
                <DocumentLink document={row} title={row.filePath}/>
              </TableCell>
              <TableCell align="right" style={{ width: '100px' }}>
                <div className={classes.statusLabel}>
                  <Tooltip title="Ocr With Errors">
                    <Icon
                      fontSize="small"
                      className={'fas fa-exclamation-triangle'}
                      style={{ width: '20px' }}/>
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell align="left" style={{ width: '50px' }}>
                <Tooltip title="Resend to OCR">
                  <div className={classes.last}>
                    <Icons.ResendDocument
                      className={classes.resendIcon}
                      onClick={async () => {
                        if (!hasAccess('perm_act_resendtoocr')) {
                          return
                        }
                        const { success } = await resendToOcr(row.documentId).unwrap()
                        if (success) {
                          dispatch(openToast({ severity: 'info', message: `${row.name} resent to OCR` }))
                        }
                      }}
                    />
                  </div>
                </Tooltip>
              </TableCell>
            </TableRow>
          )
        })
      }>
    </TableData>
  )
}
