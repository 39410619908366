import { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Chip, MenuItem, TableSortLabelProps, Tooltip } from '@material-ui/core'
import {
  setPage,
  setPageSize,
  sortBy,
  toggleAllDocuments,
  toggleDocument, updateSelectedDocuments,
  useSearchDocuments
} from 'redux/slices/searchDocumentsSlice'
import { BusinessStatus, SearchDocumentsSortColumn, User } from 'types'
import { openModal } from 'redux/slices/appSlice'
import { DocumentLink } from 'common/DocumentLink/DocumentLink'
import { DocumentLabels } from 'common/DocumentLabels/DocumentLabels'
import { LisaPaper } from 'common/LisaPaper/LisaPaper'
import { useTransitionContext, useTransitionId } from 'context/TransitionContext'
import { useBulkLabelAssignActions } from 'components/BulkLabelAssign/BulkLabelAssign'
import { businessStatusOptions } from 'common/Select/DocumentStatusSelect'
import { MaybeForbiddenAction } from 'common/MaybeForbiddenAction/MaybeForbiddenAction'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useSearchQuery } from 'services/api/searchApi'
import Dropdown from 'common/LisaControls/Dropdown'
import { CheckMark } from 'common/Icons/SvgIcons'
import { ProcessingStatusIndicator } from 'common/ProcessingStatusIndicator/ProcessingStatusIndicator'
import {
  ColumnConfiguratorKey,
  columnConfiguratorLabels,
  globalSearchColumns
} from 'utils/columnConfigurator'
import { Cell, Column, LisaTable, Row } from 'common/LisaTable/LisaTable'
import { AbbreviationTypeIcon } from 'common/AbbreviationTypeIcon/AbbreviationTypeIcon'
import { formattedDate } from 'utils/date'
import { DocumentSearchModals } from 'common/DocumentSearch/components/DocumentSearchModals'

const useStyles = makeStyles((theme) => ({
  emptyChip: {
    '&.MuiChip-root': {
      background: 'transparent'
    }
  },
  queriesCount: {
    lineHeight: '16px'
  },
  statusLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  bodyRows: {
    height: '42px'
  },
  markForDeletion: {
    background: theme.palette.red.main
  },
  dataTableCheckbox: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.35rem'
    }
  },
  resultsBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .resultCount': {
      fontSize: '16px',
      fontWeight: 600
    },
    '& .returnResults': {
      width: '200px',
      '& .MuiSelect-root': {
        textTransform: 'lowercase'
      }
    }
  }
}))

const handleDocumentState = (value: BusinessStatus | '') => {
  const text = [{
    value: '',
    text: 'Any state'
  }, ...businessStatusOptions].filter((item) => item.value === value)
  return text[0].text
}
export const SearchResults: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { openDocument } = useTransitionContext()
  const { hasAccess, user: { settings: { searchSettings } } } = useLisaAuth()
  const transitionId = useTransitionId()
  const {
    selectedDocuments,
    searchParams,
    selectedLabel
  } = useSelector(useSearchDocuments)
  const {
    data: {
      rows,
      total,
      pages,
      pageSize
    } = {
      page: 1,
      pages: 1,
      total: 0,
      rows: [],
      pageSize: 25
    },
    isFetching,
    fulfilledTimeStamp
  } = useSearchQuery({
    ...searchParams,
    transitionId
  })
  const {
    startDocumentAssign,
    startDocumentsAssign
  } = useBulkLabelAssignActions()
  const areDocumentsSelected = selectedDocuments.length > 0
  const hasLabel = !!searchParams.label
  const { sortColumn, sortOrder } = searchParams
  const hasValidStatusesForBulkChange = hasLabel && rows.some(_ => _.status !== BusinessStatus.Clarification)
  const canTriggerBulkLabelAssign = hasAccess('perm_act_a2klabel')
  const handleOpenAllSelected = () => {
    selectedDocuments.forEach(({ documentId }) => {
      openDocument(documentId)
    })
  }
  const selectedDocumentIds = selectedDocuments.map(_ => _.documentId)

  const openBulkStatusChangeModal = () => {
    dispatch(openModal('DOCUMENT_STATUS_CHANGE_SEARCH_FORM'))
  }

  useEffect(() => {
    if (fulfilledTimeStamp) {
      dispatch(updateSelectedDocuments(rows))
    }
  }, [fulfilledTimeStamp])

  const items = useMemo(() => {
    const canChangeStatus = hasAccess('perm_act_documentmetadata') || hasAccess('perm_act_deletedocument')
    return [
      <MenuItem
        key={'open-selected'}
        disabled={!areDocumentsSelected}
        onClick={handleOpenAllSelected}>
        Open Selected
      </MenuItem>,
      <MaybeForbiddenAction key="bulk-label-assign" isForbidden={!canTriggerBulkLabelAssign}>
        <MenuItem
          disabled={!areDocumentsSelected}
          onClick={() => startDocumentsAssign(selectedDocuments)}>
          Bulk label assign
        </MenuItem>
      </MaybeForbiddenAction>,
      <MaybeForbiddenAction key={'change-status-selected'} isForbidden={!canChangeStatus}>
        <MenuItem
          disabled={!areDocumentsSelected || !hasValidStatusesForBulkChange || selectedLabel === null}
          onClick={openBulkStatusChangeModal}>
        Change status of selected documents
        </MenuItem>
      </MaybeForbiddenAction>,
      <MaybeForbiddenAction key={'change-status-all'} isForbidden={!canChangeStatus}>
        <MenuItem
          disabled={areDocumentsSelected || !hasValidStatusesForBulkChange || rows.length === 0 || selectedLabel === null}
          onClick={openBulkStatusChangeModal}>
        Change status of all documents
        </MenuItem>
      </MaybeForbiddenAction>,
      <MenuItem
        key={'configure-table-view'}
        onClick={() => dispatch(openModal('COLUMN_CONFIGURATOR_SEARCH'))}>
        {'Configure table view'}
      </MenuItem>
    ]
  }, [areDocumentsSelected, hasValidStatusesForBulkChange, rows.length, selectedDocuments])
  const title = useMemo(() => {
    return (
      <div className={classes.resultsBar}>
        <span className={'resultCount'}>{total > 0 && `${total} results`}</span>
        <div className={'returnResults'}>
          <Dropdown
            name={'perPage'}
            items={[
              {
                value: 10,
                text: '10 per page'
              },
              {
                value: 15,
                text: '15 per page'
              },
              {
                value: 25,
                text: '25 per page'
              }
            ]}
            value={searchParams.size}
            showSelectOption={false}
            onChange={(e) => dispatch(setPageSize(e.target.value as number))}
          />
        </div>
      </div>
    )
  }, [searchParams.size, total])

  const tableCellProps: Map<ColumnConfiguratorKey, TableSortLabelProps> = useMemo(() => {
    const configArray: [ColumnConfiguratorKey, SearchDocumentsSortColumn][] = [
      ['globalSearchListFilename', 'Name'],
      ['globalSearchListQueries', 'Query'],
      ['globalSearchListDocumentStatus', 'BusinessStatus'],
      ['globalSearchListDocumentType', 'DocType'],
      ['globalSearchListImportDate', 'ImportDate'],
      ['globalSearchListImportedBy', 'ImportedBy'],
      ['globalSearchListOrigin', 'ImportFolder'],
      ['globalSearchListProcessingStatus', 'ProcessingStatus']
    ]
    return new Map(configArray.map(([ccKey, sc]) => [
      ccKey, {
        active: sortColumn === sc,
        direction: sortColumn === sc ? sortOrder || 'asc' : 'asc',
        onClick: () => dispatch(sortBy(sc))
      }
    ]))
  }, [sortOrder, sortColumn])

  const columns: Column[] = useMemo(() => {
    return globalSearchColumns
      .filter((ic) => searchSettings[ic as keyof User['settings']['searchSettings']])
      .map((ccKey) => {
        return {
          id: String(ccKey),
          label: columnConfiguratorLabels.get(ccKey) ?? '-',
          className: ccKey === 'globalSearchListFilename' ? 'fileName' : undefined,
          tableSortLabelProps: tableCellProps.get(ccKey),
          width: ccKey === 'globalSearchListDocumentType' ? '60px' : undefined
        }
      })
  }, [searchSettings, tableCellProps])

  const tableRows: Row[] = useMemo(() => {
    const ccKeys = Object.entries(searchSettings).filter((entry) => entry[1]).map(([ccKey]) => ccKey as ColumnConfiguratorKey)
    return rows.map((document) => {
      const { labels } = document
      const cells: Cell[] = []
      if (ccKeys.includes('globalSearchListDocumentType')) {
        cells.push({
          id: `document-${document.documentId}-docType`,
          value: (
            <AbbreviationTypeIcon
              docTypeRecognitionResultByPages={document.docTypeRecognitionResultByPages}
              docTypeRecognitionResult={document.docTypeRecognitionResult}/>
          )
        })
      }
      if (ccKeys.includes('globalSearchListFilename')) {
        cells.push({
          id: `document-${document.documentId}-fileName`,
          value: <DocumentLink document={document} noDocType documentNameClass={'fileNameEllipsis'} searchParam={searchParams.searchText}/>
        })
      }
      if (ccKeys.includes('globalSearchListA2KLabels')) {
        cells.push({
          id: `document-${document.documentId}-labels`,
          value: <DocumentLabels
            withAssignLabelsOption
            labels={labels}
            onClick={() => startDocumentAssign(document)}/>
        })
      }
      if (ccKeys.includes('globalSearchListOrigin')) {
        cells.push({
          id: `document-${document.documentId}-origin`,
          value: (
            <Tooltip title={document.filePath}><span>{document.importFolder}</span></Tooltip>
          )
        })
      }
      if (ccKeys.includes('globalSearchListImportDate')) {
        cells.push({
          id: `document-${document.documentId}-importDate`,
          value: formattedDate(new Date(document.importDate))
        })
      }
      if (ccKeys.includes('globalSearchListImportedBy')) {
        cells.push({
          id: `document-${document.documentId}-importedBy`,
          value: document.importedBy
        })
      }
      if (ccKeys.includes('globalSearchListProcessingStatus')) {
        cells.push({
          id: `document-${document.documentId}-processingStatus`,
          value: <ProcessingStatusIndicator
            ocrStatus={document.ocrStatus}
            processingStatus={document.processingStatus}/>
        })
      }
      if (ccKeys.includes('globalSearchListQueries')) {
        const hasQueries = document.queryCount > 0 || document.closedQueryCount > 0
        cells.push({
          id: `document-${document.documentId}-processingPriority`,
          value: (
            <>
              {
                !hasQueries && <Chip size="small" className={classes.emptyChip}/>
              }
              {
                hasQueries &&
                <Chip
                  size="small"
                  classes={{ label: classes.queriesCount }}
                  label={document.queryCount > 0 && document.queryCount}/>
              }
            </>
          )
        })
      }
      if (ccKeys.includes('globalSearchListDocumentStatus')) {
        cells.push({
          id: `document-${document.documentId}-documentStatus`,
          value: (
            <div className={classes.statusLabel}>
              <Chip
                className={clsx([
                  'FileStatus',
                  handleDocumentState(document.status)
                ])}
                label={handleDocumentState(document.status)}
                size="small"
                variant="outlined"
                icon={document.status === BusinessStatus.Accepted ? <CheckMark /> : <></>}
              />
            </div>
          )
        })
      }
      return {
        id: `document-${document.documentId}`,
        selected: selectedDocumentIds.includes(document.documentId),
        document,
        cells
      }
    })
  }, [searchSettings, rows, selectedDocumentIds])

  return (
    <LisaPaper toolbar={{
      items,
      title
    }}>
      <LisaTable
        columns={columns}
        rows={tableRows}
        onSelectAll={() => dispatch(toggleAllDocuments(rows))}
        onRowToggle={(document) => dispatch(toggleDocument(document))}
        selectedRows={selectedDocumentIds}
        allRows={rows.map(_ => _.documentId)}
        isFetching={isFetching}
        page={searchParams.page}
        pages={pages}
        pageSize={pageSize}
        total={total}
        onPageChange={(value: number) => dispatch(setPage(value))}/>
      <DocumentSearchModals/>
    </LisaPaper>
  )
}
