import { FC } from 'react'
import { LinearProgress, makeStyles, Tooltip, Typography } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import { ExportProgress as ExportProgressProps } from 'services/api/exportApi'

const useStyles = makeStyles((theme) => ({
  treeViewLoader: {
    width: '100%',
    height: '100px',
    margin: '6px',
    borderRadius: '4px',
    background: theme.palette.statusColors.bluelight,
    borderBottom: `1px solid ${theme.palette.grey2.main}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '12px 16px',
    '& .dropdown': {
      width: 200
    },
    '& .header': {
      color: theme.palette.black,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .title': {
        fontSize: '14px',
        fontWeight: '600',
        textTransform: 'uppercase'
      },
      '& .right': {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.statusColors.blue,
        '& .refresh': {
          fontSize: '14px',
          marginRight: '6px',
          '&:hover': {
            cursor: 'pointer',
            color: theme.palette.black.main
          }
        },
        '& .percent': {
          color: theme.palette.black.light,
          fontSize: '14px'
        }
      }
    },
    '& .loader': {
      color: theme.palette.statusColors.blue,
      marginTop: '8px',
      '& .file-progress': {
        fontSize: '13px',
        lineHeight: '1',
        fontWeight: '500',
        color: theme.palette.black.light,
        margin: '4px 0'
      },
      '& .MuiLinearProgress-colorPrimary': {
        background: 'white'
      },
      '& .MuiLinearProgress-bar': {
        background: theme.palette.statusColors.blue
      }
    }
  }
}))

export const ExportProgress: FC<ExportProgressProps & { checkExportProgress?: () => void }> = ({
  exportedCount,
  exportedPercent,
  totalCount,
  checkExportProgress
}) => {
  const classes = useStyles()

  return (
    <div className={classes.treeViewLoader}>
      <div className="header">
        <Typography className={'title'}>
        delivery bible export IN PROGRESS
        </Typography>
        <div className="right">
          {
            checkExportProgress &&
            <Tooltip title="refresh progress">
              <Icon
                fontSize="small"
                className={'refresh fas fa-sync'}
                onClick={checkExportProgress}
              />
            </Tooltip>
          }
          <Typography className={'percent'}>
            {exportedPercent}%
          </Typography>
        </div>
      </div>
      <div className="loader">
        <LinearProgress
          variant="determinate"
          value={exportedPercent}
        />
        <Typography className={'file-progress'}>
          <b>{exportedCount}</b> of{' '}
          <b>{totalCount}</b> documents exported
        </Typography>
      </div>
    </div>
  )
}
