import { lisaApi } from 'services/api/lisaApi'
import {
  BusinessStatus,
  DeliveryBibleReport,
  DeliveryBibleReportValue,
  DocumentId,
  DocumentMetaData,
  DocumentParser,
  DocumentParserId,
  DocumentReference,
  DocumentStatusReport,
  DocumentStatusReportValue,
  DocumentType,
  DocumentTypeId,
  LabelId,
  LisaFile,
  LisaResponseExtended,
  ParserStatus,
  RecentDocument,
  ReferenceId,
  TransitionId,
  UserId
} from 'types'
import { sortByWeek } from 'utils/transitionReports'

export type BulkStatusUpdateParams = {
  labelId: LabelId,
  documentIds: DocumentId[],
  statusesToChange: BusinessStatus[],
  newStatus: BusinessStatus
}

export const documentApi = lisaApi.injectEndpoints({
  endpoints: (builder) => ({
    getDocument: builder.query<LisaFile, {
      id: DocumentId
      transitionId: TransitionId
    }>({
      query: (params) => ({
        url: 'Document/v2',
        params
      }),
      providesTags: ['Document']
    }),
    getRecentDocuments: builder.query<RecentDocument[], UserId>({
      query: (userId) => ({
        url: 'Document/Recent',
        params: { userId }
      })
    }),
    setSingleDocumentPriority: builder.mutation<DocumentId, DocumentId>({
      query: (documentId) => ({
        url: `Document/highpriority?documentId=${documentId}`,
        method: 'PUT'
      }),
      invalidatesTags: ['Documents']
    }),
    setBulkDocumentPriority: builder.mutation<DocumentId[], DocumentId[]>({
      query: (documentIds) => ({
        url: `Document/BulkHighPriority?documentIds=${documentIds}`,
        method: 'PUT'
      }),
      invalidatesTags: ['Documents']
    }),
    bulkStatusUpdate: builder.mutation<LisaResponseExtended, BulkStatusUpdateParams>({
      query: (params) => ({
        url: 'document/bulkstatusupdate',
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['Documents']
    }),
    getDocumentMetaData: builder.query<DocumentMetaData, DocumentId>({
      query: (documentId) => ({
        url: 'Document/metadata',
        params: { documentId }
      }),
      providesTags: ['DocumentMetaData']
    }),
    getDocumentRecentReferences: builder.query<DocumentReference[], DocumentId>({
      query: (documentId) => ({
        url: 'Document/reference',
        params: { documentId }
      }),
      providesTags: ['DocumentRecentReferences']
    }),
    addDocumentReference: builder.mutation<DocumentReference, {
      documentId: DocumentId
      referenceKey: string
      referenceValue: string
      createdBy: UserId
    }>({
      query: (params) => ({
        url: 'Document/reference/add',
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['DocumentMetaData', 'DocumentRecentReferences']
    }),
    removeDocumentReference: builder.mutation<ReferenceId, ReferenceId>({
      query: (id) => ({
        url: 'Document/reference/delete',
        method: 'PUT',
        params: { id }
      }),
      invalidatesTags: ['DocumentMetaData', 'DocumentRecentReferences']
    }),
    recoverDocuments: builder.mutation<LisaResponseExtended, { documentIds: DocumentId[] }>({
      query: (params) => ({
        url: 'Document/recycleBin/bulkRecover',
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['Documents']
    }),
    canUpdateDocumentStatus: builder.mutation<LisaResponseExtended, DocumentId>({
      query: (documentId) => ({
        url: `Document/CanUpdateStatus?documentId=${documentId}`,
        method: 'PUT'
      })
    }),
    updateDocument: builder.mutation<DocumentMetaData, {
      documentId: DocumentId
      metadataId: string
      comment: string
      businessStatus: BusinessStatus,
      createdBy: UserId
    }>({
      query: (params) => ({
        url: 'Document/update',
        method: 'PUT',
        body: params
      }),
      invalidatesTags: (result) => {
        if (result?.businessStatus !== BusinessStatus.Deleted) {
          return ['Document', 'DocumentMetaData']
        }
        return ['DocumentUpdate', 'DocumentMetaData']
      }
    }),
    replaceDocument: builder.mutation<any, {
      documentId: DocumentId
      files: File[]
    }>({
      queryFn: async ({
        files,
        documentId
      }, _, eo, baseQuery) => {
        const formData = new FormData()
        files.forEach((file) => {
          formData.append('files', file)
        })
        formData.append('documentId', documentId)
        return baseQuery({
          url: 'Document/Replace',
          method: 'POST',
          body: formData
        })
      },
      invalidatesTags: ['Document', 'DocumentMetaData']
    }),
    getOCRMessages: builder.query<string[], DocumentId>({
      query: (documentId) => ({
        url: `Document/OcrMessages?documentId=${documentId}`
      })
    }),
    removeDocument: builder.mutation<void, {
      documentId: DocumentId
      userId: UserId
    }>({
      query: ({
        documentId,
        userId
      }) => ({
        url: `Document/remove?documentId=${documentId.toUpperCase()}&userId=${userId}`,
        method: 'PUT'
      }),
      invalidatesTags: ['Document', 'Documents', 'OCRReport']
    }),
    convertDocumentToExcel: builder.mutation<LisaResponseExtended, number>({
      query: (originalFileId) => ({
        url: `Document/ConvertToExcel?originalFileId=${originalFileId}`,
        method: 'PUT'
      }),
      invalidatesTags: ['Document']
    }),
    changeDocumentType: builder.mutation<void, {
      documentTypeId: DocumentTypeId
      documentId: DocumentId
    }>({
      query: (params) => ({
        url: 'Document/DocumentType',
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['Document']
    }),
    changeDocumentTypePerPage: builder.mutation<void, {
      updatedDocumentTypeId: DocumentTypeId
      documentId: DocumentId,
      documentPageNumber: number
    }>({
      query: (params) => ({
        url: 'Document/DocumentTypePerPage',
        method: 'PUT',
        params
      }),
      invalidatesTags: ['Document']
    }),
    checkParserStatus: builder.query<ParserStatus, DocumentId>({
      query: (documentId) => ({
        url: `Document/ParserStatus?documentId=${documentId}`
      }),
      providesTags: ['CheckParserStatus']
    }),
    changeDocumentParser: builder.mutation<void, {
      documentId: DocumentId
      documentParserId: DocumentParserId
    }>({
      query: (params) => ({
        url: 'Document/DocumentParser',
        body: params,
        method: 'PUT'
      }),
      invalidatesTags: ['Document', 'CheckParserStatus']
    }),
    getDocumentTypes: builder.query<DocumentType[], void>({
      query: () => ({
        url: 'Document/types'
      }),
      keepUnusedDataFor: 300 // 5 minutes
    }),
    getDocumentParsers: builder.query<DocumentParser[], void>({
      query: () => ({
        url: 'Document/parsers'
      })
    }),
    getDocumentWeeklyReport: builder.query<DocumentStatusReport, TransitionId>({
      query: (transitionId) => ({
        url: 'Document/weeklyReports',
        params: { transitionId }
      }),
      transformResponse: (response: {
        documentStatusReportNotes: string
        documentStatusWeeklyReports: (DocumentStatusReportValue)[]
      }) => ({
        notes: response.documentStatusReportNotes,
        data: sortByWeek(response.documentStatusWeeklyReports)
      })
    }),
    updateWeeklyReportNotes: builder.mutation<LisaResponseExtended, {
      transitionId: TransitionId
      notes: string
    }>({
      query: ({
        notes,
        transitionId
      }) => ({
        url: 'Document/updateStatusReportNotes',
        method: 'POST',
        body: {
          documentStatusReportNotes: notes,
          transitionId
        }
      })
    }),
    getDeliveryBibleWeeklyReport: builder.query<DeliveryBibleReport, TransitionId>({
      query: (transitionId) => ({
        url: 'Document/deliveryBibleWeeklyReports',
        params: { transitionId }
      }),
      transformResponse: (response: {
        deliveryBibleReportNotes: string
        deliveryBibleStatusWeeklyReports: (DeliveryBibleReportValue)[]
      }) => ({
        notes: response.deliveryBibleReportNotes,
        data: sortByWeek(response.deliveryBibleStatusWeeklyReports)
      })
    }),
    updateDeliveryBibleWeeklyReportNotes: builder.mutation<LisaResponseExtended, {
      transitionId: TransitionId
      notes: string
    }>({
      query: ({
        notes,
        transitionId
      }) => ({
        url: 'Document/updateDeliveryBibleReportNotes',
        method: 'POST',
        body: {
          deliveryBibleReportNotes: notes,
          transitionId
        }
      })
    }),
    changeAcceptance: builder.mutation<LisaResponseExtended, {
      documentId: DocumentId,
      isAccepted: boolean
    }>({
      query: (params) => ({
        url: 'Document/changeAcceptance',
        body: params,
        method: 'PUT'
      }),
      invalidatesTags: ['DocumentMetaData', 'Documents']
    }),
    changeBulkAcceptance: builder.mutation<LisaResponseExtended, {
      documentIds: DocumentId[],
      isAccepted: boolean
    }>({
      query: (params) => ({
        url: 'Document/bulkChangeAcceptance',
        body: params,
        method: 'PUT'
      }),
      invalidatesTags: ['Documents']
    }),
    getProcessingStatus: builder.query<string, DocumentId>({
      query: (documentId) => ({
        url: `Document/processingStatus?documentId=${documentId}`
      }),
      providesTags: ['ProcessingStatus']
    })
  })
})

export const {
  useGetProcessingStatusQuery,
  useGetDocumentQuery,
  useGetRecentDocumentsQuery,
  useSetSingleDocumentPriorityMutation,
  useSetBulkDocumentPriorityMutation,
  useBulkStatusUpdateMutation,
  useRecoverDocumentsMutation,
  useGetDocumentMetaDataQuery,
  useGetDocumentRecentReferencesQuery,
  useAddDocumentReferenceMutation,
  useRemoveDocumentReferenceMutation,
  useCanUpdateDocumentStatusMutation,
  useUpdateDocumentMutation,
  useReplaceDocumentMutation,
  useGetOCRMessagesQuery,
  useRemoveDocumentMutation,
  useConvertDocumentToExcelMutation,
  useChangeDocumentTypeMutation,
  useCheckParserStatusQuery,
  useChangeDocumentParserMutation,
  useGetDocumentTypesQuery,
  useGetDocumentParsersQuery,
  useGetDocumentWeeklyReportQuery,
  useUpdateWeeklyReportNotesMutation,
  useGetDeliveryBibleWeeklyReportQuery,
  useUpdateDeliveryBibleWeeklyReportNotesMutation,
  useChangeAcceptanceMutation,
  useChangeBulkAcceptanceMutation,
  useChangeDocumentTypePerPageMutation
} = documentApi
