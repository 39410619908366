import { ChangeEvent, FC, useMemo, useState } from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { CommentVisibility, UserId } from 'types'
import { useTransitionContext } from 'context/TransitionContext'
import { useQueryId } from 'redux/slices/queriesSlice'
import { useLisaAuth } from 'hooks/useLisaAuth'
import clsx from 'clsx'
import { useAddQueryCommentMutation, useGetAssigneeUsersQuery, useGetQueryDataQuery } from 'services/api/queryApi'
import { openToast } from 'redux/slices/appSlice'
import { WithLoader } from 'common/WithLoader/WithLoader'
import TextArea from 'common/LisaControls/TextArea'
import Dropdown from 'common/LisaControls/Dropdown'
import SwitchLabel from 'common/LisaControls/SwitchLabel'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    marginTop: '32px',
    flexDirection: 'column',
    rowGap: '15px',
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiDivider-root': {
      width: '100%',
      margin: '10px 0px 0px 1px'
    },
    '& .MuiInputBase-inputMultiline': {
      height: '250px'
    }
  },
  actionButtons: {
    padding: '32px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  },
  userName: {
    fontWeight: 600,
    lineHeight: '18px',
    fontSize: '16px'
  },
  userEmail: {
    fontWeight: 500,
    lineHeight: '16px',
    fontSize: '14px'
  },
  commentVisibility: {
    '& .MuiTypography-body1': {
      whiteSpace: 'normal',
      lineHeight: '1',
      margin: '8px 0'
    }
  }
}))

type MessagesFormProps = {
  onComplete (): void
  className?: string
  postReply?: boolean
}
export const MessagesForm: FC<MessagesFormProps> = ({
  onComplete,
  className,
  postReply = false
}) => {
  const { transitionId } = useTransitionContext()
  const { hasAccess } = useLisaAuth()

  const { userId } = useLisaAuth()
  const queryId = useQueryId()
  const { data: selectedQuery } = useGetQueryDataQuery(queryId)
  const [comment, setComment] = useState<string>('')
  const [assignTo, setAssignTo] = useState<UserId>(selectedQuery?.assignTo ?? '')
  const [commentVisibility, setCommentVisibility] = useState<CommentVisibility>(
    selectedQuery?.visibility === 2 ? CommentVisibility.External : CommentVisibility.Internal)
  const queryVisibility: boolean = commentVisibility === CommentVisibility.External
  const classes = useStyles()
  const dispatch = useDispatch()
  const [addQueryComment] = useAddQueryCommentMutation()
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const {
    data: externalInternalUserList = [],
    isLoading
  } = useGetAssigneeUsersQuery({
    transitionId,
    isExternal: queryVisibility
  })

  const dropdownUserList = useMemo(() => {
    return externalInternalUserList
      ? externalInternalUserList.map((item) => ({
        value: item.userId,
        text: (
          <div>
            <Typography className={classes.userName}>{`${item.firstName} ${item.lastName}`}</Typography>
            <Typography className={classes.userEmail}>{item.email}</Typography>
          </div>)
      }))
      : []
  }, [externalInternalUserList])

  const userListCheck = externalInternalUserList?.some(_ => _.userId === assignTo)
  const canSubmit = comment && comment.trim() && userListCheck

  const submitComment = async () => {
    setButtonDisabled(true)
    try {
      await addQueryComment({
        queryId,
        comment,
        assignTo,
        createdBy: userId!,
        transitionId,
        commentVisibility
      }).unwrap()
    } catch (e) {
      dispatch(openToast({
        severity: 'error',
        message: 'You are not authorized for this action'
      }))
    }
    setButtonDisabled(false)
    setComment('')
    setCommentVisibility(CommentVisibility.Internal)
    onComplete()
  }

  return (
    <div className={clsx(classes.root, className)}>
      <WithLoader loading={isLoading} hasResults={true}>
        <Grid container wrap="nowrap" direction="column" spacing={1}>
          <Grid item>
            <TextArea
              label="Add reply *"
              name="comment"
              value={comment}
              onChange={(e: ChangeEvent<{ value: string }>) => setComment(e.target.value)}
            />
          </Grid>
          <Grid item>
            <div>
              <Dropdown
                label="Reassign to"
                name="assignTo"
                items={dropdownUserList}
                value={assignTo}
                onChange={(e) => setAssignTo(e.target.value as string)}
              />
            </div>
          </Grid>
          {
            hasAccess('perm_view_query_internal') &&
            <Grid item style={{ marginTop: 20 }}>
              <div className={classes.commentVisibility}>
                <SwitchLabel
                  disabled={selectedQuery?.visibility === 1}
                  name="commentVisibility"
                  label={'Comment visible to external users'}
                  value={commentVisibility === CommentVisibility.External}
                  onChange={(e, checked) => {
                    setCommentVisibility(checked ? CommentVisibility.External : CommentVisibility.Internal)
                  }}
                />
              </div>
            </Grid>
          }
        </Grid>
        <Grid item container justifyContent={postReply ? 'flex-end' : 'flex-start'}>
          {
            !postReply &&
          <div className={classes.actionButtons}>
            <Button
              disabled={!canSubmit || buttonDisabled}
              onClick={submitComment}
              variant="contained"
              color="primary"
              size="small">
              Add
            </Button>
            <Button
              onClick={() => onComplete()}
              variant="contained"
              color="primary"
              size="small">
              Cancel
            </Button>
          </div>
          }
          {
            postReply &&
          <Button
            disabled={!canSubmit}
            variant="contained"
            color="primary"
            size="small"
            onClick={submitComment}>
            Post Reply
          </Button>
          }
        </Grid>
      </WithLoader>
    </div>
  )
}
