import { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import { SignOut } from 'pages/Auth/SignOut'
import { SignIn } from 'pages/Auth/SignIn'
import MailForm from 'pages/Login/components/MailForm'
import ResetPasswordForm from 'pages/Login/components/ResetPasswordForm'
import { SignInRedirect } from 'pages/Auth/SignInRedirect'
import { ErrorPage } from 'pages/ErrorPage/ErrorPage'

export const PublicRoutes: FC = () => {
  return (
    <Switch>
      <Route exact path={'/error/code/:code'}>
        <ErrorPage/>
      </Route>
      <Route path="/signout-oidc" component={SignOut}/>
      <Route path="/signin-oidc" component={SignIn}/>
      <Route path="/forgotPassword" component={MailForm}/>
      <Route path="/resetPassword" component={ResetPasswordForm}/>
      <Route path="*" component={SignInRedirect}/>
    </Switch>
  )
}
