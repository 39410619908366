import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useTransitionContext, useTransitionId } from 'context/TransitionContext'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { QueriesExportParams, QueryFlag, QueryStatus, QueryVisibility } from 'types'
import { QueryFlagSelect } from './QueryFlagSelect'
import { QueryStatusSelect } from './QueryStatusSelect'
import { QueryVisibilitySelect } from './QueryVisibilitySelect'
import { useExportToExcelMutation, useGetQueriesByTransitionQuery } from 'services/api/queryApi'
import { downloadFile } from 'utils/file'
import SwitchLabel from 'common/LisaControls/SwitchLabel'

const useStyles = makeStyles(() => ({
  root: {
    paddingBlock: 24
  },
  note: {
    fontSize: 16
  },
  actionsContainer: {
    marginTop: 8
  },
  innerContainer: {
    paddingBlock: 24
  }
}))

const initialState = {
  visibilityOption: [QueryVisibility.Internal, QueryVisibility.External],
  statusOption: [
    QueryStatus.New, QueryStatus.Open, QueryStatus.Pending, QueryStatus.WorkPack,
    QueryStatus.Delayed, QueryStatus.Closed, QueryStatus.Sideletter
  ],
  flagOption: [QueryFlag.Critical, QueryFlag.Sideletter],
  externalVisibilityOption: [QueryVisibility.External]
}

enum NoteMessages {
  ALL_USERS = 'Query visibility and Query statuses should have at least one selected value.',
  EXTERNAL_USERS = 'Query statuses should have at least one selected value.'
}

export const ExportQueriesFrom: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data: queries = [] } = useGetQueriesByTransitionQuery({
    transitionId: useTransitionId(),
    unresolved: false
  }, {
    refetchOnMountOrArgChange: true
  })
  const { userId, hasAccess } = useLisaAuth()
  const hasReducedVisibility = hasAccess('perm_view_query_internal')
  const { transitionId } = useTransitionContext()
  const [exportAllToggle, setExportAllToggle] = useState<boolean>(hasReducedVisibility)
  const [visibilityOption, setVisibilityOption] = useState<QueryVisibility[]>(
    hasReducedVisibility ? initialState.visibilityOption : initialState.externalVisibilityOption)
  const [statusOption, setStatusOption] = useState<QueryStatus[]>(initialState.statusOption)
  const [flagOption, setFlagOption] = useState<QueryFlag[]>(exportAllToggle ? [] : initialState.flagOption)
  const [exportToExcel, { isLoading }] = useExportToExcelMutation()

  const close = () => dispatch(closeModal(['EXPORT_QUERIES_TO_EXCEL']))

  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleSubmit = async () => {
    const params: QueriesExportParams = {
      transitionId: transitionId.toUpperCase(),
      userId: userId!,
      queryFlags: flagOption,
      queryStatuses: statusOption,
      visibility: visibilityOption,
      related: !exportAllToggle
    }
    const {
      isError,
      message,
      fileName,
      content
    } = await exportToExcel(params).unwrap()
    if (isError) {
      dispatch(openToast({
        severity: 'error',
        message: message
      }))
    } else {
      downloadFile(content, fileName)
      close()
    }
  }

  const resetOptions = () => {
    setVisibilityOption(initialState.visibilityOption)
    setStatusOption(initialState.statusOption)
  }

  useEffect(() => {
    if (exportAllToggle) {
      resetOptions()
      setFlagOption([])
    } else {
      setFlagOption(initialState.flagOption)
    }
    if (buttonRef.current) {
      buttonRef.current.focus()
    }
  }, [exportAllToggle])

  return (
    <Grid container direction={'column'} spacing={2} className={classes.root}>
      <Grid item>
        {
          <SwitchLabel
            label={'Export all queries (' + queries.length + ')'}
            name="exportAllQueries"
            value={exportAllToggle}
            onChange={() => setExportAllToggle(!exportAllToggle)}
          />
        }
      </Grid>
      <Grid container item spacing={2}>
        {
          <Grid item xs={6}>
            <QueryVisibilitySelect
              label={'Query visibility'}
              name={'queryVisibility'}
              onChange={(e) => setVisibilityOption(e.target.value as QueryVisibility[])}
              value={visibilityOption}
              reduce={hasReducedVisibility}
              disabled={exportAllToggle || !hasReducedVisibility}/>
          </Grid>
        }
        <Grid item xs={6}>
          <QueryStatusSelect
            label={'Query statuses'}
            name={'queryStatuses'}
            onChange={(e) => setStatusOption(e.target.value as QueryStatus[])}
            value={statusOption}
            disabled={exportAllToggle}/>
        </Grid>
        <Grid item xs={6}>
          <QueryFlagSelect
            label={'Query flag'}
            name={'queryFlag'}
            onChange={(e) => setFlagOption(e.target.value as QueryFlag[])}
            value={flagOption}
            disabled={exportAllToggle}/>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.actionsContainer}>
        <Typography variant="body2">
          <span style={{
            textTransform: 'uppercase',
            fontWeight: 700
          }}>NOTE: </span>
          {!hasReducedVisibility ? NoteMessages.EXTERNAL_USERS : NoteMessages.ALL_USERS}
        </Typography>
      </Grid>
      <Grid item container spacing={1} className={classes.actionsContainer}>
        <Grid item>
          <Button
            ref={buttonRef}
            disabled={statusOption.length === 0 || visibilityOption.length === 0 || isLoading}
            onClick={() => handleSubmit()}
            variant="contained"
            color="primary"
            size="small">
            Apply
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={isLoading}
            onClick={close}
            variant="contained"
            color="primary"
            size="small">
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
