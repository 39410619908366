import { FC, ReactNode } from 'react'
import { Grid, GridDirection, GridProps, makeStyles } from '@material-ui/core'
import { LisaToolbar, LisaToolbarProps } from 'common/LisaToolbar/LisaToolbar'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    flexGrow: 1
  },
  contentContainer: {
    flexGrow: 1,
    height: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    justifyContent: 'flex-start',
    position: 'relative'
  },
  toolbarContainer: {
    borderBottom: `1px solid ${theme.palette.grey2.main}`,
    boxShadow: '0 3px 3px 0px rgba(0,0,0,.05)',
    paddingLeft: '16px',
    borderRadius: '4px 4px 0 0'
  }
}))

type LisaPaperProps = GridProps & {
  direction?: GridDirection
  header?: ReactNode
  toolbar?: LisaToolbarProps
  option?: ReactNode
  classes?: {
    root?: string
    contentContainer?: string
    toolbarContainer?: string
  }
}

export const LisaPaper: FC<LisaPaperProps> = ({
  direction = 'row',
  header = null,
  toolbar = {
    title: null,
    items: null,
    disabled: false,
    option: null
  },
  children,
  classes: propsClasses = {},
  ...gridProps
}) => {
  const classes = useStyles()
  return (
    <Grid container direction={'column'} className={clsx(classes.root, propsClasses.root)} {...gridProps}>
      { header && <Grid className={clsx(classes.toolbarContainer)}>
        {header}
      </Grid>
      }
      <LisaToolbar {...toolbar} className={clsx(classes.toolbarContainer, propsClasses.toolbarContainer)}/>
      <Grid
        item
        container
        direction={direction}
        className={clsx(classes.contentContainer, propsClasses.contentContainer)}>
        {children}
      </Grid>
    </Grid>
  )
}
