import {
  AttachmentId,
  FileUpload,
  LoadingState,
  Nullable,
  QueryId,
  QueryStatus,
  QueryType,
  SortDirection,
  UserId
} from 'types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import lisaApi from 'api/lisaApi'
import { lisaApi as api } from 'services/api/lisaApi'
import { RootState } from 'redux/store'
import { downloadFile, MAX_UPLOAD_FILE_SIZE_MB } from 'utils/file'
import { openToast } from './appSlice'
import { useSelector } from 'react-redux'
import { AxiosError } from 'axios'

export interface QueryFilters {
  searchTerm: string
  queryStatus: QueryStatus | null
  assigneeId: UserId
  reporterId: UserId
  queryType: QueryType | null
  sortOrder: SortDirection
  commentsSortOrder: SortDirection,
  labelId: string,
  label: string,
  documentId: string,
  documentName: string,
  fromClientStructure?: boolean,
  related: boolean
}

export interface QueriesState {
  selectedQuery: Nullable<QueryId>
  attachmentUpload: { [key: QueryId]: FileUpload }
  filters: QueryFilters
  showStatusReport: boolean
}

export const initialState: QueriesState = {
  selectedQuery: null,
  attachmentUpload: {},
  filters: {
    searchTerm: '',
    queryStatus: QueryStatus.Unresolved,
    assigneeId: '',
    reporterId: '',
    queryType: null,
    sortOrder: 'desc',
    commentsSortOrder: 'desc',
    labelId: '',
    label: '',
    documentId: '',
    documentName: '',
    fromClientStructure: false,
    related: false
  },
  showStatusReport: true
}

type AddQueryAttachmentParams = {
  files: FileList,
  queryId: QueryId,
  userId: UserId
}
export const addQueryAttachment = createAsyncThunk(
  'queries/addQueryAttachment',
  async ({
    files,
    queryId,
    userId
  }: AddQueryAttachmentParams, {
    rejectWithValue,
    dispatch
  }) => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      e.returnValue = ''
    }
    try {
      const formData = new FormData()
      Array.from(files).forEach((file) => {
        formData.append('files', file)
      })
      formData.append('userId', userId)
      formData.append('queryId', queryId)
      window.addEventListener('beforeunload', onBeforeUnload)
      const response = await lisaApi.post<{ success: boolean }>('/Query/Attachment/Add', formData, {
        onUploadProgress: (pe: ProgressEvent) => {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          dispatch(updateUploadPercent({
            total: pe.total,
            loaded: pe.loaded,
            queryId
          }))
        }
      })
      if (response.data.success) {
        dispatch(api.util.invalidateTags(['QueryAttachments']))
      }
      window.removeEventListener('beforeunload', onBeforeUnload)
    } catch (e) {
      window.removeEventListener('beforeunload', onBeforeUnload)
      const { response } = e as AxiosError
      const status = response?.status
      switch (status) {
      case 413:
      case 400:
        dispatch(openToast({
          severity: 'error',
          message: `Maximum file size is ${MAX_UPLOAD_FILE_SIZE_MB}MB`
        }))
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        dispatch(clearQueryAttachmentProgress(queryId))
        break
      case 500:
      default:
        dispatch(openToast({
          severity: 'error',
          message: 'Unknown error occurred'
        }))
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        dispatch(clearQueryAttachmentProgress(queryId))
        break
      }
      return rejectWithValue('')
    }
  }
)

export const downloadQueryAttachment = createAsyncThunk(
  'queries/downloadQueryAttachment',
  async (attachmentId: AttachmentId, { rejectWithValue }) => {
    try {
      const { data: file } = await lisaApi.get<{ content: string, name: string }>('/Query/Attachment/Download', {
        params: {
          attachmentId
        }
      })
      downloadFile(file.content, file.name)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

const queriesSlice = createSlice({
  name: 'transitionQueries', // @todo Could be renamed once old queries reducers are gone
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.filters = { ...initialState.filters }
    },
    resetAll: () => initialState,
    toggleStatusReport: (state) => {
      state.showStatusReport = !state.showStatusReport
    },
    updateFilters: (state, { payload }: PayloadAction<Partial<QueryFilters>>) => {
      state.filters = {
        ...state.filters,
        ...payload
      }
    },
    selectQuery: (state, { payload }: PayloadAction<Nullable<QueryId>>) => {
      state.selectedQuery = payload
    },
    updateUploadPercent: (state, { payload }: PayloadAction<{ total: number, loaded: number, queryId: QueryId }>) => {
      const {
        total,
        loaded,
        queryId
      } = payload
      const uploadPercent = total > 0 ? Math.floor(100 * loaded / total) : 0
      const completed = uploadPercent >= 100
      const pending = !completed && uploadPercent > 0
      state.attachmentUpload[queryId] = {
        uploadPercent,
        loading: completed ? LoadingState.Completed : (pending ? LoadingState.Pending : LoadingState.Init),
        isError: false
      }
    },
    clearQueryAttachmentProgress: (state, { payload }: PayloadAction<QueryId>) => {
      delete state.attachmentUpload[payload]
    },
    showAllQueries: (state, { payload }: PayloadAction<boolean>) => {
      const related = payload
      state.filters = {
        ...initialState.filters,
        queryStatus: related ? null : state.filters.queryStatus,
        related
      }
    }
  }
})

export const {
  resetFilters,
  updateFilters,
  selectQuery,
  resetAll,
  toggleStatusReport,
  updateUploadPercent,
  clearQueryAttachmentProgress,
  showAllQueries
} = queriesSlice.actions

export const useQueries = (state: RootState) => state.queries
export const useQueryId = (): QueryId => useSelector(useQueries).selectedQuery ?? ''

export default queriesSlice.reducer
