import { FC } from 'react'
import { UpdateStructure } from './UpdateStructure'
import { ImportFolderModal } from './ImportFolderModal'
import { UpdateSingleDocument } from './UpdateSingleDocument'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { sortBy, SortColumnToConfigMap, useImportDocuments } from 'redux/slices/importDocumentsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, Typography } from '@material-ui/core'
import { ConfirmDialog } from 'common/Dialogs/ConfirmDialog'
import { CloneToA2KForm } from './CloneToA2KForm'
import { useSetBulkDocumentPriorityMutation } from 'services/api/documentApi'
import { useCancelImportMutation, useDeleteFolderMutation } from 'services/api/importApi'
import { useTransitionId } from 'context/TransitionContext'
import { ColumnConfigurator } from 'common/ColumnConfigurator/ColumnConfigurator'
import {
  ColumnConfiguratorKey,
  getCheckedColumnsFromCheckedColumnsSettings,
  importColumns,
  mandatoryColumns
} from 'utils/columnConfigurator'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useUpdateUserSettingsMutation } from 'services/api/usersApi'
import { User } from 'types'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { CreateFolderModal } from './CreateFolderModal'
import { RenameFolderModal } from './RenameFolderModal'
import { useInitArchiveFinishedMutation } from 'services/api/transitionApi'
import { ImportLargeFileModal } from './ImportLargeFileModal'

const useStyles = makeStyles(() => ({
  message: {
    fontSize: '20px',
    padding: '48px 0'
  },
  dialogWrapper: {
    maxWidth: '360px',
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 0 32px 0',
    background: 'transparent',
    '& .body': {
      fontSize: '16px',
      fontWeight: '500',
      marginBottom: '8px'
    },
    ' & .small': {
      fontSize: '14px',
      fontWeight: '500',
      margin: '16px 0'
    }
  }
}))

export const ImportModals: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const transitionId = useTransitionId()
  const { selectedDocuments, filters, selectedFolder, importFolderId } = useSelector(useImportDocuments)
  const { user: { userId, settings } } = useLisaAuth()
  const [setBulkDocumentPriority] = useSetBulkDocumentPriorityMutation()
  const [cancelImport] = useCancelImportMutation()
  const [updateUserSettings] = useUpdateUserSettingsMutation()
  const [deleteFolder] = useDeleteFolderMutation()
  const [initArchiveFinished] = useInitArchiveFinishedMutation()

  return (
    <>
      <ConfirmDialog
        title='PRIORITISE OCR'
        type={'SET_HIGH_OCR_PRIORITY'}
        onConfirm={() => setBulkDocumentPriority(selectedDocuments.map(_ => _.documentId))}>
        <div className={classes.message}>Are you sure you want to prioritise OCR for selected documents?</div>
      </ConfirmDialog>
      <ConfirmDialog
        title='END IMPORT PROCESS'
        type={'END_IMPORT_PROCESS'}
        onConfirm={async () => {
          await cancelImport(transitionId)
        }}>
        <div className={classes.message}>Are you sure you want to end current import?</div>
      </ConfirmDialog>
      <LisaModal
        modalType={'ADD_FROM_ONE_DRIVE'}
        title="Add from onedrive"
        maxWidth="md"
        scroll='body'>
        <UpdateStructure/>
      </LisaModal>
      <LisaModal
        modalType={'UPLOAD_FILES'}
        title="Upload files"
        scroll='body'
        maxWidth="sm">
        <UpdateSingleDocument/>
      </LisaModal>
      <LisaModal
        modalType={'UPLOAD_FOLDER'}
        title="Upload folder"
        scroll='body'
        maxWidth="sm">
        <ImportFolderModal/>
      </LisaModal>
      <LisaModal
        modalType={'UPLOAD_LARGE_FILE'}
        title="Upload large file"
        scroll='body'
        maxWidth="sm">
        <ImportLargeFileModal/>
      </LisaModal>
      <LisaModal
        modalType={'CREATE_FOLDER'}
        title="Create folder"
        scroll='body'
        maxWidth="sm">
        <CreateFolderModal/>
      </LisaModal>
      <LisaModal
        modalType={'RENAME_FOLDER'}
        title="Rename folder"
        scroll='body'
        maxWidth="sm">
        <RenameFolderModal/>
      </LisaModal>
      <ConfirmDialog
        title="Delete folder"
        type={'DELETE_FOLDER'}
        onConfirm={async () => {
          try {
            const { message, success } = await deleteFolder({
              id: importFolderId
            }).unwrap()
            if (message) {
              dispatch(openToast({
                severity: success ? 'success' : 'error',
                message
              }))
              if (success) {
                dispatch(closeModal())
              }
            }
          } catch (e) {
            const { data: { message } } = e as { data: { message: string } }
            if (message) {
              dispatch(openToast({
                severity: 'error',
                message
              }))
            }
          }
        }}>
        <Typography
          className={classes.message}
        >{`Are you sure you want to delete folder ${selectedFolder?.name} and all its contents?`}
        </Typography>
      </ConfirmDialog>
      <ConfirmDialog
        title="Complete Initial Archive"
        type={'INIT_ARCHIVE_FINISHED'}
        onConfirm={async () => {
          try {
            const { success } = await initArchiveFinished({
              transitionId: transitionId
            }).unwrap()

            if (success) {
              dispatch(openToast({
                severity: 'success',
                message: 'Set initial archive completed with success.'
              }))
            } else {
              dispatch(openToast({
                severity: 'error',
                message: 'Set initial archive completed with error.'
              }))
            }

            dispatch(closeModal())
          } catch (e) {
            const { data: { message } } = e as { data: { message: string } }
            if (message) {
              dispatch(openToast({
                severity: 'error',
                message
              }))
            }
          }
        }}>
        <div className={classes.dialogWrapper}>
          <div className={'body'}>Are you sure that you want to complete Initial Archive?</div>
          <div className={'small'}>After this action you would not be able to import documents from OneDrive anymore</div>
        </div>
      </ConfirmDialog>
      <LisaModal
        modalType={'CLONE_TO_A2K'}
        title={'Clone selected to a2k'}
        scroll={'body'}>
        <CloneToA2KForm/>
      </LisaModal>
      <ColumnConfigurator
        columns={importColumns}
        checkedColumns={getCheckedColumnsFromCheckedColumnsSettings(settings.importSettings)}
        mandatoryColumns={mandatoryColumns.get('import')}
        onSave={async (checkedColumns) => {
          const importSettings = Object.keys(settings.importSettings).reduce((is, ccKey) => {
            return {
              ...is,
              [ccKey]: checkedColumns.includes(ccKey as keyof User['settings']['importSettings'])
            }
          }, settings.importSettings)
          if (!checkedColumns.includes(SortColumnToConfigMap.get(filters.sortColumn) as ColumnConfiguratorKey)) {
            // Sort by name if currently selected column is turned off
            dispatch(sortBy('Name'))
          }
          updateUserSettings({
            userId,
            settings: {
              importSettings
            }
          })
          dispatch(closeModal())
        }}/>
    </>
  )
}
