import { FC, useMemo } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { CommentVisibility, QueryComment as Comment, UserId } from 'types'
import { UserAvatarWithName } from 'common/UserAvatar/UserAvatar'
import { toFormattedString } from 'utils/date'
import { useLisaAuth } from 'hooks/useLisaAuth'
import clsx from 'clsx'
import { ExternalCommentIndicator } from 'common/Indicators/ExternalCommentIndicator'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '50%',
    '& .message-body-': {
      marginTop: '4px',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      padding: '16px 16px 32px 16px',
      borderRadius: '0px 16px 16px 16px',
      '&loggedIn': {
        background: theme.palette.blue.main,
        color: '#fff',
        marginTop: '4px',
        padding: '16px 16px 32px 16px',
        borderRadius: '0px 16px 16px 16px'
      }
    },
    '& .userDetails': {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '4px'
    },
    '& .messageHeader-': {
      display: 'flex',
      alignItems: 'center',
      '&reverse': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        '& .userDetails': {
          alignItems: 'flex-end',
          marginRight: '4px'
        }
      }
    },
    '& .message': {
      marginTop: '12px',
      fontSize: '1.05rem',
      lineHeight: '1.3',
      letterSpacing: '.1px',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word'
    }
  },
  userRole: {
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: 500
  },
  userName: {
    fontSize: '15px',
    lineHeight: '17px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginBottom: '4px',
    textTransform: 'capitalize'
  },
  strikeTrough: {
    textDecoration: 'line-through'
  },
  externalComment: {
    borderRadius: '16px 0px 16px 16px !important'
  },
  datePosted: {
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    marginBottom: 4,
    '& .query-comment-indicator': {
      marginRight: 6
    }
  }
}))

type QueryCommentProps = {
  comment: Comment
  reporterId: UserId
  assigneeId: UserId
}
export const QueryComment: FC<QueryCommentProps> = ({
  comment,
  reporterId,
  assigneeId
}) => {
  const classes = useStyles()
  const { createdByUrl, createdByFirstName, createdByLastName, commentVisibility, createdBy } = comment
  const { userId } = useLisaAuth()
  const userRole: string = useMemo(() => {
    return reporterId === comment.createdBy ? 'Reporter' : 'Assignee'
  }, [comment.createdBy, reporterId])
  const crossOut = userRole === 'Assignee' && assigneeId !== comment.createdBy
  const isCreatedByMe = userId === comment.createdBy
  const showExternalFlag = commentVisibility === CommentVisibility.External && !comment.isExternal
  return (
    <div className={classes.root}>
      <UserAvatarWithName
        reverse={comment.isExternal}
        user={{
          url: createdByUrl,
          firstName: createdByFirstName,
          lastName: createdByLastName,
          userId: createdBy ?? ''
        } ?? null}
        label={userRole}
        options={{ textDecoration: crossOut ? 'strikethrough' : 'none' }}
        oppositeColor/>
      <div className={clsx(`message-body-${isCreatedByMe ? 'loggedIn' : ''}`, {
        [classes.externalComment]: comment.isExternal
      })}>
        <Typography className={classes.datePosted}>
          {showExternalFlag && <ExternalCommentIndicator reverse={isCreatedByMe}/>}
          {toFormattedString(new Date(comment.createdDate))}
        </Typography>
        <div className={'message'}>{comment.comment}</div>
      </div>
    </div>
  )
}
