import { FC, KeyboardEvent, useMemo, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import InputSearch from 'common/LisaControls/InputSearch'
import Dropdown from 'common/LisaControls/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import {
  MyTransitionProjects,
  MyTransitionUsers,
  resetMyTransitionFilters,
  updateMyTransitionsFilters,
  useDashboard
} from 'redux/slices/dashboardSlice'
import { ProjectId, SortDirection } from 'types'
import { ResetFiltersButton } from 'common/Buttons/ResetFiltersButton'
import { useGetMyTransitionsQuery } from 'services/api/transitionApi'
import { useLisaAuth } from 'hooks/useLisaAuth'
import ComboBox from 'common/LisaControls/ComboBox'

const useStyles = makeStyles(() => ({
  inputSearch: {
    paddingLeft: 16
  }
}))

const allUsersOption = { userId: '', name: 'All users' }

export const MyTransitionsFilter: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { myTransitionsFilters } = useSelector(useDashboard)
  const { userId } = useLisaAuth()
  const { data: myTransitions = [], fulfilledTimeStamp } = useGetMyTransitionsQuery(userId)
  const [searchTerm, setSearchTerm] = useState(myTransitionsFilters.term)

  const myTransitionProjects = useMemo<MyTransitionProjects[]>(() => {
    return myTransitions.reduce<MyTransitionProjects[]>((projects, { projectId, projectName }) => {
      if (!projects.find(_ => _.projectId === projectId)) {
        projects.push({ projectId, projectName })
      }
      return projects
    }, []).sort((a, z) => a.projectName.toUpperCase().localeCompare(
      z.projectName.toUpperCase()))
  }, [fulfilledTimeStamp])

  const myTransitionUsers = useMemo<MyTransitionUsers[]>(() => {
    return myTransitions.reduce<MyTransitionUsers[]>((allUsers, { users }) => {
      return allUsers.concat(
        users.filter((u) => !allUsers.some((au) => au.userId === u.userId))
          .map((u) => ({ userId: u.userId, name: `${u.firstName} ${u.lastName}` })))
    }, [])
      .sort((a, z) => a.name.toUpperCase().localeCompare(
        z.name.toUpperCase()))
  }, [fulfilledTimeStamp])

  return (
    <Grid container spacing={2} alignItems={'flex-end'} justifyContent={'flex-start'}>
      <Grid item xs={12} lg={5}>
        <InputSearch
          inputClass={classes.inputSearch}
          placeholder={'Find Asset'}
          noTooltip
          value={searchTerm}
          name={'myTransitionsSearch'}
          onClick={() => dispatch(updateMyTransitionsFilters({ term: searchTerm }))}
          onKeyUp={(e: KeyboardEvent) => {
            if (e.key === 'Enter') {
              dispatch(updateMyTransitionsFilters({ term: searchTerm }))
            }
            if (e.key === 'Backspace') {
              if (searchTerm.length === 0) {
                dispatch(updateMyTransitionsFilters({ term: '' }))
              }
            }
          }}
          onChange={(e) => setSearchTerm(e.target.value)}/>
      </Grid>
      <Grid item xs={3} lg={2}>
        <Dropdown
          name={'project'}
          label={'Entity'}
          showSelectOption={false}
          value={myTransitionsFilters.projectId}
          onChange={(e) => {
            dispatch(updateMyTransitionsFilters({ projectId: e.target.value as ProjectId }))
          }}
          items={[
            { value: '', text: 'All entities' },
            ...myTransitionProjects.map(_ => ({ value: _.projectId, text: _.projectName }))
          ]}/>
      </Grid>
      <Grid item xs={3} lg={2}>
        <ComboBox
          label={'User'}
          options={[allUsersOption, ...myTransitionUsers]}
          getOptionLabel={(mtu: MyTransitionUsers) => mtu.name ?? ''}
          onInputChange={(e, value, reason) => {
            if (reason === 'clear') {
              dispatch(updateMyTransitionsFilters({ userId: '' }))
            }
          }}
          onChange={(e, mtu) => {
            if (mtu) {
              dispatch(updateMyTransitionsFilters({ userId: mtu.userId }))
            }
          }}
          value={myTransitionUsers.find((mtu) => mtu.userId === myTransitionsFilters.userId) ?? allUsersOption}/>
      </Grid>
      <Grid item xs={3} lg={2}>
        <Dropdown
          name={'sort'}
          label={'Sort'}
          showSelectOption={false}
          value={myTransitionsFilters.sort}
          onChange={(e) => {
            dispatch(updateMyTransitionsFilters({ sort: e.target.value as SortDirection }))
          }}
          items={[
            { value: 'asc', text: 'Newest first' },
            { value: 'desc', text: 'Oldest first' }
          ]}/>
      </Grid>
      <Grid item xs={3} md={2} lg={1}>
        <ResetFiltersButton onClick={() => {
          setSearchTerm('')
          dispatch(resetMyTransitionFilters())
        }}/>
      </Grid>
    </Grid>
  )
}
