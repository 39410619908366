import { FC } from 'react'
import { GridGrow } from 'common/Grid/GridGrow'
import { Grid } from '@material-ui/core'
import { TransitionHeader } from './TransitionHeader'
import { OverallTransitionProgressReport } from './OverallTransitionProgressReport'
import { DocumentStatusReport } from './DocumentStatusReport'
import { WeeklyQueryStatusReport } from './WeeklyQueryStatusReport'
// import { RecordReviewStatusReport } from './RecordReviewStatusReport'
import { DeliveryBibleReport } from './DeliveryBibleReport'

export const GeneralProgress: FC = () => {
  return (
    <GridGrow container spacing={2}>
      <Grid item xs={12}>
        <TransitionHeader/>
      </Grid>
      <Grid item xs={12}>
        <OverallTransitionProgressReport/>
      </Grid>
      <Grid item sm={12} md={6}>
        <DocumentStatusReport/>
      </Grid>
      <Grid item sm={12} md={6}>
        <WeeklyQueryStatusReport/>
      </Grid>
      {/* <Grid item sm={12} md={6}> */}
      {/*  <RecordReviewStatusReport/> */}
      {/* </Grid> */}
      <Grid item sm={12} md={6}>
        <DeliveryBibleReport/>
      </Grid>
    </GridGrow>
  )
}
