import { FC, useMemo } from 'react'
import { RiskLevel } from 'types'
import { makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { queryRiskLevels } from 'utils/queries'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 4,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  info: {
    backgroundColor: theme.palette.statusColors.blue
  },
  low: {
    backgroundColor: theme.palette.statusColors.green
  },
  medium: {
    backgroundColor: '#ffed00'
  },
  high: { backgroundColor: '#e20005' },
  whiteIcon: {
    color: theme.palette.common.white
  },
  darkIcon: {
    color: theme.palette.common.black
  },
  text: {
    fontSize: 12,
    textTransform: 'uppercase',
    marginRight: 4
  },
  upArrow: {
    transform: 'rotate(-45deg)'
  },
  downArrow: {
    transform: 'rotate(45deg)'
  }
}))

export interface QueryRiskLevelIndicatorProps {
  riskLevel: RiskLevel
  withText?: boolean
}

export const QueryRiskLevelIndicator: FC<QueryRiskLevelIndicatorProps> = ({ riskLevel, withText = false }) => {
  const classes = useStyles()
  const text = useMemo(() => {
    if (!withText) {
      return ''
    }
    return queryRiskLevels.find(_ => _.value === riskLevel)?.text ?? ''
  }, [withText])
  return (
    <>
      {
        withText && text &&
        <Typography className={classes.text}>{text}</Typography>
      }
      <div
        className={clsx(classes.root, {
          [classes.info]: riskLevel === RiskLevel.Information,
          [classes.low]: riskLevel === RiskLevel.Low,
          [classes.medium]: riskLevel === RiskLevel.Medium,
          [classes.high]: riskLevel === RiskLevel.High
        })}>
        { riskLevel === RiskLevel.Information && <i className={clsx('fas fa-info', classes.whiteIcon)}/> }
        {
          riskLevel !== RiskLevel.Information &&
        <ArrowForwardIcon
          className={clsx(classes.whiteIcon, {
            [classes.darkIcon]: riskLevel === RiskLevel.Medium,
            [classes.upArrow]: riskLevel === RiskLevel.High,
            [classes.downArrow]: riskLevel === RiskLevel.Low
          })}/>
        }
      </div>
    </>
  )
}
