import { FC, useMemo } from 'react'
import clsx from 'clsx'
import { makeStyles, Theme, Tooltip } from '@material-ui/core'
import { LoadingDotsIcon } from 'common/LoadingDotsIcon/LoadingDotsIcon'

const useStyles = makeStyles<Theme, { docType: string, recognition: number }>((theme) => ({
  documentTypeContainer: {
    display: 'flex',
    justifyContent: 'center',
    lineHeight: '1.6',
    background: theme.palette.blue.main,
    color: theme.palette.lime.main,
    fontSize: 12,
    minWidth: 50,
    maxWidth: 50
  },
  default: {
    fontWeight: 700,
    borderRadius: 4,
    '&.EMPT, &.OTHR': {
      background: theme.palette.grey[600],
      color: theme.palette.common.white
    },
    '&.error': {
      background: props => props.recognition <= 60
        ? theme.palette.statusColors.red
        : theme.palette.blue.main,
      color: props => props.recognition <= 60
        ? theme.palette.lime.main
        : theme.palette.common.white
    }
  },
  clickable: {
    fontWeight: 700,
    paddingBlock: 2,
    cursor: 'pointer'
  },
  clickableActive: {
    backgroundColor: theme.palette.lime.main,
    color: theme.palette.common.black
  }
}))

type AbbreviationTypeProps = {
  docTypeRecognitionResult: string
  docTypeRecognitionResultByPages?: string[]
  recognition?: number
  onClick?: () => void
  variation?: 'default' | 'clickable',
  active?: boolean
}
export const AbbreviationTypeIcon: FC<AbbreviationTypeProps> = ({
  docTypeRecognitionResult: docType,
  docTypeRecognitionResultByPages: typeList = [],
  recognition = 60,
  onClick = () => null,
  variation = 'default',
  active = false
}) => {
  const ifMixed: boolean = docType === 'MXD'
  const ifNA: boolean = docType === 'N/A'
  const classes = useStyles({ docType, recognition })

  const tooltipTitle = useMemo(() => {
    if (!ifMixed) {
      return ''
    }
    return typeList.filter((_, i, self) => self.indexOf(_) === i).sort((a, z) => a.localeCompare(z)).join(', ')
  }, [ifMixed, typeList.join(',')])

  return (
    <Tooltip title={tooltipTitle}>
      <div>
        {
          ifNA && <LoadingDotsIcon />
        }
        {
          !ifNA &&
        <div
          onClick={onClick}
          className={clsx(
            classes.documentTypeContainer,
            `${ifNA ? 'loading' : docType}`,
            'document-type-container',
            {
              [classes.default]: variation === 'default',
              [classes.clickable]: variation === 'clickable',
              [classes.clickableActive]: variation === 'clickable' && active
            }
          )}>
          {docType}
        </div>
        }
      </div>
    </Tooltip>
  )
}
