import { FC, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateFilters, useQueries, useQueryId } from 'redux/slices/queriesSlice'
import { Chip, Grid, Icon, IconButton, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core'
import { CommentVisibility, SortDirection } from 'types'
import clsx from 'clsx'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { QueryComment } from './QueryComment'
import { sortQueryComments } from 'utils/queries'
import { useGetQueryCommentsQuery, useGetQueryDataQuery } from 'services/api/queryApi'
import { openModal } from 'redux/slices/appSlice'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey2.light100,
    padding: 16,
    borderRadius: 4
  },
  paper: {
    backgroundColor: 'transparent',
    maxWidth: '85%',
    minWidth: '35%',
    alignSelf: 'flex-start',
    '&.initial': {
      width: '100%'
    },
    '&.replyLeft': {
      alignSelf: 'flex-start',
      marginBottom: '18px',
      minWidth: '60%',
      '& .queryBlock': {
        marginTop: '4px',
        backgroundColor: '#E4E8EB',
        color: theme.palette.black.main,
        padding: '16px',
        borderRadius: '0px 16px 16px 16px',
        '&.currentUser': {
          background: theme.palette.blue.main,
          color: '#fff'
        },
        '& .datePosted': {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 500,
          marginBottom: '4px'
        },
        '& .message': {
          fontSize: '16px',
          lineHeight: '1.2',
          fontWeight: 600
        }
      }
    },
    '&.replyLeftReporter': {
      alignSelf: 'flex-start',
      marginBottom: '18px',
      minWidth: '60%',
      '& .queryBlock': {
        marginTop: '4px',
        backgroundColor: '#CAE5F8',
        color: theme.palette.black.main,
        padding: '16px',
        borderRadius: '0 16px 16px 16px',
        '& .datePosted': {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 500,
          marginBottom: '4px'
        },
        '& .message': {
          fontSize: '16px',
          lineHeight: '1.2',
          fontWeight: 600
        }
      }
    },
    '&.replyRight': {
      alignSelf: 'flex-end',
      marginBottom: '18px',
      minWidth: '60%',
      '& .queryBlock': {
        marginTop: '4px',
        backgroundColor: '#65696E',
        color: theme.palette.common.white,
        padding: '16px',
        borderRadius: '16px 0 16px 16px',
        '& .datePosted': {
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: '500',
          marginBottom: '4px'
        },
        '& .message': {
          fontSize: '16px',
          lineHeight: '1.2',
          fontWeight: '600'
        }
      }
    }
  },
  external: {
    justifyContent: 'flex-end'
  },
  sortDropDown: {
    width: '300px',
    marginBottom: '24px'
  },
  commentContainer: {
    borderBottom: `1px solid ${theme.palette.grey2.main}`,
    marginBottom: 18
  },
  commentsHeader: {
    textTransform: 'uppercase',
    color: theme.palette.black.main,
    fontSize: 12,
    borderBottom: `1px solid ${theme.palette.grey2.dark}`,
    marginBlock: 18,
    fontWeight: 600,
    paddingBottom: 8
  },
  timelineChip: {
    borderRadius: 4,
    fontSize: 12,
    textTransform: 'uppercase',
    '& .MuiChip-label': {
      paddingInline: 4
    }
  },
  timelineChipActive: {
    backgroundColor: `${theme.palette.blue.main} !important`,
    color: `${theme.palette.common.white} !important`
  },
  addComment: {
    width: '64px',
    height: '64px',
    background: theme.palette.blue.main,
    color: theme.palette.common.white,
    zIndex: 12,
    boxShadow: '2px 2px 10px 0 rgba(0,0,0,.3)',
    '&:hover': {
      background: theme.palette.lime.main,
      color: theme.palette.black.main
    },
    '& .MuiIcon-fontSizeSmall': {
      fontSize: '20px',
      lineHeight: '22px'
    }
  },
  disabledComments: {
    background: theme.palette.grey[400],
    '&:hover': {
      background: theme.palette.grey[400],
      color: theme.palette.common.white
    }
  }
}))
export interface QueryCommentsProps {
  className?: string
  noHeader?: boolean
  showAddCommentButton?: boolean
  canAddComment?: boolean
}
export const QueryComments: FC<QueryCommentsProps> = ({
  className,
  noHeader = false,
  showAddCommentButton = false,
  canAddComment = false
}) => {
  const classes = useStyles()
  const { filters } = useSelector(useQueries)
  const queryId = useQueryId()
  const { data: selectedQuery } = useGetQueryDataQuery(queryId)
  const { data: comments = [] } = useGetQueryCommentsQuery(queryId)
  const { hasAccess } = useLisaAuth()
  const dispatch = useDispatch()
  const commentsNumber = comments.length
  const setSortOrder = useCallback((commentsSortOrder: SortDirection) => {
    dispatch(updateFilters({
      commentsSortOrder
    }))
  }, [])

  const addQueryCommentButton = useMemo(() => {
    return (
      <Tooltip title="add comment" placement="right">
        <div>
          <IconButton
            className={clsx(classes.addComment, {
              [classes.disabledComments]: !canAddComment
            })}
            aria-label="add comment"
            onClick={() => canAddComment && dispatch(openModal('ADD_COMMENT_TO_QUERY'))}>
            <Icon className="far fa-comment-alt" fontSize="small" />
          </IconButton>
        </div>
      </Tooltip>
    )
  }, [canAddComment])

  if (commentsNumber === 0) {
    return showAddCommentButton ? addQueryCommentButton : null
  }
  return (
    <Grid container direction={'column'} className={clsx(classes.root, className)}>
      <Grid item container justifyContent={'space-between'} style={{ rowGap: 8 }}>
        <Grid item>
          <Typography variant={'subtitle2'}>
            {commentsNumber} COMMENT{commentsNumber % 10 !== 1 && 'S'}
          </Typography>
        </Grid>
        <Grid item>
          <Chip
            className={clsx(classes.timelineChip, { [classes.timelineChipActive]: filters.commentsSortOrder === 'desc' })}
            clickable
            onClick={() => setSortOrder('desc')}
            label={'Newest first'}/>
          <Chip
            className={clsx(classes.timelineChip, { [classes.timelineChipActive]: filters.commentsSortOrder === 'asc' })}
            clickable
            onClick={() => setSortOrder('asc')}
            label={'Oldest first'}/>
        </Grid>
      </Grid>
      {
        !noHeader &&
        <Grid item container className={classes.commentsHeader} justifyContent={'space-between'}>
          <Grid item>Entity comments</Grid>
          <Grid item>Client comments</Grid>
        </Grid>
      }
      <Grid item container direction={'column'}>
        {
          sortQueryComments(comments, filters.commentsSortOrder)
            .filter(_ => hasAccess('perm_view_query_internal') || _.commentVisibility === CommentVisibility.External)
            .map((comment) => (
              <Grid
                item
                container
                key={comment.queryCommentId}
                className={clsx(classes.commentContainer, { [classes.external]: comment.isExternal })}>
                <Paper
                  elevation={0}
                  className={classes.paper}>
                  <QueryComment
                    comment={comment}
                    reporterId={selectedQuery!.reporter}
                    assigneeId={selectedQuery!.assignTo}/>
                </Paper>
              </Grid>
            ))
        }
      </Grid>
      { showAddCommentButton ? addQueryCommentButton : null }
    </Grid>
  )
}
