import { FC, KeyboardEvent, MouseEvent, useRef, useState } from 'react'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { Grid, makeStyles, Tooltip } from '@material-ui/core'
import InputSearch from 'common/LisaControls/InputSearch'
import Dropdown from 'common/LisaControls/Dropdown'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.black.main,
    '& .MuiToggleButtonGroup-root': {
      display: 'flex',
      '& .MuiToggleButton-root': {
        flex: 1,
        border: 'none',
        fontWeight: 600,
        '&:hover': {
          background: 'none'
        },
        '&.Mui-selected': {
          background: 'none',
          borderRadius: '0px',
          boxShadow: `inset 0 -4px ${theme.palette.lime.main}`
        }
      }
    }
  },
  tooltip: {
    color: theme.palette.black.main,
    background: theme.palette.statusColors.orange,
    padding: '2px 8px',
    borderRadius: '4px'
  },
  arrow: {
    color: theme.palette.statusColors.orange
  },
  searchTabButtons: {
    '&.MuiToggleButtonGroup-root': {
      background: theme.palette.common.white
    },
    '& .MuiToggleButton-root': {
      padding: '4px',
      '&:hover': {
        background: `${theme.palette.lime.main} !important`
      }
    },
    '& .MuiToggleButton-label': {
      color: 'black',
      fontWeight: 600,
      height: '16px'
    },
    '&.MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-selected': {
      boxShadow: 'none',
      background: theme.palette.blue.main,
      color: theme.palette.common.white,
      '&:hover': {
        background: `${theme.palette.blue.main} !important`
      },
      '& .MuiToggleButton-label': {
        color: 'white'
      }
    }
  },
  operatorWrapper: {
    height: '24px',
    borderRadius: '4px',
    overflow: 'hidden',
    marginTop: '5px'
  },
  conditionDropDown: {
    width: 100,
    marginRight: 8
  }
}))

export type SearchCondition = 'matchCase' | 'operatorAnd' | 'operatorOr' | null

type SearchInputWithConditionsProps = {
  searchText: string
  onSearchTextChange(searchText: string): void
  condition: SearchCondition
  onConditionChange(condition: 'matchCase' | 'operatorAnd' | 'operatorOr' | null): void
  onClick(): void
  conditionPicker?: 'dropdown' | 'toggle'
}

export const SearchInputWithConditions: FC<SearchInputWithConditionsProps> = ({
  onClick, searchText, onSearchTextChange, onConditionChange, condition, conditionPicker = 'toggle'
}) => {
  const classes = useStyles()
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)
  const ref = useRef(null)

  const enterPressed = (event: KeyboardEvent) => {
    event.preventDefault()
    if (searchText.length === 0 || searchText.length > 2) {
      setTooltipIsOpen(false)
      if (event.key === 'Enter') {
        onClick()
      }
      if (event.key === 'Backspace') {
        if (searchText.length === 0) {
          onClick()
        }
      }
    } else {
      if (event.key === 'Enter') {
        setTooltipIsOpen(true)
      }
    }
  }

  return (
    <div className={classes.root}>
      <Tooltip
        open={tooltipIsOpen}
        title="Min. 3 characters needed to perform search"
        placement="top"
        onOpen={() => setTooltipIsOpen(true)}
        onClose={() => setTooltipIsOpen(false)}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}>
        <div className={'searchInput'} ref={ref}>
          <Grid container>
            {
              conditionPicker === 'dropdown' &&
              <Grid item>
                <Dropdown
                  className={classes.conditionDropDown}
                  showSelectOption={false}
                  value={condition}
                  onChange={(e) => onConditionChange(e.target.value as SearchCondition)}
                  items={[
                    { value: 'operatorAnd', text: 'And' },
                    { value: 'operatorOr', text: 'Or' },
                    { value: 'matchCase', text: 'Match' }
                  ]}/>
              </Grid>
            }
            <Grid item xs>
              <InputSearch
                name="searchText"
                label="Find document"
                onChange={(e) => onSearchTextChange(e.currentTarget.value)}
                onClick={onClick}
                value={searchText}
                onKeyUp={enterPressed}/>
            </Grid>
          </Grid>
          {
            conditionPicker === 'toggle' &&
            <div className={classes.operatorWrapper}>
              <ToggleButtonGroup
                value={condition}
                exclusive
                onChange={(e: MouseEvent<HTMLElement>, value) => onConditionChange(value)}
                aria-label="text alignment"
                className={classes.searchTabButtons}>
                <ToggleButton value="operatorOr" aria-label="or" disableRipple>or</ToggleButton>
                <ToggleButton value="operatorAnd" aria-label="and" disableRipple>and</ToggleButton>
                <ToggleButton value="matchCase" aria-label="exact" disableRipple>match</ToggleButton>
              </ToggleButtonGroup>
            </div>
          }
        </div>
      </Tooltip>
    </div>
  )
}
