import { FC, useState } from 'react'
import { IconButton, InputAdornment, makeStyles, TextField, TextFieldProps } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { FormField } from 'common/FormField/FormField'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  input: {
    height: '42px',
    '& $notchedOutline': {
      borderWidth: 0
    },
    '&:hover $notchedOutline': {
      borderWidth: 0
    },
    '&:focused $notchedOutline': {
      borderWidth: 0
    },
    '& .Mui-disabled': {
      background: theme.palette.grey2.light
    }
  },
  notchedOutline: {}
}))

export type LisaInputField = TextFieldProps & {
  errMsg?: string
  allowShowPassword?: boolean
}

const Input: FC<LisaInputField> = ({
  label,
  required,
  className,
  errMsg,
  type,
  InputProps,
  allowShowPassword = false,
  ...props
}) => {
  const isPassword = type === 'password'
  const [showPassword, setShowPassword] = useState(false)

  const classes = useStyles()

  const handleClickShowPassword = () => {
    allowShowPassword && setShowPassword(!showPassword)
  }

  return (
    <FormField label={label} required={required} error={errMsg}>
      <TextField
        {...props}
        type={isPassword && showPassword ? 'text' : type}
        error={!!errMsg}
        InputProps={{
          ...InputProps,
          endAdornment: isPassword && allowShowPassword
            ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end">
                  {!showPassword ? <VisibilityOff/> : <Visibility/>}
                </IconButton>
              </InputAdornment>
            )
            : null
        }}
        className={clsx(classes.input, className)}
      />
    </FormField>
  )
}

export default Input
