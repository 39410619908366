import { FC } from 'react'
import { TransitionComponent, TransitionFieldType } from 'types'
import { wantsTransitionField } from 'utils/transition'
import { Grid, GridProps } from '@material-ui/core'

type TransitionFieldProps = GridProps & {
  fieldType: TransitionFieldType
  transitionComponent: TransitionComponent
}

export const TransitionField: FC<TransitionFieldProps> = ({
  children,
  transitionComponent,
  fieldType,
  ...gridProps
}) => {
  if (!wantsTransitionField(transitionComponent, fieldType)) {
    return null
  }
  return (
    <Grid {...gridProps}>
      {children}
    </Grid>
  )
}
