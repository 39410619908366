import { FC, useMemo } from 'react'
import { Report } from 'common/Report/Report'
import { ChartData, ChartOptions, ChartType } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { WithReportLoader } from 'common/WithLoader/WithLoader'
import { useGetTransitionQuery } from 'services/api/transitionApi'
import { useTransitionId } from 'context/TransitionContext'
import { ProcessingReport, ProcessingStatusesNames } from 'types'
import { useGetProcessingReportQuery } from 'services/api/ocrApi'

const getConfig = (title: string): ChartOptions => ({
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    mode: 'index'
  },
  plugins: {
    legend: {
      align: 'center',
      display: true,
      position: 'top'
    },
    title: {
      display: true,
      text: title,
      align: 'start',
      padding: {
        bottom: 20
      }
    },
    tooltip: {
      intersect: false,
      itemSort: () => -1,
      callbacks: {
        label: ({ formattedValue, dataset: { label } }) => {
          return `${label}: ${formattedValue}%`
        }
      }
    }
  },
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true,
      ticks: {
        callback: _ => `${_}%`
      },
      max: 100
    }
  }
})

const initialData = (): ChartData<ChartType, number[]> => ({
  labels: [ProcessingStatusesNames.DATA_EXTRACTION, ProcessingStatusesNames.SHORT_DTR, ProcessingStatusesNames.AUTO_LABELING],
  datasets: [
    {
      label: 'Sucess',
      backgroundColor: '#00C128',
      maxBarThickness: 60,
      data: []
    },
    {
      label: 'Waiting',
      backgroundColor: '#008000',
      maxBarThickness: 60,
      data: []
    },
    {
      label: 'Error',
      backgroundColor: '#E20001',
      maxBarThickness: 60,
      data: []
    }
  ]
})

const percentageCalculator = (n: number, total: number): number => {
  return Math.round(((n / total) * 100))
}

const chartArray = (n: number[], total: number) => {
  const chartArr = n.map((_) => {
    const percentage = percentageCalculator(_, total)
    return percentage
  })
  return chartArr
}

const chartData = (data: ProcessingReport): ChartData => {
  const total = data.dataExtractionSuccess + data.dataExtractionInQueue + data.dataExtractionError
  const dExt = chartArray([data.dataExtractionSuccess, data.dataExtractionInQueue, data.dataExtractionError], total)
  const dtr = chartArray([data.dtrSuccess, data.dtrInQueue, data.dtrError], total)
  const aLab = chartArray([data.autoLabellingSuccess, data.autoLabellingInQueue], total)
  const value = initialData()
  value.datasets[0].data.push(dExt[0], dtr[0], aLab[0])
  value.datasets[1].data.push(dExt[1], dtr[1])
  value.datasets[2].data.push(dExt[2], dtr[2], aLab[1])

  return value
}

export const ProcessingStatusReport: FC = () => {
  const { data: transitionData } = useGetTransitionQuery(useTransitionId())
  const transition = transitionData!
  const { transitionId } = transition
  const { data: processingReport, isLoading } = useGetProcessingReportQuery(transitionId)
  const config = useMemo(() => getConfig(transition.name), [transition.name])
  const data = processingReport!

  const ChartElement = useMemo(() => {
    return <Bar type={'bar'} data={chartData(data)} options={config} />
  }, [data, config])

  return (
    <Report
      title={''}
    >
      <WithReportLoader
        noWrapper
        loading={isLoading}
        hasResults={true}>
        {ChartElement}
      </WithReportLoader>
    </Report>
  )
}
