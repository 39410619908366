import {
  QueriesRequestParams,
  Query,
  QueryComment,
  QuerySortOptions,
  QueryStatus,
  QueryStatusReport,
  RiskLevel,
  SortDirection,
  TransitionId,
  UserId
} from 'types'
import { QueryFilters } from 'redux/slices/queriesSlice'

export const sortQueriesByDate = (queries: Query[], direction: SortDirection = 'asc'): Query[] => [...queries].sort(
  (q1, q2) => (direction === 'asc' ? 1 : -1) * q1.createdDate.localeCompare(q2.createdDate))

export const shouldDoSearch = (filters: QueryFilters): boolean =>
  !!(filters.queryStatus !== 0 || filters.searchTerm || filters.queryType || filters.assigneeId || filters.reporterId)

export const filterToQueryParams = (filters: QueryFilters, transitionId: TransitionId, userId: UserId): QueriesRequestParams => ({
  queryStatus: filters.queryStatus ?? '',
  searchText: filters.searchTerm ?? '',
  queryType: filters.queryType ?? '',
  assignTo: filters.assigneeId ?? '',
  reporter: filters.reporterId ?? '',
  labelId: filters.labelId ?? '',
  label: filters.label ?? '',
  documentId: filters.documentId ?? '',
  documentName: filters.documentName ?? '',
  transitionId: transitionId.toUpperCase(),
  wildcard: !!filters.searchTerm.match(/(\*|\?)/),
  userId: userId,
  related: !filters.related
})

export const queryStatusOptions = [
  {
    value: '',
    text: 'All Queries'
  },
  {
    value: QueryStatus.Unresolved,
    text: 'Unresolved Queries'
  },
  {
    value: QueryStatus.New,
    text: 'New'
  },
  {
    value: QueryStatus.Open,
    text: 'Open'
  },
  {
    value: QueryStatus.Pending,
    text: 'Pending'
  },
  {
    value: QueryStatus.WorkPack,
    text: 'WorkPack'
  },
  {
    value: QueryStatus.Delayed,
    text: 'Delayed'
  },
  {
    value: QueryStatus.Closed,
    text: 'Closed'
  },
  {
    value: QueryStatus.Sideletter,
    text: 'Sideletter'
  }
]

export const queryStatusColors: Map<QueryStatus, string> = new Map([
  [QueryStatus.New, '#a9a9a9'],
  [QueryStatus.Open, '#ffeb06'],
  [QueryStatus.Pending, '#ff9f24'],
  [QueryStatus.Delayed, '#e3060a'],
  [QueryStatus.WorkPack, '#9929bd'],
  [QueryStatus.Closed, '#06c22d'],
  [QueryStatus.Sideletter, '#069fe5']
])

export const labelForStatus = (status: QueryStatus): string =>
  queryStatusOptions.find(_ => _.value === status)?.text ?? ''

export const sortQueryComments = (queryComments: QueryComment[], direction: SortDirection = 'asc'): QueryComment[] =>
  [...queryComments].sort((q1, q2) =>
    (direction === 'asc' ? 1 : -1) * q1.createdDate.localeCompare(q2.createdDate))

export const calculateTotal = (qr: QueryStatusReport): QueryStatusReport => ({
  ...qr,
  totalQueriesCount: qr.newQueriesCount +
    qr.openQueriesCount +
    qr.pendingQueriesCount +
    qr.delayedQueriesCount +
    qr.sideletterQueriesCount +
    qr.closedQueriesCount
})

/**
 * for list of queries builds query status report
 * @param queries
 */
export const buildQueryStatusReport = (queries: Query[]): QueryStatusReport => {
  return queries.reduce<QueryStatusReport>((qsr, query) => {
    switch (query.queryStatus) {
    case QueryStatus.Open:
      qsr.openQueriesCount += 1
      break
    case QueryStatus.Pending:
      qsr.pendingQueriesCount += 1
      break
    case QueryStatus.Closed:
      qsr.closedQueriesCount += 1
      break
    case QueryStatus.Delayed:
      qsr.delayedQueriesCount += 1
      break
    case QueryStatus.Sideletter:
      qsr.sideletterQueriesCount += 1
      break
    case QueryStatus.WorkPack:
      qsr.workPackQueriesCount += 1
      break
    case QueryStatus.New:
    default:
      qsr.newQueriesCount += 1
      break
    }
    return qsr
  }, {
    closedQueriesCount: 0,
    newQueriesCount: 0,
    openQueriesCount: 0,
    pendingQueriesCount: 0,
    delayedQueriesCount: 0,
    sideletterQueriesCount: 0,
    workPackQueriesCount: 0,
    totalQueriesCount: queries.length
  })
}

export const filterQueriesByTransition = <Q extends { transitionId: TransitionId }> (queries: Q[], transitionId: TransitionId): Q[] => {
  return queries.filter(_ => transitionId === '' || _.transitionId === transitionId)
}

export const sortQueries = <Q extends { riskLevel: RiskLevel, createdDate: string, dueDate: string | Date, critical: boolean }> (
  queries: Q[], sortOptions: QuerySortOptions
): Q[] => {
  const queriesCopy = queries.slice()
  if (sortOptions.prioritySort !== false) {
    return queriesCopy.sort((a, z) =>
      (a.riskLevel + (a.critical ? 1 : 0) - (z.riskLevel + (z.critical ? 1 : 0))) * // Add + 1 if critical
      (sortOptions.prioritySort === 'asc' ? 1 : -1))
  }
  if (sortOptions.dueDateSort !== false) {
    return queriesCopy.sort((a, z) => {
      return a.dueDate.toString().localeCompare(z.dueDate.toString()) * (sortOptions.dueDateSort === 'asc' ? 1 : -1)
    })
  }
  if (sortOptions.timeAddedSort !== false) {
    return queriesCopy.sort((a, z) => {
      return a.createdDate.toString().localeCompare(z.createdDate.toString()) * (sortOptions.timeAddedSort === 'asc' ? 1 : -1)
    })
  }
  return queriesCopy
}

export const queryRiskLevels = [
  {
    value: RiskLevel.None,
    text: 'Select'
  },
  {
    value: RiskLevel.Information,
    text: 'Info'
  },
  {
    value: RiskLevel.Low,
    text: 'Low'
  },
  {
    value: RiskLevel.Medium,
    text: 'Medium'
  },
  {
    value: RiskLevel.High,
    text: 'High'
  }
]
