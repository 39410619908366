import { ChangeEvent, FC, useState } from 'react'
import ContentWrapper from 'components/ContentWrapper/ContentWrapper'
import { makeStyles, Tab, Tabs } from '@material-ui/core'
import { TabContext, TabPanel } from '@material-ui/lab'
import Ocr from 'pages/Ocr/Ocr'
import clsx from 'clsx'
import { GeneralProgress } from './components/GeneralProgress'
import { useGetTransitionQuery } from 'services/api/transitionApi'
import { useTransitionId } from 'context/TransitionContext'
import { useLisaAuth } from 'hooks/useLisaAuth'

const useStyles = makeStyles((theme) => ({
  tabs: {
    backgroundColor: theme.palette.common.white,
    marginBottom: 16,
    color: theme.palette.blue.main,
    '& .MuiTab-textColorInherit': {
      opacity: 1
    }
  },
  tabsIndicator: {
    backgroundColor: `${theme.palette.blue.main} !important`,
    height: '5px !important'
  },
  tabPanel: {
    padding: 0,
    flexGrow: 1,
    display: 'flex'
  }
}))

enum ReportTabs {
  TRANSITION = '0',
  OCR = '1'
}

export const TransitionReports: FC = () => {
  const classes = useStyles()
  const { hasAccess } = useLisaAuth()
  const hasAssetsAccess = hasAccess('perm_view_areport_asset')
  const hasOcrAccess = hasAccess('perm_view_areport_processing')
  const [activeTab, setActiveTab] = useState<ReportTabs>(!hasAssetsAccess ? ReportTabs.OCR : ReportTabs.TRANSITION)
  const { isLoading } = useGetTransitionQuery(useTransitionId())
  if (isLoading) {
    return null
  }
  return (
    <ContentWrapper newLayout>
      <TabContext value={activeTab}>
        <Tabs
          classes={{
            root: classes.tabs,
            indicator: classes.tabsIndicator
          }}
          centered
          value={activeTab}
          onChange={(event: ChangeEvent<{}>, v) => setActiveTab(v)}
          aria-label="Report view">
          {hasAssetsAccess && <Tab value={ReportTabs.TRANSITION} label="Asset reports"/>}
          {hasOcrAccess && <Tab value={ReportTabs.OCR} label="Processing reports"/>}
        </Tabs>
        {
          hasAssetsAccess &&
          <TabPanel
            className={clsx({ [classes.tabPanel]: activeTab === ReportTabs.TRANSITION })}
            value={ReportTabs.TRANSITION}>
            <GeneralProgress/>
          </TabPanel>
        }
        {
          hasOcrAccess &&
          <TabPanel
            className={clsx({ [classes.tabPanel]: activeTab === ReportTabs.OCR })}
            value={ReportTabs.OCR}>
            <Ocr/>
          </TabPanel>
        }
      </TabContext>
    </ContentWrapper>
  )
}
