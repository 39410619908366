import { FC, HTMLAttributes, ReactNode } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { CIcon, OIcon } from 'common/Icons/SvgIcons'

type Variant = 'default' | 'small'
type State = 'open' | 'closed'

const text = new Map<Variant, Map<State, ReactNode>>([
  ['default', new Map<State, ReactNode>([
    ['open', 'Open'],
    ['closed', 'Closed']
  ])],
  ['small', new Map<State, ReactNode>([
    ['open', <OIcon key={'open'}/>],
    ['closed', <CIcon key={'close'}/>]
  ])]
])

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    display: 'inline-block',
    fontWeight: 600
  },
  open: {
    backgroundColor: theme.palette.statusColors.red
  },
  closed: {
    backgroundColor: theme.palette.statusColors.green
  },
  default: {
    paddingInline: 8,
    borderRadius: 4,
    fontSize: 13,
    width: 60,
    textAlign: 'center'
  },
  small: {
    minWidth: 16,
    maxWidth: 16,
    minHeight: 16,
    maxHeight: 16,
    borderRadius: 2
  }
}))

type OpenOrClosedProps = HTMLAttributes<HTMLSpanElement> & {
  state: State
  variant?: Variant
}

export const OpenOrClosed: FC<OpenOrClosedProps> = ({
  state,
  variant = 'default',
  className,
  ...spanProps
}) => {
  const classes = useStyles()
  return (
    <span
      {...spanProps}
      className={clsx(
        classes.root,
        {
          [classes.open]: state === 'open',
          [classes.closed]: state === 'closed',
          [classes.default]: variant === 'default',
          [classes.small]: variant === 'small'
        },
        className)}>
      {text.get(variant)?.get(state) ?? ''}
    </span>
  )
}
