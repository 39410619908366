import { FC } from 'react'
import { useSelector } from 'react-redux'
import { LabelHierarchy } from 'common/LabelHierarchy/LabelHierarchy'
import { makeStyles } from '@material-ui/core'
import { LabelList } from 'common/LabelList/LabelList'
import { LoadingState } from 'types'
import { useLabelsContext } from 'context/LabelsContext'
import LisaLoader from 'common/Loaders/LisaLoader'

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
})

type LabelTreeProps = {
  renderSearchResultsTree?: boolean
  a2kRules?: boolean
}

export const LabelTree: FC<LabelTreeProps> = ({ renderSearchResultsTree = false, a2kRules = false }) => {
  const { selector } = useLabelsContext()
  const classes = useStyles()
  const {
    hierarchy,
    loadingHierarchy,
    searchTerm,
    searchResults
  } = useSelector(selector)
  if (loadingHierarchy === LoadingState.Init) {
    return null
  }
  if (loadingHierarchy === LoadingState.Pending) {
    return (
      <LisaLoader
        text="Loading labels..."
        minusHeight={0}
        inline={false}/>
    )
  }
  if (hierarchy === null) return null
  return (
    <div className={classes.root}>
      {
        !!searchTerm && !renderSearchResultsTree &&
        <LabelList labels={searchResults}/>
      }
      {
        (!searchTerm || renderSearchResultsTree) &&
        <LabelHierarchy
          onlySelectedLabels={renderSearchResultsTree && searchResults.length > 0}
          label={hierarchy}
          depth={0}
          a2kRules={a2kRules}/>
      }
    </div>
  )
}
