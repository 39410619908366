import { FC } from 'react'
import { Box, LinearProgress, LinearProgressProps, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles<Theme, { barColor?: string }>((theme) => ({
  root: {
    width: '100%',
    '& .MuiBox-root': {
      display: 'block',
      padding: '2px 0'
    }
  },
  barColorPrimary: {
    backgroundColor: 'transparent',
    background: (props) => props.barColor ?? theme.palette.statusColors.green
  },
  low: { background: `${theme.palette.statusColors.red} !important` },
  medium: { background: `${theme.palette.statusColors.orange} !important` },
  high: { background: `${theme.palette.statusColors.green} !important` }
}))

type ProgressBarProps = LinearProgressProps & {
  value: number
  label?: (value: number) => string
  barColor?: string
}
export const ProgressBar: FC<ProgressBarProps> = ({ value, label, barColor, ...props }) => {
  const classes = useStyles({ barColor })
  return (
    <Box display="flex" alignItems="center" flexDirection="column" gridRowGap="5px" className={classes.root}>
      {
        label &&
          <Box width="100%" mr={1}>
            <Typography variant="body2" color="textSecondary">
              {label(value)}
            </Typography>
          </Box>
      }
      <Box width="100%" mr={1}>
        <LinearProgress
          classes={{
            barColorPrimary: clsx(classes.barColorPrimary, {
              [classes.low]: !barColor && value < 50,
              [classes.medium]: !barColor && value >= 50 && value < 80,
              [classes.high]: !barColor && value >= 80
            })
          }}
          value={value}
          variant="determinate"
          {...props}/>
      </Box>
    </Box>
  )
}
