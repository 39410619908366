import { FC, useMemo } from 'react'
import { Report } from 'common/Report/Report'
import { ChartData, ChartOptions } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { WithReportLoader } from 'common/WithLoader/WithLoader'
import { useGetTransitionQuery } from 'services/api/transitionApi'
import { useTransitionId } from 'context/TransitionContext'
import { useGetDocumentWeeklyReportQuery, useUpdateWeeklyReportNotesMutation } from 'services/api/documentApi'
import { DocumentStatusReportValue } from 'types'

const getConfig = (title: string): ChartOptions => ({
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'bottom'
    },
    title: {
      display: true,
      text: title,
      align: 'start',
      padding: {
        bottom: 20
      }
    }
  },
  scales: {
    x: {
    },
    y: {
    }
  }
})

const initialChartData = (categoryPercentage?: number): ChartData => ({
  labels: [],
  datasets: [
    {
      label: 'uploaded',
      backgroundColor: '#009ee5',
      maxBarThickness: 30,
      categoryPercentage,
      data: []
    },
    {
      label: 'digitalised',
      backgroundColor: '#02c12b',
      maxBarThickness: 30,
      categoryPercentage,
      data: []
    }
  ]
})

const chartData = (data: DocumentStatusReportValue[]): ChartData => {
  const dataLength = data.length
  const addCategoryThickness = dataLength < 10
  return data.reduce<ChartData>((cd, { week, digitalisedDocsCount, uploadedDocsCount }) => {
    cd.labels?.push(`W${week}`)
    cd.datasets[0].data.push(uploadedDocsCount)
    cd.datasets[1].data.push(digitalisedDocsCount)
    return cd
  }, initialChartData(addCategoryThickness ? dataLength * 0.1 : 1))
}

export const DocumentStatusReport: FC = () => {
  const { data: transitionData } = useGetTransitionQuery(useTransitionId())
  const transition = transitionData!
  const { transitionId } = transition
  const { data: weeklyReport, isLoading } = useGetDocumentWeeklyReportQuery(transitionId)
  const [updateNotes, { originalArgs }] = useUpdateWeeklyReportNotesMutation()
  const config = useMemo(() => getConfig(transition.name), [transition.name])
  const notes = originalArgs?.notes ?? weeklyReport?.notes ?? ''
  const data = weeklyReport?.data ?? []

  const ChartElement = useMemo(() => {
    return <Bar type={'bar'} data={chartData(data)} options={config} />
  }, [data, config])

  return (
    <Report
      title={'Document Status'}
      info={'Number of text recognised documents in relation to the amount of imported/uploaded documents.'}
      reportNote={{
        note: notes,
        saveNote: (notes) => updateNotes({ notes, transitionId })
      }}>
      <WithReportLoader
        noWrapper
        loading={isLoading}
        hasResults={true}>
        {ChartElement}
      </WithReportLoader>
    </Report>
  )
}
