import { FC, useEffect, useState } from 'react'
import { Grid, Icon, IconButton, makeStyles } from '@material-ui/core'
import { MaybeForbiddenAction } from 'common/MaybeForbiddenAction/MaybeForbiddenAction'
import { useLisaAuth } from 'hooks/useLisaAuth'
import clsx from 'clsx'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { NoteForm } from './NoteForm'
import { useDispatch, useSelector } from 'react-redux'
import { openModal, useApp } from 'redux/slices/appSlice'

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 16,
    '& .MuiButtonBase-root': {
      background: theme.palette.blue.main,
      width: 26,
      height: 26,
      marginRight: 7,
      '&:hover': {
        background: theme.palette.lime.main,
        '& .fa-pen': {
          color: theme.palette.black.main
        }
      },
      '&.editQueryDisabled': {
        background: theme.palette.grey[400]
      }
    },
    '& .fa-pen': {
      color: theme.palette.common.white,
      fontSize: 14
    }
  },
  text: {
    marginTop: 7,
    whiteSpace: 'pre-wrap'
  },
  noText: {
    color: '#9a9a9a'
  }
}))

export type NoteProps = {
  note: string
  saveNote: (note: string) => void
}
export const Note: FC<NoteProps> = ({ note, saveNote }) => {
  const classes = useStyles()
  const [editMode, setEditMode] = useState(false)
  const { activeModal } = useSelector(useApp)
  const dispatch = useDispatch()
  const { hasAccess } = useLisaAuth()
  const canEdit = hasAccess('perm_act_areports_notes')
  const noText = !note

  useEffect(() => {
    if (editMode) {
      dispatch(openModal('EDIT_NOTE'))
    }
  }, [editMode])

  useEffect(() => { // to handle cancel or X of modal
    if (activeModal.length === 0) {
      setEditMode(false)
    }
  }, [activeModal.length])

  return (
    <Grid container direction={'column'}>
      <Grid item className={classes.title}>
        {/* // @todo we need to see if this tooltip should be Edit query or note */}
        <MaybeForbiddenAction isForbidden={!canEdit} tooltip={'Edit query'}>
          <IconButton
            aria-label="edit query"
            onClick={() => setEditMode(true)}>
            <Icon className="fas fa-pen" fontSize="small" />
          </IconButton>
        </MaybeForbiddenAction>
        Notes
      </Grid>
      <Grid item className={clsx(classes.text, { [classes.noText]: noText })}>
        {!noText ? note : 'There are no notes at the moment...'}
      </Grid>
      {
        editMode && // To prevent multiple edit note modals
        <LisaModal title='Edit notes' modalType={'EDIT_NOTE'} maxWidth={'xs'} scroll='body'>
          <NoteForm note={note} onComplete={(text) => {
            saveNote(text)
            setEditMode(false)
          }}/>
        </LisaModal>
      }
    </Grid>
  )
}
