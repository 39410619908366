import { FC } from 'react'
import { Grid, GridProps, makeStyles, useTheme } from '@material-ui/core'
import { ProgressBar } from 'common/ProgressBar/ProgressBar'
import { toFormattedString, toTimeElapsedString } from 'utils/date'
import { LisaBox } from 'common/LisaBox/LisaBox'
import { HighPriorityIndicator } from 'common/Indicators/HighPriorityIndicator'
import clsx from 'clsx'
import { OCRQueue } from 'types'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  root: {
    padding: '8px 16px',
    marginBottom: 4,
    fontSize: 14,
    cursor: 'pointer',
    borderRadius: '0',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, .15)',
    transition: 'all .15s linear',
    '&:hover': {
      background: 'rgba(255,255,255,.7)',
      boxShadow: 'none'
    }
  },
  hidden: {
    cursor: 'default'
  },
  priorityIndicatorContainer: {
    alignSelf: 'baseline',
    paddingTop: 5,
    width: 16
  },
  progressBarContainer: {
  },
  infoCellContainer: {
    padding: '8px 16px',
    minWidth: 0
  },
  infoCellValue: {
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 200,
    fontSize: '15px'
  },
  timeElapsedInfoCell: {
    width: 160
  }
}))

type OCRQueueProgressInfoCellProps = GridProps & {
  title: string
  value: string | number
}
export const OCRQueueProgressInfoCell: FC<OCRQueueProgressInfoCellProps> = ({
  title, value, className, ...gridProps
}) => {
  const classes = useStyles()
  return (
    <Grid className={clsx(className, classes.infoCellContainer)} item {...gridProps}>
      <div>{title}</div>
      <div className={classes.infoCellValue}>{ value }</div>
    </Grid>
  )
}

type OCRQueueProgressProps = OCRQueue & {
  orderNo: number
}
export const OCRQueueProgress: FC<OCRQueueProgressProps> = ({
  transitionName,
  transitionId,
  orderNo,
  ocrQueuePercents,
  TotalDocuments,
  importDate,
  importType,
  isHighPriority,
  timeElapsed
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { hasAccess } = useLisaAuth()
  const history = useHistory()
  const canViewA2k = hasAccess('perm_view_a2kstructure')
  const importDt = new Date(importDate)
  const canClick: boolean = transitionName !== 'Hidden'

  return (
    <LisaBox
      container
      direction={'column'}
      className={clsx('ocrProgressItem', classes.root, { [classes.hidden]: !canClick })}
      onClick={() => {
        canClick && history.push(`/structures/${canViewA2k ? 'import' : 'client'}/${transitionId}`)
      }
      }>
      <Grid container alignItems={'center'} >
        <Grid item>{ orderNo }.</Grid>
        <Grid container item xs>
          <Grid item xs>
            <Grid container>
              <OCRQueueProgressInfoCell
                xs
                title={toFormattedString(importDt)}
                value={importType}/>
              <OCRQueueProgressInfoCell
                xs
                title={'Asset'}
                value={transitionName}/>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <OCRQueueProgressInfoCell
                xs={6}
                title={'Documents'}
                value={TotalDocuments}/>
              <OCRQueueProgressInfoCell
                className={classes.timeElapsedInfoCell}
                xs={6}
                title={'Time elapsed'}
                value={toTimeElapsedString(timeElapsed)}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.priorityIndicatorContainer}>
          {
            isHighPriority && <HighPriorityIndicator/>
          }
        </Grid>
      </Grid>
      <Grid item className={classes.progressBarContainer}>
        <ProgressBar value={ocrQueuePercents} barColor={theme.palette.statusColors.green}/>
      </Grid>
    </LisaBox>
  )
}
