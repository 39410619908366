import { FC, KeyboardEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { onSubLabelAdded, useA2KLabels } from 'redux/slices/a2kLabelsSlice'
import { LisaForm, useLisaForm } from 'common/Form/LisaForm'
import { useTransitionContext } from 'context/TransitionContext'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { hasAllowedCharacters, INVALID_LABEL_NAME } from 'utils/validators'
import { useAddSubLabelMutation } from 'services/api/labellingApi'
import Input from 'common/LisaControls/Input'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '32px',
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  paper: {
    padding: '24px'
  },
  sectionTitle: {
    fontSize: '14px',
    margin: '32px 0 6px 0',
    fontWeight: 700,
    letterSpacing: '.8px',
    textTransform: 'uppercase'
  },
  actionButtons: {
    padding: '32px 0',
    display: 'flex',
    // columnGap: "10px",
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  }
}))

interface FormValues {
  name: string
}
interface FormErrors {
  name: string
}
const initialFormValues: FormValues = {
  name: ''
}

const AddLabelModal: FC = () => {
  const { selected: label } = useSelector(useA2KLabels)
  const { transitionId } = useTransitionContext()
  const classes = useStyles()
  const dispatch = useDispatch()
  const canAddSubLabel = (label!.level ?? 0) < 7
  const [addSubLabel] = useAddSubLabelMutation()

  const {
    values, handleInputChange
  } = useLisaForm<FormValues, FormErrors>(initialFormValues)

  const isEmpty = values.name.trim().length === 0
  const isNameValid = hasAllowedCharacters(values.name)
  const nameError = isNameValid || isEmpty ? '' : INVALID_LABEL_NAME

  const close = () => dispatch(closeModal())

  const handleAddNewLabel = async () => {
    if (isNameValid) {
      const { isError, message, structure } = await addSubLabel({
        parentId: label!.labelId,
        labelName: values.name.trim(),
        transitionId
      }).unwrap()
      if (isError) {
        dispatch(openToast({
          severity: 'error',
          message
        }))
      } else {
        dispatch(onSubLabelAdded(structure))
        close()
      }
    }
  }

  const handleKeyUp = async (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      await handleAddNewLabel()
    }
  }

  return (
    <div className={classes.root}>
      {
        !canAddSubLabel &&
          <>
            <Typography variant="body1">Action not possible.</Typography>
            <Typography variant="body2" noWrap>You cannot add label more than seven levels.</Typography>
          </>
      }
      {
        canAddSubLabel &&
        <LisaForm onSubmit={e => { e.preventDefault() }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Input
                label="Label name"
                name="name"
                required={true}
                value={values.name}
                onChange={handleInputChange}
                onKeyUp={handleKeyUp}
                errMsg={nameError ?? ''}
              />
            </Grid>
          </Grid>
        </LisaForm>
      }
      <div className={classes.actionButtons}>
        {
          canAddSubLabel &&
          <Button
            onClick={handleAddNewLabel}
            variant="contained"
            color="primary"
            size="small"
            disabled={isEmpty || !isNameValid}>
            Add
          </Button>
        }
        <Button
          onClick={close}
          variant="contained"
          color="primary"
          size="small"
        >
          {canAddSubLabel ? 'Cancel' : 'Close'}
        </Button>
      </div>
    </div>
  )
}

export default AddLabelModal
