import { FC, KeyboardEvent, useEffect, useState } from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { resetFilters, updateFilters, useQueries } from 'redux/slices/queriesSlice'
import { TransitionTeamSelect } from 'common/TransitionTeamSelect/TransitionTeamSelect'
import { QueryTypeSelect } from 'common/Select/QueryTypeSelect'
import { QueryStatusSelect } from 'common/Select/QueryStatusSelect'
import InputSearch from 'common/LisaControls/InputSearch'

const useStyles = makeStyles((theme) => ({
  search: {
    marginTop: '20px'
  },
  lisaControls: {
    columnGap: 8,
    display: 'flex',
    '& .MuiInputBase-root': {
      marginRight: '8px'
    }
  },
  resetFilters: {
    marginTop: '20px',
    minWidth: '110px !important',
    lineHeight: '12px !important',
    padding: '13px 10px !important',
    height: '42px !important',
    letterSpacing: '.15px !important',
    background: 'transparent !important',
    color: `${theme.palette.blue.main} !important`,
    '&:hover': {
      background: `${theme.palette.lime.main} !important`,
      color: theme.palette.black.main
    }
  }
}))

export const QueriesFilter: FC = () => {
  const { filters } = useSelector(useQueries)
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState(filters.searchTerm)
  const ifLabel = filters.label ? { name: filters.label, labelId: +filters.labelId } : null
  const ifDocumentName = filters.documentName ? { name: filters.documentName, labelId: 0 } : null
  const fromClient = filters.fromClientStructure

  useEffect(() => () => {
    dispatch(resetFilters())
  }, [])

  const search = (term?: string) => {
    if (term !== undefined) {
      setSearchTerm(term)
    }
    dispatch(updateFilters({ searchTerm: term ?? searchTerm }))
  }

  const enterPressed = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      search()
    }
    if (e.key === 'Backspace') {
      if (searchTerm.length === 0) {
        search()
      }
    }
  }

  const classes = useStyles()
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={4} className={classes.search}>
        <InputSearch
          name="searchText"
          label="Find Queries"
          labelBase={ifLabel}
          documentName={ifDocumentName}
          clearAction={true}
          value={searchTerm}
          onChange={(e) => e && setSearchTerm(e.currentTarget.value)}
          onClear={() => search('')}
          onClick={() => search()}
          onKeyUp={enterPressed}
          handleDeleteLabel={() => dispatch(resetFilters())}
          fromClient={fromClient}
        />
      </Grid>
      <Grid item xs={12} lg={8} className={classes.lisaControls}>
        <QueryStatusSelect
          showUnresolvedOption
          value={filters.queryStatus ?? ''}
          ignoreRoles
          onChange={(queryStatus) => {
            dispatch(updateFilters({ queryStatus: queryStatus !== '' ? queryStatus : null }))
          }}/>
        <TransitionTeamSelect
          label={'Assignee'}
          userId={filters.assigneeId ?? ''}
          onChange={(assigneeId) => dispatch(updateFilters({ assigneeId }))}/>
        <TransitionTeamSelect
          label={'Reporter'}
          userId={filters.reporterId ?? ''}
          onChange={(reporterId) => dispatch(updateFilters({ reporterId }))}/>
        <QueryTypeSelect
          value={filters.queryType ?? ''}
          onChange={(queryType) => dispatch(updateFilters({ queryType: queryType || null }))}/>
        <Button
          className={classes.resetFilters}
          style={{ fontWeight: 600 }} // fontWeight too restrictive. Fixed in v5
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            setSearchTerm('')
            dispatch(resetFilters())
          }}>
            reset
        </Button>
      </Grid>
    </Grid>
  )
}
