import { FC, useState } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { SearchInputWithConditions } from 'common/DocumentSearch/components/SearchInputWithConditions'
import { useDocumentSearch } from 'common/DocumentSearch/components/useDocumentSearch'
import { useTransitionContext } from 'context/TransitionContext'
import { useSelector } from 'react-redux'
import { useSearchDocuments } from 'redux/slices/searchDocumentsSlice'
import * as Icons from 'common/Icons/SvgIcons'
import { SearchFilters } from 'common/DocumentSearch/components/SearchFilters'
import { GridGrow } from 'common/Grid/GridGrow'
import { SearchResults } from './SearchResults'

const useStyles = makeStyles((theme) => ({
  tabTitle: {
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '4px',
    lineHeight: '24px',
    height: '30px'
  },

  filterIco: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    '& .MuiSvgIcon-root': {
      cursor: 'pointer',
      marginTop: '2px',
      width: '20px',
      height: '20px',
      fill: theme.palette.black.main
    },
    '&:hover': {
      background: theme.palette.lime.main
    }
  }
}))

export const Search: FC = () => {
  const classes = useStyles()
  const { transitionId } = useTransitionContext()
  const { searchParams } = useSelector(useSearchDocuments)
  const {
    searchText,
    setSearchText,
    condition,
    handleCondition,
    search
  } = useDocumentSearch(transitionId, searchParams.searchText)
  const [showFilters, setShowFilters] = useState(false)

  return (
    <Grid container direction={'column'} spacing={2} style={{ height: '100%' }}>
      <Grid item container alignItems={'center'} justifyContent={'space-between'}>
        <Typography className={classes.tabTitle} variant="h6">
          Search
        </Typography>
        <div
          className={classes.filterIco}
          onClick={() => setShowFilters(!showFilters)}>
          <Icons.Filter />
        </div>
      </Grid>
      <Grid item>
        <SearchInputWithConditions
          searchText={searchText}
          onSearchTextChange={setSearchText}
          condition={condition}
          onConditionChange={handleCondition}
          onClick={() => search(searchParams)}/>
      </Grid>
      {
        showFilters &&
        <Grid item>
          <SearchFilters compact setSearchText={setSearchText} />
        </Grid>
      }
      <GridGrow item container>
        <SearchResults searchText={searchText}/>
      </GridGrow>
    </Grid>
  )
}
