import { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { Logo } from 'common/Logo/Logo'
import { useHistory, useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: `radial-gradient(${theme.palette.blue.main} 30%, #000532 80%)`,
    height: '100vh',
    textAlign: 'center'
  },
  logoContainer: {
    padding: 24
  },
  errorHeading: {
    display: 'inline-block',
    width: 'auto',
    padding: '4px 12px',
    lineHeight: '1.8rem',
    textTransform: 'uppercase',
    background: theme.palette.statusColors.red,
    color: theme.palette.blue.main,
    fontWeight: 900,
    fontSize: '2rem',
    letterSpacing: '-.5px',
    transform: 'rotate(-4deg)'
  },
  paragraph: {
    color: theme.palette.common.white,
    fontWeight: 300,
    fontSize: '1.2rem'
  },
  button: {
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'uppercase',
    border: 'none',
    padding: '10px 24px',
    background: theme.palette.lime.main,
    borderRadius: 4,
    cursor: 'pointer',
    color: theme.palette.blue.main,
    '&:hover': {
      background: theme.palette.common.white
    }
  }
}))

type HttpCodes = '400' | '401' | '403' | '404' | '500'

const codeMessages = new Map([
  ['400', 'Bad request'],
  ['401', 'Unauthorized'],
  ['403', 'Forbidden'],
  ['404', 'Page not found'],
  ['500', 'Internal server error']
])

type ErrorPageProps = {}
export const ErrorPage: FC<ErrorPageProps> = () => {
  const { code } = useParams<{code: HttpCodes}>()
  const classes = useStyles()
  const history = useHistory()
  return (
    <div className={classes.root}>
      <div className="message-wrapper">
        <div className={classes.logoContainer}>
          <Logo/>
        </div>
        <h1 className={classes.errorHeading}>{code} Error. {codeMessages.get(code) ?? 'Unknown error.'}</h1>
        <p className={classes.paragraph}>We apologise for any inconveniences caused.</p>
        <button
          className={classes.button}
          type="button"
          onClick={() => {
            if (code === '401' || code === '403') {
              history.push('/dashboard')
            } else if (history.length > 0) {
              history.goBack()
            } else {
              history.push('/dashboard')
            }
          }}>
          Go back
        </button>
      </div>
    </div>
  )
}
