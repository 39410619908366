import { FC, memo } from 'react'
import { makeStyles, TableRow, TableRowProps } from '@material-ui/core'
import { TableViewRow as TableViewRowT } from 'types'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { TableViewCell } from './TableViewCell'
import { selectCell } from 'redux/slices/documentSlice'

const useStyles = makeStyles(() => ({
  firstColumn: {
    left: 0,
    minWidth: 30,
    maxWidth: 30,
    textAlign: 'center'
  },
  secondColumn: {
    left: 48,
    minWidth: 130,
    maxWidth: 130
  },
  thirdColumn: {
    left: 212,
    minWidth: 50,
    maxWidth: 50
  },
  mediumCol: {
    minWidth: 150
  },
  selected: {
    border: '1px solid red !important'
  }
}))

const isMediumColumn = (name: string): boolean => ['Subject'].includes(name)

type TableViewRowProps = TableRowProps & {
  rowData: TableViewRowT
  selectedColumn?: number
}
export const TableViewHeaderRow: FC<TableViewRowProps> = memo(({
  rowData,
  selectedColumn,
  ...tableRowProps
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { columns, rowIndex } = rowData
  const statusCol = columns.find(_ => _.columnIndex === -1)
  const scrollableCols = columns.filter(_ => _.columnIndex !== -1)
  return (
    <TableRow {...tableRowProps}>
      <TableViewCell sticky className={classes.firstColumn}>{rowIndex > 0 ? rowIndex : 'Row'}</TableViewCell>
      {statusCol && <TableViewCell sticky className={classes.secondColumn}>{statusCol.cellContent}</TableViewCell>}
      {
        scrollableCols.map(({ cellContent, columnIndex }) => {
          return (
            <TableViewCell
              onClick={() => dispatch(selectCell({ row: rowData.rowIndex, column: columnIndex }))}
              className={clsx({
                [classes.mediumCol]: isMediumColumn(cellContent),
                [classes.selected]: selectedColumn === columnIndex
              })}
              key={cellContent + columnIndex}>
              {cellContent}
            </TableViewCell>
          )
        })
      }
    </TableRow>
  )
})
TableViewHeaderRow.displayName = 'TableViewHeaderRowMemoized'
