import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'

export interface ProjectsSlice {
  searchTerm: string
}

export const initialState: ProjectsSlice = {
  searchTerm: ''
}

const projectsSlice = createSlice({
  name: 'projects2',
  initialState,
  reducers: {
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload
    }
  }
})

export const { setSearchTerm } = projectsSlice.actions

export const useProjects = (state: RootState) => state.projects

export default projectsSlice.reducer
