import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Grid, Icon, makeStyles, Paper, Typography } from '@material-ui/core'
import clsx from 'clsx'
import * as Icons from 'common/Icons/SvgIcons'
import { Query, QueryStatus } from 'types'
import { useDispatch, useSelector } from 'react-redux'
import { selectQuery, useQueries } from 'redux/slices/queriesSlice'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import useOnScreen from 'hooks/useIsOnScreen'
import { QueryStatusChip } from 'components/Query/QueryStatusChip'
import { QueryRiskLevelIndicator } from 'components/Query/QueryRiskLevelIndicator'
import { QueryReferenceLabel } from 'components/Query/QueryReferenceLabel'
import { QueryStatusSelect } from 'common/Select/QueryStatusSelect'
import { MaybeForbiddenAction } from 'common/MaybeForbiddenAction/MaybeForbiddenAction'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useGetQueryDataQuery, useUpdateQueryStatusMutation } from 'services/api/queryApi'
import { RoleName } from 'utils/userRoleSecurity'
import { closeModal, openModal, openToast } from 'redux/slices/appSlice'
import { labelForStatus } from 'utils/queries'
import { UserAvatarWithName } from 'common/UserAvatar/UserAvatar'
import { RoundIconButton } from 'common/RoundIconButton/RoundIconButton'
import { queryTypeOptions } from 'common/Select/QueryTypeSelect'
import { QueryAttachments } from 'components/Query/QueryAttachments'
import { QueryComments } from 'components/Query/QueryComments'
import { MessagesForm } from 'components/Query/MessagesForm'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { QueryForm } from 'components/Query/QueryForm'
import { formattedDate, toFormattedDateTime } from 'utils/date'
import { Label } from 'common/Label/Label'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .fas.fa-exclamation-triangle': {
      color: theme.palette.statusColors.red
    }
  },
  paper: {
    paddingTop: 4,
    paddingInline: 12,
    paddingBottom: 12,
    display: 'block',
    justifyContent: 'space-between',
    borderRadius: '4px',
    boxShadow: 'none',
    transition: 'all .15s linear',
    marginBottom: '8px',
    '& .queryRefLabel': {
      background: 'rgba(255,255,255,.3)',
      borderRadius: '4px',
      padding: '4px',
      marginRight: '-4px',
      color: 'black'
    },
    '&:hover': {
      background: 'rgba(255,255,255,.7)',
      boxShadow: 'none'
    },
    '&.active': {
      background: theme.palette.blue.main,
      color: 'white !important',
      '& .MuiChip-root': {
        background: theme.palette.common.white
      }
    }
  },
  section: {
    paddingBlock: 8,
    borderBottom: `1px solid ${theme.palette.grey2.dark}`
  },
  blankSection: {
    paddingBlock: 8
  },
  header: {
    '& .MuiChip-colorPrimary': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: '20px'
    }
  },
  queryTitle: {
    fontSize: 18,
    fontWeight: 600,
    whiteSpace: 'normal'
  },
  statusIcons: {
    marginLeft: '-5px'
  },
  queryStatus: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: 8
  },
  queryStatusExpanded: {
    columnGap: 0
  },
  criticalColor: {
    fill: theme.palette.statusColors.red
  },
  showMore: {
    display: 'flex',
    padding: '12px 0 0 0',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '13px',
    lineHeight: '13px',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: theme.palette.black.light,
    textDecoration: 'none !important',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.black.main
    },
    '& .MuiSvgIcon-root': {
      height: '20px'
    }
  },
  greyLabel: {
    color: '#929292',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 12
  },
  queryText: {
    whiteSpace: 'pre-wrap',
    paddingBlock: 12
  },
  editQuery: {
    marginBottom: 3,
    '& .MuiButtonBase-root': {
      background: theme.palette.blue.main,
      '&.editQueryDisabled': {
        background: theme.palette.grey[400]
      },
      '&:hover': {
        background: theme.palette.lime.main,
        '& .editIcon': {
          color: theme.palette.black.main
        }
      }
    },
    '& .editIcon': {
      color: theme.palette.common.white
    }
  },
  label: {
    fontSize: '16px',
    flexWrap: 'wrap',
    rowGap: 4,
    '& .MuiChip-sizeSmall': {
      height: '24px',
      fontSize: '13px',
      fontWeight: 600,
      width: 'auto',
      overflow: 'visible',
      textOverflow: 'clip !important',
      background: theme.palette.common.white,
      borderRadius: '4px',
      border: `2px solid ${theme.palette.statusColors.blue}`,
      transition: 'all .1s linear',
      '& .MuiChip-labelSmall': {
        paddingInline: 4
      }
    },
    '& span': {
      whiteSpace: 'nowrap'
    },
    '& span.docName': {
      textDecoration: 'none',
      fontWeight: 600,
      wordBreak: 'break-all',
      whiteSpace: 'initial'
    }
  },
  wotc: {
    fontWeight: 600
  },
  messagesForm: {
    padding: 16,
    background: '#E4E8EB',
    marginTop: 0,
    borderRadius: '0 16px 16px 16px'
  }
}))
type QueryItemProps = {
  query: Query,
  activeClass?: string
}
export const QueryItem: FC<QueryItemProps> = ({ query, activeClass }) => {
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const { selectedQuery: selectedQueryId } = useSelector(useQueries)
  const dispatch = useDispatch()
  const isThisQuerySelected = selectedQueryId === query.queryId
  const isActive = query.queryId.toUpperCase() === (selectedQueryId || '').toUpperCase()
  const isSideletter = query.queryStatus === QueryStatus.Sideletter
  const {
    hasRole,
    hasAccess,
    userId
  } = useLisaAuth()
  const { data: selectedQuery, isFetching } = useGetQueryDataQuery(selectedQueryId ?? '', { skip: !selectedQueryId })
  const isAssignedToMe = selectedQuery?.assignTo === userId
  const isReportedByMe = selectedQuery?.reporter === userId
  const canQueryComment = hasAccess('perm_act_query_comment')
  const canEditQuery = hasAccess('perm_act_query_edit')
  const canAddComment = canQueryComment ||
    (isAssignedToMe && hasAccess('perm_act_query_comment_assigned')) ||
    (isReportedByMe && hasAccess('perm_act_query_comment_reported'))
  const canEdit = canEditQuery ||
    (isAssignedToMe && hasAccess('perm_act_query_edit_reported')) ||
    (isReportedByMe && hasAccess('perm_act_query_edit_assigned'))
  const [updateQueryStatus] = useUpdateQueryStatusMutation()
  const expandedReady = expanded && selectedQuery && !isFetching
  const hasLabel = selectedQuery?.labelId && selectedQuery?.labelName
  const hasDocument = selectedQuery?.documentId && selectedQuery?.documentName
  const showRefersTo = expandedReady && (hasLabel || hasDocument)
  const hasWotc = query.wotc !== ''

  useEffect(() => {
    if (!isThisQuerySelected) {
      setExpanded(false)
    }
  }, [isThisQuerySelected])

  useEffect(() => {
    if (!isVisible && isActive) {
      ref && ref.current && ref.current.scrollIntoView(false)
    }
  }, [isActive])

  const onStatusChange = useCallback(async (queryStatus: QueryStatus | '', wotc?: string) => {
    if (!selectedQuery || queryStatus === selectedQuery?.queryStatus || !queryStatus) {
      return
    }
    if (!hasRole(RoleName.ProgramManager) && !isReportedByMe && queryStatus === QueryStatus.Closed) {
      dispatch(openToast({
        severity: 'error',
        message: 'You are not allowed to close the query.'
      }))
    } else {
      const { success } = await updateQueryStatus({
        status: queryStatus,
        queryId: selectedQuery.queryId,
        userId: userId!,
        wotc
      }).unwrap()
      if (success) {
        dispatch(openToast({
          severity: 'success',
          message: `Query status changed to '${labelForStatus(queryStatus)}'`
        }))
      }
    }
  }, [selectedQuery?.queryId, selectedQuery?.queryStatus])

  return (
    <div className={classes.root} ref={ref}>
      <Paper
        square={true}
        elevation={0}
        className={clsx(classes.paper, {
          [activeClass ?? 'active']: isActive
        })}
        onClick={() => {
          dispatch(selectQuery(query.queryId))
        }}
      >
        <Grid container direction={'column'}>
          {/* Indicator, query reference number and query risk level */}
          <Grid
            container
            item
            className={clsx(classes.header, classes.section)}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Grid item >
              <Grid container justifyContent={'flex-start'}>
                <Grid item className={classes.statusIcons}>
                  { query.visibility === 2 && <Icons.ExternalQuery />}
                  { query.critical && <Icons.CriticalQuery className={classes.criticalColor} /> }
                  { isSideletter && <Icons.SideletterQuery /> }
                </Grid>
                <Grid item
                  className={clsx([{
                    orderOne: true
                  }])}>
                  {query.queryReference !== '' && <QueryReferenceLabel queryReference={query.queryReference}/>}
                </Grid>
              </Grid>
            </Grid>
            <Grid xs item container className={clsx(classes.queryStatus, { [classes.queryStatusExpanded]: expanded })}>
              {!expandedReady && <QueryStatusChip queryStatus={query.queryStatus}/>}
              {expandedReady && <Typography className={classes.greyLabel}>Priority: </Typography>}
              <QueryRiskLevelIndicator riskLevel={query.riskLevel} withText={expanded}/>
            </Grid>
          </Grid>
          {/* Query status toggle */}
          {
            expandedReady &&
            <Grid item container className={classes.section}>
              <MaybeForbiddenAction isForbidden={!canEdit}>
                <QueryStatusSelect
                  showSelectOption={false}
                  label={''}
                  isQueryReportedByMe={isReportedByMe}
                  value={selectedQuery.queryStatus}
                  onChange={onStatusChange}
                  wotc={selectedQuery.wotc ?? ''}
                  mode={'toggle'}/>
              </MaybeForbiddenAction>
            </Grid>
          }
          {/* Reporter */}
          {
            expandedReady &&
            <Grid item className={classes.section}>
              <UserAvatarWithName
                label={'Reporter'}
                user={{
                  firstName: selectedQuery.reporterFirstName ?? '',
                  lastName: selectedQuery.reporterLastName ?? '',
                  url: selectedQuery.reporterUrl ?? '',
                  userId: selectedQuery.reporter ?? ''
                } ?? null}
                footNote={`${toFormattedDateTime(new Date(selectedQuery.createdDate))}`}/>
            </Grid>
          }
          {/* Query title */}
          <Grid item className={clsx(classes.editQuery, classes.section)}>
            <Typography className={classes.queryTitle}>{query.title}</Typography>
            {
              expandedReady &&
              <Typography variant={'body2'} className={classes.queryText}>
                {selectedQuery.queryText}
              </Typography>
            }
            {
              expandedReady &&
              <MaybeForbiddenAction isForbidden={!canEdit} tooltip={'Edit query'}>
                <RoundIconButton
                  color="primary"
                  onClick={() => dispatch(openModal('EDIT_QUERY'))}
                  icon={<Icon className="editIcon fas fa-pen"/>}/>
              </MaybeForbiddenAction>
            }
          </Grid>
          {/* Reference */}
          {
            showRefersTo &&
            <Grid item container direction={'column'} alignItems={'flex-start'} className={clsx(classes.label, classes.section)}>
              <span>Reference:</span>
              {
                hasLabel &&
                <Label
                  noMargin
                  label={{
                    labelId: Number(selectedQuery.labelId!), // Comes as string
                    name: selectedQuery.labelName,
                    isSelected: false
                  }}/>
              }
              {hasDocument && <span className={'docName'}>{selectedQuery.documentName}</span>}
              {
                hasWotc && <Grid item alignContent='flex-start'>
                  <span>WO/TC ref:</span>
                  <div className={classes.wotc}>{query.wotc}</div>
                </Grid>
              }
            </Grid>
          }
          {/* Assignee */}
          {
            expandedReady &&
            <Grid item className={classes.section}>
              <UserAvatarWithName
                user={{
                  firstName: selectedQuery.assignToFirstName ?? '',
                  lastName: selectedQuery.assignToLastName ?? '',
                  url: selectedQuery.assignToUrl ?? '',
                  userId: selectedQuery.assignTo ?? ''
                } ?? null}
                label={'Current assignee'}/>
            </Grid>
          }
          {/* Due Date */}
          <Grid container className={classes.section} justifyContent={'space-between'}>
            <Typography variant="body2">Due date:</Typography>
            <Typography variant="body2">{formattedDate(new Date(query.dueDate.toString()))}</Typography>
          </Grid>
          {/* ATA Chapter */}
          {
            expandedReady &&
            <Grid container className={classes.section} justifyContent={'space-between'}>
              <Typography variant="body2">ATA Chapter:</Typography>
              <Typography variant="body2">
                {selectedQuery.ataChapter?.length > 0 ? selectedQuery.ataChapter : 'n/a'}
              </Typography>
            </Grid>
          }
          {/* Query Type */}
          {
            expandedReady &&
            <Grid container className={classes.section} justifyContent={'space-between'}>
              <Typography variant="body2">Query Type:</Typography>
              <Typography variant="body2">
                {queryTypeOptions.find(_ => _.value === selectedQuery.queryType)?.text ?? ''}
              </Typography>
            </Grid>
          }
          {/* Attachments */}
          {
            expandedReady &&
            <Grid item className={classes.blankSection}>
              <QueryAttachments/>
            </Grid>
          }
          {/* Comments */}
          {
            expandedReady &&
            <Grid item className={classes.blankSection}>
              <QueryComments canAddComment={canAddComment} showAddCommentButton/>
            </Grid>
          }
          <div
            className={classes.showMore}
            onClick={() => {
              if (!expanded) {
                dispatch(selectQuery(query.queryId))
              }
              setExpanded(!expanded)
            }}>
            {expanded ? 'Show less' : 'Show more'}
            {expanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}
          </div>
        </Grid>
      </Paper>
      {
        expandedReady &&
        <LisaModal
          title='Add comment to the query'
          scroll={'body'}
          modalType={'ADD_COMMENT_TO_QUERY'}>
          <MessagesForm
            onComplete={() => dispatch(closeModal(['ADD_COMMENT_TO_QUERY']))}
          />
        </LisaModal>
      }
      {
        expandedReady &&
        <LisaModal
          title="Edit Query"
          scroll={'body'}
          modalType={'EDIT_QUERY'}>
          <QueryForm
            onComplete={() => null}
            query={selectedQuery} />
        </LisaModal>
      }
    </div>
  )
}
