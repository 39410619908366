import { FC, MouseEvent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import Icon from '@material-ui/core/Icon'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, Tooltip } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import {
  resetSelectedDocuments,
  selectFolder,
  setFolderId,
  toggleFolder,
  useImportDocuments,
  setPage
} from 'redux/slices/importDocumentsSlice'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { FileTree, FolderId } from 'types'
import { openModal } from 'redux/slices/appSlice'
import { useGetImportFileTreeQuery } from 'services/api/importApi'
import { useTransitionId } from 'context/TransitionContext'
import { useGetTransitionQuery } from 'services/api/transitionApi'
import { NoDocuments } from 'common/NoDocuments/NoDocuments'
import * as React from 'react'

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.grey2.light
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
      '& .MuiTreeItem-label': {
        backgroundColor: 'transparent !important'
      }
    },
    '& .MuiTreeItem-label': {
      display: 'flex',
      columnGap: '5px'
    },
    '& .MuiIcon-root': {
      fontSize: '1.25em',
      color: theme.palette.import.main
    },
    '& .MuiTreeItem-iconContainer': {
      width: 0,
      marginRight: '24px',
      marginLeft: '10px',
      display: 'block'
    },
    '& .MuiTreeItem-label:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiTypography-body1': {
      fontSize: '16px',
      fontWeight: 500,
      letterSpacing: '.05px',
      flexGrow: 1
    }
  },
  content: {
    color: theme.palette.black.light,
    height: '40px',
    // fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      // fontWeight: theme.typography.fontWeightMedium,
      borderBottom: `1px solid ${theme.palette.grey2.main}`,
      paddingLeft: 0
    }
  },
  contentSelected: {
    height: '40px',
    backgroundColor: theme.palette.blue.main,
    borderBottom: `1px solid ${theme.palette.grey2.main}`,
    '& .MuiTreeItem-label': {
      color: theme.palette.common.white
    },
    '& .MuiTreeItem-iconContainer svg': {
      color: 'white'
    }
  },
  group: {
    '& $content': {
      borderBottom: `1px solid ${theme.palette.grey2.main}`,
      transition: 'all .1s linear'
    }
  },
  expanded: {},
  selected: {},
  label: {
    color: theme.palette.black.main,
    fontSize: '20px',
    fontWeight: 500,
    letterSpacing: '.05px',
    flexGrow: 1

  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0)
  },
  labelRootLast: {
    display: 'flex',
    alignItems: 'center'
  },
  labelIconLast: {
    marginRight: theme.spacing(1),
    color: theme.palette.a2k.main
  },
  labelText: {},
  letterLabel: {
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '.1px'
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: '24px',
    height: '24px',
    background: theme.palette.black.main
  },
  AKLetter: {
    fontSize: '14px'
  },
  labelIcon: {}

}))

type StylesTreeItemProps = TreeItemProps & {
  labelText: string
  isSelected: boolean
}
const StyledTreeItem: FC<StylesTreeItemProps> = (props) => {
  const classes = useTreeItemStyles()
  const {
    labelText,
    nodeId,
    isSelected,
    ...other
  } = props

  return (
    <TreeItem
      nodeId={nodeId}
      label={
        <div className={classes.labelRootLast}>
          <Typography variant="body1" className={classes.labelText}>
            {labelText}
          </Typography>
          <Icon
            fontSize="small"
            className={clsx(
              isSelected ? 'fas fa-folder-open' : 'far fa-folder',
              classes.labelIconLast
            )}
          />
        </div>
      }
      classes={{
        root: classes.root,
        content: isSelected ? classes.contentSelected : classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label
      }}
      {...other}
    />
  )
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  initialImportContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
    flexGrow: 1
  },
  fileNumber: {
    color: '#858585',
    display: 'flex',
    minWidth: '56px',
    fontWeight: 700,
    fontSize: '14px',
    justifyContent: 'flex-end',
    marginRight: '12px',
    '& span': {
      marginLeft: '2px',
      letterSpacing: '-.4px',
      fontWeight: 400
    }
  },
  noUnlabelledDocumentsContainer: {
    height: '100%'
  }
}))

export const RecursiveTreeView: FC = () => {
  const classes = useStyles()
  const {
    folderState,
    selectedFolder,
    onlyUnlabelledFolders
  } = useSelector(useImportDocuments)
  const dispatch = useDispatch()
  const { hasAccess } = useLisaAuth()
  const transitionId = useTransitionId()
  const { data: transition } = useGetTransitionQuery(transitionId)
  const { data: fileTree, isLoading, isFetching } = useGetImportFileTreeQuery({ transitionId, unlabelled: onlyUnlabelledFolders })

  const handleClick = (e: MouseEvent<HTMLElement>, folder: FileTree) => {
    e.stopPropagation()
    const {
      storageId
    } = folder
    dispatch(resetSelectedDocuments())
    dispatch(setFolderId(storageId!))
    dispatch(selectFolder(folder))
    dispatch(setPage(1))
  }

  const renderTree = (nodes: FileTree) => {
    const filesNo = onlyUnlabelledFolders ? nodes.unlabelledDocsCount : nodes.filesNo
    return (
      <StyledTreeItem
        key={nodes.id}
        labelText={nodes.name}
        nodeId={nodes.id}
        isSelected={selectedFolder?.id === nodes.id}
        label={<>
          <Icon
            fontSize="small"
            className={clsx(
              selectedFolder?.id === nodes.id ? 'fas fa-folder-open' : 'far fa-folder'
            )}
          />
          {nodes.name && nodes.name.length > 52
            ? <>
              <Tooltip title={nodes.name}><Typography noWrap
                variant="body1">{nodes.name.substring(0, 52)}...</Typography></Tooltip>
              {filesNo === 0 ? '' : <div className={classes.fileNumber}>{filesNo}<span>doc</span></div>}
            </>
            : <>
              <Typography noWrap variant="body1">{nodes.name}</Typography>
              {filesNo === 0 ? '' : <div className={classes.fileNumber}>{filesNo}<span>doc</span></div>}
            </>
          }
        </>
        }
        onClick={(e) => handleClick(e, nodes)}
      >
        {
          Array.isArray(nodes.children)
            ? [...nodes.children].sort((a, b) => a.name.localeCompare(b.name))
              .map((node) => node.isFolder && renderTree(node))
            : null
        }
      </StyledTreeItem>
    )
  }

  const isImportInProgress = transition?.importInProgress
  const noStructure = !fileTree?.children && !isImportInProgress && hasAccess('perm_act_uploaddocument') && !isLoading
  return (
    <>
      {
        noStructure && !onlyUnlabelledFolders && !isFetching &&
        <div className={classes.initialImportContainer}>
          <Button
            onClick={() => dispatch(openModal('ADD_FROM_ONE_DRIVE'))}
            variant="contained"
            color="primary"
            size="small">
            Add from OneDrive
          </Button>
        </div>
      }
      {
        noStructure && onlyUnlabelledFolders &&
        <Grid
          className={classes.noUnlabelledDocumentsContainer}
          container
          justifyContent={'center'}
          alignItems={'center'}>
          <NoDocuments message={'No unlabelled documents'}/>
        </Grid>
      }
      {
        fileTree !== null && fileTree?.children &&
        <TreeView
          className={clsx(classes.root)}
          defaultCollapseIcon={<ArrowDropDownIcon/>}
          defaultExpandIcon={<ArrowRightIcon/>}
          onNodeSelect={(e: React.ChangeEvent<{}>, folderId: FolderId) => {
            dispatch(toggleFolder(folderId))
          }}
          selected={selectedFolder?.id ?? ''}
          defaultExpanded={[fileTree.id, ...folderState]}
          expanded={[fileTree.id, ...folderState]}>
          {renderTree(fileTree)}
        </TreeView>
      }
    </>
  )
}
