import { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles, Tooltip, Typography } from '@material-ui/core'
import * as Icons from 'common/Icons/SvgIcons'
import { setPage, useSearchDocuments } from 'redux/slices/searchDocumentsSlice'
import { WithDocumentsLoader } from 'common/WithLoader/WithLoader'
import { BusinessStatus, DocumentId } from 'types'
import { WithPagination } from 'common/WithPagination/WithPagination'
import { DocumentLink } from 'common/DocumentLink/DocumentLink'
import { useTransitionId } from 'context/TransitionContext'
import { GridGrow } from 'common/Grid/GridGrow'
import { useDocumentId } from 'context/DocumentContext'
import { useSearchQuery } from 'services/api/searchApi'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  searchResults: {
    paddingTop: '24px',
    width: '100%'
  },
  searchResultCount: {
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'uppercase',
    marginBottom: '6px'
  },
  documentsContainer: {
    overflowY: 'auto',
    minHeight: 100,
    height: 0,
    scrollBehavior: 'smooth',
    padding: '6px 0',
    position: 'relative',
    borderTop: `1px solid ${theme.palette.grey2.dark}`
  },
  singleDocument: {
    display: 'flex',
    borderRadius: '4px',
    flexDirection: 'column',
    height: 'auto',
    padding: 8,
    backgroundColor: 'white',
    color: 'black',
    marginBottom: '4px',
    '&:hover': {
      background: theme.palette.grey2.light,
      color: 'black'
    },
    '& .head': {
      display: 'flex',
      // height: "24px",
      flex: 1,
      justifyContent: 'space-between'
    },
    '& .link': {
      display: 'block',
      textDecoration: 'none',
      color: theme.palette.black.main,
      width: 'calc(100% - 64px)',
      fontSize: '16px',
      fontWeight: 500,
      wordWrap: 'break-word',
      '&:hover': {
        color: theme.palette.linkBlue.main,
        textDecoration: 'underline'
      }
    }
  },
  markForDeletion: {
    background: theme.palette.red.main
  },
  resultActionIcon: {
    fontSize: '16px',
    borderRadius: '4px',
    transition: 'all .1s linear',
    cursor: 'pointer',
    padding: '3px',
    color: theme.palette.black.light100,
    '&:hover': {
      color: theme.palette.black.main
    }
  },
  documentLink: {
    padding: 0,
    lineBreak: 'anywhere',
    wordWrap: 'break-word'
  }
}))

export const SearchResults: FC<{ searchText?: string }> = ({ searchText = '' }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { searchParams } = useSelector(useSearchDocuments)
  const transitionId = useTransitionId()
  const {
    data: { total, rows, pages } = {
      page: 1,
      pages: 1,
      total: 0,
      rows: [],
      pageSize: 25
    }, isFetching
  } = useSearchQuery({ ...searchParams, transitionId })
  const documentId = useDocumentId()
  const { page } = searchParams

  const openComparePage = useCallback((id: DocumentId, compareId: DocumentId) => {
    const win = window.open(`/compare/${id}/${compareId}/${transitionId}`, '_blank')
    win?.focus()
  }, [transitionId])

  return (
    <GridGrow container direction={'column'}>
      <Grid item>
        <Typography className={classes.searchResultCount} variant="h5">
          {total} results
        </Typography>
      </Grid>
      <GridGrow
        item
        container
        direction={'column'}>
        <GridGrow item className={classes.documentsContainer}>
          <WithDocumentsLoader
            noResultsProps={{
              message: 'No results match your search criteria'
            }}
            loading={isFetching}
            hasResults={total > 0}>
            {
              rows.map((doc) => {
                return (
                  <div className={clsx(classes.singleDocument, { [classes.markForDeletion]: doc.businessStatus === BusinessStatus.Deleted })} key={doc.documentId}>
                    <div className="head">
                      <DocumentLink
                        document={doc}
                        className={classes.documentLink}
                        searchParam={searchText}/>
                      <Tooltip title={'Compare this with opened document'} placement="top">
                        <span>
                          <Icons.CompareIco
                            className={classes.resultActionIcon}
                            onClick={() => openComparePage(documentId, doc.documentId)}
                          />
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                )
              })
            }
          </WithDocumentsLoader>
        </GridGrow>
        <Grid item>
          <WithPagination
            page={page}
            pages={pages}
            setPage={(p) => dispatch(setPage(p))}
            options={{ onlyPages: true }}/>
        </Grid>
      </GridGrow>
    </GridGrow>
  )
}
