import { FC } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { DocumentSearch } from 'common/DocumentSearch/DocumentSearch'

const useStyles = makeStyles(() => ({
  searchWrapper: {
    justifyContent: 'center',
    paddingBottom: 16
  }
}))
export const SearchDocuments: FC = () => {
  const classes = useStyles()
  return (
    <Grid container item className={classes.searchWrapper} justifyContent="center">
      <Grid item xs={12} md={8} lg={6}>
        <DocumentSearch/>
      </Grid>
    </Grid>
  )
}
