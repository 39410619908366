import { ChangeEvent, ElementType, FC, Fragment, HTMLAttributes, KeyboardEvent, MouseEvent } from 'react'
import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import * as Icons from 'common/Icons/SvgIcons'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import { Label } from 'common/Label/Label'
import { LabelBase } from 'types'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none',
    borderRadius: '4px',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.grey2.main}`,
    flexDirection: 'unset',
    '&:focus-within': {
      boxShadow: 'inset 0 0 0 2px #404db2',
      '&:hover': {
        borderColor: theme.palette.grey2.main
      }
    },
    '&:hover': {
      borderColor: theme.palette.black.main
    }
  },
  input: {
    fontSize: '16px',
    fontWeight: 500,
    flex: 1,
    '&::placeholder': {
      color: 'red'
    },
    '&.MuiInputBase-input': {
      padding: '0'
    }
  },
  iconButton: {
    padding: 0,
    width: '40px',
    height: '40px',
    borderRadius: '0px',
    background: 'transparent',
    transition: 'all .1s linear',
    color: theme.palette.blue.main,
    '&:hover': {
      background: theme.palette.blue.main,
      color: theme.palette.common.white
    },
    '&:active': {
      background: theme.palette.lime.main,
      color: theme.palette.blue.main
    }
  },
  infoTip: {
    margin: '0 8px',
    fontSize: '16px',
    color: theme.palette.statusColors.blue,
    opacity: '.65'
  },
  tooltipLine: {
    padding: '10px',
    '& .wildcard': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      color: theme.palette.lime.main,
      background: theme.palette.black.main
    }
  },
  clearButton: {
    width: '22px',
    height: '22px',
    marginRight: '4px',
    background: theme.palette.grey2.main,
    '& .MuiSvgIcon-root': {
      fontSize: '20px'
    },
    '&:hover': {
      background: theme.palette.blue.main,
      color: theme.palette.common.white
    }
  },
  divider: {
    height: 28,
    margin: 4
  },
  clearInput: {
    display: 'flex',
    '& .MuiSvgIcon-root': {
    }
  }
}))

type InputSearchProps = {
  name?: string,
  label?: string,
  labelBase?: LabelBase | null,
  documentName?: LabelBase | null,
  value: string,
  noTooltip?: boolean,
  onClear?: () => void,
  onClick?: (e: MouseEvent<HTMLDivElement | HTMLButtonElement> | KeyboardEvent<HTMLImageElement>) => void,
  onKeyUp?: (e: KeyboardEvent) => void,
  onChange: (e: ChangeEvent<{value: string}>) => void
  handleDeleteLabel?: () => void,
  clearAction?: boolean,
  component?: ElementType<HTMLAttributes<HTMLElement>> | undefined,
  placeholder?: string | null,
  inputClass?: string,
  fromClient?: boolean
}

const InputSearch: FC<InputSearchProps> = (props) => {
  const classes = useStyles(props)
  const {
    name,
    label,
    labelBase,
    documentName,
    value,
    onClick,
    onClear,
    onKeyUp,
    onChange,
    handleDeleteLabel,
    clearAction = false,
    component = 'form',
    noTooltip = false,
    placeholder = null,
    inputClass = '',
    fromClient
  } = props

  return (
    <Paper
      component={component}
      className={classes.root}
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      {
        !noTooltip &&
        <Tooltip
          placement='bottom-start'
          title={
            <Fragment>
              <div className={classes.tooltipLine}>
                <i className='fas fa-question wildcard'/> <span>{'Matches any single character'}</span>.<br/>
              </div>
              <div className={classes.tooltipLine}>
                <i className='fas fa-asterisk wildcard'/> <span>{'Can match zero or more characters, including an empty one'}</span>.<br/>
              </div>
            </Fragment>
          }>
          <div className={classes.infoTip}><i className='fas fa-info-circle'/></div>
        </Tooltip>
      }
      {labelBase && <div style={{ flex: 1 }}><Label fromClient={fromClient} handleDeleteLabel={handleDeleteLabel} label={{ ...labelBase, isSelected: true }} variant='outlined' noMargin/></div>}
      {documentName && <div style={{ flex: 1 }}><Label fromClient={fromClient} documentIcon handleDeleteLabel={handleDeleteLabel} label={{ ...documentName, isSelected: true }} variant='outlined' noMargin/></div>}
      {(!labelBase && !documentName) &&
          <InputBase
            name={name}
            className={clsx(classes.input, inputClass)}
            placeholder={placeholder ?? `${label}...`}
            inputProps={{ 'aria-label': `${label}...` }}
            value={value}
            onChange={onChange}
            onKeyUp={onKeyUp}
            autoComplete='off'
          />}
      { clearAction
        ? (<>
          {
            value && (
              <IconButton
                onClick={onClear}
                className={classes.clearButton}
                aria-label='search'
              >
                <CloseIcon />
              </IconButton>
            )}
          <IconButton
            onClick={onClick}
            className={classes.iconButton}
            aria-label='search' >
            <Icons.SearchIco />
          </IconButton>
        </>
        )
        : (
          <IconButton
            disabled={value.length < 3 && value.length > 0}
            onClick={onClick}
            className={classes.iconButton}
            aria-label='search'
          >
            <Icons.SearchIco />
          </IconButton>
        )}
    </Paper>
  )
}
export default InputSearch
