import {
  A2KDocument,
  AssignedLabel,
  BusinessStatus,
  DocumentId,
  DtrStatus,
  FileType,
  Label,
  LabelBase,
  LisaDocument,
  LisaDocumentWithLabels,
  OCRDocument,
  OcrStatus,
  SortDirection
} from 'types'
import { pickLabels } from './labels'

export const fileNameComparator = (direction: SortDirection) => (d1: LisaDocument, d2: LisaDocument) =>
  !direction ? 0 : (d1.name.localeCompare(d2.name) * (direction === 'asc' ? 1 : -1))

export const ocrQualityComparator = (direction: SortDirection) => (d1: OCRDocument, d2: OCRDocument) =>
  !direction ? 0 : ((d1.quality - d2.quality) * (direction === 'asc' ? 1 : -1))

export const sortOcrDocuments = (
  documents: OCRDocument[],
  sortDirections: {
    fileName?: SortDirection,
    ocrStatus?: SortDirection,
    ocrQuality?: SortDirection
  }): OCRDocument[] => [...documents] // In case it was frozen
  .sort(fileNameComparator(sortDirections.fileName ?? false))
  // .sort(ocrStatusComparator(ocrStatus ?? false))
  .sort(ocrQualityComparator(sortDirections.ocrQuality ?? false))

export const filterA2KDocuments = (documents: A2KDocument[], businessStatusFilter: BusinessStatus | '') =>
  documents.filter((document) => {
    const bs = document.businessStatus
    if (businessStatusFilter === 0) {
      return bs !== BusinessStatus.Deleted && bs !== BusinessStatus.Irrelevant
    } else {
      return !businessStatusFilter || document.businessStatus === businessStatusFilter
    }
  })

export const toggleDocumentsOnPage = (documentIds: DocumentId[], selectedDocumentIds: DocumentId[]): DocumentId[] => {
  const selectedIdsOnPage = documentIds.filter(_ => selectedDocumentIds.includes(_))
  const shouldSelectAll = selectedIdsOnPage.length < documentIds.length
  if (shouldSelectAll) {
    return Array.from(new Set([...selectedDocumentIds, ...documentIds]))
  } else {
    return selectedDocumentIds.filter(_ => !documentIds.includes(_))
  }
}

export const toggleDocumentsOnPage2 = <D extends LisaDocument> (documents: D[], selectedDocuments: D[]): D[] => {
  const selectedOnPage = documents.filter((d) =>
    selectedDocuments.some((sd) => sd.documentId === d.documentId))
  const shouldSelectAll = selectedOnPage.length < documents.length
  if (shouldSelectAll) {
    return Array.from(new Set([...selectedDocuments, ...documents]))
  } else {
    return selectedDocuments.filter((sd) => documents.every((d) => d.documentId !== sd.documentId))
  }
}

export const getAssignedLabels = <D extends LisaDocumentWithLabels> (documents: D[], labelTree: Label): AssignedLabel[] => {
  // Create unique labels list
  const uniqueLabels: LabelBase[] = documents
    .flatMap((d) => d.labels.filter(
      (al, i, self) => self.findIndex(_ => _.labelId === al.labelId) === i))
  const assignedLabels: AssignedLabel[] = pickLabels(labelTree, uniqueLabels.map(_ => _.labelId))
    .map((al) => ({
      ...al,
      assignedToAll: true
    }))
  // Check each assigned label in documents list
  return documents.reduce((al, document) => {
    return al.map((assignedLabel) => ({
      ...assignedLabel,
      assignedToAll: assignedLabel.assignedToAll && document.labels.some((dl) => dl.labelId === assignedLabel.labelId)
    }))
  }, assignedLabels)
}

export const isCompleted = <D extends { businessStatus: BusinessStatus }>(document: D): boolean =>
  [BusinessStatus.Accepted, BusinessStatus.Reviewed].includes(document.businessStatus)

export const resolveDocumentType = (extension: string, ocrStatus?: OcrStatus): FileType => {
  if (ocrStatus && ocrStatus === OcrStatus.Success) {
    return 'pdf'
  }
  switch (extension.toLowerCase()) {
  case '.jpeg':
  case '.jpg':
  case '.gif':
  case '.png':
  case '.apng':
  case '.svg':
  case '.bmp':
  case '.ico':
    return 'image'
  case '.mp4':
  case '.webm':
  case '.ogg':
    return 'video'
  default:
    return 'pdf'
  }
}

export const supportedFiles = ['.pdf', '.xls', '.xlsx', '.xml', '.doc', '.docx', '.ppt', '.pptx', '.png', '.jpg', '.jpeg', '.bmp', '.gif', '.svg', '.txt', '.tiff', '.csv']
export const supportedFilesDotsOff = ['pdf', 'xls', 'xlsx', 'xml', 'doc', 'docx', 'ppt', 'pptx', 'png', 'jpg', 'jpeg', 'bmp', 'gif', 'svg', 'txt', 'tiff', 'csv']
export const ocrStatusOptions = [
  {
    value: 0,
    text: 'Any OCR status'
  },
  {
    value: OcrStatus.Ready,
    text: 'Waiting for OCR'
  },
  {
    value: OcrStatus.Success,
    text: 'OCR completed'
  },
  {
    value: OcrStatus.Failed,
    text: 'OCR with errors'
  },
  {
    value: OcrStatus.InList,
    text: 'OCR in progress'
  },
  {
    value: OcrStatus.Unsupported,
    text: 'Unsupported'
  }
]

export const ocrProcessingTooltipText = [
  {
    value: OcrStatus.Ready,
    text: 'Waiting'
  },
  {
    value: OcrStatus.Processing,
    text: 'In Progress'
  },
  {
    value: OcrStatus.Success,
    text: 'OCR Successful'
  },
  {
    value: OcrStatus.Failed,
    text: 'Error'
  },
  {
    value: OcrStatus.Unsupported,
    text: 'Unsupported Filetype'
  },
  {
    value: OcrStatus.InList,
    text: 'In Progress'
  },
  {
    value: OcrStatus.Timeout,
    text: 'Error Timeout'
  },
  {
    value: OcrStatus.Cancelled,
    text: 'Error Process Cancelled'
  },
  {
    value: OcrStatus.ExtractedText,
    text: 'Complete Extraction'
  },
  {
    value: OcrStatus.Incomplete,
    text: 'Incomplete Extraction'
  }
]

export const dtrAndLabelTooltipText = [
  {
    value: DtrStatus.Ready,
    text: 'Empty / Not Started'
  },
  {
    value: DtrStatus.Processing,
    text: 'In Progress'
  },
  {
    value: DtrStatus.Success,
    text: 'Success'
  },
  {
    value: DtrStatus.Failed,
    text: 'Error'
  }
]
