import { FC, MouseEvent, useCallback, useEffect, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import { Menu, MenuItem, Typography } from '@material-ui/core'
import { Notification } from 'types'
import { ConfirmDialog } from 'common/Dialogs/ConfirmDialog'
import { useDispatch, useSelector } from 'react-redux'
import { closeConfirmDialog, openConfirmDialog, openModal, openToast, useApp } from 'redux/slices/appSlice'
import { LisaModal } from 'common/Dialogs/LisaModal'
import { useDeleteNotificationMutation } from 'services/api/notificationsApi'
import NotificationForm from './NotificationForm'

const useStyles = makeStyles(() => ({
  dialogTItle: {
    '& .MuiTypography-h6': {
      lineHeight: 1
    }
  },
  dialogMessage: {
    fontSize: '20px',
    fontWeight: 500,
    paddingTop: '48px'
  },
  dialogSubMessage: {
    fontSize: '16px',
    fontWeight: 500,
    paddingBottom: '48px'
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(76, 175, 80, 0.3)'
    }
  },
  message: {
    fontSize: '20px',
    padding: '48px 0'
  }
}))

export interface Props {
  notification: Notification
}

export const NotificationMenu: FC<Props> = ({ notification }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [editMode, setEditMode] = useState(false)
  const [editModeDialog, setEditModeDialog] = useState(false)
  const { activeModal } = useSelector(useApp)
  const [deleteNotification] = useDeleteNotificationMutation()
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    if (editMode) {
      dispatch(openModal('EDIT_NOTIFICATION'))
    }
  }, [editMode])

  useEffect(() => { // to handle cancel or X of modal
    if (activeModal.length === 0) {
      setEditMode(false)
    }
  }, [activeModal.length])

  useEffect(() => {
    if (editModeDialog) {
      dispatch(openConfirmDialog('DELETE_NOTIFICATION'))
    }
  }, [editModeDialog])

  useEffect(() => { // to handle cancel or X of modal
    if (activeModal.length === 0) {
      setEditModeDialog(false)
    }
  }, [activeModal.length])

  const handleOpenSettings = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseSettings = () => {
    setAnchorEl(null)
  }

  const handleEditNotification = () => {
    setEditMode(true)
    handleCloseSettings()
  }

  const handleDeleteNotification = () => {
    setEditModeDialog(true)
    setAnchorEl(null)
  }

  const deleteNotificationCB = useCallback(async (params) => {
    const {
      success,
      message
    } = await deleteNotification({
      createdTime: params
    }).unwrap()
    if (!success && message) {
      dispatch(openToast({
        severity: 'error',
        message: message
      }))
    } else {
      dispatch(openToast({
        severity: 'info',
        message: 'Notification successfully deleted'
      }))
    }
    dispatch(closeConfirmDialog())
  }, [])

  return (
    <div className='menu-item'>
      <IconButton
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleOpenSettings}
      >
        <Icon className='fas fa-ellipsis-v' fontSize='small' />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        elevation={8}
        open={Boolean(anchorEl)}
        onClose={handleCloseSettings}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={handleEditNotification}
        >
          Edit Notification
        </MenuItem>
        <MenuItem
          onClick={handleDeleteNotification}
        >
          Delete Notification
        </MenuItem>
      </Menu>
      {editModeDialog && <ConfirmDialog
        title='Delete Notification '
        type={'DELETE_NOTIFICATION'}
        onConfirm={() => deleteNotificationCB(notification.createdTime)}
        onCancel={() => {
          setEditModeDialog(false)
        }}>
        <div className={classes.message}>
          <Typography variant='inherit'>{'Are you sure you want to remove notification'}</Typography>
        </div>
      </ConfirmDialog>
      }
      {editMode &&
      <LisaModal
        modalType={'EDIT_NOTIFICATION'}
        title={'Edit Notification'}
        scroll={'body'}>
        <NotificationForm notification={notification}/>
      </LisaModal>}
    </div>
  )
}

export default NotificationMenu
