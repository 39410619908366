import { FC } from 'react'
import { QueryType } from 'types'
import Dropdown from 'common/LisaControls/Dropdown'

export const queryTypeOptions = [
  { value: QueryType.DeliveryDocuments, text: 'Delivery Documents ' },
  { value: QueryType.Physical, text: 'Physical' },
  { value: QueryType.AmpLdnd, text: 'AMP & LDND' },
  { value: QueryType.Util, text: 'Util & TLB & Maint ' },
  { value: QueryType.AD, text: 'AD' },
  { value: QueryType.SB, text: 'SB' },
  { value: QueryType.Mod, text: 'Mod' },
  { value: QueryType.HTC, text: 'HTC' },
  { value: QueryType.OCCM, text: 'OCCM' },
  { value: QueryType.LDG, text: 'LDG' },
  { value: QueryType.NLG, text: 'NLG' },
  { value: QueryType.MLGLH, text: 'MLG LH' },
  { value: QueryType.MLGRH, text: 'MLG RH' },
  { value: QueryType.CLGLH, text: 'CLG LH' },
  { value: QueryType.CLGRH, text: 'CLG RH' },
  { value: QueryType.Eng1, text: 'Eng #1' },
  { value: QueryType.Eng2, text: 'Eng #2' },
  { value: QueryType.Eng3, text: 'Eng #3' },
  { value: QueryType.Eng4, text: 'Eng #4' },
  { value: QueryType.APU, text: 'APU' },
  { value: QueryType.Repairs, text: 'Repairs' },
  { value: QueryType.Manuals, text: 'Manuals' }
]
type QueryTypeSelectProps = {
  value: QueryType | ''
  onChange(value: QueryType | ''): void
  required?: boolean
  error?: string
}
export const QueryTypeSelect: FC<QueryTypeSelectProps> = ({
  value,
  onChange,
  required,
  error
}) => {
  return (
    <Dropdown
      required={required}
      error={error}
      label="Query Type"
      name="queryType"
      items={queryTypeOptions}
      value={value}
      onChange={(e) => onChange(e.target.value as QueryType)}
    />
  )
}
