import { FC } from 'react'
import { BusinessStatus } from 'types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useLisaAuth } from 'hooks/useLisaAuth'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  statuses: {
    display: 'inline-block',
    width: 'auto',
    borderRadius: 4,
    backgroundColor: 'rgba(0,0,0,.9)'
  },
  divider: {
    display: 'inline-block',
    width: 1,
    height: 26,
    backgroundColor: 'rgba(0,0,0,.8)',
    margin: '0px 10px'
  },
  status: {
    position: 'relative',
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    float: 'left',
    padding: '0 16px',
    border: 'none',
    borderRadius: 4,
    height: 28,
    color: 'white',
    backgroundColor: 'transparent',
    cursor: 'pointer'
  },
  hover: {
    '&:hover:not([disabled])': {
      borderRadius: '0px !important',
      background: '#353535'
    }
  },
  statusActive: {
    color: 'white'
  },
  isDisabled: {
    color: 'gray',
    cursor: 'default'
  },
  statusOpenActive: {
    backgroundColor: theme.palette.statusColors.blue,
    color: theme.palette.black.main,
    '& .arrow': {
      backgroundColor: theme.palette.statusColors.blue,
      zIndex: 1
    }
  },
  statusClarificationActive: {
    backgroundColor: theme.palette.statusColors.orange,
    color: theme.palette.black.main,
    '& .arrow': {
      backgroundColor: theme.palette.statusColors.orange,
      zIndex: 1
    }
  },
  statusReviewedActive: {
    backgroundColor: theme.palette.statusColors.green,
    color: theme.palette.black.main
  },
  statusAcceptedActive: {
    backgroundColor: theme.palette.statusColors.green,
    color: theme.palette.black.main,
    cursor: 'default'
  },
  statusIrrelevantActive: {
    backgroundColor: theme.palette.statusColors.grey,
    color: theme.palette.black.main
  },
  statusDeletionActive: {
    backgroundColor: theme.palette.common.black,
    color: '#FF0000'
  },
  arrow: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'flex-end',
    top: 3,
    right: -8,
    transform: 'rotate(45deg)',
    width: 22,
    height: 22,
    zIndex: -1,
    borderRadius: 4
  },
  notAccepted: {
    cursor: 'default'
  }
}))

type DocumentStatusToggleProps = {
  documentStatus: BusinessStatus
  onChange: (documentStatus: BusinessStatus) => void
  disabled?: boolean,
  hasOpenQueries: number,
  lockedStatus?: boolean
}
export const DocumentStatusToggle: FC<DocumentStatusToggleProps> = ({
  documentStatus,
  onChange,
  disabled = false,
  lockedStatus
}) => {
  const classes = useStyles()
  const { hasAccess } = useLisaAuth()
  const isOpen = documentStatus === BusinessStatus.Open
  const isClarification = documentStatus === BusinessStatus.Clarification
  const isReviewed = documentStatus === BusinessStatus.Reviewed
  const isIrrelevant = documentStatus === BusinessStatus.Irrelevant
  const isDeleted = documentStatus === BusinessStatus.Deleted
  const isAccepted = documentStatus === BusinessStatus.Accepted
  const disableButton = disabled || isAccepted || lockedStatus
  const disableIrrelevantAndDeletion = disableButton || isReviewed || lockedStatus
  const canOnlyDeleteDocument = hasAccess('perm_act_deletedocument') && !hasAccess('perm_act_documentmetadata')

  return (
    <div className={classes.root}>
      <div className={classes.statuses}>
        <button
          disabled={!isOpen || canOnlyDeleteDocument || disabled}
          onClick={() => !isOpen && onChange(BusinessStatus.Open)}
          className={clsx(classes.status, {
            [classes.statusOpenActive]: isOpen,
            [classes.hover]: !isOpen,
            [classes.isDisabled]: isAccepted || !isOpen || canOnlyDeleteDocument
          })}>
          <div className={clsx('arrow', clsx(classes.arrow))}/>
          Open
        </button>
        <button
          disabled={canOnlyDeleteDocument || disabled}
          onClick={() => !isClarification && onChange(BusinessStatus.Clarification)}
          className={clsx(classes.status, {
            [classes.statusClarificationActive]: isClarification,
            [classes.hover]: !isClarification,
            [classes.isDisabled]: isAccepted || canOnlyDeleteDocument || disabled
          })}>
          <div className={clsx('arrow', clsx(classes.arrow))}/>
          Clarification
        </button>
        <button
          // @todo This is disabled due to future feature implementation
          disabled={disableButton || canOnlyDeleteDocument}
          onClick={() => !isReviewed && onChange(BusinessStatus.Reviewed)}
          className={clsx(classes.status, {
          // @todo need's to add hasOpenQueries: boolean to classes logic when feature is ready
            [classes.statusReviewedActive]: isReviewed,
            [classes.hover]: !isReviewed,
            [classes.isDisabled]: disableButton || canOnlyDeleteDocument
          })}>
          Reviewed
        </button>
      </div>
      <div className={classes.divider}/>
      <div className={classes.statuses}>
        <button
          disabled={disableIrrelevantAndDeletion}
          onClick={() => !isIrrelevant && onChange(BusinessStatus.Irrelevant)}
          className={clsx(classes.status, {
            [classes.statusIrrelevantActive]: isIrrelevant, [classes.hover]: !isIrrelevant, [classes.isDisabled]: disableIrrelevantAndDeletion
          })}>Irrelevant
        </button>
        <button
          disabled={disableIrrelevantAndDeletion}
          onClick={() => !isDeleted && onChange(BusinessStatus.Deleted)}
          className={clsx(classes.status, {
            [classes.statusDeletionActive]: isDeleted, [classes.hover]: !isDeleted, [classes.isDisabled]: disableIrrelevantAndDeletion
          })}>for deletion
        </button>
      </div>
      <div className={classes.divider}/>
      <div className={classes.statuses}>
        <button
          disabled={disabled || canOnlyDeleteDocument}
          className={clsx(classes.status, {
            [classes.statusAcceptedActive]: isAccepted,
            [classes.notAccepted]: !isAccepted,
            [classes.isDisabled]: disableButton || canOnlyDeleteDocument
          })}>accepted
        </button>
      </div>
    </div>
  )
}
