import { useGetQueryDataQuery } from 'services/api/queryApi'
import { useSelector } from 'react-redux'
import { useQueries } from 'redux/slices/queriesSlice'

export const useQueryData = () => {
  const { selectedQuery } = useSelector(useQueries)
  return useGetQueryDataQuery(selectedQuery ?? '', {
    skip: selectedQuery === null, refetchOnMountOrArgChange: true
  })
}
