import { FC } from 'react'
import { LabelsProvider, LabelsType } from 'context/LabelsContext'
import { LabelTree } from 'common/LabelTree/LabelTree'

export const TreeView: FC = () => {
  return (
    <LabelsProvider labelsType={LabelsType.Client}>
      <LabelTree/>
    </LabelsProvider>
  )
}
