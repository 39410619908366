import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Labeling from './Labeling'
import MetaData from './MetaData'
import Queries from './Queries'
import { Search } from './Search'
import Badge from '@material-ui/core/Badge'
import * as Icons from 'common/Icons/SvgIcons'
import { Box, Grid, Tab, Tabs, Tooltip, withStyles } from '@material-ui/core'
import { GridGrow } from 'common/Grid/GridGrow'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { QueryStatus } from 'types'
import { useGetQueriesByDocumentQuery } from 'services/api/queryApi'
import { useDocumentId } from 'context/DocumentContext'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // minWidth: 360,
    width: '100%',
    '& .MuiBox-root': {
      padding: '20px',
      margin: '0'
    }
  },
  queryCount: {
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      backgroundColor: '#E20000 !important',
      fontWeight: 700,
      color: theme.palette.common.white
    },
    '& .MuiBadge-badge': {
      display: 'flex',
      height: '20px',
      minWidth: '20px',
      padding: '0 2px'
    }
  },
  queryCountClosed: {
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      backgroundColor: theme.palette.statusColors.green,
      fontWeight: 700,
      color: theme.palette.common.white
    },
    '& .MuiBadge-badge': {
      display: 'flex',
      height: '14px',
      minWidth: '14px',
      marginTop: '4px',
      marginRight: '3px'
    }
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    display: 'none'
  },
  text: {
    backgroundColor: theme.palette.common.white
  }
}))

const TinyTab = withStyles((theme) => ({
  root: {
    width: '20%',
    minWidth: 'unset',
    background: theme.palette.blue.main,
    color: theme.palette.grey2.main,
    opacity: '1',
    height: '56px',
    transition: 'all .15s linear',
    '&:hover': {
      backgroundColor: theme.palette.blue.main,
      color: theme.palette.lime.dark
    },
    '&.Mui-disabled': {
      opacity: '.8',
      '& svg': {
        opacity: '.5'
      }
    }
  },
  selected: {
    width: '20%',
    height: '56px',
    minWidth: 'unset',
    background: theme.palette.grey2.light100,
    color: theme.palette.black.main,
    opacity: '1',
    '&:hover': {
      backgroundColor: theme.palette.grey2.light100,
      color: theme.palette.black.main
    }
  }
}))(Tab)
const TabPanel = withStyles((theme) => ({
  root: {
    background: theme.palette.grey2.light100,
    height: '100%',
    boxSizing: 'border-box',
    padding: ' 8px 16px'
  }
}))(Box)

export default function IconTabs () {
  const classes = useStyles()
  const [tab, setTab] = useState<number>(0)
  const { hasAccess } = useLisaAuth()
  const { data: queries = [] } = useGetQueriesByDocumentQuery(useDocumentId())
  const closedQueryCount = queries.filter(_ =>
    _.queryStatus === QueryStatus.Closed ||
    _.queryStatus === QueryStatus.Sideletter ||
    _.queryStatus === QueryStatus.WorkPack)

  return (
    <Grid container direction={'column'} style={{ height: '100%' }}>
      <Grid item>
        <Tabs
          value={tab}
          onChange={(_, t) => {
            setTab(t)
          }}
          variant="fullWidth"
          classes={{
            indicator: classes.indicator
          }}
          aria-label="icon tabs example">
          <Tooltip title={'Document Meta Data'} placement="bottom">
            <TinyTab
              icon={<Icons.DocMetaData/>}
              aria-label="subject"
            />
          </Tooltip>
          <Tooltip title={'Document Search'} placement="bottom">
            <TinyTab
              icon={<Icons.DocSearch/>}
              aria-label="search"
            />
          </Tooltip>
          {
            <Tooltip title={'Document Labelling'} placement={'bottom'}>
              <TinyTab
                disabled={!hasAccess('perm_act_a2klabel')}
                icon={<Icons.DocLabel/>}
                aria-label="label"/>
            </Tooltip>
          }
          <Tooltip title={'Document Queries'} placement="bottom">
            <TinyTab
              label={queries.length === 0 && closedQueryCount.length === 0
                ? <Icons.DocQuery/>
                : <Badge
                  className={queries.length === closedQueryCount.length ? classes.queryCountClosed : classes.queryCount}
                  badgeContent={queries.length === closedQueryCount.length ? ' ' : queries.length - closedQueryCount.length}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}>
                  <Icons.DocQuery/></Badge>}
              aria-label="queries"
            />
          </Tooltip>
          {/* <Tooltip title={'Document History'} placement="bottom"> */}
          <TinyTab
            value={'history'}
            disabled={true}
            icon={<Icons.DocHistory/> }
            aria-label="history"
          />
          {/* </Tooltip> */}
        </Tabs>
      </Grid>
      <GridGrow>
        {
          tab === 0 &&
          <TabPanel>
            <MetaData/>
          </TabPanel>
        }
        {
          tab === 1 &&
          <TabPanel>
            <Search/>
          </TabPanel>
        }
        {
          tab === 2 &&
          <TabPanel>
            <Labeling/>
          </TabPanel>
        }
        {
          tab === 3 &&
          <TabPanel>
            <Queries/>
          </TabPanel>
        }
        {
          tab === 4 &&
          <TabPanel/>
        }
      </GridGrow>
    </Grid>
  )
}
