import { FC, forwardRef, MouseEvent, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, makeStyles } from '@material-ui/core'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { setDeliveryBibleName } from 'utils/transition'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useExportDeliveryBibleMutation } from 'services/api/exportApi'
import { setExportPathName } from 'redux/slices/clientDocumentsSlice'
import { useGetTransitionQuery } from 'services/api/transitionApi'
import { useTransitionId } from 'context/TransitionContext'
import InputLink from 'common/LisaControls/InputLink'
import { toDMYHMSString } from 'utils/date'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '200px',
    padding: '32px 0 0 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontSize: '16px'
  },
  title: {},
  bodyTxt: {
    lineHeight: '1.5',
    fontWeight: 500
  },
  folderName: {
    lineHeight: '28px',
    fontSize: '20px',
    fontWeight: 600
  },
  link: {
    margin: '16px 0'
  },
  actionButtons: {
    padding: '32px 0',
    display: 'flex',
    // columnGap: "10px",
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  }
}))
export const ExportForm: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [folderPath, setFolderPath] = useState('')
  const [oneDriveUrl, setOneDriveUrl] = useState('')
  const { userId } = useLisaAuth()
  const { data } = useGetTransitionQuery(useTransitionId())
  const transition = data!
  const transitionId = transition.transitionId
  const handleClose = () => {
    dispatch(closeModal())
  }
  const [exportDeliveryBible] = useExportDeliveryBibleMutation()
  const buttonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    let partial
    if (process.env.REACT_APP_ENVIRONMENT === 'production') { // @todo url in env
      partial = 'https://amrosglobal-my.sharepoint.com/personal/lisatransition_prod_amrosglobal_aero/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Flisatransition_prod_amrosglobal_aero%2FDocuments%2FDeliveryBible%2F'
    } else {
      partial = 'https://amrosglobal-my.sharepoint.com/personal/lisatransition_amros-global_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Flisatransition_amros-global_com%2FDocuments%2FDeliveryBible%2F'
    }
    const path = `${transition.name.replaceAll(/\s/g, '')} ${toDMYHMSString(new Date())}`
    const url = `${partial}${`${transition.name.replaceAll(
      /\s/g,
      ''
    )}%20${toDMYHMSString(new Date())}`}`
    setFolderPath(path)
    setOneDriveUrl(url)
    dispatch({ type: 'SET_EXPORT_DELIVERY_BIBLE_NAME', payload: path })
    if (buttonRef.current) {
      buttonRef.current.focus()
    }
    return () => {}
  }, [])

  const close = () => {
    handleClose()
  }
  const handleExportDeliveryBible = () => {
    setDeliveryBibleName(transitionId, folderPath)
    dispatch(setExportPathName(folderPath)) // for live reload. TMP I hope
    exportDeliveryBible({
      exportName: folderPath,
      transitionId,
      userId: userId!
    })
    close()
  }

  const copyToClipboard = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    await navigator.clipboard.writeText(oneDriveUrl)
    dispatch(openToast({ severity: 'success', message: 'Link Copied!' }))
  }

  const CopyLink = forwardRef((props, ref) => (
    <InputLink
      ref={ref}
      onClick={copyToClipboard}
      value={oneDriveUrl}
    />
  ))
  CopyLink.displayName = 'CopyLink'

  return (
    <>
      <div className={classes.root}>
        <div className={classes.title}>
          <div className={classes.bodyTxt}>
            Delivery Bible will be exported to file storage folder:
          </div>
          <div className={classes.folderName}>{folderPath}</div>
        </div>
        <div className={classes.link}>
          <CopyLink/>
        </div>
        <div>
          <span className={classes.bodyTxt}>
            You can share provided link with anyone but for them to be able to
            access Delivery Bible you need to grant them access to
            <span style={{ fontWeight: 600 }}> {folderPath} </span>
            folder on your file storage.
          </span>
        </div>
      </div>
      <div className={classes.actionButtons}>
        <Button
          ref={buttonRef}
          onClick={handleExportDeliveryBible}
          variant="contained"
          color="primary"
          size="small"
        >
          Export
        </Button>
        <Button
          onClick={close}
          variant="contained"
          color="primary"
          size="small"
        >
          Cancel
        </Button>
      </div>
    </>
  )
}
