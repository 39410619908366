import { FC } from 'react'
import { Chip, makeStyles, Tooltip, Typography, Theme } from '@material-ui/core'

const useStyles = makeStyles<Theme, { label: boolean }>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: props => props.label ? 'none' : 'center',
    rowGap: '3px',
    minHeight: '50px',
    paddingBottom: 0,
    '& .MuiChip-sizeSmall': {
      height: '24px',
      fontSize: '13px',
      background: 'white',
      borderRadius: '4px',
      border: `2px solid ${theme.palette.import.main}`
    }
  }
}))

interface FolderPathProps {
    filePath?: string, // @todo change if response param is different key name
    importFolder?: string,
    label?: boolean
}

const FolderPath: FC<FolderPathProps> = ({ filePath, importFolder, label = true }) => {
  const classes = useStyles({ label })

  return (
    <div className={classes.root}>
      {label && <Typography variant="body2">Import folder</Typography>}
      <Tooltip title={filePath ?? ''} placement={!label ? 'bottom' : 'left'}>
        <Chip size="small" label={importFolder} />
      </Tooltip>
    </div>
  )
}

export default FolderPath
