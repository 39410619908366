import { FC, useEffect } from 'react'
import { SignInLoader } from 'common/Loaders/LisaLoader'
import { useCurrentUrl } from 'hooks/useCurrentUrl'
import { useAuth } from 'react-oidc-context'
import { useHistory } from 'react-router-dom'

export const SignInRedirect: FC = () => {
  const url = useCurrentUrl()
  const { signinRedirect } = useAuth()
  const history = useHistory()
  useEffect(() => {
    signinRedirect({
      state: url
    }).then().catch(() => {
      // This is to handle ERR_CERT_AUTHORITY_INVALID for dev IS
      history.replace('/error/code/500')
    })
  }, [])
  return <SignInLoader/>
}
