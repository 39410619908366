import { ChangeEvent, FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useLabelsContext } from 'context/LabelsContext'
import InputSearch from 'common/LisaControls/InputSearch'

const useStyles = makeStyles({
  searchFilter: {
    width: '100%',
    marginBottom: '8px',
    '& .fas.fa-info-circle': {
      display: 'none'
    }
  }
})
export const LabelSearch: FC = () => {
  const { selector, reducers, minLabelLevel } = useLabelsContext()
  const { searchTerm } = useSelector(selector)
  const classes = useStyles()
  const dispatch = useDispatch()
  const onSearch = (e: ChangeEvent<{value: string}>) => {
    dispatch(reducers.search({ search: e.currentTarget.value, minLevel: minLabelLevel }))
  }
  return (
    <div className={classes.searchFilter}>
      <InputSearch
        component={'div'}
        name="searchTerm"
        label="Find label"
        onChange={onSearch}
        value={searchTerm}/>
    </div>
  )
}
