import { ChangeEvent, FC, useCallback, useRef, useState } from 'react'
import { Icon, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { LisaFile, OcrStatus } from 'types'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { MaybeForbiddenAction } from 'common/MaybeForbiddenAction/MaybeForbiddenAction'
import {
  downloadExcelDocument,
  downloadOriginalDocument,
  downloadProcessedDocument,
  setDocumentToRename
} from 'redux/slices/documentSlice'
import { useDispatch } from 'react-redux'
import { openModal, openToast } from 'redux/slices/appSlice'
import { resolveDocumentType } from 'utils/documents'
import {
  useConvertDocumentToExcelMutation,
  useReplaceDocumentMutation
} from 'services/api/documentApi'

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    width: '36px !important',
    height: '36px !important',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'all .1s linear',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.black.main,
    lineHeight: '40px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: theme.palette.lime.main,
      color: theme.palette.black.main
    },
    '& .fas.fa-ellipsis-v': {
      fontSize: '18px'
    }
  }
}))

type DocumentActionsMenuProps = {
  document: LisaFile
}

export const DocumentActionsMenu: FC<DocumentActionsMenuProps> = ({ document }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const hiddenFileInput = useRef<HTMLInputElement | null>(null)
  const { hasAccess } = useLisaAuth()
  const dispatch = useDispatch()
  const canHaveExcelFile = document.ocrStatus === OcrStatus.Success &&
    resolveDocumentType(document.extension, document.ocrStatus) !== 'video'
  const [replaceDocument] = useReplaceDocumentMutation()
  const [convertDocumentToExcel] = useConvertDocumentToExcelMutation()

  const onFileInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (document.documentId && e.currentTarget.files && e.currentTarget.files.length > 0) {
      replaceDocument({
        documentId: document.documentId,
        files: Array.from(e.currentTarget.files)
      })
    }
  }, [document.documentId])

  return (
    <div className={'document-actions-menu'}>
      <input
        ref={hiddenFileInput}
        style={{ display: 'none' }}
        onChange={onFileInputChange}
        type="file"
      />
      <div
        className={classes.menuIcon}
        onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Icon
          className="fas fa-ellipsis-v"
          fontSize="small"
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MaybeForbiddenAction
          isForbidden={!hasAccess('perm_act_replacedocument')}>
          <MenuItem key='replace-document' onClick={() => hiddenFileInput?.current?.click()}>
            Replace Document
          </MenuItem>
        </MaybeForbiddenAction>
        <MenuItem key='download-original-document' onClick={() => dispatch(downloadOriginalDocument(document.storageId))}>
          Download Original Document
        </MenuItem>
        <MenuItem
          key='download-ocr-document'
          disabled={!document.isProcessed}
          onClick={() => {
            document.isProcessed && dispatch(downloadProcessedDocument(document.storageId))
          }}>
          Download OCR Document
        </MenuItem>
        {
          !document.excelUrl &&
          <MenuItem
            key='convert-document-to-excel-version'
            disabled={!document.excelConverterEnabled || !canHaveExcelFile}
            onClick={async () => {
              const { success } = await convertDocumentToExcel(document.originalFileId).unwrap()
              if (success) {
                dispatch(openToast({ severity: 'info', message: 'Document conversion to Excel started' }))
              }
            }}>
            { document.excelConverterEnabled ? 'Convert Document to Excel' : 'Conversion to Excel in progress'}
          </MenuItem>
        }
        {
          document.excelUrl &&
          <MenuItem
            key='download-document-excel-version'
            disabled={!document.excelUrl}
            onClick={() => {
              document.excelUrl && dispatch(downloadExcelDocument({
                excelUrl: document.excelUrl,
                documentName: document.name
              }))
            }}>
            Download Document Excel version
          </MenuItem>
        }
        <MaybeForbiddenAction key={'rename-document'} isForbidden={!hasAccess('perm_act_renamedocument')}>
          <MenuItem
            onClick={() => {
              dispatch(setDocumentToRename(document))
              dispatch(openModal('RENAME_DOCUMENT'))
            }}>
          Rename Document
          </MenuItem>
        </MaybeForbiddenAction>
      </Menu>
    </div>
  )
}
