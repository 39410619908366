import { FC, useEffect, useRef, useState } from 'react'
import lisaApi from 'api/lisaApi'
import { Grid, makeStyles } from '@material-ui/core'
import { GridGrow } from 'common/Grid/GridGrow'
import { DocumentViewerToolbar } from './DocumentViewerToolbar'
import { DocumentViewerProps } from './documentViewerTypes'
import { setVideoLoader, useDocument } from 'redux/slices/documentSlice'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { DocumentDownloadProgress } from 'common/DocumentDownloadProgress/DocumentDownloadProgress'

const useStyles = makeStyles(() => ({
  content: {
    marginTop: 60
  },
  video: {
    width: '100%',
    maxHeight: '80vh'
  },
  hidden: {
    width: '0%'
  },
  videoLoader: {
    height: '80vh',
    width: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  videoWrapper: {
    position: 'relative',
    width: '95%',
    height: '100%'
  }
}))

export const VideoViewer: FC<DocumentViewerProps> = (props) => {
  const classes = useStyles()
  const sourceRef = useRef<HTMLSourceElement>(null)
  const [downloadPercentage, setDownloadPercentage] = useState(0)
  const dispatch = useDispatch()
  const { videoLoader } = useSelector(useDocument)
  const { document } = props
  const videoElement = () => sourceRef?.current?.parentElement as HTMLVideoElement

  useEffect(() => {
    lisaApi.get(document!.url, {
      responseType: 'blob',
      onDownloadProgress: (pe) => {
        if (pe.loaded && pe.total) {
          setDownloadPercentage(Math.floor(pe.loaded / pe.total * 100))
        }
      }
    }).then(({ data }) => {
      if (sourceRef && sourceRef.current && sourceRef.current.parentElement) {
        sourceRef.current.src = URL.createObjectURL(data)
        videoElement().load()
      }
      dispatch(setVideoLoader())
      setDownloadPercentage(0)
    })
  }, [])

  const goFullScreen = async () => {
    const video = videoElement()
    if (video) {
      await video.requestFullscreen()
    }
  }
  return (
    <Grid
      container
      item
      justifyContent={'center'}
      alignItems={'stretch'}
      direction={'column'}>
      <Grid item>
        <DocumentViewerToolbar
          fullScreenHandler={goFullScreen}
          document={document!}
          compact={props.options?.view === 'compact'}/>
      </Grid>
      <GridGrow item container justifyContent='center' className={classes.content}>
        {
          !videoLoader &&
          <div className={classes.videoLoader}>
            <DocumentDownloadProgress percentage={downloadPercentage} size={document!.size}/>
          </div>
        }
        <div className={classes.videoWrapper}>
          <video controls className={clsx(classes.video, { [classes.hidden]: !videoLoader })}>
            <source ref={sourceRef}/>
          </video>
        </div>
      </GridGrow>
    </Grid>
  )
}
