import { FC, memo, useMemo } from 'react'
import { makeStyles, Paper, Typography } from '@material-ui/core'
import { BarElement, Chart, ChartData, Tooltip } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { buildQueryStatusReport, queryStatusColors } from 'utils/queries'
import { Query, QueryStatus, QueryStatusReport } from 'types'

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 0,
    height: 80,
    // boxSizing: 'content-box',
    position: 'relative',
    padding: '0 12px'
  },
  chartTitle: { // Chart title and chart legend cannot share line by design. This is workaround
    position: 'absolute',
    top: 8,
    left: 16,
    fontWeight: 600
  }
}))

const commonDataSet = {
  borderRadius: {
    topLeft: 3,
    topRight: 3,
    bottomLeft: 3,
    bottomRight: 3
  },
  borderWidth: 2,
  borderSkipped: false,
  backgroundColor: ['#ffffff'],
  hoverBackgroundColor: ['#ffffff']
}

const barChartData = (qsr: QueryStatusReport): ChartData => ({
  labels: [''],
  datasets: [
    {
      label: 'Closed',
      data: [qsr.closedQueriesCount],
      borderColor: [queryStatusColors.get(QueryStatus.Closed)],
      ...commonDataSet
    },
    {
      label: 'Sideletter',
      data: [qsr.sideletterQueriesCount],
      borderColor: [queryStatusColors.get(QueryStatus.Sideletter)],
      ...commonDataSet
    },
    {
      label: 'Delayed',
      data: [qsr.delayedQueriesCount],
      borderColor: [queryStatusColors.get(QueryStatus.Delayed)],
      ...commonDataSet
    },
    {
      label: 'Pending',
      data: [qsr.pendingQueriesCount],
      borderColor: [queryStatusColors.get(QueryStatus.Pending)],
      ...commonDataSet
    },
    {
      label: 'WorkPack',
      data: [qsr.workPackQueriesCount],
      borderColor: [queryStatusColors.get(QueryStatus.WorkPack)],
      ...commonDataSet
    },
    {
      label: 'Open',
      data: [qsr.openQueriesCount],
      borderColor: [queryStatusColors.get(QueryStatus.Open)],
      ...commonDataSet
    },
    {
      label: 'New',
      data: [qsr.newQueriesCount],
      borderColor: [queryStatusColors.get(QueryStatus.New)],
      ...commonDataSet
    }
  ]
})

// @todo Create file for chart plugins if more are added
const tooltipPlugin = Chart.registry.getPlugin('tooltip') as typeof Tooltip
tooltipPlugin!.positioners!.centerTop = (elements) => {
  const [activeElement] = elements
  const reportTitle = document.getElementById('query-status-report-title')
  if (!activeElement) {
    if (reportTitle !== null) {
      reportTitle.style.opacity = '1'
    }
    return false
  }
  const element = activeElement.element as BarElement
  const centerPointX = element.getCenterPoint().x
  if (reportTitle !== null) {
    reportTitle.style.opacity = centerPointX < 150 ? '0.2' : '1'
  }
  return { x: centerPointX, y: -10 }
}

type QueriesStatusReportChartProps = {
  data: ChartData,
  max: number
}
export const QueriesStatusReportChart: FC<QueriesStatusReportChartProps> = ({ data, max }) => {
  return (
    <Bar
      type={'bar'}
      data={data}
      options={{
        animation: {
          // duration: 0
          easing: 'easeOutQuint'
        },
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'x',
          position: 'centerTop',
          yAlign: 'bottom',
          padding: {
            top: 10,
            left: 16,
            right: 16,
            bottom: 10
          }
        },
        plugins: {
          title: {
            display: false
          },
          legend: {
            align: 'end',
            labels: {
              padding: 15
            },
            onClick: () => null
          }
        },
        responsive: true,
        indexAxis: 'y',
        scales: {
          x: {
            stacked: true,
            max,
            grid: {
              display: false
            },
            ticks: {
              precision: 0
            }
          },
          y: {
            stacked: true,
            ticks: {
              display: false
            },
            grid: {
              display: false
            }
          }
        }
      }}/>
  )
}

const QueriesStatusReportChartMemoized = memo(QueriesStatusReportChart)

export interface QueriesStatusReportProps {
  queries: Query[]
}
export const QueriesStatusReport: FC<QueriesStatusReportProps> = ({ queries }) => {
  const classes = useStyles()
  const report = useMemo(() => buildQueryStatusReport(queries), [JSON.stringify(queries)])
  const data = useMemo(() => barChartData(report), [report])
  if (report.totalQueriesCount === 0) {
    return null
  }
  return (
    <Paper elevation={0} className={classes.root}>
      <Typography id='query-status-report-title' variant='body2' className={classes.chartTitle}>QUERY STATUS</Typography>
      <QueriesStatusReportChartMemoized data={data} max={report.totalQueriesCount}/>
    </Paper>
  )
}
