import { ChangeEvent, KeyboardEvent, MouseEvent, useState } from 'react'
import LoginWallpaper from 'public/images/login-wallpaper.jpg'
import AmrosLogo from 'public/images/LOGO-01-Custom.png'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Copyright from 'common/Copyright/Copyright'
import { useUpdatePasswordMutation } from 'services/api/usersApi'
import Input from 'common/LisaControls/Input'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    backgroundImage: `url('${LoginWallpaper}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    '& .formWrapper': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '50%',
      height: '100vh',
      '@media (max-width: 992px)': {
        width: '100%'
      }
    },
    '& .copyright': {
      fontSize: '14px',
      padding: '16px',
      textAlign: 'left',
      display: 'block',
      width: '100%',
      position: 'absolute',
      bottom: '0',
      '@media (max-width: 992px)': {
        width: '100%',
        textAlign: 'center'
      }
    }
  },
  logoSection: {
    width: 'auto',
    padding: '24px',
    display: 'block',
    boxSizing: 'border-box',
    position: 'absolute',
    top: '0',
    left: '0',
    '& img': {
      width: '200px'
    },
    '@media (max-width: 992px)': {
      width: '100%',
      textAlign: 'center'
    }
  },
  formSection: {
    boxSizing: 'border-box',
    border: 'none',
    borderRadius: '2px',
    maxWidth: '400px',
    width: 'calc(100% - 32px)',
    margin: '80px 16px',
    padding: '48px 36px',
    boxShadow: '0 0 32px 0 rgb(0 0 0 / 25%)',
    background: 'white',
    height: 'auto'
  },
  form: {
    width: '100%'
  },
  paper: {
    padding: '24px',
    height: '40%',
    width: '40%'
  },
  email: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '8px'
  },
  password: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '8px',
    '& .MuiSvgIcon-root': {
      width: '18px',
      height: '18px'
    }
  },
  forgotPassword: {
    textDecoration: 'none',
    marginTop: '16px',
    fontSize: '14px'
  },
  errorMessage: {
    color: theme.palette.statusColors.red,
    textDecoration: 'none',
    fontSize: '14px'
  },
  formText: {
    display: 'flex',
    flexDirection: 'column',
    '& .title': {
      fontSize: '24px',
      fontWeight: '700'
    },
    '& p': {
      fontSize: '16px',
      margin: '0'
    },
    '& span': {
      fontSize: '16px',
      fontWeight: 700,
      marginBottom: '28px'
    }
  },
  noteDescription: {
    marginTop: '20px'
  },
  bodyTxt: {
    fontWeight: 900
  },
  checkText: {
    background: 'white',
    padding: '34px',
    fontSize: '24px',
    fontWeight: 600
  }
}))

const ResetPasswordForm = () => {
  const [values, setValues] = useState({
    password: '',
    repeatPassword: '',
    error: ''
  })
  const [updatePassword, { data: updatePasswordResponse }] = useUpdatePasswordMutation()
  const success = updatePasswordResponse?.success ?? false
  const message = updatePasswordResponse?.message ?? ''
  const classes = useStyles()
  const history = useHistory()
  const passwordRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@:#$!.%*?&])[A-Za-z\d@:#$!.%*?&]{8,}$/

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value
    })
  }

  const validate = () => {
    const p1 = passwordRegex.test(values.password)
    const p2 = passwordRegex.test(values.repeatPassword)
    if (values.password === values.repeatPassword && p1 && p2) {
      setValues({ ...values, error: '' })
      return true
    } else {
      if (!p1 || !p2) {
        setValues({ ...values, error: 'Password pattern is not correct' })
        return false
      }
      if (values.password !== values.repeatPassword) {
        setValues({ ...values, error: 'Password must match!' })
        return false
      }
    }
  }

  const validateEntry = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const search = window.location.search
    const params = new URLSearchParams(search)
    const IdFromURL = params.get('uid')
    if (validate() && IdFromURL!.length > 0) {
      updatePassword({ guid: IdFromURL!, newPassword: values.repeatPassword })
    }
  }

  const enterPressed = (e: KeyboardEvent<HTMLImageElement>) => {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const IdFromURL = params.get('uid')
    if (e.key === 'Enter') {
      if (validate() && IdFromURL!.length > 0) {
        updatePassword({ guid: IdFromURL!, newPassword: values.repeatPassword })
      }
    }
  }

  return (
    <div className={classes.root}>
      <div className={'formWrapper'}>
        <div className={classes.logoSection}>
          <img src={AmrosLogo} alt={'amros-logo'}/>
        </div>
        {!success
          ? <div className={classes.formSection}>
            <div className={classes.form}>
              <div className={classes.formText} style={{ marginBottom: '32px' }}>
                <div className={'title'}>Create new password</div>
              </div>
              <div className={classes.email}>
                <Input
                  label='Password'
                  name='password'
                  type={'password'}
                  value={values.password}
                  onChange={handleInputChange}
                  onKeyUp={enterPressed}
                />
              </div>
              <div className={classes.email}>
                <Input
                  label='Repeat password'
                  name='repeatPassword'
                  type={'password'}
                  value={values.repeatPassword}
                  onChange={handleInputChange}
                  onKeyUp={enterPressed}
                  errMsg={values.error}
                />
              </div>
              <Grid item xs={12} className={classes.noteDescription} >
                <Typography variant='body2'> <span className={classes.bodyTxt}>Note: </span>password must have 8 characters minimum, 1 uppercase letter, 1 number and 1 special character ( !, #, @, $, %, &, *, ? ) minimum (e.g. H3lloW@rld)</Typography>
              </Grid>
              <Button
                disabled={(values.password === '' || values.repeatPassword === '')}
                variant='contained'
                color='primary'
                size='large'
                style={{ marginTop: '24px', width: '100%' }}
                onClick={validateEntry}>
                RESET PASSWORD
              </Button>
            </div>
          </div>

          : <div className={classes.formSection}>
            <div className={classes.formText}>
              <div className={'title'}>{message}</div>
            </div>
            <Button variant='contained' color='primary' size='large' style={{ marginTop: '24px', width: '100%' }} onClick={() => history.push('/')} >GO TO LOGIN</Button>
          </div>
        }
      </div>
      <Copyright />
    </div>
  )
}

export default ResetPasswordForm
