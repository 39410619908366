import { FC } from 'react'
import { Label } from 'types'
import { LabelFolder } from 'common/LabelFolder/LabelFolder'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useLabelsContext } from 'context/LabelsContext'
import { getAllLabelIds } from 'utils/labels'

const useStyles = makeStyles({
  childrenContainer: {
  }
})

export type LabelStructureProps = {
  label: Label
  depth: number
  path?: number[]
  onlySelectedLabels?: boolean
  a2kRules?: boolean
}
export const LabelHierarchy: FC<LabelStructureProps> = ({
  label,
  depth,
  path = [],
  onlySelectedLabels = false,
  a2kRules = false
}) => {
  const classes = useStyles()
  const { selector } = useLabelsContext()
  const { openedLabels, searchResults } = useSelector(selector)
  // @todo OK for now since this feature is only used when there is only one search results. Refactor to slice if becomes to demanding
  const selectedLabelIds = !onlySelectedLabels ? [] : getAllLabelIds(searchResults)
  const isInSelectedPath = !onlySelectedLabels || selectedLabelIds.includes(label.labelId)
  return (
    <>
      {
        label.parentId !== null && isInSelectedPath &&
          <LabelFolder
            label={label}
            a2kRules={a2kRules}/>
      }
      {
        (openedLabels[label.labelId] ?? false) && isInSelectedPath &&
          <div className={classes.childrenContainer} data-testid='labelsChildren'>
            {
              label.children.map((child) => (
                <LabelHierarchy
                  onlySelectedLabels={onlySelectedLabels}
                  key={child.labelId}
                  label={child}
                  path={[...path, label.labelId]}
                  depth={depth + 1}
                  a2kRules={a2kRules}/>
              ))
            }
          </div>
      }
    </>
  )
}
