import { FC, useState, useEffect, useCallback } from 'react'
import { Button, Grid } from '@material-ui/core'
import { NotificationType, Notification } from 'types'
import { NotificationSelect } from './NotificationSelect'
import DatePicker from 'common/LisaControls/DatePicker'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import TimePicker from 'common/LisaControls/TimePicker'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { closeModal, openToast } from 'redux/slices/appSlice'
import { useCreateOrEditSystemNotificationMutation } from 'services/api/notificationsApi'
import { addMinutes, compareDateWithCurrent, convertDateFromUTCtoLocal } from 'utils/date'

type NotificatioFormProps = {
  notification?: Notification
}

type InitialValues = {
  notificationTemplateId: NotificationType,
  createdTime?: Date
  date: Date,
  hourFrom: Date | null,
  hourTo: Date | null
}

const initialValues: InitialValues = {
  notificationTemplateId: NotificationType.RELEASE,
  date: new Date(),
  hourFrom: new Date(),
  hourTo: addMinutes(new Date(), 30)
}

const useStyles = makeStyles(() => ({
  root: {
    paddingBlock: 26
  },
  splitSections: {
    padding: '0px 20px 10px 0px'
  },
  actionButtons: {
    paddingBottom: '26px',
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButtonBase-root': {
      marginRight: '8px'
    }
  }
}))
type Errors = {
  date?: string,
  hourFrom?: string,
  hourTo?: string
}

const NotificationForm: FC<NotificatioFormProps> = ({ notification }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isEdit = notification !== undefined
  const compareDates = compareDateWithCurrent(new Date(notification?.createdTime!))
  const [values, setValues] = useState<Partial<InitialValues>>(isEdit
    ? {
      notificationTemplateId: notification.notificationTemplateId,
      createdTime: notification.createdTime,
      date: compareDates ? new Date() : notification.outageFrom, // Here is new Date() provided, only when edit is enabled
      hourFrom: compareDates ? new Date() : convertDateFromUTCtoLocal(notification.outageFrom),
      hourTo: compareDates ? new Date() : convertDateFromUTCtoLocal(notification.outageTo)
    }
    : initialValues)
  const [errors, setErrors] = useState<Errors | null>(null)
  const [createOrEditSystemNotification] = useCreateOrEditSystemNotificationMutation()
  const isErrosEmpty = JSON.stringify(errors) === '{}'
  const validate = () => {
    const validationFields: (keyof Errors)[] = ['date', 'hourFrom', 'hourTo']
    const { hourFrom, hourTo } = values

    return validationFields.reduce(
      ({
        errors: e,
        isValid
      }: { errors: Errors, isValid: boolean }, field: keyof Errors) => {
        if (hourFrom && hourTo) {
          const diff = hourFrom.getTime() >= hourTo.getTime()
          if (diff) {
            setErrors({
              ...errors,
              hourTo: 'Hour to cannot be less than hour from!'
            })
            isValid = false
          } else {
            setErrors({})
          }
        }
        if (!values[field]) {
          setErrors({
            ...errors,
            [field]: 'This field is required.'
          })
          isValid = false
        }
        return {
          errors: e,
          isValid
        }
      }, {
        errors: {},
        isValid: true
      })
  }

  useEffect(() => {
    validate()
  }, [values])

  const handleClose = () => {
    dispatch(closeModal(['CREATE_NOTIFICATION', 'EDIT_NOTIFICATION']))
  }

  const createOrEditSystemNotificationCB = useCallback(async (params) => {
    const values = {
      isEdit,
      notificationTemplateId: params.notificationTemplateId!,
      scheduledTime: new Date(new Date().toISOString()),
      expirationTime: params.hourTo!,
      outageFrom: params.hourFrom!,
      outageTo: params.hourTo!
    }
    const sendObject = {
      ...values,
      ...(isEdit && { createdTime: params.createdTime! })
    }
    const {
      success,
      message
    } = await createOrEditSystemNotification(sendObject).unwrap()
    setErrors({})
    handleClose()
    if (!success && message) {
      dispatch(openToast({
        severity: 'error',
        message: message
      }))
    } else {
      dispatch(openToast({
        severity: 'info',
        message: `Notification successfully ${isEdit ? 'updated' : 'created'}`
      }))
    }
  }, [])

  const handlePublishNotification = () => {
    const { isValid } = validate()
    if (isValid) {
      createOrEditSystemNotificationCB(values)
    }
  }

  return (
    <Grid container direction={'column'}>
      <div className={classes.root}>
        <Grid item xs={6} className={classes.splitSections}>
          <NotificationSelect
            showSelectOption={false}
            label={'Notification type'}
            name={'notificationTypes'}
            onChange={(e) => {
              setValues({
                ...values,
                notificationTemplateId: e.target.value as NotificationType
              })
            }
            }
            value={values.notificationTemplateId}
            disabled={false}/>
        </Grid>
        <Grid item xs={6} className={classes.splitSections}>
          <DatePicker
            label="Due Date"
            name="dueDate"
            required
            disablePast={true}
            value={values.date }
            errMessage={errors?.date}
            onChange={(date: MaterialUiPickersDate) => date && setValues({
              ...values,
              date: date,
              hourFrom: date,
              hourTo: date
            })}/>
        </Grid>
        <Grid container item>
          <Grid item xs={6} className={classes.splitSections}>
            <TimePicker
              variant="inline"
              label="From (hh:mm)"
              name="hourFrom"
              required
              value={values.hourFrom}
              onChange={(date: MaterialUiPickersDate) => date && setValues({
                ...values,
                hourFrom: date
              })}/>
          </Grid>
          <Grid item xs={6} className={classes.splitSections}>
            <TimePicker
              variant="inline"
              label="To (hh:mm)"
              name="hourTo"
              required
              value={values.hourTo}
              onChange={(date: MaterialUiPickersDate) => date && setValues({
                ...values,
                hourTo: date
              })}
              errMessage={errors?.hourTo}/>
          </Grid>
        </Grid>
      </div>
      <div className={classes.actionButtons}>
        <Button disabled={!isErrosEmpty!} onClick={handlePublishNotification} variant='contained' color='primary' size='small'>
          Publish
        </Button>
        <Button onClick={handleClose} variant='contained' color='primary' size='small'>
          Cancel
        </Button>
      </div>
    </Grid>
  )
}

export default NotificationForm
