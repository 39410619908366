import { Label, LabelResponse, LoadingState } from 'types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createHierarchy, searchTree, selectLabelInTree } from 'utils/labels'
import { initialLabelsState, labelsReducers, LabelsReducers, LabelsState } from './labelsSlice'
import { RootState } from 'redux/store'

export interface QueryLabelsState extends LabelsState {
  selected: Label | null
}

export interface QueryLabelsReducers extends LabelsReducers {
}

export const initialState: QueryLabelsState = {
  ...initialLabelsState,
  selected: null
}

const queryLabelsSlice = createSlice({
  name: 'queryLabels',
  initialState,
  reducers: {
    search: labelsReducers.search<QueryLabelsState>(),
    toggle: labelsReducers.toggle<QueryLabelsState>(),
    labelAdded: (state, { payload }: PayloadAction<Label>) => {
      state.hierarchy = selectLabelInTree(state.hierarchy!, payload, true)
      state.selected = {
        ...payload,
        isSelected: true
      }
      state.searchResults = searchTree(state.hierarchy, state.searchTerm)
    },
    labelRemoved: (state, { payload }: PayloadAction<Label>) => {
      state.hierarchy = selectLabelInTree(state.hierarchy!, payload, false)
      state.selected = null
      state.searchResults = searchTree(state.hierarchy, state.searchTerm)
    },
    reset: (state) => {
      if (state.selected !== null) {
        state.hierarchy = selectLabelInTree(state.hierarchy!, state.selected, false)
      }
      state.selected = null
      state.searchTerm = ''
      state.searchResults = []
      if (state.hierarchy) {
        state.openedLabels = {
          [state.hierarchy!.labelId]: true
        }
      }
    },
    setHierarchy: (state, { payload }: PayloadAction<LabelResponse>) => {
      state.loadingHierarchy = LoadingState.Completed
      state.hierarchy = createHierarchy(payload)
      state.searchResults = searchTree(state.hierarchy, state.searchTerm)
      state.openedLabels[state.hierarchy.labelId] = true // Root is always open
    }
  }
})

export const {
  search,
  toggle,
  labelAdded,
  labelRemoved,
  reset,
  setHierarchy
} = queryLabelsSlice.actions

export const queryLabelsReducers: QueryLabelsReducers = {
  search,
  toggle,
  labelAdded,
  labelRemoved
}

export const useQueryLabels = (state: RootState) => state.queryLabels

export default queryLabelsSlice.reducer
