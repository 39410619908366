import { FC, useMemo } from 'react'
import countries from 'i18n-iso-countries'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useAutocompleteStyle } from 'hooks/styles'

export interface CountriesDropDowProps {
  countryCode: string
  name: string
  onCountryChange: (countryCode: string) => void
}
export const CountriesDropDown: FC<CountriesDropDowProps> = ({ countryCode, name, onCountryChange }) => {
  const acClasses = useAutocompleteStyle()

  const countryNames = useMemo(() => countries.getNames('en', { select: 'official' }), [])

  const countriesArray = useMemo(() => {
    return Object.keys(countryNames).map((countryCode) => ({
      value: countryCode,
      name: countryNames[countryCode]
    })).sort((a, z) => a.name.localeCompare(z.name))
  }, [countryNames])

  return (
    <Autocomplete
      className={acClasses.autocomplete}
      options={countriesArray}
      getOptionLabel={(country) => country.name}
      value={countriesArray.find(_ => _.value === countryCode) ?? null}
      onChange={(_, country) => {
        if (country !== null) {
          onCountryChange(country.value)
        }
      }}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          className={acClasses.textField}
          variant='outlined'/>
      )}
    />
  )
}
