import { FC, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Chip, MenuItem, TableSortLabelProps } from '@material-ui/core'
import {
  clearSelectedDocuments,
  setPage,
  setPageSize,
  sortBy,
  toggleAllDocuments,
  toggleDocument, updateSelectedDocuments,
  useDeletedDocuments
} from 'redux/slices/deletedDocumentsSlice'
import { BusinessStatus, DeletedDocumentsSortColumn, User } from 'types'
import { openModal, openToast } from 'redux/slices/appSlice'
import { DocumentLink } from 'common/DocumentLink/DocumentLink'
import { DocumentLabels } from 'common/DocumentLabels/DocumentLabels'
import { LisaPaper } from 'common/LisaPaper/LisaPaper'
import { useTransitionId } from 'context/TransitionContext'
import { businessStatusOptions } from 'common/Select/DocumentStatusSelect'
import { useLisaAuth } from 'hooks/useLisaAuth'
import { useSearchQuery } from 'services/api/deletedApi'
import Dropdown from 'common/LisaControls/Dropdown'
import { CheckMark } from 'common/Icons/SvgIcons'
import { ProcessingStatusIndicator } from 'common/ProcessingStatusIndicator/ProcessingStatusIndicator'
import {
  ColumnConfiguratorKey,
  columnConfiguratorLabels,
  deletedColumns
} from 'utils/columnConfigurator'
import { Cell, Column, LisaTable, Row } from 'common/LisaTable/LisaTable'
import { AbbreviationTypeIcon } from 'common/AbbreviationTypeIcon/AbbreviationTypeIcon'
import { formattedDate } from 'utils/date'
import { RecycleBinModals } from './RecycleBinModals'
import FolderPath from 'pages/Document/components/FolderPath'
import { useRecoverDocumentsMutation } from 'services/api/documentApi'

const useStyles = makeStyles((theme) => ({
  emptyChip: {
    '&.MuiChip-root': {
      background: 'transparent'
    }
  },
  queriesCount: {
    lineHeight: '16px'
  },
  statusLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  bodyRows: {
    height: '42px'
  },
  markForDeletion: {
    background: theme.palette.red.main
  },
  dataTableCheckbox: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.35rem'
    }
  },
  resultsBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .resultCount': {
      fontSize: '16px',
      fontWeight: 600
    },
    '& .returnResults': {
      width: '200px',
      '& .MuiSelect-root': {
        textTransform: 'lowercase'
      }
    }
  }
}))

const handleDocumentState = (value: BusinessStatus | '') => {
  const text = [{
    value: '',
    text: 'Any state'
  }, ...businessStatusOptions].filter((item) => item.value === value)
  return text[0].text
}
export const DocumentsTable: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [recoverDocuments] = useRecoverDocumentsMutation()
  const { user: { settings: { recycleBinSettings } } } = useLisaAuth()
  const transitionId = useTransitionId()
  const {
    selectedDocuments,
    searchParams
  } = useSelector(useDeletedDocuments)
  const {
    data: {
      rows,
      total,
      pages,
      pageSize
    } = {
      page: 1,
      pages: 1,
      total: 0,
      rows: [],
      pageSize: 25
    },
    isFetching,
    fulfilledTimeStamp
  } = useSearchQuery({
    ...searchParams,
    transitionId
  })
  const areDocumentsSelected = selectedDocuments.length > 0
  const hasLabel = !!searchParams.label
  const { sortColumn, sortOrder } = searchParams
  const hasValidStatusesForBulkChange = hasLabel && rows.some(_ => _.status !== BusinessStatus.Clarification)
  const selectedDocumentIds = selectedDocuments.map(_ => _.documentId)

  const handleRecoverDocuments = useCallback(async () => {
    const { success, message } = await recoverDocuments({ documentIds: selectedDocumentIds }).unwrap()
    if (message && !success) {
      dispatch(openToast({ severity: 'error', message }))
    }
    if (success) {
      dispatch(openToast({
        severity: 'success',
        message: 'Documents recovered successfully'
      }))
      dispatch(clearSelectedDocuments())
    }
  }, [selectedDocumentIds])

  useEffect(() => {
    if (fulfilledTimeStamp) {
      dispatch(updateSelectedDocuments(rows))
    }
    return () => {
      dispatch(clearSelectedDocuments())
    }
  }, [fulfilledTimeStamp])

  const items = useMemo(() => {
    return [
      <MenuItem
        key={'open-selected'}
        disabled={!areDocumentsSelected}
        onClick={handleRecoverDocuments}>
        Recover Documents
      </MenuItem>,
      <MenuItem
        key={'configure-table-view'}
        onClick={() => dispatch(openModal('COLUMN_CONFIGURATOR_RECYCLE_BIN'))}>
        {'Configure table view'}
      </MenuItem>
    ]
  }, [areDocumentsSelected, hasValidStatusesForBulkChange, rows.length, selectedDocuments])
  const title = useMemo(() => {
    return (
      <div className={classes.resultsBar}>
        <span className={'resultCount'}>deleted documents</span>
        <div className={'returnResults'}>
          <Dropdown
            name={'perPage'}
            items={[
              {
                value: 10,
                text: '10 per page'
              },
              {
                value: 15,
                text: '15 per page'
              },
              {
                value: 25,
                text: '25 per page'
              }
            ]}
            value={searchParams.size}
            showSelectOption={false}
            onChange={(e) => dispatch(setPageSize(e.target.value as number))}
          />
        </div>
      </div>
    )
  }, [searchParams.size, total])

  const tableCellProps: Map<ColumnConfiguratorKey, TableSortLabelProps> = useMemo(() => {
    const configArray: [ColumnConfiguratorKey, DeletedDocumentsSortColumn][] = [
      ['recycleBinFilename', 'Name'],
      ['recycleBinDocumentStatus', 'BusinessStatus'],
      ['recycleBinDocumentType', 'DocType'],
      ['recycleBinOrigin', 'ImportFolder'],
      ['recycleBinProcessingStatus', 'ProcessingStatus'],
      ['recycleBinDeletedAt', 'DeletedAt'],
      ['recycleBinDeletedBy', 'DeletedBy']
    ]
    return new Map(configArray.map(([ccKey, sc]) => [
      ccKey, {
        active: sortColumn === sc,
        direction: sortColumn === sc ? sortOrder || 'asc' : 'asc',
        onClick: () => dispatch(sortBy(sc))
      }
    ]))
  }, [sortOrder, sortColumn])

  const columns: Column[] = useMemo(() => {
    return deletedColumns
      .filter((ic) => recycleBinSettings[ic as keyof User['settings']['recycleBinSettings']])
      .map((ccKey) => {
        return {
          id: String(ccKey),
          label: columnConfiguratorLabels.get(ccKey) ?? '-',
          className: ccKey === 'recycleBinFilename' ? 'fileName' : undefined,
          tableSortLabelProps: tableCellProps.get(ccKey),
          width: ccKey === 'recycleBinDocumentType' ? '60px' : undefined
        }
      })
  }, [recycleBinSettings, tableCellProps])

  const tableRows: Row[] = useMemo(() => {
    const ccKeys = Object.entries(recycleBinSettings).filter((entry) => entry[1]).map(([ccKey]) => ccKey as ColumnConfiguratorKey)
    return rows.map((document) => {
      const { labels } = document
      const cells: Cell[] = []
      if (ccKeys.includes('recycleBinDocumentType')) {
        cells.push({
          id: `document-${document.documentId}-docType`,
          value: (
            <AbbreviationTypeIcon
              docTypeRecognitionResultByPages={document.docTypeRecognitionResultByPages}
              docTypeRecognitionResult={document.docTypeRecognitionResult}/>
          )
        })
      }
      if (ccKeys.includes('recycleBinFilename')) {
        cells.push({
          id: `document-${document.documentId}-fileName`,
          value: <DocumentLink document={document} noDocType displayOnly documentNameClass={'fileNameEllipsis'} searchParam={searchParams.searchText}/>
        })
      }
      if (ccKeys.includes('recycleBinA2KLabels')) {
        cells.push({
          id: `document-${document.documentId}-labels`,
          value: <DocumentLabels
            labels={labels}
            onClick={() => null}/>
        })
      }
      if (ccKeys.includes('recycleBinOrigin')) {
        cells.push({
          id: `document-${document.documentId}-origin`,
          value: (
            <FolderPath label={false} filePath={document.filePath} importFolder={document.importFolder}/>
          )
        })
      }
      if (ccKeys.includes('recycleBinProcessingStatus')) {
        cells.push({
          id: `document-${document.documentId}-processingStatus`,
          value: <ProcessingStatusIndicator
            ocrStatus={document.ocrStatus}
            processingStatus={document.processingStatus}/>
        })
      }
      if (ccKeys.includes('recycleBinDocumentStatus')) {
        cells.push({
          id: `document-${document.documentId}-documentStatus`,
          value: (
            <div className={classes.statusLabel}>
              <Chip
                className={clsx([
                  'FileStatus',
                  handleDocumentState(document.status)
                ])}
                label={handleDocumentState(document.status)}
                size="small"
                variant="outlined"
                icon={document.status === BusinessStatus.Accepted ? <CheckMark /> : <></>}
              />
            </div>
          )
        })
      }
      if (ccKeys.includes('recycleBinDeletedAt')) {
        cells.push({
          id: `document-${document.documentId}-DeletedAt`,
          value: formattedDate(new Date(document.deletedDate))
        })
      }
      if (ccKeys.includes('recycleBinDeletedBy')) {
        cells.push({
          id: `document-${document.documentId}-DeletedBy`,
          value: (
            <div>{`${document.deletedBy}`}</div>
          )
        })
      }
      return {
        id: `document-${document.documentId}`,
        selected: selectedDocumentIds.includes(document.documentId),
        document,
        cells
      }
    })
  }, [recycleBinSettings, rows, selectedDocumentIds])

  return (
    <LisaPaper toolbar={{
      items,
      title
    }}>
      <LisaTable
        columns={columns}
        rows={tableRows}
        onSelectAll={() => dispatch(toggleAllDocuments(rows))}
        onRowToggle={(document) => dispatch(toggleDocument(document))}
        selectedRows={selectedDocumentIds}
        allRows={rows.map(_ => _.documentId)}
        isFetching={isFetching}
        page={searchParams.page}
        pages={pages}
        pageSize={pageSize}
        total={total}
        onPageChange={(value: number) => dispatch(setPage(value))}/>
      <RecycleBinModals/>
    </LisaPaper>
  )
}
