import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import { endSession } from 'redux/slices/appSlice'

// Check if error is related to user or tenant deactivations
const isUserRejectedError = (action: any): boolean => {
  const isRejected = action && action.error && action.error.message === 'Rejected'
  if (!isRejected) {
    return false
  }
  const payload = action?.payload ?? {}
  const is401 = payload.status === 401
  if (!is401) {
    return false
  }
  const data = payload?.data ?? {}
  if (data?.status !== 401) {
    return false
  }
  return data?.deactivated === 'User' || data?.deactivated === 'Tenant'
}

export const apiErrorHandler: Middleware =
  () => (next) => async (action) => {
    if (isRejectedWithValue(action)) {
      if (isUserRejectedError(action)) {
        return next(endSession())
      }
    }

    return next(action)
  }
